import React, { useEffect } from 'react'
import styles from './VideosSection.module.css'    
import VideoTrailContainer from './videoCards/videoTrailContainer/VideoTrailContainer'
import SingleVideoContainer from './videoCards/singleVideoContainer/SingleVideoContainer'
import RecentVideoCard from './videoCards/recentVideoCard/RecentVideoCard'
import FilterVideoCategory from './videoCards/filterVideoContainer/FilterVideoContainer'
import VideoSectionHeader from './videoCards/videoSectionHeader/VideoSectionHeader'
import { botconversaWebhook } from '../../functions/botconversaWebhook'


const VideosSection = ({isSandwichMenuClosed, changeSandwichMenuState, closeSandwichMenu}) => {

  const defaultPicture = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1XM1JEUsHgGACmNFidXwEj3hEJo3obn1CE_cB-jQrXSp-5tMb_WKYZig4bqcQTknekaI&usqp=CAU'
  const pageLocale = 'MEconecte Flix'
  useEffect(()=>{
  
      botconversaWebhook({locale: pageLocale, action: 'Abriu Seção de Videos'})
  })        
  return (
    <div className={styles.videosSection}>   

    
        <VideoSectionHeader isSandwichMenuClosed={isSandwichMenuClosed} changeSandwichMenuState={changeSandwichMenuState}/>

        <div onClick={()=>closeSandwichMenu()} className={styles.videoContainers}>
            <RecentVideoCard defaultPicture={defaultPicture}/>
            {/*<FilterVideoCategory/>*/}
            <VideoTrailContainer title="Trilhas de Aprendizado" defaultPicture={defaultPicture}/>
            <SingleVideoContainer title="Vídeos" defaultPicture={defaultPicture}/>   
        </div>
    </div>

  )
}

export default VideosSection