import React from 'react'
import styles from './EditClient.module.sass'
import axios from 'axios'
import UserStore from '../../../store/userStore'
import { useState } from 'react'
import { StringMask } from '../../library/StringMasks'

const EditClient = ({close, setIsLoadingScreen, selectedClient: sClient}) => {

    console.log(sClient)
    const [client, setClient] = useState({name: sClient.clientName, 
                    address: sClient.address, 
                    businessPosition: sClient.businessPosition, 
                    email: sClient.email, 
                    identity: sClient.identity, 
                    CEP: sClient.CEP, 
                    phone01: sClient.phone01, 
                    phone02: sClient.phone02, 
                    origem: sClient.origem, uf: '', localidade: '',
                    classification: sClient.classification, clientId: sClient.id})


                    const getAddress = async(cep) => {
                        await axios.get(`http://viacep.com.br/ws/${cep.replace('-', '')}/json/`)
                        
                        
                        
                        .then(resp=>{setClient({...client, localidade: resp.data.localidade, uf: resp.data.uf})}).catch(err=>console.log(err))



                    }

                    const editClient = async() =>{
                        setIsLoadingScreen(true)
                        getAddress(client.CEP) 
                        await axios.put('https://qwih4aw3zpr2bruxoyyqmykfo40ymveh.lambda-url.us-east-2.on.aws/', {client: client})
                        .then(resp=>{setIsLoadingScreen(false); close()}).catch(err=>{setIsLoadingScreen(false); console.log(err)})


                    }
                    const formatCEP = (cep) => {

                        
                        var formattedCEP = cep.replace(/\D/g, '');
                        formattedCEP = formattedCEP.replace(/(\d{5})(\d{3})/, "$1-$2");

                        console.log(cep); console.log(formattedCEP)
                        return formattedCEP;
                    }
  return (
    <div className={styles.editClient}>
        <div className={styles.content}>
            <div className={styles.topInfo}>
                <h2>Editar Cliente</h2>
                <span onClick={close}>x</span>   
            </div>    
            <div className={styles.middleInfo}>
                <div className={styles.leftContent}>
                    <label>Nome</label><input type="" value={client.name} onChange={e=>setClient({...client,  name:  e.target.value})}/>
                    <label>Endereço</label><input type="" value={client.address} onChange={e=>setClient({...client, address:  e.target.value})}/>
                    <label>Cargo</label><input type="" value={client.businessPosition} onChange={e=>setClient({...client, businessPosition:  e.target.value})}/>
                    <label>Email</label><input type="" value={client.email} onChange={e=>setClient({...client, email:  e.target.value} )}/>
                </div>







    
                <div className={styles.rightContent}>
                    <label>CNPJ / CPF</label><input type="" value={client.identity} maxLength={18} onChange={e=>setClient({...client, identity:  StringMask(e.target.value, { type:'CPFCNPJ' } ,'')})}/>
                    <label>CEP</label><input type="" value={client.CEP} maxLength={9 } onChange={e=>setClient({...client, CEP:  formatCEP(e.target.value)})}/>
                    <div><label>Telefone<input type="" value={client.phone01} maxLength={15} onChange={e=>setClient({...client, phone01:  StringMask(e.target.value, { type:'Phone' } ,'')  })}/></label>
                    <label>Celular<input type="" value={client.phone02} maxLength={15} onChange={e=>setClient({...client, phone02:  StringMask(e.target.value, { type:'Phone' } ,'')})}/></label></div>
                    <div><label>Origem<select onChange={e=>{console.log(e.target.value); setClient({...client, origem: e.target.value})}}>
                        <option value="Indicação">Indicação</option>
                        <option value="Internet">Internet</option>
                        <option value="E-mail">E-mail</option>
                        </select></label>
                    </div>


                </div>
            </div>   
            <div className={styles.bottomInfo}><div onClick={editClient} className={styles.registerButton}>Confirmar</div></div>
        </div>
    </div>










  )
}

export default EditClient  