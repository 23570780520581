import React, { useState } from 'react'
import styles from './ExpensesHistory.module.sass'
import Select from 'react-select'
import { StringMask } from '../../../library/StringMasks'
import UserStore from '../../../../store/userStore'
import axios from 'axios'
const ExpensesHistory = ({index, selectedYear, close, cfeItems, expensesSelection, getExpenses}) => {    
            const [newPrice, setNewPrice] = useState(expensesSelection.done )
  const inputMaskHandle = () =>
  {
      var inputCurrencyCost = document.getElementById('currencyInput')
      inputCurrencyCost.value = StringMask( inputCurrencyCost.value, { type:'2DigitFraction' }, '' );
  }
  
  const selecStyles =
  {   
      valueContainer:( provided, state ) => ( { ...provided, padding:'0px 5px' } ),
      dropdownIndicator:( provided, state ) => ( { ...provided, padding:'4px' } ),
      control:( styles ) => ( { ...styles, minHeight:'10px', 
                              borderColor:'gray',
                              width:'21rem', 
                              height:'2.5rem',
                              borderRadius:'5px',
                              padding:'5px',
                              fontFamily: 'Montserrat',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              fontSize: '1.2rem',
                              lineHeight: '1.3rem',
                              color: '#251F53' } )
  };







  

  const checkSelectedValue= async( index ) =>
  {
    console.log(StringMask( newPrice, { type:'float' }, '' ))
      let userId = UserStore.getUserId();
      let token = UserStore.getToken();
      document.getElementById( 'confirmBtn' ).innerHTML = "Salvando";



      console.log(index)
      await axios.put
      (
          'https://j6q3fg67zatdipf2wksmrskqqq0cqcfo.lambda-url.us-east-2.on.aws/',
          {
              company: userId,
              token: token,
              cfe:expensesSelection.cfeId,
              month: ( index + 1 ),
              done: parseFloat( StringMask( newPrice.toFixed(2), { type:'float' }, '' ) )
          }
      );
      document.getElementById( 'confirmBtn' ).innerHTML = "Salvo";
      getExpenses({month: index + 1})
      close();
  }

  const monthNames = [ 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro' ];
  return (
    <div className={styles.expensesHistory}>


      <div className={styles.content}>
          
          <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={close}>
                    <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF',width:'40rem',height:'20rem',paddingLeft:'1.5rem'}} onClick={(event) => event.stopPropagation()}>
                        <text className="CashFlowBtnClosePopUp" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'0.5rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={close}>X</text>
                        <div style={{display:'flex', flexDirection:'row', width:'20rem',margin:'0rem 0rem 1rem 2rem'}}>
                            <text className="journeyFWQQuestionText" style={{fontSize:'1.4rem'}}>Mês: {monthNames[index]}</text>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',fontSize:'1.4rem'}}>Ano: {selectedYear}</text>
                        </div>
                        <div style={{height:'0.1rem', backgroundColor:'#4a3bac',width:'105%',margin:'0rem 0rem 1rem 2rem' }}></div>
                        <div style={{display:'flex', flexDirection:'row', width:'20rem',margin:'0rem 0rem 1rem 2rem' ,gap:'2rem'}}>
                            <div style={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>
                                <text className="journeyFWQQuestionText" >Custo/Despesa</text>
                                <input  id='currencyInput' className="CashFlowInputPopUp"  type="" value={ expensesSelection.expense  } placeholder='' />
                            </div>
                                <div style={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>
                                        <text className="journeyFWQQuestionText" >Preço</text>
                                        <input id='currencyInput' className="CashFlowInputPopUp" onChange={e=>setNewPrice(parseFloat(StringMask( e.target.value, { type:'float' }, '' )))} value={StringMask(newPrice.toFixed( 2 ), { type:'2DigitFraction' }, ' ' )}></input>
                                    </div>
                                <div>
                            </div>
                        </div>
                        <div id='confirmBtn' className='CashFlowConfirmbtn' style={{margin:'0rem 0rem 1rem 2rem'}} onClick={()=>checkSelectedValue(index)}>
                            Confirmar Custo Efetivo
                        </div>
                    </div>
                </div>

      </div>
    </div>
  )
}
export default ExpensesHistory