import React from 'react'
import styles from './TitleTagJourneyCard.module.css'
import { ReadMoreContentJourneyCard } from '../readMoreContentJourneyCard/ReadMoreContentJourneyCard'
import { useState } from 'react'
const TitleTagJourneyCard = ({tag, secondTitle, content}) => {
    
  const [moreContent, setMoreContent] = useState(false)
  const [moreContentMessage, setMoreContentMessage] = useState('')
  const [moreContentTitle, setMoreContentTitle] = useState('')
  const contentLength = 220
  const setScreen = (message, moreContentTitle) =>{

    setMoreContentMessage(message)
    setMoreContent(true)
    setMoreContentTitle(moreContentTitle)
  }


  
  return (
    <div className={styles.tagsJourneyCard} style={{backgroundColor: (!secondTitle ? '#EEE8F4' : null)}}>
        {moreContent && <ReadMoreContentJourneyCard title={moreContentTitle} message={moreContentMessage} onClick={()=>setMoreContent(false)}/>}
        <div className={styles.tagsCard}>

        <div style={{backgroundColor: (!secondTitle ? '#571D94' : null)}}><p>{tag}</p></div>


        </div>
        {secondTitle && <div><h2 className={styles.secondTitle}>{secondTitle}</h2></div>}
        <div>
          <p>
            {typeof content ==='string' ? content.substring(0, contentLength) : content} 
            {typeof content==='string' && content.length>contentLength ? <span style={{cursor: 'pointer', fontWeight: 'bold', fontSize: '12px'}} onClick={()=>setScreen(content, secondTitle)}>Leia Mais</span> : ''}
            </p>
            </div>
    </div>
  )
}

export default TitleTagJourneyCard