import React from "react";
import "../questions/questions.css";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import UserStore from "../../store/userStore";
import { useState, useEffect } from "react";
import axios from 'axios';
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import {FailAddElement} from "../failAddElement/FailAddElement";

const ValuePropositionQuestions = (props)=>{
  
    const [ journeyData, setJourneyData] = useState( null );
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)


    const LoadJourneyAnswers = async() =>{
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );

        if(resp.data.What )
        {
            setJourneyData(resp.data.What);
        }
    }

    const saveAnswers = async()=>{

        setLoading(true)
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData = 
        {
            jwCustomerNeeds:journeyData.jwCustomerNeeds, 
            jwProductBenefits:journeyData.jwProductBenefits, 
            jwAssociatedProblemFirst:journeyData.jwAssociatedProblemFirst,
            jwAssociatedBenefitFirst:journeyData.jwAssociatedBenefitFirst,
            jwAssociatedProblemSecond:journeyData.jwAssociatedProblemSecond,
            jwAssociatedBenefitSecond:journeyData.jwAssociatedBenefitSecond, 
            jwAssociatedProblemThird:journeyData.jwAssociatedProblemThird,
            jwAssociatedBenefitThird:journeyData.jwAssociatedBenefitThird, 
            jwAssociatedProblemFourth:journeyData.jwAssociatedProblemFourth,
            jwAssociatedBenefitFourth:journeyData.jwAssociatedBenefitFourth,  
            jwFirstValuePhrases:journeyData.jwFirstValuePhrases,
            jwSecondValuePhrases:journeyData.jwSecondValuePhrases,
            jwThirdValuePhrases:journeyData.jwThirdValuePhrases,
            jwFourValuePhrases:journeyData.jwFourValuePhrases
        };


        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'What', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })
    
        document.getElementById( 'saveBtn' ).innerHTML = "Salvar";

        
        
    }

    const changetext =(e,source,choice)=>{

        switch (source){

            case 'text1':{
                if(journeyData.jwCustomerNeeds !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwCustomerNeeds : e.target.value})         
            }
            break;
            case 'text2':{
                if(journeyData.jwProductBenefits !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwProductBenefits : e.target.value})
            }
            break;
            case 'text3_problem01':{
                if(journeyData.jwAswAssociatedProblemFirst !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwAssociatedProblemFirst : e.target.value})         
            }
            break;
            case 'text3_benefit01':{
                if(journeyData.jwAssociatedBenefitFirst !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwAssociatedBenefitFirst : e.target.value})         
            }
            break;
            case 'text3_problem02':{
                if(journeyData.jwAssociatedProblemSecond !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwAssociatedProblemSecond : e.target.value})         
            }
            break;
            case 'text3_benefit02':{
                if(journeyData.jwAssociatedBenefitSecond !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwAssociatedBenefitSecond : e.target.value})         
            }
            break;
            case 'text3_problem03':{
                if(journeyData.jwAssociatedProblemThird !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwAssociatedProblemThird : e.target.value})         
            }
            break;
            case 'text3_benefit03':{
                if(journeyData.jwAssociatedBenefitThird !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwAssociatedBenefitThird : e.target.value})         
            }
            break;
            case 'text3_problem04':{
                if(journeyData.jwAssociatedProblemFourth !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwAssociatedProblemFourth : e.target.value})         
            }
            break;
            case 'text3_benefit04':{
                if(journeyData.jwAssociatedBenefitFourth !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwAssociatedBenefitFourth : e.target.value})         
            }
            break;

            case 'text4_f1':{
                if(journeyData.jwFirstValuePhrases !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwFirstValuePhrases : e.target.value})
            }
            break;            
            case 'text4_f2':{

                if(journeyData.jwSecondValuePhrases !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwSecondValuePhrases : e.target.value})
            }
            break;            
            case 'text4_f3':{
                if(journeyData.jwThirdValuePhrases !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwThirdValuePhrases : e.target.value})
            }
            break;            
            case 'text4_f4':{
                if(journeyData.jwFourValuePhrases !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwFourValuePhrases : e.target.value})
            }
            break;            
            default:
            break;

         {/*   case 'choice1':{
                setJourneyData({...journeyData, jwPainOpt: choice})
            }
            break;
            case 'choice2':{
                setJourneyData({...journeyData, jwProblemOpt: choice})
            }
            break;
            case 'choice3':{
                setJourneyData({...journeyData, jwNeedsOpt: choice})
            }
           break;
 */}         }
        
    }

    const checkText =(text)=>{

        let allTextInput = document.querySelectorAll(`#${text}`)
        ////let textInput = document.getElementById(text)
        allTextInput.forEach(textInput=>{

        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }


        }) 
    }

    const editText = (text) =>{

        let allTextInput = document.querySelectorAll(`#${text}`)
       //// let textInput = document.getElementById(text)

        allTextInput.forEach(textInput=>{
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }    
        })

    }


    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()
        


    }

    useEffect( () => { LoadJourneyAnswers()}, [] );

    return(
        <div className="journeyFWQQuestionsLayout1">    

        {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>}
        {loading && <LoadingScreen/>}
        {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>}
        {props.activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.redirect}/>}
           

              <div className="journeyQuestionsSaveBtn"  id='saveBtn' onClick={saveAnswers}>
                    Salvar
                </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Quais são os problemas e necessidades do público-alvo?
                        <QuestionsInformation breakLine={true} information="Um problema é uma situação indesejada ou uma dificuldade que o cliente enfrenta e que precisa ser resolvida.Uma necessidade é um desejo ou uma aspiração que o cliente tem e que busca satisfazer.Por exemplo:

                                <br/>Problema: Um cliente que trabalha em um escritório de advocacia está enfrentando um problema de gerenciamento ineficiente de documentos. Ele têm dificuldade em localizar rapidamente documentos relevantes, resultando em atrasos no trabalho, perda de tempo e redução da produtividade da equipe.

                                <br/>Necessidade: O mesmo cliente pode ter a necessidade de melhorar a organização e o acesso aos documentos, a fim de agilizar o fluxo de trabalho e aumentar a eficiência. Ele deseja satisfazer essa necessidade para melhorar a produtividade da equipe e fornecer um serviço mais rápido e eficaz aos clientes.

                                Nesse exemplo, o problema é a situação indesejada de gerenciamento ineficiente de documentos, que causa dificuldades e atrasos no trabalho. A necessidade é a aspiração de melhorar a organização e o acesso aos documentos, visando aumentar a eficiência e a produtividade.
                                "/>                        
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea1' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwCustomerNeeds : '---'} onChange={(e) => changetext(e,'text1')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea1')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea1')}></img>
                        </div>
                    </div>
 {/*                    <div className="journeyFWQQuestionsLayout2">
                        <div className="journeyFWQQuestionsLayout3" style={{marginTop:'0rem'}}>
                            <text className="journeyFWQQuestionText" style={{marginTop:'0.3rem', marginBottom:'-0.5rem'}}>Dor:</text>
                            <div id='painChoice1' className={ (journeyData !== null) ? ((journeyData.jwPainOpt === 1) ? 'journeyQuestionsMultipleChoicesSelected' : 'journeyQuestionsMultipleChoices') : 'journeyQuestionsMultipleChoicesSelected' } onClick={(e) =>changetext(e,'choice1',1)}>
                                Diminuo
                            </div>
                            <div id='painChoice2' className={ (journeyData !== null) ? ((journeyData.jwPainOpt === 2) ? 'journeyQuestionsMultipleChoicesSelected' : 'journeyQuestionsMultipleChoices') : 'journeyQuestionsMultipleChoicesSelected' } onClick={(e) =>changetext(e,'choice1',2)}>
                                Elimino
                            </div>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2" style={{marginTop:'-0.5rem'}}>
                        <div className="journeyFWQQuestionsLayout3">  
                            <text className="journeyFWQQuestionText" style={{marginTop:'0.3rem'}}>Problema:</text>
                            <div id='problemChoice1' className={ (journeyData !== null) ? ((journeyData.jwProblemOpt === 1) ? 'journeyQuestionsMultipleChoicesSelected' : 'journeyQuestionsMultipleChoices') : 'journeyQuestionsMultipleChoicesSelected' } onClick={(e) =>changetext(e,'choice2',1)}>
                                Resolvo total
                            </div>
                            <div id='problemChoice2' className={ (journeyData !== null) ? ((journeyData.jwProblemOpt === 2) ? 'journeyQuestionsMultipleChoicesSelected' : 'journeyQuestionsMultipleChoices') : 'journeyQuestionsMultipleChoicesSelected' } onClick={(e) =>changetext(e,'choice2',2)}>
                                Resolvo parcialmente
                            </div>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2" style={{marginTop:'-0.5rem'}}>
                        <div className="journeyFWQQuestionsLayout3">
                            <text className="journeyFWQQuestionText" style={{marginTop:'0.3rem'}}>Necessidade:</text>
                            <div id='needsChoice1' className={ (journeyData !== null) ? ((journeyData.jwNeedsOpt === 1) ? 'journeyQuestionsMultipleChoicesSelected' : 'journeyQuestionsMultipleChoices') : 'journeyQuestionsMultipleChoicesSelected' } onClick={(e) =>changetext(e,'choice3',1)}>
                                Satisfaço
                            </div>
                            <div id='needsChoice2' className={ (journeyData !== null) ? ((journeyData.jwNeedsOpt === 2) ? 'journeyQuestionsMultipleChoicesSelected' : 'journeyQuestionsMultipleChoices') : 'journeyQuestionsMultipleChoicesSelected' } onClick={(e) =>changetext(e,'choice3',2)}>
                                Não satisfaço
                            </div>
                        </div>
                    </div>
*/}                    <div className="journeyFWQQuestionsLayout2" style={{marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText">Quais benefícios o produto ou serviço entrega ao cliente? 
                        <QuestionsInformation haveTopics={true} breakLine={true} information="É importante entender a diferença entre benefícios e características de um produto ou solução na hora de montar a proposta de valor. 

                                <br/>Características referem-se às especificações técnicas, recursos ou aspectos tangíveis de um produto ou serviço. São detalhes sobre o que o produto faz ou como ele é construído. Por exemplo, no caso do sistema de gerenciamento de documentos, as características podem incluir armazenamento em nuvem, capacidade de pesquisa avançada, colaboração em tempo real, entre outros.

                                <br/>Por outro lado, benefícios são os resultados ou vantagens que o cliente obtém ao utilizar o produto ou serviço. Eles descrevem as melhorias ou satisfações que os clientes experimentam em relação às suas necessidades, problemas ou desejos. No exemplo do sistema de gerenciamento de documentos, alguns benefícios podem ser: 

                                <br/>1 - Economia de tempo:-> Acesso rápido e fácil a documentos importantes economiza tempo valioso da equipe ao localizar informações relevantes rapidamente.

                                <br/>ou e:

                                <br/>2 - Mobilidade:-> Acesso remoto aos documentos permite que os advogados trabalhem em casos e projetos em qualquer lugar, oferecendo flexibilidade e produtividade fora do escritório."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea2' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwProductBenefits : '---'} onChange={(e) => changetext(e,'text2')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea2')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea2')}></img>
                        </div>
                    </div>



                    <div className="journeyFWQQuestionsLayout2" style={{marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText">Associe os problemas e necessidades com os benefícios que seu produto ou serviço entrega ao cliente.
                        <QuestionsInformation information="Associe os benefícios que seu produto ou serviço oferece aos problemas e necessidades específicas que ele pode resolver. Certifique-se de que seus benefícios sejam diretamente relevantes e abordem as necessidades e desejos do seu público-alvo. "/>
                        </text><div style={{display: 'flex', marginLeft: '0px', justifyContent: 'space-around', fontSize: '18px', fontWeight: 'bold'}}><p>Problema/Necessidade</p><p>Beneficio</p></div>
                        <div className="journeyFWQQuestionsLayout3" >
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                        <div>
                            <div>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwAssociatedProblemFirst : '---'} onChange={(e) => changetext(e,'text3_problem01')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwAssociatedProblemSecond : '---'} onChange={(e) => changetext(e,'text3_problem02')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                            </div>

                            <div> 
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwAssociatedProblemThird : '---'} onChange={(e) => changetext(e,'text3_problem03')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwAssociatedProblemFourth : '---'} onChange={(e) => changetext(e,'text3_problem04')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                            </div>
                            
                        </div>
                                    
                        <div> 
                              
                            <div>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwAssociatedBenefitFirst : '---'} onChange={(e) => changetext(e,'text3_benefit01')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwAssociatedBenefitSecond : '---'} onChange={(e) => changetext(e,'text3_benefit02')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                            </div>

                            <div> 
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwAssociatedBenefitThird : '---'} onChange={(e) => changetext(e,'text3_benefit03')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwAssociatedBenefitFourth : '---'} onChange={(e) => changetext(e,'text3_benefit04')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                            </div>

                        </div>
                                                
                        
                        
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea3')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea3')}></img>
                        </div>
                    </div>



                    <div className="journeyFWQQuestionsLayout2" style={{marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText">Crie frases que podem ser usadas como propostas de valor da sua empresa.
                        <QuestionsInformation information="A proposta de valor deve capturar o que torna o produto ou serviço único, relevante e atraente para os clientes. Ela destaca os benefícios, vantagens e soluções que a oferta proporciona, ressaltando como atende às necessidades, desejos ou problemas do público-alvo.

                            Uma proposta de valor eficaz deve ser clara, concisa e direcionada ao público-alvo específico. Ela deve se concentrar nos resultados que os clientes podem obter ao utilizar a oferta, ao invés de se ater apenas às características ou funcionalidades do produto ou serviço.
                            "/>
                        </text>
                        <div className="journeyFWQQuestionsLayout4" style={{dislpay: 'flex', flexDirection: 'column'}}>
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea4' className="journeyFWQTextArea" style={{height: '2.8rem'}} placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwFirstValuePhrases : '---'} onChange={(e) => changetext(e,'text4_f1')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                            
                            <textarea id='journeyFWQtextArea4' className="journeyFWQTextArea" style={{height: '2.8rem'}} placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwSecondValuePhrases : '---'} onChange={(e) => changetext(e,'text4_f2')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                                                        
                            <textarea id='journeyFWQtextArea4' className="journeyFWQTextArea" style={{height: '2.8rem'}} placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwThirdValuePhrases : '---'} onChange={(e) => changetext(e,'text4_f3')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                                                        
                            <textarea id='journeyFWQtextArea4' className="journeyFWQTextArea" style={{height: '2.8rem'}} placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwFourValuePhrases : '---'} onChange={(e) => changetext(e,'text4_f4')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>




                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea4')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea4')}></img>
                        </div>
                    </div>


                </div>
    )
}

export default ValuePropositionQuestions;