import UserStore from './../store/userStore';
import { colors, AppArea } from "../components/Styles";
import React, { useState } from 'react';
import axios from 'axios';
import Background from "./../assets/background_purple.png";
import { useEffect } from 'react';
import LoadingScreen from '../../src/components/loadingScreen/LoadingScreen';
import styles from './RecoverPassword.module.css';
import LoginContainer from '../components/loginContainer/LoginContainer';
import LoginButton from '../components/loginButton/LoginButton';


var md5 = require('md5');

const OTPCodePage = ({hash}) => {

    const hashcode = hash.substring(1)
    const [otpCode, setOtpCode] = useState(-1)
    const [hasValidHash, setHasValidHash] = useState(true)
    const [canChangePassword, setCanChangePassword] = useState(false)
    const [userId, setUserId] = useState(-1)
    const [userToken, setUserToken] = useState('')
    const [ changePasswordData, setChangePasswordData ] = useState( { newPassword: '', confirmNewPassword: '' } );
    const [pageState, setPageState] = useState(-1) 
    

    useEffect(()=>{
        const checkHash = async() =>{
                    setHasValidHash(true)
            console.log(hashcode)
            await axios.get('https://3mvqynumyw5g6nuejzsz3neojm0gasen.lambda-url.us-east-2.on.aws/', {params: 
              {
                hashcode: hashcode
              }
            }

            ).then(resp=>{
                
                
                
                console.log(resp.data)
                if (resp.data.products[0].hash!=='') {


                    setOtpCode(resp.data.products[0].otp)
                    setUserId(resp.data.products[0].id)
                    setUserToken(resp.data.products[0].token)
                    setPageState(1)
                }else{
                    setHasValidHash(false)
                    setPageState(0)

                }
            
            
            }).catch(err=>{
                setHasValidHash(false)
                setPageState(0)

            }
            )


        }
        checkHash()


    }, [hashcode])

    const [loading, setLoading] = useState(false);
    console.log(hash)

    
    const changePasswordInputHandler = ( event, input ) =>
    {
        setChangePasswordData( { ...changePasswordData, [ input ]: event.target.value } );
    }

    const changePassOTP = async() =>{
        setLoading(true)
        console.log(userId)
        console.log(userToken)
        console.log(changePasswordData)


        const resp =  await axios.put( 'https://qmijv5wuvfykeevlch5isj75wq0pwsmw.lambda-url.us-east-2.on.aws/', { company: userId, token: userToken, newPassword: md5(changePasswordData.newPassword) } ).catch(err=>{console.log(err); setLoading(false)});

        if( resp.data.hasOwnProperty( 'result' ) )
        {
            switch( resp.data.result )
            {
                case 0:



                    await axios.delete('https://jwhb5mf72twrjgrzx6qzhsvziu0hztbb.lambda-url.us-east-2.on.aws/', { data: { id:userId} })//tem que deletar o codigo otp usado e o hash
                    alert( 'Senha alterada com sucesso.' ); 


                    window.location.replace('/')



                    break;
                case 2:
                    alert( 'Senha atual não confere.' );
                    break;
                default:
                    alert('Erro na alteração da senha, tente novamente mais tarde.')
                    break;    
            }

                    setLoading(false)


        }



    }

    const checkOTPCode = async() =>
    {
        
        setLoading(true);
        var localOtpcode = document.getElementsByName( "otpcode" )[ 0 ].value;
        if (Number(localOtpcode) === otpCode) {

    
            setCanChangePassword(true)


        }

        else{

            alert('Código Inválido')
            console.log(typeof Number(localOtpcode))
            console.log(typeof otpCode)
        }

        setLoading(false)




    };

    return (
        <div id="app" style={{display:'flex',flexDirection:'row',width:'100%',height:'100%'}} >  

        
                <LoginContainer id="quizContainer" bg={Background}  > 
                    <AppArea id="login">
                        
                        {pageState<0 && <LoadingScreen/>}
                        
                        {pageState===1 && !canChangePassword && hasValidHash &&
                        <div className="login-area">
                            <div className="form-box">
                                <div className="header-form">
                                    <h4 className="text-primary text-center"><i className="fa fa-cogs" style={{fontSize:"110px", color:colors.primary}}></i></h4>
                                    <div className="image" />                                    
                                </div>
                                <div className="body-form">     

                                <p className={styles.title}>Insira o código recebido por email</p>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fa fa-cog"></i></span>
                                        </div>
                                        <input name='otpcode' type="text" className="form-control" placeholder="Código" />
                                    </div>

                                    <text className='modelSettingsErrorMsg' 
                                            style={{fontSize:'1rem',fontWeight:'600'}}
                                            id='wrongLogin'>Usuario não encontrado.<br/> Código inválido.</text>
                                    {!loading && <LoginButton onClick={ checkOTPCode }>CONFIRMAR</LoginButton>}       
                                    {loading && <LoginButton disabled="disabled">CARREGANDO...</LoginButton>}   
                                    {/* <div className="social">
                                        <a href="/"><i className="fab fa-facebook" style={{color:colors.primary}}></i></a>
                                        <a href="/"><i className="fab fa-twitter-square" style={{color:colors.primary}}></i></a>
                                        <a href="/"><i className="fab fa-google" style={{color:colors.primary}}></i></a>
                                    </div> */}
                                </div>
                                
                                <text className='loginInfoText'>*Caso não tenha recebido o código, solicite novamente</text>
                            </div>
                        </div> 
                        
                        }



                        {pageState===1 && canChangePassword && hasValidHash &&
                        
                        
                        
                        <div className="login-area">
                        <div className="form-box">
                            <div className="header-form">
                                <h4 className="text-primary text-center"><i className="fa fa-key" style={{fontSize:"110px", color:colors.primary}}></i></h4>
                                <div className="image" />                                    
                            </div>
                            <div className="body-form">     
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-lock"></i></span>
                                    </div>
                                    <input name='passwordNew' type="password" className="form-control" onChange={ ( event ) => changePasswordInputHandler( event, 'newPassword' ) } placeholder="Nova senha" />
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-lock"></i></span>
                                    </div>
                                    <input name='passwordNewConfirm' type="password" className="form-control" onChange={ ( event ) => changePasswordInputHandler( event, 'confirmNewPassword' ) } placeholder="Confirme a Nova senha" />
                                </div>      

            
                                {!loading && <LoginButton onClick={ changePassOTP }>ALTERAR SENHA</LoginButton>}       
                                {loading && <LoginButton disabled="disabled">CARREGANDO...</LoginButton>}   
                                {/* <div className="social">
                                    <a href="/"><i className="fab fa-facebook" style={{color:colors.primary}}></i></a>
                                    <a href="/"><i className="fab fa-twitter-square" style={{color:colors.primary}}></i></a>
                                    <a href="/"><i className="fab fa-google" style={{color:colors.primary}}></i></a>
                                </div> */}
                            </div>
                            
                            <text className='loginInfoText'>*Libere aqui seu cadastro</text>
                        </div>
                    </div> 
                            
                            
                            
                            }

                        {!hasValidHash && pageState===0 &&
                        <>
                                                    
                        
                        
                        <div className="login-area">
                        <div className="form-box">
                            <div className="header-form">
                                <h4 className="text-primary text-center"><i className="fa fa-exclamation-triangle" style={{fontSize:"110px", color:colors.primary}}></i></h4>
                                <div className="image" />                                    
                            </div>
                            <div className="body-form">     
                            <h2>Link Inválido</h2>

            
                         <LoginButton onClick={ ()=>{window.location.replace('/')} }>IR PARA PAGINA INICIAL</LoginButton>    
                     
                                {/* <div className="social">
                                    <a href="/"><i className="fab fa-facebook" style={{color:colors.primary}}></i></a>
                                    <a href="/"><i className="fab fa-twitter-square" style={{color:colors.primary}}></i></a>
                                    <a href="/"><i className="fab fa-google" style={{color:colors.primary}}></i></a>
                                </div> */}
                            </div>
                            
                            <text className='loginInfoText'>*Página não encontrada</text>
                        </div>
                    </div> 
                            
                            
                        </>
                        }


                        

                    </AppArea>                  
                </LoginContainer>
                
                
                
                                                   
        </div>
    );
}

export default OTPCodePage;