import React from 'react'
import styles from './MobileSearchGroups.module.sass'
const MobileSearchGroups = ({closeFn, selectedGroup, userGroups, changeSelectedGroup, openGroupsSearch}) => {

    console.log(selectedGroup);console.log(userGroups)






  return (
    <div className={styles.mobileSearchGroups}>
        <div className={styles.outerContent} onClick={closeFn}>

        </div>
        <div className={styles.interContent}>
            <div className={styles.titleItems}>
                <p>Grupos</p>
                <span onClick={closeFn}>x</span>

            </div>



            <div className={styles.search}>
                <button className={styles.searchButton} onClick={()=>{openGroupsSearch(); closeFn()}}>
                    Procurar Grupos
                </button>
            </div>

            <div className={styles.allGroups}>
                               {userGroups && userGroups.map(group=>(
                   <div className={styles.userGroup} id={Number(selectedGroup ? selectedGroup.groupId : userGroups[0].groupId)===Number(group.groupId) && /**/styles.isSelected} onClick={()=>{changeSelectedGroup(group); closeFn()}}>
                        <span>{String.fromCodePoint(parseInt (group.groupEmoji, 10))} </span>

                        <h2>{group.groupTitle}</h2>
                    </div>



               ))}
            </div>
        </div>


    </div>
  )
}

export default MobileSearchGroups