import React from 'react'
import styles from './VideosList.module.sass'
import { convertToSemanticTimeFormat } from '../../../../../../functions/convertToSemanticTime'
import SubGroup from '../subGroup/SubGroup'
const VideosList = ({videosArray, selectStep, subGroups, isSubGroup}) => {

  const getVideosFromSubGroup = ({array, subGroupId}) =>{
    console.log('TYPE');console.log(typeof array)



  }
  console.log(videosArray)
  return (
    <div className={styles.videosList}>

      {videosArray && videosArray.map(
          video=>( <>
          
          
          {isSubGroup && <div className={styles.video} onClick={()=>selectStep({group: video.sGroup, step: video.sStep, action: `Abriu ${video.sAnswers ? 'Perguntas' : 'Vídeo'} - ${video.sTitle}`, botStep: video.botStep})}>
            <div className={styles.leftContent}>
                {!video.sHaveWatched && <div className={styles.videoIcon}></div>}   
                {!!video.sHaveWatched && <div className={styles.watchedVideoIcon}><div className={styles.checkedVideo}><i className="fa fa-check"/></div></div>}
                <div className={styles.videoInfo}><p>{video.sTitle}</p><span>{convertToSemanticTimeFormat(video.sDuration)}</span></div>
    

            </div>
            <div className={styles.rightContent}>
                {!!video.sHaveWatched &&<div className={styles.checkedbox}> <i className="fa fa-check"/></div>}
                {!video.sHaveWatched && <div className={styles.checkbox}></div> }


            </div>
          
      </div>}
          {!isSubGroup && !video.sSubGroup &&<div className={styles.video} onClick={()=>selectStep({group: video.sGroup, step: video.sStep})}>
            <div className={styles.leftContent}>
                {!video.sHaveWatched && <div className={styles.videoIcon}></div>}   
                {!!video.sHaveWatched && <div className={styles.watchedVideoIcon}><div className={styles.checkedVideo}><i className="fa fa-check"/></div></div>}
                <div className={styles.videoInfo}><p>{video.sTitle}</p><span>{convertToSemanticTimeFormat(video.sDuration)}</span></div>
    

            </div>
            <div className={styles.rightContent}>
                {!!video.sHaveWatched &&<div className={styles.checkedbox}> <i className="fa fa-check"/></div>}
                {!video.sHaveWatched && <div className={styles.checkbox}></div> }


            </div>
          
      </div>}
      
      
      {!isSubGroup && !!video.sSubGroup && <SubGroup selectStep={selectStep} subGroups={subGroups} videos={video}/>}
      </>




          )



      )}
    </div>
  )
}

export default VideosList