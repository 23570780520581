var UserStore = ( () => {
    const isLogged = () =>
    {
        var logged = false;

        if( localStorage.getItem( 'logged' ) == null )
        {
            logged = false;
        }
        else
        {
            logged =( localStorage.getItem( 'logged' ).localeCompare( 'true' ) === 0 );
        }

        return logged;
    };

    const setLogged = ( newState ) => {
        localStorage.setItem( 'logged', newState );
    };

    const getToken = () => {
        return localStorage.getItem( 'token' );
    };

    const setToken = ( newToken ) => {
        localStorage.setItem( 'token', newToken );
    };

    const getUserEmail = () => {
        return localStorage.getItem( 'email' );
    };

    const setUserEmail = ( newUserEmail ) => {
        localStorage.setItem( 'email', newUserEmail );
    };
    const getUserName = () => {
        return localStorage.getItem( 'name' );
    };

    const setUserName = ( newUserName ) => {
        localStorage.setItem( 'name', newUserName );
    };
    const getUserId = () => {
        return parseInt( localStorage.getItem( 'userId' ) );
    };

    const setUserId = ( newUserId ) => {
        localStorage.setItem( 'userId', newUserId );
    };

    const getMyModel = () => {
        return localStorage.getItem( 'myModel' );
    };

    const setMyModel = ( newModel ) => {
        localStorage.setItem( 'myModel', newModel );
    };

    const getAccType = () => {
        return localStorage.getItem( 'accType' );
    };

    const setAccType = ( newAccType ) => {
        localStorage.setItem( 'accType', newAccType );
    };

    const getAccess = () =>{
        return localStorage.getItem( 'isFirstAccess' );   
    };
    
    const setAccess = ( access ) =>{
        localStorage.setItem( 'isFirstAccess', access )
    }
    const getWelcomeState = () =>{
        return localStorage.getItem( 'isFirstWelcomeState' );   
    };
    
    const setWelcomeState = ( welcomeState ) =>{
        localStorage.setItem( 'isFirstWelcomeState', welcomeState )
    }    

    const getUserPhone = () => {
        return localStorage.getItem( 'phone' );
    };

    const setUserPhone = ( newUserEmail ) => {
        localStorage.setItem( 'phone', newUserEmail );
    };


    const getUserFirstAccess = () => {
        return localStorage.getItem( 'firstAccess' );
    };

    const setUserFirstAccess = ( firstAccess ) => {
        localStorage.setItem( 'firstAccess', firstAccess );
    };
    const getFirstPage = () => {
        return localStorage.getItem( 'firstPage' );
    };

    const setFirstPage = ( firstPage ) => {
        localStorage.setItem( 'firstPage', firstPage );
    };
    const getUserPlanDays = () => {
        return localStorage.getItem( 'userPlanDays' );
    };

    const setUserPlanDays = ( userPlanDays ) => {
        localStorage.setItem( 'userPlanDays', userPlanDays );
    };
    const getHideIntro = () => {
        return localStorage.getItem( 'hideIntro' );
    };

    const setHideIntro = ( hideIntro ) => {
        localStorage.setItem( 'hideIntro', hideIntro );
    };    
    const setSelectedPageStore = ( page ) =>{ localStorage.setItem( 'selectedPage', page ) }; const getSelectedPageStore = () =>{return localStorage.getItem( 'selectedPage' )}
    const setSelectedMenuStore = ( menu ) =>{ localStorage.setItem( 'selectedMenu', menu ) }; const getSelectedMenuStore = () =>{return localStorage.getItem( 'selectedMenu' )}    
    return{ isLogged: isLogged,
        setLogged: setLogged,
        getToken: getToken,
        setToken: setToken,
        getUserId: getUserId,
        setUserId: setUserId,
        getUserEmail: getUserEmail,
        setUserEmail: setUserEmail,
        getMyModel: getMyModel,
        setMyModel: setMyModel,
        getAccType: getAccType,
        setAccType: setAccType,
        getUserName: getUserName,
        setUserName: setUserName,
        getAccess: getAccess,
        setAccess: setAccess,
        getWelcomeState: getWelcomeState,
        setWelcomeState: setWelcomeState,
        getUserPhone: getUserPhone,

        setUserPhone: setUserPhone,
        getUserFirstAccess: getUserFirstAccess,
        setUserFirstAccess: setUserFirstAccess,
        getFirstPage: getFirstPage,
        setFirstPage: setFirstPage,
        getUserPlanDays: getUserPlanDays,
        setUserPlanDays: setUserPlanDays,     
        getHideIntro: getHideIntro,
        setHideIntro: setHideIntro, setSelectedPageStore, getSelectedPageStore, setSelectedMenuStore, getSelectedMenuStore


    };
} )();
  
export default UserStore;