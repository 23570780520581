import React,{useState} from "react";
import "../settings/MyAccount.css";
import UserStore from '../../store/userStore';
import axios from 'axios';
import { useEffect } from 'react';
import profile from "../../assets/icons/userQuestionsJourney.png";
import { StringMask } from '../library/StringMasks';   
import SupportIcon from "../supportIcon/SupportIcon";
var md5 = require('md5');

const MyAccount = () =>
{
    const [ changePasswordData, setChangePasswordData ] = useState( { currentPassword: '', newPassword: '', confirmNewPassword: '' } );
    const [ companyData, setCompanyData ] = useState
    ( {
        respFirstName: 'Carregando',
        respLastName: 'Carregando',
        email: 'Carregando',
        identity: 'Carregando',
        companyName: 'Carregando',
        phone: 'Carregando' 
    } );

    const getCompanyData = async() =>
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();

        const tResp = await axios.get( 'https://syjbx5wxnjzcnp3p3rkjdxrj7e0yswil.lambda-url.us-east-2.on.aws/', { params: { company: userId, token: token } } );

        if( tResp.data.companyData )
        {
            setCompanyData
            ( {
                respFirstName: tResp.data.companyData[ 0 ].respFirstName,
                respLastName: tResp.data.companyData[ 0 ].respLastName,
                email: tResp.data.companyData[ 0 ].email,
                identity: StringMask( tResp.data.companyData[ 0 ].identity, { type: 'CPFCNPJ' }, '' ),
                companyName: tResp.data.companyData[ 0 ].companyName,
                phone: StringMask( tResp.data.companyData[ 0 ].phone, { type: 'phone' }, '' )
            } );
        }
    }

    console.log('aaaaaaaa'); console.log(UserStore.getUserName())

    const editCompanyData = async() =>
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();

        const resp = await axios.put( 'https://ruiva63zdhaid5rud4keddxgiq0cxftk.lambda-url.us-east-2.on.aws/', { company: userId, token: token, companyData: companyData } );

        if( resp.data.hasOwnProperty( 'result' ) )
        {
            switch( resp.data.result )
            {
                case 0:
                    alert( 'Dados alterados com sucesso.' );
                    break;
                default:
                    alert('Erro na alteração dos dados, tente novamente mais tarde.')
                    break;    
            }
        }
        else
        {
            alert('Erro na alteração dos dados, tente novamente mais tarde.')
        }
    }

    const changePassword = async() =>
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();

        if( validateNewPasswordInputs() )
        {
            const resp = await axios.put( 'https://ok4aa4vfhxxsro7mamcakspexq0gsxzd.lambda-url.us-east-2.on.aws/', { company: userId, token: token, currentPassword: md5( changePasswordData.currentPassword ), newPassword: md5( changePasswordData.newPassword ) } );

            if( resp.data.hasOwnProperty( 'result' ) )
            {
                switch( resp.data.result )
                {
                    case 0:
                        alert( 'Senha alterada com sucesso.' );
                        break;
                    case 2:
                        alert( 'Senha atual não confere.' );
                        break;
                    default:
                        alert('Erro na alteração da senha, tente novamente mais tarde.')
                        break;    
                }
            }
            else
            {
                alert('Erro na alteração da senha, tente novamente mais tarde.')
            }
        }
    }

    const companyInputHandler = ( event, input ) =>
    {
        switch( input )
        {
            case 'respFirstName':
            case 'respLastName':
            case 'email':
            case 'companyName':
                setCompanyData( { ...companyData, [ input ]: event.target.value } );
                break;
            case 'identity':            
                setCompanyData( { ...companyData, identity: StringMask( event.target.value, { type: 'CPFCNPJ' }, '' ) } );
                break;
            case 'phone':
                setCompanyData( { ...companyData, phone: StringMask( event.target.value, { type: 'phone' }, '' ) } );
                break;
            default:
        }
    }

    const changePasswordInputHandler = ( event, input ) =>
    {
        setChangePasswordData( { ...changePasswordData, [ input ]: event.target.value } );
    }

    var UploadImage = event =>
    {
        if( event.target.files && event.target.files[ 0 ] )
        {
            var image = document.getElementById( 'profileImg' );
	        image.src = URL.createObjectURL( event.target.files[ 0 ] );
        }
    }

    const validateNewPasswordInputs = () =>
    {
        var missmatchPassword = document.getElementById('passwordNewError');

        if( changePasswordData.newPassword.length < 8 )
        {
            missmatchPassword.style.display = 'block';
            missmatchPassword.innerHTML = 'Favor inserir uma senha com mais de 8 caracteres';
            return false;
        }
        else if( changePasswordData.newPassword !== changePasswordData.confirmNewPassword )
        {
            missmatchPassword.style.display = 'block';
            missmatchPassword.innerHTML = 'As senhas não coincidem';
            return false;
        }
        else
        {
            missmatchPassword.style.display = 'none';
            missmatchPassword.innerHTML = '';
            return true;
        }
    }
    
    useEffect( () => { 
        const boardContent = document.querySelector('.BoardContent')
        boardContent.style.height = '80vh'



        getCompanyData();
    
    }, [] );

    return(
        <div className="modelSettings">

            <SupportIcon/>
            <div className="modelSettingsDiv">
                <div style={{display:'flex', gap:'15rem'}}>
                    <div style={{display:'flex',flexDirection:'column',gap:'0.5rem'}}>
                        <text className="modelSettingsTitle">Minha conta</text>
                        <text className="modelSettingsSubtitle">Atualize os detalhes da sua conta aqui</text>
                    </div>
                </div>
                <div style={{display:'flex', gap:'1rem'}}>
                    <div className="modelSettingsWhiteDiv" style={{display:'flex',flexDirection:'column', gap:'2rem'}}>
                        <div className='myAccountInputFields'>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="modelSettingsWhiteDivText">Nome</text>
                                <input className="modelSettingsWhiteDivInput" onChange={ ( event ) => companyInputHandler( event, 'respFirstName' ) } value={ ( companyData !== null ) ? companyData.respFirstName : '...' }/>
                            </div>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="modelSettingsWhiteDivText">Sobrenome</text>
                                <input className="modelSettingsWhiteDivInput" onChange={ ( event ) => companyInputHandler( event, 'respLastName' ) } value={ ( companyData !== null ) ? companyData.respLastName : '...' }/>
                            </div>
                        </div>
                        <div className='myAccountInputFields'>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="modelSettingsWhiteDivText">E-mail</text>
                                <input className="modelSettingsWhiteDivInput" onChange={ ( event ) => companyInputHandler( event, 'email' ) } value={ ( companyData !== null ) ? companyData.email : '...' }/>
                            </div>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="modelSettingsWhiteDivText">CPF / CNPJ</text>
                                <input  type='text' className="modelSettingsWhiteDivInput" id='CPFinput' maxLength='17' onChange={ ( event ) => companyInputHandler( event, 'identity' ) }
                                    value={ ( companyData !== null ) ? companyData.identity : '...' }/>
                            </div>
                        </div>
                        <div className='myAccountInputFields'>

                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="modelSettingsWhiteDivText">Nome do negócio</text>
                                <input className="modelSettingsWhiteDivInput" onChange={ ( event ) => companyInputHandler( event, 'companyName' ) } value={ ( companyData !== null ) ? companyData.companyName : '...' }/>
                            </div>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="modelSettingsWhiteDivText">Telefone</text>
                                <input type='text' className="modelSettingsWhiteDivInput" id='Telinput' maxLength='16' onChange={ ( event ) => companyInputHandler( event, 'phone' ) }
                                    value={ ( companyData !== null ) ? companyData.phone : '' }/>
                            </div>
                        </div>
                        <div className="modelSettingsSaveChangesBtn" onClick={ editCompanyData }>
                            Salvar Mudanças
                        </div>
                    </div>
                    {/* <div className="modelSettingsWhiteDiv" style={{display:'flex', flexDirection:'column',alignItems:'center', paddingTop:'1.5rem', gap:'0.8rem'}}>
                        <text className="modelSettingsWhiteDivText" style={{fontSize:'1rem'}}>Imagem de Perfil</text>
                        <div style={{width:'6rem', height:'6rem',borderRadius:'5rem'}}>
                            <img id='profileImg' alt="" style={{width:'6rem',height:'6rem',borderRadius:'5rem'}} src={profile}/>
                        </div>
                        <div className="modelSettingsPhotoBtn" style={{paddingTop:'0.5rem'}} >
                            <label for= 'inputTag' style={{cursor:'pointer'}}>
                            Atualizar foto
                            <input name='inputTag' id='inputTag' type='file' style={{display:'none'}} 
                                    onChange={UploadImage}/>
                            </label>
                        </div>
                    </div> */}
                </div>
                <div className="modelSettingsWhiteDiv" style={{display:'flex',flexDirection:'column', gap:'1rem',marginTop:'1rem'}}>
                    <div className='passwordInputs' style={{display:'flex', gap:'1rem'}} >
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <text className="modelSettingsWhiteDivText">Senha atual</text>
                            <input type ='password' id ='currentPasswordInput'className="modelSettingsWhiteDivInput" onChange={ ( event ) => changePasswordInputHandler( event, 'currentPassword' ) } value={ changePasswordData.currentPassword } />
                            <text id="passwordMatch" className="modelSettingsErrorMsg">Senha incorreta</text>
                        </div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <text className="modelSettingsWhiteDivText">Nova senha</text>
                            <input type ='password' id="passwordNew" className="modelSettingsWhiteDivInput" onChange={ ( event ) => changePasswordInputHandler( event, 'newPassword' ) } value={ changePasswordData.newPassword }/>
                        </div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <text className="modelSettingsWhiteDivText">Confirme a senha</text>
                            <input type ='password' id="passwordNewConfirm" className="modelSettingsWhiteDivInput" onChange={ ( event ) => changePasswordInputHandler( event, 'confirmNewPassword' ) } value={ changePasswordData.confirmNewPassword }/>
                        </div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                        <text className="modelSettingsWhiteDivText"></text>
                            <div className="modelSettingsChangePassword" onClick={ changePassword }>Alterar Senha</div>
                        </div>
                    </div>
                    <text id="passwordNewError" className="modelSettingsErrorMsg" style={ { marginTop:'-0.5rem' } }></text>
                </div>
            </div>
        </div>
    )
}

export default MyAccount;