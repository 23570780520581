import { colors } from "../Styles";
import React from 'react';
import { CProgress, CProgressBar } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'
import { ModelsNames, stepOpt } from "../library/QuizData"
import { useEffect } from 'react';
import { InfoIconImg } from '../imgsLoader/LoadIconImgs'; 
import { useState } from "react";
import { botconversaWebhook } from "../../functions/botconversaWebhook";

const ModelRecForm = ( props ) => {

        
    const pageLocale = 'Diagnostico'
    useEffect(()=>{
    
        botconversaWebhook({locale: pageLocale, action: 'Abriu Questionario'})
    })    

    //TODO: Criar matrix de desição de forma dinamica baseado no step
    var decisionMatrix = props.MyBusinessModel.selectedOptions;
    var modelsToIgnore = [ 1, 2, 6, 14, 21, 23, 25, 26, 27, 28, 30, 33, 34, 35, 36, 37, 40, 42, 43, 44, 45, 47, 48 ];

    var resultMatrix = [ [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ],
                         [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ],
                         [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ],
                         [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ],
                         [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ], [ 0, 0 ] ];

    var resultMatrixPerPage = [
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
                                [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ]
                            ];
                         
    var currentStep = 0;
    var firstIndex = 0;
    var firstWeight = 1;
    var secondIndex = 1;
    var secondWeight = 1;
    var thirdIndex = 2;
    var thirdWeight = 1;
    
    //TODO: Validar valores fora do vetor menor que 3 e maior que 15
    const loadCheckBoxOpt = () =>
    {
        const StepProgressId = document.getElementById( "StepProgressId" );
        const QuestionTitleId = document.getElementById( "QuestionTitleId" );
        const NumberOfOptionsId = document.getElementById( "NumberOfOptionsId" );
        const StepProgressBarId = document.getElementById( "StepProgressBarId" );
        const option = document.getElementsByName( "option" );

        StepProgressId.innerHTML = 'Recomendação de modelo para seu negócio - ' + Math.round( ( currentStep * 100 ) / 15 ) + "% concluído";
        StepProgressBarId.style.width= ( currentStep * 100 ) / 15 + "%";

        for( let index = 0; index < option.length; index++ )
        {
            option[ index ].hidden = true;
            option[ index ].childNodes[ 0 ].childNodes[ 0 ].childNodes[ 0 ].checked = false;
        }

        for( let index = 0; index < ( stepOpt[ currentStep ].length - 1 ); index++ )
        {
            option[ index ].hidden = !stepOpt[ currentStep ][ index + 1 ][ 0 ].title.localeCompare( "" );
            option[ index ].childNodes[ 0 ].childNodes[ 0 ].childNodes[ 0 ].checked = ( decisionMatrix[ currentStep ][ index ] === 1 );
            option[ index ].childNodes[ 0 ].childNodes[ 0 ].childNodes[ 1 ].childNodes[ 0 ].innerHTML = stepOpt[ currentStep ][ index + 1 ][ 0 ].title;
            option[ index ].childNodes[ 0 ].childNodes[ 0 ].childNodes[ 1 ].childNodes[ 1 ].style.display = ( !stepOpt[ currentStep ][ index + 1 ][ 0 ].desc.localeCompare( "" ) ) ? 'none' : '';
            option[ index ].childNodes[ 0 ].childNodes[ 0 ].childNodes[ 1 ].childNodes[ 2 ].innerHTML = stepOpt[ currentStep ][ index + 1 ][ 0 ].desc;
        }
        
        QuestionTitleId.innerHTML = stepOpt[ currentStep ][ 0 ][ 0 ];
        NumberOfOptionsId.innerHTML = "Escolha até " + stepOpt[ currentStep ][ 0 ][ 1 ] + " opções abaixo.";
        StepProgressId.innerHTML = 'Recomendação de modelo para seu negócio - ' + Math.round( ( currentStep * 100 ) / 15 ) + "% concluído";

        enableDisableQuizOpt();
    };

    const resultCalculation = () =>
    {
        const option = document.getElementsByName( "option" );

        for( let index = 0; index < option.length; index++ )
        {
            option[ index ].hidden = true;
        }
        
        for( var modelIndex = 0; modelIndex < resultMatrixPerPage[ 0 ].length; modelIndex++ )
        {
            resultMatrix[ modelIndex ][ 0 ] = 0;
            resultMatrix[ modelIndex ][ 1 ] = modelIndex;

            if( ( decisionMatrix[ 0 ][ 0 ] === 1 ) 
                || ( ( decisionMatrix[ 0 ][ 3 ] === 1 || decisionMatrix[ 0 ][ 6 ] === 1 ) && !modelsToIgnore.includes( modelIndex ) ) )
            {
                for( let step = 0; step < resultMatrixPerPage.length; step++ )    
                {
                    resultMatrix[ modelIndex ][ 0 ] += resultMatrixPerPage[ step ][ modelIndex ];
                }
            }
        }

        resultMatrix.sort( ( a, b ) => b[ 0 ] - a[ 0 ] );
        firstIndex = resultMatrix[ 0 ][ 1 ];
        firstWeight = ( resultMatrix[ 0 ][ 0 ] === 0 ) ? 1 : resultMatrix[ 0 ][ 0 ];
        secondIndex = resultMatrix[ 1 ][ 1 ];
        secondWeight = resultMatrix[ 1 ][ 0 ];
        thirdIndex = resultMatrix[ 2 ][ 1 ];
        thirdWeight = resultMatrix[ 2 ][ 0 ];

        var secondModelFit =  ( secondWeight * 100 ) / firstWeight;
        var thirdModelFit =  (  thirdWeight * 100 ) / firstWeight;
        props.setRecModelsByQuiz( { First: { modelId: firstIndex, modelName: ModelsNames[ firstIndex ], modelFit: 100 },
                                    Second: { modelId: secondIndex, modelName: ModelsNames[ secondIndex ], modelFit: secondModelFit.toFixed() },
                                    Third: { modelId: thirdIndex, modelName: ModelsNames[ thirdIndex ], modelFit: thirdModelFit.toFixed() } } );
        //TODO: Perguntar se deseja salvar o progresso
        props.setMyBusinessModel( { selectedOptions: decisionMatrix } );
        props.setQuizStep( { step: 1, modelId: -1 } );
    }

    const resultPerPageCalculation = () =>
    {
        var sum;

        for( var step = 0; step < decisionMatrix.length; step++ )
        {
            for( var column = 0; column < resultMatrixPerPage[ step ].length; column++ )
            {
                sum = 0;

                for( var aux = 0; aux < stepOpt[ step ].length - 1; aux++ )
                {
                    sum += decisionMatrix[ step ][ aux ] * stepOpt[ step ][ aux + 1 ][ column + 1 ];
                }

                resultMatrixPerPage[ step ][ column ] = sum;
            }
        }
    }

    const enableDisableQuizOpt = () =>
    {
        const option = document.getElementsByName( "option" );
        const nextButton = document.getElementsByName( "nextButton" );
        const prevButton = document.getElementsByName( "prevButton" );
        var numberOfSelected = 0;

        //TODO: dynamic decisionMatrix based on stepOpt[ currentStep ].length - 1 ) 
        for( var index = 0; index < decisionMatrix[ currentStep ].length; index++ )
        {
            numberOfSelected += option[ index ].childNodes[ 0 ].childNodes[ 0 ].childNodes[ 0 ].checked ? 1 : 0
        }

        if( numberOfSelected >= stepOpt[ currentStep ][ 0 ][ 1 ] )
        {
            for( let index = 0; index < decisionMatrix[ currentStep ].length; index++ )
            {
                option[ index ].childNodes[ 0 ].childNodes[ 0 ].childNodes[ 0 ].disabled = !option[ index ].childNodes[ 0 ].childNodes[ 0 ].childNodes[ 0 ].checked;
            }
        }
        else
        {
            for( let index = 0; index < decisionMatrix[ currentStep ].length; index++ )
            {
                option[ index ].childNodes[ 0 ].childNodes[ 0 ].childNodes[ 0 ].disabled = false;
            }
        }

        nextButton[ 0 ].className = ( numberOfSelected === 0 ) ? 'BoardBtnDisabled' : 'BoardBtnOk';
        prevButton[ 0 ].className = ( currentStep === 0 ) ? 'BoardBtnDisabled' : 'BoardBtnBack';
    }

    const optCheckBoxEvt = () =>
    {
        //TODO: validar numero de inputs contra o permitido para evitar user injection
        const option = document.getElementsByName( "option" );

        for( var index = 0; index < decisionMatrix[ currentStep ].length; index++ )
        {
            decisionMatrix[ currentStep ][ index ] = option[ index ].childNodes[ 0 ].childNodes[ 0 ].childNodes[ 0 ].checked ? 1 : 0;
        }

        enableDisableQuizOpt();
    };

    const nextStepFcn = () =>
    {

    
        botconversaWebhook({locale: pageLocale, action: 'Clicou em Prosseguir'})
    

        const nextButton = document.getElementsByName( "nextButton" );

        if( nextButton[ 0 ].className === 'BoardBtnOk' )
        {
            if( currentStep >= 0 && currentStep <= 12 )
            {

                
                currentStep += 1;       
                //setCurrentStepCheck(currentStep)               
                
            }
            else if( currentStep === 13 )
            {

                
                currentStep = ( decisionMatrix[ currentStep ][ 0 ] === 1 ) ? 14 : 15;
                //setCurrentStepCheck(currentStep)

            }
            else
            {   
                
                
                currentStep = 15;
                //setCurrentStepCheck(currentStep)

            }

            resultPerPageCalculation();

            if( currentStep >= 0 && currentStep <= 14 )
            {
                loadCheckBoxOpt();
            }
            else
            {
                resultCalculation();
            }
        }
    };

    const prevStepFcn = () =>
    {

            

    
        botconversaWebhook({locale: pageLocale, action: 'Clicou em Voltar'})
    
        const prevButton = document.getElementsByName( "prevButton" );

        if( prevButton[ 0 ].className === 'BoardBtnBack' )
        {
            if( currentStep >= 15 )
            {               
                currentStep = 14;
                                
               // setCurrentStepCheck(14)               
            }
            else if( currentStep <= 0 )
            {

                
                currentStep = 0;

               // setCurrentStepCheck(0)              
            }
            else
            {              
                
                
                currentStep -= 1;

                //setCurrentStepCheck(currentStep)        
            }

            loadCheckBoxOpt();
            loadCheckBoxOpt();
        }
    };

    const ShowInfoPopup = ( props ) => {
        if( props.target.tagName === 'IMG' )
        {
            const ProfileMenuBox = props.target;
            var viewportOffset = ProfileMenuBox.parentNode.getBoundingClientRect();
            var top = viewportOffset.top + viewportOffset.height - 1;
            var left = viewportOffset.left - 1;
            var width = viewportOffset.width + 2;

            if( ProfileMenuBox.nextSibling.className && ProfileMenuBox.nextSibling.innerHTML )
            {
                ProfileMenuBox.nextSibling.className = 'InfoOverPopUp';
                ProfileMenuBox.nextSibling.style.top = top + 'px';
                ProfileMenuBox.nextSibling.style.left = left + 'px';
                ProfileMenuBox.nextSibling.style.width = width + 'px';
            }
        }
    }

    const HideInfoPopup = ( props ) => {
        if( props.target.tagName === 'IMG' )
        {
            const ProfileMenuBox = props.target.nextSibling;
            ProfileMenuBox.className = 'DivHidden';
        }
    }

    useEffect( () => 
    {
        loadCheckBoxOpt();
        optCheckBoxEvt();

    
    }, [] );

    return(
        <div className='FullBoardContent'>



        
            <div>
                <CProgress height={ 10 } color={ colors.lightblue }>
                    <CProgressBar value={ 18 } id="StepProgressBarId"/>
                </CProgress>
                <div className='BoardTitleBtnsInline'>
                    <div name="nextButton" style={{padding: window.screen.width<780 && '16px', fontSize: window.screen.width<780 && '16px'}} className="BoardBtnDisabled" onClick={ nextStepFcn }>Prosseguir</div>
                </div>
                <div className='BoardTitleBtnsInline'>
                    <div name="prevButton" style={{padding: window.screen.width<780 &&  '16px', fontSize: window.screen.width<780 && '16px'}} className="BoardBtnDisabled" onClick={ prevStepFcn }>Voltar</div>
                </div>
                <div id="StepProgressId" className="FullBoardContentTitle">
                    Recomendação de modelo para seu negócio - 0% concluído
                </div>
            </div>
            <div className='HorizontalLineSeparator'/>
            <div>
                <div id="QuestionTitleId" className="FullBoardContentFormQuestion">
                    Seu negócio vende produto ou serviço?
                </div>
                <div id="NumberOfOptionsId" className="FullBoardContentSubTitle">
                    Escolha até 1 opções abaixo.
                </div>
            </div>


            <table style={ { margin:'auto'  } }>
                <tbody>
                    <tr>
                        <td name="option" style={ { width: 'auto' } }>
                            <div className={"quizCheckBox"}>
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td name="option" style={ { width: 'auto' } }>
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td name="option" style={ { width: 'auto' } }>
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td name="option">
                            <div className={"quizCheckBox"}>
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td name="option">
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td name="option">
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td name="option">
                            <div className={"quizCheckBox"}>
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td name="option">
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td name="option">
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td name="option">
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td name="option">
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td name="option">
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td name="option">
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td name="option">
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td name="option">
                            <div className="quizCheckBox">
                                <label>
                                    <input type="checkbox" onClick={ optCheckBoxEvt }/>
                                    <span>
                                        <div style={ { display: 'inline', overflowWrap: 'break-word'} }>...</div>
                                        <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                                        <div className='DivHidden'>Popup Info</div>
                                    </span>
                                </label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default ModelRecForm;