import React from 'react';
import '@coreui/coreui/dist/css/coreui.min.css'
import { TrashIconImg, EditIconImg, AddIconImg } from '../imgsLoader/LoadIconImgs';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import UserStore from '../../store/userStore';
import axios from 'axios';
import { StringMask } from '../library/StringMasks';
import { SuccessAddElement } from '../successAddElement/SuccessAddElement';
import { FailAddElement } from '../failAddElement/FailAddElement';
import { RemoveElement } from '../removeElement/RemoveElement';
import LoadingScreen from '../loadingScreen/LoadingScreen';
import { botconversaWebhook } from '../../functions/botconversaWebhook';

let eItems = [];
let expenses = [];
let erItems = [];
let expenseRecs = [];

const Expenses = () =>
{







    const pageLocale = 'Gestão Financeira'
    useEffect(()=>{
    
        botconversaWebhook({locale: pageLocale, action: 'Abriu Custos e Despesas'})
    })        
    const [ tableExpenses, setTableExpenses ] = useState( [] );
    const [ tableExpenseRecs, setTableExpensesRecs ] = useState( [] );
    const [ loadingExpenseList, setLoadingExpenseList ] = useState( true );
    const [ loadingExpenseRecList, setLoadingExpenseRecList ] = useState( true );
    const [ screenDebounceTime, setScreenDebounceTime ] = useState( true );

    const [alertMessage, setAlertMessage] = useState("");
    const [failWarn, setFailWarn] = useState(false);
    const [successWarn, setSuccessWarn] = useState(false);
    const [removeExpenseWarn, setRemoveExpenseWarn] = useState(false);
    const [warnExpense, setWarnExpense] = useState();
    

    const [loadingScreen, setLoadingScreen] = useState(false);

    const emptyTableExpensesWarn = 
        <tr>
            <td colSpan={ 4 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
                Você não possui custos cadastrados.<br/>
                Clique em '+' no canto superior direito para adicionar um novo custo.
            </td>
        </tr>;

    const emptyTableExpenseRecsWarn = 
    <tr>
        <td colSpan={ 2 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
            Você utilizou todas as recomendações.
        </td>
    </tr>;

    const loadingTableExpensesWarn = 
    <tr>
        <td colSpan={ 4 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
            Por favor aguarde, carregando lista de despesas.
        </td>
    </tr>;

    const loadingTableExpenseRecsWarn = 
    <tr>
        <td colSpan={ 2 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
            Por favor aguarde, carregando lista de recomendações.
        </td>
    </tr>;


    const checkBeforeRemoveExpense = (expense) =>{
          setWarnExpense(expense);
          setRemoveExpenseWarn(true);
  

    }

    const searchItems = (eItems, expenseId) =>{
        let eItem;
        eItems.forEach((item)=>{if(item.expenseId === expenseId){
        eItem = item;
         }})
     return eItem;
    }


    const fillExpensesList = async() =>
    {

        eItems = []

        expenses = []
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();

        axios.get( 'https://7yvw2lobzyfid6b6jvtduft6ii0qtopa.lambda-url.us-east-2.on.aws/', { params: { company: userId, token: token } } ).then( ( eResp ) =>
        {
            if( eResp.data.expenses )
            {
                for( let index = 0; index < eResp.data.expenses.length; index++ )
                {
                    let expense = eResp.data.expenses[ index ].expense;
                    let name = eResp.data.expenses[ index ].name;
                    let payDay = eResp.data.expenses[ index ].payDay;
                    let value = StringMask( eResp.data.expenses[ index ].value.toFixed( 2 ), { type:'2DigitFraction' }, '' )
                    let expenseRec = eResp.data.expenses[ index ].expenseRec;
    
                    const newLine =
                        <tr id={ 'e_' + expense } key={ expense }>
                            <td>{ name }</td>
                            <td>{ payDay }</td>
                            <td>{ value }</td>
                            <td><div className='userInputsIconGreen' onClick={ () => editExpense( expense ) }><img src={ EditIconImg } alt='...'/></div></td>
                            <td><div className='userInputsIconRed' onClick={ () => checkBeforeRemoveExpense( expense ) }><img src={ TrashIconImg } alt='...'/></div></td>
                        </tr>;
    
                    eItems.push( { added: false, expenseId: expense, name: name, value: value, expenseRec: expenseRec , payDay: payDay } );
                    expenses.push( newLine );
                }

                setTableExpenses( [ ...expenses ] );
            }
    
            setLoadingExpenseList( false );
        } );

        axios.get( 'https://kxsv5mfgqkp4fmt7mj5yy2ut440wfges.lambda-url.us-east-2.on.aws/', { params: { company: userId, token: token } } ).then( ( erResp ) =>
        {
            if( erResp.data.expenses )
            {
                for( let index = 0; index < erResp.data.expenses.length; index++ )
                {
                    let expense = erResp.data.expenses[ index ].expense;
                    let name = erResp.data.expenses[ index ].name;

                    if( eItems.findIndex( ( item ) => { return item.expenseRec === expense } ) === -1 )
                    {
                        const newLine =
                            <tr id={ 'er_' + expense } key={ expense }>
                                <td><div className='userInputsIconGreen' onClick={ () => addRecomendedExpense( name, expense ) }><img src={ AddIconImg } alt='...'/></div></td>
                                <td>{ name }</td>
                            </tr>;

                        erItems.push( { expenseId: expense, name: name } );
                        expenseRecs.push( newLine );
                    }
                }

                setTableExpensesRecs( [ ...expenseRecs ] );
            }

            setLoadingExpenseRecList( false );
        } );
    }

    const handleNameChange = ( expenseId, event ) => 
    {
        let eIndex = eItems.findIndex( ( expense ) => { return expense.expenseId === expenseId } );
        eItems[ eIndex ].name = ( eIndex !== -1 ) ? event.target.value : eItems[ eIndex ].name;
    }

    const handleValueChange = ( expenseId, event ) => 
    {
        let eIndex = eItems.findIndex( ( expense ) => { return expense.expenseId === expenseId } );
        eItems[ eIndex ].value = ( eIndex !== -1 ) ? event.target.value : eItems[ eIndex ].value;
        eItems[ eIndex ].value = StringMask( eItems[ eIndex ].value, { type:'2DigitFraction' }, '' );
        event.target.value = eItems[ eIndex ].value;
    }

    const handleDayChange = ( expenseId, event ) => 
    {
        let eIndex = eItems.findIndex( ( expense ) => { return expense.expenseId === expenseId } );
        eItems[ eIndex ].payDay = ( eIndex !== -1 ) ? event.target.value : eItems[ eIndex ].payDay;
        event.target.value = eItems[ eIndex ].payDay ;
    }

    const addRecomendedExpense = ( expenseName, expenseRec ) =>
    {       
        let erIndex = expenseRecs.findIndex( ( props ) => { return props.key === expenseRec.toString() } );

        newExpenseOnTable( expenseName, '1','0,00',expenseRec );
            
        if( erIndex !== -1 )
        {
            expenseRecs.splice( erIndex, 1 );
            erItems.splice( erIndex, 1 );
            setTableExpensesRecs( [ ...expenseRecs ] );
        }
    }


    const cancelElementAdd = (e) =>{    
        let newExpenseArray = []
        const firstEl = e.target
        const secondEl = firstEl.parentElement
        const tableElementId = secondEl.parentElement.id
     
        expenses.forEach((expenseThis)=>{
            const expenseThisKey = `e_${expenseThis.key}`
            if (expenseThisKey !== tableElementId) {
              newExpenseArray.push(expenseThis);
            }
        })
        setTableExpenses(newExpenseArray)
        expenses=[]
        newExpenseArray.forEach(newE => {
           expenses.push(newE)
        })

        newExpenseArray = []
        eItems.forEach((eItem)=>{
            const eItemId = `e_${eItem.expenseId}`
            if (eItemId !== tableElementId) {
                newExpenseArray.push(eItem)
            }
        })

        eItems = []
        newExpenseArray.forEach(newE =>{
            eItems.push(newE)
        })


    }



    const newExpenseOnTable = ( name, payDay , value , expenseRec ) =>
    {
        let expenseId = uuidv4();
        let nameColumn = <td>{ name }</td>;
        
        if( expenseRec === 0 )
        {
            nameColumn = <td><input className='userInputsText' type='text' name='eName' defaultValue={ name } onChange={ ( event ) => handleNameChange( expenseId, event ) }/></td>;
        }

        const newLine =
            <tr id={ 'e_' + expenseId } key={ expenseId }>
                { nameColumn }
                <td><input className='userInputsText' type='text' name='ePayDay' defaultValue={ payDay } onChange={ ( event ) => handleDayChange( expenseId, event ) }/></td>
                <td><input className='userInputsText' type='text' name='eValue' defaultValue={ value } onChange={ ( event ) => handleValueChange( expenseId, event ) }/></td>




                <td>{loadingScreen ? <div className='userInputsIconGreen' >&#x2713;</div> : <div className='userInputsIconGreen' onClick={ () => {setLoadingScreen(true); createExpense( expenseId )} }>&#x2713;</div> }</td>
                <td onClick={(e)=>{cancelElementAdd(e)}}><div className='userInputsIconRed'>X</div></td>
            </tr>;
        eItems.push( { added: false, expenseId: expenseId, name: name, value: value, expenseRec: expenseRec, payDay: payDay } );
        expenses.push( newLine );
        setTableExpenses( [ ...expenses ] );
    };

    const createExpense = async( expenseId ) => 
    {                
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();
        let eIndex = expenses.findIndex( ( props ) => { return props.key === expenseId.toString() } );
        const eItem = searchItems(eItems, expenseId)
        if( eIndex !== -1 )
        {
            if( eItem.name === "" )
            {
                setAlertMessage( "Por favor, insira um nome para o novo custo." );
                setFailWarn(true)
            }
            else if( eItem.value === "0,00" )
            {
                setAlertMessage( "Por favor, insira um valor para o novo custo." );
                setFailWarn(true)
            }
            else if( eItem.payDay <= 0 || eItem.payDay > 31 )
            {
                setAlertMessage("Por favor, insira uma data valida") ;
                setFailWarn(true)
            }
            else
            {console.log('SSSS')


                const resp = await axios.post
                (
                    'https://4jpwbnudftzorva5bnpadgovbi0iwxjb.lambda-url.us-east-2.on.aws/',
                    {
                        company: userId,
                        token: token,
                        name: eItems[ eIndex ].name,
                        value: StringMask( eItems[ eIndex ].value, { type:'float' }, '' ),
                        expenseRec: eItems[ eIndex ].expenseRec,
                        payDay: eItems[ eIndex ].payDay
                    }
                ).catch(err=>setLoadingScreen(false));
                    //console.log(resp)
                if( resp.data.expense !== -1 )
                {

                    
                        botconversaWebhook({locale: pageLocale, action: 'Criou Despesa'})
                    
                    const expenseResp = resp.data.expense;
                    const newLine =
                        <tr id={ 'e_' + expenseResp } key={ expenseResp }>
                            <td>{ eItems[ eIndex ].name }</td>
                            <td>{ eItems[ eIndex ].payDay }</td>
                            <td>{ eItems[ eIndex ].value }</td>
                            <td><div className='userInputsIconGreen' onClick={ () => editExpense( expenseResp ) }><img src={ EditIconImg } alt='...'/></div></td>
                            <td><div className='userInputsIconRed' onClick={ () => checkBeforeRemoveExpense( expenseResp ) }><img src={ TrashIconImg } alt='...'/></div></td>
                        </tr>;
                    expenses[ eIndex ] = newLine;
                    setTableExpenses( [ ...expenses ] );
                    setLoadingScreen(false)
                    
                }
                            setSuccessWarn(true);
                //setAlertMessage('Produto Adicionado com Sucesso!')
                //setSuccessWarn(true)

            }



            setLoadingScreen(false)
        }
    };

    const editExpense = async( expenseId ) =>
    {
        let eIndex = expenses.findIndex( ( props ) => { return props.key === expenseId.toString() } );

        if( eIndex !== -1 )
        {
            let nameColumn = <td>{ eItems[ eIndex ].name }</td>;
        
            if( eItems[ eIndex ].expenseRec === 0 )
            {
                nameColumn = <td><input className='userInputsText' type='text' name='eName' defaultValue={ eItems[ eIndex ].name } onChange={ ( event ) => handleNameChange( expenseId, event ) }/></td>;
            }
            
            const newLine = 
                <tr id={ 'e_' + expenseId } key={ expenseId }>
                    { nameColumn }
                    <td><input className='userInputsText' type='text' name='ePayDay' onChange={ ( event ) => handleDayChange( expenseId, event ) } defaultValue={ eItems[ eIndex ].payDay }/></td>
                    <td><input className='userInputsText' type='text' name='eValue' onChange={ ( event ) => handleValueChange( expenseId, event ) } defaultValue={ eItems[ eIndex ].value }/></td>
                    <td><div className='userInputsIconGreen' onClick={ () => confirmEditExpense( expenseId ) }><img src={ AddIconImg } alt='...'/></div></td>
                    <td></td>
                </tr>;

            expenses[ eIndex ] = newLine;
        }

        setTableExpenses( [ ...expenses ] );
    };

    const confirmEditExpense = async( expenseId ) =>
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();
        let eIndex = expenses.findIndex( ( props ) => { return props.key === expenseId.toString() } );

        if( eIndex !== -1 )
        {
            if( eItems[ eIndex ].name === "" )
            {
                alert( "O nome do custo não pode ser vazio." );
            }
            else if( eItems[ eIndex ].value === "" )
            {
                alert( "O valor do custo não pode ser vazio." );
            }
            else
            {console.log('DDDD')

                setLoadingScreen(true)
                const resp = await axios.put
                (
                    'https://rnbh3hjwta3wvfkhfx3bccgftq0hnfmn.lambda-url.us-east-2.on.aws/',
                    {
                        expense: expenseId,
                        company: userId,
                        token: token,
                        name: eItems[ eIndex ].name,
                        value:StringMask( eItems[ eIndex ].value, { type:'float' }, '' ),
                        expenseRec: eItems[ eIndex ].expenseRec,
                        payDay: eItems[ eIndex ].payDay
                    }
                ).catch(err=>setLoadingScreen(false));
                //console.log( resp )
//TODO: Not equal to undefined
                if( resp.data.expense !== -1 )
                {

                        botconversaWebhook({locale: pageLocale, action: 'Editou Despesa'})

                    const newLine = 
                        <tr id={ 'e_' + expenseId } key={ expenseId }>
                            <td>{ eItems[ eIndex ].name }</td>
                            <td>{ eItems[ eIndex ].payDay }</td>
                            <td>{ eItems[ eIndex ].value }</td>
                            <td><div className='userInputsIconGreen' onClick={ () => editExpense( expenseId ) }><img src={ EditIconImg } alt='...'/></div></td>
                            <td><div className='userInputsIconRed' onClick={ () => removeExpense( expenseId ) }><img src={ TrashIconImg } alt='...'/></div></td>
                        </tr>;

                    expenses[ eIndex ] = newLine;
                    setTableExpenses( [ ...expenses ] );
                    setLoadingScreen(false)
                }
            }   
        }
    }

    const removeExpense = async( expenseId ) =>
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();
        let eIndex = expenses.findIndex( ( props ) => { return props.key === expenseId.toString() } );

        if( eIndex !== -1 )
        {

            setLoadingScreen(true)
            const resp = await axios.delete( 'https://qpmr375yy2cpacyeowbmpy6iiy0wggqo.lambda-url.us-east-2.on.aws/', { data: { expense: expenseId, company: userId, token: token } } ).catch(setLoadingScreen(false));
            eIndex = expenses.findIndex( ( props ) => { return props.key === expenseId.toString() } );
            let expense = eItems[ eIndex ].expenseId;
            let name = eItems[ eIndex ].name;

            if( resp.data.expense !== -1 )
            {

                
                    botconversaWebhook({locale: pageLocale, action: 'Deletou Despesa'})
                
                if( eItems[ eIndex ].expenseRec !== 0 )
                {
                    const newLine =
                        <tr id={ 'er_' + expense } key={ expense }>
                            <td><div className='userInputsIconGreen' onClick={ () => addRecomendedExpense( name, expense ) }><img src={ AddIconImg } alt='...'/></div></td>
                            <td>{ name }</td>
                        </tr>;
                    
                    erItems.push( { expenseId: expense, name: name } );
                    expenseRecs.push( newLine );
                }

                expenses.splice( eIndex, 1 );
                eItems.splice( eIndex, 1 );
                setTableExpenses( [ ...expenses ] );
                setTableExpensesRecs( [ ...expenseRecs ] );

                setLoadingScreen(false)


            }
        }
    };

    useEffect( () => { fillExpensesList(); setTimeout( () => { setScreenDebounceTime( false ); }, 1000 ); }, [] );

    return(





        <div style={{height:'90%', float:'left', display:'table'}}>


        {loadingScreen && <LoadingScreen/>}        
        {successWarn && <SuccessAddElement message="Custo adicionado com sucesso" onClick={()=>{setSuccessWarn(false)}}/>}
        {failWarn && <FailAddElement message={alertMessage} onClick={()=>{setFailWarn(false)}}/>}
        {removeExpenseWarn && <RemoveElement message="Deseja remover custo?" closeWarn={()=>{setRemoveExpenseWarn(false)}} moreButtons={true} onClick={()=>{

            setRemoveExpenseWarn(false);
            removeExpense(warnExpense);
            }}/>}

            <div className='ExpensesLeftBoardContent'>
                <div style={{minHeight:'100%'}}>
                    <div className="FullBoardContentTitle">
                        Custos e despesas comuns
                    </div>
                    <div id="NumberOfOptionsId" className="FullBoardContentSubTitle">
                        Dúvidas sobre os custos do seu negócio? Listamos aqui os principais custos e despesas que você pode ter no seu negócio.<br/>
                        Clique no "+" para adicioná-lo no seu controle.
                    </div>
                </div>
                <div className='HorizontalLineSeparator'/>
                <div style={{height:'100%'}}>
                    <table className="CommExpensesTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Custo</th>
                            </tr>
                        </thead>
                        <tbody>
                            { ( loadingExpenseRecList || screenDebounceTime ) ? loadingTableExpenseRecsWarn : ( tableExpenseRecs.length > 0 ) ? tableExpenseRecs : emptyTableExpenseRecsWarn }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='ExpensesRightBoardContent'>
                <div style={{minHeight:'100%'}}>
                    <div className="FullBoardContentTitle">
                        Custos e despesas
                        <div className='TitleNewItemBtn' style={{float:'right'}} onClick={ () => newExpenseOnTable( '', '1','0,00', 0 ) }>+</div>
                    </div>
                    <div id="NumberOfOptionsId" className="FullBoardContentSubTitle">
                        Aqui você pode controlar todos os custos adicionados a sua empresa.<br/>
                        Clique em "+" no para adicionar um custo extra ou não encontrado na lista a esquerda.
                    </div>
                </div>
                <div className='HorizontalLineSeparator'/>
                <div style={{height:'100%'}}>
                    <table className="ModelExpensesTable">
                        <thead>
                            <tr>
                                <th>Custo</th>
                                <th>{window.screen.width>780 ? "Dia de Vencimento" : "Vence dia"}</th>
                                <th>Valor Mensal (R$)</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { ( loadingExpenseList || screenDebounceTime ) ? loadingTableExpensesWarn : ( tableExpenses.length > 0 ) ? tableExpenses : emptyTableExpensesWarn }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Expenses;