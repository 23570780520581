import React, { useEffect } from 'react'
import styles from './VideoStepsContainer.module.sass'
import StepOption from './stepOption/StepOption'
import axios from 'axios'
import UserStore from '../../../../store/userStore'

const VideoStepsContainer = ({reloadSteps, allSteps, selectStep, currentGroup, subGroups , isWeb, setIsFullStepOpen}) => {
  console.log(currentGroup)
  console.log(allSteps)
  console.log(isWeb)
  
  return (
    <div className={styles.videoStepContainer}>

      {isWeb && allSteps && Object.keys(allSteps).map(group=><StepOption  isWeb={isWeb} subGroups={subGroups} reloadSteps={reloadSteps} group={group} isOpen={currentGroup===allSteps[group][0].sGroup } selectStep={selectStep} groupStepList={allSteps[group]} groupName={group}/>)}
      {!isWeb && allSteps && Object.keys(allSteps).map(group=>{
      
    
      if (allSteps[group][0].sGroup===currentGroup) {
        return(<StepOption isWeb={isWeb} setIsFullStepOpen={setIsFullStepOpen} subGroups={subGroups} 
      
      reloadSteps={reloadSteps} group={group} isOpen={currentGroup===allSteps[group][0].sGroup }
      selectStep={selectStep} groupStepList={allSteps[group]} groupName={group}/>)
      }}
      
      )}
    
    
    </div>
  )
}

export default VideoStepsContainer