import React from 'react'
import styles from './LoadingIcon.module.sass'

const LoadingIcon = () => {
  return (
    <div className={styles.loadingIcon}>
        <div className={styles.ring_first}></div>
        <div className={styles.ring_second}></div>
        <div className={styles.ring_third}></div>

    </div>
  )
}

export default LoadingIcon