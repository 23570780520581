import React from 'react'
import styles from './FiltersCard.module.sass'
import Select from 'react-select'
const FiltersCard = ({filterClients, origemOptions, productsOption, statusOptions, closeFilter, clientsList, productFilter, statusFilter, origemFilter, setStatusFilter, setDateFilter, setDateIIFilter, setOrigemFilter, setFilteredClientsList, setproductFilter}) => {


  return (
    <div className={styles.filtersCard}>

<label>Data<input type="date" onChange={e=>filterClients({value: e.target.value, filter: 0})}/></label>
                    <label>Data II<input type="date" onChange={e=>filterClients({value: e.target.value, filter: 1})}/></label>
                    <label>Origem<Select defaultValue={origemFilter } onChange={e=>filterClients({option:e, filter: 2})} options={origemOptions && origemOptions}   /></label>
                    <label>Produto<Select defaultValue={productFilter } onChange={e=>filterClients({option:e, filter: 3})} options={productsOption && productsOption}   /></label>
                    <label>Status<Select defaultValue={statusFilter } onChange={e=>filterClients({option:e, filter: 4})} options={statusOptions && statusOptions}   /></label>
                    <div className={styles.button} onClick={()=>closeFilter()}>Filtrar</div>



    </div>
  )
}
export default FiltersCard