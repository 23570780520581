import React from 'react'
import styles from './GetPremiumPlan.module.css'  

const GetPremiumPlan = () => {
    const premiumPlanValue = '57,00'



  return (
    <div className={styles.getPremiumPlan}>
      
      
      
      <div className={styles.getPremiumPlanContainer}>
        <h2>Tome o controle da sua empresa</h2>
        <p>Desbloqueie todas as funcionalidades para ter a melhor gestão para sua empresa</p>
        <p>Por apenas</p> <span className={styles.monthPlanValue}>R${premiumPlanValue}/Mês</span>
        <a href="https://sun.eduzz.com/1745888" className={styles.getPremiumPlanButton}>Assine agora</a>
      </div>
    
    
    
    </div>
  )
}

export default GetPremiumPlan