import React from 'react'
import styles from './SelectOption.module.sass'
const SelectOption = ({closeFn, type, currentValue, selectFn}) => {
    
    const arrayOfMonths = [
        {name: 'Janeiro', value: 1},
        {name: 'Fevereiro', value: 2},
        {name: 'Março', value: 3},
        {name: 'Abril', value: 4},
        {name: 'Maio', value: 5},
        {name: 'Junho', value: 6},
        {name: 'Julho', value: 7},
        {name: 'Agosto', value: 8},
        {name: 'Setembro', value: 9},
        {name: 'Outubro', value: 10},
        {name: 'Novembro', value: 11},
        {name: 'Dezembro', value: 12}         
]

    const arrayOfYears = [2023, 2024, 2025, 2026]







  return (
    <div className={styles.selectOption}>
        <div className={styles.titleInfo}>
            {type==="month" && <h2 className={styles.title}>Selecione o Mês</h2>}            
            {type==="year" && <h2 className={styles.title}>Selecione o Ano</h2>}   

            <span onClick={closeFn }>x</span>
        </div>

    <div className={styles.selectOptionContent}>   
        {type==="month" && arrayOfMonths.map(month=>(
            <p className={styles.option} onClick={()=>{selectFn({name: month.name, value: month.value})}}>{month.name}</p>   
        ))}
        {type==="year" && arrayOfYears.map(year=>(
            <p className={styles.option} onClick={()=>{console.log(year);selectFn(year)}}>{year}</p>   
        ))}</div>                
    </div>
  )
}

export default SelectOption