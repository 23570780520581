import React from 'react'
import styles from './ProductsContainer.module.sass'
import ProductCard from '../productCard/ProductCard'
const ProductsContainer = ({products, monthIndex, convertToCurrency}) => {
  return (
    <div className={styles.productsContainer}>
        {products && products.map(product=><ProductCard convertToCurrency={convertToCurrency} productName={product.name} targetValue={product.target[monthIndex]} doneValue={product.done[monthIndex]}/>)}


    </div>
  )
}

export default ProductsContainer