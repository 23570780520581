import React from "react";
import { CProgress, CProgressBar } from '@coreui/react'
import "../journey/JourneyForWhomQuestions.css";
import PainQuestions from "../questions/painQuestions";
import ProductCharacteristicsQuestions from "../questions/productCharacteristicsQuestions";
import menuJourneyPurple from "../../assets/icons/iconMenuJourneyPurple.png";
import JourneysideMenu from "./JourneysideMenu";
import styles from './JourneyAll.module.css';   
import { useEffect } from "react";
import axios from "axios";
import UserStore from "../../store/userStore";
import { botconversaWebhook } from "../../functions/botconversaWebhook";

const JourneyProductAndServiceQuestions = () =>{
    


    const pageLocale = 'Jornada'
    useEffect(()=>{

        botconversaWebhook({locale: pageLocale, action: 'Abriu Perguntas - Produtos ou Serviços'})
        const postStep = async(step) =>{
            await axios.put('https://iz5smq7lxhsxjtskiegdcibiru0vwhjh.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), token: UserStore.getToken(), journeyStep: step})
        }
        postStep(22) 
    }, [])    
    const OpenSideDiv = ()=>{
        let sideDiv = document.getElementById('journey1SideDiv');
        sideDiv.style.display='block';
    }

    
    return(
        <div className="journeyFWQBackground" >
            <img src={menuJourneyPurple} alt-='' className="journeyFWQIconMenu" onClick={OpenSideDiv} >
            </img>
            <div style={{position:'relative',
                        top: '1.7rem',
                        left: '6.8rem',
                        width:'12rem'}}>
                <text className="journeyFWQHeader">Entendendo seu modelo de negócio</text>
            </div>
            <div className="journeyFWQEbookDiv">
            <a href="/journey/valueproposition/video" className="journeyFWQlink" style={{textDecoration:'none',
                                                                                        gap:'2.8rem'}}>
                    <div style={{display:'flex',
                                flexDirection:'column',
                                marginLeft:'1.5rem',
                                marginTop:'0.8rem',
                                marginBottom:'0.8rem'}}>
                        <text className="journeyFWQVideoUpperTxt">Próximo conteúdo</text>
                        <text className="journeyFWQVideoLowerTxt">Proposta de Valor</text>
                    </div>
                        <div className="journeyFWQArrowDiv" style={{cursor:'pointer'}}>
                            <text className="journeyFWQArrow">&#8250;</text>
                        </div>
                </a>
            </div>
            <text className="journeyFWQprogressText" style={{top:'-5.4rem'}}>
                Seu progresso
            </text>      
            <div style={{position:'relative',
                        top:'-7rem',
                        left:'75.2rem',
                        paddingLeft:'0.4rem',
                        paddingRight:'0.4rem',
                        borderRadius:'0.3rem',
                        backgroundColor:'#251F53',
                        width:'fit-content'}}>
                <text className="journeyFWQprogressBarText">
                    45% completo
                </text>
            </div>
            <div style={{position:'relative',
                        top:'-6.5rem',
                        left:'67rem',
                        width:'20rem'}}>
                <CProgress height={ 6 }  >
                    <CProgressBar value={ 45 } />
                </CProgress>
            </div>
            <text className='journeyFWQQuestionsTitle'>Perguntas</text>
            <div className="journeyFWQQuestionsDiv">
                <ProductCharacteristicsQuestions/>
            </div>
            <a href='/journey/productandservices/video' style={{textDecoration:'none', display: 'block', width: '100px'}}>
                <div className="backButton2" style={{top: window.screen.width>780 && '-5rem'}}>
                    <text className="textBackButton2">&#8249;</text>
                </div>
            </a>
            <JourneysideMenu></JourneysideMenu>
        </div>
    )
}

export default JourneyProductAndServiceQuestions;