import React, { useEffect, useState } from 'react'
import styles from './MobileCashFlow.module.sass'
import SelectOption  from './selectOption/SelectOption'   
import axios from 'axios'
import UserStore from '../../../../store/userStore' 
import { StringMask } from '../../../library/StringMasks' 
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid';
import ProductsContainer from './productsContainer/ProductsContainer'
import ValueCard from './valueCard/ValueCard'
import ExpensesContainer from './expensesContainer/ExpensesContainer'
import EditExpense from './editExpense/EditExpense'


const MobileCashFlow = ({selectedMonthIndex, salesList, totalSales, totalTributes, totalExpenses}) => {
    console.log(totalSales)
    const thisCurrentDate = new Date()
    const thisCurrentMonth = thisCurrentDate.getMonth()

    const currentMonthName = thisCurrentDate.toLocaleString('default', { month: 'long' });
    const thisCurrentMonthName = currentMonthName.charAt(0).toUpperCase() + currentMonthName.slice(1)

    const [selectedYear, setSelectedYear] = useState(thisCurrentDate.getFullYear())
    const [selectedMonth, setSelectedMonth] = useState(thisCurrentMonthName)


    const [openMenuType, setOpenMenuType] = useState('')
    const [thisCfpItems, setThisCfpItems] = useState('')   

    const [thisCfeItems, setThisCfeItems] = useState(null)
    const [monthResult, setMonthResult] = useState(null)
    const [monthAccumulated, setMonthAccumulated] = useState(null)
    const [thisMonthTaxes, setThisMonthTaxes] = useState(null)
    const [isExpenses , setIsExpenses ] = useState(false)
    const [monthExpenses, setMonthExpenses] = useState()

    const [isEditExpense, setIsEditExpense] = useState(false)
    const [expenseData, setExpenseData] = useState(null)

    const editExpense = ({name, id, doneValue, targetValue}) =>{

        
        setExpenseData({name, id, doneValue, targetValue, month: selectedMonthIndex})
        setIsEditExpense(true)  
    }
    const convertToCurrency = (value) =>{
        return value.toString().replace(',', '%').replace('.', ',').replace('%', '.')   
    }




    const getStringMonth = (month) =>{

        if (month<10) {
            return `0${month}`
        } 
        return `${month}`       
    }
    const searchForMonth = async({name, value}) =>{ 
        setSelectedMonth(name)
    
        setOpenMenuType('')
    }



    
    const searchForYear = async(year) =>{

        setSelectedYear(year)
        fillCFList(year)
 
 
    }



    const monthNames = [ 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro' ];
    const currentYear = new Date().getFullYear()
    
    let currentMonth = new Date().getMonth() + 1;
    let currentSemester = currentMonth <= 6 ? 1 : 2
    let selectedSemester = currentSemester;
    let cfpList = [];
    let salesListLocal = new Array( 12 ).fill( 0 );
    let cfeList = [];
    let aliquotTable = 0;
    let rbt12 = 0;
    let monthTaxes = Array( 12 ).fill( 0 );
    let cfpItems = [ { year: currentYear, products: [] } ];
    let cfeItems = [ { year: currentYear, expenses: [] } ];
    
    
    
    
    
    
    
    
    
        const pageLocale = 'Gestão Financeira'
        useEffect(()=>{
        
            //botconversaWebhook({locale: pageLocale, action: 'Abriu Fluxo de Caixa'})
        })    
        const [isLoading, setIsLoading] = useState(false)

        const [ tableCFP, setTableCFP ] = useState( [] );
        const [ tableCFE, setTableCFE ] = useState( [] );
        const [ effectiveExpensPopUp, setPopUp ] = useState('');
        const [ expensesSelection, setExpensesSelection ] = useState( [] );
    
        const currentDate = new Date();
    
    
    
        const selecStyles =
        {   
            valueContainer:( provided, state ) => ( { ...provided, padding:'0px 5px' } ),
            dropdownIndicator:( provided, state ) => ( { ...provided, padding:'4px' } ),
            control:( styles ) => ( { ...styles, minHeight:'10px', 
                                    borderColor:'gray',
                                    width:'21rem', 
                                    height:'2.5rem',
                                    borderRadius:'5px',
                                    padding:'5px',
                                    fontFamily: 'Montserrat',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '1.2rem',
                                    lineHeight: '1.3rem',
                                    color: '#251F53' } )
        };
    
        const initTable = () =>
        {
                
            
            
            
            cfpList = []
    
            cfeList = []
            for( let index = 0; index < 5; index++ )
            {
    
                //console.log('lista');//console.log(cfpList)
    
                cfpList.push
                (
                    <tr id={ 'p_' + index } key={ 'p_' + index }>
                        <td><div>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    </tr>
                );
    
                cfeList.push
                (
                    <tr id={ 'e_' + index } key={ 'e_' + index }>
                        <td><div>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                        <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    </tr>
                );
            }
    
            setTableCFP( [ ...cfpList ] );
            setTableCFE( [ ...cfeList ] );
    
        
        }
    
        const fillCFList = async(thisYear) =>
        {
            
            //console.log('List');//console.log(thisYear)
            var userId = UserStore.getUserId();
            var token = UserStore.getToken();
            setIsLoading(true)
            //console.log(userId);//console.log(token)
            cfpItems[ 0 ].year = thisYear
            cfpItems[ 0 ].products = []
            cfeItems[ 0 ].expenses = []
            await axios.get( 'https://4iirgtz6rqhn2itpfzey4zd4au0iyfli.lambda-url.us-east-2.on.aws/', { params: { year: thisYear, company: userId, token: token } } ).then( ( cfResp ) =>
            {//console.log('RESP');//console.log(cfResp)

                setOpenMenuType('')
                if( cfResp.data.pTarget )
                {
                    for( let index = 0; index < cfResp.data.pTarget.length; index++ )
                    {
                        let curProduct = cfResp.data.pTarget[ index ];
                        let product = curProduct.product;
                        let name = curProduct.name;
                        let target = [ curProduct.jan, curProduct.feb, curProduct.mar, curProduct.apr, curProduct.may, curProduct.jun, curProduct.jul, curProduct.aug, curProduct.sep, curProduct.oct, curProduct.nov, curProduct.dece ];
                        let done = Array( 12 ).fill( 0 );
    
                        cfpItems[ 0 ].products.push( { product:product, name:name, done:done, target:target } );
                    }
    
                    //console.log('cfp after axios');//console.log(cfpItems)
                }
    
                if( cfResp.data.pDone )
                {
    
                    for( let index = 0; index < cfResp.data.pDone.length; index++ )
                    {
                        const pIndex = cfpItems[ 0 ].products.findIndex( ( element ) => { return element.product == cfResp.data.pDone[ index ].product } );
                        if(cfResp.data.pDone[index].saleYear === thisYear || cfResp.data.pDone[index].month + cfResp.data.pDone[index].recurrenceMonths -1 > 12 || cfResp.data.pDone[index].month + cfResp.data.pDone[index].installments - 1 > 12){
    
                        if( pIndex !== -1  )
                        {
    
                            if( cfResp.data.pDone[ index ].recurrenceMonths > 1 )
                            { 
    
                                if(cfResp.data.pDone[index].saleYear === thisYear){
                                    for( let monthLoop = 0; monthLoop < cfResp.data.pDone[ index ].recurrenceMonths; monthLoop++ )
                                    {          
                                        cfpItems[ 0 ].products[ pIndex ].done[ cfResp.data.pDone[ index ].month + monthLoop - 1 ] += cfResp.data.pDone[ index ].done; 
                                    }  
                                }else{
                                    const changeStep = 12 - cfResp.data.pDone[index].month + 1 //  12 - 12 + 1 = 1
                                    const changeMonths = cfResp.data.pDone[index].recurrenceMonths - changeStep // 5 - 2 = 3 -->> 1 2 3
                                    //console.log(changeStep);//console.log(changeMonths)
    
    
                                    for( let monthLoop = 0; monthLoop < changeMonths; monthLoop++ )
                                    {          
                                        cfpItems[ 0 ].products[ pIndex ].done[ monthLoop ] += cfResp.data.pDone[ index ].done; 
                                    }
                                }
    
                            }
                            else
                            {
                                const paymentFraction = cfResp.data.pDone[ index ].done / Math.max( cfResp.data.pDone[ index ].installments, 1 );
    
    
    
    
    
                                
                                if(cfResp.data.pDone[index].saleYear === thisYear){                           
                                for( let monthInstallment = 0; monthInstallment < Math.max( cfResp.data.pDone[ index ].installments, 1 ); monthInstallment++ )
                                {
                                    cfpItems[ 0 ].products[ pIndex ].done[ cfResp.data.pDone[ index ].month + monthInstallment - 1 ] += paymentFraction;
                                } }
    
                                
                                else{          
                                    
                                    
    
                                    const changeStep = 12 - cfResp.data.pDone[index].month + 1 //  12 - 12 + 1 = 1
                                    const changeMonths = cfResp.data.pDone[index].installments - changeStep // 5 - 2 = 3 -->> 1 2 3
                                    //console.log(changeStep);//console.log(changeMonths)
                                    for( let monthInstallment = 0; monthInstallment < changeMonths; monthInstallment++ )
                                    {
    
    
    
    
                                        cfpItems[ 0 ].products[ pIndex ].done[ monthInstallment ] += paymentFraction;
                                    } }
        
                                
                                            
    
    
                            }
                        }
                        }
    
    
    
                    }
                }

                if( cfResp.data.expenses )
                {//console.log('cfResp.data');//console.log(cfResp.data)
                setThisCfpItems(cfpItems[0].products)
             
                    for( let index = 0; index < cfResp.data.expenses.length; index++ )
                    {
                        //TODO change expense var name
                        //TODO change values after the current month
                        let curProduct = cfResp.data.expenses[ index ];
                        let cfe = curProduct.cfe;
                        let name = curProduct.name;
                        let done = [ curProduct.jan, curProduct.feb, curProduct.mar, curProduct.apr, curProduct.may, curProduct.jun, curProduct.jul, curProduct.aug, curProduct.sep, curProduct.oct, curProduct.nov, curProduct.dece ];
                        let target = [ curProduct.jant, curProduct.febt, curProduct.mart, curProduct.aprt, curProduct.mayt, curProduct.junt, curProduct.jult, curProduct.augt, curProduct.sept, curProduct.octt, curProduct.novt, curProduct.dect ];
                        cfeItems[ 0 ].expenses.push( { cfe: cfe, name: name, done: done, target: target } );
                    }
                }
    
                if( cfResp.data.aliquot )
                {
                    aliquotTable = cfResp.data.aliquot;
                }
    
                if( cfResp.data.taxes )
                {   
                    monthTaxes = cfResp.data.taxes;
                }
    
    
            } ).catch(err=>{setIsLoading(false); monthTaxes = new Array(12).fill(0); aliquotTable = 0;});
                //drawTable(); 
                
                
            drawTable()

            setIsLoading(false)
        }
  
        const drawTable = () =>
        {
            /*const MonthTableHeader = document.getElementsByName( "ModelCashflowTable" )[ 0 ].childNodes[ 0 ].childNodes[ 0 ];
            const RevenuesTableHeader = document.getElementsByName( "ModelCashflowTable" )[ 0 ].childNodes[ 1 ].childNodes[ 1 ];
            const TaxesTableHeader = document.getElementsByName( "ModelCashflowTable2" )[ 0 ].childNodes[ 0 ].childNodes[ 1 ];
            const Accummulated = document.getElementsByName( "ModelCashflowTable3" )[ 0 ].childNodes[ 0 ].childNodes[ 2 ];
            const ExpensesTableHeader = document.getElementsByName( "ModelCashflowTable3" )[ 0 ].childNodes[ 0 ].childNodes[ 0 ];
            const ResultTableHeader = document.getElementsByName( "ModelCashflowTable3" )[ 0 ].childNodes[ 0 ].lastChild;
            */const monthOffset = ( selectedSemester === 2 ) ? 6 : 0;
            let monthRevenues = Array( 12 ).fill( 0 );
            
            let monthExpenses = Array( 12 ).fill( 0 );
            let monthResult = Array( 12 ).fill( 0 );
            let monthAccumulated = Array( 12 ).fill( 0 );
            let monthResultWithAccumulated = Array( 12 ).fill( 0 );
            setExpensesSelection( [] );
    
            for( let index = 0; ( index < cfpItems[ 0 ].products.length ); index++ )
            {
                let cfp = cfpItems[ 0 ].products[ index ];
    
                for( let monthIndex = 0; monthIndex < 12; monthIndex++ )
                {
                    monthRevenues[ monthIndex ] += cfp.done[ monthIndex ];
                }
            }
    
            for( let index = 0; ( index < cfeItems[ 0 ].expenses.length ); index++ )
            {
                let cfe = cfeItems[ 0 ].expenses[ index ];
    
                for( let monthIndex = 0; monthIndex < 12; monthIndex++ )
                {
                    monthExpenses[ monthIndex ] += cfe.done[ monthIndex ];
                }
            }
    
            for( let index = 0; ( index < cfpItems[ 0 ].products.length ); index++ )
            {
                let cfp = cfpItems[ 0 ].products[ index ];
                let td = [];
    
                for( let monthIndex = 0; monthIndex < 6; monthIndex++ )
                {
                    td.push
                    (
                        <td key={ 'cfp_' + uuidv4() }>
                            <div className='ModelCashflowLabelLeft'>{ StringMask( cfp.target[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' ) }</div>
                            <div className='ModelCashflowLabelRight'>{ StringMask( cfp.done[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' ) }</div>
                        </td>
                    );
                }
    
                cfpList[ index ] =
                    <tr id={ 'p_' + cfp.cfp } key={ 'p_' + index }>
                        <td><div>{ cfp.name }</div></td>
                        { td }
                    </tr>;
            }
            setThisCfeItems(cfeItems[0].expenses)
            for( let index = 0; ( index < cfeItems[ 0 ].expenses.length ); index++ )
            {
                let cfe = cfeItems[ 0 ].expenses[ index ];
                let td = [];
    
                
                for( let monthIndex = 0; monthIndex < 6; monthIndex++ )
                {
                    td.push
                    (
                        <td key={ 'cfe_' + uuidv4() } >
                            <div className='ModelCashflowLabelLeft'>{ StringMask( cfe.target[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' ) }</div>
                            <div className='ModelCashflowLabelRight'>{(cfeItems[ 0 ].expenses[ index ].done[ monthOffset + monthIndex ] !== undefined) ? StringMask( cfe.done[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' ) : StringMask(  0 , { type:'2DigitFraction' }, '' ) }</div>
                        </td>
                    );
                } 
                
                cfeList[ index ] =
                    <tr id={ 'e_' + cfe.cfe } key={ 'e_' + index }>
                        <td><div>{ cfe.name }</div></td>
                        { td }
                    </tr>;
    
                setExpensesSelection(expensesSelection =>[ ...expensesSelection, { label:cfeItems[ 0 ].expenses[ index ].name, value: index } ] );
            }
    
            for( let monthIndex = 0; monthIndex < 12; monthIndex++ )
            {
                //TODO: Check rbt12
                //TODO: Port logic to back end and also add current month as limit
                monthAccumulated[ monthIndex ] = ( monthIndex > 0  &&  monthIndex <=  currentMonth && selectedYear<=currentYear) ? monthResultWithAccumulated[ monthIndex - 1 ] : 0;
                monthResult[ monthIndex ] = monthRevenues[ monthIndex ] - monthExpenses[ monthIndex ] - monthTaxes[ monthIndex ] //+ monthAccumulated[ monthIndex ];
    
                monthResultWithAccumulated[monthIndex] = monthResult[monthIndex] //+ monthAccumulated[monthIndex]
            }
            


            setMonthExpenses(monthExpenses)
            setMonthAccumulated(monthRevenues)
            setMonthResult(monthResult)
            setThisMonthTaxes(monthTaxes)



            /*for( let monthIndex = 0; monthIndex < 6; monthIndex++ )
            {
                //TODO: quando começa a zerar os tributos do cara
                //TODO: Ajustar rbt para todos os meses
                //TODO: Resultado negativo de dezembro para janeiro e de junho para julho
                RevenuesTableHeader.childNodes[ monthIndex + 1 ].textContent = StringMask( monthRevenues[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' );
                TaxesTableHeader.childNodes[ monthIndex + 1 ].textContent = StringMask( (monthTaxes[ monthOffset + monthIndex ]).toFixed( 2 ), { type:'2DigitFraction' }, '' );
                ExpensesTableHeader.childNodes[ monthIndex + 1 ].textContent = StringMask( monthExpenses[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' );
                ResultTableHeader.childNodes[ monthIndex + 1 ].textContent = StringMask( monthResult[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, ( monthResult[ monthOffset + monthIndex ] < 0 ) ? '-' : '' );
                Accummulated.childNodes[ monthIndex + 1 ].textContent = StringMask( monthAccumulated[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, ( monthAccumulated[ monthOffset + monthIndex ] < 0 ) ? '-' : '' );
            }*/
    
            setTableCFP( [ ...cfpList ] );
            setTableCFE( [ ...cfeList ] );
        }
    
        const checkSelectedValue= async( index ) =>
        {
            const selectorExp = document.getElementsByName('expensesSelector');
            var inputCurrencyCost = document.getElementById('currencyInput')
            var itemNumber = selectorExp[0].value;
            cfeItems[ 0 ].expenses[itemNumber].done[index] =parseFloat(StringMask( inputCurrencyCost.value, { type:'float' }, '' ));
    
            let userId = UserStore.getUserId();
            let token = UserStore.getToken();
            document.getElementById( 'confirmBtn' ).innerHTML = "Salvando";
            await axios.put
            (
                'https://j6q3fg67zatdipf2wksmrskqqq0cqcfo.lambda-url.us-east-2.on.aws/',
                {
                    company: userId,
                    token: token,
                    cfe:cfeItems[ 0 ].expenses[ itemNumber ].cfe,
                    month: ( index + 1 ),
                    done: parseFloat( StringMask( inputCurrencyCost.value, { type:'float' }, '' ) )
                }
            );
            document.getElementById( 'confirmBtn' ).innerHTML = "Salvo";
            //drawTable();
            closePopUp();
        }
    
        const inputMaskHandle = () =>
        {
            var inputCurrencyCost = document.getElementById('currencyInput')
            inputCurrencyCost.value = StringMask( inputCurrencyCost.value, { type:'2DigitFraction' }, '' );
        }
    
        const editEfetiveCost = ( index ) =>
        {
            setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF',width:'40rem',height:'20rem',paddingLeft:'1.5rem'}} onClick={(event) => event.stopPropagation()}>
                            <text className="CashFlowBtnClosePopUp" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'0.5rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <div style={{display:'flex', flexDirection:'row', width:'20rem',margin:'0rem 0rem 1rem 2rem'}}>
                                <text className="journeyFWQQuestionText" style={{fontSize:'1.4rem'}}>Mês: {monthNames[index]}</text>
                                <text className="journeyFWQQuestionText" style={{marginLeft:'auto',fontSize:'1.4rem'}}>Ano: {selectedYear}</text>
                            </div>
                            <div style={{height:'0.1rem', backgroundColor:'#4a3bac',width:'105%',margin:'0rem 0rem 1rem 2rem' }}></div>
                            <div style={{display:'flex', flexDirection:'row', width:'20rem',margin:'0rem 0rem 1rem 2rem' ,gap:'2rem'}}>
                                <div style={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>
                                    <text className="journeyFWQQuestionText" >Custo/Despesa</text>
                                    <Select name='expensesSelector' className='actionPlanPopupSelector' styles={ selecStyles }  options={ expensesSelection } defaultValue={ expensesSelection.label } placeholder='' />
                                </div>
                                    <div style={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>
                                            <text className="journeyFWQQuestionText" >Preço</text>
                                            <input id='currencyInput' className="CashFlowInputPopUp" onChange={inputMaskHandle}></input>
                                        </div>
                                    <div>
                                </div>
                            </div>
                            <div id='confirmBtn' className='CashFlowConfirmbtn' style={{margin:'0rem 0rem 1rem 2rem'}} onClick={()=>checkSelectedValue(index)}>
                                Confirmar Custo Efetivo
                            </div>
                        </div>
                    </div>
            )
        }
    
        const closePopUp = () =>
        {
            setPopUp(<div></div>)
        }
        useEffect( () => { fillCFList(thisCurrentDate.getFullYear()) }, [] );
        
    //console.log(openMenuType)
    
    const closeEdit = () =>{
setIsEditExpense(false); setExpenseData(null)

    }
  return (
    <div className={styles.mobileCashFlow}>
        {isEditExpense && <EditExpense fillCFList={()=>fillCFList(selectedYear)} expense={expenseData} closeEdit={closeEdit}/>}
        
        <div className={styles.selects}>



        

        
        
        </div>    




        <ValueCard title="Receita Total" value={totalSales} monthIndex={selectedMonthIndex}/>
        <ValueCard title="Resultado" value={totalSales - totalTributes - totalExpenses} monthIndex={selectedMonthIndex}/>            
             <div className={styles.selectedItem}>
                <span onClick={()=>setIsExpenses(prev=>!prev)} style={{color: !isExpenses ? '#000000' : undefined}}>Receitas</span>
                <span onClick={()=>setIsExpenses(prev=>!prev)} style={{color: isExpenses ? '#000000' : undefined}}>Despesas</span>
            </div>       

        {isExpenses ? 
                    <ExpensesContainer  editExpense={editExpense} expenses={thisCfeItems} monthIndex={selectedMonthIndex} /> 
                    : 
                    <ProductsContainer products={salesList} monthIndex={selectedMonthIndex} convertToCurrency={convertToCurrency}/>
        }        
        <ValueCard title="Tributos" value={totalTributes} monthIndex={selectedMonthIndex }/>        
        <ValueCard title="Total de Custo" value={totalExpenses} monthIndex={selectedMonthIndex }/>        
            
    </div>
  )
}

export default MobileCashFlow