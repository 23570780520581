import React, { useEffect, useState } from 'react'
import styles from './SalesHistory.module.sass'
import { StringMask } from '../../../library/StringMasks'
import axios from 'axios'
import UserStore from '../../../../store/userStore'
const SalesHistory = ({clientId, clientName, month, close, productName, setIsLoadingScreen, getSales, setSelectedSale, editSale}) => {


    const client = clientName===null ? 'sem cliente identificado' :  clientName
    const [clientSales, setClientSales] = useState([] )
    const [isLoading, setIsLoading] = useState(false )
    const [ haveError, setHaveError ] = useState(false)     
    
















    const [ removeExpenseWarn, setRemoveExpenseWarn ] = useState(false)
    const removeSale = async( {sale} ) =>
    {console.log(sale)
        setRemoveExpenseWarn(false)
        setIsLoadingScreen(true)
        
            await axios.delete( 'https://vsp4cmb53av3sywzzjw62pbfue0sbfyh.lambda-url.us-east-2.on.aws/', { data: { sale:sale, company:UserStore.getUserId(), token:UserStore.getToken() } } ) 
           .then(resp=>{getSales(); close()})
           
           
           
           .catch(err=>{setIsLoadingScreen(false)})
            
                setIsLoadingScreen(false);
        
        
    
    };
    useEffect(()=>{
        const getClientSales = async() =>{

            setIsLoading(true)
            await axios.get('https://7krh6cladf4fz4s3u2zlkf7ysu0pqpsl.lambda-url.us-east-2.on.aws/', {params: {clientId: clientId, companyId: UserStore.getUserId()}})
            .then(resp=>{console.log(resp.data.sales); setIsLoading(false); setClientSales(resp.data.sales)}).catch(err=>{setIsLoading(false); setHaveError(true)})     
        }


        getClientSales()
    }, [])
  return (
    <div className={styles.salesHistory}>




        <div className={styles.content}>
           <span className={styles.close} onClick={close}>x</span>  
           










            {clientSales.length>0 && <p>Vendas do produto <span className={styles.info}>{productName}</span> {clientName!==null && 'pro cliente'}<span className={styles.info}>{client}</span></p> }   {haveError &&                <div className={styles.saleCard}>
                    <div className={styles.topInfo}>
                        <p>ERRO, POR FAVOR RECARREGUE A PAGINA</p>                   
                    </div>
                </div>}  {isLoading &&                <div className={styles.saleCard}>
                    <div className={styles.topInfo}>
                        <p>CARREGANDO</p>                   
                    </div>
                </div>} 
           <div className={styles.clientSales}>{clientSales.length>0 && clientSales.map(sale=>(
               <>
                <div className={styles.saleCard}>
                    <div className={styles.topInfo}>
                        <p>Dia: {sale.saleDate}</p><p>Quantidade: {sale.quantity}</p>       
                        <p>Preço da Unidade: {StringMask( sale.soldPrice.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' ) }</p>
                        <p>Total: {StringMask( (sale.soldPrice * sale.quantity).toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' ) }</p>                      
                        {sale.recurrenceMonths>1 && <p>Recorrência {sale.recurrenceMonths} meses</p>  }                               
                        {sale.installments>1 && <p>{sale.installments} Parcelas  </p>  }                      
                    </div>
                    <div className={styles.bottomInfo}>
                        <i className="fa fa-pen" onClick={()=>{setSelectedSale({...sale, curPrice: sale.sCurPrice, value: sale.sSellPrice, sale: sale.id}); editSale(); close();}}/>
                        <i className="fa fa-trash" onClick={()=>removeSale({sale: sale.id})}/> 
                    </div>    
                </div>
               </>



           ))}</div>

        </div>



    </div>
  )
}

export default SalesHistory