import React from "react";
import { CProgress, CProgressBar } from '@coreui/react'
import "../journey/JourneyKnowingYourModel.css";
import menuJourney from "../../assets/icons/iconMenuJourney.png";
import JourneysideMenu from "./JourneysideMenu";
import { useEffect } from "react";
import axios from "axios";
import UserStore from "../../store/userStore";
import MobileKYMStructure from "./mobileJourney/mobileKYMStructure/MobileKYMStructure";
import { botconversaWebhook } from "../../functions/botconversaWebhook";


const JourneyIntroduction = () => {
    const pageLocale = 'Jornada'


    
    useEffect(()=>{

        botconversaWebhook({locale: pageLocale, action: 'Abriu Introdução'})
        const postStep = async(step) =>{
            await axios.put('https://iz5smq7lxhsxjtskiegdcibiru0vwhjh.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), token: UserStore.getToken(), journeyStep: step})
        }
        postStep(1) 
    }, [])



    var pagesVisited=[];
    var pagesCounter = 0;
    var percentProgress = 0;
    var numberOfPages = 47;


    const OpenSideDiv = ()=>{
        let sideDiv = document.getElementById('journey1SideDiv');
        sideDiv.style.display='block';
    }


    const checkPages = (totalPages) =>{

        pagesVisited[0] = 1;
        pagesVisited[1] = 1;
        pagesVisited[2] = 1;
        pagesVisited[3] = 1;
        pagesVisited[4] = 0;

        for(var i=0; i < 5;i++){
            pagesVisited[i] ? (pagesCounter=pagesCounter + 1) : (pagesCounter=pagesCounter);
        }

        percentProgress = Math.round((pagesCounter/totalPages)*100);
        return(percentProgress);

    }

    useEffect(()=>{checkPages(numberOfPages);},[]);

    const screenWidth = window.screen.width

    const type= 'Entendendo seu modelo de negócio'
    const title = 'Introdução'
    const content = `                                        Seja bem vindo a maior plataforma de modelos de negócios e gestão para pequenos empreendedores do Brasil. 
                            Sou Nelson Naibert – Founder da MEconecte – Economista e Contador – empreendedor a mais de 25 anos -  
                            Vocês sabem que a cada 10 empresas que abrem no Brasil 3 quebram no primeiro ano e mais 5 no segundo ano. 
                            Missão: ajudar os empreendedores a não fazerem parte da estatística e gerar resultados. 
                            A plataforma MEconecte vai te ajudar a organizar sua ideia, planejar e executar seu negócio. De forma simples e automática, a partir de informações básicas. 
                            Na plataforma vamos trabalhar com 4 ferramentas que são importantes para o sucesso do seu negócio. 
`
    const prevVideoPath = '/journey/introduction'
    const nextVideoPath = '/journey/introduction/video'
    const stepPercent = 2
    const list = `
                                <li className="journeyKYMTextlist">1. Modelo de Negócio</li>
                                <li className="journeyKYMTextlist">2. Plano de Ação</li>
                                <li className="journeyKYMTextlist">3. Fluxo de caixa</li>
                                <li className="journeyKYMTextlist">4. Dashboard de indicadores</li>
                                <ul>
                                    <li className="journeyKYMTextlist2">a. Performance</li>
                                    <li className="journeyKYMTextlist2">b. Financeiro</li>
                                </ul>
                            	`



    return(
        <>
        {screenWidth>780 ? 
        <div className="journeyKYMBackground">
            <img src={menuJourney} alt-='' className="journeyKYMIconMenu" onClick={OpenSideDiv}>
            </img>
            <div style={{position:'relative',
                        top: '1.7rem',
                        left: '6.8rem',
                        width:'12rem'}}>
                <text className="journeyKYMHeader">{type}</text>
            </div>
            <div className="journeyKYMTextBackground" style={{position:'relative', top:'9rem', left:'2rem'}}>
                <text className="journeyKYMTitleBlurDiv">{title}</text>
                    <div  style={{width:'50rem', marginTop:'1rem'}}>
                        <text className="journeyKYMText" style={{fontSize:'1.1rem'}}>
                        
                                        Seja bem vindo a maior plataforma de modelos de negócios e gestão para pequenos empreendedores do Brasil. 
                            Sou Nelson Naibert – Founder da MEconecte – Economista e Contador – empreendedor a mais de 25 anos -  
                            Vocês sabem que a cada 10 empresas que abrem no Brasil 3 quebram no primeiro ano e mais 5 no segundo ano. 
                            Missão: ajudar os empreendedores a não fazerem parte da estatística e gerar resultados. 
                            A plataforma MEconecte vai te ajudar a organizar sua ideia, planejar e executar seu negócio. De forma simples e automática, a partir de informações básicas. 
                            Na plataforma vamos trabalhar com 4 ferramentas que são importantes para o sucesso do seu negócio. 
                            <ul>
                                <li className="journeyKYMTextlist">1. Modelo de Negócio</li>
                                <li className="journeyKYMTextlist">2. Plano de Ação</li>
                                <li className="journeyKYMTextlist">3. Fluxo de caixa</li>
                                <li className="journeyKYMTextlist">4. Dashboard de indicadores</li>
                                <ul>
                                    <li className="journeyKYMTextlist2">a. Performance</li>
                                    <li className="journeyKYMTextlist2">b. Financeiro</li>
                                </ul>
                            </ul>	   
                        



                        </text>                         
                    </div>
            </div>  
            <div className="journeyKYMVideoDivIntro">
            <a href="/journey/introduction/video" className="journeyKYMlink" style={{textDecoration:'none'}}>
                <div style={{display:'flex',
                            flexDirection:'column',
                            marginLeft:'1.5rem',
                            marginTop:'0.8rem',
                            marginBottom:'0.8rem'}}>
                    <text className="journeyKYMVideoUpperTxt">Próximo conteúdo</text>
                    <text className="journeyKYMVideoLowerTxt">Vídeo</text>
                </div>
                    <div className="journeyKYMArrowDiv" style={{backgroundColor:'transparent'}}>
                        <text className="journeyKYMArrow" style={{backgroundColor:'transparent', 
                        }}>&#8250;</text>
                    </div>
            </a>
            </div>    
            <text className="journeyKYMprogressText" style={{position: 'absolute', top:'62px', right: '188px'}}>
                Seu progresso
            </text>      
            <div style={{position:'absolute',
                        top:'65px',
                        right:'88px',
                        paddingLeft:'0.4rem',
                        paddingRight:'0.4rem',
                        borderRadius:'0.3rem',
                        backgroundColor:'#FFFFFF',
                        width:'fit-content'}}>
                <text className="journeyKYMprogressBarText">
                    {stepPercent}% completo
                </text>
            </div>
            <div style={{position:'absolute',
                        top:'93px',
                        right:'10px',
                        width:'20rem'}}>
                <CProgress height={ 6 }  >
                    <CProgressBar value={ stepPercent } />
                    
                </CProgress>
            </div>
            <JourneysideMenu></JourneysideMenu>
        </div>
        
        
        
        : 
        
        <MobileKYMStructure height='910px' stepPercent={stepPercent} prevVideoPath={prevVideoPath} nextVideoPath={nextVideoPath} content={content} type={type} title={title} OpenSideDiv={OpenSideDiv}/>
        
        }</>
    )
}

export default JourneyIntroduction;