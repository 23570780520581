import React, { useEffect, useRef, useState } from 'react'
import styles from './VideoContainer.module.sass'
import axios from 'axios';
import UserStore from '../../../../store/userStore';

const VideoContainer = ({getAllGroupsInfo, videoUrl, nextStep, step, group, wasWatched, openAbout, openAnnotations, openEbooks}) => {

  

  const [watched, setWatched] = useState(!!wasWatched)
  const videoRef = useRef(); 
  let w = false
  
  
  useEffect(() => {
    setWatched(!!wasWatched)
    if (!wasWatched ) {
      
    
    const video = videoRef.current;

    const handleProgress = async() => {
      const { currentTime, duration } = video;
      const progress = (currentTime / duration) * 100; 
      if (progress >= 80) {
        setWatched(true)
        
        video.removeEventListener('timeupdate', handleProgress);
        //alert('Usuário assistiu a 80% do vídeo');  

        await axios.post('https://fvrw74cj2zkivukmkai7gostme0kwpue.lambda-url.us-east-2.on.aws/', {
          
        
        
        userId: UserStore.getUserId(), step: step, group: group}).then(resp=>{
            getAllGroupsInfo()
        


        })
      }
    };




    if (!watched) {}
        video.addEventListener('timeupdate', handleProgress);      
    


    return () => {
      video.removeEventListener('timeupdate', handleProgress);
    };
  
  
  }
  }, [wasWatched, videoUrl]);


  return (
    <div className={styles.videoContainer}>         
        <div className={styles.video}>
            <video controls={true} src={videoUrl} ref={videoRef}></video>

            <div className={styles.videoOptions}>

              <div className={styles.topOptions}>

                <div className={styles.option } onClick={openAbout}><span>Sobre</span></div>                
                <div className={styles.option } onClick={openAnnotations}><span>Anotações</span></div>      
              </div>

                <div className={styles.bottomOptions}>
                    <div className={styles.option } onClick={openEbooks}><span>Ebooks</span></div>                 
                    <div style={{background: '#571D94'}} onClick={nextStep} className={styles.nextStepOption }><span>Proximo</span><span>&gt;</span></div>
                  </div>               

            </div>
        </div>

    </div>
  )
}

export default VideoContainer