import { useEffect } from "react";
import { CardsContainer } from "../cardsContainer/CardsContainer";
import styles from './JourneyQuestions.module.sass'
const JourneyForWhoModel = () =>{

    const screenWidth = window.screen.width

    const changeBackgroundColor = () =>{


        const boardContent = document.querySelector('.BoardContent')
    
        if (screenWidth>800) {
            boardContent.style.backgroundColor = '#270D43'   
            boardContent.style.padding = '30px'
            boardContent.style.borderRadius = '22px'        
        }else{
            boardContent.style.backgroundColor = '#ffffff'   
        }
      
    }
    useEffect(()=>{   
        changeBackgroundColor()
    }, [])
    return(

        <div className={styles.forWho} style={{padding: screenWidth<800 && '25px 0px 85px 0px'}}>
            <CardsContainer/>
        </div>
    );


}


export default JourneyForWhoModel;
