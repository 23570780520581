import React from 'react';
import '@coreui/coreui/dist/css/coreui.min.css'
import { useState, useEffect } from 'react';
import UserStore from '../../store/userStore';
import axios from 'axios';

let tractionList = [];
let changedValues = [];
let availableYears = [];
let firstColumnYear = 2022;

const Traction = () =>
{
    const [ tableTraction, setTableTraction ] = useState( [] );
    const [ loadingTractionList, setLoadingTractionList ] = useState( true );
    const [ screenDebounceTime, setScreenDebounceTime ] = useState( true );

    const emptyPoductsTractionWarn = 
        <tr>
            <td colSpan={ 9 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
                Você não possui produtos cadastrados.<br/>
                Por favor, prossiga para a aba 'Receitas' acima e adicione os produtos do seu negócio.
            </td>
        </tr>;

    const loadingTableTractionWarn = 
        <tr>
            <td colSpan={ 9 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
                Por favor aguarde, carregando lista de produtos.
            </td>
        </tr>;

    const fillTractionList = async() =>
    {
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();

        axios.get( 'https://qpm6xhpvprt5obvsqkp2pww4j40wknjp.lambda-url.us-east-2.on.aws/', { params: { company: userId, token: token } } ).then( ( resp ) =>
        {
            if( resp.data.products )
            {
                for( let index = 0; index < resp.data.products.length; index++ )
                {
                    let productTraction = resp.data.products[ index ];

                    let productIndex = tractionList.findIndex( ( product ) => { return product.product === productTraction.product } );
                    
                    if( !availableYears.includes( productTraction.year ) )
                    {
                        availableYears.push( productTraction.year );
                    }

                    if( productIndex === -1 )
                    {
                        tractionList.push( { product: productTraction.product, name: productTraction.name, traction: [ { year: productTraction.year, value: productTraction.value } ] } );
                    }
                    else
                    {
                        tractionList[ productIndex ].traction.push( { year: productTraction.year, value: productTraction.value } );
                    }
                }
            }

            firstColumnYear = ( new Date().getFullYear() > ( Math.min.apply( null, availableYears ) + 2 ) ) ? ( new Date().getFullYear() - 2 ) : Math.min.apply( null, availableYears );

            setLoadingTractionList( false );
            buildTableContent();
        } );
    }

    const checkUpdateError = ( currentValue ) =>
    {
        if( currentValue !== 0 )
        {
            let productIndex = tractionList.findIndex( ( product ) => { return product.product === currentValue } );
            
            if( productIndex !== -1 )
            {
                alert( 'Erro ao editar produto ' + tractionList[ productIndex ].name + ', por favor tente novamente mais tarde.' );
            }
        }
    }

    const confirmEdition = async() =>
    {
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();

        if( changedValues.length > 0 )
        {
            const resp = await axios.put( 'https://n65m5qs6chykmarog425cvjinu0ccelz.lambda-url.us-east-2.on.aws/', { params: { company: userId, token: token, toUpdate: changedValues } } );

            if( resp.data.length > 0 )
            {
                resp.data.forEach( ( currentValue ) => checkUpdateError( currentValue ) );
                changedValues = [];
                buildTableContent();
            }
            else
            {
                alert( 'Erro no servidor. Tente novamente mais tarde.' )
            }
        }
    }

    const modifyTraction = ( product, year, direction ) =>
    {
        const productIndex = tractionList.findIndex( ( productTraction ) => { return productTraction.product === product; } );
        
        if( productIndex !== -1 )
        {
            const yearIndex = tractionList[ productIndex ].traction.findIndex( ( traction ) => { return traction.year === year; } );

            if( yearIndex !== -1 )
            {
                const changedValueIndex = changedValues.findIndex( ( changed ) => { return ( changed.product === product ) && ( changed.year === year ) } );
                
                tractionList[ productIndex ].traction[ yearIndex ].value += direction * 5;
                buildTableContent();

                if( changedValueIndex !== -1 )
                {
                    changedValues[ changedValueIndex ].value = tractionList[ productIndex ].traction[ yearIndex ].value;
                }
                else
                {
                    changedValues.push( { product: product, year: year, value: tractionList[ productIndex ].traction[ yearIndex ].value } );
                }
            }
        }
    }

    const buildTableContent = () =>
    {
        const ModelTractionTableHeader = document.getElementsByName( "ModelGrowthTable" )[ 0 ].childNodes[ 0 ].childNodes[ 0 ];
        let   tableContent = [];

        ModelTractionTableHeader.childNodes[ 2 ].childNodes[ 0 ].textContent = firstColumnYear + 0;
        ModelTractionTableHeader.childNodes[ 3 ].childNodes[ 0 ].textContent = firstColumnYear + 1;
        ModelTractionTableHeader.childNodes[ 4 ].childNodes[ 0 ].textContent = firstColumnYear + 2;
        ModelTractionTableHeader.childNodes[ 5 ].childNodes[ 0 ].textContent = firstColumnYear + 3;
        ModelTractionTableHeader.childNodes[ 6 ].childNodes[ 0 ].textContent = firstColumnYear + 4;
        ModelTractionTableHeader.childNodes[ 7 ].childNodes[ 0 ].textContent = firstColumnYear + 5;

        for( let iProduct = 0; iProduct < tractionList.length; iProduct++ )
        {
            let productsList = [];
            let productTractionYears = tractionList[ iProduct ].traction;

            for( let year = firstColumnYear; year < ( firstColumnYear + 6 ); year++ )
            {
                const product = tractionList[ iProduct ].product;
                const selectedTractionYear = productTractionYears.find( ( productTraction ) => { return productTraction.year === year; } );

                productsList.push(
                    <td key={ product + '' + year }>
                        <div className='ModelGrowthPercValuesBox'>
                            <div className='ModelGrowthPercValuesBoxLeft' >-</div>
                            <div className='ModelGrowthPercValuesBoxValue'>{ ( selectedTractionYear !== undefined ) ? selectedTractionYear.value + '%' : '---' }</div>
                            <div className='ModelGrowthPercValuesBoxRight' >+</div>
                        </div>
                    </td>
                );
            }

            tableContent.push(
                <tr key={ tractionList[ iProduct ].product }>
                    <td colSpan={ 2 }>{ tractionList[ iProduct ].name }</td>
                    { productsList }
                    <td></td>
                </tr>
            );

            setTableTraction( tableContent );
        }
    }

    const changeYear = ( direction ) =>
    {
        firstColumnYear = firstColumnYear + direction;
        buildTableContent();
    }

    useEffect( () => { buildTableContent(); fillTractionList(); setTimeout( () => { setScreenDebounceTime( false ); }, 1000 ); }, [] );

    return(
        <div className='FullBoardContent'>
            {/* <div className='BoardTitleBtnsInline'><div className="BoardBtnOk" onClick={ confirmEdition }>Confirmar valores ajustados</div></div> */}
            <div className="FullBoardContentTitle">Tração</div>
            <div className="FullBoardContentSubTitle">
                Aqui é possível configurar a meta de tração ano a ano, para os próximos 5 anos, de maneira a ter <br/>
                suas metas de vendas e lucros ajustadas. Utilize "+"/"-" para ajustar a tração desejado naquele mês.<br/>
                *É recomendado que a tração não exceda 20% ao ano.<br/>
                *Funcionalidade a ser liberada
            </div>
            <div className='HorizontalLineSeparator'/>
            <div style={{height:'100%'}}>
                <table name='ModelGrowthTable' className="ModelGrowthTable">
                    <thead>
                        <tr>
                            <th>Produto</th>
                            <th><div className='SimpleBtn' onClick={ () => changeYear( -1 ) }>{ '<' }</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='SimpleBtn' onClick={ () => changeYear( 1 ) }>{ '>' }</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        { ( screenDebounceTime || loadingTractionList ) ? loadingTableTractionWarn : ( tableTraction.length !== 0 ) ? tableTraction : emptyPoductsTractionWarn }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Traction;