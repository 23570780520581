import React from 'react'
import styles from './DashboardPerformance.module.sass'
import { CircularProgressbar } from 'react-circular-progressbar'

const DashboardPerformance = ({monthGoals, colors, checkValue, globalRemainingMonthGoal, globalMonthSales, globalMonthTicket, globalMonthCAC, globalYearSales, yearGoals, globalYearExpenses, globalYearResult, screenDebounceTime, loadingDashboard, loadingMonthSalesWarn, tableMonthSales, emptyMonthSalesWarn}) => {
  
  console.log('month');console.log(globalMonthSales)

  
    return (
    <div className={styles.dashboardPerformance}>
        <div className={styles.leftContent}>


            <div className={styles.goalsCard}>
                <h2 className={styles.cardTitle}>Meta do Mês</h2>
                <div className={styles.progressBar}>
                  <CircularProgressbar
                    value={ 
                        //checkedRemainingMonthGoals
                        monthGoals
    
                    }
                    text={ checkValue(
                        //checkedRemainingMonthGoals
                        monthGoals
                        
                        ) + '%' }
                    strokeWidth={15}
                    styles={{
                        path: { stroke: `${colors.lightPurple}`, transition: 'stroke-dashoffset 0.5s ease 0s' },
                        text: { fill: '#000000', fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '16px' }
                    }}
                />
                </div>
            </div>


            <div className={styles.remainingMonthGoal}>
                <h2 className={styles.cardTitle}>Faltam (R$)</h2>
                <span className={styles.result}>{globalRemainingMonthGoal && globalRemainingMonthGoal}</span>
                <p>Para o atingimento da meta</p>
            </div>


            <div className={styles.globalMonthSales} style={{backgroundColor: colors.purple, color: '#ffffff'}}>
                <h2 className={styles.cardTitle}>Resultado do Mês (R$)</h2>
                <span className={styles.result}>{globalMonthSales && globalMonthSales}</span>
            </div>
        </div>



        <div className={styles.middleContent}>
            <div className={styles.topContent}>
                
                <div className={styles.card}>
                    <h2 className={styles.cardTitle}>Ticket Médio (R$)</h2>
                    <span className={styles.result}>{globalMonthTicket && globalMonthTicket}</span> 
                </div>
                
                <div className={styles.card}>
                    <h2 className={styles.cardTitle}>CPC (R$)</h2>
                    <span className={styles.result}>{globalMonthCAC && globalMonthCAC}</span>
                </div>

                <div className={styles.card}  style={{backgroundColor: colors.purple, color: '#ffffff'}}>
                    <h2 className={styles.cardTitle}>Receita Total do Ano (R$)</h2>
                    <span className={styles.result}>{globalYearSales && globalYearSales}</span>


                </div>

                <div className={styles.card}>
                                                                                            
                                    <table className="DashProductTable">
                                        

                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th style={ { paddingLeft: '5px', paddingRight: '5px' } }>Produto</th>
                                                <th style={ { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', width:'95px' } }>Quantidade</th>
                                                <th style={ { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', width:'120px' } }>Evolução (R$)</th>
                                                <th style={ { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', width:'95px' } }>Progresso</th>
                                            </tr>
                                        </thead>
                                       

                                        <tbody style={{ overflowY:'scroll'}}>



                                            { ( screenDebounceTime || loadingDashboard ) ? loadingMonthSalesWarn : ( tableMonthSales.length > 0 ) ? tableMonthSales : emptyMonthSalesWarn }
                                        </tbody>
                                    </table>



                </div>
            </div>



        </div>
        <div className={styles.rightContent}>
            <div className={styles.goalsCard}>
                <h2 className={styles.cardTitle}>Meta do Ano</h2>
                <div className={styles.progressBar}>
                  <CircularProgressbar
                    value={ 
                        //checkedRemainingMonthGoals
                        yearGoals
    
                    }
                    text={ checkValue(
                        //checkedRemainingMonthGoals
                        yearGoals
                        
                        ) + '%' }
                    strokeWidth={15}
                    styles={{
                        path: { stroke: `${colors.lightPurple}`, transition: 'stroke-dashoffset 0.5s ease 0s' },
                        text: { fill: '#000000', fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '16px' }
                    }}
                />
                </div>
            </div>


            <div className={styles.card} style={{backgroundColor: colors.purple, color: '#ffffff'}}>
                <h2 className={styles.cardTitle}>Custos Totais do Ano (R$)</h2>
                <span className={styles.result}>{globalYearExpenses && globalYearExpenses}</span>
            </div>

            <div className={styles.card} style={{backgroundColor: colors.purple, color: '#ffffff'}}>
                <h2 className={styles.cardTitle}>Resultado do Ano (R$)</h2>
                <span className={styles.result}>{globalYearResult && globalYearResult}</span>
            </div>

        </div>

    </div>
  )
}

export default DashboardPerformance