import React from 'react'
import styles from './LoadingScreen.module.css';
import { IconLoading } from '../imgsLoader/LoadIconImgs';
import LoadingIcon from './loadingIcon/LoadingIcon';
const LoadingScreen = () => {
  return (
    <div className={styles.loadingScreen}>
        <div>

            {/*<img src={IconLoading} alt=""/>*/}

            <LoadingIcon/>
            <p>Carregando</p>
            
            
            </div>
        
        
        </div>
  )
}

export default LoadingScreen