import React from 'react'
import styles from './MaterialCard.module.sass'
import { MaterialsPDFBg } from '../../../../imgsLoader/LoadBgImgs'
import UserStore from '../../../../../store/userStore'
const MaterialCard = ({url, title, material}) => {
    const token = UserStore.getToken()
    const company = UserStore.getUserId()
    const materialss =  'AAAA'
    const downloadFile = () =>{
    const material = window.location
    console.log(material)


    }
  return (
    <div className={styles.materialCard}>    
        <div className={styles.materialType}>EBOOK</div>
        <div className={styles.topContent}><img src={MaterialsPDFBg} alt=""/></div>
        <div className={styles.bottomContent}>
            
            

        <div className={styles.bottomTitle}><h2>{title}</h2></div>
        
        {

<a href={url} target ='_blank' rel='noreferrer' style={ { textDecoration:'none' } }>

        <div className={styles.download}><button onClick={downloadFile}>Download</button> 
        </div>
        </a>
        }  
                </div>    
    </div>
    

  )
}

export default MaterialCard