import AFolhaDeSaoPauloLogo from "../../assets/logos/AFolhaDeSaoPauloLogo.png";
import AbrilModaLogo from "../../assets/logos/AbrilModaLogo.png";
import AirbnbLogo from "../../assets/logos/AirbnbLogo.png";
import AirtelLogo from "../../assets/logos/AirtelLogo.png";
import AmericanAirlinesLogo from "../../assets/logos/AmericanAirlinesLogo.png";
import AmericanExpressLogo from "../../assets/logos/AmericanExpressLogo.png";
import AmzKindleLogo from "../../assets/logos/AmzKindleLogo.png";
import AmzLogo from "../../assets/logos/AmzLogo.png";
import AmzManagedBlockchainLogo from "../../assets/logos/AmzManagedBlockchainLogo.png";
import AmzWebServicesLogo from "../../assets/logos/AmzWebServicesLogo.png";
import AppleLogo from "../../assets/logos/AppleLogo.png";
import AvonLogo from "../../assets/logos/AvonLogo.png";
import BasfLogo from "../../assets/logos/BasfLogo.png";
import BayerLogo from "../../assets/logos/BayerLogo.png";
import BestBuyLogo from "../../assets/logos/BestBuyLogo.png";
import BetoCarreroWorldLogo from "../../assets/logos/BetoCarreroWorldLogo.png";
import BirdLogo from "../../assets/logos/BirdLogo.png";
import BoschLogo from "../../assets/logos/BoschLogo.png";
import BradescoLogo from "../../assets/logos/BradescoLogo.png";
import BrainpoolLogo from "../../assets/logos/BrainpoolLogo.png";
import BrighterLivingLogo from "../../assets/logos/BrighterLivingLogo.png";
import BrillianceLogo from "../../assets/logos/BrillianceLogo.png";
import BuffetLogo from "../../assets/logos/BuffetLogo.png";
import CacauShowLogo from "../../assets/logos/CacauShowLogo.png";
import Car2GoLogo from "../../assets/logos/Car2GoLogo.png";
import CarnegieLogo from "../../assets/logos/CarnegieLogo.png";
import CeweColorLogo from "../../assets/logos/CeweColorLogo.png";
import CeweLogo from "../../assets/logos/CeweLogo.png";
import CinemarkLogo from "../../assets/logos/CinemarkLogo.png";
import CirqueDuSoleilLogo from "../../assets/logos/CirqueDuSoleilLogo.png";
import CiscoLogo from "../../assets/logos/CiscoLogo.png";
import ClubeWineLogo from "../../assets/logos/ClubeWineLogo.png";
import CraigsListLogo from "../../assets/logos/CraigsListLogo.png";
import DellLogo from "../../assets/logos/DellLogo.png";
import DennerLogo from "../../assets/logos/DennerLogo.png";
import DeutschePostLogo from "../../assets/logos/DeutschePostLogo.png";
import DollarShaveClubLogo from "../../assets/logos/DollarShaveClubLogo.png";
import DropboxLogo from "../../assets/logos/DropboxLogo.png";
import EasySmartGridLogo from "../../assets/logos/EasySmartGridLogo.png";
import EbayLogo from "../../assets/logos/EbayLogo.png";
import EntocycleLogo from "../../assets/logos/EntocycleLogo.png";
import EvoluttoLogo from "../../assets/logos/EvoluttoLogo.png";
import FacebookLogo from "../../assets/logos/FacebookLogo.png";
import FelfelLogo from "../../assets/logos/FelfelLogo.png";
import FordLogo from "../../assets/logos/FordLogo.png";
import FoxconnLogo from "../../assets/logos/FoxconnLogo.png";
import FreitagLogo from "../../assets/logos/FreitagLogo.png";
import GELogo from "../../assets/logos/GELogo.png";
import GilletteLogo from "../../assets/logos/GilletteLogo.png";
import goCaseLogo from "../../assets/logos/goCaseLogo.png";
import GoogleLogo from "../../assets/logos/GoogleLogo.png";
import GrouponLogo from "../../assets/logos/GrouponLogo.png";
import HPLogo from "../../assets/logos/HPLogo.png";
import HapimagLogo from "../../assets/logos/HapimagLogo.png";
import HarleyDavidsonLogo from "../../assets/logos/HarleyDavidsonLogo.png";
import HeMLogo from "../../assets/logos/HeMLogo.png";
import HiltiLogo from "../../assets/logos/HiltiLogo.png";
import HotmartLogo from "../../assets/logos/HotmartLogo.png";
import HumbleBundleLogo from "../../assets/logos/HumbleBundleLogo.png";
import ibisBudgetLogo from "../../assets/logos/ibisBudgetLogo.png";
import IkeaLogo from "../../assets/logos/IkeaLogo.png";
import ImperfectFoodsLogo from "../../assets/logos/ImperfectFoodsLogo.png";
import InstagramLogo from "../../assets/logos/InstagramLogo.png";
import IntelLogo from "../../assets/logos/IntelLogo.png";
import iTunesLogo from "../../assets/logos/iTunesLogo.png";
import JcDecauxLogo from "../../assets/logos/JcDecauxLogo.png";
import JeronimoLogo from "../../assets/logos/JeronimoLogo.png";
import KickStarterLogo from "../../assets/logos/KickStarterLogo.png";
import LevissLogo from "../../assets/logos/LevissLogo.png";
import LinkedInLogo from "../../assets/logos/LinkedInLogo.png";
import LiveloLogo from "../../assets/logos/LiveloLogo.png";
import LufthansaLogo from "../../assets/logos/LufthansaLogo.png";
import MaryKayLogo from "../../assets/logos/MaryKayLogo.png";
import McDonaldsLogo from "../../assets/logos/McDonaldsLogo.png";
import McfFitLogo from "../../assets/logos/McfFitLogo.png";
import MercadoLivreLogo from "../../assets/logos/MercadoLivreLogo.png";
import MicrosoftLogo from "../../assets/logos/MicrosoftLogo.png";
import MieleLogo from "../../assets/logos/MieleLogo.png";
import MobilityLogo from "../../assets/logos/MobilityLogo.png";
import MonetizzeLogo from "../../assets/logos/MonetizzeLogo.png";
import MozillaLogo from "../../assets/logos/MozillaLogo.png";
import MyHammerLogo from "../../assets/logos/MyHammerLogo.png";
import NespressoLogo from "../../assets/logos/NespressoLogo.png";
import NetflixLogo from "../../assets/logos/NetflixLogo.png";
import NikeLogo from "../../assets/logos/NikeLogo.png";
import NintendoLogo from "../../assets/logos/NintendoLogo.png";
import OneWorldLogo from "../../assets/logos/OneWorldLogo.png";
import OpenDeskLogo from "../../assets/logos/OpenDeskLogo.png";
import OutbackLogo from "../../assets/logos/OutbackLogo.png";
import PaneraBreadLogo from "../../assets/logos/PaneraBreadLogo.png";
import PaybackLogo from "../../assets/logos/PaybackLogo.png";
import PeGLogo from "../../assets/logos/PeGLogo.png";
import PebbleLogo from "../../assets/logos/PebbleLogo.png";
import PepsicoLogo from "../../assets/logos/PepsicoLogo.png";
import PersonalNovelLogo from "../../assets/logos/PersonalNovelLogo.png";
import PlayStationLogo from "../../assets/logos/PlayStationLogo.png";
import PorscheLogo from "../../assets/logos/PorscheLogo.png";
import PPPLogo from "../../assets/logos/PPPLogo.png";
import RedbullLogo from "../../assets/logos/RedbullLogo.png";
import RenaultLogo from "../../assets/logos/RenaultLogo.png";
import ReservaInkLogo from "../../assets/logos/ReservaInkLogo.png";
import RichelieuLogo from "../../assets/logos/RichelieuLogo.png";
import RollsRoyceLogo from "../../assets/logos/RollsRoyceLogo.png";
import RufflesLogo from "../../assets/logos/RufflesLogo.png";
import RyanairLogo from "../../assets/logos/RyanairLogo.png";
import SalesForceLogo from "../../assets/logos/SalesForceLogo.png";
import SapLogo from "../../assets/logos/SapLogo.png";
import SegaLogo from "../../assets/logos/SegaLogo.png";
import SheinLogo from "../../assets/logos/SheinLogo.png";
import ShellLogo from "../../assets/logos/ShellLogo.png";
import ShopeeLogo from "../../assets/logos/ShopeeLogo.png";
import SkypeLogo from "../../assets/logos/SkypeLogo.png";
import SlideShareLogo from "../../assets/logos/SlideShareLogo.png";
import SmartFitLogo from "../../assets/logos/SmartFitLogo.png";
import SomfyLogo from "../../assets/logos/SomfyLogo.png";
import SpotifyLogo from "../../assets/logos/SpotifyLogo.png";
import StarbucksLogo from "../../assets/logos/StarbucksLogo.png";
import SteamLogo from "../../assets/logos/SteamLogo.png";
import StreetLineLogo from "../../assets/logos/StreetLineLogo.png";
import SubwayLogo from "../../assets/logos/SubwayLogo.png";
import TchiboLogo from "../../assets/logos/TchiboLogo.png";
import TheBodyShopLogo from "../../assets/logos/TheBodyShopLogo.png";
import ToyotaLogo from "../../assets/logos/ToyotaLogo.png";
import TupperwareLogo from "../../assets/logos/TupperwareLogo.png";
import TwitterLogo from "../../assets/logos/TwitterLogo.png";
import UberLogo from "../../assets/logos/UberLogo.png";
import UnileverLogo from "../../assets/logos/UnileverLogo.png";
import UnimedLogo from "../../assets/logos/UnimedLogo.png";
import VivoLogo from "../../assets/logos/VivoLogo.png";
import WikipediaLogo from "../../assets/logos/WikipediaLogo.png";
import WurthLogo from "../../assets/logos/WurthLogo.png";
import XeroxLogo from "../../assets/logos/XeroxLogo.png";
import YouTubeLogo from "../../assets/logos/YouTubeLogo.png";
import ZaraLogo from "../../assets/logos/ZaraLogo.png";
import ZopaLogo from "../../assets/logos/ZopaLogo.png";

const images = {
    AFolhaDeSaoPauloLogo,
    AbrilModaLogo,
    AirbnbLogo,
    AirtelLogo,
    AmericanAirlinesLogo,
    AmericanExpressLogo,
    AmzKindleLogo,
    AmzLogo,
    AmzManagedBlockchainLogo,
    AmzWebServicesLogo,
    AppleLogo,
    AvonLogo,
    BasfLogo,
    BayerLogo,
    BestBuyLogo,
    BetoCarreroWorldLogo,
    BirdLogo,
    BoschLogo,
    BradescoLogo,
    BrainpoolLogo,
    BrighterLivingLogo,
    BrillianceLogo,
    BuffetLogo,
    CacauShowLogo,
    Car2GoLogo,
    CarnegieLogo,
    CeweColorLogo,
    CeweLogo,
    CinemarkLogo,
    CirqueDuSoleilLogo,
    CiscoLogo,
    ClubeWineLogo,
    CraigsListLogo,
    DellLogo,
    DennerLogo,
    DeutschePostLogo,
    DollarShaveClubLogo,
    DropboxLogo,
    EasySmartGridLogo,
    EbayLogo,
    EntocycleLogo,
    EvoluttoLogo,
    FacebookLogo,
    FelfelLogo,
    FordLogo,
    FoxconnLogo,
    FreitagLogo,
    GELogo,
    GilletteLogo,
    goCaseLogo,
    GoogleLogo,
    GrouponLogo,
    HPLogo,
    HapimagLogo,
    HarleyDavidsonLogo,
    HeMLogo,
    HiltiLogo,
    HotmartLogo,
    HumbleBundleLogo,
    ibisBudgetLogo,
    IkeaLogo,
    ImperfectFoodsLogo,
    InstagramLogo,
    IntelLogo,
    iTunesLogo,
    JcDecauxLogo,
    JeronimoLogo,
    KickStarterLogo,
    LevissLogo,
    LinkedInLogo,
    LiveloLogo,
    LufthansaLogo,
    MaryKayLogo,
    McDonaldsLogo,
    McfFitLogo,
    MercadoLivreLogo,
    MicrosoftLogo,
    MieleLogo,
    MobilityLogo,
    MonetizzeLogo,
    MozillaLogo,
    MyHammerLogo,
    NespressoLogo,
    NetflixLogo,
    NikeLogo,
    NintendoLogo,
    OneWorldLogo,
    OpenDeskLogo,
    OutbackLogo,
    PaneraBreadLogo,
    PaybackLogo,
    PeGLogo,
    PebbleLogo,
    PepsicoLogo,
    PersonalNovelLogo,
    PlayStationLogo,
    PorscheLogo,
    PPPLogo,
    RedbullLogo,
    RenaultLogo,
    ReservaInkLogo,
    RichelieuLogo,
    RollsRoyceLogo,
    RufflesLogo,
    RyanairLogo,
    SalesForceLogo,
    SapLogo,
    SegaLogo,
    SheinLogo,
    ShellLogo,
    ShopeeLogo,
    SkypeLogo,
    SlideShareLogo,
    SmartFitLogo,
    SomfyLogo,
    SpotifyLogo,
    StarbucksLogo,
    SteamLogo,
    StreetLineLogo,
    SubwayLogo,
    TchiboLogo,
    TheBodyShopLogo,
    ToyotaLogo,
    TupperwareLogo,
    TwitterLogo,
    UberLogo,
    UnileverLogo,
    UnimedLogo,
    VivoLogo,
    WikipediaLogo,
    WurthLogo,
    XeroxLogo,
    YouTubeLogo,
    ZaraLogo,
    ZopaLogo
};

function getImgLogoByKey( key ) 
{
  return images[ key ];
}

export default getImgLogoByKey