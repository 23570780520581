import React, { useEffect, useState } from 'react'
import styles from './StepAnnotation.module.sass'
import UserStore from '../../../../store/userStore'
import axios from 'axios'
const StepAnnotation = ({step, group}) => {
    const [annotation, setAnnotation] = useState('')

    const [userStepAnnotations, setUserStepAnnotations] = useState(null)
    const userId = UserStore.getUserId()
    const getAnnotations = async() =>{
        axios.get('https://35ibgat7yanshkqp25ps4ckaru0nusvq.lambda-url.us-east-2.on.aws/', {params:{
            userId: userId,
            step: step,
            group: group

        }}).then(resp=>{
            console.log(resp)
            setUserStepAnnotations(resp.data.annotations)


        })



    }
    const postAnnotation = async() =>{
        await axios.post('https://xawezrohlxlfh7jv2dombpgg3u0jawrk.lambda-url.us-east-2.on.aws/', {annotation: annotation, group:  group, step: step, userId: userId}).then(resp=>{
            console.log(resp)
            setAnnotation('')
            getAnnotations()

        })



    }



    useEffect(()=>{
        getAnnotations()



    }, [])




    


  return (
    <div className={styles.stepAnnotation} >
        <p>Anotações</p>
        <div><textarea placeholder='Escreva aqui...' onChange={e=>setAnnotation(e.target.value)}/></div>
        <div className={styles.saveAnnotations} onClick={postAnnotation}>Salvar Anotação</div>
        <div className={styles.allAnnotations}>{userStepAnnotations && userStepAnnotations.map(annotation=><div className={styles.answers}>{annotation.aAnnotation}</div>)}</div>
    </div>
  )
}

export default StepAnnotation