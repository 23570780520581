import React from 'react'
import styles from './MobileVideoStructure.module.sass'
import { CProgress, CProgressBar } from '@coreui/react'
import JourneysideMenu from '../../JourneysideMenu'
import menuJourney from '../../../../assets/icons/iconMenuJourney.png'
import { useEffect } from 'react'

const MobileVideoStructure = ({videoTitle, videoResponsible, videoUrl, type, prevVideoPath, nextVideoPath, nextVideoName, stepPercent, OpenSideDiv, height}) => {

    useEffect(()=>{
        const board = document.querySelector('.BoardContent')
        board.style.padding='0'

    }, [])
  return (

    <div className="journeyKYMBackground" style={{overflow:'hidden', position: 'relative', height: height || '600px', width: '100vw', paddingBottom: '20px'}}>
    <i className="fa fa-bars" id={styles.menuIcon} onClick={OpenSideDiv}/>
    
    <div style={{position:'relative',
                top: '4.4rem',
                left: '1.6rem',
                width:'50%'}}>
        <text className="journeyKYMHeader" style={{fontSize: '26px'}}>
            {type}</text>
    </div>
    <div className="journeyFWVVideoDiv" style={{top: '80px', left: '0px'}}>
                <video src={videoUrl} style={{width: '100vw'}} controls="controls" controlsList="nodownload">                
                </video>
                <text className="journeyFWVVideoTitle" style={{fontSize: '1.4rem'}}>{videoTitle}</text>
                <text className="journeyFWVVideoSubtitle">{videoResponsible}</text>
            </div>
    <div className="journeyKYMVideoDiv" style={{bottom: '10px', right: '4px'}}>
    <a href={nextVideoPath} className="journeyKYMlink" style={{textDecoration:'none', gap: '0'}}>
        <div style={{display:'flex',
                    flexDirection:'column',
                    marginLeft:'1.5rem',
                    marginTop:'0.8rem',
                    marginBottom:'0.8rem'}}>
            <text className="journeyKYMVideoUpperTxt">Próximo conteúdo</text>
            <text className="journeyKYMVideoLowerTxt">{nextVideoName}</text>
        </div>
            <div className="journeyKYMArrowDiv">
                <text className="journeyKYMArrow">&#8250;</text>
            </div>
            </a>   
    </div> 
    
    
    <div className="journeyKYMVideoDiv" style={{bottom: '10px', left: '4px', background: 'none', border: 'none'}}>
    
    <a href={prevVideoPath} className={styles.backArrow} style={{textDecoration:'none'}}>
        <div className={styles.backButton}>
            <text className="textBackButton1">&#8249;</text>
        </div>
    </a>



    </div> 




    
    <text className="journeyKYMprogressText" style={{position: 'absolute', top:'68px', left: '22px', fontSize: '16px'}}>
        Seu progresso
    </text>      
    <div style={{position:'absolute',
                top:'70px',
                left:'150px',
                paddingLeft:'0.4rem',
                paddingRight:'0.4rem',
                borderRadius:'0.3rem',
                backgroundColor:'#FFFFFF',
                width:'fit-content'}}>
        <text className="journeyKYMprogressBarText">
            {stepPercent}% completo
        </text>
    </div>
    <div style={{position:'absolute',
                top:'100px',
                left:'22px',
                width:'20rem'
                }}>
        <CProgress height={ 6 }  >
            <CProgressBar value={ stepPercent } />
        </CProgress>
    </div>

    <JourneysideMenu></JourneysideMenu>
</div>

  )
}

export default MobileVideoStructure