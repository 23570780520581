import { useEffect, useState } from "react";
import styles from './NewPopUp.module.sass'
import { DateMask, StringMask } from "../../../library/StringMasks";
import Select from "react-select";
import UserStore from "../../../../store/userStore";
import axios from "axios";
import { botconversaWebhook } from "../../../../functions/botconversaWebhook";

export const NewPopUp = ( props ) =>
{    

    const { clientsSelection, setActivePopUp, productsSelection, registerNewTask, registerNewSale, salesList, twoDigitInteger, confirmRemoveSale, setSalesList, newTaskInfo, setNewTaskInfo} = props
    let {saleItems} = props  
    const [addClientToSale, setAddClientToSale] = useState(false)   
    console.log(clientsSelection); console.log(productsSelection); //console.log(newSellInfo);
    const installments =
    [
        { label: 'À vista', value: 1 }, 
        { label: '2 meses',  value: 2 },
        { label: '3 meses',  value: 3 },
        { label: '4 meses',  value: 4 },
        { label: '5 meses',  value: 5 },
        { label: '6 meses',  value: 6 },
        { label: '7 meses',  value: 7 },
        { label: '8 meses',  value: 8 },
        { label: '9 meses',  value: 9 },
        { label: '10 meses', value: 10 },
        { label: '11 meses', value: 11 },
        { label: '12 meses', value: 12 }
    ];
        


    const recurrenceMonths =
    [
        { label: '2 meses',  value: 2 },
        { label: '3 meses',  value: 3 },
        { label: '4 meses',  value: 4 },
        { label: '5 meses',  value: 5 },
        { label: '6 meses',  value: 6 },
        { label: '7 meses',  value: 7 },
        { label: '8 meses',  value: 8 },
        { label: '9 meses',  value: 9 },
        { label: '10 meses', value: 10 },
        { label: '11 meses', value: 11 },
        { label: '12 meses', value: 12 }
    ];
        
    const recurrences =
    [
      { label: 'Sem recorrência', value: 0, customLabel: 'Parcelas' },
      { label: 'Com recorrência', value: 1, customLabel: 'Renovações' }
    ];



    //console.log('productsSelection')
    //console.log(productsSelection[0].value)
    const taskCategories =
[
    { label: 'Operacional', value: 0 },
    { label: 'Relacionamento', value: 1 },
    { label: 'Marketing e vendas', value: 2 },
    { label: 'Financeiro', value: 3 },
    { label: 'Canais de comunicação', value: 4 },
    { label: 'Parcerias', value: '5' }
];

 /*   const [ newTaskInfo, setNewTaskInfo ] = 
    useState
    ( {
            category:(taskCategories ? taskCategories[ 0 ] : ''),
            date:DateMask( new Date() ),
            responsible:'',
            task:''
    } );*/


    const localMaskValue = (value) =>{

        
        if (value) {
            const splittedValue = value.toString().split('.')


        
            if (!splittedValue[1]) {
                splittedValue[1] = '00'
            }
        console.log('splitted');console.log(splittedValue);console.log(splittedValue[1]) 
            const splittedDigits = splittedValue[1].length>1 ? splittedValue[1] : `${splittedValue[1]}0`


            const valueDigits = splittedDigits ? Number(splittedDigits) : 0




            const isInteger = (valueDigits===0) ? true : false

            const convertedValue = isInteger ? value.toFixed(2) : parseFloat(value).toFixed(2)

            const maskedString = convertedValue.toString().replace( '.', '$' ).replace( ',', '' ).replace('$', ',');    
            console.log('CONV');console.log(maskedString);console.log(isInteger)    
            return maskedString



        }



       /* console.log(isInteger)
       

    */







    }

    //console.log('product');console.log( localMaskValue( productsSelection && productsSelection[0].value ))
    const sellData = {
        value:            (productsSelection ? localMaskValue( productsSelection[0].value) : '---'),
        recurrenceMonths: { label: '',  value: 1 },
        date:             DateMask( new Date() ),
        product:          (productsSelection ? productsSelection[ 0 ].label : ''),
        installments:     (installments ? installments[ 0 ] : ''),
        recurrence:       (recurrences ? recurrences[0] : ''),
        total:            '0,00',
        quantity:         '',
        notes:            '',

        client: ''
    }





    console.log('select'); console.log(productsSelection); 
    const [ newSellInfo, setNewSellInfo ] = 
    useState
    ( sellData );


    const loadingPopUpInfo = <div style={ { textAlign:'center' } }>Por favor aguarde, carregando lista.</div>;
    const emptySaleWarn = <div style={ { textAlign:'center' } }>Lista vazia, adicione novas vendas para esse produto para ter o histórico de vendas.</div>;

    let popup = '';
    let itemInputs = '';
    let popUpTitle = '';

    const newTaskInputEvent = ( event, source ) =>
    {
        switch( source )
        {
            case 'category':
                setNewTaskInfo( { ...newTaskInfo, [ source ]:event } );
                break;
            case 'date':
                setNewTaskInfo( { ...newTaskInfo, [ source ]:StringMask( event.target.value, { type:'DDMMYYYY' }, '' ) } );
                break;
            case 'responsible':
            case 'task':
                setNewTaskInfo( { ...newTaskInfo, [ source ]:event.target.value } );
                break;
        }
    }



    const newSellInputEvent = ( event, source ) =>
    {
        let quantity = 0;
        let value = 0;
        let total = 0;
        console.log(event)
        switch( source )
        {
            case 'product':
                setNewSellInfo( { ...newSellInfo, [ source ]:event, value: localMaskValue(event.value ) } );
                break;
            case 'recurrence':
                if( event.value === 0 )
                {
                    setNewSellInfo( { ...newSellInfo, [ source ]: event, installments: installments[0], recurrenceMonths: { label: '', value: 1 } } );
                }
                else
                {
                    setNewSellInfo( { ...newSellInfo, [ source ]:event, installments: installments[0], recurrenceMonths: recurrenceMonths[0] } );
                }
                break;
            case 'installments':
                if( newSellInfo.recurrence.value === 0 )
                {
                    setNewSellInfo( { ...newSellInfo, installments: event } );
                }
                else
                {
                    setNewSellInfo( { ...newSellInfo, recurrenceMonths: event } );
                }
                break;
            case 'quantity':
                quantity = parseInt( StringMask( event.target.value, { type:'onlyNumbers' }, '' ) );
                value = parseFloat( StringMask( newSellInfo.value, { type:'float' }, '' ) );

                console.log(`Valor ${newSellInfo.value}`)
                total = quantity * value;
                setNewSellInfo( { ...newSellInfo, [ source ]:quantity, total:StringMask( parseFloat( total ).toFixed( 2 ), { type:'2DigitFraction' }, '' ) } );
                break;
            case 'value':
                quantity = parseFloat( newSellInfo.quantity );
                value = parseFloat( StringMask( event.target.value, { type:'float' }, '' ) );
                total = quantity * value;
                setNewSellInfo( { ...newSellInfo, [ source ]:StringMask( event.target.value, { type:'2DigitFraction' }, '' ), total:StringMask( parseFloat( total ).toFixed( 2 ), { type:'2DigitFraction' }, '' ) } );
                break;
            case 'date':
                setNewSellInfo( { ...newSellInfo, [ source ]:StringMask( event.target.value, { type:'DDMMYYYY' }, '' ) } );
                break;
            case 'notes':
                setNewSellInfo( { ...newSellInfo, [ source ]:event.target.value } );
                break;




            case 'client':

                setNewSellInfo({ ...newSellInfo, [ source ]:event.value }           )



                break;
            default:
                break;

        }
    }

    const selecStyles =
    {
        valueContainer:( provided, state ) => ( { ...provided, padding:'0px 5px' } ),
        dropdownIndicator:( provided, state ) => ( { ...provided, padding:'4px' } ),
        control:( provided, state ) => ( { ...provided, minHeight:'10px', fontWeight:'normal' } )
    };

    if( ( props.prop.type === 'newTask' ) || ( props.prop.type === 'newSell' ) || ( props.prop.type === 'sellHistory' ) )
    {
        if( props.prop.type === 'newTask' )
        {
            popUpTitle = 'Registrar tarefa';
            itemInputs =
                <table className='actionPlanSellDebtPopup'>
                    <tbody>
                        <tr><td>Categoria</td></tr>
                        <tr><td><Select className='actionPlanPopupSelector' styles={ selecStyles } options={ taskCategories } defaultValue={ newTaskInfo.category } onChange={ ( event ) => newTaskInputEvent( event, 'category' ) }/></td></tr>
                        <tr><td>Data limite</td></tr>
                        <tr><td><input className='actionPlanPopupInput' type="text" placeholder='01/01/2023' maxLength='10' value={ newTaskInfo.date } onChange={ ( event ) => newTaskInputEvent( event, 'date' ) }/></td></tr>
                        <tr><td>Resposável</td></tr>
                        <tr><td><input className='actionPlanPopupInput' type="text" onChange={ ( event ) => newTaskInputEvent( event, 'responsible' ) }/></td></tr>
                        <tr><td>Tarefa</td></tr>
                        <tr><td><input className='actionPlanPopupInput' type="text" onChange={ ( event ) => newTaskInputEvent( event, 'task' ) }/></td></tr>
                        <tr><td>Prévia</td></tr>
                        <tr>
                            <td>
                                <div className='AgendaItem'>
                                    <div className='AgendaItemInfoTitle'>
                                        [{ newTaskInfo.category.label }]
                                    </div>
                                    <div className='AgendaItemInfo'>
                                        { newTaskInfo.date ? newTaskInfo.date : 'Data' } - { newTaskInfo.task ? newTaskInfo.task : 'Tarefa' }.
                                    </div>
                                    <div className='AgendaItemInfo'>
                                        { newTaskInfo.responsible ? newTaskInfo.responsible : 'Resposável' }
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr></tr>
                        <tr><td><div className='actionPlanPopupBtnOk' onClick={ () => {        botconversaWebhook({action: 'Adicionou tarefa'}); registerNewTask( newTaskInfo )} }>Registrar</div></td></tr>
                    </tbody>
                </table>;
        }
        else if( props.prop.type === 'newSell' )
        {
            popUpTitle = 'Registrar venda';
            itemInputs =
                <table className='actionPlanSellDebtPopup'>
                    <tbody><tr>
                             <td>Adicionar Cliente à Venda</td><td colSpan={ 3 }>Cliente</td>  
                        </tr>                         <tr>
                            
                            
                            
            <td style={{display: 'flex', alignItems: 'center', gap: '20px'}}><label for="yes"> <input type="radio" style={{marginRight: '4px'}} id="yes" name="fav_language" value="yes" onChange={()=>setAddClientToSale(true)}/>
SIM</label> 
<label for="no"><input type="radio" style={{marginRight: '4px'}} id="no" name="fav_language" value="no" onChange={()=>setAddClientToSale(false)} checked={!addClientToSale}/>
 NÃO</label> </td>
                            <td colSpan={ 3 }><Select className='actionPlanPopupSelector' styles={ selecStyles } isDisabled={!addClientToSale} options={ clientsSelection && clientsSelection } defaultValue={ '' } onChange={ ( event ) => newSellInputEvent( event, 'client' ) }/></td>
            
                        </tr>                        
                        <tr>
                            <td colSpan={ 3 }>Produto</td>   
                            <td>Valor no sistema (R$)</td>
                        </tr>                                               
                        <tr>
                            <td colSpan={ 3 }><Select className='actionPlanPopupSelector' styles={ selecStyles } options={ productsSelection && productsSelection } defaultValue={ newSellInfo ? newSellInfo.product : '' } onChange={ ( event ) => newSellInputEvent( event, 'product' ) }/></td>
                            <td><input className='actionPlanPopupInput' type="text" value={ ( newSellInfo.product !== undefined ) ? newSellInfo.value : '---' } disabled/></td>
                        </tr>
                        <tr>
                            <td colSpan={ 2 }>Quantidade</td>
                            <td>Valor unitário(R$)</td>
                            <td>Valor total (R$)</td>
                        </tr>
                        <tr>
                            <td colSpan={ 2 }><input className='actionPlanPopupInput' type="text" value={ newSellInfo.quantity } onChange={ ( event ) => newSellInputEvent( event, 'quantity' ) }/></td>
                            <td><input className='actionPlanPopupInput' type="text" value={ newSellInfo.value } onChange={ ( event ) => newSellInputEvent( event, 'value' ) }/></td>
                            <td><input className='actionPlanPopupInput' type="text" value={ newSellInfo.total } disabled/></td>
                        </tr>
                        <tr>
                            <td>Recorrência</td>
                            <td>{ newSellInfo.recurrence.customLabel }</td>
                            <td>Data da operação</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><Select className='actionPlanPopupSelector' styles={ selecStyles } options={ recurrences } value={ newSellInfo.recurrence } onChange={ ( event ) => newSellInputEvent( event, 'recurrence' ) }/></td>
                            <td><Select className='actionPlanPopupSelector' styles={ selecStyles } options={ ( newSellInfo.recurrence.value === 0 ) ? installments : recurrenceMonths } value={ ( newSellInfo.recurrence.value === 0 ) ? newSellInfo.installments : newSellInfo.recurrenceMonths } onChange={ ( event ) => newSellInputEvent( event, 'installments' ) }/></td>
                            <td><input className='actionPlanPopupInput' type="text" placeholder='01/01/2023' maxLength='10' value={ newSellInfo.date } onChange={ ( event ) => newSellInputEvent( event, 'date' ) }/></td>
                            <td><div className='actionPlanPopupBtnOk' onClick={ () => {    setAddClientToSale(false);    botconversaWebhook({action: 'Adicionou venda'}); registerNewSale( newSellInfo )} }>Registrar</div></td>
                        </tr>
                        <tr>
                            <td colSpan={ 4 }>Notas sobre a registro</td>
                        </tr>
                        <tr>
                            <td colSpan={ 4 }><textarea className='actionPlanPopupTextArea' maxLength={ 512 } onChange={ ( event ) => newSellInputEvent( event, 'notes' ) } placeholder={ 'Adicione aqui qualquer notas importante sobre o registro.\nEx.: Descontos, razão, parcelamento.' }/></td>
                        </tr>
                    </tbody>
                </table>;
        }
        else if( props.prop.type === 'sellHistory' )
        {
            let userId = UserStore.getUserId();
            let token = UserStore.getToken();

            popUpTitle = props.prop.title;
            itemInputs = ( salesList == null ) ? loadingPopUpInfo : ( salesList.length > 0 ) ? salesList : emptySaleWarn;
    
            if( salesList == null )
            {
                axios.get( 'https://bwfxdnzp5j6jbrdlnzvpadrzbm0vgrie.lambda-url.us-east-2.on.aws/', { params:{ company:userId, token:token, product:props.prop.itemId, year:2023 } } ).then( ( resp ) =>
                {
                    if( resp.data.sales )
                    {
                        for( let index = 0; index < resp.data.sales.length; index++ )
                        {
                            const curItem = resp.data.sales[ index ];
                            console.log('Vendas');console.log(resp.data.sales)
                            const splittedDate = curItem.date.split('-')

                            //saleDate fix the wrong date, by changing the format of date from YYYY-MM-DD to MM-DD-YYYY
                            const saleDate = `${splittedDate[1]}-${splittedDate[2].substring(0,2)}-${splittedDate[0]}`
                            
                            const ISODate = new Date( saleDate );
                            //console.log('isodate');console.log(saleDate)

                            const date = twoDigitInteger( ISODate.getDate() ) + twoDigitInteger( ISODate.getMonth() + 1 ) + ISODate.getFullYear();
                            const total = curItem.value * curItem.quantity;
                            const newLine =
                                <div key={ 's_' + curItem.sale } className='HistoryItemNew'>
                                    <div>
                                        <div className='HistoryItemInfo'>
                                            <b>{ StringMask( date, { type:'DDMMYYYY' }, '' ) }</b> - <b>{ curItem.quantity }</b> unidades totalizando <b>{ StringMask( total.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' ) }</b> em <b>{ curItem.installments }xTeste</b>.
                                        </div>
                                        <div className='HistoryItemInfo'>
                                            Preço correnteeeee era muito alto<b>{ StringMask( curItem.curPrice.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' ) }</b> e foi efetuado por <b>{ StringMask( curItem.value.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' ) }</b>{curItem.recurrenceMonths>1 && <><br/><b>Recorrência: </b>{curItem.recurrenceMonths} meses</>}{curItem.notes && <><br/><b>Observações: </b>{curItem.notes}</>}.
                                        </div>
                                    </div>
                                    <div className='SellItemInfoClose'>
                                        {window.screen.width<780 ?
                                            <div onClick={ () => { console.log(`Test: ${curItem.sale}`); confirmRemoveSale(curItem.sale);} }>s</div>
                                            :
                                            <i className="fa fa-trash" onClick={()=>confirmRemoveSale(curItem.sale)}/>
                                        }
                                    </div>
                                </div>;

                            saleItems.push( newLine );
                        }

                        setSalesList( saleItems );
                    }
                } );
            }
        }

        popup =
            <div className='popUpBgBox' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={ () => {setAddClientToSale(false); setActivePopUp( { type:'', top:0, left:0 } )} }>
                <div className={styles.actionPlanNewPopUp} onClick={ ( event ) => event.stopPropagation() }>
                    <div className="FullBoardContentTitle">
                        { popUpTitle }
                        <div className='TitleNewItemBtn' onClick={ () => {setAddClientToSale(false); setActivePopUp( { type:'', top:0, left:0 } ) }} style={ { float:'right', backgroundColor:'#e42a2a' } } >x</div>
                        <div className='HorizontalLineSeparator'/>
                    </div>
                    <div className={styles.itemsDisplay}>
                    { itemInputs }
                </div>

                </div>
            </div>;
    }
    else
    {
        if( salesList !== null )
        {
            saleItems = [];
            setSalesList && setSalesList( null );
        }
    }

    return( popup );
}