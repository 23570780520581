import React from 'react'
import styles from './ProductCard.module.sass'
import { StringMask } from '../../../../library/StringMasks' 
const ProductCard = ({productName, targetValue, doneValue, saleDate, clientName, quantity}) => {

  const result = doneValue - targetValue
  return (
    <div className={styles.productCard}>
        <div className={styles.itemName}><p>Cliente: {clientName}</p><p>Produto: {productName}</p></div>
        <div className={styles.values}>
            <div><p>Valor</p><span>R$ {StringMask( doneValue.toFixed( 2 ), { type:'2DigitFraction' }, ( doneValue < 0 ) ? '-' : '' )}</span></div>
            <div><p>Quantidade</p><span>   {quantity}</span></div> 
        </div>
    </div>
  )
}

export default ProductCard