import React from 'react'
import styles from './Monthcashflow.module.sass'
import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import LoadingBar from '../../clients/loadingBar/LoadingBar'  
import UserStore from '../../../store/userStore' 
import LoadingScreen from '../../loadingScreen/LoadingScreen' 
import Select from 'react-select'
import SaleCard from './saleCard/SaleCard'
import { StringMask } from '../../library/StringMasks'
import SalesHistory from './salesHistory/SalesHistory'
import ExpensesHistory from './expensesHistory/ExpensesHistory'
import DefaultCard from './defaultCard/DefaultCard'
import EditSale from '../../actionPlan/cards/editSale/EditSale'
import MobileCashFlow from './mobileCashFlow/MobileCashFlow'
const Monthcashflow = () => {
    const [isNewSale, setIsNewSale] = useState(false )
    const [isSalesHistory, setIsSalesHistory] = useState(false )

    const [isExpensesHistory, setIsExpensesHistory] = useState(false )
    const [clientName, setClientName] = useState('')
    const [productName, setProductName] = useState('')

    const [expensesList, setExpensesList] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const [isLoadingScreen, setIsLoadingScreen] = useState(false)
    const [salesList, setSalesList] = useState({}) 
    const date = new Date()
    const currentMonth = date.getMonth()
    const [ clientsSelection, setClientsSelection ] = useState({})
    const [clientId, setClientId] = useState()
    const [selectedMonth, setSelectedMonth] = useState(currentMonth + 1)
    const [selectedExpense, setSelectedExpense] = useState(null)            
    const [totalExpenses, setTotalExpenses] = useState(0)
    const [totalSales, setTotalSales] = useState(0)
    const [totalTributes, setTotalTributes] = useState(0)
    const [isEditSale, setIsEditSale] = useState(false)
    const [selectedSale, setSelectedSale] = useState(null)


    const monthOptions = [     { value: 1, label: 'Janeiro' },
    { value: 2, label: 'Fevereiro' },
    { value: 3, label: 'Março' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Maio' },
    { value: 6, label: 'Junho' },
    { value: 7, label: 'Julho' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Setembro' },
    { value: 10, label: 'Outubro' },
    { value: 11, label: 'Novembro' }, {value: 12, label: 'December'}
      ]; 
    const getExpenses = async({month}) =>{console.log(month )
        setIsLoading(true)
        axios.get('https://3tzvgrajhjbgkkp6chk6ddgvqi0gpyey.lambda-url.us-east-2.on.aws/', {params: {companyId: UserStore.getUserId(), selectedYear: date.getFullYear(), month: month}})
        .then(resp=> { console.log(resp); setExpensesList(resp.data.expenses);            let totalExpenses = 0;
            resp.data.expenses.forEach((expense) => {
              totalExpenses += expense.done;
            });
            setTotalExpenses(totalExpenses); setIsLoading(false)})
        .catch(err=>{                console.error('Erro na requisição:', err); setIsLoading(false)})
    }   
    const getSales = async({month}) =>{
        setIsLoading(true)
        axios.get('https://ig4k3bhkbvbsbavfelvcoflh3i0fxitz.lambda-url.us-east-2.on.aws/', {params: {company: UserStore.getUserId(), selectedYear: date.getFullYear(), month: month}})
        .then(resp=> { console.log(resp);                 let  localTotalSales = 0; let i = 0
            const newArr = [];
            const date = new Date()

             




            const baseYear = date.getFullYear()
            const addBase = 12
            resp.data.products.forEach((sale) => { 
                const installments = sale.sInstallments
                const recurrenceMonths = sale.sReccurenceMonths
                const price = sale.value

                    const distance = (baseYear - Number(sale.saleYear))*addBase - Number(sale.sellMonthDate) + Number(month)
                    console.log(distance); console.log(); console.log(); console.log(); console.log(); 


                    if (baseYear===sale.saleYear && sale.sellMonthDate>month) {
                        
                    } else {
                        
                    
                    if (installments -distance> 0  || recurrenceMonths - distance> 0) {
                        console.log(sale); console.log(sale.clientsId); console.log(sale.product);   
                        const existingIndex = newArr.findIndex(item => item.clientsId === sale.clientsId && item.product === sale.product); console.log(existingIndex)
                         if(existingIndex !== -1) {
                          newArr[existingIndex].value += sale.value;       

                          newArr[existingIndex].quantity += sale.quantity;
                        } else {                newArr.push({...sale});
                       }



                    } 
            }});
            
            
            
                




            
            
            newArr.forEach(sale=>localTotalSales+=sale.value)
            console.log(newArr); setSalesList(newArr);console.log(localTotalSales)
            setTotalSales(localTotalSales); setIsLoading(false)})
        .catch(err=>{                                console.error('Erro na requisição:', err);setIsLoading(false)})
    }
    const getTaxes = ({month}) =>{


        const userId = UserStore.getUserId()


        const token = UserStore.getToken()
        //axios.get('https://k7rmrfmr2vdbo4rk4ax4lm2kia0rvmng.lambda-url.us-east-2.on.aws/', {params: {company: UserStore.getUserId()}}).then(resp=>{console.log(resp.data); setTotalTributes(resp.data.taxValue)})
      axios.get( 'https://4iirgtz6rqhn2itpfzey4zd4au0iyfli.lambda-url.us-east-2.on.aws/', { params: { year: 2024, company: userId, token: token } } ).then( ( cfResp ) =>
        {//console.log('RESP');//console.log(cfResp)
            if( cfResp.data.pTarget )
            {//console.log('CFR'); //console.log(cfResp.data)
              

            if( cfResp.data.aliquot )
            {
                        console.log(cfResp.data.aliquot);
            }

            if( cfResp.data.taxes )
            {   
                setTotalTributes(cfResp.data.taxes[month - 1]);
            }}}


).catch(err=>{                                console.error('Erro na requisição:', err);setIsLoading(false);})
        

    }


    
    
    useEffect(()=>{
        getExpenses({month: currentMonth + 1})  
        getSales({month: currentMonth + 1}) 



        getTaxes({month: currentMonth + 1})
    }, [])



    const formatGroupLabel = ({data}) =>{
        return (    <div>
            <span>{data.label}</span>
          </div>) 



    }


    const convertDate = (datetime)=> {
 
        const date = new Date(datetime);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
    
        
        day = (day < 10) ? '0' + day : day;
        month = (month < 10) ? '0' + month : month;
        return `${day}/${month}/${year}`
    }





    const filterMonth = ({month}) =>{
        console.log(month)
        setSelectedMonth(month)
        getSales({month})
        getExpenses({month})
        getTaxes({month})

    }





    const deleteClient = async({clientId})=>{

        setIsLoadingScreen(true)
        await axios.delete('https://eugbqufm77zrftifcd47x2ueau0pblca.lambda-url.us-east-2.on.aws/', {data: {clientId:clientId}})
        .then(resp=>{
            
            
            setIsLoadingScreen(false)
            getExpenses({month: selectedMonth})})
        .catch(err=>{
            setIsLoadingScreen(false)
            
            
            console.log(err)})

    }


    const actualMonth = new Date().getMonth() + 1
    const [salesSelectedMonth, setSalesSelectedMonth] = useState(actualMonth)
    const [tasksSelectedMonth, setTasksSelectedMonth] = useState(actualMonth)
    const actualYear = new Date().getFullYear()
    const [salesSelectedYear , setSalesSelectedYear ] = useState(actualYear) 
    const [monthSales, setMonthSales] = useState([])
const [productsSelection, setProductsSelection] = useState(null)
    const getSalesList = async(month=actualMonth, year=salesSelectedYear) =>{


        setIsLoadingScreen(true)
        await axios.get( 'https://ug22i67qcjzgjpi5o57numzhou0kougr.lambda-url.us-east-2.on.aws/', { params:{ company: UserStore.getUserId(), token: UserStore.getToken(), month: month, year:year } } ).then( ( resp ) =>
        {
        if( resp.data.products.length > 0 )
        {console.log('resp.data');console.log(resp.data)
            setMonthSales( resp.data.products );
            const productssss = resp.data.products
            productssss.forEach(product=>{product.label = product.name; delete product.name;})
            setProductsSelection(productssss)   

            

        }



        setIsLoadingScreen(false)
        } ).catch(err=>{                               console.error('Erro na requisição:', err);setIsLoadingScreen(false)})



    }  
  const updateExpenseCheck = async({cfeId, isChecked, index}) =>{

    const newValue = isChecked === 0 ? 1 : 0;
            const prevExpensesList = [...expensesList]
            let newExpenses = [...expensesList];     
            newExpenses[index].isChecked = newValue
            setExpensesList(newExpenses);


    await axios.put('https://a4es2wptbiml5myn4jbwrl5ngm0stygc.lambda-url.us-east-2.on.aws/', {cfeId: cfeId, newValue: newValue, month: selectedMonth}).then(resp=>{  




    }).catch(err=>{setExpensesList(prevExpensesList)})

}    



useEffect(()=>{
        const getClients = async()=>{   console.log('AAAA'); console.log(productsSelection)
            await axios.get('https://b275nbsclbzsman3igysfj7shm0ebhxr.lambda-url.us-east-2.on.aws/', {params: {companyId: UserStore.getUserId()}})
            .then(resp=>{   console.log(resp.data.clients)
                setClientsSelection(resp.data.clients) 
            }).catch(err => {
                console.error('Erro na requisição:', err);
              });
        }    
        getClients()
        getSalesList()
    }, [])
  return (
    <div className={styles.clients} style={{padding: window.screen.width<800 && '0px'}}> 
    
    
        {isExpensesHistory && <ExpensesHistory index={selectedMonth} getExpenses={getExpenses} expensesSelection={selectedExpense} close={()=>setIsExpensesHistory(false)}/>}


        {isEditSale && <EditSale selectedSale={selectedSale} closeFn={()=>{setIsEditSale(false); setSelectedSale(null)}} reloadInfo={()=>getSales({month: selectedMonth})}/>}
        {isSalesHistory && <SalesHistory setSelectedSale={setSelectedSale} editSale={()=>setIsEditSale(true)} getSales={()=>getSales({month: selectedMonth})} setIsLoadingScreen={setIsLoadingScreen} productName={productName} clientId={clientId} clientName={clientName} close={()=>setIsSalesHistory(false)}/>}            
        {isNewSale && <SaleCard month={selectedMonth} getSales={getSales} setIsLoadingScreen={setIsLoadingScreen} isLoadingScreen={isLoadingScreen} productsSelection={productsSelection} clientsSelection={clientsSelection} close={()=>setIsNewSale(false)}/>}
        {isLoadingScreen && <LoadingScreen/>}<div className={styles.content}>   
            <h2>Fluxo de Caixa Mensal</h2>
            <p>Dúvidas sobre o registro de vendas do seu negócio? Listamos aqui os principais itens e informações que você deve rastrear em cada venda. Clique no "+" para adicioná-los ao seu sistema de registro.</p>
            <div className={styles.clientsButtons}>
                            
            <label>Mês<Select defaultValue={monthOptions[currentMonth]} onChange={e=>filterMonth({month: e.value})} options={monthOptions && monthOptions}   /></label>
 
                                
                <div className={styles.buttonItem}><div className={styles.button} onClick={()=>setIsNewSale(true)}>Venda +</div></div>
                
                </div>








































            {window.screen.width>800 ? <>
            <table>   
                <tr>
                    <th>Cliente</th>
                    <th>Receita</th>
                    <th>Quantidade</th>
                    <th>Valor Total</th>
                    <th>Editar</th>   
                </tr>  
                {isLoading &&                 <tr>
                    <td><LoadingBar/></td>
                    <td><LoadingBar/></td>                      
                    <td><LoadingBar/></td>                      
    




                    <td><LoadingBar/></td>




                </tr> }
                {!isLoading && salesList.length>0 && salesList.map(sale=>(
                    <tr><td>{sale.clientName!==null  ? sale.clientName : 'Sem Cliente'}</td>
                    <td>{sale.name}</td>
                    <td>{sale.quantity}</td>
                    <td>{StringMask( sale.value.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' )}</td>
                
                    <td className={styles.icons}><i onClick={()=>{setClientName(sale.clientName); setProductName(sale.name); setClientId(sale.clientsId); setIsSalesHistory(true)}} className="fa fa-history "/></td>
                    <td></td>   
                </tr>



                ))
                     }





            </table>           <table>   
                <tr>
                    <th>Custo</th>
                    <th>Vencimento</th>
                    <th>Valor previsto</th>
                    <th>Valor</th>
                    <th>Check</th>
                    <th>Editar</th>   
                </tr> 

                {isLoading &&                 <tr>
                    <td><LoadingBar/></td>
                    <td><LoadingBar/></td>                      
                    <td><LoadingBar/></td>                      
                    <td><LoadingBar/></td>                    
                    <td><LoadingBar/></td>
                </tr> }
                {!isLoading && expensesList.length>0 && expensesList.map((expense, index)=>(
                    <tr><td>{expense.expense}</td>
                    <td>{expense.expiresOn}</td>
                    <td>{StringMask(expense.target.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' )}</td>
                    <td>{StringMask(expense.done.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' )}</td>
                    <td><input type="checkbox" checked={expense.isChecked===1} onClick={()=>updateExpenseCheck({cfeId: expense.cfeId, isChecked: expense.isChecked, index: index})}/></td>
                    <td className={styles.icons}><i onClick={()=>{setSelectedExpense(expense); setIsExpensesHistory(true)}} className="fa fa-history " /></td>
                    <td></td>   
                </tr>



                ))
                     }





            </table> <div className={styles.cards}>
                
                <DefaultCard title="Receita Total" color="" value={totalSales} />
                <DefaultCard title="Tributos" color="" value={totalTributes} />
                <DefaultCard title="Despesas" color="" value={totalExpenses} />
                <DefaultCard title="Resultado Mês" color="" value={totalSales - totalTributes - totalExpenses} isResult={true}/>
                
                
                
                </div> </> : <div>
                    {!isLoading && salesList.length>0 && totalSales && totalTributes && <MobileCashFlow totalSales={totalSales} totalTributes={totalTributes} totalExpenses={totalExpenses} selectedMonthIndex={selectedMonth} salesList={salesList} />}
                
                
                </div> }
        </div>
    </div>
  )
}

export default Monthcashflow