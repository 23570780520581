import React, { useEffect, useRef, useState } from 'react'
import styles from './StepOption.module.sass'
import VideosList from './videosList/VideosList'
import CustomProgressBar from '../../customProgressBar/CustomProgressBar'
import axios from 'axios'
import { convertTimeFormat } from '../../../../../functions/convertTimeFormat'
import { convertToSemanticTimeFormat } from '../../../../../functions/convertToSemanticTime'




const StepOption = ({groupStepList, groupName, selectStep, isOpen,    setIsFullStepOpen, reloadSteps, subGroups, isWeb}) => {
  const videosArray = groupStepList
  const [isCardOpened, setIsCardOpened] = useState(isOpen)

  const [totalWatchedSteps, setTotalWatchedSteps] = useState(0)
  const [totalSteps, setTotalSteps] = useState(0)
  const [totalGroupDuration, setTotalGroupDuration] = useState(null)





  



  const convertSecondsToTime = (seconds) =>{
    let secondsPlace = 0
    let minutesPlace = 0
    let hoursPlace = 0

    minutesPlace = (seconds/60) >= 1 ? Math.floor(seconds/60) : 0;
    secondsPlace = seconds%60
    hoursPlace = (minutesPlace/60) >= 1 ? Math.floor(minutesPlace/60) : 0
    minutesPlace = minutesPlace % 60
          
    const completeTime = `${hoursPlace>=10 ? hoursPlace : `0${hoursPlace}`}:${minutesPlace>=10 ? minutesPlace : `0${minutesPlace}`}:${secondsPlace>=10 ? secondsPlace : `0${secondsPlace}`}`;

    return completeTime

  }




















































  const convertTimeToSeconds = (arrayOfTime) =>{
    const hourToSeconds = arrayOfTime[0] * 3600
    const minutesToSeconds = arrayOfTime[1] * 60
    const seconds = arrayOfTime[2]

    return Number(hourToSeconds) + Number(minutesToSeconds) + Number(seconds )
  }
  const sumTime = (firstTime='00:00:00', secondTime) =>{
    const firstTimeArray = firstTime.split(':')
    const secondTimeArray = secondTime.split(':')
    const totalTimeInSeconds = convertTimeToSeconds(firstTimeArray) + convertTimeToSeconds(secondTimeArray) 
    //console.log(`sumTime ${convertSecondsToTime(totalTimeInSeconds)}`)
    return convertSecondsToTime(totalTimeInSeconds)
  }

  const getTotalGroupDuration = () =>{
    let initialTime = '00:00:00'    

    videosArray.forEach(video=>{    

      console.log(video);console.log(video.sDuration)
      initialTime = sumTime(video.sDuration, initialTime)
    } );

    const totalTime = convertToSemanticTimeFormat(initialTime)  //Pega o valor do initialTime que agora já esta calculado e completo
    setTotalGroupDuration(totalTime)
    
  }




  const getTotalSteps = () =>{

    let steps = 0
    videosArray.forEach(step=>{steps+=1})
    setTotalSteps(steps)


  }

  const getTotalWatchedSteps = () =>{

    let steps = 0
    videosArray.forEach(step=>{if (step.sHaveWatched===1) {
      steps+=1
    }})
    setTotalWatchedSteps(steps)


  }

  useEffect(()=>{
    getTotalGroupDuration()



  }, [])
  useEffect(()=>{

    getTotalSteps()

    getTotalWatchedSteps()
    setIsCardOpened(isOpen)



  }, [isOpen, reloadSteps])


  const clickOnStep = () =>{
    if (isWeb) {
      setIsCardOpened(prev=> !prev)
    } else {
      setIsFullStepOpen(true)
    }

  }

  return (
    
    <div>

      
        <div className={styles.stepOption} onClick={clickOnStep}>  
        <div className={styles.leftContent}> 
            <CustomProgressBar percent={totalWatchedSteps && totalSteps && (totalWatchedSteps/totalSteps)*100}/>
            <div><span>{groupName}</span><span>{totalGroupDuration && totalGroupDuration}</span></div>
        
        </div>
        {isWeb && <span style={{rotate: isCardOpened && '180deg'}}>v</span>}
        {!isWeb && <span style={{fontSize: '30px'}}>+</span>}
        

      </div>    
      {isCardOpened && <VideosList videosArray={videosArray} selectStep={selectStep} subGroups={subGroups}/>}  

    </div>
  )
}

export default StepOption