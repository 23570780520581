import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

const video = [{ title: 'Como empreender de forma saudavel', desc: 'Aprenda essa técnica com', picture: 'https://images.unsplash.com/photo-1629904853893-c2c8981a1dc5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80'}]

export const useRecentVideoData = () => {
    const mostRecentVideoDataUrl = 'https://oxslz46wmvwshdiom6qc7bkoiu0ndruo.lambda-url.us-east-2.on.aws/';
    const numberOfVideos = 1;
    const [isLoadingScreen, setIsLoadingScreen ]= useState(true)

    const [data, setData] = useState([])
    useEffect(()=>{

        const getRecentVideoData = async() =>{
            await axios.get(mostRecentVideoDataUrl, { params:{limitNum: numberOfVideos}}).then(resp => {console.log(resp);setData(resp.data.videos[0])})
            //await setData(video)
            setIsLoadingScreen(false)


            
        }
        getRecentVideoData()


   }, [])

        return {data, isLoadingScreen};

}
