import React from 'react'
import styles from './RegisterClient.module.sass'
import axios from 'axios'
import UserStore from '../../../store/userStore'
import { useState } from 'react'
import { StringMask } from '../../library/StringMasks'

const RegisterClient = ({close, setIsLoadingScreen, setErrorMessage}) => {
    const [client, setClient] = useState({name: '', 
                    address: '', 
                    businessPosition: '', 
                    email: '', 
                    identity: '', 
                    CEP: '', 
                    phone01: '', 
                    phone02: '', 
                    origem: 'Indicação', uf: '', localidade: '',
                    classification: 'Revenda', companyId: UserStore.getUserId()})


                    const getAddress = async (CEP, client) => {
                    
                            try {
                              const response = await axios.get(`https://viacep.com.br/ws/${CEP.replace('-', '')}/json/`);
                              const data = response.data;
                              client.localidade = data.localidade;
                              client.uf = data.uf;
                              return client;
                            } catch (error) {
                              console.error("Error fetching address data: ", error);
                              return client; // or handle the error in a different way
                            }
                
                          
                          
                          
                          
                      }

                    const createNewClient = async() =>{
                        const localClient = await getAddress(client.CEP, client);




                        if (!localClient.name || localClient.name === '') {
                            setErrorMessage('Preencha o   "Nome"');
                        } else if (!localClient.address || localClient.address === '') {
                            setErrorMessage('Preencha o   "Endereço"');
                        } else if (!localClient.businessPosition || localClient.businessPosition === '') {
                            setErrorMessage('Preencha o   "Cargo"');
                        } else if (!localClient.email || localClient.email === '') {
                            setErrorMessage('Preencha o   "Email"');
                        } else if (!localClient.identity || localClient.identity === '') {
                            setErrorMessage('Preencha o   "Identidade"');
                        } else if (!localClient.CEP || localClient.CEP === '') {
                            setErrorMessage('Preencha o   "CEP"');
                        } else if (!localClient.phone01 || localClient.phone01 === '') {
                            setErrorMessage('Preencha o   "Telefone"');
                        } else if (!localClient.phone02 || localClient.phone02 === '') {
                            setErrorMessage('Preencha o   "Celular"');
                        } else if (!localClient.uf || localClient.uf === '') {
                            setErrorMessage('CEP inválido');
                        } else if (!localClient.localidade || localClient.localidade === '') {
                            setErrorMessage('CEP inválido');
                        }else{                         setIsLoadingScreen(true);                       
                          console.log(localClient  )
                        await axios.post('https://j3vspr7o7245g62c25s4rywaly0koyzr.lambda-url.us-east-2.on.aws/', {client: localClient})
                        .then(resp=>{setIsLoadingScreen(false); close()}).catch(err=>{setIsLoadingScreen(false); console.log(err)})
}

                    }
                    const formatCEP = (CEP) => {

                        
                        var formattedCEP = CEP.replace(/\D/g, '');
                        formattedCEP = formattedCEP.replace(/(\d{5})(\d{3})/, "$1-$2");

                        console.log(CEP); console.log(formattedCEP)
                        return formattedCEP;
                    }
  return (
    <div className={styles.registerClient}>
        <div className={styles.content}>
            <div className={styles.topInfo}>
                <h2>Cadastrar Cliente</h2>
                <span onClick={close}>x</span>   
            </div>    
            <div className={styles.middleInfo}>
                <div className={styles.leftContent}>
                    <label>Nome</label><input type="" onChange={e=>setClient({...client,  name:  e.target.value})}/>
                    <label>Endereço</label><input type="" onChange={e=>setClient({...client, address:  e.target.value})}/>
                    <label>Cargo</label><input type="" onChange={e=>setClient({...client, businessPosition:  e.target.value})}/>
                    <label>Email</label><input type="" onChange={e=>setClient({...client, email:  e.target.value} )}/>
                </div>







    
                <div className={styles.rightContent}>
                    <label>CNPJ / CPF</label><input type="" value={client.identity} maxLength={18} onChange={e=>setClient({...client, identity:  StringMask(e.target.value, { type:'CPFCNPJ' } ,'')})}/>
                    <label>CEP</label><input type="" value={client.CEP} maxLength={9 } onChange={e=>setClient({...client, CEP:  formatCEP(e.target.value)})}/>
                    <div><label>Telefone<input type="" value={client.phone01} maxLength={15} onChange={e=>setClient({...client, phone01:  StringMask(e.target.value, { type:'Phone' } ,'')  })}/></label>
                    <label>Celular<input type="" value={client.phone02} maxLength={15} onChange={e=>setClient({...client, phone02:  StringMask(e.target.value, { type:'Phone' } ,'')})}/></label></div>
                    <div><label>Origem<select onChange={e=>{console.log(e.target.value); setClient({...client, origem: e.target.value})}}>
                        <option value="Indicação">Indicação</option>
                        <option value="Internet">Internet</option>
                        <option value="E-mail">E-mail</option>
                        </select></label>
                    </div>


                </div>
            </div>   
            <div className={styles.bottomInfo}><div onClick={createNewClient} className={styles.registerButton}>Registrar</div></div>
        </div>
    </div>










  )
}

export default RegisterClient  