import React, { useState } from 'react'
import styles from './SaleCard.module.sass'
import Select from 'react-select';
import { DateMask, StringMask } from '../../../library/StringMasks';
import { botconversaWebhook } from '../../../../functions/botconversaWebhook';
import axios from 'axios';
import UserStore from '../../../../store/userStore';
const SaleCard = ({getSales, close, productsSelection, getSalesList, clientsSelection, month, isLoadingScreen, setIsLoadingScreen}) => {
    
    const [addClientToSale, setAddClientToSale] = useState(false)   
    const installments =
    [
        { label: 'À vista', value: 1 }, 
        { label: '2 meses',  value: 2 },
        { label: '3 meses',  value: 3 },
        { label: '4 meses',  value: 4 },
        { label: '5 meses',  value: 5 },
        { label: '6 meses',  value: 6 },
        { label: '7 meses',  value: 7 },
        { label: '8 meses',  value: 8 },
        { label: '9 meses',  value: 9 },
        { label: '10 meses', value: 10 },
        { label: '11 meses', value: 11 },
        { label: '12 meses', value: 12 }
    ];
        


    const recurrenceMonths =
    [
        { label: '2 meses',  value: 2 },
        { label: '3 meses',  value: 3 },
        { label: '4 meses',  value: 4 },
        { label: '5 meses',  value: 5 },
        { label: '6 meses',  value: 6 },
        { label: '7 meses',  value: 7 },
        { label: '8 meses',  value: 8 },
        { label: '9 meses',  value: 9 },
        { label: '10 meses', value: 10 },
        { label: '11 meses', value: 11 },
        { label: '12 meses', value: 12 }
    ];
        
    const recurrences =
    [
      { label: 'Sem recorrência', value: 0, customLabel: 'Parcelas' },
      { label: 'Com recorrência', value: 1, customLabel: 'Renovações' }
    ];



    //console.log('productsSelection')
    //console.log(productsSelection[0].value)
    const taskCategories =
[
    { label: 'Operacional', value: 0 },
    { label: 'Relacionamento', value: 1 },
    { label: 'Marketing e vendas', value: 2 },
    { label: 'Financeiro', value: 3 },
    { label: 'Canais de comunicação', value: 4 },
    { label: 'Parcerias', value: '5' }
];



const selecStyles =
{
    valueContainer:( provided, state ) => ( { ...provided, padding:'0px 5px' } ),
    dropdownIndicator:( provided, state ) => ( { ...provided, padding:'4px' } ),
    control:( provided, state ) => ( { ...provided, minHeight:'10px', fontWeight:'normal' } )
};






const localMaskValue = (value) =>{

        
    if (value) {
        const splittedValue = value.toString().split('.')


    
        if (!splittedValue[1]) {
            splittedValue[1] = '00'
        }
    console.log('splitted');console.log(splittedValue);console.log(splittedValue[1]) 
        const splittedDigits = splittedValue[1].length>1 ? splittedValue[1] : `${splittedValue[1]}0`


        const valueDigits = splittedDigits ? Number(splittedDigits) : 0




        const isInteger = (valueDigits===0) ? true : false

        const convertedValue = isInteger ? value.toFixed(2) : parseFloat(value).toFixed(2)

        const maskedString = convertedValue.toString().replace( '.', '$' ).replace( ',', '' ).replace('$', ',');    
        console.log('CONV');console.log(maskedString);console.log(isInteger)    
        return maskedString

    }}
    const newSellInputEvent = ( event, source ) =>
    {
        let quantity = 0;
        let value = 0;
        let total = 0;
        console.log(event)
        switch( source )
        {
            case 'product':
                setNewSellInfo( { ...newSellInfo, [ source ]:event, value: localMaskValue(event.value ) } );
                break;
            case 'recurrence':
                if( event.value === 0 )
                {
                    setNewSellInfo( { ...newSellInfo, [ source ]: event, installments: installments[0], recurrenceMonths: { label: '', value: 1 } } );
                }
                else
                {
                    setNewSellInfo( { ...newSellInfo, [ source ]:event, installments: installments[0], recurrenceMonths: recurrenceMonths[0] } );
                }
                break;
            case 'installments':
                if( newSellInfo.recurrence.value === 0 )
                {
                    setNewSellInfo( { ...newSellInfo, installments: event } );
                }
                else
                {
                    setNewSellInfo( { ...newSellInfo, recurrenceMonths: event } );
                }
                break;
            case 'quantity':
                quantity = parseInt( StringMask( event.target.value, { type:'onlyNumbers' }, '' ) );
                value = parseFloat( StringMask( newSellInfo.value, { type:'float' }, '' ) );

                console.log(`Valor ${newSellInfo.value}`)
                total = quantity * value;
                setNewSellInfo( { ...newSellInfo, [ source ]:quantity, total:StringMask( parseFloat( total ).toFixed( 2 ), { type:'2DigitFraction' }, '' ) } );
                break;
            case 'value':
                quantity = parseFloat( newSellInfo.quantity );
                value = parseFloat( StringMask( event.target.value, { type:'float' }, '' ) );
                total = quantity * value;
                setNewSellInfo( { ...newSellInfo, [ source ]:StringMask( event.target.value, { type:'2DigitFraction' }, '' ), total:StringMask( parseFloat( total ).toFixed( 2 ), { type:'2DigitFraction' }, '' ) } );
                break;
            case 'date':
                setNewSellInfo( { ...newSellInfo, [ source ]:StringMask( event.target.value, { type:'DDMMYYYY' }, '' ) } );
                break;
            case 'notes':
                setNewSellInfo( { ...newSellInfo, [ source ]:event.target.value } );
                break;




            case 'client':

                setNewSellInfo({ ...newSellInfo, [ source ]:event.value }           )



                break;
            default:
                break;

        }
    }        
    
    const registerNewSale = async(sale) =>{ 
        var curValue = `${sale.product.value}` //StringMask( , { type:'float' }, '' );
        var value = StringMask( sale.value, { type:'float' }, '' );
        setIsLoadingScreen(true)
        console.log(curValue);console.log(value)
        
        
        console.log(sale) 

        if (!addClientToSale) {
            sale.client = ''
        }
        const resp = await axios.post( 
              'https://c6eulyfzkrnvmnqvaepfylfbme0ylxvv.lambda-url.us-east-2.on.aws/', 
            { 
                company: UserStore.getUserId(),  
                token: UserStore.getToken(),  
                product: sale.product.product,  
                quantity: sale.quantity,  
                curValue: curValue,
                value: value,  
                installments: sale.installments.value,  
                date: sale.date,  
                notes: sale.notes,     
                recurrenceMonths: sale.recurrenceMonths.value,
                client: sale.client




            } ).catch(err=>setIsLoadingScreen(false));
        if( resp.data.product !== -1 )
        {

            setIsLoadingScreen(false);
            close()
            //setSuccessAddSell(true);

            //window.location.reload( false );
        }        //getSalesList()

        botconversaWebhook({locale: 'Gestão de Vendas', action: 'Cadastrou Venda'});
        setIsLoadingScreen(false)
        getSales({month: month})




    }
const sellData = {
    value:            (productsSelection ? localMaskValue( productsSelection[0].value) : '---'),
    recurrenceMonths: { label: '',  value: 1 },
    date:             DateMask( new Date() ),
    product:          (productsSelection ? productsSelection[ 0 ].label : ''),
    installments:     (installments ? installments[ 0 ] : ''),
    recurrence:       (recurrences ? recurrences[0] : ''),
    total:            '0,00',
    quantity:         '',
    notes:            '',

    client: ''
}
const [ newSellInfo, setNewSellInfo ] = 
useState
( sellData );



const [ removeExpenseWarn, setRemoveExpenseWarn ] = useState(false)
const removeSale = async( sale ) =>
{
    setRemoveExpenseWarn(false)
    setIsLoadingScreen(true)
    
        await axios.delete( 'https://vsp4cmb53av3sywzzjw62pbfue0sbfyh.lambda-url.us-east-2.on.aws/', { data: { sale:sale, company:UserStore.getUserStore(), token:UserStore.getToken() } } ) 
        .catch(err=>{setIsLoadingScreen(false)})
        
            setIsLoadingScreen(false);
    
    

};
  return (
    <div className={styles.saleCard}>


        <div className={styles.content}>
        <span onClick={close}>x</span>

        <table className='actionPlanSellDebtPopup'>
                    <tbody><tr>
                             <td>Adicionar Cliente à Venda</td><td colSpan={ 3 }>Cliente</td>  
                        </tr>                         <tr>
                            
                            
                            
            <td style={{display: 'flex', alignItems: 'center', gap: '20px'}}><label for="yes"> <input type="radio" style={{marginRight: '4px'}} id="yes" name="fav_language" value="yes" onChange={()=>setAddClientToSale(true)}/>
SIM</label> 
<label for="no"><input type="radio" style={{marginRight: '4px'}} id="no" name="fav_language" value="no" onChange={()=>setAddClientToSale(false)} checked={!addClientToSale}/>
 NÃO</label> </td>
                            <td colSpan={ 3 }><Select className='actionPlanPopupSelector' styles={ selecStyles } isDisabled={!addClientToSale} options={ clientsSelection && clientsSelection } defaultValue={ '' } onChange={ ( event ) => newSellInputEvent( event, 'client' ) }/></td>
            
                        </tr>                        
                        <tr>
                            <td colSpan={ 3 }>Produto</td>   
                            <td>Valor no sistema (R$)</td>
                        </tr>                                               
                        <tr>
                            <td colSpan={ 3 }><Select className='actionPlanPopupSelector' styles={ selecStyles } options={ productsSelection && productsSelection } defaultValue={ newSellInfo ? newSellInfo.product : '' } onChange={ ( event ) => newSellInputEvent( event, 'product' ) }/></td>
                            <td><input className='actionPlanPopupInput' type="text" value={ ( newSellInfo.product !== undefined ) ? newSellInfo.value : '---' } disabled/></td>
                        </tr>
                        <tr>
                            <td colSpan={ 2 }>Quantidade</td>
                            <td>Valor unitário(R$)</td>
                            <td>Valor total (R$)</td>
                        </tr>
                        <tr>
                            <td colSpan={ 2 }><input className='actionPlanPopupInput' type="text" value={ newSellInfo.quantity } onChange={ ( event ) => newSellInputEvent( event, 'quantity' ) }/></td>
                            <td><input className='actionPlanPopupInput' type="text" value={ newSellInfo.value } onChange={ ( event ) => newSellInputEvent( event, 'value' ) }/></td>
                            <td><input className='actionPlanPopupInput' type="text" value={ newSellInfo.total } disabled/></td>
                        </tr>
                        <tr>
                            <td>Recorrência</td>
                            <td>{ newSellInfo.recurrence.customLabel }</td>
                            <td>Data da operação</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><Select className='actionPlanPopupSelector' styles={ selecStyles } options={ recurrences } value={ newSellInfo.recurrence } onChange={ ( event ) => newSellInputEvent( event, 'recurrence' ) }/></td>
                            <td><Select className='actionPlanPopupSelector' styles={ selecStyles } options={ ( newSellInfo.recurrence.value === 0 ) ? installments : recurrenceMonths } value={ ( newSellInfo.recurrence.value === 0 ) ? newSellInfo.installments : newSellInfo.recurrenceMonths } onChange={ ( event ) => newSellInputEvent( event, 'installments' ) }/></td>
                            <td><input className='actionPlanPopupInput' type="text" placeholder='01/01/2023' maxLength='10' value={ newSellInfo.date } onChange={ ( event ) => newSellInputEvent( event, 'date' ) }/></td>
                            <td><div className='actionPlanPopupBtnOk' onClick={ () => {    setAddClientToSale(false);    botconversaWebhook({action: 'Adicionou venda'}); registerNewSale( newSellInfo )} }>Registrar</div></td>
                        </tr>
                        <tr>
                            <td colSpan={ 4 }>Notas sobre a registro</td>
                        </tr>
                        <tr>
                            <td colSpan={ 4 }><textarea className='actionPlanPopupTextArea' maxLength={ 512 } onChange={ ( event ) => newSellInputEvent( event, 'notes' ) } placeholder={ 'Adicione aqui qualquer notas importante sobre o registro.\nEx.: Descontos, razão, parcelamento.' }/></td>
                        </tr>
                    </tbody>
                </table> 

        </div>


        
    </div>


  )
}

export default SaleCard