import UserStore from './../store/userStore';
import { colors, AppArea } from "../components/Styles";
import React, { useState } from 'react';
import axios from 'axios';
import Background from "./../assets/background_purple.png";
import styles from './RecoverPassword.module.css'
import LoginContainer from '../components/loginContainer/LoginContainer';
import LoginButton from '../components/loginButton/LoginButton';


var md5 = require('md5');

const RecoverPasswordPage = () => {

    const [loading, setLoading] = useState(false);




    const recoverPasswordBtn = async() =>
    {

        setLoading(true);
        var email = document.getElementsByName( "email" )[ 0 ].value;
        var password = md5('');
        const resp = await axios.get
        (
            'https://csgebkbi7dniy6uayt3ureh6km0lguwe.lambda-url.us-east-2.on.aws/',
            {
                params:
                {
                    email: email,
                  
                }
            }
        );

        if( resp.data.token )
        {
            if( resp.data.token !== '' && resp.data.userId !== '' )
            {
                //UserStore.setToken( resp.data.token );
                //UserStore.setLogged( true );
                //UserStore.setUserEmail( email );
                //UserStore.setUserId( resp.data.userId );
                //UserStore.setMyModel( resp.data.modelId );
                //UserStore.setAccType( resp.data.accType );
                //window.location.replace("/recoverpassword");



                const token = resp.data.token
                const id = resp.data.userId
                const date = new Date();
                const hashcode = md5(email+date);
                const otpcode = Math.floor(Math.random() * 600000 + date.getMilliseconds()*350);

                
                console.log(hashcode)
                await axios.put('https://zto5h6ch2d3yh4xaao7wkqin340wrsso.lambda-url.us-east-2.on.aws/', {
                    id: id,
                    token: token, //
                    email:email,
                    hashcode:hashcode,
                    otpcode:otpcode
                }).catch(err=>{console.log(err); setLoading(false)})


                await axios.get('https://x5sukrhatrp6cuzzpdby7dzzge0ekbvt.lambda-url.us-east-2.on.aws/', {params:{email:email, otpcode:otpcode, hashcode:hashcode}})
   

                window.location.replace(`/recoverpassword/otpcode?${hashcode}`);

                setLoading(false)



            }
        }

        else{
            var errorText = document.getElementById('wrongLogin');
            errorText.style.display = 'block';
            setLoading(false)

        }
    };

    return (
        <div id="app" style={{display:'flex',flexDirection:'row',width:'100%',height:'100%'}} >  
                <LoginContainer id="quizContainer" bg={Background}  > 
                    <AppArea id="login">
                        <div className="login-area">
                            <div className="form-box">
                                <div className="header-form">
                                    <h4 className="text-primary text-center"><i className="fa fa-envelope" style={{fontSize:"110px", color:colors.primary}}></i></h4>
                                    <div className="image" />                                    
                                </div>
                                <div className="body-form">     

                                            <p className={styles.title}>Iremos enviar um codigo de recuperação para o seu email</p>  

                                    <div className="input-group mb-3">
                                      
                                        <div className="input-group-prepend">


                                            <span className="input-group-text"><i className="fa fa-user"></i></span>
                                        </div>
                                        <input name='email' type="text" className="form-control" placeholder="Insira seu email" />
                                    </div>

                                    <text className='modelSettingsErrorMsg' 
                                            style={{fontSize:'1rem',fontWeight:'600'}}
                                            id='wrongLogin'>Usuario não encontrado.<br/> Por favor, revise seu email.</text>
                                    {!loading && <LoginButton onClick={ recoverPasswordBtn }>ENVIAR CODIGO</LoginButton>}       
                                    {loading && <LoginButton disabled="disabled">CARREGANDO...</LoginButton>}   
                                    {/* <div className="social">
                                        <a href="/"><i className="fab fa-facebook" style={{color:colors.primary}}></i></a>
                                        <a href="/"><i className="fab fa-twitter-square" style={{color:colors.primary}}></i></a>
                                        <a href="/"><i className="fab fa-google" style={{color:colors.primary}}></i></a>
                                    </div> */}
                                </div>
                                
                                <text className={styles.bottoInfo}>*O código será válido por 1 dia.</text>
                            </div>
                        </div>   
                    </AppArea>                  
                </LoginContainer>                                   
        </div>
    );
}

export default RecoverPasswordPage;