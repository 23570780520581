import React from 'react'
import { useState } from 'react'
import ModelRecForm from '../../../components/modelRecomendation/ModelRecForm'
import UserStore from '../../../store/userStore';
import ModattaModelRecResult from './modattaModelRecResult/ModattaModelRecResult';
import ModattaModel from './modattaModel/ModattaModel';

const ModattaQuestions = ({userId, modid, setAreQuestionsComplete}) => {
  
  const initialSelectedOptions =  [ 
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
    [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ] 
];    




  const [ QuizStep, setQuizStep ] = useState( { step: 0, modelId: -1 } );
  const [ MyBusinessModel, setMyBusinessModel ] = useState( { modelName: '', selectedOptions: initialSelectedOptions } );
  const [ RecModelsByQuiz, setRecModelsByQuiz ] = useState( { First: { modelId: 0, modelName: '', modelFit: 0 }, Second: { modelId: 0, modelName: '', modelFit: 0 }, Third: { modelId: 0, modelName: '', modelFit: 0 } } );
  
if( ( QuizStep.step === 0 ) && ( QuizStep.modelId === -1 ) && ( UserStore.getMyModel() ) )
 {
     //setQuizStep( { step: 2, modelId: -1 } );
}

  return (
    <> 
        {(QuizStep.step !== 1 && QuizStep.step !==2) &&<ModelRecForm setQuizStep={ setQuizStep } MyBusinessModel={ MyBusinessModel } setRecModelsByQuiz={ setRecModelsByQuiz } setMyBusinessModel={ setMyBusinessModel }/>}
        {(QuizStep.step === 1) && <ModattaModelRecResult userId={userId} setQuizStep={ setQuizStep } RecModelsByQuiz={ RecModelsByQuiz } MyBusinessModel={ MyBusinessModel }/>}
        {(QuizStep.step === 2) && <ModattaModel setAreQuestionsComplete={setAreQuestionsComplete} modid={modid} QuizStep={ QuizStep } setQuizStep={ setQuizStep }/>}
    </>
  )
}

export default ModattaQuestions