import { useState } from 'react';
import styles from './ReadMoreContentJourneyCard.module.css';
import { useEffect } from 'react';
export const ReadMoreContentJourneyCard = (props) =>{
    const [secondButton, setSecondButton] = useState(false);
    useEffect(()=>{
        setSecondButton(props.moreButtons);

    }, [props.moreButtons]);



    return(
        <div className={styles.readMoreContentJourneyCard}>

            <div>
                    <div><h2>{props.title}</h2></div>
                <div>


                    <div><p>{props.message}</p></div>
                    
                </div>
                <div>
                    <button className={styles.confirmAddButton} onClick={props.onClick}>Fechar</button>
                    {secondButton && <button
                    className={styles.confirmAddButton} 
                    style={{color: 'rgb(46, 53, 88)', fontWeight: 'bold', backgroundColor: 'white', borderColor: 'rgb(46,53,88)'}}
                    onClick={props.closeWarn}
                    >Cancelar</button>
                    }
                </div>
                

            </div>
        </div>



    )


}