import { IconRemoveExpense } from '../imgsLoader/LoadIconImgs';
import { AddElementLayout } from '../addElementLayout/AddElementLayout';
export const RemoveElement = (props) =>{
const message = props.message;
    return(
        <div>
            <AddElementLayout closeWarn={props.closeWarn} moreButtons={props.moreButtons} message={message} image={IconRemoveExpense} onClick={props.onClick}/>
        </div>
    )
}

