import React from 'react'
import { useRecentVideoData } from '../../hooks/useRecentVideoData.js'
import { useEffect } from 'react';
import { useState } from 'react';
import styles from './RecentVideoCard.module.css';
import redirectTo from '../../functions/redirectTo.js';
import UserStore from '../../../../store/userStore.js';


const RecentVideoCard = ({defaultPicture}) => {

const {data: video, isLoadingScreen} = useRecentVideoData()
    
    const redirectToVideos = () =>{

      UserStore.setSelectedPageStore('videoTrail/introduction')
      redirectTo(video.trailPath, video.trailCode, video.position)   

    }





  return ( <>{video && !isLoadingScreen && 
      
         <div className={styles.recentVideoCard}>
        

            <div className={styles.leftContainer}>
              <span>NOVO VÍDEO</span>
              <h2>{video.title}</h2>
              <p>{video.videoDescription}</p>
              <button onClick={redirectToVideos}><i className="fa fa-play"></i>VER AGORA</button>

            </div>



            <div className={styles.rightContainer}>   
              {<img src={video.pictureUrl ? video.pictureUrl : defaultPicture} alt=""/>}
            </div>

        
  
        </div>        } </>
  )
}

export default RecentVideoCard