import UserStore from '../../../../store/userStore';
import { colors, QuizButtonNext, AppWidgetProgressBar, AppWidgetResultTitle } from "../../../../components/Styles";
import React from 'react';
import '@coreui/coreui/dist/css/coreui.min.css'
import { CircularProgressbar } from "react-circular-progressbar";
import { CProgress, CProgressBar } from '@coreui/react'
import Check from "../../../../assets/icons/whiteCheck.png";
import axios from 'axios';

const ModattaModelRecResult = ( props ) =>
{
    var decisionMatrix = props.MyBusinessModel.selectedOptions;
    var optionPopUp = true;
    let restartOpt = '';
    
    const closePopUp = ()=>{
        let popUp = document.getElementById('popUpDiv');
        popUp.style.display='none';
    }

    const selectYes = ()=>{
        let yesDiv = document.getElementById('yesDiv');
        let noDiv = document.getElementById('noDiv');
        yesDiv.style.backgroundColor = '#211DFF';
        noDiv.style.backgroundColor = 'transparent';
        optionPopUp = true;
    }

    const selectNo = ()=>{
        let yesDiv = document.getElementById('yesDiv');
        let noDiv = document.getElementById('noDiv');
        yesDiv.style.backgroundColor = 'transparent';
        noDiv.style.backgroundColor = '#211DFF';
        optionPopUp = false;
    }

    const sendInfo = ()=>{
        let popUp = document.getElementById('popUpDiv');
        popUp.style.display='none';
    }


    const checkModel = async( modelId ) => {
        const { userId } = props
        var token = UserStore.getToken();
        var quizOptions = '';

        for( var step = 0; step < decisionMatrix.length; step++ )
        {
            for( var option = 0; option < decisionMatrix[ step ].length; option++ )
            {   
                if( decisionMatrix[ step ][ option ] === 0 )
                {
                    quizOptions = quizOptions + '0'
                }
                else
                {
                    quizOptions = quizOptions + '1'
                }

                if( option < decisionMatrix[ step ].length - 1 )
                {
                    quizOptions = quizOptions + ','
                }
            }

            quizOptions = quizOptions + ';'
        }
        console.log('ModelRec ID');console.log(userId)
        const resp = await axios.get( 'https://i2posxecqgbp443z3vqhrbfioq0zlbrh.lambda-url.us-east-2.on.aws/', { params: { userId: userId, modelId: modelId, token: '', quizOptions: quizOptions } } );

        if( resp )
        {
            if( resp.data )
            {
                if( resp.data.updated )
                {
                    if( resp.data.updated !== '' )
                    {
                        props.setQuizStep( { step: 2, modelId: modelId } );
                    }
                }
            }
        }
    };

    if( UserStore.getAccType() == 5 )
    {
        restartOpt = ( <div className="BoardBtnOk" onClick={ () => props.setQuizStep( { step: 0, modelId: 0 } ) }>Recomeçar</div> );
    }

    return (
            <div className='FullBoardContent'>
                <div>
                    <CProgress height={ 10 } color={ colors.lightblue }>
                        <CProgressBar value={ 100 } id="StepProgressBarId"/>
                    </CProgress>
                    <div className='BoardTitleBtnsInline'>
                        { restartOpt }
                    </div>
                    <div id="StepProgressId" className="FullBoardContentTitle">
                        Recomendação de modelo para seu negócio - 100% concluído
                    </div>
                    <div id="NumberOfOptionsId" className="FullBoardContentSubTitle">
                        Modelos recomendados que mais se encaixam com seu negócio em porcentagem de compatibilidade
                    </div>
                </div>
                <div className='HorizontalLineSeparator'/>
                <table style={ { margin: 'auto' } }>
                    <tbody>
                        <tr style={{borderRadius:'1rem'}}>
                            <td style={{width:"350px", textAlign:"center", padding: "50px"}}>
                                <AppWidgetResultTitle>
                                    { props.RecModelsByQuiz.Second.modelName }
                                </AppWidgetResultTitle>
                                <AppWidgetProgressBar>
                                    <CircularProgressbar
                                        value={ props.RecModelsByQuiz.Second.modelFit }
                                        text={`${ props.RecModelsByQuiz.Second.modelFit }%`}
                                        strokeWidth={12}
                                        styles={{
                                            path: {
                                            stroke: `${colors.silver}`,
                                            transition: 'stroke-dashoffset 0.5s ease 0s'
                                            },
                                            text: {
                                                fill: '#000000',
                                                fontFamily: 'Montserrat',
                                                fontWeight: 'bold',
                                                fontSize: '12px',
                                            },
                                            trail: {
                                                stroke: `${colors.light3}`,
                                                strokeLinecap: 'butt',
                                                transform: 'rotate(0.25turn)',
                                                transformOrigin: 'center center',
                                            },
                                        }}
                                    />
                                </AppWidgetProgressBar>
                                <QuizButtonNext onClick={ () => checkModel( props.RecModelsByQuiz.Second.modelId ) }>
                                    Entender Modelo
                                </QuizButtonNext>
                            </td>
                            <td style={{width:"300px", textAlign:"center"}}>
                                <AppWidgetResultTitle>
                                    { props.RecModelsByQuiz.First.modelName }
                                </AppWidgetResultTitle>
                                <AppWidgetProgressBar>
                                    <CircularProgressbar
                                        value={ props.RecModelsByQuiz.First.modelFit }
                                        text={`${ props.RecModelsByQuiz.First.modelFit }%`}
                                        strokeWidth={12}
                                        styles={{
                                            path: {
                                            stroke: `${colors.gold}`,
                                            transition: 'stroke-dashoffset 0.5s ease 0s'
                                            },
                                            text: {
                                                fill: '#000000',
                                                fontFamily: 'Montserrat',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                            },
                                            trail: {
                                                stroke: `${colors.light3}`,
                                                strokeLinecap: 'butt',
                                                transform: 'rotate(0.25turn)',
                                                transformOrigin: 'center center',
                                            },
                                        }}
                                    />
                                </AppWidgetProgressBar>
                                <QuizButtonNext onClick={ () => checkModel( props.RecModelsByQuiz.First.modelId ) }>
                                    Entender Modelo
                                </QuizButtonNext>
                            </td>
                            <td style={{width:"350px", textAlign:"center", padding: "50px"}}>   
                                <AppWidgetResultTitle>
                                    { props.RecModelsByQuiz.Third.modelName }
                                </AppWidgetResultTitle>
                                <AppWidgetProgressBar>
                                    <CircularProgressbar
                                        value={ props.RecModelsByQuiz.Third.modelFit }
                                        text={`${ props.RecModelsByQuiz.Third.modelFit }%`}
                                        strokeWidth={12}
                                        styles={{
                                            path: {
                                            stroke: `${colors.cooper}`,
                                            transition: 'stroke-dashoffset 0.5s ease 0s'
                                            },
                                            text: {
                                                fill: '#000000',
                                                fontFamily: 'Montserrat',
                                                fontWeight: 'bold',
                                                fontSize: '12px',
                                            },
                                            trail: {
                                                stroke: `${colors.light3}`,
                                                strokeLinecap: 'butt',
                                                transform: 'rotate(0.25turn)',
                                                transformOrigin: 'center center',
                                            },
                                        }}
                                    />
                                </AppWidgetProgressBar>
                                <QuizButtonNext onClick={ () => checkModel( props.RecModelsByQuiz.Third.modelId ) }>
                                    Entender Modelo
                                </QuizButtonNext> 
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='modelRecDivHelpUs' id='popUpDiv'>
                    <div className='modelRecDivHelpUsCentralDiv'>
                        <text style={{alignSelf:'end', color:'#251F53',fontSize:'0.6rem', fontWeight:'bold', cursor:'pointer'}} onClick={closePopUp}>&#9587;</text>
                        <text className='modelRecDivHelpUsTitle'>Nos ajude a melhorar sua experiência na MeConecte</text>
                        <text className='modelRecDivHelpUsSubtitle'>Você acredita que os modelos recomendados se aplicam ao seu negócio?</text>
                        <div style={{display:'flex', flexDirection:'row', gap:'1.5rem', marginTop:'1rem'}}>
                            <div style={{display:'flex', flexDirection:'row',gap:'0.3rem'}}>
                                <div className='modelRecDivHelpUsSelectItem' onClick={selectYes} id='yesDiv'>
                                    <img src={Check} alt='' style={{width:'1.2rem',marginBottom:'0.4rem'}}/>
                                </div>
                                <text className='modelRecDivHelpUsSelectItemOptions'>SIM</text>
                            </div>
                            <div style={{display:'flex', flexDirection:'row',gap:'0.3rem'}}>
                                <div className='modelRecDivHelpUsSelectItem' onClick={selectNo} id='noDiv'>
                                    <img src={Check} alt='' style={{width:'1.2rem',marginBottom:'0.4rem'}}/>
                                </div>
                                <text className='modelRecDivHelpUsSelectItemOptions'>NÃO</text>
                            </div>
                        </div>
                        <text className='modelRecDivHelpUsSubtitle' style={{marginTop:'1.5rem'}}>
                            Caso não, qual modelo você acredita se aplicar ao seu negócio?
                        </text>
                        <textarea placeholder='Ex: Agência de publicidade' className='modelRecDivHelpUsTextArea' ></textarea>
                        <div className='modelRecDivHelpUsSendeBtn' onClick={sendInfo}>
                            Enviar a Resposta
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default ModattaModelRecResult;