import React, { useRef, useState } from 'react'   
import styles from './VideoTrailContainer.module.css'
import VideoTrailCard from '../videoTrailCard/VideoTrailCard'
import { useEffect } from 'react'
import axios from 'axios'
import { useMostRecentTrails } from '../../hooks/useMostRecentTrails'
import { convertToSemanticTimeFormat } from '../../../../functions/convertToSemanticTime'

const VideoTrailContainer = ({title, defaultPicture}) => {
  const { data: trails, isLoadingScreen } = useMostRecentTrails()

  const changePosition = useRef(null)
   
  const [trailDuration, setTrailDuration] = useState('')

  const limitSize = 4;
  const [haveMoreCards, setHaveMoreCards] = useState(false);
  if(trails.length>limitSize){
    setHaveMoreCards(true)



  }



  const [haveLeftArrow, setHaveLeftArrow] = useState(false)
  const [haveRightArrow, setHaveRightArrow] = useState(true)   

  const [rightPosition, setRightPosition] = useState('55px')


  const moveSlide = (leftOrRight) => {


    if (leftOrRight==='left') {


      setHaveRightArrow(true)
      changePosition.current.scrollLeft -= changePosition.current.offsetWidth
    
      if ((changePosition.current.scrollLeft - changePosition.current.offsetWidth)<= 0  ) {

        setRightPosition('55px')        
        setHaveLeftArrow(false)
      }    
    } 
    
    
    else {
      setHaveLeftArrow(true)

      setRightPosition('15px')
      changePosition.current.scrollLeft += changePosition.current.offsetWidth - 60
      if (changePosition.current.scrollLeft=== 0  ) {

      }
            console.log(changePosition.current.clientWidth)    
    }


  }



    

      







  const getDuration = (trail) =>{

    if (!trail.duration) {
      return 
    }
    return  convertToSemanticTimeFormat(trail.duration)



  }
  return (
    <div> 



      {!isLoadingScreen && <>          
      {haveMoreCards && haveLeftArrow &&<div className={styles.leftArrow} onClick={()=>moveSlide('left')}>&lt;</div>}           
      {haveMoreCards && haveRightArrow && <div className={styles.arrow} onClick={()=>moveSlide('right')} style={{right: rightPosition}}>&gt;</div>} 
        <div><p className={styles.title}>{title}</p></div>{console.log(trails)}
        <div className={styles.videoTrailContainer} ref={changePosition}>{trails && trails.map(trail=>(
        <VideoTrailCard   
          title={trail.title}
          backgroundPicture={trail.pictureUrl ? trail.pictureUrl : defaultPicture}
          time={getDuration(trail)}
          classesNum={trail.classesQuantity}
          trailPath={trail.path}
          trailCode={trail.code}
          />
          ))}</div>
       </>}
    
    </div>
  )
}

export default VideoTrailContainer