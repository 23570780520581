import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import styles from './CustomProgressBar.module.sass'
const CustomProgressBar = ({percent}) => {
  return (                 <div className={styles.custom}>                                                      
    <CircularProgressbar
        value={ 
            percent
        }
    text={ Math.round(percent)  + '%' }
    strokeWidth={10}
    styles={{
        path: { stroke: `#7BB460`, transition: 'stroke-dashoffset 0.5s ease 0s' },
        text: { fill: '#7BB460' , fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '20px' }
    }}
/></div> 
  )
}

export default CustomProgressBar