import React from 'react'
import styles from './ProductsContainer.module.sass'
import ProductCard from '../productCard/ProductCard'
const ProductsContainer = ({products, monthIndex, convertToCurrency}) => {console.log(products)
  return (
    <div className={styles.productsContainer}>
        {products && products.map(product=><ProductCard convertToCurrency={convertToCurrency} saleDate={product.saleDate} clientName={product.clientName} quantity={product.quantity} productName={product.name} targetValue={product.value} doneValue={product.value }/>)}


    </div>
  )
}

export default ProductsContainer