import React, { useState } from 'react'
import styles from './SubGroup.module.sass'
import VideosList from '../videosList/VideosList'
const SubGroup = ({videos, selectStep}) => {

  const [isOpened, setIsOpened] = useState(false)
  return (
    <div className={styles.subGroup} onClick={()=>setIsOpened(prev=>!prev)}>
        <div className={styles.subGroupInfo}><p>{videos && videos.sSubGroupData[0].sName}</p>
        
        
        
        {isOpened ? <span className={styles.opened}>v</span> : <span>v</span> }</div>

         
        <div>{isOpened && videos && <VideosList selectStep={selectStep} videosArray={videos.sSubGroupData} isSubGroup={true}/>}</div> 
      {/*<div className={styles.stepOption} onClick={()=>setIsCardOpened(prev=> !prev)}>    
        <div className={styles.leftContent}> 
            <CustomProgressBar percent={totalWatchedSteps && totalSteps && (totalWatchedSteps/totalSteps)*100}/>
            <div><span>{groupName}</span><span>{totalGroupDuration && totalGroupDuration}</span></div>
        
        </div>
        <span style={{rotate: isCardOpened && '180deg'}}>v</span>
    

      </div>   
      {isCardOpened && <VideosList videosArray={videosArray} selectStep={selectStep}/>}  
*/} 
    </div>
  )
}

export default SubGroup