import React, { useEffect } from 'react'
import styles from './JourneyNewIntroduction.module.sass'
import DefaultCard from './defaultCard/DefaultCard'



import JourneyFirstStep from '../../../assets/backgrounds/JourneyFirstStep.png'
import JourneySecondStep from '../../../assets/backgrounds/JourneySecondStep.png'
import JourneyThirdStep from '../../../assets/backgrounds/JourneyThirdStep.png'

import JourneyFourthStep from '../../../assets/backgrounds/JourneyFourthStep.png'
import IntroCard from './introCard/IntroCard'

import IconPain from '../../../assets/icons/nRa.svg'
import IconTarget from  '../../../assets/icons/IconTarget.svg'
import IconAquisition from  '../../../assets/icons/IconAquisition.svg'
import IconCommunication from  '../../../assets/icons/IconCommunication.svg'
import IconCompetitors from  '../../../assets/icons/IconCompetitors.svg'
import IconDelivery from  '../../../assets/icons/IconDelivery.svg'
import IconIntroduction from  '../../../assets/icons/IconIntroduction.svg'
import IconNeeds from  '../../../assets/icons/IconNeeds.svg'
import IconProblem from  '../../../assets/icons/IconProblem.svg'
import IconProducts from  '../../../assets/icons/IconProducts.svg'
import IconPurpose from  '../../../assets/icons/IconPurpose.svg'
import IconRelationships from  '../../../assets/icons/IconRelationships.svg'
import IconMarket from '../../../assets/icons/IconMarket.svg'
const JourneyNewIntroduction = () => {   
  const firstTopicList = [[{icon: IconIntroduction, title: "Introdução", description: "Comece sua jornada por aqui", url: "0/0"}]]

  const secondTopicList = [ 
    
    [
    
    {icon: IconPain, title: "Problema", url: "1/1"}, 
    
    {icon: IconMarket, title: "Mercado", url: "1/3"}], 
    
    [{icon: IconTarget, title: "Público alvo", url: "1/5"}, 
  
  {icon: IconCompetitors, title: "Concorrência", url: "1/7"}] ];

  const thirdTopicList = [  [{icon: IconProducts, title: "Produtos", url: "2/1"}], 
  
  [{icon: IconMarket, title: "Proposta de valor", url: "2/3"}]
  ]
  const fourthTopicList = [  [{icon: IconCommunication, title: "Comunicação", url: "3/1"}, 
  {icon: IconAquisition, title: "Vendas e aquisição", url: "3/3"}], 
  [{icon: IconDelivery, title: "Entregas", url: "3/5"}, 
  {icon: IconRelationships, title: "Relações", url: "3/7"}]]


  
  
  
  
  const fifthTopicList = [  
    
[{icon: IconMarket, title: "Atividade", url: "4/1"},{icon: IconMarket, title: "Recursos", url: "5/0"}], 
  [{icon: IconMarket, title: "Parcerias", url: "4/3"}]
  , ]
  /*const sixtyTopicList = [  {icon: IconCost, title: "Custos e despesas", url: ""}, 
  {icon: IconRevenue, title: "Receitas", url: ""}]*/
  const screenWidth = window.screen.width


  const changeHeight = ( ) =>{
    const board = document.querySelector('.BoardContent')
    board.style.height = 'calc(100vh - 145px)'

}      
const purpleBackground = () =>{
  const board = document.querySelector('.BoardRight')
  board.style.background = '#EEE8F4'

}      


useEffect(()=>{
    if (screenWidth<800) {

      purpleBackground()
      changeHeight()
    }



}, [])
  return (
    <div className={styles.journeyNewIntroduction}>


      <div className={styles.topOptions}>
        {screenWidth>800 && <IntroCard step={1} topicList={firstTopicList} title={`Entendendo seu modelo de negócio`} cover={JourneyFirstStep}/>
}

      </div>
      <div className={styles.bottomOptions}>

        {screenWidth<800 &&         <DefaultCard accessUrl={`0/0`} step={1} title={``} cover={JourneyFirstStep} topicList={firstTopicList}/> }
        <DefaultCard accessUrl={`1/0`} step={2} title={`Pra quem você cria valor? `} cover={JourneySecondStep} topicList={secondTopicList}/>
        <DefaultCard accessUrl={`2/0`} step={3} title={`O que você cria de valor?`} cover={JourneyThirdStep} topicList={thirdTopicList}/>
        <DefaultCard accessUrl={`2/0`} step={4} title={`Canais`} cover={JourneyFourthStep} topicList={fourthTopicList}/>
        <DefaultCard accessUrl={`3/0`} step={5} title={`Como faço tudo funcionar?`} cover={JourneyFourthStep} topicList={fifthTopicList}/>
        
       
       
       
       </div> 



    </div>
  )
}

export default JourneyNewIntroduction