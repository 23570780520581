import React, { useEffect, useState } from 'react'
import TableJourneyCard from '../journeyCard/tableJourneyCard/TableJourneyCard';
import TextWithTagsCard from '../journeyCard/textWithTagsCard/TextWithTagsCard';
import styles from './JourneyForWhatModel.module.css';
import RankingJourneyCard from '../journeyCard/rankingJourneyCard/RankingJourneyCard';
import axios from 'axios';
import UserStore from '../../store/userStore';
import ComparationJourneyCard from '../journeyCard/comparationJourneyCard/ComparationJourneyCard';
import { ReadMoreContentJourneyCard } from '../journeyCard/readMoreContentJourneyCard/ReadMoreContentJourneyCard';



const JourneyForWhatModel = () => {



  const changeBackgroundColor = () =>{

    const screenWidth = window.screen.width
    const boardContent = document.querySelector('.BoardContent')
        
    if (screenWidth>800) {
        boardContent.style.backgroundColor = '#270D43'   
        boardContent.style.padding = '10px 30px'
        boardContent.style.borderRadius = '22px'        
    }else{boardContent.style.backgroundColor = '#ffffff'}
  
}
useEffect(()=>{   
    changeBackgroundColor()
}, [])




    const [tableData, setTableData] = useState([])
    const [forWhat, setForWhat] = useState([])
    const [moreContent, setMoreContent] = useState(false)
    const [moreContentMessage, setMoreContentMessage] = useState('')


    useEffect(()=>{
    
      axios.get('https://bmiejsiuhnjv5ipb5sjnbanjr40vxxxu.lambda-url.us-east-2.on.aws/', {params:{company: UserStore.getUserId(), token: UserStore.getToken()}}).then(
        resp=>{

          if (resp.data) {
              setTableData(resp.data.products)            
          }

        }
        )

        axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/', {params:{ company:UserStore.getUserId() ,token: UserStore.getToken() }})
        .then((resp)=>{

            if (resp.data) {
            setForWhat(resp.data.What)

            }
        })
    }, [])



  
  return (
    <div className={styles.journeyForWhatModel}>



      
        {moreContent && <ReadMoreContentJourneyCard message={moreContentMessage}/>}
      <div><TableJourneyCard tableData={tableData}/></div>
      <div>
          <p className={styles.asideTitle}>Proposta de Valor</p>

          <div className={styles.asideForWhatCards}>
            <div style={{display: 'flex'}}>
              <div className={styles.middleForWhatCards}>
                <div>
                  <TextWithTagsCard title="Quais são os problemas e necessidades do público-alvo?" tags="Problema, Necessidade" content={forWhat.jwCustomerNeeds || <a href='valueproposition/questions'>Preencha aqui!</a>}/>
                  <TextWithTagsCard title="Quais beneficios o produto ou serviço entrega ao cliente?" tags="" content={forWhat.jwProductBenefits || <a href='valueproposition/questions'>Preencha aqui!</a>}/>
                </div>
              </div>
            <div className={styles.positionCards}>
                <ComparationJourneyCard title="Problema x Beneficio" problem01={forWhat.jwAssociatedProblemFirst} problem02={forWhat.jwAssociatedProblemSecond} problem03={forWhat.jwAssociatedProblemThird} benefit01={forWhat.jwAssociatedBenefitFirst} benefit02={forWhat.jwAssociatedBenefitSecond} benefit03={forWhat.jwAssociatedBenefitThird}/>
                <RankingJourneyCard title="Propostas de valor" valuePhrase01={forWhat.jwFirstValuePhrases} valuePhrase02={forWhat.jwSecondValuePhrases} valuePhrase03={forWhat.jwThirdValuePhrases}/>                        
            
            
            
            </div>               
 
         </div>
        </div>
      </div>
    
    </div>
  )
}

export default JourneyForWhatModel