import React from 'react'
import styles from './LoginContainer.module.sass'

const LoginContainer = ({id, bg, children}) => {
  return (
    <div className={styles.loginContainer}>
        <div className={styles.lightPointTop}></div>
        <div className={styles.lightPointBottom}></div>
        
         <img src={bg} alt=""/>
        {children}
        </div>
  )
}

export default LoginContainer




