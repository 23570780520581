
import styles from './ActionPlanCard.module.css';

export const ActionPlanCard = ({category, responsible, task, removeTaskFn, checkTaskFn}) =>{
let color = {
    backgroundColor: '',
    color: ''

    
}

    const verifyCategory = () =>{
        switch (category) {
            case 0:
                color.backgroundColor = 'rgb(255, 113, 8)'
                return 'Operacional';
            case 1:
                color.backgroundColor = 'rgb(255, 0, 184)'
                return 'Relacionamento';
        
            case 2:
                color.backgroundColor = 'rgb(32, 29, 255)'
                return 'Marketing e Vendas';
                
            case 3:    
                color.color =  'rgb(37, 31, 83)';
                color.backgroundColor = 'rgb(8, 191, 70)'
                
                return 'Financeiro';
        
                    
            case 4:
                color.backgroundColor = 'rgb(167, 167, 167)'
                return 'Canais de Comunicação';
                        
                           
            case 5:    

                color.color = 'rgb(37, 31, 83)'
                color.backgroundColor = 'rgb(255, 215, 8)'
                return 'Parcerias';

            default:
                return;



        }

    }

    const categoryText = verifyCategory(category)
    return(


        <div className={styles.actionPlanCard}>

    
            



            <div className={styles.categoryLine}>
                <div className={styles.planCardCategory} style={color}><p>{categoryText}</p></div>
                <div className={styles.arrowCategory}><input type="checkbox" onChange={checkTaskFn}/> </div>
            </div>
            <div className={styles.tasksLine}><p>{task}</p></div>
            <div className={styles.respoLine}><p>{responsible}</p></div>



        </div>



    )


}