import React from 'react'
import styles from './PlataformGuide.module.sass'
const PlataformGuide = ({openSelectModel}) => {
  return (
    <div className={styles.plataformGuide} onClick={openSelectModel}>
        <span>Guia</span>  
    </div>
  )
}

export default PlataformGuide