import React from "react";
import "../questions/questions.css";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import UserStore from "../../store/userStore";
import { useState,useEffect } from "react";
import axios from "axios";
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import { FailAddElement } from "../failAddElement/FailAddElement";


const CompetitorsQuestions = (props)=>{

    const [ journeyData, setJourneyData] = useState( null );
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)


    const LoadJourneyAnswers = async() =>{
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();

        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );

        if(resp.data.ForWhom )
        {
           setJourneyData(resp.data.ForWhom);
        }
   }

    const saveAnswers = async()=>{

        setLoading(true)
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData = 
        {
            jfwCompetitorsList:journeyData.jfwCompetitorsList, 
            jfwCompetitorsComparation:journeyData.jfwCompetitorsComparation, 
            jfwCompetitorsKnowledge:journeyData.jfwCompetitorsKnowledge,
            jfwCompetitorsWeakness:journeyData.jfwCompetitorsWeakness,
            jfwCompetitorsTecNeed:journeyData.jfwCompetitorsTecNeed
        };

        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'ForWhom', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })



        document.getElementById( 'saveBtn' ).innerHTML = "Salvar";

        
    }

    const changetext = ( e, source, choice ) =>
    {
        switch( source )
        {
            case 'text1':
                if(journeyData.jfwCompetitorsList !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}         
                setJourneyData({...journeyData, jfwCompetitorsList : e.target.value});
                break;
            case 'text2':
                if(journeyData.jfwCompetitorsComparation !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwCompetitorsComparation : e.target.value});
                break;
            case 'text3':
                if(journeyData.jfwCompetitorsKnowledge !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwCompetitorsKnowledge : e.target.value});
                break;
            case 'text4':
                if(journeyData.jfwCompetitorsWeakness !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwCompetitorsWeakness : e.target.value});
                break;
            case 'text5':
                if(journeyData.jfwCompetitorsTecNeed !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwCompetitorsTecNeed : e.target.value});
                break;
        }    
    }


    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()
        


    }


    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }

    useEffect( () => { LoadJourneyAnswers()}, [] );

    return(
        <div className="journeyFWQQuestionsLayout1">    
        {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>}
        {loading && <LoadingScreen/>}
        {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>}                
        
        
        
        {props.activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.redirect}/>}
        




                    <div className="journeyQuestionsSaveBtn" id='saveBtn' onClick={saveAnswers}>
                        Salvar
                    </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Quem vende produtos ou serviços similares, substitutos ou iguais ao seu?
                        <QuestionsInformation breakLine={true} information="Por mais que sempre pensemos na nossa solução como única e que ninguém faz melhor que a nossa empresa, é importante termos os concorrentes como mecanismo de aprendizado. Por exemplo, produtos ou serviços substitutos são aqueles que podem ser usados em vez do produto original para satisfazer uma necessidade semelhante. 
                        <br/>Eles podem ser de uma categoria diferente, mas oferecem uma função ou benefício similar. Por exemplo, se você deseja comprar um carro, pode considerar tanto veículos a gasolina quanto veículos elétricos como substitutos. 
                        <br/>Embora operem de maneiras diferentes, ambos são capazes de fornecer transporte pessoal. Os produtos similares são aqueles que compartilham características essenciais, desempenho e finalidade semelhantes. Por exemplo, se você está procurando por um telefone celular, existem várias marcas e modelos diferentes que oferecem funcionalidades e recursos comparáveis. 
                        <br/>Esses produtos similares podem variar em termos de design, preço, qualidade e marca, mas ainda atendem à mesma necessidade básica de comunicação móvel."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea1' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwCompetitorsList : '---'} onChange={(e) => changetext(e,'text1')} 
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea1')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea1')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">O seu concorrente entrega algo que você não entrega? Faz algo melhor do que você faz?
                        <QuestionsInformation information="Aqui você pode utilizar qualquer uma das três categorias (iguais,similares ou substitutos). Pense em algo que esse concorrente faz que te agrada e que você poderia incorporar na sua solução. Ou se existe algo que ele faz melhor do que você faz."/>                        
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea2' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwCompetitorsComparation : '---'} onChange={(e) => changetext(e,'text2')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea2')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea2')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Com a concorrência, o que você pode aprender sobre tecnologia, operação, marketing e vendas?
                        <QuestionsInformation haveTopics={true} breakLine={true} information="Tecnologia:-> Ao analisar os produtos ou serviços oferecidos pelos concorrentes, você pode aprender sobre as tecnologias utilizadas, recursos inovadores, melhorias de desempenho e tendências emergentes. Isso pode inspirar suas próprias iniciativas de desenvolvimento tecnológico e ajudar a identificar áreas em que você precisa aprimorar sua oferta para acompanhar o mercado.
                                <br/>Operação:-> Observar as práticas operacionais dos concorrentes pode fornecer insights sobre a eficiência, cadeia de suprimentos, logística, gestão de qualidade e estratégias de produção. Você pode aprender com seus pontos fortes e identificar oportunidades para otimizar seus próprios processos operacionais.
                                <br/>Marketing:-> A concorrência oferece a oportunidade de estudar as estratégias de marketing dos concorrentes, como posicionamento de marca, mensagens de comunicação, campanhas publicitárias, presença nas redes sociais e abordagens de segmentação de mercado. Isso pode ajudar a aprimorar suas próprias táticas de marketing e identificar lacunas ou oportunidades não exploradas.
                                <br/>Vendas:-> Ao analisar como os concorrentes abordam o processo de vendas, você pode aprender sobre suas estratégias de precificação, canais de distribuição, abordagem de vendas, atendimento ao cliente e práticas de pós-venda. Isso pode ajudar a melhorar suas próprias técnicas de vendas e identificar maneiras de fornecer uma experiência excepcional aos clientes."/>                        
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta."  value ={(journeyData !== null) ? journeyData.jfwCompetitorsKnowledge : '---'} onChange={(e) => changetext(e,'text3')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea3')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea3')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2" >
                        <text className="journeyFWQQuestionText">Em quais aspectos você acha que a concorrência falha?
                        <QuestionsInformation information="Pense nos aspectos relacionados às áreas da pergunta anterior (marketing e vendas, operação, tecnologia e operação). Veja se a concorrência falha em algum desses aspectos e se eles podem ser aproveitados pela sua empresa."/>                        
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea4' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwCompetitorsWeakness : '---'} onChange={(e) => changetext(e,'text4')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea4')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea4')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2" style={{marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText">Das tecnologias da concorrência, quais você precisa para ser competitivo?
                        <QuestionsInformation information="Pense nos produtos ou serviços oferecidos pelos concorrentes, você pode aprender sobre as tecnologias utilizadas, recursos inovadores, melhorias de desempenho e tendências emergentes. Isso pode inspirar suas próprias iniciativas de desenvolvimento tecnológico e ajudar a identificar áreas em que você precisa aprimorar sua oferta para acompanhar o mercado. "/>                        
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea5' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwCompetitorsTecNeed : '---'} onChange={(e) => changetext(e,'text5')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea5')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea5')}></img>
                        </div>
                    </div>
                </div>
    )
}

export default CompetitorsQuestions;