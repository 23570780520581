import React from 'react'
import styles from './SandwichMenuOptions.module.css'
import { MeSideLogoIconImg, ModelIconImg, DashboardIconImg, CashFlowIconImg, ActionPlanIconImg, JourneyIconImg, SettingsIconImg, IconPadlock, IconVideo } from '../../../imgsLoader/LoadIconImgs';

const SandwichMenuOptions = () => {



    
    const allOptions = [
        {
          "id": "0",
          "name": "Quiz",
          "icon": ModelIconImg,
          "url": "/modelrec"
        },
        {
          "id": "1",
          "name": "Jornada",
          "icon": JourneyIconImg,
          "url": "/journey"
        },
    
        {
          "id": "2",
          "name": "Plano de Ação",
          "icon": ActionPlanIconImg,
          "url": "/actionplan"
        },
        
        {
          "id": "3",
          "name": "Fluxo de Caixa",
          "icon": CashFlowIconImg,
          "url": "/cashflow"
        },
            
        {
          "id": "4",
          "name": "Dashboard",
          "icon": DashboardIconImg,
          "url": "/dashboard"
        },
                
            
        {
          "id": "5",
          "name": "Seção de Vídeos",
          "icon": IconVideo,
          "url": "/videoSection"
        },
                        
        {
          "id": "6",
          "name": "Configurações",
          "icon": SettingsIconImg,
          "url": "/modelsettings"
        }
                                    

    ]

  const redirecToOption = (url) =>{


    window.location.replace(`${url}`)



  }

  return (
    <div className={styles.sandwichMenuOptions}>
        
        {allOptions && allOptions.map(option=>(
            <div key={option.id} className={styles.sandwichMenuOption} onClick={()=>redirecToOption(option.url)}>
               <img src={option.icon} alt=""/> <p>{option.name}</p>
            </div>



        ))}

    </div>
  )
}

export default SandwichMenuOptions