import { colors, AppWidgetTitle} from "../Styles";
import React, { useRef, useState } from 'react';
import { IoTriangle } from 'react-icons/io5';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CProgress } from '@coreui/react'
import { CProgressBar } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'
import "./Dashboard.css";
import UserStore from '../../store/userStore';
import axios from 'axios';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { StringMask } from '../library/StringMasks';
import { calculateAliquote } from '../library/cashFlowLib'
import { MonthSelect } from '../monthSelect/MonthSelect';
import { ColorRing } from "react-loader-spinner";
import DashboardNew from "./DashboardNew";
import { botconversaWebhook } from "../../functions/botconversaWebhook";
ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend );
//TODO: Ver tamanho dos textos dentro das caixas
const actualMonth = new Date().getMonth() + 1;
let doneExpense = 0;



const options =
{
    indexAxis: 'y',
    elements: { bar: { borderWidth: 0 } },
    responsive: true,
    plugins: { legend: { display: false, rtl: false } },
    scales:
    {
        y:
        {
            ticks:
            {
                color: 'black',
                font: { family: 'Montserrat', size: 11 },
                reverse: false
            },
            grid: { color:"transparent" }
        },
        x:
        {
            ticks:
            {
                step: 100,
                color: 'black', 
                font: { family: 'Montserrat', size: 10 },
                reverse: false
            },
            grid: { borderColor:"black" }
        }
    }
};


const checkValue = (value) =>{
    if (isNaN(value)) {
        return 0;
    }
    else{
      return value;
    }
}


const Dashboard = () =>
{
const pageLocale = 'Painel de Indicadores'
useEffect(()=>{

    botconversaWebhook({locale: pageLocale, action: 'Abriu Dashboard'})
})
    const changeScrollBar = () =>{

        const screenWidth = window.screen.width
        
        if (screenWidth>780) {
          const boardContent = document.querySelector('.BoardContent')
          boardContent.style.height = '86vh'  
          boardContent.style.width = '94vw'

          
        }


    }
    const [yearExpense, setYearExpense] = useState(null);

    const [globalYearSales, setGlobalYearSales] = useState('---')
    const [globalMonthTicket, setGlobalMonthTicket] = useState('---')
    const [globalRemainingMonthGoal, setGlobalRemainingMonthGoal] = useState('0,00')
    const [globalMonthSales, setGlobalMonthSales] = useState('---')
    const [globalYearResult, setGlobalYearResult] = useState('---')
    const [globalYearExpenses, setGlobalYearExpenses] = useState('---')
    const [globalMonthCAC, setGlobalMonthCAC] = useState('---')
        
    const userId = UserStore.getUserId();
    const token = UserStore.getToken();


    const [globalExpenses, setGlobalExpenses] = useState(0);
    const [globalRevenues, setGlobalRevenues] = useState(0);
    const [allGlobalRevenues, setAllGlobalRevenues] = useState([])

    const [selectedMonth, setSelectedMonth] = useState(actualMonth);
    const revenuesCostsChart = useRef(null);
    //let costCoverageChart = React.createRef();
    const costCoverageChart = useRef(null);
    const [checkedRemainingMonthGoals, setCheckedRemainingMonthGoals] = useState(0);
    let selectedYear = 2024;
    let aliquotTable = 0;
    let rbt12 = 0;
    let monthTaxes = new Array( 12 ).fill( 0 );
    const [ yearGoals, setYearGoals ] = useState( 0 );
    const [ monthGoals, setMonthGoals] = useState( 0 );
    const [ revenuesCostsData, setRevenuesCostsData ] = useState( { labels: [ '', '' ], datasets: [ { data: [ 0, 0 ], backgroundColor: [ colors.purple, colors.lightPurple ] } ] } );
    const [ costCoverageData, setCostCoverageData ] = useState( { labels: [ '', '' ], datasets: [ { data: [ 0, 0 ], backgroundColor: [ colors.purple, colors.lightPurple ] } ] } );
    const [ tableMonthSales, setTableMonthSales ] = useState( [] );
    const [ loadingDashboard, setLoadingDashboard ] = useState( true );
    const [ screenDebounceTime, setScreenDebounceTime ] = useState( true );

    const loadingMonthSalesWarn =
        <tr>
            <td colSpan={ 6 } style={ { textAlign:'center', fontSize:'15px', fontWeight:'normal' } }>
                Por favor aguarde, carregando lista.
            </td>
        </tr>;

    const emptyMonthSalesWarn =
        <tr>
            <td colSpan={ 6 } style={ { textAlign:'center', fontSize:'15px', fontWeight:'normal' } }>
                Lista vazia, adicione produtos para controlar seu fluxo de caixa.
            </td>
        </tr>;


   

    
const convertMonthToValue = (monthName) =>{
    switch (monthName) {
        case 'Janeiro':
            return 1
        case 'Fevereiro':
            return 2
        case 'Março':
            return 3
        case 'Abril':
            return 4
        case 'Maio':
            return 5
        case 'Junho':
            return 6
        case 'Julho':
            return 7
        case 'Agosto':
            return 8
        case 'Setembro':
            return 9
        case 'Outubro':
            return 10
        case 'Novembro':
            return 11
        case 'Dezembro':
            return 12
                                                                                                         
        default:
            return;
        }
    }


    const constructTable = (name, index, monthQuantity, monthDone, monthTarget) =>{

      return (
        <tr key={ 's_' + uuidv4() }>
        <td style={ { paddingLeft: '5px', paddingRight: '5px', width: '25px' } }>
            {/* <IoTriangle color="green" /> 999%+ */}
        </td>
        <td style={ { padding: '5px', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '5px', paddingRight: '5px' } }>
            <AppWidgetTitle size={ 14 }>{ name }</AppWidgetTitle>
        </td>
        <td style={ { paddingLeft: '5px', paddingRight: '5px', textAlign:'center' } }>
            <AppWidgetTitle size={14}>{ StringMask( monthQuantity, { type:'onlyNumbers' }, '' ) }</AppWidgetTitle>
        </td>
        <td style={ { padding: '5px', textAlign:'center' } }>
            {/**/<AppWidgetTitle size={14}>{ StringMask( monthDone.toFixed( 2 ), { type:'2DigitFraction' }, '' ) }</AppWidgetTitle>}

    
        </td>
        <td style={ { padding: '5px', textAlign:'center' } }>
            <CProgress color={colors.purple} >
                <CProgressBar value={ parseInt( 100 * monthDone / monthTarget ) } color={colors.purple}/>
            </CProgress>
        </td>
    </tr>

      )


    }

    const fillDashboard = async() =>
    {
        let tableItems = [];
        let totalMonthQuantity = 0;
        let monthQuantity = 0;
        let monthDone = 0;
        let monthTarget = 0;
        let newLine = '';
        let monthDebts = 0;
        let monthRevenues = 0;
        let monthResult = 0;
        let yearSales = 0;
        let yearDebts = 0;
        let yearResult = 0;
        let yearGoals = 0;
        let monthGoals = 0;
        let remainingMonthGoals = 0;
        let monthTicket = '---';
        let monthCAC = '---';
        const userId = UserStore.getUserId();
        const token = UserStore.getToken();

        axios.get( 'https://6cjt7kosxbrqosqcd6gt7p32cm0pdgud.lambda-url.us-east-2.on.aws/', { params: { company: userId, token: token, month: selectedMonth, year: selectedYear } } ).then( ( dbResp ) =>
        {


            if( dbResp.data )
            {console.log('month'); console.log(dbResp.data)
                if( dbResp.data.monthList )
                {
                    for( let index = 0; index < dbResp.data.monthList.length; index++ )
                    {//console.log('Month Done');console.log(dbResp.data)
                        monthQuantity = ( dbResp.data.monthList[ index ].quantity ) ? dbResp.data.monthList[ index ].quantity : 0;
                        monthDone = ( dbResp.data.monthList[ index ].done ) ? dbResp.data.monthList[ index ].done : 0;

                        console.log('dbResp');console.log(monthQuantity)
                        //monthDone = globalRevenues
                        monthTarget = ( dbResp.data.monthList[ index ].target ) ? dbResp.data.monthList[ index ].target : 0.01;
                        newLine =  constructTable(dbResp.data.monthList[ index ].name, index, monthQuantity, monthDone, monthTarget)


                        monthRevenues += monthDone ? monthDone : 0;
                        totalMonthQuantity += monthQuantity;
                        tableItems.push( newLine );
                    }

                   // setTableMonthSales( [ ...tableItems ] );
                }

                if( dbResp.data.aliquot )
                {
                    aliquotTable = dbResp.data.aliquot;
                }

                if( dbResp.data.monthlyRevenues && dbResp.data.monthlySales )
                {
                    for( let monthIndex = 2; monthIndex <= new Date().getMonth(); monthIndex++ )
                    {
                        rbt12 = 0;

                        for( let rbtIndex = 12 - monthIndex; rbtIndex < 24 - monthIndex; rbtIndex++ )
                        {
                            rbt12 += dbResp.data.monthlyRevenues[ rbtIndex ];
                        }

                        monthTaxes[ monthIndex ] = calculateAliquote( dbResp.data.monthlySales[ 11 - monthIndex ], rbt12, aliquotTable );
                    }
                }
                let localQuantity = 0;
                dbResp.data.monthList.forEach(month=>{
                    if (month.quantity !== null) {
                        localQuantity += month.quantity ? month.quantity : 0
                    }   
                })
                //setGlobalQuantity(localQuantity)

                console.log('monthDebts');console.log(dbResp)
                monthDebts = ( dbResp.data.monthDebts ) ? ( monthTaxes[ new Date().getMonth() ] + dbResp.data.monthDebts ) : 0;
 
                monthResult = dbResp.data.monthlyRevenues[ 11 - new Date().getMonth() ] - monthDebts;
        



                yearSales = ( dbResp.data.yearSales ) ? dbResp.data.yearSales : 0;
                yearDebts = ( dbResp.data.yearDebts ) ? dbResp.data.yearDebts + monthTaxes.reduce( ( sum, value ) => sum + value ): 0;




                yearResult = yearSales - yearDebts;
                yearGoals = ( dbResp.data.yearPrevision === 0 ) ? 0 : parseInt( ( 100 * yearSales ) / ( dbResp.data.yearPrevision ) );
                
                
                console.log('Meta')
                console.log(yearSales)

                console.log('Result ');console.log(dbResp.data.yearPrevision)
                monthGoals = ( dbResp.data.monthPrevision === 0 ) ? 0 : parseInt( ( 100 * monthRevenues ) / dbResp.data.monthPrevision );
                remainingMonthGoals = dbResp.data.monthPrevision - monthRevenues;
                monthTicket = '---';
                monthCAC = '---';

                if( totalMonthQuantity !== 0 )
                {
                    monthTicket = monthRevenues / totalMonthQuantity;
                    monthCAC = monthDebts / totalMonthQuantity;
                }
               
                if( yearGoals >= 100 ) yearGoals = 100;
                if( yearGoals <= 0 ) yearGoals = 0;
                if( monthGoals >= 100 ) monthGoals = 100;
                if( monthGoals <= 0 ) monthGoals = 0;






                if( remainingMonthGoals <= 0 ) remainingMonthGoals = 0;
            
                setMonthGoals( monthGoals );
                setYearGoals( yearGoals );
                
                setGlobalYearSales(StringMask( yearSales.toFixed( 2 ), { type:'2DigitFraction' }, '' ))
                
                setGlobalMonthTicket(StringMask( monthTicket==='---' ? monthTicket : monthTicket.toFixed( 2 ), { type:'2DigitFraction' }, '' ))
                setGlobalRemainingMonthGoal(StringMask( remainingMonthGoals.toFixed( 2 ), { type:'2DigitFraction' }, ( remainingMonthGoals < 0 ) ? '-' : '' ) )


            }

            setLoadingDashboard( false );
        } );
    }











       const fillDashboardData = async() =>{

         await axios.get( 'https://qi7rw65z4btastjtutpqwwj46e0ywwmz.lambda-url.us-east-2.on.aws/', { params: { year: 2024, company: userId, token: token } } ).then( ( cfResp ) =>
        {




            if (cfResp.data) {console.log('cfResp'); console.log(cfResp.data)
    
            



                

                let doneSales = new Array(12)
                doneSales.fill(0, 0)
                let installmentsSales = new Array(12)
                installmentsSales.fill(0, 0)


                cfResp.data.pDone.forEach((sale)=>{
                    

                    if (sale.installments>1) {
            

                        for(let i = 0; i<Number(sale.installments); i++){
                           doneSales[sale.month - 1 + i] += sale.done/sale.installments



                        }
                    } else if(sale.recurrenceMonths>1){
                         

                        for(let i = 0; i<Number(sale.recurrenceMonths); i++){
                            doneSales[sale.month - 1 + i] += sale.done
 
 
 
                         }                      
                    }else{doneSales[sale.month-1] += sale.done}//sale.installments 
                })

                setGlobalRevenues(doneSales[selectedMonth - 1])
                console.log('after forEach');console.log(cfResp)  
               




            doneExpense = 0;
            let expensesArray = [];

            //console.log(cfResp.data)
            const expenses = cfResp.data.expenses;

           let allExpenses;

            expenses.forEach(expense => {
                
             allExpenses = [ expense.jan, expense.feb, expense.mar, expense.apr, expense.may, expense.jun, expense.jul, expense.aug, expense.sep, expense.oct, expense.nov, expense.dece ];
    

                let count= 0;

                allExpenses.forEach(singleExpense=>{

                    if (count<actualMonth) {
                        doneExpense += singleExpense;
                    } 
                    count++;
                })

        
            })
            setGlobalExpenses(doneExpense)


                
           //CPC todos os custos // numero de vendas

                                                
            let allResults = [];
            let initialResult = 0;

            //const expe = cfResp.data.expenses
            //let allExpenses = [expe.jan, expe.feb, expe.mar, expe.apr, expe.may, expe.jun, expe.jul, expe.aug, expe.sep, expe.oct, expe.nov, expe.dece]
            //console.log(dbResp.data)

                 let allMonthResults = 0;

                 let jan=0;
                 let feb=0;
                 let mar=0;
                 let apr=0;
                 let may=0;
                 let jun=0;
                 let jul=0;
                 let aug=0;
                 let sep=0;
                 let oct=0;
                 let nov=0;
                 let dece=0;



                    console.log(expenses)


                 let arrayOfAllExpenses;            
                 expenses.forEach(expense => {
                    jan += expense.jan
                    feb += expense.feb
                    mar += expense.mar
                    apr += expense.apr
                    may += expense.may
                    jun += expense.jun
                    jul += expense.jul
                    aug += expense.aug
                    sep += expense.sep
                    oct += expense.oct
                    nov += expense.nov
                    dece += expense.dece          

                    arrayOfAllExpenses = [ jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dece ];
                    console.log(expense.jan)
                
       
               
                   })

                    //console.log(arrayOfAllExpenses[4])
           

            
                                console.log('Done Sales');console.log(revenuesCostsChart);console.log(doneSales);console.log(arrayOfAllExpenses)

                if (revenuesCostsChart.current) {
                /**/revenuesCostsChart.current.data.datasets[ 0 ].data = [ doneSales[selectedMonth - 1], arrayOfAllExpenses[selectedMonth-1] ];
                revenuesCostsChart.current.data.labels = [ 'Receitas', 'Custos' ];
                revenuesCostsChart.current.update();                                    
                }

                 //console.log(costCoverageChart.current.data.datasets)   
    
            // costCoverageChart.current.data.datasets[ 0 ].data = [ ( globalRevenues > arrayOfAllExpenses[selectedMonth - 1] ) ? arrayOfAllExpenses[selectedMonth - 1] : globalRevenues, ( globalRevenues > arrayOfAllExpenses[selectedMonth - 1] ) ? 0 : ( arrayOfAllExpenses[selectedMonth -1] - globalRevenues ) ];   
                if (costCoverageChart.current) {
                costCoverageChart.current.data.datasets[ 0 ].data = [ ( doneSales[selectedMonth - 1] > arrayOfAllExpenses[selectedMonth - 1] ) ? arrayOfAllExpenses[selectedMonth - 1] : doneSales[selectedMonth - 1], ( doneSales[selectedMonth - 1] > arrayOfAllExpenses[selectedMonth - 1] ) ? 0 : ( arrayOfAllExpenses[selectedMonth -1] -doneSales[selectedMonth - 1] ) ];   
            
                costCoverageChart.current.data.labels = [ [ 'Custo', 'Coberto' ], [ 'Custo', 'Descoberto' ] ];
                costCoverageChart.current.update();                    
                }


             for (let index = 0; index < cfResp.data.monthlyRevenues.length/2; index++) {
                //allMonthResults += cfResp.data.monthlyRevenues[ 11 - index] - arrayOfAllExpenses[index] - cfResp.data.taxes[index] + initialResult;
                 
                allMonthResults = doneSales[index] - arrayOfAllExpenses[index] - cfResp.data.taxes[index] + initialResult;
                allResults.push(allMonthResults)
                console.log('doneSales');console.log(doneSales[6])
                console.log('-Expenses');console.log(arrayOfAllExpenses[6])

                console.log('-taxes');console.log(cfResp.data.taxes[6])
                console.log('RESLT');console.log(allMonthResults)

            // console.log(doneSales[index])
             }

             //const yearResult = allResults[]
             const monthResult = allResults[selectedMonth - 1];
             //const yearResult = allResults[actualMonth - 1] + allResults[actualMonth - 2] REVISAR
             console.log('year');console.log(doneSales);
              let initial = 0
              //const yearResult = allResults[actualMonth - 1]

               
               allResults.forEach((monthResult, monthIndex)=>{
                   if (monthIndex < actualMonth) {
                       initial += monthResult
                   }
               })

               const yearResult = initial    
             setGlobalMonthSales(StringMask( monthResult.toFixed( 2 ), { type:'2DigitFraction' }, ( monthResult < 0 ) ? '-' : '' ))

             setGlobalYearResult(StringMask( yearResult.toFixed( 2 ), { type:'2DigitFraction' }, ( yearResult < 0 ) ? '-' : '' ))





                console.log(monthResult)

            
                const remainingMonthGoals = Math.abs(allExpenses[12-selectedMonth]) + Math.abs(allResults[selectedMonth - 2]) + Math.abs(cfResp.data.taxes[selectedMonth-1])- cfResp.data.monthlyRevenues[12 - selectedMonth]
                setCheckedRemainingMonthGoals((remainingMonthGoals<0) ? 0 : remainingMonthGoals) 
                //const remainingMonthGoals = 100
                setYearExpense(doneExpense)

                setGlobalYearExpenses(StringMask( doneExpense.toFixed( 2 ), { type:'2DigitFraction' }, '' ))
            
                //document.getElementById( 'RemainingMonthGoal' ).innerHTML = ( checkedRemainingMonthGoals === '---') ? '---' : StringMask( checkedRemainingMonthGoals.toFixed( 2 ), { type:'2DigitFraction' }, ( remainingMonthGoals < 0 ) ? '' : '' )
                //setAllGlobalRevenues()
                let localItems = []
                const monthSales = []
                let salesList = new Array( 12 ).fill( 0 );
                let cfpItems = [ { year: 2023, products: [] } ];



                cfResp.data.pTarget.forEach(cfp=>{
                    let curProduct = cfp;
                    let product = curProduct.product;
                    let name = curProduct.name;
                    let target = [ curProduct.jan, curProduct.feb, curProduct.mar, curProduct.apr, curProduct.may, curProduct.jun, curProduct.jul, curProduct.aug, curProduct.sep, curProduct.oct, curProduct.nov, curProduct.dece ];
                    let done = Array( 12 ).fill( 0 );  
                    let quantity = Array( 12 ).fill( 0 );

                    cfpItems[ 0 ].products.push( { product:product, name:name, quantity: quantity, done:done, target:target } );



                })



                cfResp.data.pDone.forEach((cfp, index)=>{console.log('CFP');console.log(cfp)
                    
                        const pIndex = cfpItems[ 0 ].products.findIndex( ( element ) => { return element.product == cfp.product } );
                        const paymentFraction = cfp.done / Math.max( cfResp.data.pDone[ index ].installments, 1 );

                        for( let monthLoop = 0; monthLoop < cfp.recurrenceMonths; monthLoop++ )
                        {
                            salesList[ cfp.month + monthLoop - 1 ] += cfp.done;

                            //cfpItems[ 0 ].products[ pIndex ].quantity[ cfp.month + monthLoop - 1 ] += cfp.quantity;
                        }

                        if( cfp.recurrenceMonths > 1 )
                        {
                            for( let monthLoop = 0; monthLoop < cfp.recurrenceMonths; monthLoop++ )
                            {
                                cfpItems[ 0 ].products[ pIndex ].done[ cfp.month + monthLoop - 1 ] += cfp.done;
                                cfpItems[ 0 ].products[ pIndex ].quantity[ cfp.month + monthLoop - 1 ] += cfp.quantity;

                                //console.log('recurrence > 1');console.log(cfpItems[ 0 ].products)
                            }
                        }
                        else
                        {
                            for( let monthInstallment = 0; monthInstallment < Math.max( cfp.installments, 1 ); monthInstallment++ )
                            {    console.log('cfp.installments');console.log(cfp.installments)
                                console.log('cfp.quantity');console.log(cfp.quantity)
                                console.log('Quantityyyyy');console.log(cfp)
                                cfpItems[ 0 ].products[ pIndex ].done[ cfp.month + monthInstallment - 1 ] += paymentFraction;
                                /*cfpItems[ 0 ].products[ pIndex ].quantity[ cfp.month + monthInstallment - 1 ] += cfp.quantity//cfp.installments;*/
                            }

                            cfpItems[ 0 ].products[ pIndex ].quantity[ cfp.month - 1 ] += cfp.quantity
                        }
                
                })
            console.log('cfpItems'); console.log(cfpItems[0].products)

            let newTable = []
            cfpItems[0].products.forEach((item, index)=>{

              const newLine = constructTable(item.name, index, item.quantity[selectedMonth-1], item.done[selectedMonth-1], item.target[selectedMonth-1])

              //dbResp.data.monthList[ index ].name, index, monthQuantity, monthDone, monthTarget
              newTable.push(newLine)
                    
            })

              setTableMonthSales([...newTable])

            }

        
        
        
            })

}


    axios.get('https://xjyxy775wlf7zrrublooif5joe0qxfwk.lambda-url.us-east-2.on.aws/', 
    {params: {company:userId, token:token}})
    .then((prod)=>{
        if(prod.data){

            let allSoldQuantity = 0;
            prod.data.products.forEach(productSold=> {
                if (productSold.sellMonthDate <= actualMonth) {
                 allSoldQuantity += Number(productSold.quantity)                   
                }



            })
            
            //
            
            let monthCAC = globalExpenses/allSoldQuantity;
        
        
            setGlobalMonthCAC(( monthCAC === '---' ) ? '---' : StringMask( monthCAC.toFixed( 2 ), { type:'2DigitFraction' }, '' ))
        
    



        }
        
        

    }
        
        
        
        )
    const showDiv = ( id ) =>
    {
        let div1 = document.getElementById( id );
        div1.style.visibility = 'visible';
    }

    const hideDiv = ( id ) =>
    {
        let div1 = document.getElementById( id );
        div1.style.visibility = 'hidden';
    }
    const screenWidth = window.screen.width
    useEffect( () => {fillDashboard(selectedMonth); fillDashboardData(selectedMonth); changeScrollBar(); console.log('cost');console.log(screenWidth); /**/ setTimeout( () => { setScreenDebounceTime( false ); }, 1000 ); }, [selectedMonth] );
    
    

    return(

    
        <div style={{position:'relative'}}>

            {screenWidth > 950 ? <>
            <div id="hideDiv1" className="dashboardInfoBoxHidden" style={{ top:'1.75em', left:'10.5em', width: '16rem', visibility:'hidden'}}>
                <text className="dashboardInfoBoxText">A sua meta de vendas é calculada com base nas quantidades de venda para atingir o ponto de equilíbrio. </text>
            </div>
            <div id="hideDiv2" className="dashboardInfoBoxHidden" style={{top:'1.75rem', left:'31rem', width: '20rem', visibility:'hidden'}}>
                <text className="dashboardInfoBoxText">É a quantidade faturada no mês em comparação com o número de clientes, o número ideal depende do segmento, público e meta ideal do seu negócio.</text>
            </div>
            <div id="hideDiv3" className="dashboardInfoBoxHidden" style={{top:'1.75rem', left:'44.5rem', width: '16rem', visibility:'hidden'}}>
                <text className="dashboardInfoBoxText">É o custo por cliente. Ou seja, é o valor que sua empresa gasta para adquirir e atender os clientes</text>
            </div>
            <div id="hideDiv4" className="dashboardInfoBoxHidden" style={{top:'41rem', left:'15.5rem', width: '16rem', visibility:'hidden'}}>
                <text className="dashboardInfoBoxText">É o valor líquido descontados os custos e impostos.</text>
            </div>
            <div id="hideDiv5" className="dashboardInfoBoxHidden" style={{top:'37rem', left:'62rem', width: '20rem', visibility:'hidden'}}>
                <text className="dashboardInfoBoxText">Diz o quanto falta de custos/despesas para atingir o equilíbrio do mês. Outra forma de interpretar é o quanto a receita do período está cobrindo os custos. </text>
            </div>
            <div id="hideDiv6" className="dashboardInfoBoxHidden" style={{top:'39.5rem', left:'40rem', width: '16rem', visibility:'hidden'}}>
                <text className="dashboardInfoBoxText">O quanto sua receita cresce em comparação aos seus custos?</text>
            </div>
            <div id="hideDiv7" className="dashboardInfoBoxHidden" style={{top:'38rem', left:'41.5rem', width: '20rem', visibility:'hidden'}}>
                <text className="dashboardInfoBoxText">Quais produtos trazem mais receita para o negócio? Um bom indicador para analisar quais produtos devem ser o foco do negócio no momento.</text>
            </div>
            <div id="hideDiv8" className="dashboardInfoBoxHidden" style={{top:'0rem', left:'66rem', width: '20rem', visibility:'hidden'}}>
                <text className="dashboardInfoBoxText">Acompanhe quanto falta para alcançar sua meta de vendas anual.</text>
            </div>
            <div id="hideDiv9" className="dashboardInfoBoxHidden" style={{top:'23.7rem', left:'70.5rem', width: '15rem', visibility:'hidden'}}>
                <text className="dashboardInfoBoxText">Acompanhe o total de custos da sua empresa de forma acumulada.</text>
            </div>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td rowSpan={2}>
                                <div className="DashWidgetDiv1">
                                    <div style={{display: "flex", flexDirection:'row', gap:'0.5rem'}}>
                                        <div className="DashComponentsTitlesBlack">Meta do mês</div>
                                        <div className='dashboardInfoTag' onMouseOver={ () => showDiv( 'hideDiv1' ) } onMouseOut={ () => hideDiv( 'hideDiv1' ) }>
                                            <text className='dashboardInfoText' style={{left: '0.28rem'}}>!</text>
                                        </div>
                                    </div>   
                                    <CircularProgressbar
                                        value={ 
                                            //checkedRemainingMonthGoals
                                            monthGoals===0 ? 0.5 : monthGoals
                                        
                                        }
                                        text={ checkValue(
                                            //checkedRemainingMonthGoals
                                            monthGoals===0 ? 1 : monthGoals
                                            
                                            ) + '%' }


                                        strokeWidth={15}
                                        styles={{
                                            path: { stroke: `${colors.lightPurple}`, transition: 'stroke-dashoffset 0.5s ease 0s' },
                                            text: { fill: '#000000', fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '16px' }
                                        }}
                                    />
                                    <div className="DashComponentsDetailBlack">
                                        {/* <IoTriangle color="green" /> 20% em relação ao mês anterior */}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="DashWidgetDiv2">
                                    <div style={{display: "flex", flexDirection:'row', gap:'0.5rem'}}>
                                        <div className="DashComponentsTitlesBlack">Ticket Médio (R$)</div>
                                        <div className='dashboardInfoTag' onMouseOver={ () => showDiv( 'hideDiv2' ) } onMouseOut={ () => hideDiv( 'hideDiv2' ) }>
                                            <text className='dashboardInfoText' style={{left: '0.3rem'}}>!</text>
                                        </div>
                                    </div>
                                    <div id="MonthTicket" className="DashComponentsValueBigBlack">{globalMonthTicket}</div>
                                    <div className="DashComponentsDetailBlack">
                                        {/* <IoTriangle color="red" style = {{transform: 'rotate(180deg)' }} /> 4% em relação ao mês anterior */}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="DashWidgetDiv2">
                                    <div style={{display: "flex", flexDirection:'row', gap:'0.5rem'}}>
                                        <div className="DashComponentsTitlesBlack">CPC (R$)</div>
                                        <div className='dashboardInfoTag' onMouseOver={ () => showDiv( 'hideDiv3' ) } onMouseOut={ () => hideDiv( 'hideDiv3' ) }>
                                            <text className='dashboardInfoText' style={{left: '0.3rem'}}>!</text>
                                        </div>
                                    </div>
                                    <div id="MonthCAC" className="DashComponentsValueBigBlack">{globalMonthCAC}</div>
                                    <div className="DashComponentsDetailBlack">
                                        {/* <IoTriangle color="green" /> 21% em relação ao mês anterior */}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="DashWidgetDiv2 DashWidgetDivBGBlue">
                                    <div style={{display: "flex", flexDirection:'row', gap:'0.5rem' }}>
                                        <div className="DashComponentsTitlesWhite" style={{width:'12rem'}}>Receita Total do Ano (R$)</div>
                                    </div>
                                    <div id="YearSales" className="DashComponentsValueBigWhite">{globalYearSales}</div>
                                    <div className="DashComponentsDetailWhite">
                                        {/* <IoTriangle color="green" /> 20% em relação ao ano anterior */}
                                    </div>
                                </div>
                            </td>
                            <td rowSpan={2}>
                                <div className="DashWidgetDiv8 DashWidgetDivBGBlue">
                                    <div style={{display: "flex", flexDirection:'row', gap:'0.5rem'}}>
                                        <div className="DashComponentsTitlesWhite">Meta Alcançada do Ano</div>
                                        <div className='dashboardInfoTagWhite'onMouseOver={ () => showDiv( 'hideDiv8' ) } onMouseOut={ () => hideDiv( 'hideDiv8' ) }>
                                            <text className='dashboardInfoTextWhite' style={{left: '0.3rem'}}>!</text>
                                        </div>
                                    </div>
                                    <CircularProgressbar id="YearGoalsProgress"
                                        value={ yearGoals===0 ? 0.5 : yearGoals }
                                        text={ yearGoals===0 ? 1 + '%' : yearGoals + '%' }
                                        strokeWidth={15}
                                        styles={{
                                            path: { stroke: `${colors.lightPurple}`,transition: 'stroke-dashoffset 0.5s ease 0s' },
                                            text: { fill: '#FFFFFF', fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '16px' }
                                        }}
                                    />
                                    <div className="DashComponentsDetailWhite">
                                        {/* <IoTriangle color="green" /> 20% em relação ao mês anterior */}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={ 3 } rowSpan={ 2 }>
                                <div className="DashWidgetDiv5" style={{justifyContent:'start',gap:'1rem',overflowY:'scroll'}}>
                                        
                                    {  
                                    <MonthSelect monthFunction={(e)=>{
                                        setSelectedMonth(convertMonthToValue(e.target.value))
                                    }} actualMonth={actualMonth}/>

                                    }                                    
                                    
                                    
                                    
                                    <table className="DashProductTable">
                                        

                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th style={ { paddingLeft: '5px', paddingRight: '5px' } }>Produto</th>
                                                <th style={ { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', width:'95px' } }>Quantidade</th>
                                                <th style={ { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', width:'120px' } }>Evolução (R$)</th>
                                                <th style={ { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', width:'95px' } }>Progresso</th>
                                            </tr>
                                        </thead>
                                       

                                        <tbody style={{ overflowY:'scroll'}}>



                                            { ( screenDebounceTime || loadingDashboard ) ? loadingMonthSalesWarn : ( tableMonthSales.length > 0 ) ? tableMonthSales : emptyMonthSalesWarn }
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="DashWidgetDiv2">
                                    <div style={{display: "flex", flexDirection:'row', gap:'0.5rem'}}>
                                        <div className="DashComponentsTitlesBlack">Faltam (R$)</div>
                                    </div>
                                    <div id="RemainingMonthGoal" className="DashComponentsValueBigBlack">{globalRemainingMonthGoal}</div>
                                    <div className="DashComponentsDetailBlack">
                                        para o atingimento da meta do mês
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="DashWidgetDiv4 DashWidgetDivBGBlue">
                                    <div style={{display: "flex", flexDirection:'row', gap:'0.5rem'}}>
                                        <div className="DashComponentsTitlesWhite" style={{width:'12rem'}}>Custos Totais do Ano (R$)</div>
                                        <div className='dashboardInfoTagWhite' onMouseOver={ () => showDiv( 'hideDiv9' ) } onMouseOut={ () => hideDiv( 'hideDiv9' ) }>
                                            <text className='dashboardInfoTextWhite' style={{left: '0.3rem'}}>!</text>
                                        </div>
                                    </div>
                                    <div id="YearExpenses" className="DashComponentsValueBigWhite">{globalYearExpenses}</div>
                                    <div className="DashComponentsDetailWhite">
                                        {/* <IoTriangle color="red" style = {{transform: 'rotate(180deg)' }}/> 5% em relação ao ano anterior */}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div >
                <div className="DashWidgetDiv7 DashWidgetDivLightBlue">
                    <div style={{display: "flex", flexDirection:'row', gap:'0.5rem'}}>
                        <div className="DashComponentsTitlesWhite">Resultado do mês (R$)</div>
                        <div className='dashboardInfoTagWhite' onMouseOver={ () => showDiv( 'hideDiv4' ) } onMouseOut={ () => hideDiv( 'hideDiv4' ) }>
                            <text className='dashboardInfoTextWhite' style={{left: '0.3rem'}}>!</text>
                        </div>
                    </div>
                    <div id="MonthSales" className="DashComponentsValueBigWhite">{globalMonthSales}</div>
                    <div className="DashComponentsDetailWhite">
                        {/* <IoTriangle color="green" /> 20% em relação ao ano anterior */}
                    </div>
                </div>
                <div className="DashWidgetDiv3">
                    <div style={{display: "flex", flexDirection:'row', gap:'0.9rem'}}>
                        <div className="DashComponentsTitlesBlack">Evolução das receitas / custos mês</div>
                        <div className='dashboardInfoTag'onMouseOver={ () => showDiv( 'hideDiv6' ) } onMouseOut={ () => hideDiv( 'hideDiv6' ) }>
                            <text className='dashboardInfoText' style={{left: '0.3rem'}}>!</text>
                        </div>
                    </div>
                    <Bar ref={ revenuesCostsChart } options={ options } data={ revenuesCostsData }/>
                </div>
                <div className="DashWidgetDiv3" >
                    <div style={{display: "flex", flexDirection:'row', gap:'0.5rem'}}>
                        <div className="DashComponentsTitlesBlack">Cobertura de custos mês</div>
                        <div className='dashboardInfoTag' onMouseOver={ () => showDiv(  'hideDiv5'  ) } onMouseOut={ () => hideDiv( 'hideDiv5' ) }>
                            <text className='dashboardInfoText' style={{left: '0.25rem'}}>!</text>
                        </div>
                    </div>
                    <Bar ref={ costCoverageChart } options={ options } data={ costCoverageData } style={ { width: '100%' }}/>
                </div>
                <div className="DashWidgetDiv6 DashWidgetDivBGBlue" style={ { marginRight: '0px' } }>
                    <div style={{display: "flex", flexDirection:'row', gap:'0.5rem'}}>
                        <div className="DashComponentsTitlesWhite" style={{width:'12rem'}}>Resultado do Ano (R$)</div>
                    </div>
                    <div id="YearResult" className="DashComponentsValueBigWhite">{globalYearResult}</div>
                    <div className="DashComponentsDetailWhite">
                        {/* <IoTriangle color="green" /> 15% em relação ao ano anterior */}
                    </div>
                </div>
            </div>
        </> 
        : 

       
        <DashboardNew 

            //PERFORMANCE
            monthGoals={monthGoals} 
            colors={colors} 
            checkValue={checkValue}
            globalRemainingMonthGoal={globalRemainingMonthGoal}
            globalMonthSales={globalMonthSales}
            globalMonthTicket={globalMonthTicket}
            globalMonthCAC={globalMonthCAC}
            globalYearSales={globalYearSales}
            screenDebounceTime={screenDebounceTime}
            emptyMonthSalesWarn={emptyMonthSalesWarn}
            loadingDashboard={loadingDashboard}
            loadingMonthSalesWarn={loadingMonthSalesWarn}
            tableMonthSales={tableMonthSales}
            yearGoals={yearGoals}
            



            globalYearExpenses={globalYearExpenses}
            globalYearResult={globalYearResult}
            //FINANCIAL

            fillDashboardData={fillDashboardData}
            options={options}
            hideDiv={hideDiv}
            showDiv={showDiv}
            revenuesCostsChart={revenuesCostsChart}
            revenuesCostsData={revenuesCostsData}
            costCoverageChart={costCoverageChart}
            costCoverageData={costCoverageData}
            />
        
        
        
        }</div>
    );
}

export default Dashboard;