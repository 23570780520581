import React, { useEffect } from 'react'
import styles from './DefaultSandwichMenu.module.sass'
import { useState } from 'react'
import Options from '../options/Options'
import UserStore from '../../../store/userStore'

const DefaultSandwichMenu = ({sideBarOptions, selectedSideMenu, setNewSelectedPage, setNewSelectedSideMenu}) => {   
    //setNewSelectedPage('modelrec');setNewSelectedSideMenu('modelrec'); UserStore.setSelectedMenuStore('modelrec'); UserStore.setSelectedPageStore('modelrec')
    //const selectedSideMenussss = sideBarOptions.find(option=>option.route===UserStore.getSelectedMenuStore())
    
    //console.log(selectedSideMenussss)
    const getSelectedMenu = ()  => {

        let menu = ''
        if (UserStore.getSelectedMenuStore()===''   || UserStore.getSelectedMenuStore()===null ) {
            menu = 'cashflow'
        } else {
            menu = UserStore.getSelectedMenuStore()

            console.log(menu)
        }
        return sideBarOptions.find(option=>option.route===menu)



    }
  const selectedMenu = getSelectedMenu()
    
  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const [isFirstAppear, setIsFirstAppear] = useState(true)

    
  const [planDays, setPlanDays] = useState(31)
  const upgradePlanLink = ''

  const MobileUpgradePlanBanner = () =>
  {
      return(
          <div className={styles.mobilePlanData}>
              <div className={styles.mobilePlanDataContent}>
                  {(planDays<=1 && planDays>0) ? <p>Seu Plano expira em {planDays} dia</p> : (planDays>0)? <p>Seu plano expira em {planDays} dias</p> : <p>Seu plano expirou</p>}
              </div>
              <div className={styles.mobilePlanDataContent}> 
                  <a target="blank" href={upgradePlanLink} className={styles.mobilePlanDataContentButton}>Fazer Upgrade</a>
              </div>
          </div>   
      )     
  }    
  const discoverPage = () =>{

    console.log(window.location.pathname)
switch (window.location.pathname) {

  case '': 
    UserStore.setSelectedMenuStore('modelrec');
    UserStore.setSelectedPageStore('modelrec');
    return 'modelrec';
  case '/newmodelrec':
    UserStore.setSelectedMenuStore('modelrec');
    UserStore.setSelectedPageStore('modelrec');
    return 'modelrec';
  case '/modelrec':
    UserStore.setSelectedMenuStore('modelrec');
    UserStore.setSelectedPageStore('modelrec');
    return 'modelrec';
  case '/dashboard':
    UserStore.setSelectedMenuStore('dashboard');
    UserStore.setSelectedPageStore('dashboard');
    return 'dashboard';
  case '/familiarcashflow':
    UserStore.setSelectedMenuStore('cashflow');
    UserStore.setSelectedPageStore('familiarcashflow');
    return 'familiarcashflow';
  case '/cashflow':
    UserStore.setSelectedMenuStore('cashflow');
    UserStore.setSelectedPageStore('cashFlow');
    return 'cashFlow';
  case '/revenues':
    UserStore.setSelectedMenuStore('cashflow');
    UserStore.setSelectedPageStore('revenues');
    return 'revenues';
  case '/expenses':
    UserStore.setSelectedMenuStore('cashflow');
    UserStore.setSelectedPageStore('expenses');
    return 'expenses';
  case '/taxes':
    UserStore.setSelectedMenuStore('cashflow');
    UserStore.setSelectedPageStore('taxes');
    return 'taxes';
  case '/growth':
    UserStore.setSelectedMenuStore('cashflow');
    UserStore.setSelectedPageStore('growth');
    return 'growth';
  case '/traction':
    UserStore.setSelectedMenuStore('cashflow');
    UserStore.setSelectedPageStore('traction');
    return 'traction';
  case '/actionplan':
    UserStore.setSelectedMenuStore('actionplan');
    UserStore.setSelectedPageStore('actionplan');
    return 'actionplan';
  case '/journey':
    UserStore.setSelectedMenuStore('journey');
    UserStore.setSelectedPageStore('journey');
    return 'journey';
  case '/journey/forwho':
    UserStore.setSelectedMenuStore('journey');
    UserStore.setSelectedPageStore('journey/forwho' );
    return 'journey/forwho';
  case '/journey/forwhat':
    UserStore.setSelectedMenuStore('journey');
    UserStore.setSelectedPageStore('journey/forwhat');
    return 'journey/forwhat';
  case '/journey/forchannel':
    UserStore.setSelectedMenuStore('journey');
    UserStore.setSelectedPageStore('journey/forchannel');
    return 'journey/forchannel';
  case '/journey/forhow':
    UserStore.setSelectedMenuStore('journey');
    UserStore.setSelectedPageStore('journey/forhow');
    return 'journey/forhow';
  case '/newjourney':
    UserStore.setSelectedMenuStore('journey');
    UserStore.setSelectedPageStore('newjourney');
    return 'newjourney';
  case '/modelsettings':
    UserStore.setSelectedMenuStore('modelsettings');
    UserStore.setSelectedPageStore('modelsettings');
    return 'modelsettings';
  case '/journey/myanswer':
    UserStore.setSelectedMenuStore('journey');
    UserStore.setSelectedPageStore('journey/myanswer');
    return 'journey/myanswer';
  case '/videoSection':
    UserStore.setSelectedMenuStore('videoSection');
    UserStore.setSelectedPageStore('videoSection');
    return 'videoSection';
  case '/videoTrail':
    UserStore.setSelectedMenuStore('videoSection');
    UserStore.setSelectedPageStore('TrailContainer' );
    return 'TrailContainer';
  case '/videoTrail/introduction':
    UserStore.setSelectedMenuStore('videoSection');
    UserStore.setSelectedPageStore('videoSection' );
    return 'videoSection';
  case '/videoTrail/businessTips':
    UserStore.setSelectedMenuStore('videoSection');
    UserStore.setSelectedPageStore('videoSection' );
    return 'videoSection';
  case '/community':
    UserStore.setSelectedMenuStore('community');
    UserStore.setSelectedPageStore('community' );
    return 'community';
  case '/getpremium':
    UserStore.setSelectedMenuStore('journey');
    UserStore.setSelectedPageStore('getpremium' );
    return 'getpremium';
  case '/clients':
    UserStore.setSelectedMenuStore('actionplan');
    UserStore.setSelectedPageStore('clients' );
    return 'clients';
  case '/monthcashflow':
    UserStore.setSelectedMenuStore('cashflow');
    UserStore.setSelectedPageStore('monthcashflow' );
    return 'monthcashflow';



  case 'settings':

    return;
  default:
    //TODO: Pagina inicial por padrão

    UserStore.setSelectedMenuStore('cashflow');
    UserStore.setSelectedPageStore('monthcashflow' );

    return 'monthcashflow';
    //UserStore.setSelectedMenuStore('modelrec');
    //UserStore.setSelectedPageStore(<Model/>);

}

}



  const changeMenuState = () =>{
        setIsMenuOpened(prev=> !prev)
        setIsFirstAppear(false)
  }
    //console.log()
  const selectedSubMenuPath = discoverPage()//UserStore.getSelectedPageStore()//window.location.pathname.substring(1)

  const splittedPath = selectedSubMenuPath.split('/')
  
  let pathDisplay = ''
  if (splittedPath[0]==='videoTrail') {
        pathDisplay = 'none'
  }     

  else{




  }console.log(selectedMenu);console.log(selectedSubMenuPath)
  const findSelectedSubMenu = selectedMenu.subMenus && selectedMenu.subMenus.find(subMenu =>  subMenu.route === selectedSubMenuPath)
  const selectedSubMenu = findSelectedSubMenu || ''
    
  return (
    <div className={styles.defaultSandwichMenu} style={{display: pathDisplay}}>

    
        <div><p className={styles.title}>{ selectedMenu && selectedMenu.title }</p></div>


        <div onClick={changeMenuState}><i className={isMenuOpened ? "fa fa-times" : "fa fa-bars"}/></div>
        <div className={isFirstAppear ? styles.firstAppear : isMenuOpened ? styles.expandedMenu : styles.closedMenu}>
            {sideBarOptions && sideBarOptions.map(option=>(
            <>
            <Options setNewSelectedPage={setNewSelectedPage} setNewSelectedSideMenu={setNewSelectedSideMenu} closeMenu={changeMenuState} title={option.title} route={option.route} icon={option.icon}/>
            {(selectedMenu === option) && selectedMenu.subMenus && selectedMenu.subMenus.map(selected=>
            
                <Options  setNewSelectedPage={setNewSelectedPage} setNewSelectedSideMenu={setNewSelectedSideMenu}  closeMenu={changeMenuState} title={selected.title} icon="" isSelected={(selected.route ? selected.route : '') === (selectedSubMenu.route ? selectedSubMenu.route : '')} isSubMenu={true} route={selected.route}/>
            
            )}
                        
            </>
            ))}
            
            
            
            {/**/<Options title="Logout" icon="" isLogout={true}/>}
        </div>
    </div>
  )
}

export default DefaultSandwichMenu