import React, { useRef } from 'react'
import styles from './SelectModel.module.sass'
import Background from "./../../assets/new_background.png";
import UserStore from '../../store/userStore';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios';
import selectGestao from '../../assets/icons/selectGestao.svg'

import selectModeloDeNegocio from '../../assets/icons/selectModeloDeNegocio.svg'
import selectAcademy from '../../assets/icons/selectmodelAcademy.svg'
import selectComunidade from '../../assets/icons/selectmodelComunidade.svg'
import { MaterialsPDFBg } from '../imgsLoader/LoadBgImgs';
const SelectModel = () => {
    
            
    const screenWidth = window.screen.width
  const hideModel = async() =>{
    //UserStore.setUserFirstAccess(1)
    
    UserStore.setHideIntro(1)
    await axios.post('https://7n7xyqdnieagwashx2adisceqy0qxsxf.lambda-url.us-east-2.on.aws/', {userId: UserStore.getUserId(), hideIntro: 1})
    window.location.replace('/')   
    
  }
    const haveWatchedIntro = async({value}) =>{
        //setAccess(1) 
        await axios.put('https://o3lghqveqwzv5rxsldv2ivrukm0qilrb.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), haveWatchedIntro: value})
        .then(resp=>{console.log(resp); if (value===1) {

            UserStore.setUserFirstAccess(1)
             //window.location.replace('/')
        }})
        .catch(err=>console.log(err))
        

    }
    const [haveWatchedVideo, setHaveWatchedVideo] = useState(false)
    
    const [isVideoCardOpen, setIsVideoCardOpen] = useState(false)
    const company = UserStore.getUserId()
    const token = UserStore.getToken()
    const [selectedMaterial, setSelectedMaterial] = useState('')


    const introVideoUrl = 'https://arbbo-str-meconecte.s3.us-east-2.amazonaws.com/videos/GUIA+-+Introdu%C3%A7%C3%A3o.mp4'
    const [videoPath, setVideoPath] = useState('')        
    const [selectedVideoTitle, setSelectedVideoTitle] = useState('')    
    const [selectedVideoUrl, setSelectedVideoUrl] = useState('')
  const redirectTo = (path) =>{
                 window.location.replace(path)



  }


  const defineFirstPage = (firstPage) =>{
    switch (firstPage) {
        case 1:
            
            return '/journey';
        case 2:
            return '/cashflow';

        default:
            return;
    }



}
  const changeFirstPage = async(newFirstPage) =>{
    await axios.post('https://ywdjyuynyzoet3crpclde4dbeq0fbjus.lambda-url.us-east-2.on.aws/', {companyId: UserStore.getUserId(), firstPage: newFirstPage}).then(resp=>{redirectTo(defineFirstPage(newFirstPage))}).catch(err=>{console.log(err)})



  }



  const playerRef = useRef(null)

  const handleVideoClick = () => {

    alert()
    // Verifica se o player está inicializado
    if (playerRef && playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
      
      // Verifica se o vídeo já está tocando
      if (player && player.getPlayerState() !== 1) {
        player.playVideo(); // Inicia a reprodução do vídeo
      }
    }
  }  


  useEffect(()=>{
    haveWatchedIntro({value: 1})



  }, [])
  return (
    <div className={styles.selectModel}>

        <img src={Background} alt='' className={styles.background}/>

        <div className={styles.content}>

            {!haveWatchedVideo && !isVideoCardOpen &&
                <div className={styles.introVideo}>

                    <p>Conheça a Plataforma</p>
                    
                    <video src={introVideoUrl} controls='controls' controlsList='nodownload'/>


        
                    <button className={styles.introButtons} onClick={()=>{setHaveWatchedVideo(true)}}>Já Assisti</button>
                    {Number(UserStore.getHideIntro())!==1 && <button className={styles.introButtons} onClick={()=>{hideModel({value: 1})}}>Não Mostrar Novamente</button>    }  





                </div>}
            {haveWatchedVideo && !isVideoCardOpen && <div className={styles.cards}>
            <div className={styles.topCards}>
                <div className={styles.optionCard}>

                    <div className={styles.card}>
                        <h2 className={styles.title}>
                            Gestão
                        </h2>



                        <div className={styles.contentDescription}><img src={selectGestao} alt=""/><p className={styles.description}>
                            Potencialize seu negócio com nossa ferramenta de gestão. Simplifique processos, tome decisões estratégicas e alcance uma vantagem competitiva.
                        </p></div>

                        <button onClick={()=>{  setSelectedVideoUrl('https://arbbo-str-meconecte.s3.us-east-2.amazonaws.com/videos/GUIA+-+Gest%C3%A3o+de+vendas+e+financeiro.mp4');setIsVideoCardOpen(true); setVideoPath('actionplan'); setSelectedVideoTitle('Gestão'); setSelectedMaterial('Gestao');  /*changeFirstPage(2)*/}}>
                            Acessar 
                        </button>
                    </div>
                
                
                
                </div>



                <div className={styles.optionCard}>

                    <div className={styles.card}>
                        <h2 className={styles.title}>
                            Modelo de Negócio
                        </h2>



                        <div className={styles.contentDescription}><img src={selectModeloDeNegocio} alt=""/><p className={styles.description}>
                            Alavanque seu negócio com nossa ferramenta de modelagem. Transforme ideias em resultados tangíveis e impulsione seu sucesso.
                        </p></div>

                        <button onClick={()=>{  setSelectedVideoUrl('https://arbbo-str-meconecte.s3.us-east-2.amazonaws.com/videos/GUIA+-+Modelo+de+neg%C3%B3cio.mp4');setIsVideoCardOpen(true); setVideoPath('journey'); setSelectedVideoTitle('Modelo de Negócio'); setSelectedMaterial('Jornada');  /*changeFirstPage(2)*/}}>
                            Acessar 
                        </button>
                    </div>
                
                
                
                </div>
</div>














            <div className={styles.bottomCards }>

                <div className={styles.optionCard}>

                    <div className={styles.card}>

                        <h2 className={styles.title}>
                                MEconecte Academy
                        </h2>

                        
                    <div className={styles.contentDescription}><img src={selectAcademy} alt=""/><p className={styles.description}>
                         Eleve seu conhecimento com a MEconecte Academy. Aprenda com os especialistas, desenvolva habilidades essenciais e avance na sua carreira.
                    </p></div>

                    <button onClick={()=>{    setSelectedVideoUrl('https://arbbo-str-meconecte.s3.us-east-2.amazonaws.com/videos/GUIA+-+Me+Conecte+Academy.mp4');setIsVideoCardOpen(true);;setVideoPath('videoSection'); setSelectedVideoTitle('MEconecte Academy'); setSelectedMaterial('Academy'); /*redirectTo('/journey')*/}}>
                    Acessar 
                    </button>                    
                    </div>

                </div>
            
            

                <div className={styles.optionCard}>

                    <div className={styles.card}>
                        <h2 className={styles.title}>
                            Comunidade
                        </h2>



                    <div className={styles.contentDescription}><img src={selectComunidade} alt=""/><p className={styles.description}>
                        Junte-se à nossa comunidade de visionários de negócios. Colabore, compartilhe insights e cresça junto a líderes do setor. Sua rede de apoio para inovação e sucesso começa aqui.
                    </p></div>

                    <button onClick={()=>{  setSelectedVideoUrl('https://arbbo-str-meconecte.s3.us-east-2.amazonaws.com/videos/GUIA+-+Comunidade.mp4');setIsVideoCardOpen(true); setVideoPath('community'); setSelectedVideoTitle('Comunidade'); setSelectedMaterial('Comunidade'); /*redirectTo('/journey')*/}}>
                    Acessar 
                    </button>                    
                    </div>

                </div>
            
            
                            
            </div>
            </div>
            
            
            }



            {isVideoCardOpen &&                 
            
        
                <div className={styles.introVideo}>

                    <p>{selectedVideoTitle}</p>


                         <video src={selectedVideoUrl} controls='controls' controlsList='nodownload'/>



  
        

                    <div className={styles.materialAndButton}>
                    <div className={styles.materialCard}>    
                        <div className={styles.materialType}>{'PDF'}</div>
                        <div className={styles.topContent}><img src={MaterialsPDFBg} alt=""/></div>
                        <div className={styles.bottomContent}>
             
            

                        <div className={styles.bottomTitle}><h2>{selectedVideoTitle}</h2></div>
        
                        <a href={ `https://uei5rvjj53q35d7d5fmeq2i7ga0kmobx.lambda-url.us-east-2.on.aws/?book=Manual_${selectedMaterial}&token=` + token + '&company=' + company } target ={screenWidth<800 ? '_self' : '_blank'} style={ { textDecoration:'none' } }>
        
        <div className={styles.download}><button>Download</button>
    
        </div>
        </a>
        
                </div>    
    </div>
    
    


                    <div className={styles.stepsButtons}>
                        <button onClick={()=>{ UserStore.setHideIntro(2); window.location.replace(`/${videoPath}`)}}>Continuar</button>
                        <button className={styles.changeOption} onClick={()=>{setIsVideoCardOpen(false); setHaveWatchedVideo(true)}}>Ver outras opções</button>
                    </div>

                        
                    </div>
                    
              </div>}
        </div>


    </div>
  )
}

export default SelectModel 