import React from 'react'
import styles from './MaterialsVideoOption.module.css'
import MaterialCard from '../materialCard/MaterialCard'
const MaterialsVideoOption = ({materialsArray}) => {

  const materialsArrayss = [{
    "type": "PDF",
    "title": "TestPDF",
    "picture": "https://images.unsplash.com/photo-1687365414421-147891bed96a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80"

  }, {
    "type": "PDF",
    "title": "TestPDF",
    "picture": "https://images.unsplash.com/photo-1687365414421-147891bed96a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80"
    



  }]

  console.log('Array'); console.log(materialsArray)
  return (
    <div className={styles.materialsVideoOption}>
        <div><p>Materiais</p></div>
        <div className={styles.materialsCards}>
          {materialsArray && materialsArray.map(material=><MaterialCard type={material.type} title={material.title} material={material.material}/>)}

        </div>


    </div>
  )
}

export default MaterialsVideoOption