import React from 'react'
import styles from './ProductCard.module.sass'
import { EditIconImg, TrashIconImg } from '../../../imgsLoader/LoadIconImgs'
import { useState } from 'react'
const ProductCard = ({id, product, price, importance, editFn, removeFn}) => {



  const localMaskValue = (value) =>{
    if (value) {
        const splittedValue = value.toString().split('.') 
        if (!splittedValue[1]) {
            splittedValue[1] = '00'
        }
        const splittedDigits = splittedValue[1].length>1 ? splittedValue[1] : `${splittedValue[1]}0`
        const valueDigits = splittedDigits ? Number(splittedDigits) : 0  
        const isInteger = (valueDigits===0) ? true : false
        const convertedValue = isInteger ? value.toFixed(2) : parseFloat(value).toFixed(2)
        const maskedString = convertedValue.toString().replace( '.', '$' ).replace( ',', '' ).replace('$', ',');    
        
        return maskedString
    }}

  const convertImportance = (importance) =>{
    switch (importance) {  
      case 0: 
        
      return 'Muito Alta'   

      case 1: 
        
      return 'Alta'   

      case 2: 
        
      return 'Normal'   

      case 3: 
        
        return 'Baixa'   

      default:
        return ''
    }
  }
    const importanceColor = (importance) =>{

      switch (importance) {  
        case 0: 
          return '#E42A2A' 
  
        case 1: 
          return '#F47B20' 
  
        case 2: 
          return '#DFB51C' 
  
        case 3: 
          return '#08BF46' 
  
        default:
          return ''

    }
    



  }
  const convertedImportance = convertImportance(importance)
  const convertedPrice = localMaskValue(price)

  return (
    <div className={styles.productCard}>
        <div>
            <h2 className={styles.name}>{product}</h2>
        </div>
       
        <div>
            <p className={styles.price}>R${convertedPrice}</p>  
        </div>
        
        <div className={styles.importance}>
            <p>Importância</p>
            <span className={styles.importanceItem} style={{backgroundColor: importanceColor(importance)}}>{convertedImportance}</span> 
        </div>

        <div className={styles.options}>
            <div onClick={()=>editFn(id)} className={styles.left}>
               <img src={EditIconImg} alt='Editar'/>

            </div>
            <div onClick={()=>removeFn(id)} className={styles.right}>
                <img src={TrashIconImg} alt='Remover'/>

            </div>

        </div>

    </div>
  )
}

export default ProductCard