import React from 'react'
import styles from './AboutVideoOption.module.css'
import axios from 'axios'
import UserStore from '../../../../store/userStore'
import { useEffect } from 'react'




const AboutVideoOption = ({videoStep, videoTitle, videoShareLink, videoDescription, haveFavourited, videoCode, favouriteVideo}) => {



  return (
    <div className={styles.aboutVideoOption}>
    
        <div className={styles.topDescription}>
            <div className={styles.videoTitle}>{videoTitle && videoTitle}</div>
            <div className={styles.shareAndFavorite}>
                {/*<span onClick={()=>{alert(`Link: ${window.location.href}${videoStep}`)}}><i className="fa fa-share-alt"/>Compartilhar</span>  */}
                <span onClick={favouriteVideo}><i className="fa fa-heart" style={{color: haveFavourited ? 'red' : ''}}/>{haveFavourited ? 'Desfavoritar' : 'Favoritar'}</span>
            </div>
        </div>
        <div className={styles.bottomDescription}>
            <span>{videoDescription && videoDescription}</span>

        </div>





    </div>
  )
}

export default AboutVideoOption