import React from "react";
import { CProgress, CProgressBar } from '@coreui/react'
import "../journey/JourneyForWhomFurtherMaterials.css";
import menuJourneyPurple from "../../assets/icons/iconMenuJourneyPurple.png";
import iconDownload from "../../assets/icons/downloadIcon.png";
import Ebook1 from "../../assets/pdfs/Material Complementar - Canais de Relacionamento.pdf";
import Ebook2 from "../../assets/pdfs/Saiba Mais - Relacionamento.pdf";
import JourneysideMenu from "./JourneysideMenu";
import UserStore from '../../store/userStore';
import styles from './JourneyAll.module.css';
import axios from "axios";
import { useEffect } from "react";
import { botconversaWebhook } from "../../functions/botconversaWebhook";
import { botconversaDownloadMaterial } from "../../functions/botconversaDownloadMaterial";
const JourneyRelationshipChannelFurtherMaterials = () =>
{
    var company = UserStore.getUserId();
    var token = UserStore.getToken(); 

    const pageLocale = 'Jornada'
    useEffect(()=>{

        botconversaWebhook({locale: pageLocale, action: 'Abriu Materiais - Canais de Relacionamento'})




        const postStep = async(step) =>{
            await axios.put('https://iz5smq7lxhsxjtskiegdcibiru0vwhjh.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), token: UserStore.getToken(), journeyStep: step})
        }
        postStep(35) 
    }, [])    

    const OpenSideDiv = ()=>{
        let sideDiv = document.getElementById('journey1SideDiv');
        sideDiv.style.display='block';
    }

    return(
        <div className="journeyFWQBackgroundMaterials">
            <img src={menuJourneyPurple} alt-='' className="journeyFWQIconMenu" onClick={OpenSideDiv} >
            </img>
            <div style={{position:'relative',
                        top: '1.7rem',
                        left: '6.8rem',
                        width:'12rem'}}>
                <text className="journeyFWQHeader">Entendendo seu modelo de negócio</text>
            </div>
            <text className="journeyFWQprogressText">
                Seu progresso
            </text>      
            <div style={{position:'relative',
                        top:'-2.6rem',
                        left:'75.2rem',
                        paddingLeft:'0.4rem',
                        paddingRight:'0.4rem',
                        borderRadius:'0.3rem',
                        backgroundColor:'#251F53',
                        width:'fit-content'}}>
                <text className="journeyFWQprogressBarText">
                    71% completo
                </text>
            </div>
            <div style={{position:'relative',
                        top:'-2rem',
                        left:'67rem',
                        width:'20rem'}}>
                <CProgress height={ 6 }  >
                    <CProgressBar value={ 71 } />
                </CProgress>
            </div>
            <div className="journeyFWQProblemDiv" style={{
                                                            top:'32rem' 
                                                        
                                                            }}>
                <a href="/journey/relationshipchannel/questions" classname='journeyFWQlink' style={{display:'flex', 
                                                                            flexDirection:'row',
                                                                            textDecoration:'none' 
                                                                        }}>    
                    <div style={{display:'flex',
                                flexDirection:'column',
                                marginLeft:'1.5rem',
                                marginTop:'0.8rem',
                                marginBottom:'0.8rem'}}>
                        <text className="journeyFWQVideoUpperTxt">Próximo conteúdo</text>
                        <text className="journeyFWQVideoLowerTxt">Perguntas</text>
                    </div>
                        <div className="journeyFWQArrowDiv">
                            <text className="journeyFWQArrow">&#8250;</text>
                        </div>
                </a>
            </div>
            <text className='journeyFWQFurtherMaterialsTitle'>Material Complementar</text>
            <div style={{display:'flex',
                        flexDirection:'row',
                        gap:'1rem',
                        overflowX: 'auto'
                        }}>
                <div style={{position:'relative',
                        width:'15rem',
                        top:'0rem',
                        left:window.screen.width>780 ? '6.9rem' : '0',
                        display:'flex',
                        flexDirection:'column'}}>
                    <div className="journeyDownloadDiv1">
                        <div className="journeyDownloadDiv1Label">
                                Ebook
                        </div>
                    </div>
                    <div className="journeyDownloadDiv2">
                        <text>Material complementar: Canais de Relacionamento</text>
                    </div>
                    
                    <a href={ 'https://uei5rvjj53q35d7d5fmeq2i7ga0kmobx.lambda-url.us-east-2.on.aws/?book=MCCanaisDeRelacionamento&token='+ token + '&company=' + company } target ='_blank' style={ { textDecoration:'none' } }>
                    <div className="journeyDownloadDiv3" onClick={()=>botconversaDownloadMaterial({materialName: 'Material complementar: Canais de Relacionamento'})}>
                        <text>DOWNLOAD</text>
                        <img src={iconDownload} alt='' style={{width:'1rem',
                                                                height:'1rem',
                                                                float:'right'}}></img>
                    </div>
                    </a>
            </div>
            <div style={{position:'relative',
                        width:'15rem',
                        top:'0rem',
                        left:window.screen.width>780 ? '6.9rem' : '0',
                        display:'flex',
                        flexDirection:'column'}}>
                    <div className="journeyDownloadDiv1">
                        <div className="journeyDownloadDiv1Label">
                                Ebook
                        </div>
                    </div>
                    <div className="journeyDownloadDiv2" style={{height:'5.9rem'}}>
                        <text>Saiba Mais: Relacionamento</text>
                    </div>
                    <a href={ 'https://uei5rvjj53q35d7d5fmeq2i7ga0kmobx.lambda-url.us-east-2.on.aws/?book=SMRelacionamento&token='+ token + '&company=' + company } target ='_blank' style={ { textDecoration:'none' } }>
                    <div className="journeyDownloadDiv3" onClick={()=>botconversaDownloadMaterial({materialName: 'Saiba Mais: Relacionamento'})}>
                        <text>DOWNLOAD</text>
                        <img src={iconDownload} alt='' style={{width:'1rem',
                                                                height:'1rem',
                                                                float:'right'}}></img>
                    </div>
                    </a>
                                                            
            </div>
                
            </div>
            <a href='/journey/relationshipchannel/video' className={styles.backArrow} style={{textDecoration:'none',
                                                            }}>
                <div className="backButton2" style={{top:'3.2rem'}}>
                    <text className="textBackButton2" style={{top:'0.9rem'}}>&#8249;</text>
                </div>
            </a>
            <JourneysideMenu/>
        </div>
    )
}

export default JourneyRelationshipChannelFurtherMaterials;