import React from 'react'
import { CompleteBlueCheck } from '../../../components/imgsLoader/LoadIconImgs'
import styles from './ModattaSucess.module.sass'

const ModattaSuccess = () => {
  return (
    <div className={styles.modattaSuccess}>    
        <img src={CompleteBlueCheck} alt=''/>
        <p>Tudo certo, o questionário foi concluído com sucesso!!</p>   
    </div> 
  )
}

export default ModattaSuccess