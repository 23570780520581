import React from 'react'   
import styles from './DashboardNew.module.sass'
import { useState } from 'react'
import DashboardPerformance from './DashboardCards/DashboardPerformance/DashboardPerformance'
import DashboardFinancial from './DashboardCards/DashboardFinancial/DashboardFinancial'
import { useEffect } from 'react'

const DashboardNew = (props) => {



  const {
    monthGoals,
    colors,
    checkValue,
    globalRemainingMonthGoal,
    globalMonthSales,
    globalMonthTicket,
    globalMonthCAC,
    showDiv,
    hideDiv,
    revenuesCostsChart,
    revenuesCostsData,        
    costCoverageChart,
    costCoverageData,
    options,
    globalYearSales,
    screenDebounceTime,
    tableMonthSales,
    loadingDashboard,
    loadingMonthSalesWarn,
    emptyMonthSalesWarn,
    globalYearExpenses,
    yearGoals,
    globalYearResult,
    fillDashboardData

  } = props
  const [selectedTab, setSelectedTab] = useState('PERFORMANCE') // PERFORMANCE OR FINANCIAL



  

  useEffect(()=>{
  const boardContent = document.querySelector('.BoardContent')

  console.log('DashboardNew');console.log(globalMonthSales)
  boardContent.style.padding = '0px'
  boardContent.style.width = '100%'
  boardContent.style.height = '100%'
  const boardRight = document.querySelector('.BoardRight')
  boardRight.style.width = 'fit-content'








  }, [])

  useEffect(()=>{
      if (selectedTab==='FINANCIAL') {
        fillDashboardData()
      }
    }, [selectedTab])
  return (
    <div className={styles.dashboardNew}>
        <div className={styles.menuTab}>
            <div className={styles.leftTab} id={selectedTab==='PERFORMANCE' && styles.selectedTab} onClick={()=>{selectedTab!=='PERFORMANCE' && setSelectedTab('PERFORMANCE')}}>
              <span>Performance</span>   
            </div>
            <div className={styles.rightTab} id={selectedTab==='FINANCIAL' && styles.selectedTab} onClick={()=>{selectedTab!=='FINANCIAL' && setSelectedTab('FINANCIAL')}}>
              <span>Relatórios</span>   
            </div> 
        </div>


        <div className={styles.content}>
            {selectedTab==='PERFORMANCE' && 
                <DashboardPerformance 
                    monthGoals={monthGoals} 
                    colors={colors}
                    checkValue={checkValue}
                    globalRemainingMonthGoal={globalRemainingMonthGoal}
                    globalMonthSales={globalMonthSales}
                    globalMonthTicket={globalMonthTicket}
                    globalMonthCAC={globalMonthCAC}
                    globalYearSales={globalYearSales}
                    loadingDashboard={loadingDashboard}
                    loadingMonthSalesWarn={loadingMonthSalesWarn}
                    tableMonthSales={tableMonthSales}
                    emptyMonthSalesWarn={emptyMonthSalesWarn}
                    screenDebounceTime={screenDebounceTime}
                    globalYearExpenses={globalYearExpenses}
                    globalYearResult={globalYearResult}
                    yearGoals={yearGoals}

                    />}
            {selectedTab==='FINANCIAL' && 
            <DashboardFinancial
                hideDiv={hideDiv}
                showDiv={showDiv}    

                options={options}
                costCoverageChart={costCoverageChart}
                costCoverageData={costCoverageData}
                revenuesCostsChart={revenuesCostsChart}
                revenuesCostsData={revenuesCostsData}
            />} 
        </div>







    </div>
  )
}

export default DashboardNew