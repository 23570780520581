import UserStore from './../store/userStore';
import { colors, AppArea } from "../components/Styles";
import React, { useState } from 'react';
import axios from 'axios';
import Background from "./../assets/new_background.png";
import SupportIcon from '../components/supportIcon/SupportIcon';
import LoginContainer from '../components/loginContainer/LoginContainer';
import LoginButton from '../components/loginButton/LoginButton';

import inputstyle from './sass/Input.module.sass'
import logo from '../assets/new_logo.png'
var md5 = require('md5');



const Login = () => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const teste = 'Olá'
    //axios.get('https://x5sukrhatrp6cuzzpdby7dzzge0ekbvt.lambda-url.us-east-2.on.aws/', {params:{teste: teste}})
    const [loading, setLoading] = useState(false);
    const scroll = document.querySelector('#root')
    scroll.style.overflow = 'hidden'




    const defineFirstPage = (firstPage) =>{console.log(firstPage)
        switch (firstPage) {
            case 1:
                UserStore.setSelectedMenuStore('journey');
                UserStore.setSelectedPageStore('journey');
                return '/journey';
            case 2: 
                //if(window.screen.width<780){return '/revenues'} 
                UserStore.setSelectedMenuStore('cashflow')  
                UserStore.setSelectedPageStore('cashflow')
                return '/cashflow';

            default:                
            UserStore.setSelectedMenuStore('cashflow')  
            UserStore.setSelectedPageStore('cashflow')
                return '/cashflow';
        }



    }

    const loginBtnClick = async() =>
    {

        setLoading(true);
        var email = document.getElementsByName( "email" )[ 0 ].value;
        var password = md5( document.getElementsByName( "password" )[ 0 ].value );
        const resp = await axios.get
        ( 
            /*'https://ar2sysms2km5s47lgg2jwxrqfe0nfkei.lambda-url.us-east-2.on.aws/'*/'https://ff7dnqc4cqykjhtn3xkehgou7m0pmmjp.lambda-url.us-east-2.on.aws/',
            {
                params:
                {
                    email: email,
                    password: password
                }
            }
        );

        if( resp.data.token )
        {
            if( resp.data.token !== '' && resp.data.userId !== '' )
            {console.log(resp)
                
                const page = window.screen.width < 780 ? '/journey' : '/'
                UserStore.setToken( resp.data.token );
                UserStore.setLogged( true );
                UserStore.setUserEmail( email );
                UserStore.setUserId( resp.data.userId );
                UserStore.setMyModel( resp.data.modelId );
                UserStore.setAccType( resp.data.accType );
                UserStore.setUserPhone(resp.data.phone);
                UserStore.setUserName(resp.data.userName);
                //UserStore.setFirstPage(resp.data.firstPage);

                window.location.replace(defineFirstPage(resp.data.firstPage));
            }

            // else{
            //     var errorText = document.getElementById('wrongLogin');
            //     errorText.style.display = 'flex';
            // }
        }

        else{
            var errorText = document.getElementById('wrongLogin');
            errorText.style.display = 'block';
            setLoading(false)

        }
    };

    return (
        <div id="app" className="loginAreaBackground" style={{/*display:'flex',flexDirection:'row',width:'100%',height:'100%'*/}} >  
                <SupportIcon/>
                <LoginContainer id="quizContainer" bg={Background}  > 
                    <AppArea id="login">

                        <div className="login-area">

                            <img src={logo} alt="MEconecte" width={160} height={80} style={{marginBottom: '20px'}}/>
                            <div className="form-box" style={{height:" 100%"}}>
                                <div className="header-form">
                                    <h2>Acesse a sua conta</h2>                               
                                </div>
                                <div className="body-form">     
                                    <div className={inputstyle.field}>

                                        <label for='email'>E-mail</label>
                                        <input name='email' type="text" className="form-control" placeholder="Digite seu email" />
                                        
 
                                    </div>

                                    <div className={inputstyle.field}>

                                        <div>
                                            
                                            <label for='password'>Senha</label>
                                            <a href='/recoverpassword' style={{textDecoration:'none', width:'fit-content', alignSelf:'center'}}>
                                               <text className='loginPasswordText' >Esqueci minha senha</text>
                                            </a>                  


                                        </div>

                                        <div className={inputstyle.password}>
                                            <input name='password' type={isPasswordVisible ? "text" : "password"} className="form-control" placeholder="Digite sua senha" />
                                            <i className={isPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye" } onClick={()=>setIsPasswordVisible(prev=>!prev)}/>
                                        </div>
                                    </div>
                                    <text className='modelSettingsErrorMsg' 
                                            style={{fontSize:'1rem',fontWeight:'600'}}
                                            id='wrongLogin'>Login ou senha incorretos.</text>
                                    {!loading && <LoginButton onClick={ loginBtnClick }>Entrar</LoginButton>}       
                                    {loading && <LoginButton disabled="disabled">CARREGANDO...</LoginButton>}   
                                    <text className='loginInfoText'>*Para primeiro acesso utilizar e-mail como login e CPF como senha</text>   
                                    {/*<div>
                                        <text className='loginInfoText'>Para se registrar gratuitamente, clique </text>
                                        <a href='/register' style={{textDecoration:'none', width:'fit-content', alignSelf:'center'}}>
                                            <text className='loginPasswordText' >AQUI</text>
                                        </a> 
                                    </div>*/}

                                </div>



                            </div>



                        </div>   
                    </AppArea>                  
                </LoginContainer>  

                                 
        </div>
    );
}

export default Login;