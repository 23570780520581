export const StringMask = ( input, attributes, label ) =>
{
    let maskedString = '';
    let inputString = input.toString();
 
    switch( attributes.type )
    {
        case 'DDMMYYYY':
            maskedString = inputString.replace( /(\D)/g, '' ).replace( /(\d{2})(\d)/, '$1/$2' ).replace( /(\d{2})(\d)/, '$1/$2' ).replace( /(\d{4})/, '$1' );
            break;
        case 'ptBRCurrency':
        case '2DigitFraction':
            maskedString = inputString.replace( '.', '' ).replace( ',', '' ).replace( /\D/g, '' );
            maskedString = maskedString === '' ? 0 : maskedString;
            maskedString = Number.isNaN( maskedString ) ? '0' : new Intl.NumberFormat( 'pt-BR', { minimumFractionDigits: 2 } ).format( parseFloat( maskedString ) / 100.00 );
            break;
        case 'float':
            maskedString = inputString.replace( '.', '' ).replace( ',', '' ).replace( /\D/g, '' );
            maskedString = maskedString === '' ? 0 : maskedString;
            maskedString = Number.isNaN( maskedString ) ? '0' : parseFloat( maskedString ).toFixed( 2 ) / 100.00;
            break;
        case 'onlyNumbers':
            maskedString = ( Number.isNaN( inputString ) || inputString === '' ) ? 0 : inputString.replace( /(\D)/g, '' );
            break;
        case 'CPFCNPJ':
            if( inputString.length > 14 )
            {
                maskedString = inputString
                    .replace( /\D/g, '' )
                    .replace( /(\d{2})(\d)/,'$1.$2' )
                    .replace( /(\d{3})(\d)/,'$1.$2' )
                    .replace( /(\d{3})(\d)/,'$1/$2' )
                    .replace( /(\d{4})(\d)/,'$1-$2' )
                    .replace( /(\d{2})/, '$1' );
            }
            else
            {
                maskedString = inputString
                    .replace( /\D/g, '' )
                    .replace( /(\d{3})(\d)/, '$1.$2' )
                    .replace( /(\d{3})(\d)/, '$1.$2' )
                    .replace( /(\d{3})(\d{1,2})/, '$1-$2' )
                    .replace( /(-\d{2})/, '$1' );
            }
            break;
        case 'phone':
        case 'Phone':
            if( inputString.length > 14 )
            {
                maskedString =  inputString
                    .replace( /\D/g,'' )
                    .replace( /(\d{2})(\d{1})(\d{4})(\d{4})/,'($1) $2 $3-$4' );
            }
            else
            {
                maskedString = inputString
                    .replace( /\D/g,'' )
                    .replace( /(\d{2})(\d)/,'($1) $2' )
                    .replace( /(\d{4})(\d)/,'$1-$2' )
                    .replace( /(\d{4})/,'$1' );
            }
        break;       
        default:
            maskedString = '...';
    }

    return label + maskedString;
}
  
export const DateMask = ( date, attributes = { type: 'DDMMYYYY', separator: '/' } ) =>
{
    const day = date.getDate().toString();
    const month = ( date.getMonth() + 1 ).toString();
    const year = date.getFullYear().toString();
    const separator = !attributes.hasOwnProperty( 'separator' ) ? '/' : attributes.separator.toString();
    let maskedDate = '';

    switch( attributes.type )
    {
        case 'DDMMYYYY':
            maskedDate = day.padStart( 2, '0' ) + separator + month.padStart( 2, '0' ) + separator + year.padStart( 4, '0' );
        break;
        default:
            maskedDate = '';
    }
    return maskedDate;
}