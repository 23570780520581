import React from "react";
import "../questions/questions.css";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import UserStore from "../../store/userStore";
import { useState,useEffect } from "react";
import axios from "axios";
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import { redirect } from "react-router-dom";
import { FailAddElement } from "../failAddElement/FailAddElement";

const PainQuestions =(props)=> {

    const [ journeyData, setJourneyData] = useState( null );
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)




    const LoadJourneyAnswers = async() =>{


        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );

        if(resp.data.ForWhom )
        {
            setJourneyData(resp.data.ForWhom);
        }
    }

    const saveAnswers = async()=>{

        setLoading(true)
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData =
        {
            jfwProblemDesc:journeyData.jfwProblemDesc,
            jfwPainOpt:journeyData.jfwPainOpt,
            jfwProblemOpt:journeyData.jfwProblemOpt,
            jfwNecessityOpt:journeyData.jfwNecessityOpt
        };
        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'ForWhom', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })
    


        document.getElementById( 'saveBtn' ).innerHTML = "Salvar";
    }

    const changetext = ( e, source, choice ) => 
    {
        switch( source )
        {
            case 'text1':    
                if(journeyData.jfwProblemDesc !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwProblemDesc : e.target.value});
                break;

            case 'text2':
            if(journeyData.jfwPainOpt !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwPainOpt : e.target.value});
                break;

            case 'text3':
            if(journeyData.jfwProblemOpt !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwProblemOpt : e.target.value});
                break;

            case 'text4':
            if(journeyData.jfwNecessityOpt !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwNecessityOpt : e.target.value});
                break;

        }
    }
    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }


    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()


    }


    useEffect( () => { LoadJourneyAnswers()}, [] );

    
    return(
        
        <div className="journeyFWQQuestionsLayout1">

    
            {loading && <LoadingScreen/>}    
            {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>}
            {
            
            props.activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.redirect}/>
            
            }



            {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>}
               <div className="journeyQuestionsSaveBtn"  id='saveBtn' onClick={saveAnswers}>
                    Salvar
                </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Qual o problema ou necessidade do Mercado sua ideia/negócio resolve? 
                                                            Descreva de forma objetiva as características deste mercado.
                                    <QuestionsInformation breakLine={true} information="Um problema é uma situação indesejada ou uma dificuldade que o mercado enfrenta e que precisa ser resolvida. Uma necessidade é um desejo ou uma aspiração que o cliente tem e que busca satisfazer. <br/> Por exemplo, um problema de um segmento de clientes pode ser a falta de tempo para cozinhar, e uma necessidade pode ser a de comer alimentos saudáveis e saborosos."/>
                            </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea1' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwProblemDesc : '---'} onChange={(e) => changetext(e,'text1')}
                              onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea1')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea1')}></img>
                        </div>
                    </div>
                    {/**<div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Dor: diminuo ou elimino?</text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea2' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwPainOpt : '---'} onChange={(e) => changetext(e,'text2')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea2')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea2')}></img>
                        </div>
                    </div>
    **/}<div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Problema: resolvo total ou em parte?
                        <QuestionsInformation breakLine={true} information='Reflita sobre o problema do seu mercado. Seu negócio pode focar em resolver parte do problema ou a totalidade dele. <br/>Por exemplo, o mercado tem um problema de falta de tempo para cozinhar. Seu negócio pode resolver o problema entregando marmitas, que resolvem em parte o problema. E a sua empresa, resolve total ou em parte?' />
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwProblemOpt : '---'} onChange={(e) => changetext(e,'text3')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea3')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea3')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2" style={{marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText">Necessidade: satisfaço?
                        <QuestionsInformation information='Continuando no exemplo de alimentos. A necessidade é comer alimentos saudáveis. Caso a empresa entregue marmitas de hambúrguer, por exemplo, ela não satisfaz a necessidade mesmo que resolva o problema. E a sua empresa, satisfaz ou não?' />
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea4' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwNecessityOpt : '---'} onChange={(e) => changetext(e,'text4')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea4')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea4')}></img>
                        </div>
                    </div>
                </div>
    )
}


export default PainQuestions;