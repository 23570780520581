import React from "react";
import "../questions/questions.css";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import axios from "axios";
import { useState,useEffect } from "react";
import UserStore from "../../store/userStore";
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import { FailAddElement } from "../failAddElement/FailAddElement";


const RelationshipChannelQuestions =(props) =>{

    const [ journeyData, setJourneyData] = useState( null );
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)


    const LoadJourneyAnswers = async() =>{
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );

        if(resp.data.What )
        {
            setJourneyData(resp.data.What);
        }
    }

    const saveAnswers = async()=>{



        setLoading(true)
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData = 
        {
            jwCustomerConquer: journeyData.jwCustomerConquer, 
            jwCustomerHolding: journeyData.jwCustomerHolding,
            jwRelationshipIncrease: journeyData.jwRelationshipIncrease, 
            jwRelationshipContact: journeyData.jwRelationshipContact
        };

        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'What', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })
    



        document.getElementById( 'saveBtn' ).innerHTML = "Salvar";
    }


    const changetext =(e,source,choice)=>{

        switch (source){

            case 'text1':{
                if(journeyData.jwCustomerConquer !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwCustomerConquer : e.target.value})         
            }
            break;
            case 'text2':{
                if(journeyData.jfwCustomerHolding !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwCustomerHolding : e.target.value})
            }
            break;
            case 'text3':{
                if(journeyData.jwRelationshipContact !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwRelationshipIncrease : e.target.value})
            }
            break;
            case 'text4':{
                if(journeyData.jwRelationshipContact !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwRelationshipContact : e.target.value})
            }
            break;
        }
        
    }

    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }



    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()
        


    }






    useEffect( () => { LoadJourneyAnswers()}, [] );


    

    return(
        <div className="journeyFWQQuestionsLayout1">    
        {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>}
        {loading && <LoadingScreen/>}
        {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>}
        {props.activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.redirect}/>}
    

                    <div className="journeyQuestionsSaveBtn" id='saveBtn' onClick={saveAnswers}>
                        Salvar
                    </div>
                    
                    <div className="journeyFWQQuestionsLayout2" style={{marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText">Quais são as formas de relacionamento com seu cliente?
                        <QuestionsInformation haveTopics={true} breakLine={true} information="Ao descrever as formas de relacionamento com o cliente, é importante considerar as várias estratégias e abordagens que uma empresa pode adotar para se envolver e atender às necessidades dos seus clientes. Aqui estão algumas das principais formas de relacionamento com o cliente:

                                <br/>Relacionamento pessoal:-> Interação direta entre a empresa e o cliente, por meio de reuniões presenciais, ligações personalizadas, adequado para negócios complexos.
                                <br/>Autoatendimento:-> Os clientes podem obter informações, fazer compras ou resolver problemas por conta própria, por meio de plataformas online, aplicativos móveis ou chatbots.
                                <br/>Relacionamento em comunidade:-> Espaço onde os clientes se conectam, compartilham experiências e fornecem feedback, como comunidades online, fóruns ou grupos em redes sociais.
                                <br/>Relacionamento automatizado:-> Interação automatizada com os clientes por meio de emails, mensagens de texto, notificações push ou campanhas de marketing baseadas em inteligência artificial.
                                <br/>Relacionamento por meio de parcerias:-> Colaborações estratégicas com outras empresas para atender às necessidades dos clientes, como programas de indicação, alianças comerciais ou soluções conjuntas."/>                        
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea4' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwRelationshipContact : '---'} onChange={(e) => changetext(e,'text4')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea4')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea4')}></img>
                        </div>
                    </div>
                        <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Como você conquista o cliente?
                            <QuestionsInformation breakLine={true} information="Conquistar um cliente envolve várias etapas e estratégias para estabelecer um relacionamento positivo e duradouro. Para isso, é necessário conhecer seu público-alvo, oferecer uma proposta de valor diferenciada, proporcionar experiência ao cliente, construir confiança e credibilidade, estar presente nas mídias sociais e canais de comunicação e solicitar feedbacks para melhorar constantemente. 
                            <br/>Quais dessas estratégias você utiliza? "/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea1' className="journeyFWQTextArea" placeholder="Digite sua resposta."  value ={(journeyData !== null) ? journeyData.jwCustomerConquer : '---'} onChange={(e) => changetext(e,'text1')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea1')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea1')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Como você retém o cliente?
                        <QuestionsInformation haveTopics={true} topicsInsideTopics={true} breakLine={true} information="Para reter um cliente, é essencial continuar oferecendo valor e mantendo um relacionamento positivo. 
                        
                        
                        
                        <br/>Aqui estão algumas estratégias para reter clientes que você pode usar:->

                            Excelência no atendimento ao cliente<topic>
                            Personalização<topic>
                            Comunicação regular<topic>
                            Programas de fidelidade e recompensas<topic>
                            Solicite feedback e demonstre melhorias<topic>
                            Ofereça incentivos para compras repetidas<topic>
                            Mantenha-se atualizado com o mercado<topic>
                                Acompanhamento pós-venda
                            "/>                       
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea2' className="journeyFWQTextArea" placeholder="Digite sua resposta."  value ={(journeyData !== null) ? journeyData.jwCustomerHolding : '---'} onChange={(e) => changetext(e,'text2')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea2')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea2')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Como você amplia as vendas ao cliente?
                        <QuestionsInformation haveTopics={true} topicsInsideTopics={true} breakLine={true} information="Ao pensar em ampliar as vendas por meio do relacionamento com o cliente, é importante focar em construir conexões sólidas e duradouras. 
                        
                        
                        
                            <br/>Aqui estão algumas estratégias para ampliar as vendas por meio do relacionamento com o cliente:->

                                Compreenda as necessidades do cliente<topic>
                                Personalize as interações e ofertas<topic>
                                Mantenha uma comunicação regular e relevante<topic>
                                Ofereça suporte pós-venda eficiente<topic>
                                Implemente programas de fidelidade e recompensas<topic>
                                Solicite feedback e faça melhorias<topic>
                                Surpreenda e encante os clientes<topic>
                                Mantenha contato constante mesmo fora das compras.
                                "/>                        
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta."  value ={(journeyData !== null) ? journeyData.jwRelationshipIncrease : '---'} onChange={(e) => changetext(e,'text3')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea3')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea3')}></img>
                        </div>
                    </div>



                </div>
    )
}

export default RelationshipChannelQuestions;