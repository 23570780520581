import React from "react";
import { CProgress, CProgressBar } from '@coreui/react'
import "../journey/JourneyForWhomQuestions.css";
import DeliveryChannelsQuestions from "../questions/deliveryChannelsQuestions";
import menuJourneyPurple from "../../assets/icons/iconMenuJourneyPurple.png";
import JourneysideMenu from "./JourneysideMenu";
import axios from "axios";
import { useEffect } from "react";
import UserStore from "../../store/userStore";
import { useState } from "react";
import { botconversaWebhook } from "../../functions/botconversaWebhook";

const JourneyDeliveryChannelQuestions = () =>{
    const [haveChanges, setHaveChanges] = useState(false)
    const [activeConfirmScreen, setActiveConfirmScreen] = useState(false)

    
    const checkChanges = (changesState) =>{
        if (changesState) {
            setHaveChanges(true)

        }
    }
    


    const Redirect = () =>{
        window.location.href="/journey/relationshipchannel/video";
     }

    const confirmChangePage =  () =>{

        if (haveChanges) {
            setActiveConfirmScreen(true)
        } else {
            Redirect()
        }


    }
    const disableConfirmScreen = () =>{setActiveConfirmScreen(false)}










    const pageLocale = 'Jornada'
    useEffect(()=>{

        botconversaWebhook({locale: pageLocale, action: 'Abriu Perguntas - Canais de Entrega'})
        const postStep = async(step) =>{
            await axios.put('https://iz5smq7lxhsxjtskiegdcibiru0vwhjh.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), token: UserStore.getToken(), journeyStep: step})
        }
        postStep(33) 
    }, [])    
    
    const OpenSideDiv = ()=>{
        let sideDiv = document.getElementById('journey1SideDiv');
        sideDiv.style.display='block';
    }

    
    return(
        <div className="journeyFWQBackground" >
            <img src={menuJourneyPurple} alt-='' className="journeyFWQIconMenu" onClick={OpenSideDiv} >
            </img>
            <div style={{position:'relative',
                        top: '1.7rem',
                        left: '6.8rem',
                        width:'12rem'}}>
                <text className="journeyFWQHeader">Entendendo seu modelo de negócio</text>
            </div>
            <div onClick={confirmChangePage} style={{cursor: 'pointer'}} className="journeyFWQEbookDiv" >
                <p className="journeyFWQlink" style={{textDecoration:'none',gap:'0.2rem', marginBottom: window.location.screen<780 && '-30px'}}>  
                    <div style={{display:'flex',
                                flexDirection:'column',
                                marginLeft:'1.5rem',
                                marginTop:'0.8rem',
                                marginBottom:'0.8rem', width: window.screen.width<780 && '150px'}}>
                        <text className="journeyFWQVideoUpperTxt">Próximo conteúdo</text>
                        <text className="journeyFWQVideoLowerTxt">Canais de Relacionamento</text>
                    </div>
                        <div className="journeyFWQArrowDiv">
                            <text className="journeyFWQArrow">&#8250;</text>
                        </div>
                </p>
            </div>
            <text className="journeyFWQprogressText" style={{top:'-5.5rem'}}>
                Seu progresso
            </text>      
            <div style={{position:'relative',
                        top:'-7rem',
                        left:'75.2rem',
                        paddingLeft:'0.4rem',
                        paddingRight:'0.4rem',
                        borderRadius:'0.3rem',
                        backgroundColor:'#251F53',
                        width:'fit-content'}}>
                <text className="journeyFWQprogressBarText">
                    67% completo
                </text>
            </div>
            <div style={{position:'relative',
                        top:'-6.5rem',
                        left:'67rem',
                        width:'20rem'}}>
                <CProgress height={ 6 }  >
                    <CProgressBar value={ 67 } />
                </CProgress>
            </div>
            <text className='journeyFWQQuestionsTitle'>Perguntas</text>
            <div className="journeyFWQQuestionsDiv">
                <DeliveryChannelsQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} redirect={Redirect} disableConfirmScreen={disableConfirmScreen}/>
            </div>
            <a href='/journey/deliverychannel/video' style={{textDecoration:'none', display: 'block', marginTop: window.screen.width<780 && '40px', width: '100px'}}>
                <div className="backButton2" style={{top: window.screen.width>780 && '-10rem'}}>
                    <text className="textBackButton2">&#8249;</text>
                </div>
            </a>    
            <JourneysideMenu></JourneysideMenu>
        </div>
    )
}

export default JourneyDeliveryChannelQuestions;