import React from 'react'
import styles from './TagsJourneyCard.module.css'
const TagsJourneyCard = ({title, tags}) => {
let tagsSplit;
  if (typeof tags==='string') {
    tagsSplit = tags.split(', ');    
  }

    

  return (
    <div className={styles.tagsJourneyCard}>
        <h4>{title}</h4>

        <div className={styles.tagsCard}>
        {tags && tagsSplit && tagsSplit.map(tag=>(
        <div><p>{tag}</p></div>



        ))}

        {!tagsSplit && <span style={{fontSize: '12px'}}>{tags}</span>}
        </div>
        
    
    </div>
  )
}

export default TagsJourneyCard