import React, { useEffect } from "react";
import "../questions/questions.css";
import axios from 'axios';
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import UserStore from "../../store/userStore";
import { useState } from "react";
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import { FailAddElement } from "../failAddElement/FailAddElement";

const MarketQuestions =(props) =>{
    const redirect = props.redirect ? props.redirect : '';
    const [ journeyData, setJourneyData] = useState( null );
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)


    const LoadJourneyAnswers = async() =>{




        let userId = UserStore.getUserId();
        let token = UserStore.getToken();

        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );

        if(resp.data.ForWhom )
        {
           setJourneyData(resp.data.ForWhom);
        }
   }

    const saveAnswers = async()=>{



        setLoading(true)        
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData = 
        {
            jfwMyMarket:journeyData.jfwMyMarket, 
            jfwMyMarketDesc:journeyData.jfwMyMarketDesc, 
            jfwMarketRange:journeyData.jfwMarketRange,
            jfwMarketSize:journeyData.jfwMarketSize,
            jfwMarketPotencialGrow:journeyData.jfwMarketPotencialGrow,
            jfwMarketGrow1:journeyData.jfwMarketGrow1,
            jfwMarketGrow2:journeyData.jfwMarketGrow2,
            jfwMarketGrow3:journeyData.jfwMarketGrow3
        };

        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'ForWhom', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })
    


        document.getElementById( 'saveBtn' ).innerHTML = "Salvar";

        setLoading(false)
        setUpdateAwnserState(true)
    }

    const changetext = ( e, source, choice ) =>
    {
        switch( source )
        {
            case 'text1':
                if(journeyData.jfwMyMarket !== e.target.value && props.checkChanges!=="-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwMyMarket : e.target.value});
                break;
            case 'text2':
                if(journeyData.jfMyMarketDesc !== e.target.value && props.checkChanges!=="-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwMyMarketDesc : e.target.value});
                break;
            case 'choice':
                if(journeyData.jfwMarketRange !== e.target.value && props.checkChanges!=="-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwMarketRange: choice});
                break;
            case 'input1':
                if(journeyData.jfwMarketSize !== e.target.value && props.checkChanges!=="-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwMarketSize: e.target.value});
                break;
            case 'input2':
                if(journeyData.jfwMarketPotencialGrow !== e.target.value && props.checkChanges!=="-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwMarketPotencialGrow: e.target.value});
                break;
            case 'inputYear1':
                if(journeyData.jfwMarketGrow1 !== e.target.value && props.checkChanges!=="-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwMarketGrow1: e.target.value});
                break;
            case 'inputYear2':
                if(journeyData.jfwMarketGrow2 !== e.target.value && props.checkChanges!=="-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwMarketGrow2: e.target.value});
                break;
            case 'inputYear3':
                if(journeyData.jfwMarketGrow3 !== e.target.value && props.checkChanges!=="-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwMarketGrow3: e.target.value})
                break;
        }
    }

    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }


    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()
    


    }
    useEffect( () => { LoadJourneyAnswers()}, [] );




    
    return(
        <div className="journeyFWQQuestionsLayout1">   
        {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>} 
        {loading && <LoadingScreen/>}
        {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>}
        {props.activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.redirect}/>}




    
              <div className="journeyQuestionsSaveBtn"  id='saveBtn' onClick={saveAnswers}>
                    Salvar
                </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Qual é o seu mercado?
                            <QuestionsInformation breakLine={true} information="Escreva o nome do seu setor de mercado junto com seu nicho de atuação. <br/>Por exemplo: sua empresa presta serviço no mercado de alimentação, mas o mercado de alimentação tem o segmento de alimentação saudável e dentro de alimentação saudável, tem o nicho de sem glúten. Então seu mercado é Prestadora de serviços em alimentação e seu nicho é consumidores que comem comidas sem glúten."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                             <textarea id='journeyFWQtextArea1' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwMyMarket : '---'} onChange={(e) => changetext(e,'text1')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea1')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea1')}></img>
                        </div>
                    </div>
                   {/* <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Qual é a característica do seu mercado?
                        <QuestionsInformation information=""/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea2' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwMyMarketDesc : '---'} onChange={(e) => changetext(e,'text2')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea2')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea2')}></img>
                        </div>
                    </div> */} 
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Qual é a abrangência do seu mercado?
                            <QuestionsInformation information="O seu mercado pode ser internacional, atender o país inteiro, uma região específica ou apenas na sua cidade."/>
                                                </text>
                        <div className="journeyFWQQuestionsLayout4">
                            <div id='choice1' className={ (journeyData !== null) ? ((journeyData.jfwMarketRange === 1) ? 'journeyQuestionsMultipleChoicesSelected' : 'journeyQuestionsMultipleChoices') : 'journeyQuestionsMultipleChoicesSelected' } onClick={(e) =>changetext(e,'choice',1)}>
                                Internacional
                            </div>
                            <div id='choice2' className={ (journeyData !== null) ? ((journeyData.jfwMarketRange === 2) ? 'journeyQuestionsMultipleChoicesSelected' : 'journeyQuestionsMultipleChoices') : 'journeyQuestionsMultipleChoicesSelected' } onClick={(e) =>changetext(e,'choice',2)}>
                                Nacional
                            </div>
                            <div id='choice3' className={ (journeyData !== null) ? ((journeyData.jfwMarketRange === 3) ? 'journeyQuestionsMultipleChoicesSelected' : 'journeyQuestionsMultipleChoices') : 'journeyQuestionsMultipleChoicesSelected' } onClick={(e) =>changetext(e,'choice',3)}>
                                Regional
                            </div>
                            <div id='choice4' className={ (journeyData !== null) ? ((journeyData.jfwMarketRange === 4) ? 'journeyQuestionsMultipleChoicesSelected' : 'journeyQuestionsMultipleChoices') : 'journeyQuestionsMultipleChoicesSelected' } onClick={(e) =>changetext(e,'choice',4)}>
                                Municipal
                            </div>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout5" style={{display: 'flex', flexDirection: 'column', marginBottom:'2rem'}}>

                        <div style={{display: 'flex'}}>
                        <text className="journeyFWQQuestionText">Qual é o tamanho desse mercado em números?
                        
                        </text>
                        
                        <div style={{position:'relative',top:'-0.2rem',left:'0.5rem'}}>
                            <input className='journeyInputPercentage' type='number' step='0.1' min='0' max='100' value ={(journeyData !== null) ? journeyData.jfwMarketSize : 0} onChange={(e) => changetext(e,'input1')}
                            ></input>  
                        </div>   
                        </div> 
                        <div><QuestionsInformation information="Cada mercado se baseia em números diferentes. Alguns na quantidade de consumidores que pode atingir e alguns no valor monetário. Pesquise o tamanho do seu mercado em associações, órgãos do governo ou em pesquisas de mercado."/>                                                
                        </div>                     
                    </div>


                    <div className="journeyFWQQuestionsLayout5" style={{display: 'flex', flexDirection: 'column', marginBottom:'2rem'}}>
                        
                        <div style={{display: 'flex'}}>
                        <text className="journeyFWQQuestionText">Qual o potencial de crescimento desse mercado em números?
                           </text>
                        <div style={{position:'relative',top:'-0.2rem',left:'0.5rem'}}>
                            <input type='number' step='0.1' min='0' max='100' value ={(journeyData !== null) ? journeyData.jfwMarketPotencialGrow : 0} onChange={(e) => changetext(e,'input2')}
                            className='journeyInputPercentage'></input>                            
                        </div>
                            </div>
                        <div>   
                            <QuestionsInformation information="Procure em associações, órgãos do governo ou em pesquisas de mercado a taxa de crescimento do seu mercado. Isso te ajudará a identificar o potencial de crescimento do seu próprio negócio."/>
                        </div>
                    </div>


                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Qual percentual desse mercado você quer alcançar nos próximos 3 anos?
                        <QuestionsInformation information="Com base nas duas perguntas anteriores, pense em quantos % desse mercado você acha que é possível atingir em 3 anos."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout5">
                            <text className="journeyFWQQuestionText">Ano 1:</text>
                            <input type='number' step='0.1' min='0' max='100'
                            className='journeyInputPercentage' style={{position:' relative',top:'-0.1rem',left:'0.25rem'}} value ={(journeyData !== null) ? journeyData.jfwMarketGrow1 : 0} onChange={(e) => changetext(e,'inputYear1')}></input>                            
                            <text className="journeyFWQQuestionText" style={{position:' relative',left:'0.25rem'}} >%</text>
                        </div>
                        <div className="journeyFWQQuestionsLayout5">
                            <text className="journeyFWQQuestionText">Ano 2:</text>
                            <input type='number' step='0.1' min='0' max='100'
                            className='journeyInputPercentage' style={{position:' relative',top:'-0.1rem'}} value ={(journeyData !== null) ? journeyData.jfwMarketGrow2 : 0} onChange={(e) => changetext(e,'inputYear2')}></input>                            
                            <text className="journeyFWQQuestionText" style={{position:' relative',left:'0rem'}} >%</text>
                        </div>
                        <div className="journeyFWQQuestionsLayout5">
                            <text className="journeyFWQQuestionText">Ano 3:</text>
                            <input type='number' step='0.1' min='0' max='100'
                            className='journeyInputPercentage' style={{position:' relative',top:'-0.1rem'}} value ={(journeyData !== null) ? journeyData.jfwMarketGrow3 : 0} onChange={(e) => changetext(e,'inputYear3')}></input>                            
                            <text className="journeyFWQQuestionText" style={{position:' relative',left:'0rem'}} >%</text>
                        </div>
                    </div>
                </div>
    )
}

export default MarketQuestions;