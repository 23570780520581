import UserStore from '../../store/userStore';

import React, { useRef } from 'react';
import { MeSideLogoIconImg, ModelIconImg, DashboardIconImg, CashFlowIconImg, ActionPlanIconImg, JourneyIconImg, SettingsIconImg, IconPadlock, IconVideo } from '../imgsLoader/LoadIconImgs';
import axios from 'axios';
import { useEffect, useState } from 'react';
import UserPlanStore from '../../store/userPlanStore';

import styles from './MainTemplate.module.css'

import SideBackground from '../../assets/sideBar.png'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const UserSideBar = ( props ) =>
{

        const {isMenuExpanded, setIsMenuExpanded, sideBarOptions, isFirstAccessScreen, setNewSelectedSideMenu, setNewSelectedPage} = props

        const completeSideBarOptions = sideBarOptions.filter(item=>item.id!==5)
        console.log('options');console.log(sideBarOptions)
    
    const [havePremiumPlan, setHavePremiunPlan] = useState(Number(UserPlanStore.getPlanExpireDate()))
    const windowWidth = useRef(window.innerWidth)
    useEffect(()=>{
        axios.get('https://iuigcj7jka7bblez3rm557uevi0zdgvl.lambda-url.us-east-2.on.aws/', {params:{ company: UserStore.getUserId(), token: UserStore.getToken()}})
        .then(resp=>{

            if (resp.data) {
            setHavePremiunPlan(resp.data.products[0].restOfPlanDays)  
            //console.log('AAAA')
            //console.log(resp.data.products[0].haveWatchedIntro)                    
            }

            }
        )



    }, [])

    const expandMenu = () =>{
        setIsMenuExpanded(true)
    }
    const closeMenu = () =>{
        setIsMenuExpanded(false)
     }

    if( UserStore.getAccType() === 0 )
    {
        return(
            <div id={styles.sideMenu} className='sideBar'>
                 <div className='sideLogo'>
                    <img src={ MeSideLogoIconImg } alt='...'/>
                </div>
                <div className='sideBarItens'>
                    <a href='/modelrec' title='Diagnóstico'/*'Quiz'*/>
                        <div className={ ( props.selectedSideMenu === 'modelrec' ) ? 'sideBarItem sideBarItemSel' : 'sideBarItem' } >
                            <img src={ ModelIconImg } alt='...'/>
                        </div>
                    </a>
                    <a href='/modelsettings' title='Configurações'>
                        <div className={ ( props.selectedSideMenu === 'modelsettings' ) ? 'sideBarItem sideBarItemSel' : 'sideBarItem' } >
                            <img src={ SettingsIconImg } alt='...'/>
                        </div>
                    </a>
                </div>
            </div>
        );
    }
    else
    {
        

        return(
            <div id={styles.sideMenu} className={isMenuExpanded ? `${styles.expandedMenu}` : 'sideBar'} onMouseOver={expandMenu} onMouseOut={closeMenu}>
                <img className={styles.sideBackground} src={SideBackground} alt=""/>
                <div className='sideLogo'>
                    <img src={ MeSideLogoIconImg } alt='...'/>
                </div>
                <div className='sideBarItens'>
                   
                    

                
                        <Link onClick={()=>{UserStore.setSelectedPageStore('modelrec'); setNewSelectedPage('modelrec'); UserStore.setSelectedMenuStore('modelrec'); setNewSelectedSideMenu('modelrec')}} to='/modelrec' style={{textDecoration: 'none'}}>
                            <div style={{width: isMenuExpanded &&  '100%', textAlign: isMenuExpanded && 'left', padding: isMenuExpanded && '20px 10px'}} className={ ( props.selectedSideMenu === 'modelrec' ) ? 'sideBarItem sideBarItemSel' : 'sideBarItem' } >
                                <img className={isMenuExpanded && styles.expandedIcon} src={ ModelIconImg } alt='...'/>
                                <span style={{color: "white"}}>{isMenuExpanded && 'Diagnóstico'/*'Quiz'*/}</span></div>
                           </Link> 

                    
                    {sideBarOptions && completeSideBarOptions.map(item=>(
   


                    <Link onClick={()=>{UserStore.setSelectedPageStore(`${item.route}`); setNewSelectedPage(`${item.route}`); UserStore.setSelectedMenuStore(`${item.route}`); setNewSelectedSideMenu(`${item.route}`)}} style={{textDecoration: 'none'}} to={`/${item.route}`}>                            <div style={{width: isMenuExpanded && '100%', textAlign: isMenuExpanded && 'left', padding: isMenuExpanded && '20px 10px'}} className={ ( props.selectedSideMenu === item.route ) ? 'sideBarItem sideBarItemSel' : 'sideBarItem' } >
                                {havePremiumPlan>0 ? <img className={isMenuExpanded && styles.expandedIcon} src={ item.icon } alt='...'/> : (havePremiumPlan<=0)? <img src={IconPadlock} alt='Bloqueado'/> : <img src='' alt=''/>}
                            <span style={{color: "white"}}>{isMenuExpanded && item.title}</span></div> </Link>
                    ))}
                    
                    
                    {/*<a href='/modelsettings' style={{textDecoration: 'none'}}>
                        <div style={{width: isMenuExpanded && '100%', textAlign: isMenuExpanded && 'left', padding: isMenuExpanded && '20px 10px'}} className={ ( props.selectedSideMenu === 'modelsettings' ) ? 'sideBarItem sideBarItemSel' : 'sideBarItem' } >
                            <img className={isMenuExpanded && styles.expandedIcon} src={ SettingsIconImg } alt='...'/>
                            <span style={{color: "white"}}>{isMenuExpanded && 'Configurações'}</span></div>
                    </a>*/}



                    <Link onClick={()=>{UserStore.setSelectedPageStore('modelsettings'); setNewSelectedPage('modelsettings'); UserStore.setSelectedMenuStore('modelsettings'); setNewSelectedSideMenu('modelsettings')}} style={{textDecoration: 'none'}} to="/modelsettings">
                        <div style={{width: isMenuExpanded && '100%', textAlign: isMenuExpanded && 'left', padding: isMenuExpanded && '20px 10px'}} className={ ( props.selectedSideMenu === 'modelsettings' ) ? 'sideBarItem sideBarItemSel' : 'sideBarItem' } >
                            <img className={isMenuExpanded && styles.expandedIcon} src={ SettingsIconImg } alt='...'/>
                            <span style={{color: "white"}}>{isMenuExpanded && 'Configurações'}</span></div>
                        
                    </Link>
                </div>
            </div>
        );
    }
}

export default UserSideBar;