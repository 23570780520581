import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

const video = [{ title: 'Como empreender de forma saudavel', desc: 'Aprenda essa técnica com', picture: 'https://images.unsplash.com/photo-1629904853893-c2c8981a1dc5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80'}]

export const useMostRecentTrails = () => {
    const mostRecentTrailUrl = 'https://la52xyppxsty6uni4cwvdv3r5i0tntcv.lambda-url.us-east-2.on.aws/';
    const numberOfVideos = 20;
    const [isLoadingScreen, setIsLoadingScreen] = useState(true)

    const [data, setData] = useState([])
    useEffect(()=>{

        const getRecentTrailData = async() =>{
            await axios.get(mostRecentTrailUrl, { params:{limitNum: numberOfVideos}}).then(resp => {console.log(resp);setData(resp.data.trails)})
            //await setData(video)
            setIsLoadingScreen(false)


            
        }
        getRecentTrailData()


   }, [])

        return {data, isLoadingScreen};

}
