import React from 'react'
import styles from './ComparationJourneyCard.module.css'
import { useState } from 'react'
import { ReadMoreContentJourneyCard } from '../readMoreContentJourneyCard/ReadMoreContentJourneyCard'
const ComparationJourneyCard = ({title, problem01, problem02, problem03, benefit01, benefit02, benefit03}) => {

  const [moreContent, setMoreContent] = useState(false)
  const [moreContentMessage, setMoreContentMessage] = useState('')
  const [moreContentTitle, setMoreContentTitle] = useState('')
  const setScreen = (message, moreContentTitle) =>{

    setMoreContentMessage(message)
    setMoreContent(true)
    setMoreContentTitle(moreContentTitle)
  }

const ListElement = ({position, problem, benefit}) =>{
    return(  
    <div style={{display: 'flex'}}>
    <div className={styles.elementInRanking} style={{marginRight: '30px'}}>
                <div className={styles.positionInRank}><p>{position}</p></div>
                <div><p>{problem && problem.substring(0, 15)} {problem && problem.length>=20 && <span className={styles.readMoreContentButton} onClick={()=>{setScreen(problem, 'Problema')}}>Ler Mais</span>}</p>
    </div>           
    </div>    
    
    <div className={styles.elementInRanking}>
                <div><p></p></div>
                { <div><p>{benefit && benefit.substring(0, 18)} {benefit && benefit.length>18 && <span className={styles.readMoreContentButton} onClick={()=>{setScreen(benefit, 'Beneficio')}}>Ler Mais</span>}</p></div>            }
    </div>    
</div>
      
)       
}



  return (
    <div className={styles.comparationJourneyCard}>



        {moreContent && <ReadMoreContentJourneyCard title={moreContentTitle} message={moreContentMessage} onClick={()=>setMoreContent(false)}/>}
        <h4>{title}</h4>

        <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <span style={{marginLeft: '4px'}}>Problema</span><span>X</span><span style={{marginRight: '10px'}}>Beneficio</span>
            </div>
        <div className={styles.listRanking}>
         <ListElement position="1" problem={problem01} benefit={benefit01}/>
         <ListElement position="2" problem={problem02} benefit={benefit02}/>         
         <ListElement position="3" problem={problem03} benefit={benefit03}/>     


        
        
          
          
          </div>
    
    </div>
  )
}

export default ComparationJourneyCard