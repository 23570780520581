import React from 'react'
import styles from './CreateGroups.module.sass'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'

const CreateGroups = ({closeFn}) => {


    const [communityGroups, setCommunityGroups] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    useEffect(()=>{
    
        const getGroups = async() =>{

            setIsLoading(true)
            await axios.get('https://emojiapi.dev/api/v1')
            .then(resp=>{console.log(resp); })
            .catch(err=>{console.log(err); setIsLoading(false)})

        }

        getGroups()

    }, [])
  return (
    <div className={styles.createGroups}> 




        <div className={styles.content}>
            <div className={styles.top}>
               <h2>Criar Grupo</h2>
               <span onClick={closeFn}>x</span>
            </div>
            <div className={styles.bottom}>
                {communityGroups && communityGroups.map(group=><p>{group.title}</p>)}

                {isLoading && <p>Carregando...</p>}

                
            </div>
        </div>



    </div>
  )
}

export default CreateGroups