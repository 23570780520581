import React, { useState } from 'react'
import styles from './ClientCard.module.sass'
const ClientCard = ({client, editClient, removeClient}) => {



    const [isExpanded, setIsExpanded] = useState(false)
  return (
    <div className={isExpanded ? styles.expandedClientCard : styles.clientCard}>
        <div className={styles.topInfo}>
            <div className={styles.clientName}>{client.clientName}</div>
            <div className={styles.origem}><p>Origem</p> <span>{client.origem}</span></div>
        </div>
        <div className={styles.bottomInfo}>
            <div className={styles.clientInfo}>
                <p><span>Nome:</span> {client.clientName}</p>
                <p><span>CPF/CNPJ:</span> {client.identity}</p>
                <p><span>Telefone:</span> {client.phone01}</p>
                <p><span>Celular:</span> {client.phone02}</p>

                <p><span>Produto:</span> {client.pName}</p>



                {isExpanded && <>









                    <p><span>Email:</span> {client.email}</p>
                    <p><span>CEP:</span> {client.CEP}</p>
                    <p><span>Endereço:</span> {client.address}</p>
                    <p><span>Estado:</span> {client.uf}</p>
                    <p><span>Cidade:</span> {client.localidade}</p>
                    <p><span>Cargo:</span> {client.businessPosition}</p>
                    

                
                
                </>}
                {client.clientStatus===1 && <div className={styles.statusABtn}><p>Ativo</p><i className="fa fa-power-off"/></div>} 
                {client.clientStatus===0 && <div className={styles.statusIBtn}><p>Inativo<i className="fa fa-power-off"/></p></div>}              


            </div>
            <div className={styles.expandInfo}>
                {!isExpanded && <div onClick={()=>setIsExpanded(true)}>Expandir <i className="fa fa-chevron-down"/></div>}
                {isExpanded && <>
                
                
                <div className={styles.cardActions}><i className="fa fa-pen" onClick={editClient}/> <i className="fa fa-trash" onClick={removeClient}/></div>
                <div onClick={()=>setIsExpanded(false)}>Ocultar <i className="fa fa-chevron-up"/></div></>}
            </div>


        </div>


    </div>
  )
}

export default ClientCard