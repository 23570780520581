import React from 'react'
import styles from './ValueCard.module.sass'
import { StringMask } from '../../../../library/StringMasks' 
const ValueCard = ({title, value, monthIndex}) => {

console.log(value )
  return (
    <div className={styles.valueCard}>
        {value && <div className={styles.itemName} style={{background: title==='Resultado' ? (value>=0 ? '#3dcf0c' : '#cf0c0c') : undefined}}>
            
            
            
            <p>{title}: </p> 
            
            
            
            <span> R$ {value && StringMask( value.toFixed( 2 ), { type:'2DigitFraction' }, ( value < 0 ) ? '-' : '' ) }</span></div>
        }
    </div>
  )
}

export default ValueCard