import React from "react";
import "../journey/JourneyKnowingYourModel.css";
import WhiteCheck from "../../assets/icons/whiteCheck.png";



const JourneysideMenu = () =>{


    const CloseSideDiv = ()=>{
        let sideDiv = document.getElementById('journey1SideDiv');
        sideDiv.style.display='none';
    }
     
    const showMarketDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorMarket');
        let mainArrow = document.getElementById('marketArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)' 
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }

    const forWhomShowDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorForWhom');
        let mainArrow = document.getElementById('forWhomArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }
        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    } 
    
    const introductionShowDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorIntroduction');
        let mainArrow = document.getElementById('introductionArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }
        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    } 
    
    const whatShowDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorWhat');
        let mainArrow = document.getElementById('whatArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }
        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }
    
    const howShowDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorHow');
        let mainArrow = document.getElementById('howArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }
        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    } 

    const showCompetitorsDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorCompetitors');
        let mainArrow = document.getElementById('competitorsArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }
    
    const showPublicDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorPublic');
        let mainArrow = document.getElementById('publicArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }    
    
    const showProductsandServicesDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorProductsandServices');
        let mainArrow = document.getElementById('productsandServicesArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }

    const showValuePropositionDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorValueProposition');
        let mainArrow = document.getElementById('valuePropositionArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }

    const showDistributionDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorDistribution');
        let mainArrow = document.getElementById('distributionArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }

    const showCommunicationChannelDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorCommunicationChannel');
        let mainArrow = document.getElementById('communicationChannelArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }

    const showSalesChannelDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorSalesChannel');
        let mainArrow = document.getElementById('salesChannelArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }

    const showDeliveryChannelDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorDeliveryChannel');
        let mainArrow = document.getElementById('deliveryChannelArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }

    const showRelationshipChannelDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorRelationshipChannel');
        let mainArrow = document.getElementById('relationshipChannelArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }

    const showActivitiesDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorActivities');
        let mainArrow = document.getElementById('activitiesArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }

    const showPartnersDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorPartners');
        let mainArrow = document.getElementById('partnersArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }

    const showResourcesDiv = () =>{
        let mainDiv = document.getElementById('journeyKYMSelectorResources');
        let mainArrow = document.getElementById('resourcesArrow');

        if(window.getComputedStyle(mainDiv).getPropertyValue('display') === 'none'){
            mainDiv.style.display='flex';
            mainArrow.style.color='#211DFF';
            mainArrow.style.transform='rotate(-90deg)'
            
        }

        else{
            mainDiv.style.display='none';
            mainArrow.style.color='#251F53';
            mainArrow.style.transform='rotate(90deg)  translateY(-0.25rem)'
        }   
    }

    return(

        <div id='journey1SideDiv'style={{display:'none',
                                            position:'absolute',
                                            top:'0rem',
                                            backgroundColor:'#ffffff',
                                            borderRadius:'0.7rem 0.7rem 0rem 0.7rem',
                                            width:'25rem',
                                            height:'100%',
                                            overflow:'hidden'}}>
                <div style={{position:'relative',
                            display:'flex',
                            flexDirection:'row',
                            backgroundColor: '#251F53',
                            borderRadius: '0.7rem 0.7rem 0rem 0rem',
                            }}>
                    <div className="journeyKYMSideMenuTextTitle">
                        Entendendo seu modelo de negócio
                    </div>
                    <div className="journeyKYMCloseSideDivBtn" onClick={CloseSideDiv}>
                        &#215;
                    </div>
                </div>
                <div className="journeyKYMScrollDiv" style={{height:'36rem'}}>
                    <div style={{display:'inline-block',         
                                marginLeft:'3rem',
                                width:'15.7rem'
                            }}>
                        <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                            INTRODUÇÃO</text>
                        <text id='introductionArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate"  onClick={introductionShowDiv} style={{fontSize: '1.8rem',
                                                                                                                                                position:'relative',
                                                                                                                                                top:'-0.5rem',
                                                                                                                                                cursor:'pointer'}}>
                            &#8250;</text>
                    </div>
                    <div style={{border: '1px solid #251F53',
                                marginLeft:'2rem',
                                width:'18.5rem'}}></div>
                    <div id='journeyKYMSelectorIntroduction'  style={{display:'none',
                                                                    flexDirection:'column',
                                                                    gap:'0rem'}}>
                            <div className='journeyKYMSelectorOptionDiv'> 
                                <a href="/journey/introduction" style={{textDecoration:'none', color:'inherit'}}>
                                    <div className="journeyKYMSelectorOption">
                                        <text>Introdução</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={WhiteCheck} alt=''/>
                                </div>
                            </div>
                            <div className='journeyKYMSelectorOptionDiv'>
                                <a href="/journey/introduction/video"  style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption" >
                                        <text>Boas Vindas</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                            </div>
                            <div className='journeyKYMSelectorOptionDiv'>
                                <a href="/journey/introduction/ourjouneyvideo"  style={{textDecoration:'none', color:'inherit'}}>
                                    <div className="journeyKYMSelectorOption" >
                                        <text>Vídeo</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                            </div>
                            <div className='journeyKYMSelectorOptionDiv'>
                                <a href="/journey/introduction/furthermaterials"  style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption">
                                        <text>E-book</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>
                        </div>


                    <div style={{display:'inline-block',          
                                marginLeft:'3rem',
                                marginTop:'1rem',
                                width:'15.7rem'
                            }}>
                        <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                            PARA QUEM</text>
                        <text id='forWhomArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate"  onClick={forWhomShowDiv} style={{fontSize: '1.8rem',
                                                                                                                                                position:'relative',
                                                                                                                                                top:'-0.5rem',
                                                                                                                                                cursor:'pointer'}}>
                            &#8250;</text>
                    </div>
                    <div style={{border: '1px solid #251F53',
                                marginLeft:'2rem',
                                width:'18.5rem'}}></div>
                    <div id='journeyKYMSelectorForWhom'  style={{display:'none',
                                                                flexDirection:'column'}}>
                            <div className='journeyKYMSelectorOptionDiv'>
                                <a href="/journey/forwhom" style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption">
                                        <text>Introdução</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                            </div>
                            <div className= 'journeyKYMSelectorOptionDiv'>
                                <a href="/journey/forwhom/introduction"  style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption">
                                        <text>Vídeo</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                            </div>
                            <div className= 'journeyKYMSelectorOptionDiv'>
                                <a href="/journey/forwhom/video"  style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption">
                                        <text>Vídeo</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                            </div>

                            <div className= 'journeyKYMSelectorOptionDiv'>
                                <a href="/journey/forwhom/furthermaterials" style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption">
                                        <text>E-book</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                            </div>

                            <div className= 'journeyKYMSelectorOptionDiv'>
                                <a href="/journey/forwhom/questions" style={{textDecoration:'none', color:'inherit'}}>
                                    <div className=  "journeyKYMSelectorOption">
                                        <text>Perguntas</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                            </div>

                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>

                            <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.7rem',
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Mercado</text>
                                    <text id='marketArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showMarketDiv} style={{fontSize: '1.8rem',
                                                                                                                                                        position:'relative',
                                                                                                                                                        top:'-0.5rem',
                                                                                                                                                        marginLeft:'auto'
                                                                                                                                                        }}>
                                        &#8250;</text>
                            </div>
                            
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                            </div>
                            <div id='journeyKYMSelectorMarket' style={{display:'none',
                                                                        flexDirection:'column'}}>

                            <div className= 'journeyKYMSelectorOptionDiv'>
                                <a href="/journey/market/video"  style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption" >
                                        <text>Vídeo</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                            </div>

                            <div className= 'journeyKYMSelectorOptionDiv'>
                                <a href="/journey/market/furthermaterials" style={{textDecoration:'none', color:'inherit'}}>
                                <div className= "journeyKYMSelectorOption">
                                        <text>E-book</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                            </div>

                            <div className='journeyKYMSelectorOptionDiv' >
                                <a href="/journey/market/questions" style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption">
                                        <text>Perguntas</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                            </div>

                                <div style={{border: '1px solid #D9D9D9',
                                        marginTop:'0.5rem',
                                        marginLeft:'2rem',
                                        width:'18.5rem'}}>
                            </div>  
                            </div>

                            <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.75rem'
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Público</text>
                                    <text id='publicArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showPublicDiv} style={{fontSize: '1.8rem',
                                                                                                                                            position:'relative',
                                                                                                                                            top:'-0.5rem',
                                                                                                                                            marginLeft:'auto'}}>
                                        &#8250;</text>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                                </div>
                            <div id='journeyKYMSelectorPublic' style={{display:'none',
                                                                        flexDirection:'column'}}>
                                <div className= 'journeyKYMSelectorOptionDiv'>
                                <a href="/journey/public/video" style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption">
                                        <text>Vídeo</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className= 'journeyKYMSelectorOptionDiv'>
                                <a href= "/journey/public/furthermaterials"style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption">
                                        <text>E-book</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className= 'journeyKYMSelectorOptionDiv' >
                                <a href="/journey/public/questions" style={{textDecoration:'none', color:'inherit'}}>
                                    <div className="journeyKYMSelectorOption">
                                        <text>Perguntas</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                            
                            
                            <div style={{border: '1px solid #D9D9D9',
                                        
                                        marginLeft:'2rem',
                                        width:'18.5rem'}}>
                            </div>  
                            </div>
                            <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.75rem'
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Concorrentes</text>
                                    <text id='competitorsArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showCompetitorsDiv} style={{fontSize: '1.8rem',
                                                                                                                                                        position:'relative',
                                                                                                                                                        top:'-0.5rem',
                                                                                                                                                        marginLeft:'auto'}}>
                                        &#8250;</text>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                            </div>
                            <div id='journeyKYMSelectorCompetitors' style={{display:'none',
                                                                        flexDirection:'column'}}>
                                 <div className= 'journeyKYMSelectorOptionDiv'>
                                <a href="/journey/competitors/video" style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption">
                                        <text>Vídeo</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className= 'journeyKYMSelectorOptionDiv'>
                                <a href="/journey/competitors/furthermaterials" style={{textDecoration:'none', color:'inherit'}}>
                                    <div className= "journeyKYMSelectorOption" >
                                        <text>E-book</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className= 'journeyKYMSelectorOptionDiv'>
                                <a href="/journey/competitors/questions" style={{textDecoration:'none', color:'inherit'}}>
                                    <div className="journeyKYMSelectorOption">
                                        <text>Perguntas</text>
                                    </div>    
                                </a>
                                <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem'}}>
                                </div>  
                            </div>
                    </div>
                    <div style={{display:'inline-block',         
                                marginLeft:'3rem',
                                width:'15.7rem',
                                marginTop:'1rem'
                            }}>
                        <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                            O QUE</text>
                        <text id='whatArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate"  onClick={whatShowDiv} style={{fontSize: '1.8rem',
                                                                                                                                                position:'relative',
                                                                                                                                                top:'-0.5rem',
                                                                                                                                                cursor:'pointer'}}>
                            &#8250;</text>
                    </div>
                    <div style={{border: '1px solid #251F53',
                                marginLeft:'2rem',
                                width:'18.5rem'}}></div> 
                    <div id='journeyKYMSelectorWhat'  style={{display:'none',
                                                                flexDirection:'column'}}>
                         <div className= 'journeyKYMSelectorOptionDiv'>
                            <a href="/journey/what" style={{textDecoration:'none', color:'inherit'}}>
                                <div className= "journeyKYMSelectorOption">
                                    <text>Introdução</text>
                                </div>    
                            </a>
                            <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                        </div>
                         <div className= 'journeyKYMSelectorOptionDiv'>
                            <a href="/journey/what/video" style={{textDecoration:'none', color:'inherit'}}>
                                <div className= "journeyKYMSelectorOption">
                                    <text>Vídeo</text>
                                </div>    
                            </a>
                            <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                        </div>
                        <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>
                        <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.7rem',     
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Produtos ou Serviços</text>
                                    <text id='productsandServicesArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showProductsandServicesDiv} style={{fontSize: '1.8rem',
                                                                                                                                                        position:'relative',
                                                                                                                                                        top:'-0.5rem',
                                                                                                                                                        marginLeft:'auto'
                                                                                                                                                        }}>
                                        &#8250;</text>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                            </div>
                            <div id='journeyKYMSelectorProductsandServices' style={{display:'none',
                                                                        flexDirection:'column'}}>
                                 <div className= 'journeyKYMSelectorOptionDiv' >
                                    <a href="/journey/productandservices/video" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption">
                                            <text>Vídeo</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className='journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/productandservices/questions" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className="journeyKYMSelectorOption" >
                                            <text>Perguntas</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>                                
                            </div>
                            <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.7rem',     
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Proposta de Valor</text>
                                    <text id='valuePropositionArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showValuePropositionDiv} style={{fontSize: '1.8rem',
                                                                                                                                                        position:'relative',
                                                                                                                                                        top:'-0.5rem',
                                                                                                                                                        marginLeft:'auto'
                                                                                                                                                        }}>
                                        &#8250;</text>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                            </div>
                            <div id='journeyKYMSelectorValueProposition' style={{display:'none',
                                                                        flexDirection:'column'}}>
                                <div className= 'journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/valueproposition/video" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption" >
                                            <text>Vídeo</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                    </div>
                                </div>
                                <div className= 'journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/valueproposition/furthermaterials" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption" >
                                            <text>E-book</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                    </div>
                                </div>
                                <div className= 'journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/valueproposition/questions" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption">
                                            <text>Perguntas</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>                                
                            </div>
                            <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.7rem',     
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Canais de Distribuição</text>
                                    <text id='distributionArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showDistributionDiv} style={{fontSize: '1.8rem',
                                                                                                                                                        position:'relative',
                                                                                                                                                        top:'-0.5rem',
                                                                                                                                                        marginLeft:'auto'
                                                                                                                                                        }}>
                                        &#8250;</text>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                            </div>
                            <div id='journeyKYMSelectorDistribution' style={{display:'none',
                                                                        flexDirection:'column'}}>
                                 <div className='journeyKYMSelectorOptionDiv' >
                                    <a href="/journey/distributionchannel/video" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption">
                                            <text>Vídeo</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>
                            </div>
                            <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.7rem',     
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Canais de Comunicação</text>
                                    <text id='communicationChannelArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showCommunicationChannelDiv} style={{fontSize: '1.8rem',
                                                                                                                                                        position:'relative',
                                                                                                                                                        top:'-0.5rem',
                                                                                                                                                        marginLeft:'auto'
                                                                                                                                                        }}>
                                        &#8250;</text>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                            </div>
                            <div id='journeyKYMSelectorCommunicationChannel' style={{display:'none',
                                                                        flexDirection:'column'}}>
                                 <div className='journeyKYMSelectorOptionDiv' >
                                    <a href="/journey/communicationchannel/video" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption">
                                            <text>Vídeo</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className= 'journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/communicationchannel/furthermaterials" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className="journeyKYMSelectorOption">
                                            <text>E-book</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className= 'journeyKYMSelectorOptionDiv' >
                                    <a href="/journey/communicationchannel/questions" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption">
                                            <text>Perguntas</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>
                            </div>
                            <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.7rem',     
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Canais de Venda</text>
                                    <text id='salesChannelArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showSalesChannelDiv} style={{fontSize: '1.8rem',
                                                                                                                                                        position:'relative',
                                                                                                                                                        top:'-0.5rem',
                                                                                                                                                        marginLeft:'auto'
                                                                                                                                                        }}>
                                        &#8250;</text>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                            </div>
                            <div id='journeyKYMSelectorSalesChannel' style={{display:'none',
                                                                        flexDirection:'column'}}>
                                 <div className= 'journeyKYMSelectorOptionDiv' >
                                    <a href="/journey/saleschannel/video" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption">
                                            <text>Vídeo</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className= 'journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/saleschannel/questions"style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption" >
                                            <text>Perguntas</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>
                            </div>
                            <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.7rem',     
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Canais de Entrega</text>
                                    <text id='deliveryChannelArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showDeliveryChannelDiv} style={{fontSize: '1.8rem',
                                                                                                                                                        position:'relative',
                                                                                                                                                        top:'-0.5rem',
                                                                                                                                                        marginLeft:'auto'
                                                                                                                                                        }}>
                                        &#8250;</text>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                            </div>
                            <div id='journeyKYMSelectorDeliveryChannel' style={{display:'none',
                                                                        flexDirection:'column'}}>
                                 <div className='journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/deliverychannel/video" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className="journeyKYMSelectorOption">
                                            <text>Vídeo</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className='journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/deliverychannel/questions" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption">
                                            <text>Perguntas</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>
                            </div>
                            <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.7rem',     
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Relacionamento</text>
                                    <text id='relationshipChannelArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showRelationshipChannelDiv} style={{fontSize: '1.8rem',
                                                                                                                                                        position:'relative',
                                                                                                                                                        top:'-0.5rem',
                                                                                                                                                        marginLeft:'auto'
                                                                                                                                                        }}>
                                        &#8250;</text>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                            </div>
                            <div id='journeyKYMSelectorRelationshipChannel' style={{display:'none',
                                                                        flexDirection:'column'}}>
                                 <div className='journeyKYMSelectorOptionDiv'>
                                    <a href= "/journey/relationshipchannel/video" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className="journeyKYMSelectorOption">
                                            <text>Vídeo</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className='journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/relationshipchannel/furthermaterials" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className="journeyKYMSelectorOption">
                                            <text>E-book</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                <div className='journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/relationshipchannel/questions" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className="journeyKYMSelectorOption">
                                            <text>Perguntas</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>
                            </div>
                    </div>
                    <div style={{display:'inline-block',         
                                marginLeft:'3rem',
                                marginTop:'1rem',
                                width:'15.7rem'
                            }}>
                        <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                            COMO</text>
                        <text id='howArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate"  onClick={howShowDiv} style={{fontSize: '1.8rem',
                                                                                                                                                position:'relative',
                                                                                                                                                top:'-0.5rem',
                                                                                                                                                cursor:'pointer'}}>
                            &#8250;</text>
                    </div>
                    <div style={{border: '1px solid #251F53',
                                marginLeft:'2rem',
                                width:'18.5rem'}}></div>
                    <div id='journeyKYMSelectorHow'  style={{display:'none',
                                                            flexDirection:'column'}}>
                            <div className='journeyKYMSelectorOptionDiv'>
                            <a href="/journey/how" style={{textDecoration:'none', color:'inherit'}}>
                                <div className= "journeyKYMSelectorOption">
                                    <text>Introdução</text>
                                </div>    
                            </a>
                            <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                        </div>
                         <div className='journeyKYMSelectorOptionDiv'>
                            <a href="/journey/how/video" style={{textDecoration:'none', color:'inherit'}}>
                                <div className= "journeyKYMSelectorOption">
                                    <text>Vídeo</text>
                                </div>    
                            </a>
                            <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                        </div>
                        <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>
                        <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.7rem',     
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Atividades</text>
                                    <text id='activitiesArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showActivitiesDiv} style={{fontSize: '1.8rem',
                                                                                                                                                        position:'relative',
                                                                                                                                                        top:'-0.5rem',
                                                                                                                                                        marginLeft:'auto'
                                                                                                                                                        }}>
                                        &#8250;</text>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                            </div>
                            <div id='journeyKYMSelectorActivities' style={{display:'none',
                                                                        flexDirection:'column'}}>
                                 <div className='journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/activities/video" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className="journeyKYMSelectorOption">
                                            <text>Vídeo</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className='journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/activities/furthermaterials" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption">
                                            <text>E-book</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className='journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/activities/questions" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption">
                                            <text>Perguntas</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>                                
                            </div>
                            <div style={{display:'flex',
                                        marginTop:'1rem',
                                        marginLeft:'4rem',
                                        width:'14.7rem',     
                                        }}>
                                    <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                                        Parceiros</text>
                                    <text id='partnersArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate" onClick={showPartnersDiv} style={{fontSize: '1.8rem',
                                                                                                                                                        position:'relative',
                                                                                                                                                        top:'-0.5rem',
                                                                                                                                                        marginLeft:'auto'
                                                                                                                                                        }}>
                                        &#8250;</text>
                            </div>
                            <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}>
                            </div>
                            <div id='journeyKYMSelectorPartners' style={{display:'none',
                                                                        flexDirection:'column'}}>
                                 <div className='journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/partners/video" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className="journeyKYMSelectorOption">
                                            <text>Vídeo</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className='journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/partners/furthermaterials" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className= "journeyKYMSelectorOption">
                                            <text>E-book</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                 <div className='journeyKYMSelectorOptionDiv'>
                                    <a href="/journey/partners/questions" style={{textDecoration:'none', color:'inherit'}}>
                                        <div className="journeyKYMSelectorOption">
                                            <text>Perguntas</text>
                                        </div>    
                                    </a>
                                    <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                                </div>
                                <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>
                            </div>
                    </div>
                    <div style={{display:'inline-block',         
                                marginLeft:'3rem',
                                marginTop:'1rem',
                                width:'15.7rem'
                            }}>
                        <text className="journeyKYMSideDivSelectorText" style={{fontSize: '1rem'}}>
                            RECURSOS</text>
                        <text id='resourcesArrow' className="journeyKYMSideDivSelectorText journeyArrowRotate"  onClick={showResourcesDiv} style={{fontSize: '1.8rem',
                                                                                                                                                position:'relative',
                                                                                                                                                top:'-0.5rem',
                                                                                                                                                cursor:'pointer'}}>
                            &#8250;</text>
                    </div>
                    <div style={{border: '1px solid #251F53',
                                marginLeft:'2rem',
                                width:'18.5rem'}}></div>
                    <div id='journeyKYMSelectorResources'  style={{display:'none',
                                                            flexDirection:'column'}}>
                            <div className='journeyKYMSelectorOptionDiv'>
                            <a href="/journey/resources" style={{textDecoration:'none', color:'inherit'}}>
                                <div className="journeyKYMSelectorOption">
                                    <text>Introdução</text>
                                </div>    
                            </a>
                            <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                        </div>
                         <div className='journeyKYMSelectorOptionDiv'>
                            <a href="/journey/resources/video"style={{textDecoration:'none', color:'inherit'}}>
                                <div className="journeyKYMSelectorOption">
                                    <text>Vídeo</text>
                                </div>    
                            </a>
                            <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                            </div>
                        </div>
                         <div className= 'journeyKYMSelectorOptionDiv'>
                            <a href="/journey/resources/furthermaterials" style={{textDecoration:'none', color:'inherit'}}>
                                <div className="journeyKYMSelectorOption">
                                    <text>E-book</text>
                                </div>    
                            </a>
                            <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                        </div>
                         <div className='journeyKYMSelectorOptionDiv'>
                            <a href="/journey/resources/questions"  style={{textDecoration:'none', color:'inherit'}}>
                                <div className= "journeyKYMSelectorOption">
                                    <text>Perguntas</text>
                                </div>    
                            </a>
                            <div className={(false) ? "journeySideDivchecked" :'journeySideDivcheck'}>
                                    <img src={(false) ? WhiteCheck : ''} alt=''/>
                                </div>
                        </div>
                        <div style={{border: '1px solid #D9D9D9',
                                        marginLeft:'2rem',
                                        width:'18.5rem',
                                        }}></div>
                    </div>
                </div>                            
            </div>
    )
}

export default JourneysideMenu;