import React from "react";
import "../questions/questions.css";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import axios from "axios";
import { useState,useEffect } from "react";
import UserStore from "../../store/userStore";
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import { FailAddElement } from "../failAddElement/FailAddElement";




const SalesChannelsQuestions = (props)=>{


    const [ journeyData, setJourneyData] = useState( null );
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)


    const LoadJourneyAnswers = async() =>{
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );

        if(resp.data.What )
        {
            setJourneyData(resp.data.What);
        }
    }

    const saveAnswers = async()=>{

        setLoading(true)
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData = 
        {
            jwSalesLocation:journeyData.jwSalesLocation, 
            jwSalesPayment:journeyData.jwSalesPayment 
        };





        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'What', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })


        document.getElementById( 'saveBtn' ).innerHTML = "Salvar";

    }



    const changetext =(e,source,choice)=>{

        switch (source){

            case 'text1':{
                if(journeyData.jwSalesLocation !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwSalesLocation : e.target.value})         
            }
            break;
            case 'text2':{
                if(journeyData.jwSalesPayment !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwSalesPayment : e.target.value})
            }
            break;
        }
        
    }

    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }








    
    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()
        


    }














    useEffect( () => { LoadJourneyAnswers()}, [] );


    return(
        <div className="journeyFWQQuestionsLayout1" style={{overflow:'auto'}}>  
        {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>}  
        {loading && <LoadingScreen/>}
        {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>}
        {props.activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.redirect}/>}
    

               <div className="journeyQuestionsSaveBtn"  id='saveBtn' onClick={saveAnswers}>
                    Salvar
                </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Quais são os pontos de contato de venda?
                            <QuestionsInformation breakLine={true} information="São os locais, canais ou momentos em que ocorre o contato direto entre a empresa e o cliente durante o processo de venda. São os pontos de interação nos quais os clientes podem obter informações sobre os produtos ou serviços, receber atendimento, fazer perguntas, comparar opções, tomar decisões de compra e, eventualmente, efetuar a transação. <br/>Esses pontos de contato podem variar dependendo do tipo de negócio e do setor, mas geralmente incluem lojas físicas, sites, aplicativos, catálogos, call centers, eventos promocionais, redes sociais entre outros."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea1' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwSalesLocation : '---'} onChange={(e) => changetext(e,'text1')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea1')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea1')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2" style={{marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText">Quais são os meios de pagamento?
                            <QuestionsInformation haveTopics={true} breakLine={true} information="Escreva as opções que sua empresa oferece. Alguns exemplos são:

                                <br/>Dinheiro em espécie:-> Os clientes podem pagar diretamente com notas e moedas.
                                <br/>Cartões  de crédito:-> Visa, Mastercard, American Express e outros cartões de crédito são amplamente aceitos em muitos estabelecimentos.
                                <br/>Cartões de débito:-> Os clientes podem utilizar cartões de débito vinculados às suas contas bancárias para efetuar pagamentos imediatos.
                                <br/>Transferência bancária:-> Os clientes podem fazer uma transferência eletrônica de fundos diretamente da sua conta bancária para a conta da empresa.
                                <br/>Pagamento por meio de aplicativos:-> Soluções como Apple Pay, Google Pay, Samsung Pay e outros aplicativos de carteira digital permitem que os clientes façam pagamentos usando seus dispositivos móveis.
                                <br/>Pagamento por código QR:-> Os clientes podem escanear códigos QR para efetuar pagamentos usando aplicativos específicos, como o WeChat Pay e o Alipay.
                                <br/>Pagamento por boleto bancário:-> O cliente recebe um boleto bancário com um código de barras para pagamento, podendo ser pago em bancos, lotéricas ou por meio de aplicativos.
                                <br/>Pagamento por criptomoedas:-> Alguns estabelecimentos aceitam criptomoedas como Bitcoin, Ethereum e outras como forma de pagamento digital.
                                <br/>Pagamento parcelado:-> Permite que os clientes dividam o valor da compra em parcelas mensais, com ou sem juros, de acordo com as políticas da empresa.
                                <br/>Pagamento por serviços de carteira eletrônica:-> Empresas como PayPal, Skrill, Payoneer e outras oferecem serviços de carteira eletrônica, permitindo que os clientes façam pagamentos online de forma conveniente.
                                <br/>Pix:-> As transações são feitas por meio de chaves de identificação, que podem ser um CPF, CNPJ, número de telefone celular ou e-mail, tornando o processo de pagamento mais simples e ágil.
                                "/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea2' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwSalesPayment : '---'} onChange={(e) => changetext(e,'text2')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea2')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea2')}></img>
                        </div>
                    </div>
                </div>
    )
}

export default SalesChannelsQuestions;