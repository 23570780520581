import React from 'react'
import styles from './ModattaContent.module.sass'
import ModattaQuestions from '../modattaQuestions/ModattaQuestions'
import { useState } from 'react'
import ModattaRegister from '../modattaRegister/ModattaRegister'
import ModattaSuccess from '../modattaSuccess/ModattaSuccess'

const ModattaContent = ({modid}) => {
  const [haveCreatedAccount, setHaveCreatedAccount] = useState(false)   
  const [userId, setUserId] = useState('')
  const [areQuestionsComplete, setAreQuestionsComplete] = useState(false)
  return (
    <div>
        {haveCreatedAccount && !areQuestionsComplete && <ModattaQuestions setAreQuestionsComplete={setAreQuestionsComplete} userId={userId}/>}
        {!haveCreatedAccount && !areQuestionsComplete && <ModattaRegister setHaveCreatedAccount={setHaveCreatedAccount} setUserId={setUserId}/>}
        {areQuestionsComplete && <ModattaSuccess/>}
    
    </div>
  )
}

export default ModattaContent