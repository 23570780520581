import React from 'react'
import styles from './ExpenseCard.module.sass'
import { StringMask } from '../../../library/StringMasks'
const ExpenseCard = ({expenseName, cfe, targetValue, doneValue, editExpense}) => {


console.log(cfe)
  return (
    <div className={styles.expenseCard}>

      
        <div className={styles.itemName}><p>{expenseName}</p><div className={styles.edit} onClick={()=>editExpense({name: expenseName, id: cfe, doneValue: doneValue, targetValue: targetValue})}><i className="fa fa-pen"/></div></div>
        <div className={styles.values}>

        <div><p>Previsto</p><span>R$ {StringMask( targetValue.toFixed( 2 ), { type:'2DigitFraction' }, ( targetValue < 0 ) ? '-' : '' )}</span></div>
        <div><p>Realizado</p><span style={{color: doneValue<=targetValue ? undefined : '#cd0000'}}>R$ {StringMask( doneValue.toFixed( 2 ), { type:'2DigitFraction' }, ( doneValue < 0 ) ? '-' : '' )}</span></div> 
       </div>
    </div>
  )
}

export default ExpenseCard