import React from "react";
import { CProgress, CProgressBar } from '@coreui/react'
import "../journey/JourneyKnowingYourModel.css";
import menuJourney from "../../assets/icons/iconMenuJourney.png";
import JourneysideMenu from "./JourneysideMenu";
import styles from './JourneyAll.module.css';
import { useEffect } from "react";
import axios from "axios";
import UserStore from "../../store/userStore";
import MobileKYMStructure from "./mobileJourney/mobileKYMStructure/MobileKYMStructure";
import { botconversaWebhook } from "../../functions/botconversaWebhook";

const JourneyKYMForWhom = () => {

    const screenWidth = window.screen.width


    const pageLocale = 'Jornada'


    
    useEffect(()=>{





        botconversaWebhook({locale: pageLocale, action: 'Abriu Para Quem?'})
        const postStep = async(step) =>{
            await axios.put('https://iz5smq7lxhsxjtskiegdcibiru0vwhjh.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), token: UserStore.getToken(), journeyStep: step})
        }
        postStep(5) 
    }, [])

 
    const OpenSideDiv = ()=>{
        let sideDiv = document.getElementById('journey1SideDiv');
        sideDiv.style.display='block';
    }

    const type= 'Entendendo seu modelo de negócio'
    const title = 'Para Quem'
    const content = `O primeiro passo no Modelo de Negócio é entender <b><u>Para Quem</u></b> vamos entregar valor, no sentido de resolver um <b><u>problema</u></b> ou <b><u>necessidade</u></b>. Neste momento é de extrema importância que sua <b><u>ideia</u></b> tenha aderência ao <u><b>Público-alvo</b></u> escolhido, representando a solução que será desenvolvida no <b><u>O Quê</u></b>.`
    const prevVideoPath = '/journey/introduction/furthermaterials'
    const nextVideoPath = '/journey/forwhom/introduction'
    const stepPercent = 11
    
    return(
    
    <>

        {screenWidth>780 ?  
        <div className="journeyKYMBackground" style={{overflow:'hidden', position: 'relative'}}>
            <img src={menuJourney} alt='' className="journeyKYMIconMenu" onClick={OpenSideDiv}/>
            
            <div style={{position:'relative',
                        top: '1.7rem',
                        left: '6.8rem',
                        width:'12rem'}}>
                <text className="journeyKYMHeader">
                    {type}</text>
            </div>
                <div className="journeyKYMTextBackground" style={{position:'relative',top:'14rem', left:'6.5rem'}}>
                    <text className="journeyKYMTitleBlurDiv" >{title}</text>
                    <div style={{width:'24rem', marginTop:'1rem'}}>
                    <text className="journeyKYMText" style={{fontSize:'1.1rem'}} dangerouslySetInnerHTML={{ __html: content}}>

                        
                   </text>
                    </div>
                </div>  
            <div className="journeyKYMVideoDiv">
            <a href={nextVideoPath} className="journeyKYMlink" style={{textDecoration:'none'}}>
                <div style={{display:'flex',
                            flexDirection:'column',
                            marginLeft:'1.5rem',
                            marginTop:'0.8rem',
                            marginBottom:'0.8rem'}}>
                    <text className="journeyKYMVideoUpperTxt">Próximo conteúdo</text>
                    <text className="journeyKYMVideoLowerTxt">Vídeo</text>
                </div>
                    <div className="journeyKYMArrowDiv">
                        <text className="journeyKYMArrow">&#8250;</text>
                    </div>
                    </a>   
            </div> 
            <text className="journeyKYMprogressText" style={{position: 'absolute', top:'62px', right: '188px'}}>
                Seu progresso
            </text>      
            <div style={{position:'absolute',
                        top:'65px',
                        right:'88px',
                        paddingLeft:'0.4rem',
                        paddingRight:'0.4rem',
                        borderRadius:'0.3rem',
                        backgroundColor:'#FFFFFF',
                        width:'fit-content'}}>
                <text className="journeyKYMprogressBarText">
                    {stepPercent}% completo
                </text>
            </div>
            <div style={{position:'absolute',
                        top:'93px',
                        right:'10px',
                        width:'20rem'}}>
                <CProgress height={ 6 }  >
                    <CProgressBar value={ stepPercent } />
                </CProgress>
            </div>
            <a href={prevVideoPath} className={styles.backArrow} style={{textDecoration:'none'}}>
                <div className="backButton1" style={{top:'10.5rem'}}>
                    <text className="textBackButton1">&#8249;</text>
                </div>
            </a>
            <JourneysideMenu></JourneysideMenu>
        </div>
        : 
        <MobileKYMStructure stepPercent={stepPercent} prevVideoPath={prevVideoPath} nextVideoPath={nextVideoPath} content={content} type={type} title={title} OpenSideDiv={OpenSideDiv}/>
        }

      </>
        
    )
}

export default JourneyKYMForWhom;