import React from 'react'
import styles from './StepEbooks.module.sass'
import MaterialCard from './materialCard/MaterialCard'
const StepEbooks = ({materials}) => {

  console.log(materials)
  return (

    
    <div className={styles.stepEbooks}>Materiais
        <div className={styles.materialCards}>{materials && materials.map(material=><MaterialCard title={material.title} url={material.url}/>)}
        </div>
    </div>
  )
}

export default StepEbooks