import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useState } from 'react';
import ModelRecForm from './ModelRecForm';
import ModelRecResult from './ModelRecResult';
import Model from './Model';
import UserStore from '../../store/userStore';


const ModelRec = () => {

    const initialSelectedOptions =  [ 
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ] 
    ];

    const [ QuizStep, setQuizStep ] = useState( { step: 0, modelId: -1 } );
    const [ MyBusinessModel, setMyBusinessModel ] = useState( { modelName: '', selectedOptions: initialSelectedOptions } );
    const [ RecModelsByQuiz, setRecModelsByQuiz ] = useState( { First: { modelId: 0, modelName: '', modelFit: 0 }, Second: { modelId: 0, modelName: '', modelFit: 0 }, Third: { modelId: 0, modelName: '', modelFit: 0 } } );
    
    if( ( QuizStep.step === 0 ) && ( QuizStep.modelId === -1 ) && ( UserStore.getMyModel() ) )
    {
        setQuizStep( { step: 2, modelId: -1 } );
    }

     switch( QuizStep.step )

    {
        case 1:
            return( <ModelRecResult setQuizStep={ setQuizStep } RecModelsByQuiz={ RecModelsByQuiz } MyBusinessModel={ MyBusinessModel }/> );
        case 2:
            return( <Model QuizStep={ QuizStep } setQuizStep={ setQuizStep }/> );
        default:
            return( <ModelRecForm setQuizStep={ setQuizStep } MyBusinessModel={ MyBusinessModel } setRecModelsByQuiz={ setRecModelsByQuiz } setMyBusinessModel={ setMyBusinessModel }/> );
    }
}

export default ModelRec;