import { useEffect } from 'react';
import styles from './MonthSelect.module.css';

export const MonthSelect = ({actualMonth, monthFunction}) =>{
    
    
    const allMonths =
    [
    { month: '01', monthAbrv: 'jan', monthName: 'Janeiro' },
    { month: '02', monthAbrv: 'feb', monthName: 'Fevereiro' },
     { month: '03', monthAbrv: 'mar', monthName: 'Março' },
     { month: '04', monthAbrv: 'apr', monthName: 'Abril' },
     { month: '05', monthAbrv: 'may', monthName: 'Maio' },
     { month: '06', monthAbrv: 'jun', monthName: 'Junho' },
     { month: '07', monthAbrv: 'jul', monthName: 'Julho' },
     { month: '08', monthAbrv: 'aug', monthName: 'Agosto' },
     { month: '09', monthAbrv: 'sep', monthName: 'Setembro' },
     { month: '10', monthAbrv: 'oct', monthName: 'Outubro' },
     { month: '11', monthAbrv: 'nov', monthName: 'Novembro' },
     { month: '12', monthAbrv: 'dec', monthName: 'Dezembro' },


    ];


    const changePropMonthValue = (value) =>{

        let newValue = value.toString()
        if (value<10) {
            return `0${newValue}`;
        } else {
            return newValue;
        }

    }



    return(
    
        
        <div className={styles.monthSelect}>
        <p>Mês</p>
        <select onChange={monthFunction}>
            
            {
        allMonths.map(month=>{


            if(month.month === changePropMonthValue(actualMonth)){

                return(
                       <option key={month.month} selected="selected" id={`${month.month}month`}>
                      {month.monthName}
                     </option> 
                     
                     )
                         
            }

            else{
                return(
                    <option key={month.month} id={`${month.month}month`}>
                    {month.monthName}
                    </option>



                )

            }

            

        })}
            
            </select>

        </div>
    )


}

