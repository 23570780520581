import React from "react";
import "../questions/questions.css";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import axios from "axios";
import { useState,useEffect } from "react";
import UserStore from "../../store/userStore";
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import { FailAddElement } from "../failAddElement/FailAddElement";

const DeliveryChannelQuestions = (props)=>{
    
    const [ journeyData, setJourneyData] = useState( null );
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)


    const LoadJourneyAnswers = async(props) =>{
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );

        if(resp.data.What )
        {
            setJourneyData(resp.data.What);
        }
    }

    const saveAnswers = async()=>{

        setLoading(true)
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData = 
        {
            jwDeliveryContact:journeyData.jwDeliveryContact
        };

       
       
       
       
       
       
       
       
        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'What', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })
    
        document.getElementById( 'saveBtn' ).innerHTML = "Salvar";
    }



    const changetext =(e,source,choice)=>{

        switch (source){

            case 'text1':{
                if(journeyData.jwDeliveryContact !== e.target.value && props.checkChanges!=="-1"){ props.checkChanges(true)}  
                setJourneyData({...journeyData, jwDeliveryContact : e.target.value})   

            }
            break;
        }
        
    }

    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }







    
    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()
        


    }


    useEffect( () => { LoadJourneyAnswers()}, [] );


    return(
        <div className="journeyFWQQuestionsLayout1" style={{overflow:'auto'}}>  
        {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>}  
        {loading && <LoadingScreen/>}
        {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>}
        {props.activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.redirect}/>}
        
               <div className="journeyQuestionsSaveBtn"  id='saveBtn' onClick={saveAnswers}>
                    Salvar   
                </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Quais canais de distribuição atendem o cliente entregam o produto/ serviço?
                            <QuestionsInformation haveTopics={true} topicsInsideTopics={true} breakLine={true} information="Canais de entrega, também conhecidos como canais de distribuição, são os meios pelos quais os produtos ou serviços de uma empresa são disponibilizados aos clientes. Eles são responsáveis por conectar a empresa ao seu público-alvo, permitindo que os produtos cheguem até os consumidores de maneira eficiente e conveniente. Esses canais podem ser físicos ou digitais, e variam de acordo com o tipo de negócio e o setor em que atuam.

                                <br/>Alguns exemplos são:

                                <br/>Canais de entrega online:->
                                E-commerce (lojas virtuais)<topic>
                                Aplicativos de entrega (delivery)<topic>
                                Marketplaces (Amazon, eBay, Alibaba, entre outros)<topic>
                                Plataformas de reserva e agendamento (Booking.com, Airbnb, Uber)<topic>
                                Redes sociais (Facebook Marketplace, Instagram Shopping)<topic>
                                Websites de serviços (contratação de profissionais, reservas de viagens)

                                <br/>Canais de entrega físicos:->

                                Lojas físicas (varejo, boutiques, supermercados)<topic>
                                Quiosques ou stands em shoppings e centros comerciais<topic>
                                Franquias (unidades físicas de marcas estabelecidas)<topic>
                                Vending machines (máquinas automáticas de venda)<topic>
                                Pontos de venda em eventos ou feiras<topic>
                                Lojas de conveniência<topic>
                                Centros de distribuição ou depósitos<topic>
                                Serviços de entrega ao domicílio (couriers, empresas de logística)<topic>
                                Quiosques em locais de alta circulação (aeroportos, estações de trem, hospitais)
                                "/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea1' className="journeyFWQTextArea" placeholder="Digite sua resposta." style={{height:'8rem'}} value ={(journeyData !== null) ? journeyData.jwDeliveryContact : '---'} onChange={(e) => changetext(e,'text1')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea1')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea1')}></img>
                        </div>
                    </div>
                    
                </div>
    )
}

export default DeliveryChannelQuestions;