import { useEffect } from 'react';
import styles from './YearSelect.module.sass';

export const YearSelect = ({actualYear,  yearFunction}) =>{
    
    
    const allYears =
    [
    { year: '2023', },
    { year: '2024',  },
     { year: '2025',  },
     


    ];


    const changePropYearValue  = (value) =>{

        let newValue = value.toString()
        if (value<10) {
            return `0${newValue}`;
        } else {
            return newValue;
        }

    }



    return(
    
        
        <div className={styles.yearSelect}>
        <p>Ano</p>
        <select onChange={ yearFunction}>
            
            {
        allYears.map(year =>{


            if(Number(year.year) === actualYear){

                return(
                       <option key={year.year} selected="selected" id={`${year.year}`}>
                      {year.year}
                     </option> 
                     
                     )
                         
            }

            else{
                return(
                    <option key={year.year} id={`${year.year}`}>
                    {year.year}
                    </option>



                )

            }

            

        })}
            
            </select>

        </div>
    )


}

