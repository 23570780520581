import UserStore from '../../store/userStore';
import React from 'react';
import { UserProfileIconImg } from '../imgsLoader/LoadIconImgs';
import styles from './MainTemplate.module.css';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const UserBoardHeader = ( props ) =>
{
    
    
    
    const [planDays, setPlanDays] = useState(31)
    const [access, setAccess] = useState(null);
    const { setNewSelectedSideMenu, setNewSelectedPage } = props


    useEffect(()=>{
        const getAccess = async() =>{
            await axios.get('https://cy7oh4mxbivpv7mggnhyj5g4zi0xlduw.lambda-url.us-east-2.on.aws/', {params:{ company: UserStore.getUserId(), token: UserStore.getToken()}})
            .then(resp=>{

                if (resp.data) {
                setAccess(resp.data.products[0].haveWatchedIntro)  
                //console.log('AAAA')
                //console.log(resp.data.products[0].haveWatchedIntro)                    
                }

                }
            )

        }
        getAccess()



        const getExpireDate = async() =>{

            await axios.get('https://iuigcj7jka7bblez3rm557uevi0zdgvl.lambda-url.us-east-2.on.aws/', {params:{ company: UserStore.getUserId(), token: UserStore.getToken()}})
            .then(resp=>{
                if (resp.data) {
                setPlanDays(resp.data.products[0].restOfPlanDays)  
                         
                }
                }
            )  
        }
        getExpireDate()

    }, [])






    const upgradePlanLink = "https://sun.eduzz.com/1745888";
    const Logout = () => 
    {

        UserStore.setHideIntro(0)
        UserStore.setToken( '' );
        UserStore.setLogged( false );
        UserStore.setUserId( '' );
        UserStore.setUserEmail( '' );
        window.location.replace("/login");
    }

    const profileIconClick = () =>
    {
        const ProfileMenuBox = document.getElementsByName( "ProfileMenuBox" );

        ProfileMenuBox[ 0 ].className = ( ProfileMenuBox[ 0 ].className === 'ProfileMenuBox' ) ? 'DivHidden' : 'ProfileMenuBox';
    }

    const ProfileItem = () =>
    {
        return(

            <div>
           
            {planDays<=30 &&  <div className={styles.upgradePlanDiv}><UpgradePlanBanner/></div>}
            <div className='BoardHeaderBtn' onClick={ profileIconClick }>

            
                <img src={ UserProfileIconImg } alt='...'/>
                <div name='ProfileMenuBox' className='DivHidden'>
                    <div className='ProfileMenuBoxItem' onClick={ Logout }>Logout</div>
                </div>
            </div>


             </div>

        );
    }



    const DarkModeProfileItem = () =>{

        return(

            <div className={styles.positionProfile}>
           
            {planDays<=30 &&  <div className={styles.upgradePlanDiv}><UpgradePlanBanner/></div>}


            <div className='BoardHeaderBtn' onClick={ profileIconClick }>
                <img src={ UserProfileIconImg } alt='...'/>
                <div name='ProfileMenuBox' className='DivHidden'>
                    <div className='ProfileMenuBoxItem' onClick={ Logout }>Logout</div>
                </div>
            </div>
    
            <div className="BoardHeaderSandwichMenu" onClick={()=>{props.changeSandwichMenuState()}}>
                <i className={props.isSandwichMenuClosed ? "fa fa-bars" : "fa fa-times"}/>   
            </div>
             </div>

        );



    }



    const UpgradePlanBanner = () =>
    {



        
        return(
            <div className={styles.planData}>
                <div className={styles.planDataContent}>
                    {(planDays<=1 && planDays>0) ? <p>Seu Plano expira em {planDays} dia</p> : (planDays>0)? <p>Seu plano expira em {planDays} dias</p> : <p>Seu plano expirou</p>}
                </div>
                <div className={styles.planDataContent}> 
                    <a target="blank" href={upgradePlanLink} className={styles.planDataContentButton}>Fazer Upgrade</a>
                </div>
                
        
            </div>



        )     
    }

    if( UserStore.getAccType() === 0 || props.page === '/modelrec' || props.page === '/' )
    {
        return(
            <div id={styles.headerMenu}>
                <div className='BoardRightHeader'>

                    {(props.page === '/' && access)? <div className='BoardHeaderOpt BoardHeaderOptSel'>Recomendação de modelo</div>: <></> }




                    <ProfileItem/>


                </div>


                <div className='HorizontalLineSeparator'/>
            </div>
        );
    }
    else if( props.page === '/dashboard' )
    {
        return(
            <div id={styles.headerMenu}>
                <div className='BoardRightHeader'>
                    {/* <div style={{float: 'left', height: '30px'}}>
                        <div className='BoardHeaderSelectorTxt'>Mês:</div>
                        <Select className='BoardHeaderSelector' options={ months } defaultValue={ months[ 0 ] }/>
                        <div className='BoardHeaderSelectorTxt'>Ano:</div>
                        <Select className='BoardHeaderSelector' options={ years } defaultValue={ years[ 0 ] }/>
                    </div> */}
                    <ProfileItem/>
                </div>
                <div className='HorizontalLineSeparator'/>
            </div>
        );
    }
    else if( ( props.page === '/cashflow' ) || ( props.page === '/monthcashflow' ) ||  ( props.page === '/revenues' ) || ( props.page === '/expenses' ) || ( props.page === '/taxes' ) || ( props.page === '/growth' ) || ( props.page === '/traction' ) || ( props.page === '/familiarcashflow' ) )
    {
        return(
            <div id={styles.headerMenu}>
                <div className='BoardRightHeader'>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('cashflow'); setNewSelectedSideMenu('cashflow'); UserStore.setSelectedPageStore('cashflow'); setNewSelectedPage('cashflow')}} to='/cashflow'><div className={ ( props.page === '/cashflow' ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Fluxo de caixa</div></Link>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('cashflow'); setNewSelectedSideMenu('cashflow'); UserStore.setSelectedPageStore('monthcashflow' ); setNewSelectedPage('monthcashflow' )}} to='/monthcashflow' ><div className={ ( props.page === '/monthcashflow'  ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Fluxo de caixa mensal</div></Link>
                                   <Link onClick={()=>{UserStore.setSelectedMenuStore('cashflow'); setNewSelectedSideMenu('cashflow'); UserStore.setSelectedPageStore('revenues'); setNewSelectedPage('revenues')}} to='/revenues'><div className={ ( props.page === '/revenues' ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Receitas</div></Link>
                    
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('cashflow'); setNewSelectedSideMenu('cashflow'); UserStore.setSelectedPageStore('expenses'); setNewSelectedPage('expenses')}} to='/expenses'><div className={ ( props.page === '/expenses' ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Custos e despesas</div></Link>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('cashflow'); setNewSelectedSideMenu('cashflow'); UserStore.setSelectedPageStore('taxes'); setNewSelectedPage('taxes')}} to='/taxes'><div className={ ( props.page === '/taxes' ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Tributação</div></Link>
                    {/*
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('cashflow'); setNewSelectedSideMenu('cashflow'); UserStore.setSelectedPageStore('journey/'); setNewSelectedPage('/cashflow')}} to='/growth'><div className={ ( props.page === '/growth' ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Crescimento</div></Link>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('cashflow'); setNewSelectedSideMenu('cashflow'); UserStore.setSelectedPageStore('journey/'); setNewSelectedPage('/cashflow')}} to='/traction'><div className={ ( props.page === '/traction' ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Tração</div></Link>
                    
                    */}
     <Link onClick={()=>{UserStore.setSelectedMenuStore('cashflow'); setNewSelectedSideMenu('cashflow'); UserStore.setSelectedPageStore('familiarcashflow'); setNewSelectedPage( 'familiarcashflow')}} to='/familiarcashflow'><div className={ ( props.page === '/familiarcashflow' ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Fluxo de Caixa Familiar</div></Link>
                    
                    <ProfileItem/>
                </div>
                <div className='HorizontalLineSeparator'/>
            </div>
        );
    }
    else if( props.page === '/actionplan' || props.page === '/clients')
    {
        return(
            <div id={styles.headerMenu}>
                <div className='BoardRightHeader'>                <Link onClick={()=>{UserStore.setSelectedMenuStore('actionplan'); setNewSelectedSideMenu('actionplan'); UserStore.setSelectedPageStore('actionplan'); setNewSelectedPage('actionplan')  }} to='/actionplan'><div className={(props.page === '/actionplan') ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt'}>Plano de Ação</div></Link>  
  
    <Link onClick={()=>{UserStore.setSelectedMenuStore('actionplan'); setNewSelectedSideMenu('actionplan'); UserStore.setSelectedPageStore('clients'); setNewSelectedPage('clients')  }} to='/clients'><div className={(props.page === '/clients') ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt'}>Clientes</div></Link>  

                    <ProfileItem/>
                </div>
                <div className='HorizontalLineSeparator'/>
            </div>
        );
    } 
    else if( props.page === '/newjourney' ){
        return(
            <div id={styles.headerMenu}>
                <div className='BoardRightHeader'>
                <div className='BoardRightHeader'>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey'); setNewSelectedPage('journey')}} to='/journey'><div className={ ( props.page === '/journey' ) ? 'BoardHeaderOptPurple BoardHeaderOptSel' : 'BoardHeaderOptPurple' }>Jornada</div></Link>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey/myanswer'); setNewSelectedPage('journey/myanswer')}} to='/journey/myanswer'><div className={ ( props.page === '/journey/myanswer' ) ? 'BoardHeaderOptPurple BoardHeaderOptSel' : 'BoardHeaderOptPurple' }>Minhas Respostas</div></Link>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey/forwho'); setNewSelectedPage('journey/forwho')}} to='/journey/forwho'><div className={ (props.page === '/journey/forwho') ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOptPurple'}>Para quem</div></Link>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey/forwhat'); setNewSelectedPage('journey/forwhat')}} to='/journey/forwhat'><div className={ (props.page === '/journey/forwhat') ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOptPurple'}>O que?</div></Link> 
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey/forchannel'); setNewSelectedPage('journey/forchannel')}} to='/journey/forchannel'><div className={(props.page === '/journey/forchannel') ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOptPurple'}>Canais</div></Link>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey/forhow'); setNewSelectedPage('journey/forhow')  }} to='/journey/forhow'><div className={(props.page === '/journey/forhow') ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOptPurple'}>Como</div></Link>  
                    <ProfileItem/>
                </div>
                </div>
                <div className='HorizontalLineSeparator'/>
            </div>
        );   
    }




    else if( props.page === '/journey' || props.page === '/journey/myanswer' || props.page === '/journey/forwho' || props.page === '/journey/forwhat' || props.page === '/journey/forchannel' || props.page === '/journey/forhow'){
        return(
            <div id={styles.headerMenu}>
                <div className='BoardRightHeader'> 
                <div className='BoardRightHeader'>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey '); setNewSelectedPage('journey')}} to='/journey'><div className={ ( props.page === '/journey' ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Jornada</div></Link>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey/myanswer'); setNewSelectedPage('journey/myanswer')}} to='/journey/myanswer'><div className={ ( props.page === '/journey/myanswer' ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Minhas Respostas</div></Link>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey/forwho'); setNewSelectedPage('journey/forwho')}} to='/journey/forwho'><div className={ (props.page === '/journey/forwho') ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt'}>Para quem</div></Link>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey/forwhat'); setNewSelectedPage('journey/forwhat')}} to='/journey/forwhat'><div className={ (props.page === '/journey/forwhat') ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt'}>O que?</div></Link> 
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey/forchannel'); setNewSelectedPage('journey/forchannel')}} to='/journey/forchannel'><div className={(props.page === '/journey/forchannel') ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt'}>Canais</div></Link>
                    <Link onClick={()=>{UserStore.setSelectedMenuStore('journey'); setNewSelectedSideMenu('journey'); UserStore.setSelectedPageStore('journey/forhow'); setNewSelectedPage( 'journey/forhow')}} to='/journey/forhow'><div className={(props.page === '/journey/forhow') ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt'}>Como</div></Link>  
                    <ProfileItem/>
                </div>
                </div>
                <div className='HorizontalLineSeparator'/>
            </div>
        );
    }
    else if( props.page === '/modelsettings')
    {
        return(
            <div id={styles.headerMenu}>
                <div className='BoardRightHeader'>
                <div className='BoardRightHeader'>
                    <a href='/modelsettings'><div className={ ( props.page === '/modelsettings' ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Minha conta</div></a>
                    { /*
                    
                    <a href='/modelsettings/plan'><div className={ ( props.page === '/modelsettings/plan' )
                    
                    ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Plano</div></a>
                    
                    */}

    
                    <ProfileItem/>
                </div>
                </div>
                <div className='HorizontalLineSeparator'/>
            </div>
        );
    }



    else if( props.page === '/community'  )
    {
        return(
            <div id={styles.headerMenu}>
                <div className='BoardRightHeader'>
                <div className='BoardRightHeader'>
                    <a href='/modelsettings'><div className={ ( props.page === '/community' ) ? 'BoardHeaderOpt BoardHeaderOptSel' : 'BoardHeaderOpt' }>Página Inicial</div></a>

    
                    <ProfileItem/>
                </div>
                </div>
                <div className='HorizontalLineSeparator'/>
            </div>
        );
    }

    else if( props.page === '/videoTrail/introduction' ||   props.page === '/videoTrail/businessTips')
    {
        return(
            <div id={styles.headerMenu}>
                <div className='BoardRightHeader'>
                    <div className='BackOption' onClick={()=>{UserStore.setSelectedPageStore('videoSection'); window.location.replace('/videoSection')}}>&lt; Voltar</div>
                
                        <DarkModeProfileItem/>
    
                </div>
                <div className='HorizontalLineSeparator'/>
            </div>
        );
    }   
               
    
    
    else
    {
        return(
            <div id={styles.headerMenu}>
                <div className='BoardRightHeader'>
                    <ProfileItem/>
                </div>
                <div className='HorizontalLineSeparator'/>
            </div>
        );
    }
}
export default UserBoardHeader;