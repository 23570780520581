import React from 'react'
import styles from './SaleCard.module.sass'
import { EyeIconImg } from '../../../imgsLoader/LoadIconImgs'
import { botconversaWebhook } from '../../../../functions/botconversaWebhook'

const SaleCard = ({product, openPopUp, getProductSales}) => {
  
  const {sProduct: productId, label, target, dQuantity, value} = product

  const restQuantity =    (dQuantity*value - product.done)/value>0 ? (dQuantity*value - product.done)/value : 0
  const targetQuantity = Math.ceil(target/value+restQuantity)

  return (
    <tr>
        <td>{label}</td>
        <td>{targetQuantity  || 0}</td>
        <td>{dQuantity || 0}</td>
        <td><img src={EyeIconImg} alt='' onClick={() => {        botconversaWebhook({action: 'Abriu vendas de um produto'}); openPopUp( { type:'sellHistory', refId:'sellItems', itemTitle:label, itemId:product }); /**/getProductSales(productId)} }/> </td>
    </tr>
  )
}

export default SaleCard