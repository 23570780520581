import React, { useState } from 'react'
import styles from './AmountJourneyCard.module.css'
const AmountJourneyCard = ({title, value, complement}) => {




  return (
    <div className={styles.amountJourneyCard}>
        <h4>{title}</h4>

        {(typeof value === 'number') ?
        <p>{(value>=1000)? value/1000 : value } {(value>=1000)? "mil" : ""} <span style={{fontSize: '18px'}}>{complement}</span></p> : 
        <p style={{fontSize: '12px', fontWeight: '100'}}>{value}</p>



        }

        
    </div>
  )
}

export default AmountJourneyCard