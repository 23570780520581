import { AppContainer, AppArea } from "../components/Styles";
import React from 'react';
import Background from "./../assets/background_purple.png";
import LoginContainer from "../components/loginContainer/LoginContainer";

const PageNotFound = () => {
    return (
        <AppContainer id="app">
                <LoginContainer id="quizContainer" bg={Background}>
                    <AppArea id="login">
                        <div className="login-area">
                            <div className="form-box">
                                Página não encontrada.
                            </div>
                        </div>
                    </AppArea>
                </LoginContainer>
        </AppContainer>
    );
}

export default PageNotFound;