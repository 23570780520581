import React, { useEffect, useState } from 'react'
import styles from './AnswersContainer.module.sass'

import ActivitiesQuestions from '../../../questions/activitiesQuestions'
import CommunicationChannelsQuestions from '../../../questions/communicationsChannelsQuestions' 
import CompetitorsQuestions from '../../../questions/competitorsQuestions' 
import DeliveryChannelQuestions from '../../../questions/deliveryChannelsQuestions' 
import MarketQuestions from '../../../questions/marketQuestions'
import PainQuestions from '../../../questions/painQuestions'
import PartnersQuestions from '../../../questions/partnersQuestions'
import ProductCharacteristicsQuestions from '../../../questions/productCharacteristicsQuestions'
import ResourcesQuestions from '../../../questions/resourcesQuestions'
import SalesChannelQuestions from '../../../questions/salesChannelQuestions'
import TargetPublicQuestions from '../../../questions/targetPublic'
import ValuePropositionQuestions from '../../../questions/valuePropositionQuestions'
import RelationshipChannelQuestions from '../../../questions/relationshipChannelQuestions'
const AnswersContainer = ({component, setComponent, answersStep, openAbout, openAnnotations, nextStep,  redirect, checkChanges, activeConfirmScreen, disableConfirmScreen}) => {


  const changeHeight = ( ) =>{
    const board = document.querySelector('.BoardContent')
    board.style.height = 'calc(100vh - 145px)'
}      




useEffect(()=>{

  if (window.screen.width<800) {
    changeHeight()    
  }




})
  return (
    <div className={styles.answersContainer}>

        {answersStep === 'ACTIVITIES' && <ActivitiesQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'COMMUNICATION_CHANNELS' && <CommunicationChannelsQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'COMPETITORS' && <CompetitorsQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'DELIVERY_CHANNELS' && <DeliveryChannelQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'MARKET' && <MarketQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'PAIN' && <PainQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'PARTNERS' && <PartnersQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'PRODUCTS_CHARACTERISTICS' && <ProductCharacteristicsQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'RELATIONSHIP_CHANNEL' && <RelationshipChannelQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'RESOURCES' && <ResourcesQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'SALES_CHANNEL' && <SalesChannelQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'TARGET_PUBLIC' && <TargetPublicQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}
        {answersStep === 'VALUE_PROPOSITION' && <ValuePropositionQuestions checkChanges={checkChanges} activeConfirmScreen={activeConfirmScreen} disableConfirmScreen={disableConfirmScreen} redirect={redirect}/>}


                        <div className={styles.videoOptions}>
                <div className={styles.option } onClick={openAbout}><span>Sobre</span></div>                
                
                <div className={styles.option }><span onClick={openAnnotations}>Anotações</span></div>                
                
                {answersStep==='PRODUCTS_CHARACTERISTICS' ? 
                
                <div style={{background: '#571D94'}} onClick={redirect} className={styles.nextStepOption }><span>Salvar e Continuar</span></div> :  
                
                <div style={{background: '#571D94'}} onClick={nextStep} className={styles.nextStepOption }><span>Salvar e Continuar</span></div> }
                
                
                
            </div>



    </div>
  )
}

export default AnswersContainer