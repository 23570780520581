import React from 'react'
import styles from './ModelRecForm.module.sass'
import { CProgress, CProgressBar } from '@coreui/react'
import { DProducts, DServices } from '../../imgsLoader/LoadIconImgs'
import { useState } from 'react'
import { stepOpt } from '../../library/NewData'
const ModelRecForm = () => {

    const title = 'Você vende produto, serviço ou os dois?'

    const percent = 100
    const description = ' Um dos elementos principais de um modelo de negócio é entender o que vendemos para os consumidores. E isso pode se dar de duas maneiras: como produto, que pode ser algo físico, como um carro, um celular ou um par de sapatos, que pode ser tocado e possuído. Ou um serviço, que diferentemente de um produto físico, não pode ser tocado ou possuído. Alguns exemplos são cortes de cabelo, reparos de automóveis'

    const allOptions = stepOpt

    const [currentStep, setCurrentStep] = useState(0)
    const [thisStepOpt, setThisStepOpt] = useState(stepOpt[currentStep])
    const nextStep = () =>{
        if (currentStep<15) {
          setCurrentStep(prev=>prev+1)

          setThisStepOpt(currentStep+1)
        }



    }

    const prevStep = () =>{
        if (currentStep>0) {
          setCurrentStep(prev=>prev-1)

          setThisStepOpt(currentStep-1)
        }



    }
  return (
    <div className={styles.modelRecForm}> 
        <div className={styles.content}>

            <div className={styles.stepNumber}>{currentStep}</div>
            <div className={styles.topInfo}>
                <h2>{title }</h2>
                <div>
                  
                  
                  






                  <div className={styles.rightInfo}>
                  <div className={styles.checked}>
                    <i className='fa fa-check'/>   
                    </div>

                    <div className={styles.percentInfo}>

                      <div className={styles.percentData}>
                        <span>{percent}%</span>
                        <p>Progresso</p>   
                      </div>
                    
                      <div className={styles.progressBar}><div className={styles.innerBar} style={{width: `${percent}%`}}></div></div>
                      
                    </div>   
                  </div>
                    </div>
            </div>







            <div className={styles.description}>
                <p>{description}</p>


            </div>



            <div className={styles.options}>
                {thisStepOpt && thisStepOpt.map((option, index)=>(   
                    <>
                      {index>0 &&
                        <div className={styles.unSelectedOption}>
                          <div className={styles.select}>
                            <i className="fa fa-check"/>
                          </div>
                          
                          
                          
                          <img src={option[0].icon} alt=''/>
                          
                          
                          
                          <p>{option[0].title}</p>
                          
                          
                          
                        </div>}</>  
                ) )}


            </div>







            <div className={styles.buttons}>
                <div className={styles.nextBtn} onClick={nextStep}>Próximo</div>
                <div className={styles.prevBtn} onClick={prevStep}><i className="fa fa-less-than"/>Voltar</div>

            </div>
        </div>
    </div>
  )
}

export default ModelRecForm