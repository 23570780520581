import UserStore from './store/userStore';
import React, { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Login from './pages/Login';
import UserBoard from './pages/UserBoard';
import PageNotFound from './pages/PageNotFound';
import RegisterPage from './pages/RegisterPage';
import GetPremiumPlan from './components/getPremiumPlan/GetPremiumPlan';
import RecoverPasswordPage from './pages/RecoverPasswordPage';
import OTPCodePage from './pages/OTPCodePage';
import UserPlanStore from './store/userPlanStore';

import Modatta from './pages/Modatta/Modatta';
import Welcome from './components/welcome/Welcome';  
import SelectModel from './components/selectModel/SelectModel';
import GetPlan from './pages/getPlan/GetPlan';



//TODO: When loading imgs on LoadBrandImgs verify if the img exist, error

function App()
{
    const location = window.location//useLocation();
    let Page = <PageNotFound/>;
    const arrayOfTest = ['/locale?aa', '/locale?ss', '/locale/?aa', '/locale/?ss']
        let found = false;
    
    const [planDays, setPlanDays] = useState('Null')

    useEffect(()=>{
      
        const url = 'https://iuigcj7jka7bblez3rm557uevi0zdgvl.lambda-url.us-east-2.on.aws/'
        const getExpireDate = async() =>{

            await axios.get(url, {params:{ company: UserStore.getUserId(), token: UserStore.getToken()}})
            .then(resp=>{

 


                if (resp.data) {
                 console.log('DATA');console.log(resp.data.products[0].restOfPlanDays)
                    UserPlanStore.setPlanExpireDate(resp.data.products[0].restOfPlanDays)
                      setPlanDays(resp.data.products[0].restOfPlanDays)  


                  const date = new Date()
                  if (date.getDate() !== Number(UserPlanStore.getAPIFetchDate() + 1)) {
                    //console.log(date.getDate())
                    //console.log(UserPlanStore.getAPIFetchDate())
                    UserPlanStore.setAPIFetchDate(date.getDate())                   

                  }
                

                
                         
                }
                }
            )  }

        getExpireDate()
    
    
    
    }


    , [])




    const checkLogin = async() =>
    {


        if( UserStore.isLogged() )
        {
            var userId = UserStore.getUserId();
            var token = UserStore.getToken();
            const resp = await axios.get( 'https://s7vq75vdhmc2tdmzv762gtjoxi0vwfie.lambda-url.us-east-2.on.aws/', { params: { userId: userId, token: token } } );

            if( resp.data.valid )
            {
                if( resp.data.valid === '' )
                {
                    UserStore.setToken( '' );
                    UserStore.setLogged( false );
                    UserStore.setUserId( '' );
                    UserStore.setUserEmail( '' );
                    window.location.replace("/login");
                }
            }
            else
            {
                UserStore.setToken( '' );
                UserStore.setLogged( false );
                UserStore.setUserId( '' );
                UserStore.setUserEmail( '' );
                window.location.replace("/login");
            }
        }
    };
    if( !UserStore.isLogged() )
    {

        
        switch( location.pathname )
        { case '/getPlan':
                    Page = <GetPlan/>
                    break;
            case '/':
               

            case '/login':
                Page = <Login/>;
                break;

                case '/selectmodel':
                    Page = <SelectModel/>
                    break;
            case '/register':
                Page = <RegisterPage/>
                break;
                
            case '/registerpartner':
                Page = <RegisterPage hideRaster={true}/>
                break;



            
            case '/recoverpassword':
                Page = <RecoverPasswordPage/>
                break;
            case '/recoverpassword/otpcode':
                Page = <OTPCodePage hash={location.search}/>
                break;    
            case '/welcome':
                Page = <Welcome/>
                break;
            case '/modatta':
                Page = <Modatta modattaSearch={location.search}/>
                break;
                case '/registerfullmonth':
                    Page = <RegisterPage time={30} hideRaster={true} />
                    break;                case '/registerfullyear':
                    Page = <RegisterPage time={3650} hideRaster={true} />
                    break;                
           default:
                Page = <PageNotFound/>;

             }
    }
    else
    {   
        if(Number(UserPlanStore.getPlanExpireDate())>0){
            switch( location.pathname )
            {
                case '/':
                case '/dashboard':
                case '/modelrec':   
                case '/familiarcashflow':    
                case '/cashflow':
                case '/revenues':
                case '/expenses':
                case '/taxes':
                case '/growth':
                case '/traction':
                case '/actionplan':
                case '/modelsettings':
                case '/modelsettings/plan':  
                case '/newmodelrec':
                case '/oldjourney':
                case '/journey':
                case '/journey/myanswer':
                case '/journey/introduction':
                case '/journey/introduction/video':
                case '/journey/introduction/ourjouneyvideo':
                case '/journey/introduction/furthermaterials':
                case '/journey/forwhom':
                case '/journey/forwhom/video':
                case '/journey/forwhom/questions':
                case '/journey/forwhom/furthermaterials':
                case '/journey/market/video':
                case '/journey/market/questions':
                case '/journey/market/furthermaterials':
                case '/journey/public/video':
                case '/journey/public/questions':
                case '/journey/public/furthermaterials':
                case '/journey/competitors/video':
                case '/journey/competitors/questions':
                case '/journey/competitors/furthermaterials':
                case '/journey/what':
                case '/journey/what/video':
                case '/journey/what/questions':
                case '/journey/productandservices/video':
                case '/journey/productandservices/questions':
                case '/journey/productandservices/furthermaterials':
                case '/journey/valueproposition/video':
                case '/journey/valueproposition/questions':
                case '/journey/valueproposition/furthermaterials':
                case '/journey/distributionchannel/video':
                case '/journey/communicationchannel/video':
                case '/journey/communicationchannel/questions':
                case '/journey/communicationchannel/furthermaterials':
                case '/journey/saleschannel/video':
                case '/journey/saleschannel/questions':
                case '/journey/saleschannel/furthermaterials':
                case '/journey/deliverychannel/video':
                case '/journey/deliverychannel/questions':
                case '/journey/deliverychannel/furthermaterials':
                case '/journey/relationshipchannel/video':
                case '/journey/relationshipchannel/questions':
                case '/journey/relationshipchannel/furthermaterials':
                case '/journey/how':
                case '/journey/how/video':
                case '/journey/activities/video':
                case '/journey/activities/questions':
                case '/journey/activities/furthermaterials':
                case '/journey/partners/video':
                case '/journey/partners/questions':
                case '/journey/partners/furthermaterials':
                case '/journey/resources':
                case '/journey/resources/video':
                case '/journey/resources/questions':
                case '/journey/resources/furthermaterials':
                case  '/journey/forwhom/introduction':
                case '/journey/forwho':
                case '/journey/forwhat':
                case '/journey/forchannel':
                case '/journey/forhow':

                case '/newjourney': 



                case '/clients':
                case '/monthcashflow':
                case '/videoSection':   
                case '/videoTrail':
                case '/videoTrail/introduction':
                case '/videoTrail/businessTips':
                case '/videoTrail/cibele':

                case '/videoTrail/claudio':




                case '/community':
                    Page = <UserBoard page={ location.pathname }/>;
                    break;
                case '/login':
                    Page = <UserBoard page={ '/' }/>;
                    break;
                case '/register':
                    Page = <RegisterPage/>
                    break;
                case '/registerpartner':
                    Page = <RegisterPage hideRaster={true}/>
                    break;
                case '/selectmodel':
                    Page = <SelectModel/>
                    break;   
                case '/welcome':
                    Page = <Welcome/>
                    break;



                case '/getPlan':
                    Page = <GetPlan/>
                    break;
                default:
                    Page = <PageNotFound/>;
        }
    }
    else if(UserPlanStore.getPlanExpireDate()<=0){

        switch(location.pathname){
            case '/':
            case '/modelrec':   
            case '/modelsettings':
            case '/modelsettings/plan':
                Page = <UserBoard page={ location.pathname }/>
            break;




            
                case '/dashboard':
                case '/familiarcashflow':    
                case '/cashflow':
                case '/revenues':
                case '/expenses':
                case '/taxes':
                case '/growth':
                case '/traction':
                case '/actionplan':   
                case '/journey':
                case '/journey/myanswer':
                case '/journey/introduction':
                case '/journey/introduction/video':
                case '/journey/introduction/ourjouneyvideo':
                case '/journey/introduction/furthermaterials':
                case '/journey/forwhom':
                case '/journey/forwhom/video':
                case '/journey/forwhom/questions':
                case '/journey/forwhom/furthermaterials':
                case '/journey/market/video':
                case '/journey/market/questions':
                case '/journey/market/furthermaterials':
                case '/journey/public/video':
                case '/journey/public/questions':
                case '/journey/public/furthermaterials':
                case '/journey/competitors/video':
                case '/journey/competitors/questions':
                case '/journey/competitors/furthermaterials':
                case '/journey/what':
                case '/journey/what/video':
                case '/journey/what/questions':
                case '/journey/productandservices/video':
                case '/journey/productandservices/questions':
                case '/journey/productandservices/furthermaterials':
                case '/journey/valueproposition/video':
                case '/journey/valueproposition/questions':
                case '/journey/valueproposition/furthermaterials':
                case '/journey/distributionchannel/video':
                case '/journey/communicationchannel/video':
                case '/journey/communicationchannel/questions':
                case '/journey/communicationchannel/furthermaterials':
                case '/journey/saleschannel/video':
                case '/journey/saleschannel/questions':
                case '/journey/saleschannel/furthermaterials':
                case '/journey/deliverychannel/video':
                case '/journey/deliverychannel/questions':
                case '/journey/deliverychannel/furthermaterials':
                case '/journey/relationshipchannel/video':
                case '/journey/relationshipchannel/questions':
                case '/journey/relationshipchannel/furthermaterials':
                case '/journey/how':
                case '/journey/how/video':
                case '/journey/activities/video':
                case '/journey/activities/questions':
                case '/journey/activities/furthermaterials':
                case '/journey/partners/video':
                case '/journey/partners/questions':
                case '/journey/partners/furthermaterials':
                case '/journey/resources':
                case '/journey/resources/video':
                case '/journey/resources/questions':
                case '/journey/resources/furthermaterials':
                case  '/journey/forwhom/introduction':
                case '/journey/forwho':
                case '/journey/forwhat':
                case '/journey/forchannel':
                case '/journey/forhow':
                case '/videoSection':   
                    Page = <UserBoard page="/getpremium"/>
                 break;
                 case '/selectmodel':
                    Page = <SelectModel/>
                    break;                       
                case '/getPlan':
                    Page = <GetPlan/>
                    break;            
        default:
            Page = <PageNotFound/>
            break;
        }





    }



    }
    useEffect( () => { checkLogin(); }, [] );
    
    return( Page );
}

export default App;