import React from "react";
import { CProgress, CProgressBar } from '@coreui/react'
import "../journey/JourneyKnowingYourModel.css";
import menuJourney from "../../assets/icons/iconMenuJourney.png";
import JourneysideMenu from "./JourneysideMenu";
import styles from "./JourneyAll.module.css";
import axios from "axios";
import { useEffect } from "react";
import UserStore from "../../store/userStore";
import MobileKYMStructure from "./mobileJourney/mobileKYMStructure/MobileKYMStructure";
import { botconversaWebhook } from "../../functions/botconversaWebhook";

const JourneyKYMWhat = () => {

     
    

    const pageLocale = 'Jornada'
    useEffect(()=>{

        botconversaWebhook({locale: pageLocale, action: 'Abriu O Que?'})
        const postStep = async(step) =>{
            await axios.put('https://iz5smq7lxhsxjtskiegdcibiru0vwhjh.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), token: UserStore.getToken(), journeyStep: step})
        }
        postStep(19) 
    }, [])    

    const OpenSideDiv = ()=>{
        let sideDiv = document.getElementById('journey1SideDiv');
        sideDiv.style.display='block';
    }

    const screenWidth = window.screen.width

    const type = 'Entendendo seu modelo de negócio'
    const title = 'O Quê'
    const content = 'Uma vez identificado o <u><b>segmento</b></u> onde a <u><b>ideia</b></u> cria uma solução que resolve um <u><b>problema</b></u> ou <u><b>necessidade</b></u>, vamos desenvolver <u><b>O Quê</b></u>. O “<b><u>O Quê</u></b> ” é a parte na qual se desenvolve dois importantes componentes: Proposta de valor e Descrição dos produtos e serviços. '
    const stepPercent  = 39
    const prevVideoPath = '/journey/competitors/questions'
    const nextVideoPath = '/journey/what/video'
    return(

        <>

        {screenWidth>780 ? 
        <div className="journeyKYMBackground">
            <img src={menuJourney} alt-='' className="journeyKYMIconMenu" onClick={OpenSideDiv}>
            </img>
            <div style={{position:'relative',
                        top: '1.7rem',
                        left: '6.8rem',
                        width:'12rem'}}>
                <text className="journeyKYMHeader">{type}</text>
            </div>
            <div className="journeyKYMTextBackground" style={{position:'relative', left:'6.5rem', top:'15rem'}}>
                <text className="journeyKYMTitleBlurDiv">{title}</text>
                <div style={{
                            width:'26rem',marginTop:'1rem'
                            }}>
                <text className="journeyKYMText" style={{fontSize:'1.1rem'}} dangerouslySetInnerHTML={{ __html: content}}>

                </text>
                </div>  
            </div>
            <div className="journeyKYMVideoDiv" style={{top:'17rem'}}>
                <a href={nextVideoPath} className="journeyKYMlink" style={{textDecoration:'none'}}>
                    <div style={{display:'flex',
                                flexDirection:'column',
                                marginLeft:'1.5rem',
                                marginTop:'0.8rem',
                                marginBottom:'0.8rem'}}>
                        <text className="journeyKYMVideoUpperTxt">Próximo conteúdo</text>
                        <text className="journeyKYMVideoLowerTxt">Vídeo</text>
                    </div>
                        <div className="journeyKYMArrowDiv">
                            <text className="journeyKYMArrow">&#8250;</text>
                        </div>
                </a>
            </div>    
            <text className="journeyKYMprogressText" style={{position: 'absolute', top:'62px', right: '188px'}}>
                Seu progresso
            </text>      
            <div style={{position:'absolute',
                        top:'65px',
                        right:'88px',
                        paddingLeft:'0.4rem',
                        paddingRight:'0.4rem',
                        borderRadius:'0.3rem',
                        backgroundColor:'#FFFFFF',
                        width:'fit-content'}}>
                <text className="journeyKYMprogressBarText">
                    {stepPercent}% completo
                </text>
            </div>
            <div style={{position:'absolute',
                        top:'93px',
                        right:'10px',
                        width:'20rem'}}>
                <CProgress height={ 6 }  >
                    <CProgressBar value={ stepPercent } />
                </CProgress>
            </div>
            <a href={prevVideoPath} className={styles.backArrow} style={{textDecoration:'none'}}>
                <div className="backButton1" style={{top:'13rem'}}>
                    <text className="textBackButton1">&#8249;</text>
                </div>
            </a>
            <JourneysideMenu></JourneysideMenu>
        </div>
        
         : 
         <MobileKYMStructure  type={type} title={title} content={content} stepPercent={stepPercent} prevVideoPath={prevVideoPath} nextVideoPath={nextVideoPath} OpenSideDiv={OpenSideDiv}/>
         }
        
        </>
    )
}

export default JourneyKYMWhat;