import axios from "axios"
import UserStore from "../store/userStore"

export const botconversaWebhook = async({locale, email, action}) =>{

  console.log(UserStore.getUserPhone())
    const getLocale = () =>{
        switch (locale) {

          case 'Cadastro': 
            const currentDate = new Date()
            return {Cadsatro: action, email: email, phone: formattedPhone, Novo_Usuario: 'Sim', Data_Cadastro: `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`}
          case 'Diagnóstico':
            return {Diagnostico: action, name: UserStore.getUserName(), email: UserStore.getUserEmail(), phone: formattedPhone}
          case 'Gestão Financeira':
            return {Jornada: action, name: UserStore.getUserName(), email: UserStore.getUserEmail(), phone: formattedPhone}
  
          case 'Painel De Indicadores':
            return {Jornada: action, name: UserStore.getUserName(), email: UserStore.getUserEmail(), phone: formattedPhone}
    
          case 'MEconecte Flix':
            return {Jornada: action, name: UserStore.getUserName(), email: UserStore.getUserEmail(), phone: formattedPhone}

          case 'Jornada':
            return {Jornada: action, name: UserStore.getUserName(), email: UserStore.getUserEmail(), phone: formattedPhone}
  
        




          case 'Gestão de Vendas':
            return {Gestao_De_Vendas: action, name: UserStore.getUserName(), email: UserStore.getUserEmail(), phone: formattedPhone}
          default:
            break;
        }



    }
    const formattedPhone = UserStore.getUserPhone().replace('(', '').replace(')', '').replace('-', '').replace(' ', '').replace(' ', '')
    const params = getLocale()

    

    console.log(typeof params) 
   // params[]
    console.log(params)


    const url = 'https://backend.botconversa.com.br/api/v1/webhooks-automation/catch/64231/OnraahUpYENu/'
    await axios.post(url, params, {
        headers: {
          'Content-Type': 'application/json'
        }
    }).then(resp=>console.log(resp))  
}