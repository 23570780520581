import { useState } from 'react';
import styles from './ConfirmChangePage.module.css';
import { useEffect } from 'react';
import { IconRemoveExpense } from '../imgsLoader/LoadIconImgs';

export const ConfirmChangePage = (props) =>{
    const [secondButton, setSecondButton] = useState(false);
    useEffect(()=>{
        setSecondButton(props.moreButtons);

    }, [props.moreButtons]);



    return(
        <div className={styles.confirmChangePage}>

            <div>

                <div>
                    <div><img src={IconRemoveExpense} alt="Confirmado"/></div>
                    <div><p>{props.message}</p></div>
                    
                </div>
                <div>
                    <button className={styles.confirmAddButton} onClick={props.onClick}>Salvar</button>
                    {secondButton && <button
                    className={styles.confirmAddButton} 
                    style={{color: 'rgb(46, 53, 88)', fontWeight: 'bold', backgroundColor: 'white', borderColor: 'rgb(46,53,88)'}}
                    onClick={props.closeWarn}
                    >Descartar</button>
                    }
                </div>
                

            </div>
        </div>



    )


}