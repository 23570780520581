import React from 'react'
import styles from './IntroCard.module.sass'
import Background from '../../../../assets/new_background.png'
import UserStore from '../../../../store/userStore'



const IntroCard = ({step, title, topicList, cover}) => {

  const thisTopicList = topicList[0][0]
  const redirectTo = ( ) =>{

    UserStore.setSelectedPageStore('newjourney')
    window.location.replace(`newjourney?=${thisTopicList.url}`)   
}
  return (
    <div className={styles.introCard}>
            <img src={Background} alt='' className={styles.background}/>        
        <div className={styles.right}>
            <div className={styles.rightContent}>   
                <p className={styles.title}>{title}</p>  
                <span className={styles.step}>{step}º passo</span>   
            </div>   
         </div>






        <div className={styles.left}>
            <img src={cover} alt='' className={styles.cover}/>

            <div className={styles.groupButtons}> 
              <div className={styles.buttons}>

                <div className={styles.contentButton}>
                    <img src={thisTopicList.icon} alt='' className={styles.icon}/>
                    <p>{thisTopicList.title}</p>
                    <span>{thisTopicList.description}</span>

                </div>
                <button className={styles.accessButton} onClick={redirectTo}>Iniciar</button>
              </div>
            </div>                       
        </div> 

    </div>
  )
}

export default IntroCard