import React from "react";
import "../questions/questions.css";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import axios from "axios";
import { useState,useEffect } from "react";
import UserStore from "../../store/userStore";
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import { FailAddElement } from "../failAddElement/FailAddElement";

const CommunicationsChannelsQuestions = (props)=>{


    const [ journeyData, setJourneyData] = useState( null );
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ socialMediaArray, setSocialMediaArray ] = useState([]);
    const [ haveError, setHaveError ] = useState(false)





    const allAvaliableSocialMedia = [
        "site",
        "email",
        "blog",
        "jornais",
        "revistas",
        "rádio",
        "televisão",
        "facebook",
        "instagram",
        "twitter",
        "linkedIn",
        "youTube",
        "pinterest",
        "snapchat",
        "tiktok",
        "reddit",
        "whatsapp",
        "wechat",
        "tumblr",
        "flickr",
        "quora",
        "twitch",
        "discord",
        "slack",
        "telegram",
        "eventos",




        "conferências"

        ]



    const LoadJourneyAnswers = async() =>{
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );

        if(resp.data.What )
        {    



            console.log(resp.data)
            setJourneyData(resp.data.What);

            setSocialMediaArray(resp.data.What.jwChannelReach.toLowerCase());


            const channelReach = resp.data.What.jwChannelReach.split(", ")

            console.log(channelReach)
            channelReach.forEach(channel=>{

                    

                    console.log(channel)
                    document.querySelector(`#${channel}Channel`).checked = true        



            })

            
        }
    }

    const saveAnswers = async()=>{

        setLoading(true)
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData = 
        {
            jwChannelReach:socialMediaArray, 
            jwChannelWay:journeyData.jwChannelWay, 
            jwChannelLiking:journeyData.jwChannelLiking,
        };

        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'What', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })
    
        document.getElementById( 'saveBtn' ).innerHTML = "Salvar"; 
    }


    const changetext =(e,source,choice)=>{

        switch (source){

            case 'text1':{
                if(journeyData.jwChannelReach !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwChannelReach : e.target.value})         
            }
            break;
            case 'text2':{
                if(journeyData.jwChannelWay !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwChannelWay : e.target.value})
            }
            break;
            case 'text3':{
                if(journeyData.jwChannelLiking !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jwChannelLiking : e.target.value})
            }

            break;
        }
        
    }

    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }




    const verifyCheckboxValue = (e) =>{
        let newArray = [];
        let elementFound = false;

        socialMediaArray.split(', ').forEach((media, index)=>{


            newArray.push(media)
            if (media===e.target.value.toString()) {

                elementFound = true
                if (!e.target.checked) {

                    newArray.pop()
                } 
            }





           

        

        })
            if (!elementFound) {
                newArray.push(e.target.value.toString())
            }        
        
        
        let newArrayString = newArray[0]
            newArray.forEach((e, index) =>{
                if (index!==0) {
            newArrayString += ', ' + e                     
                }
    
            })
                //console.log(e.target.value)

        if(socialMediaArray !== newArrayString && props.checkChanges !== "-1"){props.checkChanges(true)}
        setSocialMediaArray(newArrayString)
        console.log(newArrayString)


    }











    
    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()
    


    }










    
    useEffect( () => { LoadJourneyAnswers()}, [] );

    
    return(
        <div className="journeyFWQQuestionsLayout1">   
        {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>} 
        {loading && <LoadingScreen/>}
        {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>}
        {props.activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.redirect}/>}
    

               <div className="journeyQuestionsSaveBtn"  id='saveBtn' onClick={saveAnswers}>
                    Salvar
                </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Através de quais canais seu cliente pode ser alcançado?
                                                    </text>
<QuestionsInformation information="Selecione os canais com base nas preferências e comportamentos de uso de mídia do seu público-alvo."/>



{/*  */} 
                        <div style={{marginTop: '10px'}}>                 
                            
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="siteChannel" name="site" value="site"/>
                          <label htmlFor="siteChannel">Site</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="emailChannel" name="email" value="email"/>
                          <label htmlFor="emailChannel">Email</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="blogChannel" name="blog" value="blog"/>
                          <label htmlFor="blogChannel">Blog</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="jornaisChannel" name="jornais" value="jornais"/>
                          <label htmlFor="jornaisChannel">Jornais</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="revistasChannel" name="revistas" value="revistas"/>
                          <label htmlFor="revistasChannel">Revistas</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="radioChannel" name="radio" value="radio"/>
                          <label htmlFor="radioChannel">Rádio</label><br/>   
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="televisaoChannel" name="televisao" value="televisao"/>
                          <label htmlFor="televisaoChannel">Televisão</label><br/>    
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="emailChannel" name="email" value="email"/>
                          <label htmlFor="emailChannel">Email</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="facebookChannel" name="facebook" value="facebook"/>
                          <label htmlFor="facebookChannel">Facebook</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="instagramChannel" name="instagram" value="instagram"/>
                          <label htmlFor="instagramChannel">Instagram</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="twitterChannel" name="twitter" value="twitter"/>
                          <label htmlFor="twitterChannel">Twitter</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="linkedinChannel" name="linkedin" value="linkedin"/>
                          <label htmlFor="linkedinChannel">LinkedIn</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="youtubeChannel" name="youtube" value="youtube"/>
                          <label htmlFor="youtubeChannel">Youtube</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="pinterestChannel" name="pinterest" value="pinterest"/>
                          <label htmlFor="pinterestChannel">Pinterest</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="snapchatChannel" name="snapchat" value="snapchat"/>
                          <label htmlFor="snapchatChannel">Snapchat</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="tiktokChannel" name="tiktok" value="tiktok"/>
                          <label htmlFor="tiktokChannel">TikTok</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="redditChannel" name="reddit" value="reddit"/>
                          <label htmlFor="redditChannel">Reddit</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="whatsappChannel" name="whatsapp" value="whatsapp"/>
                          <label htmlFor="whatsappChannel">WhatsApp</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="wechatChannel" name="wechat" value="wechat"/>
                          <label htmlFor="wechatChannel">WeChat</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="tumblrChannel" name="tumblr" value="tumblr"/>
                          <label htmlFor="tumblrChannel">Tumblr</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="flickrChannel" name="flickr" value="flickr"/>
                          <label htmlFor="flickrChannel">Flickr</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="quoraChannel" name="quora" value="quora"/>
                          <label htmlFor="quoraChannel">Quora</label><br/>                          
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="twitchChannel" name="twitch" value="twitch"/>
                          <label htmlFor="twitchChannel">Twitch</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="discordChannel" name="discord" value="discord"/>
                          <label htmlFor="discordChannel">Discord</label><br/> 
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="slackChannel" name="slack" value="slack"/>
                          <label htmlFor="slackChannel">Slack</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="githubChannel" name="github" value="github"/>
                          <label htmlFor="githubChannel">Github</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="adsChannel" name="ads" value="ads"/>
                          <label htmlFor="adsChannel">Ads</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="telegramChannel" name="telegram" value="telegram"/>
                          <label htmlFor="telegramChannel">Telegram</label><br/>                          
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="eventosChannel" name="eventos" value="eventos"/>
                          <label htmlFor="eventosChannel">Eventos</label><br/>
                          <input type="checkbox" onChange={e=>verifyCheckboxValue(e)} id="conferenciasChannel" name="conferencias" value="conferencias"/>
                          <label htmlFor="conferenciasChannel">Conferências</label><br/> 



                    </div>





















                    </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Em quais desses canais a sua empresa pode focar no momento?
                            <QuestionsInformation breakLine={true} information="Pense na construção de conteúdo para essas redes. E como sua empresa vai abordar isso. É importante compreender o perfil, comportamento e  preferências do público-alvo, os objetivos de comunicação, os recursos disponíveis e as características de cada canal.
                            <br/>Analisar as preferências e comportamentos do público-alvo, bem como a concorrência, ajuda a identificar os canais mais relevantes. É importante testar e analisar os resultados para fazer ajustes conforme necessário. Concentre-se nos canais que agregam valor e atingem efetivamente o público-alvo para uma estratégia de comunicação bem-sucedida."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea2' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwChannelWay : '---'} onChange={(e) => changetext(e,'text2')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea2')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea2')}></img>
                        </div>
                    </div>
                  {/*  <div className="journeyFWQQuestionsLayout2" style={{marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText">Os canais de comunicação escolhidos batem com a forma como os clientes gostariam de ser alcançados?</text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jwChannelLiking : '---'} onChange={(e) => changetext(e,'text3')} 
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea3')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea3')}></img>
                        </div>
                    </div>
                 */}</div>
    )
}

export default CommunicationsChannelsQuestions;