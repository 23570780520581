import React from 'react'
import styles from './SalesContainer.module.sass'
import SaleCard from '../saleCard/SalesCard'
  
const SalesContainer = ({monthSales, openPopUp, getProductSales, selectedProductSales}) => {



  return (
    <table className={styles.salesContainer}>
        <thead>
          <tr>
            <th>Produto</th>
            <th>Meta Prevista (Mês)</th>
            <th>Meta Realizada (Mês)</th>
            <th></th>
          </tr> 
        </thead>
        <tbody>
            {monthSales && monthSales.map(sale=><SaleCard product={sale} openPopUp={openPopUp} getProductSales={getProductSales} selectedProductSales={selectedProductSales}/>)}
  
        </tbody>


    </table>
  )
}

export default SalesContainer