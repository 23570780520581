import { CompleteBlueCheck } from '../imgsLoader/LoadIconImgs';
import { AddElementLayout } from '../addElementLayout/AddElementLayout';
export const SuccessAddElement = (props) =>{
const message = "Custo Adicionado Com Sucesso";
    return(
        <div>
            <AddElementLayout message={props.message} image={CompleteBlueCheck} onClick={props.onClick}/>
        </div>
    )
}


