import React from 'react'
import styles from './VideoOptions.module.css';
import { useState } from 'react';
import AboutVideoOption from '../aboutVideoOption/AboutVideoOption';
import CommentsVideoOption from '../commentsVideoOption/CommentsVideoOption';
import MaterialsVideoOption from '../materialsVideoOption/MaterialsVideoOption';
import AnnotationsVideoOption from '../annotationsVideoOption/AnnotationsVideoOption';
import { useEffect } from 'react';
import axios from 'axios';


//

export const VideoOptions = ({materialsArray, isLoading, newAnnotation, putNewAnnotation, changeNewAnnotationValue, annotationsArray, favouriteVideo, haveFavourited, videoStep, videoTitle, videoDescription, videoCode}) => {
  const allOptions = ['Sobre', 'Materiais', 'Anotações']
  const [selectedOptionPosition, setSelectedOptionPosition] =  useState(0)
  const [videoShareLink, setVideoShareLink] =  useState('url')  
  



  //console.log('1VDF');console.log(materialsArray);


  return (
    <div className={styles.videoOptions}>
                 
                 <div className={styles.videoContentOptions}>   
                    {allOptions.map((option, index)=>(
                    <div 
                    key={index} 
                    className={(selectedOptionPosition===index) ? styles.activeOption : styles.unactiveOption } 
                    onClick={()=>setSelectedOptionPosition(index)}>
                        {option}   
                    </div>
                    ))}
                 </div> 
                <div className={styles.videoOptionsDescription}>
                    {selectedOptionPosition===0 && <AboutVideoOption favouriteVideo={favouriteVideo} haveFavourited={haveFavourited} videoCode={videoCode} videoStep={videoStep} videoTitle={videoTitle} videoShareLink={videoShareLink} videoDescription={videoDescription}/>}
                    {/*selectedOptionPosition===1 && <CommentsVideoOption/>*/}                    
                    {selectedOptionPosition===1 && <MaterialsVideoOption materialsArray={materialsArray}/>}                    
                    {selectedOptionPosition===2 && <AnnotationsVideoOption isLoading={isLoading} newAnnotation={newAnnotation} putNewAnnotation={putNewAnnotation} changeNewAnnotationValue={changeNewAnnotationValue} videoCode={videoCode} annotationsArray={annotationsArray}/>}                    
               
                    


                </div>

      
    </div>
  )
}
