import React from 'react'
import styles from './DashboardFinancial.module.sass'
import { Bar } from 'react-chartjs-2'
import { useEffect } from 'react'
import { useState } from 'react'

const DashboardFinancial = ({showDiv, revenuesCostsChart, options, revenuesCostsData, costCoverageChart, costCoverageData, hideDiv}) => {
    console.log('costData')
    console.log(costCoverageData)
    console.log('costChart')
    console.log(costCoverageChart)
    console.log('costOptions')
    console.log(options)

    

  return (
    <div className={styles.dashboardFinancial}>
                                        <div className="DashWidgetDiv3">
                    <div style={{display: "flex", flexDirection:'row', gap:'0.9rem'}}>
                        <div className="DashComponentsTitlesBlack">Evolução das receitas / custos mês</div>
                        
                    </div>
                    <Bar ref={ revenuesCostsChart } options={ options } data={ revenuesCostsData}/>
                </div>
                <div className="DashWidgetDiv3" >
                    <div style={{display: "flex", flexDirection:'row', gap:'0.5rem'}}>
                        <div className="DashComponentsTitlesBlack">Cobertura de custos mês</div>
                        
                    </div>
                    <Bar ref={ costCoverageChart } options={ options } data={ costCoverageData } style={ { width: '100%' }}/>
                </div>



    </div>
  )
}

export default DashboardFinancial