import { isNaN } from "formik"
import { removeLeftNullNumbers } from "./removeLeftNullNumbers"

export const convertTimeFormat = (time) => {

  const splitedTime = time.split(':')
  let arrayOfTime = []
  let definer = false
  splitedTime.forEach((timeStep, index)=>{
    if (Number(timeStep)===0) {
        if (Number(timeStep[index-1]===0 )|| isNaN(Number(timeStep[index-1])) ) {
            
        } else {
            arrayOfTime.push(timeStep)
        }
    }
    else{    
        arrayOfTime.push(timeStep)    
    }

  })

  const newTime = removeLeftNullNumbers(arrayOfTime.join(':')) 

  return newTime
}