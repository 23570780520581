export const calculateAliquote = function( revenue, rbt12, aliquotTable )
{
    var deductValue = aliquotTable[ aliquotTable.length - 1 ].aDeductValue;
    var fixedDiscount = aliquotTable[ aliquotTable.length - 1 ].aFixedDiscount;
    var nominalAliquote = aliquotTable[ aliquotTable.length - 1 ].aValue;
    var tax = 0;

    for( let index = 0; index < aliquotTable.length; index++ )
    {
        if( rbt12 > aliquotTable[ index ].aLowerLimit && rbt12 <= aliquotTable[ index ].aUpperLimit )
        {
            nominalAliquote = aliquotTable[ index ].aValue;
            deductValue = aliquotTable[ index ].aDeductValue;
            fixedDiscount = aliquotTable[ index ].aFixedDiscount;
            break;
        }
    }

    if( rbt12 == 0 )
    {
        tax = revenue * nominalAliquote + fixedDiscount;
    }
    else
    {
        tax = revenue * ( ( ( rbt12 * nominalAliquote ) - deductValue ) / rbt12 ) + fixedDiscount;
    }

    return tax;
}