
import React, { useEffect, useState } from 'react';
import MeLogo from "./../assets/icons/meLogo.png";
import "../pages/RegisterPage.css";
import axios from 'axios';
import { StringMask } from '../components/library/StringMasks';
import TagManager from 'react-gtm-module';
import { FailAddElement } from '../components/failAddElement/FailAddElement';
import { SuccessAddElement } from '../components/successAddElement/SuccessAddElement';
import LoadingScreen from '../components/loadingScreen/LoadingScreen';
import UserStore from '../store/userStore';
import { botconversaWebhook } from '../functions/botconversaWebhook';

var md5 = require( 'md5' );

const RegisterPage = (props) =>
{


    console.log(props.time)
    const {hideRaster} = props
//d2wl1u9vv4g88g.cloudfront.net
    const showOverflow = () =>{
        const html = document.querySelector('html')
        html.overflowY = 'auto'


    }

    useEffect(()=>{

        showOverflow()
        const script = document.createElement("script")
        script.src = "https://d335luupugsy2.cloudfront.net/js/loader-scripts/e41f8fd7-c302-4f11-a289-9c3b5f91a02b-loader.js";
        script.async = true
        document.body.appendChild(script)
    }, [])

    const tagManagerArgs = {
        gtmId: 'GTM-T6GXBPB' 

   }
     useEffect(() => {
       if(!hideRaster){TagManager.initialize(tagManagerArgs);}
     }, []);

    var emailOK = false;
    var passwordOK = false;

    var terms = <a href='https://meconectebr.com.br/termos-e-condicoes/' target='_blank' >Termos e Condições</a>;

    const [userInfo, setRegister] = useState ( {email:'', password:'', responsibleName:'', companyName:'',phone:'', cpfCnpj:''});
    const [ step, setStep ] = useState( 1 );

    const [ changeBody, setBody ] = useState( false );
    const [ emailText, setEmailText ] = useState( 'E-mails não coincidem' );
    const [ passwordText, setPasswordText ] = useState( 'Senhas não coincidem' );
    const [ plasticText, setPlasticText ] = useState( 'Esse campo é obrigatório' );
    const [ plasticDateText, setPlasticDateText ] = useState ( 'Esse campo é obrigatório' );
    const [ CVVText, setCVVText ] = useState ( 'Esse campo é obrigatório' );
    const [ unlockRegisterButton, setUnlockRegisterButton ] = useState(false);
    const [ isRegisterNotAllowed, setIsRegisterNotAllowed ] = useState(false);
    const [ notAllowedMessage, setNotAllowedMessage ] = useState('');
    const [ haveError, setHaveError ] = useState(false);
    const [ isAccountCreated, setIsAccountCreated ] = useState(false);
    const [ isLoadingData, setIsLoadingData ] = useState(false);
    const screen = window.screen



    const cpfMask = ( value ) =>
    {
        return value
        .replace( /\D/g, '' )
        .replace( /(\d{3})(\d)/, '$1.$2' )
        .replace( /(\d{3})(\d)/, '$1.$2' )
        .replace( /(\d{3})(\d{1,2})/, '$1-$2' )
        .replace( /(-\d{2})\d+?$/, '$1' );
    }
 

    const plasticMask = (value) =>{
        return( value )
        .replace( /\D/g,'' )
        .replace( /(\d{4})(\d)/,'$1 $2' )
        .replace( /(\d{4})(\d)/,'$1 $2' )
        .replace( /(\d{4})(\d)/,'$1 $2' )
        .replace( /(\d{4})/,'$1' );



    }

    const dateMask = ( value ) =>
    {
        return( value )
        .replace( /\D/g,'' )
        .replace( /(\d{2})(\d)/,'$1/$2' )
        .replace( /(\d{2})/,'$1' );
    }

    const cvvMask = ( value ) =>
    {
        return( value )
        .replace( /\D/g,'' );
    }

    const handleChangeCVV = () =>
    {
        var cvvInput = document.getElementById( 'CVVinput' )
        cvvInput.value = cvvMask( cvvInput.value );
    }

    const handleChangeCPF = (e) =>
    {
        e.value = cpfMask( e.value );
    }

    const handlePlastic = ( e ) =>
    {
        e.value = plasticMask( e.value );
    }

    const handleChangeDate = () =>
    {
        var valueDate = document.getElementById( 'plasticDateInput' );
        valueDate.value = dateMask(valueDate.value);
    }


    const checkEmail = () =>
    {
        var emailInputed = document.getElementById( 'emailInput' );
        //var emailConfirmed = document.getElementById( 'confirmEmailInput' );
        const emailConfirmed = emailInputed
        var emailAlert = document.getElementById( 'emailAlert' );

        if(emailInputed.value === '')
            {

                
                emailAlert.style.display = 'flex';
                setEmailText( 'Digite um e-mail válido.' );
                setNotAllowedMessage(emailText);
                setIsRegisterNotAllowed(true);   
                return(false);
            }
        else{
            if( emailInputed.value === emailConfirmed.value )
            {
                emailOK = true;
                emailAlert.style.display = 'none';
                return(true);
            }
            else
            {
                emailOK = false;
                emailAlert.style.display = 'flex';
                setEmailText( 'E-mails não coincidem.' );
                setNotAllowedMessage(emailText);
                return(false);
            }
        }
    }

    const checkPassword = () =>
    {
        var passwordInputed = document.getElementById( 'passwordInput' );
        //var passwordConfirmed = document.getElementById( 'confirmPasswordInput' );

        const passwordConfirmed = passwordInputed
        var passwordAlert = document.getElementById( 'passwordAlert' );
        
        if(passwordInputed.value === ''){
            
            setPasswordText( 'Digitar uma senha válida.' );
            setNotAllowedMessage(passwordText);
            passwordAlert.style.display = 'flex';
            return(false);

        }
        else{
            if( passwordInputed.value === passwordConfirmed.value )
            {
                passwordOK = true;
                passwordAlert.style.display = 'none';
                return(true);
            }
            else
            {
                passwordOK = false;
                passwordAlert.style.display = 'flex';
                setPasswordText( 'Senhas não coincidem.' );
                setNotAllowedMessage(passwordText);
                setIsRegisterNotAllowed(true)



                return(false);
            }
        }
    }

    const checkName =()=>{
        var valueName = document.getElementById( 'nameInput' );
        var nameAlert = document.getElementById( 'nameAlert' );
        if( valueName.value === '' )
            {
                setNotAllowedMessage('Por favor preencha seu nome'); 
                setIsRegisterNotAllowed(true);
                nameAlert.style.display = 'flex';
                return(false);
            }
        else{
                nameAlert.style.display = 'none';
                return(true);
        }
    }

    const checkTel =()=>{
        var valueTEL = document.getElementById( 'TELinput' );
        var TELAlert = document.getElementById( 'TELAlert' );
        
        if( valueTEL.value.length === 14 || valueTEL.value.length === 16 )
            {
                TELAlert.style.display = 'none';
                return(true);
            }
        else{

                setNotAllowedMessage('Verifique seu numero de telefone');
                setIsRegisterNotAllowed(true);   
                TELAlert.style.display = 'flex';
                return(false);
            }
    }

    const checkCPF =()=>{
        var valueCPF = document.getElementById( 'CPFinput' );
        var CPFAlert = document.getElementById( 'CPFAlert' );
        
        if( valueCPF.value.length === 18 || valueCPF.value.length === 14  )
            {
                CPFAlert.style.display = 'none';
                return(true);
            }
        else{                
            
                setNotAllowedMessage('Por favor, verifique se seu CPF ou CNPJ está correto')
                setIsRegisterNotAllowed(true)
                CPFAlert.style.display = 'flex';
                return(false);
        }
    }
    
    const checkTerms =()=>{

        var termsCheck = document.getElementById( 'checkboxTerms' );
        var checkboxAlert = document.getElementById( 'checkboxAlert' );
        
        if( termsCheck.checked )
            {
                checkboxAlert.style.display = 'none';
                return(true);
            }
        else{
            checkboxAlert.style.display = 'flex';

                setNotAllowedMessage('Por favor, verifique se você aceitou os termos');
                setIsRegisterNotAllowed(true)



                return(false);
        }

    }

    const checkPassNull = (password, firstOrLastChild) =>{
        const element = password.parentNode.parentNode;
        let otherPassword;
        setUnlockRegisterButton(false);

        if (firstOrLastChild==='first') {
            otherPassword = element.lastChild.childNodes[1].value
        } 
        else if(firstOrLastChild==='last') {
            otherPassword = element.firstChild.childNodes[1].value
        }





        if (password.value!=='' && password.value!==null) {
            if (otherPassword!=='' && otherPassword!==null) {
                setUnlockRegisterButton(true);
            }
        }
        

    }
    const checkIfIsNull = (e) =>
    {
        if (e.id === 'passwordInput') {
            checkPassNull(e, 'first');            
        } else if(e.id === 'confirmPasswordInput') {
            checkPassNull(e, 'last');
        }

    }




    const CheckPlanChosen = () =>
    {

        var valuePlan = false;
      

        var emailCheck = checkEmail()
        var passwordCheck = checkEmail()
        var nameCheck = checkName()
        var telCheck = checkTel()
        //var cpfCheck = checkCPF() 
        var termsCheck = checkTerms()

        if( emailCheck
            && passwordCheck
            && nameCheck
            && telCheck
            //&& cpfCheck
            && termsCheck
            )
        {
            if( valuePlan === true )
            {    

                UserStore.setWelcomeState('true')
                window.location.href = '/welcome';
            }
            else
            {   

                if(!hideRaster){ postFormData()}
                registerCompany();
                // window.location.href = 'https://chk.eduzz.com/1745888';
            }
        }
}

const handleSubmit = (e) =>{
    e.preventDefault()



}


const postFormData = async() =>{

const response = await axios.post(
    'https://api.rd.services/platform/conversions',
    // '\n{\n  "event_type": "CONVERSION",\n  "event_family": "CDP",\n  "payload": {\n    "conversion_identifier": "Conversão exemplo",\n    "email": "conversao@exemplo.com"\n  }\n}\n',
    {
      'event_type': 'CONVERSION',
      'event_family': 'CDP',
      'payload': {
        'conversion_identifier': `Cadastro Realizado: Quero Testar Grátis`,
        'email': `${userInfo.email}`,
        'mobile_phone': `${userInfo.phone}`,
        'name': `${userInfo.responsibleName}`
      }
    },
    {
      params: {
        'api_key': 'e5f10eb4dae6fe85b4dc0f836848c417'
      },
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    }
  );


}



const LoginOption = ( props ) =>
{
    var loginOption = '';

    if( props.step === 1)
    {


        if (props.isUnlocked) {
         loginOption =
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <button style={{margin: '0'}} type="submit" className="registerPageCreateAccount" onClick={ (e)=>{handleSubmit(e); CheckPlanChosen()} }>
                    Criar Conta
                </button>
            </div>           
        } else {
            loginOption =
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', cursor:'not-allowed'}}>
                <div className="registerPageCreateAccount" style={{cursor:'not-allowed'}}>
                    Criar Conta
                </div>
            </div>          
        }

    }
    else
    {
        loginOption = 
            <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} >
                <div className="registerPageWhiteDivText">
                    Conta criada com sucesso!
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', gap:'15rem', marginTop:'0.5rem' }}>
                    <div className="registerPageCreateAccount" onClick={()=> redirectFunction('login')}>
                        Login
                    </div>
                    {/*<div className="registerPageCreateAccount" onClick={()=> redirectFunction('payment')}>
                        Acesso Completo
                    </div>*/}
                </div>
            </div>;
    }

    return( loginOption );
}

    const namePlastic = () =>
    {
        var plasticInput = document.getElementById( 'namePlasticInput' );

        if( plasticInput.value !== '' )
        {
            plasticInput.style.textTransform = 'uppercase';
        }
        else
        {
            plasticInput.style.textTransform = 'none';
        }
    }

    const checkPlasticInput = () =>
    {
        var plasticInput = document.getElementById( 'namePlasticInput' );
        var plasticAlert = document.getElementById( 'namePlasticAlert' );

        if( plasticInput.value === '' )
        {
            plasticAlert.style.display ='flex';
            return( false );
        }
        else
        {
            plasticAlert.style.display ='none';
            return( true );
        }
    }

    const checkCPFInput =()=>
    {
        var CPFInput = document.getElementById( 'CPFInput2' );
        var CPFAlert = document.getElementById( 'CPFAlert2' );

        if( CPFInput.value === '' )
        {
            CPFAlert.style.display ='flex';
            return( false );
        }
        else
        {
            CPFAlert.style.display ='none';
            return( true );
        }
    }

    const checkPlasticNumberInput = () => {

        var plasticNumberInput = document.getElementById( 'plasticInput' );
        var plasticNumberAlert = document.getElementById( 'plasticAlert' );

        if( plasticNumberInput.value === '' )
        {
            plasticNumberAlert.style.display ='flex';
            setPlasticText( 'Esse campo é obrigatório.' );
            return( false );
        }
        else if( plasticNumberInput.value.length < 19 )
        {
            plasticNumberAlert.style.display ='flex';
            setPlasticText( 'Digitar um valor válido.' );
            return( false );
        }
        else
        {
            plasticNumberAlert.style.display ='none';
            return( true );
        }
    }

    const checkDate = () =>
    {

        var dateValue = document.getElementById( 'plasticDateInput' );
        var dateText = document.getElementById( 'plasticDateAlert' );
        var currentDate = new Date();
        var currentMonth = currentDate.getMonth() + 1;
        var currentYear = currentDate.getFullYear();
        var dateInputed = dateValue.value.split( '/' );
        var monthInputed = parseInt( dateInputed[ 0 ] );
        var yearInputed = parseInt( dateInputed[ 1 ] ) + 2000;

        if( dateValue.value === '' )
        {
            setPlasticDateText( 'Esse campo é obrigatório.' );
            dateText.style.display='flex';
            return( false );
        }
        else if( dateValue.value.length < 5)
        {
            setPlasticDateText( 'Digitar um valor válido.' );
            dateText.style.display='flex';
            return( false );
        }
        else if( yearInputed < currentYear )
        {
            setPlasticDateText( 'Digitar um valor válido.' );
            dateText.style.display='flex';
            return( false );
        }
        else if( currentYear === yearInputed )
        {
            if( monthInputed === 0 || monthInputed > 12 )
            {
                setPlasticDateText( 'Digitar um valor válido.' );
                dateText.style.display='flex';
                return( false );
            }
            else if( monthInputed < currentMonth )
            {
                setPlasticDateText( 'Digitar um valor válido.' );
                dateText.style.display='flex';
                return( false );
            }
            else
            {
                dateText.style.display='none';
                return( true );
            }
        }
        else
        {
            if( monthInputed === 0 || monthInputed > 12 )
            {
                setPlasticDateText( 'Digitar um valor válido.' );
                dateText.style.display='flex';
                return( false );
            }
            else
            {
                dateText.style.display='none';
                return( true );
            }
        }
    }

    const checkCVV = () =>
    {
        var cvvValue = document.getElementById( 'CVVinput' )
        var cvvText = document.getElementById( 'CVVAlert' );

        if( cvvValue.value === '' )
        {
            setCVVText( 'Esse campo é obrigatório.' );
            cvvText.style.display='flex';
            return( false );
        }

        if( cvvValue.value.length < 3 )
        {
            setCVVText( 'Digitar um valor válido.' );
            cvvText.style.display='flex';
            return( false );
        }
        else
        {
            cvvText.style.display = 'none';
            return( true );
        }
    }

    const checkPaymentData = () =>
    {
        if( checkPlasticInput() && checkCPFInput() && checkPlasticNumberInput() && checkDate() && checkCVV() )
        {
            window.location.href = '/login';
        }
    }

    const redirectFunction = (plan) =>{
        switch(plan){

            case 'welcome':

                UserStore.setWelcomeState('true')
                window.location.href = '/welcome'
                break
            case 'login':
                window.location.href = '/login';
            break;
            case 'payment':
                window.location.href = 'https://chk.eduzz.com/1745888';
            break;
            default:
        }

    }

    const getUserData =async(email, password) =>{
        const userCredentials = await axios.get
        (
            'https://ff7dnqc4cqykjhtn3xkehgou7m0pmmjp.lambda-url.us-east-2.on.aws/',
            {
                params:
                {
                    email: email,
                    password: password
                }
            }
        );
        setIsLoadingData(false)
        return userCredentials.data;

    }
    const defineCredentials = async(userEmail, userPassword) => {
        const user = await getUserData(userEmail, userPassword)

        UserStore.setToken( user.token );
        UserStore.setLogged( true );
        UserStore.setUserEmail( userEmail );
        UserStore.setUserId( user.userId );
        UserStore.setMyModel( user.modelId );
        UserStore.setAccType( user.accType );
        UserStore.setUserPhone(user.phone);
        UserStore.setUserName(user.userName)

        setIsAccountCreated(true)
    }

    const twoDigitsNumber = (number) =>{
        const convertedNumber = Number(number)
        return convertedNumber<10 ? `0${convertedNumber}` : convertedNumber
    }

    const createExpireDate = (day) =>{

        const  today = new Date();
        const lastDateOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); 
        const lastDayOfMonth = lastDateOfMonth.getDate()
        const dayDiff = Number(lastDayOfMonth)-Number(day)
        if (dayDiff>=15) {
            return `${today.getFullYear()}-${twoDigitsNumber(today.getMonth() + 1)}-${Number(day) + 15}`
        } else {

            console.log(dayDiff)

            if (today.getMonth() + 1 === 12 && day + 15 > 31) {
                const expireDay = - 31 + day + 15 
        
                return `${today.getFullYear() + 1}-01-${twoDigitsNumber(expireDay)}`                  
            } else {


                const expireDay = 15 - dayDiff

                return `${today.getFullYear()}-${twoDigitsNumber(today.getMonth() + 2)}-${twoDigitsNumber(expireDay)}`  


            }    

        }

    }

    const addSubscriptionPayment = async({userEmail, userPassword}) =>{
        const user = await getUserData(userEmail, userPassword)   
        const createdDate = new Date()
        const year = createdDate.getFullYear()
        const month = createdDate.getMonth() + 1
        const day = createdDate.getDate()




        const expireMonth = month>6 ? (month - 6) : (month + 6)    
        const expireYear = month>6 ? year + 1 : year



        const createdAt = `${year}-${twoDigitsNumber(month)}-${twoDigitsNumber(day)}`
        //const expiresOn = hideRaster ?   `${expireYear}-${twoDigitsNumber(expireMonth)}-${twoDigitsNumber(day)}` : createExpireDate(day)
        
        let expiresOn = createExpireDate(day)        
        if (props.time===30) {
            

            const  today = new Date();
            const lastDateOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); 
            const lastDayOfMonth = lastDateOfMonth.getDate()
            const dayDiff = Number(lastDayOfMonth)-Number(day)
            if (dayDiff>=30) {
                expiresOn= `${today.getFullYear()}-${twoDigitsNumber(today.getMonth() + 1)}-${Number(day) + 30}`
            } else {
    
                console.log(dayDiff)
    
                if (today.getMonth() + 1 === 12 && day + 30 > 31) {
                    const expireDay = - 31 + day + 30 
            
                    expiresOn= `${today.getFullYear() + 1}-01-${twoDigitsNumber(expireDay)}`                  
                } else {
    
    
                    const expireDay = 30 - dayDiff
    
                    expiresOn= `${today.getFullYear()}-${twoDigitsNumber(today.getMonth() + 2)}-${twoDigitsNumber(expireDay)}`  
    
    
                }    
    
            }
    
        } else if(props.time===3650){    
                    createdAt = `${year+1}-${twoDigitsNumber(month)}-${twoDigitsNumber(day)}`}

        console.log('ssss');console.log(createdAt);console.log(expiresOn);console.log(user.userId)
        await axios.post( 
            'https://aylduka55fxquysd72hawrvqvu0fkigl.lambda-url.us-east-2.on.aws/', 
            {
                companyId: user.userId ,
                createdAt: createdAt, 
                expiresOn: expiresOn, } );



    }

    const registerCompany = async() => {
        var eMail = userInfo.email;
        var password = md5 (userInfo.password) ;
        var companyName = userInfo.companyName;
        var responsibleName = userInfo.responsibleName;
        var cpfCnpj = userInfo.cpfCnpj;
        var phone = userInfo.phone;

        setIsLoadingData(true)

        const resp = await axios.post( 
            'https://n575iabljnkgqszqxnumanq2wi0zrpvs.lambda-url.us-east-2.on.aws/', 
            {
                eMail: eMail ,
                password: password, 
                companyName: companyName, 
                responsibleName:responsibleName, 
                cpfCnpj:cpfCnpj, 
                phone: phone   } );


        if( resp.data.hasOwnProperty('result') )
        {
            switch( resp.data.result)
            {
                case 0:  
                    
                    addSubscriptionPayment({companyId: 871, userEmail: eMail, userPassword: password})

                    if(!hideRaster){botconversaWebhook({locale: 'Cadastro', email: eMail, action: 'Cadastrou na Plataforma'});
                    
                    
                    
                    postConversionAPI()
                
                
                
                }
                    setStep( 2 )
                    defineCredentials(eMail, password)  
                    break;
                case 2:
                    setIsLoadingData(false)
                    alert('E-mail já cadastrado na plataforma.')

                    break;
                default:
                    setIsLoadingData(false)
                    alert('Erro na criação da conta.')

                    break;    
            }
        }
        else
        {   
            setIsLoadingData(false)
            alert('Erro na criação da conta.')
        }
    };

    const insertData = (event,type) =>{
        switch(type){
            case 'responsibleName':
                setRegister({...userInfo,responsibleName: event.value})
            break;
            case 'cpfCnpj':            
                setRegister({...userInfo,cpfCnpj: StringMask(event.value, { type:'CPFCNPJ' } ,'') })
            break;
            case 'companyName':
                setRegister({...userInfo,companyName: event.value })
            break;
            case 'phone':
                setRegister({...userInfo,phone: StringMask(event.value, { type:'Phone' } ,'') })
            break;
            case 'email':
                setRegister({...userInfo,email: event.value })
            break;
            case 'password':
                setRegister({...userInfo,password: event.value })    
            break;
            default:
        }
    }



    const postConversionAPI = async() =>{

        await axios.post(
            'https://graph.facebook.com/v18.0/668023162060499/events',
            '',
            {
              params: {
                'data': '[\n  {\n    "event_name": "Register",\n    "event_time": 1695438947,\n    "action_source": "website",\n    "event_source_url": "https://app.meconectebr.com.br/register",\n    "user_data": {\n      "em": [\n        "6b583232e99af45c3b436798f32800a4dd6f3524624ba6507ed8269b53ce82a3"\n      ],\n      "ph": [\n        "3cf4c9cac085c74ed58014978304f7b445921465f292eec60b9f28e2990e23b2"\n      ],\n      "fn": [\n        "74234e98afe7498fb5daf1f36ac2d78acc339464f950703b8c019892f982b90b"\n      ]\n    }\n  }\n]',
                'test_event_code': 'TEST72839',
                'access_token': 'EAAL1uRVHlekBO3AV3pWUMrI51tI2zviscz0SzTTcKTQt37AV4tNdLVmxl5ff2yTtsU91wWCj5eMV0PcK4iAH78LoJXdG8w6KQ7jZCk43qat8P3xgsUweXFfsqtyTD1LYU0YTqRlXM6HVMOhbMYbTBZBPLEXP6ZBJqaCqjVPdJAd84OjJ8W7jFJZANwgfsw2xHwZDZD'
              }
            }
          );



    }



    const post = async() =>{
        await axios.post()



    }

    return (
        <div className='registerPageBackground'>


            {isAccountCreated && <SuccessAddElement 
            message="Conta criada com sucesso" 
            onClick={()=>{ 
                redirectFunction('welcome')
                setIsAccountCreated(false)
                }}/>}

            {haveError && <FailAddElement onClick={()=>setHaveError(false)} message={notAllowedMessage}/>}

            {isLoadingData && <LoadingScreen/>}

            <div className='registerPageUpperDiv'>
                <img src ={MeLogo} alt='' style={{width:'4rem', height:'4rem'}}/>
                <a href='/login' style={{marginLeft:'auto',cursor:'pointer', textDecoration:'none'}}>
                    <text className='registerPageUpperDivText'>Entrar</text>
                </a>
            </div>
            <div style={{height:'0.1rem', width:'100%', backgroundColor:'#FFFFFF', marginTop:'1.2rem'}}/>
            <div className="registerUpperTitle" style={{height: screen.width<800 && `${screen.height - 100}px`}}>
                {(changeBody)?
                <div className="registerCenterMenu">
                    <text className='registerPageTitle'> Preencha seus dados de pagamento</text>
                    <div className='registerPageWhiteDiv' style={{gap:'2rem' }}>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <text className="registerPageWhiteDivText" style={{fontSize:'1.2rem'}}>Nome do titular do cartão:</text>
                            <input className="registerPageWhiteDivCreditInput" placeholder="Digite seu nome" style={{width:'46rem',fontSize:'1.2rem'}} id='namePlasticInput' onChange={namePlastic} onBlur={checkPlasticInput}></input>
                            <text className='registerPageErrorMsg' id='namePlasticAlert' style={{fontSize:'1.2rem'}}>Este campo é obrigatório.</text>
                        </div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <text className="registerPageWhiteDivText" style={{fontSize:'1.2rem'}}>CPF:</text>
                            <input className="registerPageWhiteDivCreditInput" placeholder="Digite seu CPF" style={{width:'46rem',fontSize:'1.2rem'}} id='CPFInput2' onChange={(e)=>handleChangeCPF(e.target)} onBlur={checkCPFInput}></input>
                            <text className='registerPageErrorMsg' id='CPFAlert2' style={{fontSize:'1.2rem'}}>Este campo é obrigatório.</text>
                        </div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <text className="registerPageWhiteDivText" style={{fontSize:'1.2rem'}}>Número do cartão:</text>
                            <input className="registerPageWhiteDivCreditInput" placeholder="Digite o número do seu cartão" style={{width:'46rem',fontSize:'1.2rem'}} id='plasticInput' maxLength='19'  onChange={(e)=>handlePlastic(e.target)} onBlur={checkPlasticNumberInput}></input>
                            <text className='registerPageErrorMsg' id='plasticAlert' style={{fontSize:'1.2rem'}}>{plasticText}</text>
                        </div>
                        <div style={{display:'flex', flexDirection:'row', gap:'6rem'}}>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText" style={{fontSize:'1.2rem'}}>Validade:</text>
                                <input style={{display:'none'}}></input>
                                <input className="registerPageWhiteDivCreditInput" placeholder="MM/YY" maxLength='5' style={{width:'20rem',fontSize:'1.2rem'}} id='plasticDateInput' onChange={handleChangeDate} onBlur={checkDate}></input>
                                <text className='registerPageErrorMsg' id='plasticDateAlert' style={{fontSize:'1.2rem'}}>{plasticDateText}</text>
                            </div>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText" style={{fontSize:'1.2rem'}}>CVV:</text>
                                <input style={{display:'none'}}></input>
                                <input className="registerPageWhiteDivCreditInput" style={{width:'20rem',fontSize:'1.2rem'}} id='CVVinput' placeholder="CVV" maxLength='3' onChange={handleChangeCVV} onBlur={checkCVV}></input>
                                <text className='registerPageErrorMsg' id='CVVAlert' style={{fontSize:'1.2rem'}}>{CVVText}</text>
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center',marginTop:'1rem'}}>
                            <div className="registerPageCreateAccount" style={{fontSize:'1.1rem'}} onClick={checkPaymentData}>
                                Finalizar cadastro
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="registerCenterMenu">

                    
                    <text className='registerPageTitle'> Crie sua conta MEconecte grátis e tenha acesso completo por {props.time===30 ? `30 dias` : props.time===3650 ? '1 ano' : `15 dias`}</text>
                    <div className='registerPageWhiteDiv' style={{gap:'1.2rem', marginBottom: '70px'}}>
                        <div className="registerInputField">
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText">Nome</text>
                                <input className="registerPageWhiteDivInput" placeholder="Digite seu nome" id='nameInput' value={userInfo.responsibleName} onChange={(event)=>insertData(event.target,'responsibleName')}></input>
                                <text className='registerPageErrorMsg' id='nameAlert'>Favor preencher seu nome.</text>
                            </div>
                            {/*<div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText">CPF/CNPJ</text>
                                <input className="registerPageWhiteDivInput" placeholder="Digite seu CPF"
                                id='CPFinput' value={userInfo.cpfCnpj} maxLength='18' onChange={(e)=>insertData(e.target,'cpfCnpj')}></input>
                                <text className='registerPageErrorMsg' id='CPFAlert'>Favor preencher um CPF válido.</text>
                            </div>*/}



                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText">Telefone</text>
                                <input className="registerPageWhiteDivInput" placeholder="Digite o seu telefone"
                                id='TELinput' maxLength='15' value={userInfo.phone} onChange={(e)=>insertData(e.target,'phone')}></input>
                                <text className='registerPageErrorMsg' id='TELAlert'>Favor preencher um telefone válido.</text>
                            </div>                            
                        </div>
                        <div className="registerInputField">
                            {/*<div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText">Nome da empresa</text>
                                <input className="registerPageWhiteDivInput" placeholder="Digite o nome da sua empresa" id='nameEnterprise' value={userInfo.companyName} onChange={(e)=>insertData(e.target,'companyName')}></input>
                            </div>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText">Telefone</text>
                                <input className="registerPageWhiteDivInput" placeholder="Digite o seu telefone"
                                id='TELinput' maxLength='15' value={userInfo.phone} onChange={(e)=>insertData(e.target,'phone')}></input>
                                <text className='registerPageErrorMsg' id='TELAlert'>Favor preencher um telefone válido.</text>
                            </div>*/}
                        </div>
                        <div className="registerInputField">
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText">E-mail</text>
                                <input className="registerPageWhiteDivInput" type="email" placeholder="Digite seu e-mail" id='emailInput' value={userInfo.email} onBlur={checkEmail} onChange={(e)=>insertData(e.target,'email')} ></input>
                                <text className='registerPageErrorMsg' id='emailAlert'>{emailText}</text>
                            </div>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText">Senha</text>
                                <input className="registerPageWhiteDivInput" placeholder="Digite sua senha" id='passwordInput' type='password' value={userInfo.password} onBlur={checkPassword} onChange={(e)=>{checkIfIsNull(e.target); insertData(e.target,'password')}}></input>
                                <text className='registerPageErrorMsg' id='passwordAlert'>{passwordText}</text>
                            </div>
                           {/* <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText">Confirme seu e-mail</text>
                                <input className="registerPageWhiteDivInput" placeholder="Confirme seu e-mail" id='confirmEmailInput' onBlur={checkEmail}></input>
                            </div>*/}
                        </div>
                        <div className="registerInputField">
                           {/* <div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText">Senha</text>
                                <input className="registerPageWhiteDivInput" placeholder="Digite sua senha" id='passwordInput' type='password' value={userInfo.password} onBlur={checkPassword} onChange={(e)=>{checkIfIsNull(e.target); insertData(e.target,'password')}}></input>
                                <text className='registerPageErrorMsg' id='passwordAlert'>{passwordText}</text>
                            </div>*/}
                            {/*<div style={{display:'flex', flexDirection:'column'}}>
                                <text className="registerPageWhiteDivText">Confirme sua senha</text>
                                <input className="registerPageWhiteDivInput" onChange={e=>checkIfIsNull(e.target)}placeholder="Confirme sua senha" id='confirmPasswordInput' type='password' onBlur={checkPassword}></input>
                            </div>*/}
                        </div>
                        <div style={{display:'flex', flexDirection:'column',marginBottom:'1rem'}} >
                            <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'0.5rem'}} >
                                <input type='checkbox' id='checkboxTerms'></input>
                                <text className="registerPageWhiteDivText" style={{marginBottom: '0rem'}}>Li e concordo com os {terms}.</text>
                            </div>
                            <text className='registerPageErrorMsg' id='checkboxAlert'>É necessário concordar com os termos e condições para prosseguir</text>
                        </div>

                        
                        {!unlockRegisterButton && <LoginOption step={ step } isUnlocked={false}/>}
                        {isRegisterNotAllowed && unlockRegisterButton && <LoginOption step={ step } onClick={()=>setHaveError(true)} isUnlocked={true}/>}
                        {!isRegisterNotAllowed && unlockRegisterButton && <LoginOption step={ step } isUnlocked={true}/>}
                    </div>



                
                </div>}
            </div>
        </div>
    );
}

export default RegisterPage;