import React from 'react'
import styles from './InformationsAndStep.module.css';
import { CProgress } from '@coreui/react';
import { convertToSemanticTimeFormat } from '../../../../functions/convertToSemanticTime';




export const InformationsAndStep = ({classesQuantity, classesTotalDuration, watchedVideos, totalWatchedVideos, totalVideos}) => {
  

  const watchedPercent = Math.ceil((totalWatchedVideos/totalVideos)*100)
  return (
    <div className={styles.informationsAndStep}>
      <div className={styles.informations}>
        <div className={styles.leftContent}>
            <div><span><i className="fa fa-play-circle"/> {(classesQuantity!==1) ? `${classesQuantity} aulas` : `${classesQuantity} aula` }</span></div>
            <div><span><i className="fa fa-clock"/>{convertToSemanticTimeFormat(classesTotalDuration)}</span></div>
        </div>
        <div className={styles.rightContent}>
            {watchedPercent}% concluido
        </div>   
      </div>

      <div className={styles.step}>
            {/*Progress Bar*/}
        <CProgress
            
            value={watchedPercent}
            showValue
            className="mb-1 bg-white"
        />
      </div>
      

      
      
    </div>
  )
}
