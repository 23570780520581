import React from 'react'
import styles from './TagsWithPercentJourneyCard.module.css'
const TagsWithPercentJourneyCard = ({title, tags, yearValue01, yearValue02, yearValue03}) => {

    
    const tagsSplit = tags.split(", ")
  
    //const percentualValue = value.replace('.', ',')


    console.log(yearValue01)
  return (
    <div className={styles.tagsWithPercentJourneyCard}>
        <h4>{title}</h4>
  
        <div className={styles.tagsCard}>
    
            <div className={styles.tagsValue}><p>{tagsSplit[0]}</p></div>
            {(typeof yearValue01 === 'number') && <div className={styles.percentValue}><p>{yearValue01}%</p></div>}


        </div>

  
        <div className={styles.tagsCard}>
    
            <div className={styles.tagsValue}><p>{tagsSplit[1]}</p></div>
            {(typeof yearValue02 === 'number') && <div className={styles.percentValue}><p>{yearValue02}%</p></div>}


        </div>

  
        <div className={styles.tagsCard}>
    
            <div className={styles.tagsValue}><p>{tagsSplit[2]}</p></div>
            {(typeof yearValue03 === 'number') && <div className={styles.percentValue}><p>{yearValue03}%</p></div>}


        </div>

  



        
    
    </div>
  )
}

export default TagsWithPercentJourneyCard