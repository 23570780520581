import React from 'react'
import styles from './ExpensesContainer.module.sass'
import ExpenseCard from '../expenseCard/ExpenseCard'
const ExpensesContainer = ({expenses, monthIndex, convertToCurrency, editExpense}) => {

  console.log(expenses)
  return (
    <div className={styles.expensesContainer}>
        {expenses && expenses.map(expense=><ExpenseCard cfe={expense.cfe} editExpense={editExpense} convertToCurrency={convertToCurrency} expenseName={expense.name} targetValue={expense.target[monthIndex]} doneValue={expense.done[monthIndex]}/>)}


    </div>
  )
}

export default ExpensesContainer