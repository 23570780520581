import React, { useEffect, useState } from 'react'
import styles from './ActionPlan.module.sass'
import axios from 'axios'
import { MonthSelect } from '../monthSelect/MonthSelect'
import UserStore from '../../store/userStore'
import { ActionPlanCard } from './cards/actionPlanCard/ActionPlanCard'
import { NewPopUp } from './cards/newPopUp/NewPopUp'
import SalesContainer from './cards/salesContainer/SalesContainer'
import { DateMask, StringMask } from '../library/StringMasks'
import LoadingScreen from '../loadingScreen/LoadingScreen'
import { RemoveElement } from '../removeElement/RemoveElement'
import { botconversaWebhook } from '../../functions/botconversaWebhook'
import { YearSelect } from '../yearSelect/YearSelect'
import EditSale from './cards/editSale/EditSale'



const ActionPlan = () => {


    const pageLocale = 'Gestão de Vendas'
    useEffect(()=>{
    
        botconversaWebhook({locale: pageLocale, action: 'Abriu Plano de Ação'})
    })
    const actualMonth = new Date().getMonth() + 1
    const [salesSelectedMonth, setSalesSelectedMonth] = useState(actualMonth)
    const [tasksSelectedMonth, setTasksSelectedMonth] = useState(actualMonth)
    const actualYear = new Date().getFullYear()
    const [salesSelectedYear , setSalesSelectedYear ] = useState(actualYear) 

    const [errorMessage, setErrorMessage] = useState('')
    const [dailyPhrase, setDailyPhrase] = useState('Carregando frase do dia...')
    const [tasksList, setTasksList] = useState([])
    const [ activePopUp, setActivePopUp ] = useState( { type:'', top:0, left:0 } );
    const [monthSales, setMonthSales] = useState([])
    const [productsSelection, setProductsSelection] = useState(null)
    const year = new Date().getFullYear()
    const userId = UserStore.getUserId()
    const userToken = UserStore.getToken()
    const [selectedProductSales, setSelectedProductSales] = useState([])

    const [ removeExpenseWarn, setRemoveExpenseWarn ] = useState(false)

    const [ selectedSale, setSelectedSale ] = useState(-1)
    const [selectedProduct, setSelectedProduct] = useState(-1)
    const [isLoadingScreen, setIsLoadingScreen] = useState(false)

    const [ removeTaskItem, setRemoveTaskItem ] = useState(false);
    const [ removeTaskParamsEvent, setRemoveTaskParamsEvent ] = useState(null);
    const [ removeTaskParamsTask, setRemoveTaskParamsTask ] = useState(0);
    const [ clientsSelection, setClientsSelection ] = useState({})


    const [isEditSaleOpen, setIsEditSaleOpen] = useState(false)
    //const [selectedSale, setSelectedSale] = useState(null)
    const removeLoadingTask = (removeTaskParamsEvent) =>{removeTaskParamsEvent.target.parentNode.parentNode.parentNode.style.display='none'}
  
    const editSale = ({curItem, date}) =>{
        curItem.date = date
        setSelectedSale(curItem )
        setIsEditSaleOpen(true)



    }

    const checkTask = async( event, task ) =>
    {
        if( event.target.checked )
        {
            
    
                await axios.put( 'https://ge5ar6ig4556ipk7ktag4mbslu0rpatp.lambda-url.us-east-2.on.aws/', { task:task, status:1, company:userId, token:userToken } )//.then(()=>getTasksList());
                botconversaWebhook({locale: pageLocale, action: 'Concluiu Tarefa'});
    
        }

        //
    };    
    
    const uncheckTask = (event) =>{
        event.target.checked = false;
    }



    const getMonth = (date) =>{

        console.log('Task');console.log(new Date().getMonth(date) + 1)
      return new Date(date).getMonth() + 1



    }
    
    const getTasksList = async(month=tasksSelectedMonth) =>{

        setIsLoadingScreen(true)
        await axios.get( 'https://vyfbubcahhio2ng3fvjohkjjbm0krbeg.lambda-url.us-east-2.on.aws/', { params:{ company: userId, token: userToken } } ).then( ( resp ) =>
        {
            const tasks = resp.data.tasks
            //const monthTasks = tasks.filter(task=>)

            const monthTasks = tasks.filter((task)=>getMonth(task.date)===Number(month))
            setTasksList(monthTasks)
            setIsLoadingScreen(false)
        }).catch(err=>setIsLoadingScreen(false))    
    }

    useEffect(()=>{
        const getClients = async()=>{   console.log('AAAA'); console.log(productsSelection)
            await axios.get('https://b275nbsclbzsman3igysfj7shm0ebhxr.lambda-url.us-east-2.on.aws/', {params: {companyId: UserStore.getUserId()}})
            .then(resp=>{   console.log(resp.data.clients)
                setClientsSelection(resp.data.clients) 
            })
        }    
        getClients()

    }, [setActivePopUp])
    const getSalesList = async(month=actualMonth, year=salesSelectedYear) =>{


        setIsLoadingScreen(true)
        await axios.get( 'https://ug22i67qcjzgjpi5o57numzhou0kougr.lambda-url.us-east-2.on.aws/', { params:{ company: userId, token: userToken, month: month, year:year } } ).then( ( resp ) =>
        {
        if( resp.data.products.length > 0 )
        {console.log('resp.data');console.log(resp.data)
            setMonthSales( resp.data.products );
            const productssss = resp.data.products
            productssss.forEach(product=>{product.label = product.name; delete product.name;})
            setProductsSelection(productssss)   

            

        }



        setIsLoadingScreen(false)
        } ).catch(err=>setIsLoadingScreen(false))



    }  
    

    const getDailyPhrase = async() =>{
        await axios.get( 'https://26hevt4qlnwnlkah47kpzzydl40qiyoi.lambda-url.us-east-2.on.aws/' ).then( ( resp ) =>
        {

            if(resp.data.phrase){
                setDailyPhrase( '"' + resp.data.phrase + '" - ' + resp.data.author );
            }else{setDailyPhrase( "A sorte favorece a mente preparada. - Louis Pasteur" );}

        } )


    }


            const cleanNewTaskInfo = () =>{setNewTaskInfo({
                category:(taskCategories ? taskCategories[ 0 ] : ''),
                date:DateMask( new Date() ),
                responsible:'',
                task:''
        } )}

    const registerNewTask = async(task) =>{
        const resp = await axios.post( 'https://ty2cg56ntnfpli44sb6iji5dge0blefh.lambda-url.us-east-2.on.aws/', { company:userId, token:userToken, category:task.category.value, task:task.task, responsible:task.responsible, date:task.date } );

        if( resp.data.task !== -1 )

        {
           // setIsLoadingScreen(false);
            setActivePopUp( { type:'', top:0, left:0 } );
            cleanNewTaskInfo()            


           // setSuccessAddTask(true);
        }        //setIsLoadingScreen(true);
        botconversaWebhook({locale: pageLocale, action: 'Cadastrou Task'});
        getTasksList()





    }

    const registerNewSale = async(sale) =>{ 
        var curValue = `${sale.product.value}` //StringMask( , { type:'float' }, '' );
        var value = StringMask( sale.value, { type:'float' }, '' );
        setIsLoadingScreen(true)
        console.log(curValue);console.log(value)



        console.log(sale)
        const resp = await axios.post( 
              'https://c6eulyfzkrnvmnqvaepfylfbme0ylxvv.lambda-url.us-east-2.on.aws/', 
            { 
                company: userId,  
                token: userToken,  
                product: sale.product.product,  
                quantity: sale.quantity,  
                curValue: curValue,
                value: value,  
                installments: sale.installments.value,  
                date: sale.date,  
                notes: sale.notes,     
                recurrenceMonths: sale.recurrenceMonths.value,
                client: sale.client




            } ).catch(err=>setIsLoadingScreen(false));
        if( resp.data.product !== -1 )
        {

            //setIsLoadingScreen(false);
            setActivePopUp( { type:'', top:0, left:0 } );
            //setSuccessAddSell(true);

            //window.location.reload( false );
        }        getSalesList()

        botconversaWebhook({locale: pageLocale, action: 'Cadastrou Venda'});
        setIsLoadingScreen(false)





    }

    const openPopUp = ( props ) =>
    {
        //const rect = document.getElementById( props.refId ).getBoundingClientRect();
        setActivePopUp( 
            {   type:props.type, 
                title:props.itemTitle, 
                itemId:props.itemId, 
                top:0, 
                left:0, 
                height:'auto', 
                width: '94%' ,
                

            } );

        
    }


    const twoDigitInteger = ( input ) =>
    {
        return ( input > 9 ) ? input : '0' + input;
    }



    const confirmRemoveSale = async(sale) =>{

        setRemoveExpenseWarn(true)
        setSelectedSale(sale) 

    }





    const getProductSales = async(selectedProduct) =>{
        
        setSelectedProduct(selectedProduct)
        await axios.get( 'https://bwfxdnzp5j6jbrdlnzvpadrzbm0vgrie.lambda-url.us-east-2.on.aws/', { params:{ company:userId, token:userToken, product:selectedProduct, year:2023 } } ).then( ( resp ) =>
    {
        if( resp.data.sales )

       
        {

            let saleItems = []
            /**/for( let index = 0; index < resp.data.sales.length; index++ )
            {
                const curItem = resp.data.sales[ index ];
                console.log('Vendas');console.log(resp.data.sales)
                const splittedDate = curItem.date.split('-')

                //saleDate fix the wrong date, by changing the format of date from YYYY-MM-DD to MM-DD-YYYY
                const saleDate = `${splittedDate[1]}/${splittedDate[2].substring(0,2)}/${splittedDate[0]}`
                
                const ISODate = new Date( saleDate );
                //console.log('isodate');console.log(saleDate)

                const date = `${twoDigitInteger( ISODate.getDate() )}` + `${twoDigitInteger( ISODate.getMonth() + 1 )}` + ISODate.getFullYear();

                console.log('iso day month'); console.log(date);
                
                
                     
                console.log(twoDigitInteger( ISODate.getDate() ) );
                
                console.log(twoDigitInteger( ISODate.getMonth() + 1 ) )

                console.log(ISODate.getFullYear())
                const total = curItem.value * curItem.quantity;
                const newLine =
                    <div key={ 's_' + curItem.sale } className='HistoryItemNew'>
                        <div>
                            <div className='HistoryItemInfo'>
                                <b>{ StringMask( date, { type:'DDMMYYYY' }, '' ) }</b> - <b>{ curItem.quantity }</b> unidades totalizando <b>{ StringMask( total.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' ) }</b> em <b>{ curItem.installments }x</b>.
                            </div>
                            <div className='HistoryItemInfo'>
                                Preço corrente era <b>{ StringMask( curItem.curPrice.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' ) }</b> e foi efetuado por <b>{ StringMask( curItem.value.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' ) }</b>{curItem.recurrenceMonths>1 && <><br/><b>Recorrência: </b>{curItem.recurrenceMonths} meses</>}{curItem.notes && <><br/><b>Observações: </b>{curItem.notes}</>}.
                            </div>
                        </div>
                        <div className='SellItemInfoClose'>
                            

                            {window.screen.width>780 ?
                                            <><div onClick={()=>editSale({curItem: curItem, date: StringMask(date, {type: 'DDMMYYYY'}, '')})}><i className="fa fa-pen"/></div><div onClick={ () => { console.log(`Test: ${window.screen.width}`); confirmRemoveSale(curItem.sale);} }>x</div></>
                                            :
                                            <i className="fa fa-trash" onClick={()=>confirmRemoveSale(curItem.sale)}/>
                                        }

                        </div>
                    </div>;

                saleItems.push( newLine );
            }


            setSelectedProductSales(saleItems)
        }
    } );

    }



    const removeSale = async( sale ) =>
    {
        setRemoveExpenseWarn(false)
        setIsLoadingScreen(true)
        
            await axios.delete( 'https://vsp4cmb53av3sywzzjw62pbfue0sbfyh.lambda-url.us-east-2.on.aws/', { data: { sale:sale, company:userId, token:userToken } } ) 
            .catch(err=>{setIsLoadingScreen(false)})
            botconversaWebhook({locale: pageLocale, action: 'Removeu Venda'});
                setIsLoadingScreen(false);
                getProductSales(selectedProduct)
                getSalesList()
    
            

    
    };



    const removeTaskItemFn = (e, task) =>{
        
        setRemoveTaskParamsEvent(e)
        setRemoveTaskParamsTask(task)
        setRemoveTaskItem(true)
    }


    const taskCategories =
[
    { label: 'Operacional', value: 0 },
    { label: 'Relacionamento', value: 1 },
    { label: 'Marketing e vendas', value: 2 },
    { label: 'Financeiro', value: 3 },
    { label: 'Canais de comunicação', value: 4 },
    { label: 'Parcerias', value: '5' }
];




    const [ newTaskInfo, setNewTaskInfo ] = 
    useState
    ( {
            category:(taskCategories ? taskCategories[ 0 ] : ''),
            date:DateMask( new Date() ),
            responsible:'',
            task:''
    } );


    useEffect(()=>{
        getDailyPhrase()
        getTasksList()
        

    }, [])
    useEffect(()=>{getSalesList(salesSelectedMonth, salesSelectedYear)}, [salesSelectedYear])    
    useEffect(()=>{getSalesList(salesSelectedMonth)}, [salesSelectedMonth])
    useEffect(()=>{getTasksList(tasksSelectedMonth)}, [tasksSelectedMonth])
    



        const convertMonthToValue = (monthName) =>{
        switch (monthName) {
            case 'Janeiro':
                return "01";
            case 'Fevereiro':
                return "02";
            case 'Março':
                return "03";
            case 'Abril':
                return "04";
            case 'Maio':
                return "05" ;
            case 'Junho':
                return "06";
            case 'Julho':
                return "07" ;
            case 'Agosto':
                return "08";
            case 'Setembro':
                return "09" ;
            case 'Outubro':
                return "10";
            case 'Novembro':
                return "11" ;
            case 'Dezembro':
                return "12";
            default:
                return;
        }
    }


  return (
    <div className={styles.actionPlan}>



            { isEditSaleOpen && selectedSale && <EditSale selectedSale={selectedSale} closeFn={()=>{setIsEditSaleOpen(false); setSelectedSale(null)}} reloadInfo={()=>getProductSales(selectedProduct)}/>}
            {removeExpenseWarn && <RemoveElement message="Deseja remover a venda?" closeWarn={()=>{setRemoveExpenseWarn(false)}} moreButtons={true} onClick={()=>{botconversaWebhook({action: 'Removeu venda de produto'}); removeSale(selectedSale)}}/>}

            {isLoadingScreen && <LoadingScreen/>}
            {!!errorMessage && <RemoveElement message={errorMessage} closeWarn={()=>{setErrorMessage('' )}} moreButtons={true} onClick={()=>{window.location.replace('/revenues');}}/>}

    { removeTaskItem && <RemoveElement onClick={() => {
        checkTask(removeTaskParamsEvent, removeTaskParamsTask);
        setRemoveTaskItem(false);
        removeLoadingTask(removeTaskParamsEvent);

    }                
    } closeWarn={()=>{
        setRemoveTaskItem(false);
        uncheckTask(removeTaskParamsEvent);

    }} moreButtons={true} message="Deseja concluir a tarefa?"/> }




        {<NewPopUp 
          prop={activePopUp}  
          registerNewTask={registerNewTask}
          registerNewSale={registerNewSale}
          setActivePopUp={setActivePopUp}
          productsSelection={productsSelection}



          clientsSelection={clientsSelection}
          salesList={selectedProductSales}
          newTaskInfo={newTaskInfo}
          setNewTaskInfo={setNewTaskInfo}
          />
          
          
          
          }




        <div className={styles.topCard}>
            <h2>- Frase do dia -</h2>
            <p>{dailyPhrase}</p>
        </div>


        <div className={styles.bottomCards}>
            <div className={styles.leftCard}>


                <div className={styles.topOptions}>
                    <div className={styles.topInformation}>
                        <h2>Planejamento</h2>
                        <div className={styles.addButton} onClick={ () => openPopUp( { type:'newTask', width: '90%', itemTitle:'' } ) }><span>+</span></div>
                    </div>
                    <div><MonthSelect actualMonth={actualMonth} monthFunction={(e)=>setTasksSelectedMonth(convertMonthToValue(e.target.value))}/>
                    </div>
                  </div>
                 <div className={styles.taskItems}>
                    {tasksList && tasksList.map(task=><ActionPlanCard 
                    category={task.category} 
                    responsible={task.responsible} 
                    task={task.taskDescription}
                    checkTaskFn={(e)=>{removeTaskItemFn(e, task.task)}}
                    />)}
                </div>



                <div>
            </div>
            </div>
            <div className={styles.rightCard}>
                
                <div className={styles.topOptions}>
                    <div className={styles.topInformation}>
                        <h2>Vendas</h2>
                        <div className={styles.addButton}  onClick={ () => {productsSelection ?  openPopUp( { type:'newSell', width: '90%', itemTitle:'' } ) : setErrorMessage('Você deve cadastrar pelomenos um produto, clique abaixo para cadastrar :)') }}><span>+</span></div>
                    </div>
                    <div className={styles.filters}><YearSelect actualYear ={actualYear }  yearFunction={(e)=>setSalesSelectedYear (e.target.value)}/>



                    <MonthSelect actualMonth={actualMonth} monthFunction={(e)=>setSalesSelectedMonth(convertMonthToValue(e.target.value))}/>
                    </div>
                </div>
                <div className={styles.bottomOptions}>
                    <SalesContainer monthSales={monthSales} openPopUp={openPopUp} getProductSales={getProductSales} selectedProductSales={selectedProductSales}/>   
                </div>




            </div>
        </div>


    </div>
  )
}

export default ActionPlan