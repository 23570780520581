import React from 'react'
import styles from './DefaultCard.module.sass'
import Background from '../../../../assets/new_background.png'
import UserStore from '../../../../store/userStore'



const DefaultCard = ({step, title, topicList, cover, accessUrl}) => {
    const isDivided = topicList && topicList.length>3

    console.log(isDivided)
    const leftArray = topicList[0]

    const rightArray = topicList[1] 

    console.log(leftArray);console.log(rightArray);
    const redirectTo = (url) =>{
        window.location.replace(`newjourney?=${url}`)



    }
  return (
    <div className={styles.defaultCard}>
            <img src={Background} alt='' className={styles.background}/>
                    
        <div className={styles.left}>
            <div>
            <span className={styles.step}>{step}º passo</span>

            <p className={styles.title}>{title}</p></div>   
            <div className={styles.topicList}>

                <div className={styles.sideTopics}>
                    {leftArray && leftArray.map(topic=>(<span onClick={()=>{UserStore.setSelectedPageStore('newjourney'); redirectTo(topic.url)}} className={styles.journeyTopic}><img className={styles.topicIcon} src={topic.icon} alt=""/><p className={styles.stepTitle}>{topic.title}</p></span>))}  
                </div>

                <div className={styles.sideTopics}>
                    {rightArray && rightArray.map(topic=>(<span onClick={()=>{UserStore.setSelectedPageStore('newjourney'); redirectTo(topic.url)}} className={styles.journeyTopic}><img className={styles.topicIcon} src={topic.icon} alt=""/><p className={styles.stepTitle}>{topic.title}</p></span>))}  
                </div>



            </div>









        </div>




        <div className={styles.right}>
            <img src={cover} alt='' className={styles.cover}/>

            <button onClick={()=>{UserStore.setSelectedPageStore('newjourney'); redirectTo(accessUrl)}} className={styles.accessButton}>Acessar</button>
        </div>  
    </div>
  )
}

export default DefaultCard