import React from "react";
import "../questions/questions.css";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import UserStore from "../../store/userStore";
import { useState, useEffect } from "react";
import axios from 'axios';
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import { FailAddElement } from "../failAddElement/FailAddElement";


const PartnersQuestions = (props)=>{
 
    const [ journeyData, setJourneyData] = useState( null );
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)


    const LoadJourneyAnswers = async() =>{
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );

        if(resp.data.How )
        {
            setJourneyData(resp.data.How);
        }
    }

    const saveAnswers = async()=>{

        setLoading(true)
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData = 
        {
            jhOperationalPartners:journeyData.jhOperationalPartners,
            jhMarketingPartners:journeyData.jhMarketingPartners
        };








        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'How', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })
    


        document.getElementById( 'saveBtn' ).innerHTML = "Salvar";
    }


    const changetext =(e,source,choice)=>{

        switch (source){

            case 'text1':{   
                if(journeyData.jhOperationalPartners !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jhOperationalPartners : e.target.value})         
            }
            break;
            case 'text2':{
                if(journeyData.jhMarketingPartners !== e.target.value && props.checkChanges!=="-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jhMarketingPartners : e.target.value})
            }
            break;
        }
        
    }

    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }


    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()
    


    }

    useEffect( () => { LoadJourneyAnswers()}, [] );


    return(
        <div className="journeyFWQQuestionsLayout1">


            {loading && <LoadingScreen/>}
            {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>}
            {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>}
            {props.activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.redirect}/>}
            
               <div className="journeyQuestionsSaveBtn"  id='saveBtn' onClick={saveAnswers}>
                    Salvar
                </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText" style={{marginBottom:'2rem'}}>Parcerias operacionais:</text>
                        <text className="journeyFWQQuestionText">Quem são seus parceiros operacionais e no que contribuem para o negócio?
                            <QuestionsInformation haveTopics={true} topicsInsideTopics={true} breakLine={true} information="São colaborações estratégicas para ajudar a impulsionar suas operações e alcançar seus objetivos. Esses parceiros desempenham um papel fundamental no fornecimento de recursos, expertise, serviços ou tecnologia que complementam ou ampliam as capacidades da empresa. 
                            
                            <br/>Eles podem ser:->

                                fornecedores<topic>
                                fabricantes<topic>
                                distribuidores<topic>
                                aliados estratégicos<topic>
                                prestadores de serviços<topic>
                                parceiros de tecnologia

                                <br/>Essas parcerias ajudam a empresa a melhorar sua eficiência, reduzir custos, expandir sua capacidade e alcance de mercado, e promover a inovação. É importante estabelecer relações sólidas e alinhadas com os objetivos da empresa.
                                "/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea1' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jhOperationalPartners : '---'} onChange={(e) => changetext(e,'text1')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea1')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea1')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2" style={{marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText" style={{marginBottom:'2rem'}}>Marketing e Vendas:</text>
                        <text className="journeyFWQQuestionText">Quem são seus parceiros de Marketing e Vendas e no que eles contribuem para seu negócio?
                          <QuestionsInformation haveTopics={true} breakLine={true} information="As parcerias de marketing e venda são colaborações estabelecidas entre empresas com o objetivo de fortalecer as atividades de marketing e vendas de ambas as partes. Essas parcerias visam alcançar benefícios mútuos, como aumentar a visibilidade da marca, expandir o alcance do público-alvo, compartilhar recursos e conhecimentos, e impulsionar as vendas.

                                <br/>Algumas formas de parcerias de marketing e venda incluem:

                                <br/>Co-marketing:-> Duas empresas se unem para criar e promover campanhas de marketing conjuntas, compartilhando os custos e recursos envolvidos. Isso pode incluir eventos promocionais, co-produção de conteúdo, compartilhamento de espaço publicitário, entre outros.
                                <br/>Parcerias de distribuição:-> Empresas estabelecem acordos para distribuir os produtos ou serviços umas das outras, aproveitando a base de clientes existente de cada parceiro. Isso pode envolver revenda, revenda cruzada ou distribuição conjunta de produtos ou serviços complementares.
                                <br/>Parcerias com influenciadores:-> Colaborações com influenciadores digitais ou personalidades relevantes em determinado nicho de mercado, que promovem os produtos ou serviços da empresa para sua audiência. Isso pode aumentar a exposição da marca e influenciar a decisão de compra dos seguidores desses influenciadores.
                                <br/>Programas de afiliados:-> Estabelecimento de um programa em que parceiros (afiliados) promovem os produtos ou serviços da empresa em troca de uma comissão por cada venda realizada através de seus esforços de marketing. Esses parceiros podem ser blogueiros, sites de revisão, influenciadores ou empresas relacionadas.
                                <br/>Parcerias estratégicas:-> Colaborações com empresas que possuem sinergia de negócios, complementando as ofertas de produtos ou serviços umas das outras. Essas parcerias podem envolver vendas conjuntas, promoções combinadas ou desenvolvimento de soluções integradas."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea2' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jhMarketingPartners : '---'} onChange={(e) => changetext(e,'text2')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea2')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea2')}></img>
                        </div>
                    </div>
                </div>
    )
}

export default PartnersQuestions;