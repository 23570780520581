import "../cashflow/Taxes.css";
import React from 'react';
import { InfoIconImg } from '../imgsLoader/LoadIconImgs';
import UserStore from '../../store/userStore';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { changeLanguage } from "i18next";
import { botconversaWebhook } from "../../functions/botconversaWebhook";
import TaxesQuestions from "../questions/taxesQuestions"
import { StringMask } from "../library/StringMasks";
import QuestionsInformation from "../questions/questionsInformation/QuestionsInformation";
const Taxes = () => {
    const [haveChanges, setHaveChanges] = useState(false)
    const [ journeyData, setJourneyData] = useState( null );
    const [janValue, setJan] = useState (0)
    const [febValue, setFeb] = useState (0)
    const [marValue, setMar] = useState (0)
    const [aprValue, setApr] = useState (0)
    const [mayValue, setMay] = useState (0)
    const [junValue, setJun] = useState (0)
    const [julValue, setJul] = useState (0)
    const [augValue, setAug] = useState (0)
    const [sepValue, setSep] = useState (0)
    const [octValue, setOct] = useState (0)
    const [novValue, setNov] = useState (0)
    const [decValue, setDec] = useState (0)
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)

    const screenWidth = window.screen.width
    const LoadJourneyAnswers = async() =>{
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );
        var test = 0

        if(resp.data.Resources )
        {
            test = resp.data.Resources.jrJanRevenue
            
            
            setJourneyData(resp.data.Resources);
            setJan(StringMask( resp.data.Resources.jrJanRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setFeb(StringMask( resp.data.Resources.jrFebRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setMar(StringMask( resp.data.Resources.jrMarRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setApr(StringMask( resp.data.Resources.jrAprRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setMay(StringMask( resp.data.Resources.jrMayRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setJun(StringMask( resp.data.Resources.jrJunRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setJul(StringMask( resp.data.Resources.jrJulRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setAug(StringMask( resp.data.Resources.jrAugRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setSep(StringMask( resp.data.Resources.jrSepRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setOct(StringMask( resp.data.Resources.jrOctRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setNov(StringMask( resp.data.Resources.jrNovRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setDec(StringMask( resp.data.Resources.jrDecRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
        }
    }


    const checkChanges = (changesState) =>{
        if (changesState) {
            setHaveChanges(true)

        }
    }
    

    const changetext =(e,source,date)=>{
 
        switch (source){

            case 'text1':{
                if(journeyData.jrIntelectual !== e.target.value && checkChanges !== "-1"){checkChanges(true)}
                setJourneyData({...journeyData, jrIntelectual : e.target.value})         
            }
            break;
            case 'text2':{
                if(journeyData.jrPhysical !== e.target.value && checkChanges !== "-1"){checkChanges(true)}
                setJourneyData({...journeyData, jrPhysical : e.target.value})
            }
            break;
            case 'text3':{
                if(journeyData.jrRevenues !== e.target.value && checkChanges !== "-1"){checkChanges(true)}  
                setJourneyData({...journeyData, jrRevenues : e.target.value})         
            }
            break;
            case 'text4':{
                if(journeyData.jrHuman !== e.target.value && checkChanges !== "-1"){checkChanges(true)}
                setJourneyData({...journeyData, jrHuman : e.target.value})
            }
            break;
            case 'text5':{
                if(journeyData.jrWaysPayment !== e.target.value && checkChanges !== "-1"){checkChanges(true)}
                setJourneyData({...journeyData, jrWaysPayment : e.target.value})         
            }
            break;
            case 'input1':{
                if(journeyData.jrMonths !== e.target.value && checkChanges !== "-1"){checkChanges(true)}
                setJourneyData({...journeyData, jrMonths : e.target.value})         
            }
            break;
            case 'moneyInput':{
                switch(date){
                    case 'jan':{



                        if(checkChanges!=="-1" && janValue !== StringMask( e.target.value, { type:'2DigitFraction' }, '' )){checkChanges(true)}
                        // setJourneyData({...journeyData, jrJanRevenue : StringMask( e.target.value, { type:'2DigitFraction' }, '' )})
                        setJan(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'feb':{
                        setFeb(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'mar':{
                        setMar(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'apr':{
                        setApr(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'may':{
                        setMay(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'jun':{
                        setJun(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'jul':{
                        setJul(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'aug':{
                        setAug(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'sep':{
                        setSep(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'oct':{
                        setOct(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'nov':{
                        setNov(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'dec':{
                        setDec(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;               
                }
            }
            break;
            default:
        }
        
    }

    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }

    var currencyMasky = (value) =>{

        if(value!== undefined ){
            value = value.replace('.', '').replace(',', '').replace(/\D/g, '');
            const options = { minimumFractionDigits: 2 }
            const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )
        return('R$'+ ' ' + result);
        }
        else{
            value = '0.00';
            value = value.replace('.', '').replace(',', '').replace(/\D/g, '');
            const options = { minimumFractionDigits: 2 }
            const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )
        return('R$'+ ' ' + result);
        }
        
    }
    

    useEffect( () => { LoadJourneyAnswers()}, [] );



    const changeHeight = () =>{
        const fullBoard = document.querySelector('.FullBoardContent')
        fullBoard.style.height = 'fit-content'


    }
    const [ screenDebounceTime, setScreenDebounceTime ] = useState( true );
    const pageLocale = 'Gestão Financeira'
    useEffect(()=>{
        changeHeight()
        botconversaWebhook({locale: pageLocale, action: 'Abriu Tributação'})
    })        




    const getUserTax = async() =>
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();

        axios.get( 'https://k7rmrfmr2vdbo4rk4ax4lm2kia0rvmng.lambda-url.us-east-2.on.aws/', { params: { company: userId, token: token } } ).then( ( tResp ) =>
        {
            if( tResp.data.tax )
            {
                document.getElementById( tResp.data.tax ).checked = true;
            }
        } );
    }
    const upadteAnswers = async({userId, token}) =>{   
        let newJourneyData = 
        {
        
            jrMonths: journeyData.jrMonths,
            jrJanRevenue: StringMask(janValue, { type:'float' }, '' ),
            jrFebRevenue: StringMask(febValue, { type:'float' }, '' ),
            jrMarRevenue: StringMask(marValue, { type:'float' }, '' ),
            jrAprRevenue: StringMask(aprValue, { type:'float' }, '' ),
            jrMayRevenue: StringMask(mayValue, { type:'float' }, '' ),
            jrJunRevenue: StringMask(junValue, { type:'float' }, '' ),
            jrJulRevenue: StringMask(julValue, { type:'float' }, '' ),
            jrAugRevenue: StringMask(augValue, { type:'float' }, '' ),
            jrSepRevenue: StringMask(sepValue, { type:'float' }, '' ),
            jrOctRevenue: StringMask(octValue, { type:'float' }, '' ),
            jrNovRevenue: StringMask(novValue, { type:'float' }, '' ),
            jrDecRevenue: StringMask(decValue, { type:'float' }, '' ),
        };

        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',     
        { company:userId, token:token, tag:'Resources', journeyData:newJourneyData } )
        .then(()=>{
        
        })   
    }







    const setNewTax = async() =>
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();
        const options = document.getElementsByName( "modeltax" );
        var selectedTax = 1;
        
        for( let index = 0; index < options.length; index++ )
        {
            selectedTax = options[ index ].checked ? options[ index ].id : selectedTax;
        }

        document.getElementById( 'SetNewTax' ).innerHTML = "Salvando...";

        upadteAnswers({userId, token})
        const tResp = await axios.put( 'https://vgfkg432zg6hb5zcnl67bljjee0grkth.lambda-url.us-east-2.on.aws/', { company: userId, token: token, tax: selectedTax } );
        
        
        
        
        const pageLocale = 'Gestão Financeira'
        botconversaWebhook({locale: pageLocale, action: 'Alterou Tributação'})
        
        document.getElementById( 'SetNewTax' ).innerHTML = "Confirmar";
    }

    const ShowInfoPopup = ( props ) =>
    {
        if( props.target.tagName === 'IMG' )
        {
            const ProfileMenuBox = props.target;
            var viewportOffset = ProfileMenuBox.parentNode.getBoundingClientRect();
            var top = viewportOffset.top + viewportOffset.height - 1;
            var left = viewportOffset.left - 1;
            var width = viewportOffset.width + 2;
            
            if( ProfileMenuBox.nextSibling.className )
            {
                ProfileMenuBox.nextSibling.className = 'InfoOverPopUp';
                ProfileMenuBox.nextSibling.style.top = top + 'px';
                ProfileMenuBox.nextSibling.style.left = left + 'px';
                ProfileMenuBox.nextSibling.style.width = width + 'px';
            }
        }
    }

    const HideInfoPopup = ( props ) =>
    {
        if( props.target.tagName === 'IMG' )
        {
            const ProfileMenuBox = props.nextSibling;
            ProfileMenuBox.className = 'DivHidden';
        }
    }
    const changeBackground = () =>{

        if (window.screen.width<780) {
            const fullBoardContent = document.querySelector('.FullBoardContent')

            fullBoardContent.style.minHeight = ''
            fullBoardContent.style.height = 'fit-content'            
        }


    }
    useEffect( () => { getUserTax(); changeBackground(); setTimeout( () => { setScreenDebounceTime( false ); }, 1000 ); }, [] );

    return(
        <div className='FullBoardContent' style={{minHeight: window.screen.width>780 && '0'}}>
            <div className='BoardTitleBtnsInline'><div id="SetNewTax" className="BoardBtnOk" onClick={ setNewTax }>Confirmar</div></div>
            <div className="FullBoardContentTitle">Escolha seu regime tributário</div>
            <div className="FullBoardContentSubTitle">
                Escolha a caixa abaixo que mais se aplica ao regime tributário do seu negócio.<br/>
                Caso tenha dúvida sobre o regime, passe o mouse no símbolo "i" da opção para mais informações.
            </div>
            <div className='HorizontalLineSeparator'/>
            <div className="FullBoardContentTitle" style={{fontSize:'1.2rem',marginLeft:'0.4rem',marginBottom:'0.5rem'}} >
                Simples Nacional
            </div>
            <div style={ { width: '100%', float: 'left' } }>
                <div className="taxCheckBox">
                    <label>
                        <input id="1" type='radio' name="modeltax"/>
                        <span>
                            <div style={ { display: 'inline' } }>Comércio (Anexo I)</div>
                            <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                            <div className='DivHidden'>Participantes: Comércio</div>
                        </span>
                    </label>
                </div>
                <div className="taxCheckBox">
                    <label>
                        <input id="2" type='radio' name="modeltax"/>
                        <span>
                            <div style={ { display: 'inline' } }>Indústrias (Anexo II)</div>
                            <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                            <div className='DivHidden'>Participantes: Indústrias</div>
                        </span>
                    </label>
                </div>
            </div>
            <div style={ { width: '100%', float: 'left' } }>
                <div className="taxCheckBox">
                    <label>
                        <input id="3" type='radio' name="modeltax"/>
                        <span>
                            <div style={ { display: 'inline' } }>Anexo III (Alguns serviços e Locação de bens móveis)</div>
                            <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                            <div className='DivHidden'>Participantes: receitas de locação de bens móveis e de prestação de serviços não relacionados no Anexo IV</div>
                        </span>
                    </label>
                </div>
            </div>
            <div style={ { width: '100%', float: 'left' } }>
                <div className="taxCheckBox">
                    <label>
                        <input id="4" type='radio' name="modeltax"/>
                        <span>
                            <div style={ { display: 'inline' } }>Anexo IV (Serviços de construção, paisagismo e outros)</div>
                            <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                            <div className='DivHidden'>Participantes:  construção de imóveis e obras de engenharia em geral, inclusive sob a forma de subempreitada, execução de projetos e serviços de paisagismo, bem como decoração de interiores; serviço de vigilância, limpeza ou conservação; serviços advocatícios.</div>
                        </span>
                    </label>
                </div>
            </div>
            <div style={ { width: '100%', float: 'left' } }>
                <div className="taxCheckBox">
                    <label>
                        <input id="5" type='radio' name="modeltax"/>
                        <span>
                            <div style={ { display: 'inline' } }>Anexo V (Outros serviços e atividades)</div>
                            <img src={ InfoIconImg } alt='...' style={ { marginLeft: '7px' } } onMouseEnter={ ShowInfoPopup } onMouseLeave={ HideInfoPopup }/>
                            <div className='DivHidden'>Participantes:  medicina veterinária; serviços de comissaria, de despachantes, de tradução e de interpretação; engenharia, medição, cartografia, topografia, geologia, geodésia, testes, suporte e análises técnicas e tecnológicas, pesquisa, design, desenho e agronomia; representação comercial e demais atividades de intermediação de negócios e serviços de terceiros; perícia, leilão e avaliação; auditoria, economia, consultoria, gestão, organização, controle e administração; jornalismo e publicidade; agenciamento, exceto de mão de obra; outras atividades do setor de serviços que tenham por finalidade a prestação de serviços decorrentes do exercício de atividade intelectual, de natureza técnica, científica, desportiva, artística ou cultural, que constitua profissão regulamentada ou não, desde que não sujeitas à tributação na forma dos Anexos III ou IV.</div>
                        </span>
                    </label>
                </div>
            </div>
            <div className="FullBoardContentTitle" style={{fontSize:'1.2rem',marginLeft:'0.4rem',marginBottom:'0.5rem'}} >
                MEI
            </div>
            <div style={ { width: '100%', float: 'left' } }>
                <div className="taxCheckBox">
                    <label>
                        <input id="6" type='radio' name="modeltax"/>
                        <span>
                            <div style={ { display: 'inline' } }>MEI - Comércio e serviços</div>
                        </span>
                    </label>
                </div>
                <div className="taxCheckBox">
                    <label>
                        <input id="7" type='radio' name="modeltax"/>
                        <span>
                            <div style={ { display: 'inline' } }>MEI - Comércio ou indústria</div>
                        </span>
                    </label>
                </div>
            </div>
            <div style={ { width: '100%', float: 'left' } }>
                <div className="taxCheckBox">
                    <label>
                        <input id="8" type='radio' name="modeltax"/>
                        <span>
                            <div style={ { display: 'inline' } }>MEI - Prestação de serviços</div>
                        </span>
                    </label>
                </div>
            </div>



            <div style={{float:'left', marginTop: '30px'}}>

            <div>
                    <div> <div>   
                        <text className="journeyFWQQuestionText" style={{marginRight: '12px'}}>Quantos meses o seu negócio possui?

                        </text>
                        <select className="journeyFWQQuestionText journeyFWQMoneyInput" style={{marginLeft:'0rem',width:'5rem'}} value = {(journeyData !== null) ? journeyData.jrMonths : '1'} onChange={(e) => changetext(e,'input1')}>
                            <option className="journeyFWQQuestionText">1</option>
                            <option className="journeyFWQQuestionText">2</option>
                            <option className="journeyFWQQuestionText">3</option>
                            <option className="journeyFWQQuestionText">4</option>
                            <option className="journeyFWQQuestionText">5</option>
                            <option className="journeyFWQQuestionText">6</option>
                            <option className="journeyFWQQuestionText">7</option>
                            <option className="journeyFWQQuestionText">8</option>
                            <option className="journeyFWQQuestionText">9</option>
                            <option className="journeyFWQQuestionText">10</option>
                            <option className="journeyFWQQuestionText">11</option>
                            <option className="journeyFWQQuestionText">12</option>
                            <option className="journeyFWQQuestionText">12+</option>
                        </select>
                    </div>
                            <QuestionsInformation information="Caso seu negócio tenha acabado de começar, coloque 1. "/>                    
                    </div>
                    <div style={{display:'flex', flexDirection:'column',marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText">Qual foi a receita do seu negócio nos últimos 12 meses?
                            <QuestionsInformation information="Caso não saiba os números exatos, coloque aproximadamente. Caso seu negócio ainda não tenha faturado, coloque 0."/>
                        </text>
                        <div style={{display:'flex', flexDirection: screenWidth<780 &&'column', marginTop:'1rem',marginLeft:'2rem',gap: screenWidth>780 ? '3rem' : '2px'}}>
                            <div style={{display:'flex', flexDirection:'column',width:'15.5rem',gap:'0.2rem'}}>
                                <div style={{display:'flex', flexDirection: 'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Janeiro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ?  janValue : '---'}   onChange={(e)=>changetext(e,'moneyInput','jan')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Fevereiro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? febValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','feb')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Março:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? marValue : '---'}  onChange={(e)=>changetext(e,'moneyInput','mar')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Abril:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? aprValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','apr')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Maio:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? mayValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','may')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Junho:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? junValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','jun')}></input>
                                </div>
                            </div>
                            <div style={{display:'flex', flexDirection:'column',width:'16.2rem',gap:'0.2rem'}}>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Julho:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? julValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','jul')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Agosto:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? augValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','aug')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Setembro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? sepValue : '---'}  onChange={(e)=>changetext(e,'moneyInput','sep')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Outubro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? octValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','oct')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Novembro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? novValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','nov')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Dezembro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? decValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','dec')}></input>
                                </div>
                            </div>
                        </div>
                    </div>

    </div>




            </div>
        </div>
    );
};

export default Taxes;