import { isNaN } from "formik"
import { removeLeftNullNumbers } from "./removeLeftNullNumbers"
import { convertTimeFormat } from "./convertTimeFormat"

export const convertToSemanticTimeFormat = (time) => {




  const changedTime = convertTimeFormat(time)
  const splitedTime = changedTime.split(':')
  const splitedTimeLength = splitedTime.length

  let arrayOfTime = splitedTime
  let timeAbreviation = ''
  if (splitedTimeLength>2) {   
    timeAbreviation = 'h'
  } else {
    timeAbreviation = 'm'
  }
    arrayOfTime.pop()
  arrayOfTime[0] = arrayOfTime[0] + timeAbreviation
  const newTime = arrayOfTime.join('') 

  return newTime
}