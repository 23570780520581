import React from 'react'
import styles from './MobileKYMStructure.module.sass'
import { CProgress, CProgressBar } from '@coreui/react'
import JourneysideMenu from '../../JourneysideMenu'
import menuJourney from '../../../../assets/icons/iconMenuJourney.png'

const MobileKYMStructure = ({title, type, content, prevVideoPath, nextVideoPath, stepPercent, OpenSideDiv, height}) => {
  return (

    <div className="journeyKYMBackground" style={{overflow:'hidden', position: 'relative', height: height}}>
    <i className="fa fa-bars" id={styles.menuIcon} onClick={OpenSideDiv}/>
    
    <div style={{position:'relative',
                top: '4.4rem',
                left: '1.6rem',
                width:'50%'}}>
        <text className="journeyKYMHeader" style={{fontSize: '26px'}}>
            {type}</text>
    </div>
        <div className="journeyKYMTextBackground" style={{position:'relative',top:'82px', left:'0px', width: '96vw'}}>
            <text className="journeyKYMTitleBlurDiv" >{title}</text>
            <div style={{width:'24rem', marginTop:'1rem'}}>
            <text className="journeyKYMText" style={{fontSize:'1.1rem'}} dangerouslySetInnerHTML={{ __html: content}}>
                
            </text>
            </div>
        </div>  
    <div className="journeyKYMVideoDiv" style={{bottom: '90px'}}>
    <a href={nextVideoPath} className="journeyKYMlink" style={{textDecoration:'none', gap: '1rem'}}>
        <div style={{display:'flex',
                    flexDirection:'column',
                    marginLeft:'1.5rem',
                    marginTop:'0.8rem',
                    marginBottom:'0.8rem'}}>
            <text className="journeyKYMVideoUpperTxt">Próximo conteúdo</text>
            <text className="journeyKYMVideoLowerTxt">Vídeo</text>
        </div>
            <div className="journeyKYMArrowDiv">
                <text className="journeyKYMArrow">&#8250;</text>
            </div>
            </a>   
    </div> 
    <text className="journeyKYMprogressText" style={{position: 'absolute', top:'68px', left: '22px', fontSize: '16px'}}>
        Seu progresso
    </text>      
    <div style={{position:'absolute',
                top:'70px',
                left:'150px',
                paddingLeft:'0.4rem',
                paddingRight:'0.4rem',
                borderRadius:'0.3rem',
                backgroundColor:'#FFFFFF',
                width:'fit-content'}}>
        <text className="journeyKYMprogressBarText">
            {stepPercent}% completo
        </text>
    </div>
    <div style={{position:'absolute',
                top:'100px',
                left:'22px',
                width:'20rem'
                }}>
        <CProgress height={ 6 }  >
            <CProgressBar value={ stepPercent } />
        </CProgress>
    </div>
    <a href={prevVideoPath} className={styles.backArrow} style={{textDecoration:'none'}}>
        <div className={styles.backButton}>
            <text className="textBackButton1">&#8249;</text>
        </div>
    </a>
    <JourneysideMenu></JourneysideMenu>
</div>

  )
}

export default MobileKYMStructure