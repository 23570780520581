import React from "react";
import "../questions/questions.css";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import axios from "axios";
import { useState,useEffect } from "react";
import UserStore from "../../store/userStore";
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import { FailAddElement } from "../failAddElement/FailAddElement";


const ActivitiesQuestions = (props)=>{
    const activeConfirmScreen = props.activeConfirmScreen
    const [ journeyData, setJourneyData] = useState( null );
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)
    const [openSave, setOpenSave] = useState(0)
    



    useEffect(()=>{
        console.log(activeConfirmScreen);console.log('SSSS')



    }, [activeConfirmScreen])
    const LoadJourneyAnswers = async() =>{
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );

        if(resp.data.How )
        {
            setJourneyData(resp.data.How);
        }
    }   
    const saveAnswers = async()=>{

        setLoading(true)
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData = 
        { 
            jhActivitiesProduct:journeyData.jhActivitiesProduct, 
            jhActivitiesMarketing:journeyData.jhActivitiesMarketing,
            jhActivitiesSell:journeyData.jhActivitiesSell,
            jhActivitiesEnterprise:journeyData.jhActivitiesEnterprise
        };




        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'How', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })
        
        document.getElementById( 'saveBtn' ).innerHTML = "Salvar";

        

    }

    const changetext =(e,source,choice)=>{

        switch (source){

            case 'text1':{     
                if(journeyData.jhActivitiesProduct !== e.target.value && props.checkChanges !== "-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jhActivitiesProduct : e.target.value})         
            }
            break;
            case 'text2':{
                if(journeyData.jhActivitiesMarketing !== e.target.value && props.checkChanges !== "-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jhActivitiesMarketing : e.target.value})
            }
            break;
            case 'text3':{
                if(journeyData.jhActivitiesSell !== e.target.value && props.checkChanges !== "-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jhActivitiesSell : e.target.value})
            }
            break;
            case 'text4':{
                if(journeyData.jhActivitiesEnterprise !== e.target.value && props.checkChanges !== "-1" ){props.checkChanges(true)}
                setJourneyData({...journeyData, jhActivitiesEnterprise : e.target.value})
            }
            break;

        }
        
    }

    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }


    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()
        //props.redirect()


    }



    
    console.log(props);console.log('ENTER ')
    useEffect( () => { LoadJourneyAnswers()}, [] );

    return(
        <div className="journeyFWQQuestionsLayout1" >  
        {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>}  
        {loading && <LoadingScreen/>}
        {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>} 
        {activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.disableConfirmScreen}/>}


                <div className="journeyQuestionsSaveBtn"  id='saveBtn' onClick={saveAnswers}>
                    Salvar
                </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText" style={{marginBottom:'2.5rem'}}>O que é necessário para desenvolver seu negócio?</text>
                        <text className="journeyFWQQuestionText" style={{marginBottom:'0.2rem'}}><li>Atividades de produto/serviço
                                <QuestionsInformation haveTopics={true} breakLine={true} information="As atividades referentes ao produto/serviço em um modelo de negócio podem variar dependendo do setor e da natureza do negócio. 
                                
                                <br/>No entanto, aqui estão algumas atividades comuns relacionadas ao produto/serviço:



                                                <br/>Pesquisa e desenvolvimento (P&D):-> Engajar-se em pesquisa para desenvolver novos produtos/serviços ou aprimorar os existentes.
                                                <br/>Design e prototipagem:-> Criar e aperfeiçoar o design e os protótipos dos produtos/serviços para garantir sua funcionalidade e atratividade.
                                                <br/>Fabricação/Produção:-> Produzir ou fabricar os produtos/serviços em conformidade com os padrões de qualidade estabelecidos.
                                                <br/>Aquisição de matérias-primas:-> Identificar e adquirir as matérias-primas necessárias para a produção dos produtos/serviços.
                                                <br/>Controle de qualidade:-> Implementar processos e procedimentos para garantir a qualidade dos produtos/serviços durante todas as etapas de produção.
                                                <br/>Embalagem e rotulagem:-> Criar embalagens adequadas e aplicar rótulos informativos e atraentes nos produtos/serviços.
                                                <br/>Distribuição e logística:-> Gerenciar a logística e a cadeia de suprimentos para garantir a entrega eficiente dos produtos/serviços aos clientes.
                                                <br/>Instalação e implementação:-> Realizar a instalação e implementação adequada de produtos/serviços que exijam configuração ou instalação específicas.
                                                <br/>Manutenção e suporte:-> Fornecer serviços de manutenção e suporte técnico para garantir o bom funcionamento contínuo dos produtos/serviços.
                                                <br/>Atualizações e melhorias:-> Realizar atualizações, melhorias e lançamento de novas versões dos produtos/serviços para atender às demandas do mercado e às necessidades dos clientes.
                                "/>
                            </li></text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea1' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jhActivitiesProduct : '---'} onChange={(e) => changetext(e,'text1')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea1')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea1')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2"  style={{marginTop:'0rem'}}>
                        <text className="journeyFWQQuestionText" style={{marginBottom:'0.2rem'}}><li>Atividades de marketing
                                <QuestionsInformation haveTopics={true} breakLine={true} information="As atividades de marketing em um modelo de negócio desempenham um papel crucial na promoção, comunicação e venda de produtos/serviços. 
                                
                                
                                
                                <br/>Aqui estão algumas atividades comuns relacionadas ao marketing:

                                    <br/>Desenvolvimento de marca:-> Criar uma identidade e personalidade de marca distintas, incluindo a definição de valores, missão, visão, slogan e elementos visuais (logotipo, cores, etc.).
                                    <br/>Estratégia de posicionamento:-> Definir a forma como o produto/serviço será posicionado em relação aos concorrentes, destacando seus pontos fortes e diferenciais.
                                    <br/>Desenvolvimento de produtos/serviços:-> Colaborar com equipes internas para desenvolver produtos/serviços que atendam às necessidades do mercado e estejam alinhados com a estratégia de marketing.
                                    <br/>Promoção e publicidade:-> Planejar e executar campanhas de publicidade e promoção para aumentar a conscientização e o interesse dos clientes em relação aos produtos/serviços.
                                    <br/>Marketing digital:-> Utilizar estratégias de marketing online, como marketing de conteúdo, mídias sociais, e-mail marketing, otimização de mecanismos de busca (SEO) e publicidade online para alcançar o público-alvo e gerar leads.
                                    <br/>Relações públicas:-> Gerenciar a comunicação com o público, incluindo relações com a imprensa, eventos, parcerias e patrocínios para fortalecer a reputação da marca.
                                    <br/>Vendas e distribuição:-> Definir estratégias e táticas para impulsionar as vendas, incluindo canais de distribuição, precificação, negociação e gestão de relacionamento com clientes.
                                    <br/>Análise de dados de marketing:-> Coletar e analisar dados de marketing para avaliar a eficácia das estratégias e tomar decisões informadas para otimizar os esforços de marketing.
                                        "/>
                            </li></text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea2' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jhActivitiesMarketing : '---'} onChange={(e) => changetext(e,'text2')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea2')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea2')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2"  style={{marginTop:'0rem'}}>
                        <text className="journeyFWQQuestionText" style={{marginBottom:'0.2rem'}}><li>Atividades de venda
                                <QuestionsInformation haveTopics={true} breakLine={true} information="As atividades de venda em um modelo de negócio desempenham um papel fundamental na conversão de leads e prospects em clientes pagantes. 
                                
                                
                                
                                <br/>Aqui estão algumas atividades comuns relacionadas à venda:
                                    <br/>Prospecção de clientes:-> Identificar e abordar potenciais clientes que possam ter interesse nos produtos/serviços oferecidos.
                                    <br/>Qualificação de leads:-> Avaliar a adequação e o potencial dos leads gerados por meio de atividades de marketing, determinando quais são mais propensos a se tornarem clientes.


                                    <br/>Apresentação e demonstração:-> Apresentar e demonstrar os produtos/serviços aos potenciais clientes, destacando seus recursos, benefícios e valor agregado.
                                    <br/>Negociação e fechamento de vendas:-> Conduzir negociações com os clientes, discutindo termos, condições e preços, com o objetivo de chegar a um acordo satisfatório e fechar a venda.
                                    <br/>Gestão de relacionamento com o cliente:-> Estabelecer e manter relacionamentos de longo prazo com os clientes, fornecendo suporte contínuo, atendimento ao cliente e acompanhamento pós-venda.
                                    <br/>Gestão de pipeline de vendas:-> Gerenciar e monitorar o pipeline de vendas, acompanhar o progresso das oportunidades de vendas em diferentes estágios e garantir ações adequadas para avançar no processo de vendas.
                                    <br/>Gestão de pedidos:-> Coordenar e gerenciar o processo de pedidos, incluindo o recebimento, processamento, rastreamento e entrega dos produtos/serviços aos clientes.
                                    <br/>Upselling e cross-selling:-> Identificar oportunidades de aumentar o valor das vendas, oferecendo produtos/serviços adicionais (upselling) ou complementares (cross-selling) aos clientes existentes.
                                    <br/>Análise de desempenho de vendas:-> Acompanhar e analisar métricas de vendas, como número de leads gerados, taxa de conversão, valor médio de vendas, entre outros, para avaliar o desempenho e identificar áreas de melhoria.
                                    <br/>Treinamento e desenvolvimento de vendas:-> Fornecer treinamento contínuo aos membros da equipe de vendas para aprimorar suas habilidades de vendas, conhecimento do produto/serviço e técnicas de negociação."/>
                            </li></text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jhActivitiesSell : '---'} onChange={(e) => changetext(e,'text3')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea3')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea3')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2"  style={{marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText" style={{marginBottom:'0.2rem'}}><li>Atividades da empresa
                                <QuestionsInformation haveTopics={true} topicsInsideTopics={true} breakLine={true} information="São as atividades administrativas e financeiras que são essenciais para o funcionamento eficiente e sustentável de uma empresa. 
                                
                                
                                
                                <br/>Aqui estão algumas atividades comuns relacionadas a essas áreas:

                                <br/>Atividades administrativas:

                                <br/>Gestão de recursos humanos:-> Recrutamento, contratação, treinamento, avaliação e desenvolvimento de funcionários.
                                <br/>Gestão de operações:-> Supervisão e coordenação das atividades diárias, monitoramento de processos, gestão de fornecedores e estoque.
                                <br/>Gestão de projetos:-> Planejamento, execução e controle de projetos para alcançar objetivos específicos dentro de prazos e orçamentos definidos.
                                <br/>Gestão de qualidade:-> Implementação de sistemas de gestão da qualidade, controle de qualidade e melhoria contínua dos processos.
                                <br/>Gestão de tecnologia da informação:-> Gerenciamento dos recursos de TI, infraestrutura, segurança de dados, suporte técnico e implementação de sistemas e software.
                                <br/>Gestão de ativos:-> Gerenciamento dos ativos físicos e financeiros da empresa, como propriedades, equipamentos, investimentos e patrimônio líquido.
                                <br/>Gestão de relacionamento com fornecedores e parceiros:-> Manutenção de relacionamentos e negociação com fornecedores e parceiros comerciais estratégicos.
                                <br/>Gestão de conformidade e regulamentação:-> Garantir que a empresa esteja em conformidade com leis, regulamentos e padrões relevantes ao seu setor.

                                <br/>Atividades financeiras:

                                <br/>Contabilidade:-> Registro e análise das transações financeiras, preparação de demonstrativos financeiros, controle de custos e elaboração de relatórios financeiros.
                                <br/>Planejamento financeiro:-> Elaboração de planos financeiros de curto e longo prazo, incluindo orçamentos, previsões de receitas e despesas, e estratégias de investimento.
                                                <br/>Gestão de fluxo de caixa:-> Monitoramento e gestão do fluxo de entrada e saída de caixa, gerenciamento de crédito e cobrança.
                                                <br/>Gestão de tesouraria:-> Gerenciamento de contas bancárias, investimentos, captação de recursos, negociação com instituições financeiras e gestão de riscos financeiros.
                                                <br/>Análise financeira:-> Avaliação do desempenho financeiro da empresa, análise de indicadores financeiros, projeções financeiras e identificação de oportunidades de melhoria.
                                                <br/>Gestão de impostos:-> Cumprimento das obrigações fiscais, elaboração e apresentação de declarações fiscais, planejamento tributário e otimização de benefícios fiscais.
                                                <br/>Gestão de riscos financeiros:-> Identificação, avaliação e mitigação de riscos financeiros, como riscos cambiais, de liquidez, de crédito e operacionais.
                                                <br/>Gestão de investimentos:-> Análise e tomada de decisões sobre investimentos em ativos financeiros, como ações, títulos e outros instrumentos financeiros."/>
                            </li></text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea4' className="journeyFWQTextArea" placeholder="Digite sua resposta."  value ={(journeyData !== null) ? journeyData.jhActivitiesEnterprise : '---'} onChange={(e) => changetext(e,'text4')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea4')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea4')}></img>
                        </div>
                    </div>
                </div>
    )
}

export default ActivitiesQuestions;