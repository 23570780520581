import React, { useState } from 'react'
import styles from './tableJourneyCard.module.css'
const TableJourneyCard = ({tableData}) => {
    console.log(tableData)
    const title = 'Caracteristicas Produto/Serviço';
    const importance = 'Alta';
    const [textImportance, setTextImportance] = useState('');

    const defineImportanceText = (importance) =>{

       switch (importance) {

       
        case 0:
            return 'Muito Alta';
        case 1:
            return 'Alta';
        case 2:
            return 'Normal';
        case 3:
            return 'Baixa';

        default:
            return;
}


    }
    const checkImportance = (importance) =>{
        switch (importance) {
            case 0:
                return 'rgba(223, 38, 38, 1)';
            case 1:
                return 'rgba(244, 123, 32, 1)';
            case 2:
                return '#dfb51c';
            default:
                return;
        }



    }

  return (
    <div className={styles.tableJourneyCard}>
        <p>{title}</p>
        <div>
            <table>

                <thead>
                <tr>
                    <th>PRODUTO</th>
                    <th>PREÇO</th>
                    <th style={{padding: '10px 68px 10px 30px'}}>IMPORTANCIA</th>
                </tr></thead>

                <tbody className={styles.tableContent}>
                {!tableData && <tr><td>Carregando, por favor aguarde...</td></tr>}
                {tableData && tableData.map(data=>(

                <tr>
                    <td>{data.name}</td>
                    <td>{data.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>

                    {

                        
                        <td>
                            <div style={{backgroundColor: checkImportance(data.importance)}}>{defineImportanceText(data.importance) }</div>
                            
                            </td>




                    }
                    
                </tr>



                ))}
</tbody>



            </table>
        </div>
    </div>
  )
}

export default TableJourneyCard