/* eslint-disable default-case */
import React from "react";
import "../questions/questions.css";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import UserStore from "../../store/userStore";
import { useState,useEffect } from "react";
import axios from "axios";
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import { FailAddElement } from "../failAddElement/FailAddElement";

const TargetPublic = (props)=>{

    const [ journeyData, setJourneyData] = useState( null );
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)


    const LoadJourneyAnswers = async() =>{
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();

        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );

        if(resp.data.ForWhom )
        {
           setJourneyData(resp.data.ForWhom);
        }
   }

    const saveAnswers = async()=>{



        setLoading(true)
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData = 
        {
            jfwConsumerProfile:journeyData.jfwConsumerProfile, 
            jfwConsumerPreference:journeyData.jfwConsumerPreference, 
            jfwInfluencyFactor:journeyData.jfwInfluencyFactor,
            jfwAquisitionPeriod:journeyData.jfwAquisitionPeriod,
            jfwConsumerLocation:journeyData.jfwConsumerLocation,
            jfwConsumerReach:journeyData.jfwConsumerReach
        };

        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'ForWhom', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })

        document.getElementById( 'saveBtn' ).innerHTML = "Salvar";

    }

    const changetext = ( e, source, choice ) =>
    {
        switch( source )
        {
            case 'text1':
                if(journeyData.jfwConsumerProfile !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwConsumerProfile : e.target.value});
            break;
            case 'text2':
                if(journeyData.jfwConsumerPreference !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwConsumerPreference : e.target.value});
                break;
            case 'text3':    
            if(journeyData.jfwInfluencyFactor !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwInfluencyFactor : e.target.value});
                break;
            case 'text4':
                if(journeyData.jfwAquisitionPeriod !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwAquisitionPeriod : e.target.value});
                break;
            case 'text5':   
            if(journeyData.jfwConsumerLocation !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwConsumerLocation : e.target.value});
                break;
            case 'text6':
                if(journeyData.jfwConsumerReach !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jfwConsumerReach : e.target.value});
                break;
        }    
    }

    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }

    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()
    


    }


    useEffect( () => { LoadJourneyAnswers()}, [] );

    return(
        <div className="journeyFWQQuestionsLayout1">   
        {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>} 
        {loading && <LoadingScreen/>}
        {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>}
        {props.activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.redirect}/>}

               <div className="journeyQuestionsSaveBtn"  id='saveBtn' onClick={saveAnswers}>
                    Salvar
                </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Quais são as características demográficas do seu público-alvo?
                            <QuestionsInformation information="As características podem ser gênero, renda, idade, escolaridade, ocupação, estado civil, religião ou etnia."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea1' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwConsumerProfile : '---'} onChange={(e) => changetext(e,'text1')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea1')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea1')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Quais são as características geográficas do seu público-alvo?
                            <QuestionsInformation information="As características podem ser cidade, país, região que o público-alvo trabalha ou frequenta; tamanho da cidade, se é uma região urbana, residencial, rural ou comercial."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea2' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwConsumerPreference : '---'} onChange={(e) => changetext(e,'text2')} 
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea2')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea2')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Quais são as características psicológicas do seu público-alvo?
                            <QuestionsInformation haveTopics={true} breakLine={true} information="Personalidade:-> é o conjunto de traços que definem a forma de pensar, sentir e agir de uma pessoa. Por exemplo, se ela é extrovertida ou introvertida, otimista ou pessimista, racional ou emocional.
                                    <br/>Valores:-> os princípios que orientam as escolhas e as ações de uma pessoa. Por exemplo, se ela valoriza a liberdade, a segurança, a justiça e a honestidade.
                                    <br/>Estilo de vida:-> o modo como uma pessoa organiza sua rotina, seus hábitos e suas preferências. Por exemplo, se ela é sedentária ou ativa, se gosta de viajar ou ficar em casa, se consome produtos orgânicos ou industrializados.
                                    <br/>Motivações:-> os fatores que impulsionam uma pessoa a buscar seus objetivos e satisfazer suas necessidades. Por exemplo, se ela busca reconhecimento, realização pessoal, diversão, aprendizado.
                                    <br/>Desafios:-> os obstáculos que uma pessoa enfrenta para alcançar seus objetivos e resolver seus problemas. Por exemplo, se ela tem dificuldade financeira, falta de tempo, falta de informação, falta de confiança."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwInfluencyFactor : '---'} onChange={(e) => changetext(e,'text3')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea3')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea3')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2" >
                        <text className="journeyFWQQuestionText">Quais são as características comportamentais do seu público-alvo?
                            <QuestionsInformation haveTopics={true} breakLine={true} information="Hábitos de compra:-> o modo como uma pessoa realiza suas compras, considerando fatores como frequência, quantidade, valor, forma de pagamento, canal de compra.
                                <br/>Preferências de marca:-> o grau de afinidade e satisfação que uma pessoa tem com determinadas marcas, produtos ou serviços, influenciando sua decisão de compra e fidelização.
                                <br/>Nível de lealdade:-> o comprometimento e a confiança que uma pessoa tem com uma marca, produto ou serviço, demonstrando sua disposição em repetir a compra e recomendar para outras pessoas.
                                <br/>Estágio do ciclo de vida do cliente:-> a fase em que uma pessoa se encontra na jornada de compra, desde o momento em que ela reconhece um problema até o momento em que ela se torna um cliente fiel e promotor da marca."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea4' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwAquisitionPeriod : '---'} onChange={(e) => changetext(e,'text4')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea4')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea4')}></img>
                        </div>
                    </div>
                    {/* <div className="journeyFWQQuestionsLayout2" >
                        <text className="journeyFWQQuestionText">Onde está o seu público-alvo?</text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea5' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwConsumerLocation : '---'} onChange={(e) => changetext(e,'text5')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea5')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea5')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2" style={{marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText">De que forma alcanço o público-alvo?</text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea6' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jfwConsumerReach : '---'} onChange={(e) => changetext(e,'text6')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e)=>(e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea6')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea6')}></img>
                        </div>
                    </div>
                */}</div>
    )
}

export default TargetPublic;