import React from "react";
import { CProgress, CProgressBar } from '@coreui/react'
import "../journey/JourneyForWhomVideo.css";
import menuJourney from "../../assets/icons/iconMenuJourney.png";
import JourneysideMenu from "./JourneysideMenu";
import styles from './JourneyAll.module.css';
import axios from "axios";
import { useEffect } from "react";
import UserStore from "../../store/userStore";
import MobileVideoStructure from "./mobileJourney/mobileVideoStructure/MobileVideoStructure";
import { botconversaWebhook } from "../../functions/botconversaWebhook";
const  JourneyDistributionChannelVideo = () => {
    
    
    const pageLocale = 'Jornada'
    useEffect(()=>{

        botconversaWebhook({locale: pageLocale, action: 'Abriu Vídeo - Canais de Distribuição'})

        const postStep = async(step) =>{
            await axios.put('https://iz5smq7lxhsxjtskiegdcibiru0vwhjh.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), token: UserStore.getToken(), journeyStep: step})
        }
        postStep(26) 
    }, [])
    const OpenSideDiv = ()=>{
        let sideDiv = document.getElementById('journey1SideDiv');
        sideDiv.style.display='block';
    }

    var Video1 = "https://arbbo-str-meconecte.s3.us-east-2.amazonaws.com/videos/Aula+5.mp4";

    const type = 'Entendendo seu modelo de negócio'
    const videoTitle = 'Canais de Distribuição'    
    const videoResponsible = 'Professor: Nelson Naibert'
    const stepPercent  = 53
    const prevVideoPath = '/journey/valueproposition/questions'
    const nextVideoPath =  "/journey/communicationchannel/video"
    const nextVideoName = 'Canais de Comunicação'
    const screenWidth = window.screen.width






    

    return(    <> {screenWidth>780 ? 
        <div className="journeyKYMBackground">
            <img src={menuJourney} alt-='' className="journeyKYMIconMenu" onClick={OpenSideDiv}>
            </img>
            <div style={{position:'relative',
                        top: '1.7rem',
                        left: '6.8rem',
                        width:'12rem'}}>
                <text className="journeyKYMHeader">Entendendo seu modelo de negócio</text>
            </div> 
            <div className="journeyFWVQuestionsDiv" style={{position:'relative',
                                                        top:'32rem',
                                                        left:'67rem'
                                                        }}>
            <a href={nextVideoPath} className="journeyKYMlink" style={{textDecoration:'none',
                                                                                            gap:"1rem"}}>
                <div style={{display:'flex',
                            flexDirection:'column',
                            marginLeft:'1.5rem',
                            marginTop:'0.8rem',
                            marginBottom:'0.8rem'}}>
                    <text className="journeyKYMVideoUpperTxt">Próximo conteúdo</text>
                    <text className="journeyKYMVideoLowerTxt">{nextVideoName}</text>
                </div>
                    <div className="journeyKYMArrowDiv" style={{cursor:'pointer',
                                                                marginRight:'1rem',
                                                                }}>
                        <text className="journeyKYMArrow" >&#8250;</text>
                    </div>
            </a>
            </div>    
            <text className="journeyFWVprogressText">
                Seu progresso
            </text>      
            <div style={{position:'relative',
                        top:'-7rem',
                        left:'75.2rem',
                        paddingLeft:'0.4rem',
                        paddingRight:'0.4rem',
                        borderRadius:'0.3rem',
                        backgroundColor:'#FFFFFF',
                        width:'fit-content'}}>
                <text className="journeyKYMprogressBarText">
                    {stepPercent}% completo
                </text>
            </div>
            <div style={{position:'relative',
                        top:'-6.5rem',
                        left:'67rem',
                        width:'20rem'}}>
                <CProgress height={ 6 }  >
                    <CProgressBar value={ stepPercent } />
                </CProgress>
            </div>
            <div className="journeyFWVVideoDiv">
                <video src={Video1} width="500" height="300" controls="controls" controlsList="nodownload">                
                </video>
                <text className="journeyFWVVideoTitle">{videoTitle}</text>
                <text className="journeyFWVVideoSubtitle">Professor: Nelson Naibert</text>
            </div>
            <a href={prevVideoPath} className={styles.backArrow} style={{textDecoration:'none'}}>
                <div className="backButton1">
                    <text className="textBackButton1">&#8249;</text>
                </div>
            </a>
            <JourneysideMenu></JourneysideMenu>
        </div>: 
        
    <MobileVideoStructure
        type={type} 
        videoTitle={videoTitle} 
        videoResponsible={videoResponsible} 
        videoUrl={Video1} 
        stepPercent={stepPercent} 
        prevVideoPath={prevVideoPath}
        nextVideoPath={nextVideoPath}
        nextVideoName={nextVideoName}
        height="600px"    



        OpenSideDiv={OpenSideDiv}
    />}</>
    )
}

export default JourneyDistributionChannelVideo;