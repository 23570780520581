import UserStore from '../../store/userStore';
import React from 'react';
import { ModelsData } from "../library/ModelsData";
import { useState } from 'react';
import getImgLogoByKey from "../imgsLoader/LoadBrandImgs"
import { BulbIconImg, PeopleIconImg, CoinIconImg, GearIconImg } from '../imgsLoader/LoadIconImgs';
import axios from 'axios';



const dateDiff = function (date1, date2) {
    const dt1 = new Date(date1);
    const dt2 = new Date(date2);

    let calculated =    Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24) )


    return calculated;
   ;}


const Model = ( props ) => {

    const screenWidth = window.screen.width

    const [canDoTestAgain, setCanDoTestAgain] = useState(false);

    axios.get('https://uy622za3v6g6ua6qfjvxujku7u0lavug.lambda-url.us-east-2.on.aws/', { params:{ company:UserStore.getUserId(), token:UserStore.getToken() } }).then((resp) => {

        if (resp) {

            const datesRef = resp.data.companyData[0]
                //console.log(date_diff_indays(datesRef.date, datesRef.expireDate))
                if(dateDiff(new Date(), datesRef.expireDate)>=0){
                    setCanDoTestAgain(true)   
                }else{setCanDoTestAgain(false)}


                
        }

        })


    
    const model = ModelsData[ ( props.QuizStep.modelId === -1 && UserStore.getMyModel() )? UserStore.getMyModel() : props.QuizStep.modelId ];
    
    let confirmModel = '';
    let selectOtherModel = '';
    let restartOpt = '';

    const [popUp, setPopUp] = useState(''); 
    const [acquirePremium, setPremiumBtn] = useState(<div></div>);  

    const closePopUp = () =>
    {
            setPopUp( <div></div> );
    }

    const openPopUp = () => 
    {    
        setPopUp(
            <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                <div className="modelRecPopUpDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                    <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                    <text className='FullBoardContentTitle' style={{fontSize:'1.2rem', justifySelf:'center'}}>Você gostaria de viver a experiência completa da nossa plataforma e adquirir a Conta Premium da MeConecte?</text>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', gap:'5rem', marginTop:'2rem'}}>
                        <div className='modelRecPopUpAcceptBtn'>
                            <a href='https://chk.eduzz.com/1745888' target='_blank' rel='noreferrer' style={{textDecoration:'none', color: '#FFFFFF'}}>Adquirir Premium</a>
                        </div>
                        <div className='modelRecPopUpDontAcceptBtn' onClick={closePopUp}>
                            Não adquirir o serviço completo
                        </div>
                    </div>
                </div>
            </div>
        )
    
}


    const backToResult = () =>
    {
        props.setQuizStep( { step: 1, modelId: props.QuizStep.modelId } );
    };

    const selectModel = () =>
    {
        props.setQuizStep( { step: 0, modelId: -1 } );

        if( UserStore.getAccType() === 0 )
        {
            setPremiumBtn( <div className="BoardBtnOk" onClick={()=>window.open('https://chk.eduzz.com/1745888')}>Adquirir Premium</div> );
            openPopUp();
        }
    };

    if( props.QuizStep.modelId !== -1 && UserStore.getMyModel() )
    {
        confirmModel = ( <div className="BoardBtnOk" onClick={ () => selectModel() }>Confirmar</div> );
        selectOtherModel = ( <div className="BoardBtnBack" onClick={ () => backToResult() }>Voltar</div> );
    }

    //if( UserStore.getAccType() === 5 )



    if(canDoTestAgain)
    {
        restartOpt = ( <div className="BoardBtnOk" onClick={ () => props.setQuizStep( { step: 0, modelId: 0 } ) }>Recomeçar</div> );
    }

    return(
        <div className='FullBoardContent' style={{height: 'fit-content'}}>
            <div className="ModelInfo">
                <div style={{width:'100%'}}>
                    <div style={{float:'left', minHeight:'10%', width:'100%'}}>
                        <div className='BoardTitleBtns'>
                            { confirmModel }
                            { selectOtherModel }
                            { restartOpt }
                            { acquirePremium }
                        </div>
                        <div className="FullBoardContentTitle">
                            { model.name }
                        </div>
                        <div className="FullBoardContentSubTitle">
                            { model.desc }
                            <br/>
                            <br/>
                            Exemplos práticos: { model.examples }.
                        </div>
                    </div>
                </div>
                <div className='HorizontalLineSeparator'/>
                <div>
                    <div className="ModelInfoRow">
                        <div className="ModelItem">
                            <div className="ModelItemTitle"><img src={ BulbIconImg } alt= ""/> O que</div>
                            <div className="ModelItemDesc" style={{wordBreak: 'break-all'}}>{ model.what.map( ( item, index ) => <li className="ModelList" key={ index }>{ item }</li> ) }</div>
                        </div>
                        <div style={{float: 'left', width: '2%',height: '10px'}}/>
                        <div className="ModelItem">
                            <div className="ModelItemTitle"><img src={ PeopleIconImg } alt= ""/> Para quem</div>
                            <div className="ModelItemDesc" style={{wordBreak: 'break-all'}}>{ model.for.map( ( item, index ) => <li className="ModelList" key={ index }>{ item }</li> ) }</div>
                        </div>
                    </div>
                    <div className="ModelInfoRow">
                        <div className="ModelItem">
                            <div className="ModelItemTitle"><img src={ GearIconImg } alt= ""/> Como</div>
                            <div className="ModelItemDesc" style={{wordBreak: 'break-all'}}>{ model.how.map( ( item, index ) => <li className="ModelList" key={ index }>{ item }</li> ) }</div>
                        </div>
                        <div style={{float: 'left', width: '2%',height: '10px'}}/>
                        <div className="ModelItem">
                            <div className="ModelItemTitle"><img src={ CoinIconImg } alt= ""/> Quanto</div>
                            <div className="ModelItemDesc" style={{wordBreak: 'break-all'}}>{ model.cost.map( ( item, index ) => <li className="ModelList" key={ index }>{ item }</li> ) }</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ModelBrands">
                <div style={{color: '#FFF'}}>
                    Exemplos
                </div>
                <div className="ModelBrandItem" style={{wordBreak: 'break-all'}}>
                    <div><img style={{width: screenWidth<780 && '100%'}} src={ getImgLogoByKey( model.logo1Img ) } alt=""/></div>
                    <div>{ model.logo1Desc }</div>
                </div>
                <div className="ModelBrandItem" style={{wordBreak: 'break-all'}}>
                    <div><img style={{width: screenWidth<780 && '100%'}} src={ getImgLogoByKey( model.logo2Img ) } alt=""/></div>
                    <div>{ model.logo2Desc }</div>
                </div>
                <div className="ModelBrandItem" style={{wordBreak: 'break-all'}}>
                    <div><img style={{width: screenWidth<780 && '100%'}} src={ getImgLogoByKey( model.logo3Img ) } alt=""/></div>
                    <div>{ model.logo3Desc }</div>
                </div>
            </div>
            {popUp}
        </div>
    );
}

export default Model;