import React, { useState } from 'react'
import styles from './SimpleJourneyCard.module.css'
import { ReadMoreContentJourneyCard } from '../readMoreContentJourneyCard/ReadMoreContentJourneyCard';
const SimpleJourneyCard = ({title, content, cardHeight, largeWidth}) => {
  let thisHeight = 'auto';
  if (cardHeight) {
    thisHeight = cardHeight + 'px'
    

  } 
  let thisWidth = '';
  if (largeWidth) {

    if (window.screen.width>780) {
        thisWidth = '29vw';      
    }



  }
  
  const contentLenght = cardHeight<200 ? 150 : 210
  const [moreContent, setMoreContent] = useState(false)
  const [moreContentMessage, setMoreContentMessage] = useState('')
  const [moreContentTitle, setMoreContentTitle] = useState('')
  const localContent = content.toString()
  const setScreen = (message, moreContentTitle) =>{

    setMoreContentMessage(message)
    setMoreContent(true)
    setMoreContentTitle(moreContentTitle)
  }


  
  return (
    <div className={styles.simpleJourneyCard} style={{height: thisHeight, width: thisWidth}}>{console.log(typeof content)}


        {moreContent && <ReadMoreContentJourneyCard title={moreContentTitle} message={moreContentMessage} onClick={()=>setMoreContent(false)}/>}
        <h4>{title}</h4>
        <p>{typeof content==='string' ? localContent.substring(0, contentLenght) : content} {content && content.length>contentLenght ? <span onClick={()=>setScreen(content, title)} style={{cursor: 'pointer', fontWeight: 'bold', fontSize: '12px', padding: '0px 12px'}}>Leia Mais</span> : ''}</p>
    
    </div>
  )
}

export default SimpleJourneyCard