import React from "react";
import { useState, useEffect } from 'react';
import { TrashIconImg, EditIconImg, AddIconImg } from '../imgsLoader/LoadIconImgs';
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid';
import "../questions/questions.css";
import UserStore from '../../store/userStore';
import axios from 'axios';



const revenueImportance = 
[
    { value: 0, label: 'Muito Alta', class: 'ModelRevenuesVeryHigh' },
    { value: 1, label: 'Alta', class: 'ModelRevenuesHigh' },
    { value: 2, label: 'Normal', class: 'ModelRevenuesNormal' },
    { value: 3, label: 'Baixa', class: 'ModelRevenuesLow' }
];

let pItems = [];
let products = [];

const ProductCharacteristicsQuestions = () =>{


    const [ tableProducts, setTableProducts ] = useState( [] );
    const [ loadingProductList, setLoadingProductList ] = useState( true );

    


    const emptyTableProductsWarn = 
        <tr>
            <td colSpan={ 5 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
                Você não possui produtos cadastrados.<br/>
                Clique em '+' no canto superior direito para adicionar um novo produto.
            </td>
        </tr>;
    
    const loadingTableProductsWarn = 
        <tr>
            <td colSpan={ 5 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
                Por favor aguarde, carregando lista de produtos.
            </td>
        </tr>;



    const fillProductsList = async() =>
    {   
        pItems = [];
        products = [];

        var userId = UserStore.getUserId();
        var token = UserStore.getToken();

        const resp = await axios.get( 'https://bmiejsiuhnjv5ipb5sjnbanjr40vxxxu.lambda-url.us-east-2.on.aws/', { params: { company: userId, token: token } } );

        if( resp.data.products )
        {
            for( let index = 0; index < resp.data.products.length; index++ )
            {
                let product = resp.data.products[ index ].product;
                let name = resp.data.products[ index ].name;
                let value = resp.data.products[ index ].value;
                let importance = resp.data.products[ index ].importance;

                const newLine = 
                    <tr id={ 'p_' + product } key={ product }>
                        <td>{ name }</td>
                        <td>{ value.toString().replace('.', ',') }</td>
                        <td><div>{ revenueImportance[ importance ].label }</div></td>
                        <td><div className='userInputsIconGreen' onClick={ () => editProduct( product ) }><img src={ EditIconImg } alt='...'/></div></td>
                        <td><div className='userInputsIconRed' onClick={ () => removeProduct( product ) }><img src={ TrashIconImg } alt='...'/></div></td>
                    </tr>;

                pItems.push( { added: true, productId: product, name: name, value: value, importance: importance } );
                products.push( newLine );
            }
        }


        setTableProducts( [ ...products ] );
        setLoadingProductList( false );
    }

    const handleSelectChange = ( productId, event ) => 
    {
        let pIndex = pItems.findIndex( ( product ) => { return product.productId === productId } );
        pItems[ pIndex ].importance = ( pIndex !== -1 ) ? event.value : pItems[ pIndex ].importance;
    }

    const handleNameChange = ( productId, event ) => 
    {
        let pIndex = pItems.findIndex( ( product ) => { return product.productId === productId } );
        pItems[ pIndex ].name = ( pIndex !== -1 ) ? event.target.value : pItems[ pIndex ].name;
    }

    const handleValueChange = ( productId, event ) => 
    {
        let pIndex = pItems.findIndex( ( product ) => { return product.productId === productId } );
        pItems[ pIndex ].value = ( pIndex !== -1 ) ? event.target.value : pItems[ pIndex ].value;
    }

    const newProductOnTable = () =>
    {
        var productId = uuidv4();

        const newLine = 
            <tr id={ 'p_' + productId } key={ productId }>
                <td><input className='userInputsText' type='text' name='pName' onChange={ ( event ) => handleNameChange( productId, event ) }/></td>
                <td><input className='userInputsText' type='text' name='pValue' onChange={ ( event ) => handleValueChange( productId, event ) }/></td>
                <td><Select className='userInputsSelector' menuPlacement='top' onChange={ ( event ) => handleSelectChange( productId, event ) } options={ revenueImportance } defaultValue= { revenueImportance[ 3 ] } styles={{control: (baseStyles, state) => ({...baseStyles,borderColor:'#000000','&:hover': { borderColor: '#000000'},boxShadow: 'none'})}}/></td>
                <td><div className='userInputsIconGreen' onClick={ () => createProduct( productId ) }>&#x2713;</div></td>
                <td><div className='userInputsIconRed' onClick={ () => cancelNewProduct( productId ) }>X</div></td>
            </tr>;

        pItems.push( { added: false, productId: productId, name: '', value: '', importance: revenueImportance[ 3 ].value } );
        products.push( newLine );
        setTableProducts( [ ...products ] );
    };

    const cancelNewProduct = ( productId ) =>
    {
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {
            pItems.splice( pIndex, 1 );
            products.splice( pIndex, 1 );
            setTableProducts( [ ...products ] );
        }
    }

    const createProduct = async( productId ) => 
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {
            if( pItems[ pIndex ].name === "" )
            {
                alert( "Por favor, insira um nome para o novo produto." );
            }
            else if( pItems[ pIndex ].value === "" )
            {
                alert( "Por favor, insira um valor para o novo produto." );
            }
            else
            {
                let impIndex = revenueImportance.findIndex( ( imp ) => { return imp.value === pItems[ pIndex ].importance } );
                impIndex = ( impIndex === -1 ) ? 0 : impIndex;
                const resp = await axios.post( 'https://6femnjigs57o2ddvvoxa43nwu40mnfcj.lambda-url.us-east-2.on.aws/', { company: userId, token: token, name: pItems[ pIndex ].name, value: parseFloat( pItems[ pIndex ].value.replace(',', '.') ), importance: pItems[ pIndex ].importance } );
                setLoadingProductList( true );
                if( resp.data.product !== -1 )
                {   
                   
                    const productResp = resp.data.product;
                    const newLine = 
                        <tr id={ 'p_' + productResp } key={ productResp }>
                            <td>{ pItems[ pIndex ].name }</td>
                            <td>{ pItems[ pIndex ].value.replace('.', ',') }</td>
                            <td><div>{ revenueImportance[ impIndex ].label }</div></td>
                            <td><div className='userInputsIconGreen' onClick={ () => editProduct( productResp ) }><img src={ EditIconImg } alt='...'/></div></td>
                            <td><div className='userInputsIconRed' onClick={ () => removeProduct( productResp ) }><img src={ TrashIconImg } alt='...'/></div></td>
                        </tr>;

                    products[ pIndex ] = newLine;
                    setTableProducts( [ ...products ] );
                }
            }
        }
    };

    const cancelEdit = ( productId ) =>
    {
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {
            let impIndex = revenueImportance.findIndex( ( imp ) => { return imp.value === pItems[ pIndex ].importance } );
            impIndex = ( impIndex === -1 ) ? 0 : impIndex;

            const newLine = 
            <tr id={ 'p_' + productId } key={ productId }>
                <td>{ pItems[ pIndex ].name }</td>
                <td>{ pItems[ pIndex ].value }</td>
                <td><div >{ revenueImportance[ impIndex ].label }</div></td>
                <td><div className='userInputsIconGreen' onClick={ () => editProduct( productId ) }><img src={ EditIconImg } alt='...'/></div></td>
                <td><div className='userInputsIconRed' onClick={ () => removeProduct( productId ) }><img src={ TrashIconImg } alt='...'/></div></td>
            </tr>;

            products[ pIndex ] = newLine;
        }

        setTableProducts( [ ...products ] );
    }

    const editProduct = ( productId ) =>
    {
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {
            let impIndex = revenueImportance.findIndex( ( imp ) => { return imp.value === pItems[ pIndex ].importance } );
            impIndex = ( impIndex === -1 ) ? 0 : impIndex;

            const newLine = 
                <tr id={ 'p_' + productId } key={ productId }>
                    <td><input className='userInputsText' type='text' name='pName' onChange={ ( event ) => handleNameChange( productId, event ) } defaultValue={ pItems[ pIndex ].name }/></td>
                    <td><input className='userInputsText' type='text' name='pValue' onChange={ ( event ) => handleValueChange( productId, event ) } defaultValue={ pItems[ pIndex ].value }/></td>
                    <td><Select className='userInputsSelector' menuPlacement='top' onChange={ ( event ) => handleSelectChange( productId, event ) } options={ revenueImportance } defaultValue= { revenueImportance[ impIndex ] } styles={{control: (baseStyles, state) => ({...baseStyles,borderColor:'#000000','&:hover': { borderColor: '#000000'},boxShadow: 'none'})}}/></td>
                    <td><div className='userInputsIconGreen' onClick={ () => confirmEditProduct( productId ) }>{/*<img src={ AddIconImg } alt='...'/>*/}&#x2713;</div></td>
                    <td><div className='userInputsIconRed' onClick={ () => cancelEdit( productId ) }>X</div></td>
                </tr>;

            products[ pIndex ] = newLine;
        }

        setTableProducts( [ ...products ] );
    };

    const confirmEditProduct = async( productId ) =>
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {
            if( pItems[ pIndex ].name === "" )
            {
                alert( "O nome do produto não pode ser vazio." );
            }
            else if( pItems[ pIndex ].value === "" )
            {
                alert( "O valor do produto não pode ser vazio." );
            }
            else
            {
                let impIndex = revenueImportance.findIndex( ( imp ) => { return imp.value === pItems[ pIndex ].importance } );
                impIndex = ( impIndex === -1 ) ? 0 : impIndex;
                const resp = await axios.put( 'https://3axebc7hd5jch3aq5allprdqw40inbvq.lambda-url.us-east-2.on.aws/', { product: productId, company: userId, token: token, name: pItems[ pIndex ].name, value: parseFloat( pItems[ pIndex ].value ), importance: pItems[ pIndex ].importance } );

                if( resp.data.product !== -1 )
                {
                    const newLine = 
                        <tr id={ 'p_' + productId } key={ productId }>
                            <td>{ pItems[ pIndex ].name }</td>
                            <td>{ pItems[ pIndex ].value }</td>
                            <td><div>{ revenueImportance[ impIndex ].label }</div></td>
                            <td><div className='userInputsIconGreen' onClick={ () => editProduct( productId ) }><img src={ EditIconImg } alt='...'/></div></td>
                            <td><div className='userInputsIconRed' onClick={ () => removeProduct( productId ) }><img src={ TrashIconImg } alt='...'/></div></td>
                        </tr>;

                    products[ pIndex ] = newLine;
                    setTableProducts( [ ...products ] );
                }
            }   
        }
    }

    const removeProduct = async( productId ) =>
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {
            const resp = await axios.delete( 'https://lxxi2dd7zrnboqd7yhvisvjtgq0rdqfy.lambda-url.us-east-2.on.aws/', { data: { product: productId, company: userId, token: token } } );
            let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

            if( resp.data.product !== -1 )
            {
                products.splice( pIndex, 1 );
                pItems.splice( pIndex, 1 );
                setTableProducts( [ ...products ] );
            }
        }
    };

    useEffect( () => { setTimeout( fillProductsList, 1000 ); }, [] );


        return(
            <div className="journeyFWQQuestionsLayout1" style={{overflow:'auto'}}>    
                <div className="journeyFWQQuestionsLayout2" style={{width:'55rem'}}>
                    <div style={{display:'flex',flexDirection:'row',gap:'2rem'}}>
                        <text className="journeyFWQQuestionText" style={{width: '80vw'}}>Qual as características do seu produto/serviço, conforme abaixo:</text>
                        <div className="journeyProductQuestionsAddBtn" onClick={ newProductOnTable } >
                            <text >Adicionar produto</text>
                        </div>
                    </div>
                    <div style={{height:'18rem',overflowY:'scroll',overflow:'auto', paddingRight:'1rem'}}>
                        <table className="productCharacteristcsTable" style={{marginTop:'1rem'}}>
                            <thead>
                                <tr style={{textAlign:'center',fontSize:'0.9rem', height:'4rem'}}>
                                    <th className="thquestions" style={{width:'9rem'}}>Nome do produto ou serviço</th>
                                    <th className="thquestions" style={{width:'9rem'}}>Preço (R$)</th>
                                    <th className="thquestions" style={{width:'9rem'}}>Importância da venda</th>
                                    <th className="thquestions" style={{width:'2rem'}}>&nbsp;</th>
                                    <th className="thquestions" style={{width:'2rem'}}>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                             { ( tableProducts.length ) ? tableProducts : ( loadingProductList ) ? loadingTableProductsWarn : emptyTableProductsWarn }
                            </tbody>
                        </table>
                    </div>
                   
                </div>               
            </div>
        )
    }

export default ProductCharacteristicsQuestions;