import React, { useEffect, useState } from 'react'
import styles from './TrailSection.module.css'    
import axios from 'axios'
import LoadingScreen from '../loadingScreen/LoadingScreen'
import {InformationsAndStep} from './trailCards/informationsAndStep/InformationsAndStep'
import { VideoOptions } from './trailCards/videoOptions/VideoOptions'
import { convertTimeFormat } from '../../functions/convertTimeFormat'
import { convertToSemanticTimeFormat } from '../../functions/convertToSemanticTime'
import UserStore from '../../store/userStore'
import { botconversaWebhook } from '../../functions/botconversaWebhook'




const TrailSection = ({ isSandwichMenuClosed, changeSandwichMenuState }) => {
  const pageLocale = 'MEconecte Flix'

  

         
    const browserUrl = window.location.search.substring(2).split('/')
    const urlTrailCode = browserUrl[0]
    const videoPosition = browserUrl[1]

    
    const initialVideo =  (videoPosition) ? (Number(videoPosition)) : 0;
    const [selectedVideo, setSelectedVideo] = useState(initialVideo===0 ? 1 : initialVideo)
    const [selectedVideoTitle, setSelectedVideoTitle] = useState('')
    const [selectedVideoDescription, setSelectedVideoDescription] = useState('')  
    const [currentVideoUrl, setCurrentVideoUrl] = useState('')    
    
    const [haveErrorOnAPI, setHaveErrorOnAPI] = useState(false)
    const [isLoadingTrailData, setIsLoadingTrailData] = useState(false)

    const [trailData, setTrailData] = useState([])    
    const [trailAuthor, setTrailAuthor] = useState('')
    const [trailTitle, setTrailTitle] = useState('')


    const [haveFavourited, setHaveFavourited] = useState(0)
    const [currentVideoCode, setCurrentVideoCode] = useState(-1)
    const [newAnnotationContent, setNewAnnotationContent] = useState('')    
    const [classesTotalDuration, setClassesTotalDuration] = useState('00:00:00')
    const [annotationsArray, setAnnotationsArray] = useState([])


    const [totalWatchedVideos, setTotalWatchedVideos] = useState(0)
    //const [isSandwichMenuClosed, setIsSandwichMenuClosed] = useState(true)
    


    const [isLoading, setIsLoading] = useState(false)




    const [isLoadingVideoData, setIsLoadingVideoData] = useState(false)
    const [watchedVideosCode, setWatchedVideosCode] = useState([])

    const [materialsArray, setMaterialsArray] = useState([])
    const [isFirstVideoClick, setIsFirstVideoClick] = useState(true)
    const convertTimeToSeconds = (arrayOfTime) =>{
      const hourToSeconds = arrayOfTime[0] * 3600
      const minutesToSeconds = arrayOfTime[1] * 60
      const seconds = arrayOfTime[2]

      return Number(hourToSeconds) + Number(minutesToSeconds) + Number(seconds )
    }

    const convertSecondsToTime = (seconds) =>{
      let secondsPlace = 0
      let minutesPlace = 0
      let hoursPlace = 0
  
      minutesPlace = (seconds/60) >= 1 ? Math.floor(seconds/60) : 0;
      secondsPlace = seconds%60
      hoursPlace = (minutesPlace/60) >= 1 ? Math.floor(minutesPlace/60) : 0
      minutesPlace = minutesPlace % 60
            
      const completeTime = `${hoursPlace>=10 ? hoursPlace : `0${hoursPlace}`}:${minutesPlace>=10 ? minutesPlace : `0${minutesPlace}`}:${secondsPlace>=10 ? secondsPlace : `0${secondsPlace}`}`;

      return completeTime

    }
    const sumTime = (firstTime, secondTime) =>{
      const firstTimeArray = firstTime.split(':')
      const secondTimeArray = secondTime.split(':')
      const totalTimeInSeconds = convertTimeToSeconds(firstTimeArray) + convertTimeToSeconds(secondTimeArray) 
      //console.log(`sumTime ${convertSecondsToTime(totalTimeInSeconds)}`)
      return convertSecondsToTime(totalTimeInSeconds)
    }
  
    const defineClassesTotalDuration = (arrayOfVideos) =>{
      let initialTime = '00:00:00'    

      arrayOfVideos.forEach(video=>{    
        initialTime = sumTime(video.duration, initialTime)
      } );

      const totalTime = initialTime //Pega o valor do initialTime que agora já esta calculado e completo
      setClassesTotalDuration(totalTime)
      
    }
        
    //const defineTrailData()
  const defineLocalVideoData = (data) =>{
    defineFavouriteValue(data.vHaveFavourited)
    //console.log(data) 


  }

  const defineFavouriteValue = (favouriteValue) =>{
    setHaveFavourited(favouriteValue)



  }
  const postVideoView = async() =>{
    
    console.log(currentVideoCode)
    axios.post('https://yyzbyzev5bljpcjv3we5nabd4y0zalej.lambda-url.us-east-2.on.aws/', {userId: UserStore.getUserId(), videoId: currentVideoCode}).then(resp=>{console.log(resp);setIsFirstVideoClick(false)})

  }
  const countWatchesInTrail = async() =>{
    setWatchedVideosCode([])
    setTotalWatchedVideos(0)
    const url = 'https://gltqlwcbbkymbomkf4pktriwpy0bmpli.lambda-url.us-east-2.on.aws/'
    await axios.get(url, {params:{company: UserStore.getUserId(), trail: urlTrailCode}}).then(resp=>{
    resp.data.informations.forEach(watch=>{
      if (watch.vHaveWatched) {

        setWatchedVideosCode(prev=>[...prev + watch.vVideoCode])
        setTotalWatchedVideos(prev=> prev + Number(watch.vHaveWatched))  
        
    
      }
    })



    })


    




  }
  const checkIfHaveData = async(url, companyCode, videoCode) =>{


    const resp = await axios.get(url, {params: {company: companyCode, video: videoCode}})
      

      
      if (resp.data.informations.length>0) {

          defineLocalVideoData(resp.data.informations[0])      
          countWatchesInTrail()

      return true; 

    } else{
        

      return false;
      }
  }
  const postVideoUserData = async(url, companyCode, videoCode, urlTrailCode) =>{

    console.log('postVideoUserData');console.log(videoCode);
    await axios.post(url, {company: companyCode, video: videoCode, trail: urlTrailCode})
  }

  const defineAnnotations = (annotations) =>{
    setAnnotationsArray(annotations)



  }

  const putNewAnnotation = async() =>{

    setIsLoading(true)
    //console.log(newAnnotationContent)
    const videoTime = getVideoCurrentTime()
    const url = 'https://4embu7tlphqa65yz5rva2mkrcm0hpzqy.lambda-url.us-east-2.on.aws/'
    await axios.put(url, {tag: 1, annotation: newAnnotationContent, company: UserStore.getUserId(), code: currentVideoCode, videotime: videoTime}).then(()=>{

      getAnnotations(currentVideoCode); 
      
      setNewAnnotationContent('');
      setIsLoading(false);    
    }).catch(()=>{setIsLoading(false); })
    



  }




  const changeNewAnnotationValue = (value) =>{
    setNewAnnotationContent(value)



  }
    
  const getAnnotations = async(videoCode) =>{   //console.log(videoCode)
    const url = 'https://bn2l5mm5w4ay4423ugv4ulhxuy0vpblc.lambda-url.us-east-2.on.aws/'
    await axios.get(url, {params:{company: UserStore.getUserId(), code: videoCode}}).then(resp=>defineAnnotations(resp.data.annotations))

  }



  

  const favouriteVideo = async() =>{
    const newFavouriteValue = !haveFavourited;
    //console.log(newFavouriteValue)
    const url = 'https://4embu7tlphqa65yz5rva2mkrcm0hpzqy.lambda-url.us-east-2.on.aws/';
    await axios.put(url, {tag: 0, code: currentVideoCode, company: UserStore.getUserId(), value: newFavouriteValue}).then(()=>defineFavouriteValue(newFavouriteValue))
  


  }

  const openVideoUserData = async(code) =>{
    
    setHaveFavourited(false)
    //console.log(currentVideoCode)
    const getUrl = 'https://7cwjt3osd5ratw4xsmwxnesdpm0pjeid.lambda-url.us-east-2.on.aws/'
    const postUrl = 'https://mnb3idthbudz4jaznowpkqyvk40ucdmm.lambda-url.us-east-2.on.aws/'
    const company = UserStore.getUserId();
    const video = code ? code : currentVideoCode;
    const haveData = await checkIfHaveData(getUrl, company, video)
    
    if (!haveData) {
      postVideoUserData(postUrl, company, video, urlTrailCode)
    }


  }




    useEffect(()=>{
       console.log(urlTrailCode)
        setIsLoadingTrailData(true)        
        const url = 'https://u7i4hjduxskebgniqoeo3kiciq0xhrhe.lambda-url.us-east-2.on.aws/';

        const getTrailData = async() =>{
            await axios.get(url, {params:{trailCode: urlTrailCode}}).then(resp=>{
              
          
                setTrailData(resp.data.videos); 
                
                setCurrentVideoUrl(`${resp.data.videos[initialVideo -1].videoUrl}#t=01,99`)
                setTrailTitle(resp.data.videos[0].trailTitle)
                setTrailAuthor(resp.data.videos[0].author)
                openVideoUserData(resp.data.videos[initialVideo -1].code);
              
                setCurrentVideoCode(resp.data.videos[initialVideo -1].code)
                 defineClassesTotalDuration(resp.data.videos)                
                 setSelectedVideoTitle(resp.data.videos[selectedVideo-1].title)
                 setSelectedVideoDescription(resp.data.videos[selectedVideo-1].videoDescription)
                getAnnotations(resp.data.videos[initialVideo-1].code)

                defineNewMaterialArray(resp.data.videos[initialVideo-1].material, resp.data.videos[initialVideo-1].materialTitle)
                
            })
            .catch(err=>{setHaveErrorOnAPI(true); console.log(err)})
    
            setIsLoadingTrailData(false)
        }

    
        const root = document.querySelector('#root')
        const profileIcon = document.querySelector('.BoardHeaderBtn')
        const lateralMenu = document.querySelector('.BoardWrapper .sideBar')
        const sideBar = document.querySelector('.BoardWrapper')
        const mainContent = document.querySelector('.BoardRight')
        const mainContentHeader = document.querySelector('.BoardRightHeader')
        const mainContentLine = document.querySelector('.HorizontalLineSeparator')
        const upgradePlan = document.querySelector('.BoardRightHeader > div > div > div')

        const body = document.querySelector('body')
        body.style.overflowY = 'hidden'
        const boardContent = document.querySelector('.BoardContent')
        boardContent.style.height = '100vh'
        boardContent.style.padding = '0'
        lateralMenu.style.backgroundColor = 'rgb(26,22,59)'
        sideBar.style.backgroundColor = 'rgb(26,22,59)'   
        profileIcon.style.backgroundColor = '#332C6F'
        profileIcon.parentNode.style.marginBottom = '16px'
       // profileIcon.style.transform = 'translateY(-30%)'
        profileIcon.parentNode.style.marginRight = '12px'



        root.style.backgroundColor = '#251F53'
        mainContent.style.padding = '0px'
        mainContentHeader.style.backgroundColor = 'rgb(26,22,59)'
        mainContentHeader.style.height = '100%'
        mainContentHeader.style.padding = '30px 0px'
        mainContentHeader.style.width = '1200px'

        mainContentLine.style.margin = '0'
        mainContent.style.backgroundColor = 'rgb(26,22,59)'
        mainContentLine.style.backgroundColor = 'rgb(26,22,59)'
        mainContent.style.width = '1290px'




        const showVideoUserData = (resp) =>{console.log(resp)}
        const getVideoUserData = async() =>{

            const url = 'https://7cwjt3osd5ratw4xsmwxnesdpm0pjeid.lambda-url.us-east-2.on.aws/'
            const companyId = UserStore.getUserId()

            await axios.get(url, {params: {company: companyId}}).then(resp=>showVideoUserData(resp)).catch(err=>console.log(err))



        }


        getTrailData();
        //getVideoUserData();



        addClickOnMenu()




    }, [])


        const defineActiveElement = (data) =>{
            console.log(data.title)
          setSelectedVideo(data.position)
          setSelectedVideoTitle(data.title)
          setSelectedVideoDescription(data.videoDescription)     
          
          
        }

        const addClickOnMenu = async() =>{
            const sandwichMenu = await document.querySelector('.BoardHeaderSandwichMenu')
            sandwichMenu.addEventListener('click', ()=>{changeSandwichMenuState(); console.log(isSandwichMenuClosed)})




        }

  const videoWasWatched = async() =>{
        const url = 'https://4embu7tlphqa65yz5rva2mkrcm0hpzqy.lambda-url.us-east-2.on.aws/'
        await axios.put(url, {tag: 2, company: UserStore.getUserId(), code: currentVideoCode}).then(()=>countWatchesInTrail())
        
        
        setSelectedVideo(selectedVideo+1)


        setCurrentVideoUrl(`${trailData[selectedVideo].videoUrl}#t=01,02`)
        setCurrentVideoCode(trailData[selectedVideo].code)

        const video = document.querySelector('#videoReload')
        video.load()
        setSelectedVideoTitle(trailData[selectedVideo].title)
        setSelectedVideoDescription(trailData[selectedVideo ].videoDescription)        
    }


  const getVideoCurrentTime = () =>{
    const video = document.querySelector('#videoReload')
    return video.currentTime
    

  }


  const defineCurrentVideoInfo = async(code) =>{
    setCurrentVideoCode(code)
    openVideoUserData(code)
    await getAnnotations(code)
    setIsLoadingVideoData(false)
  }


  const WatchedVideoIcon = () =>{
    return (
      <div className={styles.watchedVideoIcon}><i className="fa fa-check"/></div>



    )



  }
  const verifyIfContains = async(contentArray, value) =>{
    console.log(value + '  S')
    console.log(contentArray)
   // contentArray.find(el=> el === '18')
    return false


  }


  const defineNewMaterialArray = (material, title) =>{
    setMaterialsArray([])

    const array = {"material": material , "title": title}
    setMaterialsArray(prev=>[...prev, array])
    

  }

    
  return (
    <div className={styles.trailSection}>


    { trailData && !haveErrorOnAPI && !isLoadingTrailData && 
      <div>
        <div className={styles.trailSectionContent}>   
          <div className={styles.leftContentMenu}>
            <div className={styles.titleInformations}>
              <h2 onClick={getVideoCurrentTime}>{!!trailTitle && trailTitle}</h2>
              <p>{!!trailAuthor && trailAuthor}</p>  

            </div>    
            <div className={styles.videoContent}>{/*width="714.6"*/}
                <video onEnded={videoWasWatched} src={currentVideoUrl} id="videoReload" controls="controls" controlsList="nodownload" onClick={()=>{console.log(isFirstVideoClick); if (isFirstVideoClick) {  postVideoView()}}}></video>
                  <VideoOptions materialsArray={materialsArray} isLoading={isLoading} newAnnotation={newAnnotationContent} putNewAnnotation={putNewAnnotation} changeNewAnnotationValue={changeNewAnnotationValue} annotationsArray={annotationsArray} favouriteVideo={favouriteVideo} haveFavourited={haveFavourited} videoStep={selectedVideo} videoTitle={selectedVideoTitle} videoDescription={selectedVideoDescription} videoCode={currentVideoCode}/>

            </div>
          </div>




              <div className={styles.rightContent} id={isSandwichMenuClosed ? '' : styles.isActive}>{/**/}
                  <InformationsAndStep totalWatchedVideos={totalWatchedVideos} classesQuantity={trailData.length} classesTotalDuration={classesTotalDuration} watchedVideos={1} totalVideos={trailData.length}/>
                <div>
                {trailData.map((video,  index)=>(<div key={index+1}>
                                        <div className={styles.rightContentMenu} style={{backgroundColor: (Number(selectedVideo)===Number(video.position) ? '#332C6F' : '')}} onClick={(e)=>{
                                          botconversaWebhook({locale: pageLocale, action: `Abriu Video ${video.title}`})
                                          setIsLoadingVideoData(true)
                                          setCurrentVideoUrl(`${video.videoUrl}#t=01,02`); 
                                          defineActiveElement(video);
                                          defineCurrentVideoInfo(video.code); 
                                          defineNewMaterialArray(video.material, video.materialTitle);
                                          setIsFirstVideoClick(true);
                                          
                                          (!isSandwichMenuClosed && changeSandwichMenuState())
                                          }}>
                                            <div style={{display: 'flex', gap: '12px'}}>
                                                {watchedVideosCode.find(el=>Number(el)===video.code) ? <WatchedVideoIcon/> : ''}
                                                <p> {video.position}. {video.title}      {}</p>  

                                              </div>


                                            <p>{convertTimeFormat(video.duration) }</p>
                            
                                        </div> 
                                       </div>
                                      
                ))}
                </div>
              </div>

        </div>
      </div>
    }



    { haveErrorOnAPI && <div><h2 style={{textAlign: 'center'}}>Ocorreu um erro, por favor tente mais tarde</h2></div> }

    { (isLoadingTrailData || isLoadingVideoData) && <div style={{color: 'black'}}><LoadingScreen/></div> }
  
    </div>
  )
}

export default TrailSection