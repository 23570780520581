import { isNaN } from "formik"

export const removeLeftNullNumbers = (time) => {


  
    if (Number(time.charAt(0) )=== 0) {
        return time.substring(1)
    }
    else{
      return time
    }
}