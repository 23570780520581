import React from 'react'
import styles from './JourneyForChannelModule.module.css';
import TagsJourneyCard from '../journeyCard/tagsJourneyCard/TagsJourneyCard';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import UserStore from '../../store/userStore';
import SimpleJourneyCard from '../journeyCard/simpleJourneyCard/SimpleJourneyCard';
const JourneyForChannelModel = () => {
    const [forChannel, setForChannel] =  useState([])
    const changeBackgroundColor = () =>{

      const screenWidth = window.screen.width
      const boardContent = document.querySelector('.BoardContent')
          
      if (screenWidth>800) {
          boardContent.style.backgroundColor = '#270D43'   
          boardContent.style.padding = '30px'
          boardContent.style.borderRadius = '22px'        
      }else{boardContent.style.backgroundColor = '#ffffff'}
    
  }
  useEffect(()=>{   
      changeBackgroundColor()
  }, [])    
      
  useEffect(()=>{
    axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/', {params:{ company:UserStore.getUserId() ,token: UserStore.getToken() }})
        .then((resp)=>{

            if (resp.data) {

              
            setForChannel(resp.data.What)     
        
            }


        })
        
}, [])







  return (
    <div className={styles.journeyForChannelModel}>
            <div className={styles.distChannel}>    
              <p className={styles.cardsTitle}>Canais de Distribuição</p>
              <TagsJourneyCard title="Quais canais você utiliza para alcançar clientes?" tags={forChannel.jwChannelReach || <a href='communicationchannel/questions'>Preencha aqui!</a>}/>
              <SimpleJourneyCard title="Em quais canais sua empresa irá focar?" content={forChannel.jwChannelWay || <a href='communicationchannel/questions'>Preencha aqui!</a>}/>
            </div>
         

          <div>
            <p className={styles.cardsTitle}>Canais de Venda</p>
            <div className={styles.salesChannel}>
                <TagsJourneyCard title="Quais os pontos de contato de venda?" tags={forChannel.jwSalesLocation || <a href='saleschannel/questions'>Preencha aqui!</a>}/>
                <TagsJourneyCard title="Quais os meios de pagamento?" tags={forChannel.jwSalesPayment || <a href='saleschannel/questions/questions'>Preencha aqui!</a>}/>
            </div>
          </div>
   



         <div>
            <p className={styles.cardsTitle}>Canais de Entrega</p>
            <div className={styles.deliveryChannel}>
            <TagsJourneyCard title="Quais canais de distribuição atendem o cliente(entregar o produto/serviço)?" tags={forChannel.jwDeliveryContact || <a href='deliverychannel/questions'>Preencha aqui!</a>}/>
            </div>
          </div>
  
          <div className={styles.relationChannel}>    
              <p className={styles.cardsTitle}>Canais de Relacionamento</p>

              <div style={{display: 'flex'}}>
                  <SimpleJourneyCard title="Como você conquista o cliente?" content={forChannel.jwCustomerConquer || <a href='relationshipchannel/questions'>Preencha aqui!</a>}/>
                  <SimpleJourneyCard title="Como você retem o clientes?" content={forChannel.jwCustomerHolding || <a href='relationshipchannel/questions'>Preencha aqui!</a>}/>
              </div>

              <div style={{display: 'flex'}}>
                  <SimpleJourneyCard title="Como você amplia as vendas ao cliente?" content={forChannel.jwRelationshipIncrease || <a href='relationshipchannel/questions'>Preencha aqui!</a>}/>
                  <SimpleJourneyCard title="Quais são as formas de relacionamento com seu cliente?" content={forChannel.jwRelationshipContact || <a href='relationshipchannel/questions'>Preencha aqui!</a>}/>
              </div>
          
        
          
          </div>
          
            
        
    </div>
  )
}

export default JourneyForChannelModel