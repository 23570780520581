import React from 'react'
import styles from './VideoSectionHeader.module.css'
import SandwichMenuOptions from '../sandwichMenuOptions/SandwichMenuOptions'
const VideoSectionHeader = ({isSandwichMenuClosed, changeSandwichMenuState}) => {
  return (

    <>
    <header className={styles.videoSectionHeader}>
        <div><i className={isSandwichMenuClosed ? "fa fa-bars" : "fa fa-times"} onClick={changeSandwichMenuState}/></div>
        <div>Seção de Vídeos</div>




    </header>

        <div>
            {!isSandwichMenuClosed && <div className={styles.menu}><SandwichMenuOptions/></div>}
        </div>

    </>
  )
}

export default VideoSectionHeader