
import React, { useEffect, useState, useSyncExternalStore } from 'react'
import styles from './NewJourney.module.sass'
import VideoContainer from './cards/videoContainer/VideoContainer'
import VideoStepsContainer from './cards/videoStepsContainer/VideoStepsContainer'
import StepDescription from './cards/stepDescription/StepDescription'
import axios from 'axios'

import UserStore from '../../store/userStore'

import LoadingScreen from '../loadingScreen/LoadingScreen'
import StepAnnotation from './cards/stepAnnotation/StepAnnotation'
import AnswersContainer from './cards/answersContainer/AnswersContainer'
import FullStep from './cards/fullStep/FullStep'
import StepEbooks from './cards/stepEbooks/StepEbooks'
import { botconversaWebhook } from '../../functions/botconversaWebhook'
const NewJourney = () => {
    const localeUrl =window.location.search 
    const url = localeUrl && localeUrl.split('=')
    const urlSteps = url[1].split('/')
    const [group, setGroup] =  useState(urlSteps[0])
    const [step, setStep] = useState(urlSteps[1])
    const [videoUrl, setVideoUrl] = useState('')
    const [stepInfo, setStepInfo] = useState(null)
    const [allSteps, setAllSteps] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [currentGroup, setCurrentGroup] = useState(0)
    const [reloadSteps, setReloadSteps] = useState(false)
    const [isAboutOpened, setIsAboutOpened] = useState(true)
    const [haveChanges, setHaveChanges] = useState(false) 
    const [saveAnswersState, setSaveAnswersState] = useState(false)     
    const [component, setComponent] = useState() 
    const [isFullStepOpen, setIsFullStepOpen] = useState(false)
    const [subGroups, setSubGroups] = useState(null)
    const [activeConfirmScreen, setActiveConfirmScreen] = useState(false)
    const screenWidth = window.screen.width
    const [isAnnotationsOpened, setIsAnnotationsOpened] = useState(false)
    const [isEbookOpened, setIsEbookOpened] = useState(false)



    const [stepMaterials, setStepMaterials] = useState('')
    const removeBoard = (width) =>{
        const board = document.querySelector('.BoardRight')
        board.style.background = '#270D43'
        board.style.width = width
    }      
    

    const changeHeight = ( ) =>{
        const board = document.querySelector('.BoardContent')
        board.style.height = 'calc(100vh - 145px)'

    
    }      


    const postBotConversa = async({action, step}) =>{
        console.log(step)
        botconversaWebhook({locale: 'Jornada', action: action})
            await axios.put('https://iz5smq7lxhsxjtskiegdcibiru0vwhjh.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), token: UserStore.getToken(), journeyStep: step})
            .then(resp=>console.log(resp))
    



    }



      

    const defineMobileBackground = () =>{

        const board = document.querySelector('.Board')
        board.style.width = '100vw'

        const boardContent = document.querySelector('.BoardContent')
        boardContent.style.padding = '0px'


    }
    const getAllSubGroups = async() =>{
        axios.get('https://clm4segl3pvtrh5ojjfuo2yubi0pdnzy.lambda-url.us-east-2.on.aws/').then(resp=>{
            setSubGroups(resp.data.products)
        })   
    }
        const getAllGroupsInfo = async() =>{
            axios.get('https://tdpx427jk7npu2k5araqg6qn4u0wucrh.lambda-url.us-east-2.on.aws/', {params: {userId: UserStore.getUserId()}}).then(resp=>{
               console.log(resp)



               /**/let subGroups = []
               resp.data.products.forEach((item, index)=>{
                   if(item.sSubGroup>0){
                       
                       if (!subGroups[item.sSubGroup]) {
                         subGroups[item.sSubGroup] = [item];
                       } else {
                         subGroups[item.sSubGroup].push(item);
                       }
                       
                   }
               })        
               

            let counter = [];
            let indicesToRemove = [];
            
            resp.data.products.forEach((item, index) => {
                if(item.sSubGroup > 0){
                    if (counter.find(counterItem => Number(item.sSubGroup) === Number(counterItem))) {
                        indicesToRemove.push(index);
                    } else {
                        counter.push(item.sSubGroup);
                        resp.data.products[index].sSubGroupData = subGroups[item.sSubGroup];
                    }
                }
            });
            
            // Remove items in reverse order to avoid messing up the indices
            for(let i = indicesToRemove.length - 1; i >= 0; i--) {
                resp.data.products.splice(indicesToRemove[i], 1);
            }

            


            
               resp.data.products.forEach((item, index)=>{
                   if(item.sSubGroup>0){
                       resp.data.products[index].sSubGroupData = subGroups[item.sSubGroup]
             
                   }
               })  

                


               const newResp = resp.data.products.reduce((acc, obj)=>{
                  let key = obj['jTitle']
                  if(!acc[key]){
                        acc[key] = []
                  }
                  delete obj.jTitle
                  acc[key].push(obj)

                  return acc
               }, {})



               console.log(newResp)
                console.log(typeof newResp)
                setAllSteps(newResp)
                setReloadSteps(prev=>!prev)
            })



        }








    useEffect(()=>{

        UserStore.setSelectedPageStore('newjourney')
        if (screenWidth<800) {

            changeHeight()
            removeBoard('100%')
            defineMobileBackground()
        }else{
             removeBoard('96%')
    
        }
    }, [])


        const getInfo = async({group, step, botStep, action}) =>{

      

          setIsLoading(true)
            await axios.get('https://yflxpsqu2xtdhrby4yonqurfsi0siuoa.lambda-url.us-east-2.on.aws/', {params: {group: group, step: step, userId: UserStore.getUserId()}}).then(resp=>{
                console.log(resp)
                setStepInfo(resp.data.step)
                setIsLoading(false)
                setCurrentGroup(resp.data.step.sGroup)



                getMaterials({thisStep: resp.data.step.sStep, thisGroup: resp.data.step.sGroup})
                postBotConversa({step: resp.data.step.botStep, action})
                
            }).catch(err=>setIsLoading(false))   



        }
    useEffect(()=>{

        getInfo({group, step})
        getAllSubGroups()



        getAllGroupsInfo()

    }, [])


    const openAnnotation = () =>{
        closeEbooks()
        closeAbout()   
        setIsAnnotationsOpened(true) 
    }

    const openAbout = () =>{
        closeEbooks()
        closeAnnotation()
        setIsAboutOpened(true)   
    }

    const openEbooks = () =>{
        closeAnnotation()
        closeAbout()
        setIsEbookOpened(true)

    }
    const closeAnnotation = () =>{
        setIsAnnotationsOpened(false) 
    }

    const closeAbout = () =>{
        setIsAboutOpened(false)   
    }

    const closeEbooks = () =>{
        setIsEbookOpened(false)

    }    



    const nextStep = () =>{

      let nextGroup = stepInfo.sGroup
      let nextStep = stepInfo.sStep + 1  
        if (stepInfo && stepInfo.sNextGroup===1) {
          nextGroup += 1
          nextStep = 0   
        }      
        console.log(nextGroup);console.log(nextStep)
        getInfo({group: nextGroup, step: nextStep})   
    }



    const confirmChangePage =  () =>{
        console.log(haveChanges)
        if (haveChanges) {

            console.log('if else ')

        
            setActiveConfirmScreen(true)
        } else {
            redirect()
        }


    }



    const checkChanges = (changesState) =>{ 
        if (changesState){
            setHaveChanges(true)

        }
    }
    
    const answerNextStep =  async() =>{      
        axios.post('https://fvrw74cj2zkivukmkai7gostme0kwpue.lambda-url.us-east-2.on.aws/',    
        {userId: UserStore.getUserId(), step: stepInfo.sStep, group: stepInfo.sGroup}   
        ).then(resp=>{     
              getAllGroupsInfo()
          })  
            confirmChangePage()               
      }    

    const getMaterials = async({thisStep, thisGroup}) =>{
        axios.get('https://leyv2m5mnf5zrwc5vppejzvpwq0qkjvp.lambda-url.us-east-2.on.aws/', 
        
        
        
        {params:{company: UserStore.getUserId(), token: UserStore.getToken(), step: thisStep, group: thisGroup}
    
    
    
    })
        .then(resp=>setStepMaterials(resp.data.products))


    }




      const disableConfirmScreen = () =>{setActiveConfirmScreen(false)}



      const redirect = () =>{        
                
        let stepGroup = stepInfo.sGroup
        let currentStep = stepInfo.sStep  
                if (stepGroup===5 && currentStep===1) {
            window.location.replace('/expenses')
        } else {
        
    
        setIsLoading(true)
        let nextGroup = stepInfo.sGroup
        let nextStep = stepInfo.sStep + 1  
          if (stepInfo && stepInfo.sNextGroup===1) {
            nextGroup += 1
            nextStep = 0   
          }              getInfo({group: nextGroup, step: nextStep}) }  
     }

  return (
    <div className={styles.newJourney}>  

    {isFullStepOpen && <FullStep setIsFullStepOpen={setIsFullStepOpen} 
    
    subGroups={subGroups} 
    
    reloadSteps={reloadSteps} 
    
    allSteps={allSteps} currentGroup={currentGroup} selectStep={getInfo}/>}
        {isLoading && <LoadingScreen/>}
    {stepInfo && <>
      <div className={styles.topContent}></div>
        <div>
            <p className={styles.title}>{stepInfo.sTitle}</p>

            {stepInfo.sType===0 && <p className={styles.author}>Especialista: {stepInfo.sAuthor}</p>}
            { stepInfo.sType===1 && <div className={styles.line}></div>}
        </div>

        <div className={styles.videoContent}>



            {stepInfo.sType===1 && <AnswersContainer 

            component={component}

            setComponent={setComponent}
            redirect={redirect}
            activeConfirmScreen={activeConfirmScreen} 
            disableConfirmScreen={disableConfirmScreen} 
            checkChanges={checkChanges} 
            saveAnswersState={saveAnswersState } 
            nextStep={answerNextStep} 
            openAbout={openAbout} 
            openAnnotations={openAnnotation} 
            answersStep={stepInfo.sAnswer }/>}
            {stepInfo.sType===0 && <VideoContainer 
            
            getAllGroupsInfo={getAllGroupsInfo} step={stepInfo && stepInfo.sStep}
            openEbooks={openEbooks}
            group={stepInfo && stepInfo.sGroup} 
            
            openAbout={openAbout} 
            
            openAnnotations={openAnnotation} wasWatched={stepInfo && stepInfo.sHaveWatched} videoUrl={stepInfo && stepInfo.sVideoUrl} nextStep={nextStep}/>}
            <VideoStepsContainer setIsFullStepOpen={setIsFullStepOpen} isWeb={screenWidth>800} 
            
            subGroups={subGroups} reloadSteps={reloadSteps} allSteps={allSteps} currentGroup={currentGroup} 
            
            selectStep={getInfo}/>  
        </div>  
    
        <div className={styles.description}>
           {isAboutOpened && <><div className={styles.descriptionTitle}>{stepInfo && !stepInfo.sHaveSelfDesc ? stepInfo.jTitle : stepInfo.sTitle}</div>
              <StepDescription haveSelfDesc={stepInfo && stepInfo.sHaveSelfDesc} selfDesc={stepInfo && stepInfo.sDesc} description={stepInfo && stepInfo.jDescription}/>
          </>} 

            {
                isAnnotationsOpened && <StepAnnotation step={stepInfo.sStep} group={stepInfo.sGroup}/>  
            }

            {isEbookOpened && <StepEbooks materials={stepMaterials}/>}

        </div></>}

    </div>
  )
}


export default NewJourney