import React from 'react'   
import styles from './Options.module.sass'
import UserStore from '../../../store/userStore';
import { Link } from 'react-router-dom';

const Options = ({icon, title, isSubMenu, route, isLogout, isSelected, closeMenu, setNewSelectedPage, setNewSelectedSideMenu}) => {

  const Logout = () => 
  {
      UserStore.setToken( '' );
      UserStore.setLogged( false );
      UserStore.setUserId( '' );
      UserStore.setUserEmail( '' );
      window.location.replace("/login");
  }
    const redirectTo = (route) =>{console.log(route)
      if (isSubMenu) { 
        //UserStore.setSelectedPageStore(`${localRoute[1]}`);  
        //setNewSelectedPage(`${localRoute[1]}`);
        UserStore.setSelectedPageStore(`${route}`); setNewSelectedPage(`${route}`);
      } else {
          UserStore.setSelectedPageStore(`${route}`); setNewSelectedPage(`${route}`); UserStore.setSelectedMenuStore(`${route}`); setNewSelectedSideMenu(`${route}`)
        
      }
      closeMenu()
      if (isLogout) {
        Logout()
      } else {
        //window.location.replace(`/${route}`)        
      }


    }

  return (
    <Link to={`/${route}`} style={{textDecoration: 'none', color: 'inherit'}}><div className={isLogout ? styles.logout : styles.options} id={isSubMenu && styles.subMenu} onClick={()=>redirectTo(route && route)}>
        
        <div>
          
          {icon && <img src={icon} alt=''/>}
          {isSelected && <div className={styles.selectedSubMenu}></div>}
        </div>
        <p>{title}</p>
    


    </div></Link>
  )
}

export default Options