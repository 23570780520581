import React from 'react'
import styles from './RevenuesMobile.module.sass'
import ProductCard from '../cards/productCard/ProductCard'
import { useState } from 'react'
import EditRevenuePopUp from './editRevenuePopUp/EditRevenuePopUp'
const RevenuesMobile = ({products, confirmEditProduct, removeProduct, setLoadingScreen, setCardReload, isAddNewProduct, setIsAddNewProduct}) => {

  const [editProduct, setEditProduct] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState([])

  const defineEdit = (id) =>{

    const product = products.find(product=>product.product===id)
    setEditProduct(true)
    setSelectedProduct(product)



  }

  const closeEditPopUp = () =>{
    setEditProduct(false)
    setSelectedProduct([])


  }

  const closeAddPopUp = () =>{
    setIsAddNewProduct(false)



  }
  return (
    <div className={styles.revenuesMobile}>

        {editProduct && <EditRevenuePopUp product={selectedProduct} closeFn={closeEditPopUp} setLoadingScreen={setLoadingScreen} setCardReload={setCardReload}/>}

        {isAddNewProduct && <EditRevenuePopUp newProduct={true} product={{product: "", name: "", value: 0.0, importance: 3}} closeFn={closeAddPopUp} setLoadingScreen={setLoadingScreen} setCardReload={setCardReload}/>}
        {products && products.map(product=>(
        <div key={product.product}>
          <ProductCard 
              id={product.product}
              product={product.name} 
              price={product.value}
              importance={product.importance}
              editFn={defineEdit}
              removeFn={removeProduct}
          />
        </div>
        ))}
    </div>
  )
}

export default RevenuesMobile