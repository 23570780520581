import React from 'react'   
import styles from './VideoTrailCard.module.css'
import redirectTo from '../../functions/redirectTo'
import UserStore from '../../../../store/userStore'

const VideoTrailCard = ({title, backgroundPicture, time, classesNum, trailPath, trailCode}) => {

  const redirectToVideos = () =>{

    UserStore.setSelectedPageStore('videoTrail/introduction')
    redirectTo(trailPath, trailCode, 1)   

  }
  


  return (
    <div onClick={redirectToVideos} className={styles.videoTrailCard}>{/*Display flex flex direction*/}
        {backgroundPicture &&<div><img src={backgroundPicture} alt=""/></div>}
        <div className={styles.category}><span>Trilha</span></div>
        <div className={styles.bottomContent}>
            <p style={{marginTop: '12px', textAlign: 'center', fontSize: '16px'}}>{title}</p>
            <div className={styles.trailInformations}>


              <p><i style={{marginRight: '4px'}} className="fa fa-clock"></i>{(classesNum!==1) ? `${classesNum} aulas` : `${classesNum} aula`} </p>


              <p><i style={{marginRight: '4px'}} className="fa fa-play-circle"></i>{time || '00:00:00'}</p>
              <p><i className="fa fa-heart"></i></p>
      
            {/*Titulo, Numero de videos, tempo de aulas, curtir*/}</div>
        </div>

    
    </div>
  )
}

export default VideoTrailCard