var UserPlanStore = ( () => {
    const isLogged = () =>
    {
        var logged = false;

        if( localStorage.getItem( 'logged' ) == null )
        {
            logged = false;
        }
        else
        {
            logged =( localStorage.getItem( 'logged' ).localeCompare( 'true' ) === 0 );
        }

        return logged;
    };

    const setLogged = ( newState ) => {
        localStorage.setItem( 'logged', newState );
    };

    const getPlanExpireDate = () => {
        return localStorage.getItem( 'planExpireDate' );
    };

    const setPlanExpireDate = ( planExpireDate ) => {
        localStorage.setItem( 'planExpireDate', planExpireDate );
    }; 


    const getAPIFetchDate = () => {
        return localStorage.getItem( 'APIFetchDate' );
    };

    const setAPIFetchDate = ( APIFetchDate ) => {
        localStorage.setItem( 'APIFetchDate', APIFetchDate );
    }; 

    return{ isLogged: isLogged,
        setLogged: setLogged,
        getPlanExpireDate: getPlanExpireDate,
        setPlanExpireDate: setPlanExpireDate,
        getAPIFetchDate: getAPIFetchDate,
        setAPIFetchDate: setAPIFetchDate
    };
} )();
  
export default UserPlanStore;