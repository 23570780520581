export const ModelsData =   [   
                                {
                                    name:       'Crowdfunding',
                                    desc:       'Concentra-se em receber financiamento externo para um projeto.',
                                    what:       [
                                                    'O Crowdfunding permite que você receba quantias de dinheiro de um grande número de pessoas que desejam ver seu projeto concretizado, geralmente em troca de recompensas.',
                                                    'É uma "vaquinha" online em que as pessoas ajudam a financiar um projeto inovador.'
                                                ],
						            examples:   [
                                                ],    
                                    for:        [
                                                    'Esse modelo se direciona a pequenos empreendedores ou pessoas com idéias inovadoras que não conseguem custear seus projetos.',
                                                    'Também se direciona a pessoas que gostam de impulsionar novas idéias e criar um impacto social positivo'
                                                ],
                                    how:        [
                                                    'A maioria dos investidores (crowdfunder) são particulares ou coletivos privados que escolhem livremente quanto querem investir em determinado projeto.',
                                                    'Em troca do auxílio, o crowdfunder recebe uma recompensa: o produto subsidiado ou algum tipo de bônus.',
                                                    'O projeto só pode ser concretizado quando a meta mínima de financiamento for alcançada, evitando que o projeto precise ser acabado.'
                                                ],
                                    cost:       [
                                                    'O custo será em torno de manutenções na plataforma.',
                                                    'A receita é gerada a partir das participações de investidores.'
                                                ],
                                    logo1Img:   'KickStarterLogo',
                                    logo1Desc:  'A Kickstarter foi a empresa que ajudou a tornar o modelo de Crowdfunding famoso no mundo todo. Através dele, 11 milhões de pessoas já transformaram mais de 107 mil projetos em realidade.',
                                    logo2Img:   'PebbleLogo',
                                    logo2Desc:  'Pebble é um smartwatch que recebeu financiamento por meio de uma campanha do Kickstarter que durou de abril de 2012 a maio de 2012 e arrecadou US$ 10,3 milhões. A Pebble começou a enviar relógios para os apoiadores do Kickstarter em janeiro de 2013.',
                                    logo3Img:   'BrainpoolLogo',
                                    logo3Desc:  'A produção do filme Stomberg foi realizada através da arrecadação do crowdfunding.'
                                },
                                {
                                    name:       'Receita oculta',
                                    desc:       'A principal fonte de receitas é um terceiro que financia as ofertas atraentes, gratuitas ou de baixo preço, feitas aos clientes.',
                                    what:       [
                                                    'Modelo cuja fonte de receitas é um terceiro que financia as ofertas atraentes, gratuitas ou de baixo preço, feitas aos clientes.',
                                                    'Uma pessoa ou empresa utiliza um espaço reservado em um serviço já existente em outra empresa para se promover.',
                                                    'A Principal vantagem de trabalhar com o modelo Receita Oculta: acessa uma fonte alternativa de renda que pode complementar ou até mesmo substituir totalmente as receitas geradas pela venda convencional de produtos.'
                                                ],
						            examples:   [
                                                    'Agência de Marketing Digital,Agência de Publicidade,Barbearia,Casa de Festas Infantis,Clinica de Saúde,Clinica de Fisioterapia,Clinica de Nutrição,Clinica Odontológica,Decoração de Ambientes,Decoração de Eventos,Empresa de Criação de Website,Empresa de Organização de Eventos,Empresa de Webdesign,Hamburgueria,Lanchonete,MEI Assessor de Redes Sociais,MEI Editor de Vídeo,Padaria,Pastelaria,Pizzaria,Produção de Games,Pub,Restaurante.'
                                                ],
                                    for:        [
                                                    'Usuários de internet.',
                                                    'Leitores de jornais e revistas.',
                                                    'Empresas que buscam promover sua marca de forma expansiva e pública.'
                                                ],
                                    how:        [
                                                    'O potencial desse padrão foi sistematicamente sobrevalorizado durante os primeiros anos da nova economia: inúmeras empresas tinham alto valor de mercado, mas não conseguiam gerar receitas reais.',
                                                    'Ainda hoje é difícil avaliar o modelo Receita Oculta.',
                                                    'Ao mesmo tempo, a Receita Oculta continua a ser extremamente popular na publicidade e no comércio de dados de clientes.'
                                                ],
                                    cost:       [
                                                    'Parcialmente ou totalmente obtida pelo pagamento de anúncios.',
                                                    'Manutenção do site quando houver.',
                                                    'Salários.'
                                                ],
                                    logo1Img:   'SkypeLogo',
                                    logo1Desc:  'Software proprietário de mensagens e videoconferência.',
                                    logo2Img:   'SlideShareLogo',
                                    logo2Desc:  'Depois que foi adquirida pelo Linkedin, a empresa roda anúncios principalmente para o público do Linkedin.',
                                    logo3Img:   'YouTubeLogo',
                                    logo3Desc:  'A maior parte da receita do youtube vem de anúncios.'
                                },
                                {
                                    name:       'Alavancagem de Dados do Cliente',
                                    desc:       'Utiliza os dados dos clientes como um recurso rentável que precisa ser aproveitado com as ferramentas apropriadas.',
                                    what:       [
                                                    'Busca adquirir e analisar dados.',
                                                    'A Alavancagem de Dados do Cliente é uma das principais áreas que se beneficia do avanço tecnológico atual e das possibilidades que se abrem nas áreas de coleta e processamento de dados.',
                                                    'O conceito reflete declarações cada vez mais frequentes como “dados são o novo petróleo”.'
                                                ],
						            examples:   [
                                                    'Agência de Marketing Digital , Agência de Publicidade, Empresa de Criação de Website, Empresa de Webdesign, MEI Assessor de Redes Sociais, MEI Editor de Vídeo, Produção de Games'
                                                ],
                                    for:        [
                                                    'Serviço.',
                                                    'TI.',
                                                    'Para empresas que buscam analisar perfis para definir sua persona, seu potencial de poupança, além de realizar análises de mercado em tempo real, gerando anúncios mais eficazes e descobrindo dependências.'
                                                ],
                                    how:        [
                                                    'Buscar e agregar diferentes perfis de pessoa com base em suas compras, pesquisas e navegações na internet.',
                                                    'Fornecer essas informações a empresas que buscam conhecer seu cliente.'
                                                ],
                                    cost:       [
                                                    'Venda dos dados.'
                                                ],
                                    logo1Img:   'LinkedInLogo',
                                    logo1Desc:  'Rede social de negócios.',
                                    logo2Img:   'TwitterLogo',
                                    logo2Desc:  'Rede social e um serviço de microblog, que permite aos usuários enviar e receber atualizações pessoais de outros contatos, por meio do website do serviço, por SMS e por softwares específicos de gerenciamento.',
                                    logo3Img:   'AmericanExpressLogo',
                                    logo3Desc:  'Usa os dados dos consumidores para oferecer perfis de anúncio para terceiros.'
                                },
                                {
                                    name:       'Mercado Bilateral',
                                    desc:       'Facilita a interação entre dois grupos complementares para benefício mútuo usando um intermediário ou uma plataforma.',
                                    what:       [
                                                    'Produtos e serviços à preços acessíveis.',
                                                    'Os conceitos fundamentais para esse modelo são os chamados efeitos de rede indiretos: quanto mais as pessoas de um grupo usam a plataforma, mais atraente ela se torna para as pessoas do outro grupo. Isso funciona em ambas as direções.'
                                                ],
						            examples:   [
                                                    'Agência de Viagens e Turismo, Brechó, Designer Gráfico, Empresa de Criação de Website, Empresa de Webdesign, Loja de Moda Intima , Representação Comercial, Revenda de Semijóias, Sex Shop'
                                                ],
                                    for:        [
                                                    'Sites de recrutamento põem em contato candidatos a empregos e recrutadores, enquanto um mecanismo de busca atrai usuários e anunciantes.',
                                                    'O mecanismo de busca da Google constitui um mercado trilateral: os que buscam, os que são procurados (websites), e quem anuncia nos websites',
                                                    'Nem todos os participantes necessariamente pagam pelo envolvimento: no caso dos mecanismos de busca, por exemplo, eles são gratuitos para os usuários navegarem, mas os anunciantes pagam para serem incluídos em website.'
                                                ],
                                    how:        [
                                                    'O principal desafio para operar essa plataforma é orientar os dois grupos de clientes de modo a maximizar os efeitos de rede indiretos. Alcançar isso ajuda a conectar clientes à empresa.',
                                                    'Também é possível visar três ou mais grupos de clientes: nesse caso, falamos de um mercado multilateral.'
                                                ],
                                    cost:       [
                                                    'Podem ser financiados por anúncios ou gerar receitas a partir de taxas.'
                                                ],
                                    logo1Img:   'FacebookLogo',
                                    logo1Desc:  'A experiência na plataforma para o usuário aumenta conforme quantidade de interações que ele pode ter com amigos, colegas, marcas e outras associações.',
                                    logo2Img:   'AmzWebServicesLogo',
                                    logo2Desc:  'A AWS tem um mercado para aplicativos de computação em nuvem. Em um catálogo digital com milhares de listagens de software de fornecedores independentes, os usuários podem encontrar softwares para testar, comprar e implantar na AWS.',
                                    logo3Img:   'SalesForceLogo',
                                    logo3Desc:  'A Salesforce oferece diferentes aplicativos em sua plataforma AppExchange. Os clientes da Salesforce podem adquirir acesso a aplicativos publicados por terceiros. Assim, ela pode alavancar um Mercado Bilateral, conectando seus clientes pagantes com editores pagantes do AppExchange.'
                                },
                                {
                                    name:       'Digitalização',
                                    desc:       'Concentra-se em transformar um produto ou serviço em formato digital.',
                                    what:       [
                                                    'Forma mais prática de entregar um produto originalmente físico ao público.',
                                                    'Possibilita que a empresa utilize recursos que não poderiam ser disponibilizados na forma original.',
                                                    'Automação de produtos ou serviços.'
                                                ],
						            examples:   [
                                                    'Bar, Brechó, Casa de Bolos e Tortas , Designer Gráfico, Empresa de Criação de Website, Empresa de Webdesign, Escritório de Contabilidade, Hamburgueria, Lanchonete , Loja de Moda Intima , Padaria, Pastelaria, Personal Trainer, Pizzaria, Representação Comercial, Restaurante, Revenda de Semijóias, Sex Shop'
                                                ],
                                    for:        [
                                                    'Busca alcançar possíveis clientes ligados ao meio digital.',
                                                    'Também procura trazer os antigos consumidores para esse meio, proporcionando maior conveniência.'
                                                ],
                                    how:        [
                                                    'Utilizando aplicativos e plataformas digitais, empresas de jornais, música, entre outras, e centralizando seu conteúdo para influenciar melhor seu público.',
                                                ],
                                    cost:       [
                                                    'Associar à outros tipos de modelo como forma de receita.',
                                                    'A assinatura um exemplo clássico .',
                                                    'Os custos base vêm diretamente das manutenções.'
                                                ],
                                    logo1Img:   'AppleLogo',
                                    logo1Desc:  'A Apple ao lançar o iPod e a iTunes Store transformou a indústria da música. A marca permitiu que os usuários baixassem uma variedade maior de músicas do que de qualquer outra forma, tendo acesso em qualquer lugar e a qualquer hora.',
                                    logo2Img:   'AFolhaDeSaoPauloLogo',
                                    logo2Desc:  'A Folha de São Paulo e outros jornais já migraram para versão virtual e atualmente as notícias são acessíveis por celulares, tablets e computadores.',
                                    logo3Img:   'NetflixLogo',
                                    logo3Desc:  'A Netflix começou como uma locadora de DVD. Entretanto ao observar que o consumo de conteúdo digital estava crescendo, lançou seu serviço de assinatura on-line, trazendo o conteúdo para a casa de todos através da Internet.'
                                },
                                {
                                    name:       'Assinatura',
                                    desc:       'A empresa assina um contrato com seus clientes, definindo a frequência e duração da prestação dos serviços.',
                                    what:       [
                                                    'A assinatura permite aos clientes receber produtos ou serviços regularmente.',
                                                    'Os clientes pagam pelos serviços antecipadamente ou em intervalos regulares, geralmente em uma base mensal ou anual.',
                                                    'Os clientes apreciam a assinatura, porque não precisam se preocupar em comprar produtos ou serviços individuais repetidamente, e isso economiza tempo e dinheiro para eles.',
                                                    'Outra vantagem é que o preço de uma assinatura frequentemente é mais baixo do que comprar o mesmo produto ou serviço várias vezes.'
                                                ],
						            examples:   [
                                                    'Academia de Ginastica 	Agência de Viagens e Turismo	Agência de Marketing Digital 	Assistência Técnica em Informática 	Agência de Publicidade	Bar	Barbearia	Brechó	Casa de Bolos e Tortas 	Call-Center 	Casa de Festas Infantis	Centro de Estética 	Clinica de Saúde 	Clinica de Fisioterapia	Clinica de Nutrição	Confeiteira (personalização de bolos e doces)	Clinica Odontológica 	Corretor (a) de Imóveis 	Creche	Crossfit	Decoração de Ambientes 	Decoração de Eventos	Designer Gráfico	Empresa de Criação de Website	Empresa de Organização de Eventos 	Empresa de Serviço de Depilação	Empresa de Webdesign	Escritório de Contabilidade	Esmalteria	Hamburgueria	Lanchonete 	Loja de Animais - Pet Shop	Loja de Moda Intima 	Marcenaria	MEI Assessor de Redes Sociais	MEI Cabeleireiro	MEI Costureiro	MEI Designer de Sobrancelha	MEI Editor de Vídeo	MEI Manicure	MEI Maquiador(a)	Padaria	Papelaria	Pastelaria	Personal Stylist	Personal Trainer	Pilates	Pizzaria	Pousada	Produção de Games	Pub	Representação Comercial	Restaurante	Revenda de Semijóias	Serviço de Jardinagem	Serviço de Pequenas Obras para Construção Civil	Serviços de Massagem	Sex Shop'
                                                ],
                                    for:        [
                                                    'Empresas que precisam de um produto ou serviço regularmente.'
                                                ],
                                    how:        [
                                                    'Esse padrão é ideal quando os clientes precisam de seu produto ou serviço regularmente.',
                                                    'Uma assinatura deve fornecer a seus clientes algum valor adicional como menos tempo necessário para comprar seus produtos, disponibilidade contínua ou menos riscos ao comprar suas ofertas.'
                                                ],
                                    cost:       [
                                                    'Taxa mensal, semestral ou anual paga pelo usuário.'
                                                ],
                                    logo1Img:   'MicrosoftLogo',
                                    logo1Desc:  'O Office 365, permite que os usuários se inscrevam por uma taxa mensal ou anual e sempre obtenham a versão mais recente do software Office em seus dispositivos.',
                                    logo2Img:   'DropboxLogo',
                                    logo2Desc:  'Os clientes que precisam de uma quantidade maior de espaço de hospedagem de arquivos podem atualizar sua capacidade reservando uma assinatura mensal ou anual. Quanto mais espaço eles exigem, maior é o preço da assinatura.',
                                    logo3Img:   'ClubeWineLogo',
                                    logo3Desc:  'Esta empresa transformou a experiência de degustar vinhos e espumantes em um modelo de assinatura que faz bastante sucesso devido à sua proposta diferenciada em relação ao varejo.'
                                },
                                {
                                    name:       'Código Aberto',
                                    desc:       'Permite que os produtos sejam desenvolvidos por uma comunidade pública em vez de uma única empresa.',
                                    what:       [
                                                    'O Código aberto (Open Source) permite que os produtos sejam desenvolvidos por uma comunidade pública em vez de uma única empresa.',
                                                    'O código-fonte é acessível ao público, de modo que qualquer um — um diletante ou um profissional — pode fazer parte da comunidade e contribuir com sua perícia.',
                                                    'Como resultado, as soluções desenvolvidas não pertencem a uma única empresa, mas ao público como um todo.'
                                                ],
						            examples:   [
                                                    'Produção de Games, Empresa de Webdesign'
                                                ],
                                    for:        [
                                                    'O Código Aberto é amplamente aplicado em projetos de softwares.',
                                                    'Programadores jovens em particular estão fazendo uso do Código aberto.',
                                                ],
                                    how:        [
                                                    'O desenvolvimento costuma ser realizado de maneira espontânea e gratuita pelos membros da comunidade.',
                                                    'Esses membros muitas vezes têm razões pessoais para participar do desenvolvimento como, por exemplo, o interesse em melhorar a solução atual.',
                                                    'Uma vantagem significativa é que o desenvolvimento de Código aberto não tem dependências em relação a fornecedores.'
                                                ],
                                    cost:       [
                                                    'O produto é gratuito e pode receber colaborações.',
                                                    'A receita geralmente é gerada com serviços complementares ao produto, como consultoria e suporte.'
                                                ],
                                    logo1Img:   'MozillaLogo',
                                    logo1Desc:  'Navegador livre e multiplataforma desenvolvido pela Mozilla Foundation com ajuda de centenas de colaboradores.',
                                    logo2Img:   'WikipediaLogo',
                                    logo2Desc:  'Tem um portal apenas para projetos de código aberto, onde terceiros podem usar para desenvolver tecnologias.',
                                    logo3Img:   'GoogleLogo',
                                    logo3Desc:  'Há um portal apenas para projetos de código aberto, onde terceiros podem usar para desenvolver tecnologias.'
                                },
                                {
                                    name:       'Add-on',
                                    desc:       'Concentra-se em manter o produto principal com preço baixo e oferecer itens extras, elevando o valor final.',
                                    what:       [
                                                    'Produto personalizado.',
                                                    'O cliente pode acrescer extras que satisfaçam suas necessidades específicas.',
                                                    'Ao criar uma proposta de valor, as empresas geralmente precisam determinar qual seleção de características do produto irá produzir a maior utilidade para o maior número de clientes.',
                                                    'A partir das funções principais do produto básico, cada cliente pode escolher seus “add-ons” preferidos, de modo a obter um nível ótimo de utilidade do produto.'
                                                ],
						            examples:   [
                                                    'Agência de Viagens e Turismo, Agência de Marketing Digital , Agência de Publicidade, Bar, Casa de Bolos e Tortas , Confeiteira (personalização de bolos e doces), Empresa de Criação de Website, Escritório de Contabilidade, Hamburgueria, Lanchonete , MEI Assessor de Redes Sociais, MEI Editor de Vídeo, Padaria, Pastelaria, Pizzaria, Produção de Games, Pub, Representação Comercial, Restaurante'
                                                ],
                                    for:        [
                                                    'Adequado a mercados com difícil segmentação.',
                                                    'Grupo de clientes com necessidades variadas.'
                                                ],
                                    how:        [
                                                    'Deixar o produto básico para que haja necessidade de acrescentar algo mais.',
                                                    'Comparação de preços.',
                                                    'Adicionais que atraiam o cliente.',
                                                    'Estrutura de custos enxuta.'
                                                ],
                                    cost:       [
                                                    'O valor do produto base será sua principal receita.',
                                                    'Um extra mais caro cobre outro mais barato.',
                                                    'Estratégias agressivas de preços.'
                                                ],
                                    logo1Img:   'RyanairLogo',
                                    logo1Desc:  'A ryanair é uma companhia aérea que utiliza esse modelo cobrando US$ 50 por passagem e complementa seu serviço com adicionais como: reserva de assento, bagagem extra e impressão de tickets esquecidos. 1/4 de sua receita é resultado desses adicionais.',
                                    logo2Img:   'SapLogo',
                                    logo2Desc:  'A SAP é uma criadora de softwares que utiliza preços competitivos em seus pacotes básicos. após a compra de um pacote, os clientes são incentivados a comprar elementos complementares para aproveitar ao máximo o potencial do software.',
                                    logo3Img:   'SegaLogo',
                                    logo3Desc:  'A Sega lançou um console em 1999 por US$ 199 e vendia separadamente os jogos por até US$ 70 Como os clientes sempre compravam mais de um jogo, a empresa faturou muito em receitas adicionais recorrentes.'
                                },
                                {
                                    name:       'Lock in',
                                    desc:       'Clientes ficam "presos" a um fornecedor de produtos e serviços, de forma que mudar para outro fornecedor implica custos extras.',
                                    what:       [
                                                    'A empresa cria uma propriedade exclusividade que só pode ser utilizada em seus itens.',
                                                    'Nesse modelo de negócio, os clientes são aprisionados no mundo de um fornecedor de produtos e serviços, de modo que mudar para outro fornecedor implica custos ou penalidades substanciais.',
                                                    'Nesse contexto o termo “custos” não se refere apenas a custos monetários: o tempo necessário para adotar uma nova opção e entender como usá-la pode ser igualmente relevante para os clientes.'
                                                ],
						            examples:   [
                                                    'Consultoria, Corretor (a) de Imóveis , Escritório de Contabilidade, Produção de Games'
                                                ],
                                    for:        [
                                                    'Fornecedores.',
                                                    'Empresas que buscam exclusividade em seu produto.',
                                                    'Clientes que sabem que ao procurar certo produto ou serviço, a marca estará disponível, além de ter a segurança e confiança, juntamente com benefícios de preço.'
                                                ],
                                    how:        [
                                                    'Sites.',
                                                    'Lojas.',
                                                    'Produzir e criar produtos/serviços que sejam únicos da marca.',
                                                    'O padrão Lock-in (fidelização) apresenta algumas variações diferentes.',
                                                    'Os contratos que obrigam o uso de um determinado fornecedor, por exemplo, são uma versão bastante óbvia do padrão.',
                                                    'Outra maneira muito comum são ativos investidos que exigem compras complementares específicas.'
                                                ],
                                    cost:       [
                                                    'Vendas.',
                                                    'Produção.',
                                                    'Manutenção.',
                                                    'Transporte.',
                                                    'Salários.'
                                                ],
                                    logo1Img:   'NespressoLogo',
                                    logo1Desc:  'Nespresso é uma marca da Nestlé Nespresso S.A., uma unidade operacional do Grupo Nestlé.',
                                    logo2Img:   'NintendoLogo',
                                    logo2Desc:  'Desenvolvedora e publicadora japonesa de jogos eletrônicos e consoles.',
                                    logo3Img:   'FacebookLogo',
                                    logo3Desc:  'Se dá pela natureza do network de amigos, onde mudar para outra plataforma se torna muito mais custoso.'
                                },
                                {
                                    name:       'Lâmina de Barbear',
                                    desc:       'Isca e anzol.',
                                    what:       [
                                                    'Concentra-se em vender pequenas quantidades de uma grande gama de produtos.',
                                                    'Proposta de Valor do nicho deve ser diferenciada.',
                                                    'Consumidores podem escolher entre uma gama grande de produtos, o que aumenta as chances de encontrar produtos que os satifaçam suas necessidades individuais.',
                                                    'Consumidores valorizam receber tudo de uma empresa só.'
                                                ],
						            examples:   [
                                                    'Empresa de Webdesign'
                                                ],
                                    for:        [
                                                    'Focado em Nichos específicos e produtos de Massa.',
                                                    'Capacidade de se adaptar rápido às mudanças de mercado.'
                                                ],
                                    how:        [
                                                    'Inteligência para oferecer produtos baseados em histórico de busca, servindo para ajudar os consumidores a encontrar o produto nicho certo sem dificuldade.',
                                                    'Permitir os consumidores a criar seus próprios produtos.',
                                                    'Processo e TI devem ser capazes de gerenciar números massivos de produtos.',
                                                    'Recursos essenciais: Custos de compra, processamento de compra, logística e TI.'
                                                ],
                                    cost:       [
                                                    'Os produtos nichados não podem ter custos significativamente maiores do que os de massa.',
                                                    'Margens menores, mas grande volume de vendas de produtos individuais.'
                                                ],
                                    logo1Img:   'NespressoLogo',
                                    logo1Desc:  'Venda de capsulas de café que só funcionam e máquinas Nespresso.',
                                    logo2Img:   'GilletteLogo',
                                    logo2Desc:  'A Gillette vende a baixo custo seus aparelhos de barbear, mas as lâminas precisam ser trocadas recorrentemente e apenas os da marca são compatíveis com o aparelho.',
                                    logo3Img:   'AppleLogo',
                                    logo3Desc:  'Os produtos desta empresa tendem a ter pouca ou nenhuma compatibilidade com aparelhos ou acessórios de outras marcas.'
                                },
                                {
                                    name:       'E-Commerce',
                                    desc:       'Concentra-se em utilizar canais online para entregar produtos e serviços.',
                                    what:       [
                                                    'Utiliza recursos da internet para chegar ao consumidor final.',
                                                    'Oferece maior facilidade na busca por produtos, além de descontos.',
                                                    'Subtrai custos de intermediários e varejo.'
                                                ],
						            examples:   [
                                                    'Brechó, Designer Gráfico, Loja de Moda Intima , Marcenaria, Revenda de Semijóias, Serviço de Jardinagem, Serviço de Pequenas Obras para Construção Civil, Sex Shop'
                                                ],
                                    for:        [
                                                    'De modo geral os e-commerces se direcionam ao público geral com acesso a internet. Entretanto, é possível segmentar conforme a proposta empresa: lojas de luxo, de livros ou até de passagens aéreas por exemplo.'
                                                ],
                                    how:        [
                                                    'Os sites ganham protagonismo e são peça-chave para quem deseja entrar nesse modelo de negócio.',
                                                    'A partir de softwares é possível modelar sua loja online, adicionar diversas opções de pagamento e ter o feedback direto do cliente.',
                                                    'A logística é essêncial para que o cliente receba em seu endereço os itens adquiridos.'
                                                ],
                                    cost:       [
                                                    'Os maiores custos nesta opção são com manutenções da rede da plataforma e com a logística, que na maioria das vezes é paga pelo próprio consumidor.',
                                                    'É importante ter um canal de atendimento que coloque o cliente sempre em primeiro lugar.'
                                                ],
                                    logo1Img:   'AmzLogo',
                                    logo1Desc:  'A amazon com seu modelo de e-commerce permitiu o estabelecimento de sistemas integrados de encomenda e distribuição, bem como a disponibilização desses sistemas para outros varejistas por meio de sua plataforma online.',
                                    logo2Img:   'MercadoLivreLogo',
                                    logo2Desc:  'O Mercado Livre possui um aglomerado de itens das mais diversas opções, com venda direta para o site ou para lojas adjacentes a plataforma. ',
                                    logo3Img:   'ShopeeLogo',
                                    logo3Desc:  'Empresa focada em unir compradores e vendedores em uma plaforma digital oferecendo uma variedade de produtos para diferentes mercados.'
                                },
                                {
                                    name:       'Venda Direta',
                                    desc:       'Concentra-se em vender um produto ou serviço direto para o consumidor final.',
                                    what:       [
                                                    'Utiliza a venda por catálogo como forma de apresentação.',
                                                    'Cliente final recebe um produto de melhor qualidade diretamente da empresa.',
                                                    'Elimina os custos de manter uma loja de varejo.'
                                                ],
						            examples:   [
                                                    'Consultoria, Mentoria, Academia de Ginastica , Agência de Viagens e Turismo, Agência de Marketing Digital , Assistência Técnica em Informática , Agência de Publicidade, Bar, Brechó, Casa de Bolos e Tortas , Call-Center , Casa de Festas Infantis, Centro de Estética , Clinica de Saúde , Clinica de Fisioterapia, Clinica de Nutrição, Confeiteira (personalização de bolos e doces), Clinica Odontológica , Corretor (a) de Imóveis , Creche, Crossfit, Decoração de Ambientes , Decoração de Eventos, Designer Gráfico, Empresa de Criação de Website, Empresa de Organização de Eventos , Empresa de Serviço de Depilação, Empresa de Webdesign, Escritório de Contabilidade, Esmalteria, Hamburgueria, Lanchonete , Loja de Animais - Pet Shop, Loja de Moda Intima , Marcenaria, MEI Assessor de Redes Sociais, MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Editor de Vídeo, MEI Manicure, MEI Maquiador(a), Padaria, Pastelaria, Personal Trainer, Pilates, Pizzaria, Pousada, Produção de Games, Pub, '
                                                ],
                                    for:        [
                                                    'Visa tornar profissionais independentes a forma de comunicação da empresa.',
                                                    'O cliente final é aquele que tem interesse pelo item/serviço oferecido.'
                                                ],
                                    how:        [
                                                    'Nesse modelo de negócio a empresa se comunicará com o cliente através de consultoras capacitadas pela empresa.',
                                                    'Os meios utilizados serão catálogos e apresentação do produto físico quando possível.',
                                                    'Nesse formato podem ocorrer demonstrações do produto, trazendo o consumidor para mais perto da marca.'
                                                ],
                                    cost:       [
                                                    'A receita gerada será o valor dos produtos subtraída a comissão das consultoras.',
                                                    'Os custos serão de produção dos produtos e de fornecimento de matéria-prima, tendo a econômia de não manter uma loja física.'
                                                ],
                                    logo1Img:   'TupperwareLogo',
                                    logo1Desc:  'A Tupperware, reconhecida pelos utilitários plásticos de cozinha utiliza venda direta como forma de arrecadação. Utilizando consultores e representantes, as “festas do Tupperware”, eram feitas para parentes, amigos e vizinhos em suas casas. Os representantes são classificados em hierarquias.',
                                    logo2Img:   'MaryKayLogo',
                                    logo2Desc:  'A Mary Kay é uma marca de produtos de beleza que trabalha apenas com consultoras, em diferentes níveis de hierarquia ao redor do mundo.',
                                    logo3Img:   'AvonLogo',
                                    logo3Desc:  'Empresa de cosméticos que funciona vendendo diretamente para o consumidor, através de diversos canais e profissionas independentes.'
                                },
                                {
                                    name:       'Vendendo Experiências',
                                    desc:       'Concentra-se em agregar valor a partir de experiências oferecidas.',
                                    what:       [
                                                    'Fornece a concepção de experiências e impressões agregada a produtos e serviços, em lojas físicas, o ambiente e o atendimento são peças-chave, trazendo um valor maior a venda e tornando os clientes mais fiéis a marca.'
                                                ],
						            examples:   [
                                                    'Mentoria	Academia de Ginastica 	Agência de Viagens e Turismo	Agência de Marketing Digital 	Assistência Técnica em Informática 	Agência de Publicidade	Bar	Barbearia	Brechó	Casa de Bolos e Tortas 	Call-Center 	Casa de Festas Infantis	Centro de Estética 	Clinica de Saúde 	Clinica de Fisioterapia	Clinica de Nutrição	Confeiteira (personalização de bolos e doces)	Clinica Odontológica 	Corretor (a) de Imóveis 	Creche	Crossfit	Decoração de Ambientes 	Decoração de Eventos	Designer Gráfico	Empresa de Criação de Website	Empresa de Organização de Eventos 	Empresa de Serviço de Depilação	Empresa de Webdesign	Escritório de Contabilidade	Esmalteria	Hamburgueria	Lanchonete 	Loja de Animais - Pet Shop	Loja de Moda Intima 	Marcenaria	MEI Assessor de Redes Sociais	MEI Cabeleireiro	MEI Costureiro	MEI Designer de Sobrancelha	MEI Editor de Vídeo	MEI Manicure	MEI Maquiador(a)	Padaria	Papelaria	Pastelaria	Personal Stylist	Personal Trainer	Pilates	Pizzaria	Pousada	Produção de Games	Pub	Representação Comercial	Restaurante	Revenda de Semijóias	Serviço de Jardinagem	Serviço de Pequenas Obras para Construção Civil	Serviços de Massagem'
                                                ],
                                    for:        [
                                                    'Esse tipo de negócio se dá para o nicho de pessoas que buscam algo além da compra em si: seja uma degustação, uma demonstração, um espaço para café, juntamente com um atendimento atencioso e solícito.'
                                                ],
                                    how:        [
                                                    'Esse negócio terá maior chance de dar certo com uma equipe treinada e capacitada para receber o consumidor.',
                                                    'Como representantes diretos da marca, suas atitudes se refletirão na compra do cliente.',
                                                    'Portanto, seja em uma loja de roupas, ofereça um café, mostre opções de roupas que combinam com a peça X, dê voz ao cliente.'
                                                ],
                                    cost:       [
                                                    'Nesse ramo, é normal incluír o valor da experiência no preço final do produto ou serviço.'
                                                ],
                                    logo1Img:   'StarbucksLogo',
                                    logo1Desc:  'Esta é uma das maiores empresas de venda de relógios e óculos da America Latina, que oferece uma combinação de experiências encantadoras aos clientes.',
                                    logo2Img:   'OutbackLogo',
                                    logo2Desc:  'No outback, a experiência é notada a partir do atendimento, solícito e eficiente.',
                                    logo3Img:   'PorscheLogo',
                                    logo3Desc:  'A marca cria uma experiência associando emoções positivas ao explorar modelos dos carros antes que a venda seja efetivada.'
                                },
                                {
                                    name:       'Cross-Selling',
                                    desc:       'Concentra-se em oferecer produtos e serviços complementares à gama básica.',
                                    what:       [
                                                    'Ocorre quando é oferecido um produto ou serviço complementar à oferta que o cliente adquiriu.',
                                                    'O cliente é estimulado a adquirir soluções que complementam sua experiência e os produtos oferecidos combinam com a compra inicial.',
                                                    'A compra de um produto não está atrelada a outro, o cliente escolhe se quer ou não o item.'
                                                ],
						            examples:   [
                                                    'Mentoria, Academia de Ginastica , Agência de Viagens e Turismo, Agência de Marketing Digital , Assistência Técnica em Informática , Agência de Publicidade, Bar, Brechó, Casa de Bolos e Tortas , Casa de Festas Infantis, Centro de Estética , Confeiteira (personalização de bolos e doces), Corretor (a) de Imóveis , Crossfit, Decoração de Ambientes , Decoração de Eventos, Designer Gráfico, Empresa de Criação de Website, Empresa de Organização de Eventos , Empresa de Serviço de Depilação, Empresa de Webdesign, Escritório de Contabilidade, Esmalteria, Hamburgueria, Lanchonete , Loja de Animais - Pet Shop, Loja de Moda Intima , Marcenaria, MEI Assessor de Redes Sociais, MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Editor de Vídeo, MEI Manicure, MEI Maquiador(a), Padaria, Pastelaria, Personal Trainer, Pilates, Pizzaria, Pousada, Produção de Games, Pub, Representação Comercial, Restaurante, Revenda de Semijóias, Serviço de Jardinagem, Serviço de Pequenas Obras para Construção Civil, Serviços de Massagem, Sex Shop'
                                                ],
                                    for:        [
                                                    'Para os clientes, o principal benefício é possuir maior valor em um único lugar, economizando assim o custo de procurar produtos adicionais e tendo maior segurança na compra.',
                                                    'Empresas que buscam gerar maior faturamento.'
                                                ],
                                    how:        [
                                                    'Criação de combinações entre produtos e serviços, de modo a gerar maior valor para o cliente e subir o faturamento.',
                                                    'Conheça seu cliente e veja se o adicional será útil a ele.'
                                                ],
                                    cost:       [
                                                    'Pode-se utilizar um produto ou serviço simples e com baixa margem de lucro combinado com produtos de alta margem.',
                                                    'É importante que o valor adicionado à proposta inicial não seja maior do que 25%.',
                                                    'Se o valor passar disso, há uma probabilidade do cliente ou futuro cliente se espantar e desistir tanto da venda cruzada quanto da venda inicial.',
                                                    'A receita aumenta, conforme os demais produtos que o cliente introduz.'
                                                ],
                                    logo1Img:   'CinemarkLogo',
                                    logo1Desc:  'Rede de salas de cinema que tem venda de ingressos e que disponibiliza no mesmo espaço venda de pipoca, guloseimas e bebidas para o cliente acompanhar a sessão.',
                                    logo2Img:   'ShellLogo',
                                    logo2Desc:  'Utiliza lojas de conveniência com uma gama de produtos e serviços, para que o cliente adquira suas necessidades em apenas um local.',
                                    logo3Img:   'DollarShaveClubLogo',
                                    logo3Desc:  'Depois de começar com serviços de barbearia, o Dollar Shave Club oferece agora diversos tipos de produtos adicionais, como cosméticos e finalizadores.'
                                },
                                {
                                    name:       'Fidelização',
                                    desc:       'Concentra-se em desenvolver uma relação com o cliente e fidelizá-lo.',
                                    what:       [
                                                    'Utiliza de recursos como programa de pontos e vantagens para manter o cliente atrelado a marca.',
                                                    'Maquia descontos no produto ou serviço que mais tarde são recompensados por compras adicionais.'
                                                ],
						            examples:   [
                                                    'Consultoria, Mentoria, Assistência Técnica em Informática , Centro de Estética , Creche, Crossfit, Empresa de Serviço de Depilação, Esmalteria, Loja de Animais - Pet Shop, Loja de Moda Intima , MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Manicure, MEI Maquiador(a), Pousada, Representação Comercial, Revenda de Semijóias, Serviço de Jardinagem, Serviço de Pequenas Obras para Construção Civil, Serviços de Massagem, Sex Shop'
                                                ],
                                    for:        [
                                                    'Esse tipo de modelo de negócios busca indivíduos com cartão de crédito, que mantém certa frequência de compra nos mesmo locais e que são influenciados por descontos.',
                                                    'Empresas e linhas de crédito também são beneficiadas.'
                                                ],
                                    how:        [
                                                    'Com o uso de uma plataforma, cria- se uma rede de lojas e serviços parceiros.',
                                                    'Habilita descontos conforme a assiduidade da pessoa.'
                                                ],
                                    cost:       [
                                                    'O programa de descontos em si é totalmente gratuito.',
                                                    'A receita se dá da venda de pontos extras e do percentual de taxas das marcas parceiras.',
                                                    'As recompensas também funcionam como um incentivo para que os clientes façam compras adicionais, uma vez que as recompensas geralmente cobrem apenas parte do preço de novos produtos ou serviços.'
                                                ],
                                    logo1Img:   'LiveloLogo',
                                    logo1Desc:  'A Livelo é um programa de pontos onde linhas de cartão parceiras registram as compras do cliente e calculam os benefícios correspondentes. esses bônus podem ser produtos físicos ou descontos em compras futuras, uma forma de seduzir os clientes e faze-los retornar à loja.',
                                    logo2Img:   'UnimedLogo',
                                    logo2Desc:  'Criou uma parceria com diversas empresas, oferecendo desconto a seus conveniados.',
                                    logo3Img:   'AmzLogo',
                                    logo3Desc:  'A Amazon aumenta a fidelidade do cliente por meio de sua assinatura Premium, a Amazon Prime. Isso permite aos membros frete grátis em diversos itens e acesso a vários outros conteúdos, incluindo o acesso a sua plataforma de streaming: Prime Video.'
                                },
                                {
                                    name:       'Afiliação',
                                    desc:       'Concentra-se em utilizar terceiros para comercializar seus produtos, mediante percentual de venda. ',
                                    what:       [
                                                    'Utiliza terceiros na venda de produtos e gera receita com as vendas deles.',
                                                    'O afiliado não se preocupa com a produção, apenas com a venda final.',
                                                    'Recompensas financeiras após a venda.',
                                                    'Redução de custos, focando apenas no percentual da venda.'
                                                ],
						            examples:   [
                                                    'Agência de Viagens e Turismo, Casa de Bolos e Tortas , Call-Center , Corretor (a) de Imóveis , Crossfit, Empresa de Criação de Website, Loja de Moda Intima , Representação Comercial, Revenda de Semijóias, Sex Shop'
                                                ],
                                    for:        [
                                                    'Pessoas ou empresas que buscam faturar com a venda de produtos.',
                                                    'Nicho de clientes que buscam renda extra ou comissionada e que recebem por venda ou exibição.',
                                                    'Empresas de revenda multimarcas.'
                                                ],
                                    how:        [
                                                    'Para o afiliado, a responsabilidade fica em fazer a venda e o marketing em torno do produto.',
                                                    'Para a empresa, o produto deve ter alta escalabilidade e fácil comercialização.',
                                                    'A gestão dos afiliados é importante, pois representará a imagem da empresa.',
                                                    'Normalmente é de forma online'
                                                ],
                                    cost:       [
                                                    'Os custos de produção devem ser baixos, para que a margem de lucro da revenda seja alta.',
                                                    'O custo é decorrente das comissões dos afiliados.'
                                                ],
                                    logo1Img:   'HotmartLogo',
                                    logo1Desc:  'Disponibiliza a plataforma para quem deseja promover produtos de outras pessoas ou produtores e receber comissões pelas vendas por estes realizadas.',
                                    logo2Img:   'EduzzLogo',
                                    logo2Desc:  'É um plataforma digital que atua prestando serviços para empreendedores do marketing digital através de Softwares, e oferece um programa de afiliação.',
                                    logo3Img:   'AirbnbLogo',
                                    logo3Desc:  'A Airbnb oferece um programa de afiliados envolvendo a entrada de clientes e anfitriões. Os anfitriões que possuem postagem no Airbnb recebem uma comissão por cada reserva que é efetuada. O mesmo vale para os afiliados que mostram oportunidades de hospedagem do Airbnb, ganhando créditos em próximas hospedagensa.'
                                },
                                {
                                    name:       'Divisão de Receita',
                                    desc:       'Baseia-se na compra de uma fração de um bem.',
                                    what:       [
                                                    'É a possibilidade de adquirir um produto ou serviço que talvez não pudesse ser comprado devido ao alto custo.',
                                                    'Todos os interessados pagam uma fração do valor do bem.'
                                                ],
						            examples:   [
                                                    'Agência de Marketing Digital , Agência de Publicidade, Casa de Festas Infantis, Centro de Estética , Clinica de Saúde , Clinica de Fisioterapia, Clinica de Nutrição, Clinica Odontológica , Corretor (a) de Imóveis , Crossfit, Decoração de Ambientes , Decoração de Eventos, Empresa de Organização de Eventos , Empresa de Serviço de Depilação, Empresa de Webdesign, Esmalteria, MEI Assessor de Redes Sociais, MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Editor de Vídeo, MEI Manicure, MEI Maquiador(a), Produção de Games, Representação Comercial, Serviços de Massagem'
                                                ],
                                    for:        [
                                                    'Clientes que buscam ter prorpiedade sobre um produto com alto valor de capital,mas não querem arcar com todos os custos sozinhos.',
                                                    'Empresas ou pessoas que não utilizarão com frequência ou totalidade um determinado produto ou serviço.'
                                                ],
                                    how:        [
                                                    'É implementada na forma de uma associação, onde uma empresa controla os acessos de cada sócio conforme o percentual da propriedade.',
                                                    'A empresa supervisora garante a manutenção do bem e também as regras e regulamentos que regem a associação.'
                                                ],
                                    cost:       [
                                                    'Os custos são divididos conforme o percentual de posse de cada sócio.',
                                                    'O produto é utilizado de forma mais eficiente, dando maior usabilidade e menos encargos do que um produto parado que necessita de manutenções mesmo sem uso.',
                                                    'A empresa que controla essa relação, recebe um percentual para manejar, agendar, e realizar manutenções.'
                                                ],
                                    logo1Img:   'MobilityLogo',
                                    logo1Desc:  'A empresa trabalha com compartilhamento de veículos, na qual o cliente arca apenas com a taxa de adesão e com o abastecimento do veículo.',
                                    logo2Img:   'HapimagLogo',
                                    logo2Desc:  'Os clientes compram o direito de usar uma casa de férias, normalmente uma unidade em um resort, por um dado período de tempo a cada ano, pagando pela manutenção anual.',
                                    logo3Img:   'AmzKindleLogo',
                                    logo3Desc:  'Oferece royalties aos autores.'
                                },
                                {
                                    name:       'Cauda Longa',
                                    desc:       'Concentra-se em vender pequenas quantidades de uma grande gama de produtos.',
                                    what:       [
                                                    'Proposta de Valor do nicho deve ser diferenciada.',
                                                    'Consumidores podem escolher entre uma gama grande de produtos, o que aumenta as chances de encontrar produtos que os satisfaçam suas necessidades individuais.',
                                                    'Consumidores valorizam receber tudo de uma empresa só.'
                                                ],
						            examples:   [
                                                    'Agência de Viagens e Turismo, Bar, Brechó, Centro de Estética , Empresa de Serviço de Depilação, Empresa de Webdesign, Esmalteria, Hamburgueria, Lanchonete , Loja de Moda Intima , MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Manicure, MEI Maquiador(a), Padaria, Pastelaria, Pizzaria, Pub, Restaurante, Revenda de Semijóias, Serviços de Massagem, Sex Shop'
                                                ],
                                    for:        [
                                                    'Focado em nichos específicos e produtos de massa.'
                                                ],
                                    how:        [
                                                    'Inteligência para oferecer produtos baseados em histórico de busca, servindo para ajudar os consumidores a encontrar o produto nicho certo sem dificuldade.',
                                                    'Permitir os consumidores a criar seus próprios produtos.',
                                                    'Processo e TI devem ser capazes de gerenciar números massivos de produtos.',
                                                    'Recursos essenciais: Custos de compra, processamento de compra, logística e TI.'
                                                ],
                                    cost:       [
                                                    'Os produtos nichados não podem ter custos significativamente maiores do que os de massa.',
                                                    'Margens menores, mas grande volume de vendas de produtos individuais.'
                                                ],
                                    logo1Img:   'CacauShowLogo',
                                    logo1Desc:  'Empresa do ramo alimentício que vende um variado leque de produtos à base de chocolate.',
                                    logo2Img:   'iTunesLogo',
                                    logo2Desc:  'É um reprodutor de áudio que organiza um acervo de milhões de músicas e vídeos.',
                                    logo3Img:   'AmzLogo',
                                    logo3Desc:  'A Amazon kindle oferece uma Variedade de livros, Sendo eles Gratuitos ou pagos.'
                                },
                                {
                                    name:       'Self-Service',
                                    desc:       'Além de preços menores, os clientes normalmente descobrem que o autoatendimento também economiza tempo.',
                                    what:       [
                                                    'No modelo autoatendimento, parte da agregação de valor de um produto ou serviço é passada para o cliente em troca de um preço mais baixo.',
                                                    'Isso é particularmente adequado para processos que geram custos altos, mas agregam um valor percebido relativamente baixo para o cliente.',
                                                    'Esse modelo é capaz até mesmo de aumentar a eficiência, uma vez que pode existir uma etapa rápida de agregação de valor e de maneira mais direcionada.'
                                                ],
						            examples:   [
                                                    'Academia de Ginastica , Brechó, Empresa de Criação de Website, Loja de Moda Intima , Personal Trainer, Pilates, Revenda de Semijóias, Sex Shop'
                                                ],
                                    for:        [
                                                    'Aplicações clássicas incluem selecionar itens nas prateleiras, planejar seus próprios projetos ou, de maneira autônoma, conduzir as operações de pagamento por produtos e serviços.',
                                                    'Uma empresa que usa o Autoatendimento tem grande potencial de economizar mão de obra, pois o cliente muitas vezes pode substituir um número significativo de postos de trabalho.'
                                                ],
                                    how:        [
                                                    'O padrão autoatendimento é bom para clientes que estão dispostos a assumir algum trabalho adicional em troca de preços mais baixos.',
                                                    'Esse padrão também é benéfico quando um elemento “faça você mesmo” no processo de fabricação aumenta o valor percebido pelo cliente. Por exemplo, T-shirts que os próprios clientes criam.',
                                                    'Para implementar com sucesso o Autoatendimento, você deve pensar a partir do ponto de vista de seus clientes.'
                                                ],
                                    cost:       [
                                                    'Custo reduzido devido a automação de algum processo pelo Self-Service. Cliente recebe redução no preço do produto.'
                                                ],
                                    logo1Img:   'RyanairLogo',
                                    logo1Desc:  'Os clientes são direcionados para terminais de check-in automático ou check-in online.',
                                    logo2Img:   'JeronimoLogo',
                                    logo2Desc:  'Restaurante de fast food do Grupo Madero que tem seu atendimento quase inteiramente Self-Service.',
                                    logo3Img:   'McfFitLogo',
                                    logo3Desc:  'As academias de ginástica da McFit são projetadas para serem totalmente self-service. O equipamento no ginásio tem instruções escritas. Além disso, os membros podem verificar e criar um plano de treinamento online. Coaches na academia geralmente estão presentes apenas para ajudar com perguntas gerais, mas não oferecem treinamento pessoal.'
                                },
                                {
                                    name:       'Pague por Uso',
                                    desc:       'Os clientes que utilizam um serviço moderadamente pagam muito menos.',
                                    what:       [
                                                    'Os clientes pagam por serviços com base na utilização efetiva, em vez de um preço único prefixado.',
                                                    'O modelo Pay-Per-Use, a utilização específica de um serviço ou produto pelo cliente é medida e cobrada.',
                                                    'Dependendo do serviço, os clientes são cobrados de diferentes maneiras: por exemplo, com base no número de unidades utilizadas ou na duração do uso.',
                                                    'Uma vantagem considerável para os clientes é que as origens dos custos incorridos são altamente transparentes.',
                                                    'O padrão também é muito justo, uma vez que os clientes que utilizam um serviço moderadamente pagam muito menos.'
                                                ],
						            examples:   [
                                                    'Consultoria, Academia de Ginastica , Agência de Viagens e Turismo, Agência de Marketing Digital , Assistência Técnica em Informática , Agência de Publicidade, Casa de Festas Infantis, Centro de Estética , Clinica de Saúde , Clinica de Fisioterapia, Clinica de Nutrição, Confeiteira (personalização de bolos e doces), Clinica Odontológica , Creche, Crossfit, Decoração de Ambientes , Decoração de Eventos, Designer Gráfico, Empresa de Criação de Website, Empresa de Organização de Eventos , Empresa de Serviço de Depilação, Empresa de Webdesign, Escritório de Contabilidade, Esmalteria, Hamburgueria, Lanchonete , Loja de Animais - Pet Shop, MEI Assessor de Redes Sociais, MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Editor de Vídeo, MEI Manicure, MEI Maquiador(a), Padaria, Pastelaria, Personal Trainer, Pilates, Pizzaria, Pousada, Produção de Games, Pub, Restaurante, Serviço de Jardinagem, Serviço de Pequenas Obras para Construção Civil, Serviços de Massagem'
                                                ],
                                    for:        [
                                                    'É empregado extensivamente no mercado de consumo de mídia e atrai clientes que desejam se beneficiar da flexibilidade.'
                                                ],
                                    how:        [
                                                    'A Internet das Coisas é um mundo de produtos inteligentes em rede que detectam e geram dados e os transmitem para análise adicional ou adaptação inteligente.',
                                                    'O padrão Pay-Per-Use oferece para as empresas uma grande possibilidade de coletar e analisar dados de consumo para tomada de decisão.'
                                                ],
                                    cost:       [
                                                    'Baseado na utilização efetiva, nas unidades utilizadas ou na duração do uso.',
                                                    'Custos incorridos são altamente transparentes.'
                                                ],
                                    logo1Img:   'AmzWebServicesLogo',
                                    logo1Desc:  'Plataforma de serviços de computação em nuvem, que formam uma plataforma de computação na nuvem oferecida pela amazon.com.',
                                    logo2Img:   'VivoLogo',
                                    logo2Desc:  'Empresa de telefonia e internet: oferece planos pré-pagos que são consumidos conforme o uso pelos clientes.',
                                    logo3Img:   'MobilityLogo',
                                    logo3Desc:  'Cobra baseado na distância percorrida e no tempo de uso.'
                                },
                                {
                                    name:       'Provedor de Soluções',
                                    desc:       'Oferece cobertura total dos produtos e serviços em um domínio particular, consolidados em uma única fonte.',
                                    what:       [
                                                    'Os clientes podem concentrar-se totalmente em seus negócios essenciais, o que frequentemente melhora o desempenho.',
                                                    'Fornece acordos de serviços personalizados e serviços de consultoria, bem como todos os suprimentos e peças de reposição necessários.',
                                                    'O objetivo é oferecer aos clientes um pacote inclusivo que cuida de todas as necessidades em uma determinada área.',
                                                    'A grande vantagem para um provedor de Soluções é a possibilidade de construir relações cada vez mais estreitas com os clientes.'
                                                ],
						            examples:   [
                                                    'Consultoria, Agência de Viagens e Turismo, Agência de Marketing Digital , Agência de Publicidade, Call-Center , Designer Gráfico, Empresa de Criação de Website, Empresa de Webdesign, Escritório de Contabilidade, MEI Assessor de Redes Sociais, MEI Editor de Vídeo, Produção de Games'
                                                ],
                                    for:        [
                                                    'O modelo é particularmente aplicável para clientes que desejam terceirizar uma completa área de especialização, por exemplo, para um provedor de serviços de internet, no caso de serviços web, ou para uma empresa de transporte, no caso de serviços internacionais de entrega.',
                                                    'Serviços personalizados, serviços de consultoria, treinamentos, serviços pós-venda.'
                                                ],
                                    how:        [
                                                    'A área típica em que você pode aplicar esse conceito é a dos serviços pós-venda. Tornando-se um provedor de Soluções completo, uma empresa pode aumentar suas receitas descobrindo novas maneiras de estender sua variedade de serviços.',
                                                    'Descobertas sobre as necessidades e os hábitos dos clientes podem ser usadas para melhorar os produtos e serviços oferecidos.'
                                                ],
                                    cost:       [
                                                    'Com o uso do provedor de soluções, uma empresa pode aumentar a receita conhecendo habitos de consumo de seus clientes e oferecendo a solução completa para suas necessidades.'
                                                ],
                                    logo1Img:   'CiscoLogo',
                                    logo1Desc:  'A Cisco oferece soluções completas para vários tipos de negócios, setores e temas. As soluções incluem componentes de software e hardware.',
                                    logo2Img:   'AppleLogo',
                                    logo2Desc:  'A Apple uniu o iPod com o iTunes oferecendo uma solução completa para o consumo de música do cliente, que vai desde o dispositivo físico para ouvir música de forma portátil até o conteúdo real a ser colocado no ipod.',
                                    logo3Img:   'SapLogo',
                                    logo3Desc:  'A SAP oferece cobertura em uma ampla gama de áreas por meio de seu software corporativo para gerenciar operações comerciais e relações com clientes.'
                                },
                                {
                                    name:       'Disponibilidade Garantida',
                                    desc:       'Reduzir os custos incorridos pela quebra de máquinas ou equipamentos técnicos, garantindo um tempo de inatividade próximo de zero.',
                                    what:       [
                                                    'Disponibilizar serviços sem custo após a venda, como manutenções e reparos.',
                                                    'Permite o acesso dos clientes a todos os serviços necessários para garantir disponibilidade constante dos produtos.',
                                                    'O cliente pode usar a oferta conforme necessário, o que minimi--za as perdas resultantes do tempo de inatividade.'
                                                ],
						            examples:   [
                                                    'Academia de Ginastica , Bar, Call-Center , Empresa de Criação de Website, Hamburgueria, Lanchonete , Padaria, Pastelaria, Personal Trainer, Pilates, Pizzaria, Pub, Restaurante'
                                                ],
                                    for:        [
                                                    'Empresas com formato B2B.',
                                                    'Industrias.',
                                                    'Empresas que necessitam de um serviço 24h por dia ou que mantenha funcionamento ativo nos horários comerciais.'
                                                ],
                                    how:        [
                                                    'Técnicos, consultores e equipe do SAC.',
                                                    'Em uma indústria onde a disponibilidade é fundamental, é recomendável fazer uso desse padrão de modelo de negócio.',
                                                    'É viável usar o padrão para conquistar grandes clientes a longo prazo e exigir um valor Premium relativo aos serviços.',
                                                    'Para haver destaque nesse modelo de negócio, é necessário lidar habilmente com crises imprevistas relacionadas com os serviços ou produtos fornecidos a seus clientes.'
                                                ],
                                    cost:       [
                                                    'Valor percentual pago pelo serviço Premium.',
                                                    'Venda do produto ou serviço.',
                                                    'Manutenções.',
                                                    'Salários.',
                                                    'Fornecedor.'
                                                ],
                                    logo1Img:   'SalesForceLogo',
                                    logo1Desc:  'Empresa americana de software on demand, mais conhecida por ter produzido o CRM chamado Sales Cloud.',
                                    logo2Img:   'AmzWebServicesLogo',
                                    logo2Desc:  'Plataforma de serviços de computação em nuvem',
                                    logo3Img:   'GoogleLogo',
                                    logo3Desc:  'Esta empresa tem uma rede global capaz de garantir a disponibilidade dos serviços em quaisquer cenários.'
                                },
                                {
                                    name:       'Caixa Eletrônico',
                                    desc:       'Concentra-se em uma troca entre empresa e cliente ou entre empresas.',
                                    what:       [
                                                    'Neste modelo, o cliente paga adiantado pelo produto antes mesmo da empresa pagar seus fornecedores.',
                                                    'Há maior liquidez que pode ser usada pela instituição para financiar investimentos em outras áreas.',
                                                    'Utiliza um ciclo de conversão de caixa negativo em que a empresa gera receita de forma mais rápida do que o tempo que tem para pagar seus fornecedores por bens adquiridos.'
                                                ],
						            examples:   [
                                                    'Clinica de Saúde , Clinica de Fisioterapia, Clinica de Nutrição, Clinica Odontológica , Corretor (a) de Imóveis '
                                                ],
                                    for:        [
                                                    'Empresas que buscam atender somente conforme a demanda ou que possuem prazos mais longos com seus fornecedores.'
                                                ],
                                    how:        [
                                                    'Quando as ofertas tiverem um alto valor agregado para os clientes.',
                                                    'Como exemplo, um processo que visa encomendas online.'
                                                ],
                                    cost:       [
                                                    'Pagamentos são feitos antecipadamente pelo cliente.',
                                                    'Fornecedores são pagos posteriormente.',
                                                    'Há um intervalo de tempo em que o valor recebido pela venda pode servir como investimento.'
                                                ],
                                    logo1Img:   'DellLogo',
                                    logo1Desc:  'A fabricante de computadores Dell foi a primeira empresa a empregar a estrátégia de encomenda nos anos 80. Isso lhe permitiu atingir um ciclo de conversão de caixa altamente negativo e logo no início já se tornou um meio importante de financiamento de seu crescimento. A Dell iniciou com capital de US$ 1000 e um grande estoque que os teria levado à falência.',
                                    logo2Img:   'AmzLogo',
                                    logo2Desc:  'A Amazon utiliza a estratégia de reposição rápida de estoque. Consegue negociar longos prazos de pagamento com fornecedores. a empresa não tem que pagar seus fornecedores até que ela mesma tenha sido paga por seus clientes pelos produtos adquiridos.',
                                    logo3Img:   'NetflixLogo',
                                    logo3Desc:  'Os clientes que assinam a Netflix pagam sua mensalidade de forma antecipada, dando a empresa maior liquidez para operar seus negócios e adquirir novos usuários.'
                                },
                                {
                                    name:       'Integrador',
                                    desc:       'No modelo de negócio Integrador, uma empresa controla a maior parte ou todas as partes da cadeia de suprimentos.',
                                    what:       [
                                                    'A empresa controla toda ou grande parte da sua cadeia de produção.',
                                                    'Desde análise de matérias prima até a fabricação e distribuição do produto.',
                                                    'Consegue manter um padrão de qualidade e evita atrasos, como pode ocorrer quando se tem fornecimento de terceiros.',
                                                    'Tal controle permite que a empresa melhore as economias de escopo e a eficiência.'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'É comum nos setores: vestuário, automobilísmo e petrolífero.',
                                                    'Marcas que prezam pela presença em toda linha de produção, assegurando a qualidade e efetividade do produto.',
                                                    'Clientes que buscam produtos que tiveram acompanhamento por todo processo.'
                                                ],
                                    how:        [
                                                    'Site em que se apresenta vídeos e fotos de todos os processos.',
                                                    'Esse padrão implica dar especial atenção à parte final da cadeia de valor.',
                                                    'A integração oferece duas vantagens específicas: criação de valor mais eficiente e melhor compreensão de toda a cadeia de valor.'
                                                ],
                                    cost:       [
                                                    'Venda dos produtos.',
                                                    'Produção.',
                                                    'Manutenção.'
                                                ],
                                    logo1Img:   'JcDecauxLogo',
                                    logo1Desc:  'Empresa que uniu a prestação de serviços com o direito de comercializar espaços publicitários, criou o conceito de Mobiliário Urbano.',
                                    logo2Img:   'ZaraLogo',
                                    logo2Desc:  'Esta empresa tem a maior parte de design, produção, distribuição, armazenamento e vendas integradas à sua cadeia.',
                                    logo3Img:   'NetflixLogo',
                                    logo3Desc:  'Originalmente esta empresa não fazia um modelo integrador, mas ultimamente ela tem produzido muito do seu próprio conteúdo.'
                                },
                                {
                                    name:       'Permuta',
                                    desc:       'Concentra-se em uma troca entre empresa e cliente ou entre empresas.',
                                    what:       [
                                                    'Modelo onde as mercadorias são doadas aos clientes sem a transação de dinheiro real. Em troca, eles fornecem algo de valor para a organização, auxiliando na formação de valor.',
                                                    'Não necessariamente envolve dinheiro em espécie.',
                                                    'Pode servir para impulsionar uma marca introduzindo potenciais novos clientes para determinados produtos.'
                                                ],
						            examples:   [
                                                    'Centro de Estética , Empresa de Serviço de Depilação, Esmalteria, MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Manicure, MEI Maquiador(a), Serviços de Massagem'
                                                ],
                                    for:        [
                                                    'Na permuta, pode-se criar parceiras com fornecedores, clientes e até mesmo influencers digitais que recebem produtos ou serviços em troca da divulgação das marcas.',
                                                    'Os parceiros não precisam ser necessariamente fornecedores ou clientes: podem ser outras empresas.'
                                                ],
                                    how:        [
                                                    'A forma de uso mais comum desse modelo é o virtual.',
                                                    'As trocas se dão por contato direto com o interessado.'
                                                ],
                                    cost:       [
                                                    'Não tem custos, pois são trocas.'
                                                ],
                                    logo1Img:   'PeGLogo',
                                    logo1Desc:  'A P&G fez uma parceria com redes de TV e canais de rádio trocando patrocínio ou financiamento com produção do conteúdo de entretenimento, para maior visibilidade de seus produtos.',
                                    logo2Img:   'PepsicoLogo',
                                    logo2Desc:  'A Pepsi foi o primeiro produto estrangeiro a ser vendido na URSS em 1972. Sob um acordo de troca, a PepsiCo ofereceu o refrigerante à União Soviética em troca de direitos de exportação de vodka Stolichnaya para a América, os quais tiveram direitos exclusivos de venda no mercado americano. Essa estratégia também aumentou a exposição da marca Pepsi-Cola e a disponibilidade do produto no país permutado.',
                                    logo3Img:   'LufthansaLogo',
                                    logo3Desc:  'Na década de 1990, a Lufthansa possuía um espaço de varejo caro em Nova York o qual gerava apenas custos e não era utilizado. Como eles não conseguiriam recuperar os custos do local, a empresa trocou o espaço por publicidade para marca.'
                                },
                                {
                                    name:       'White Label',
                                    desc:       'Estratégia de marca: outras empresas distribuem os seus produtos ou serviços adotando suas próprias marcas.',
                                    what:       [
                                                    'Os produtos não recebem o nome específico da marca que os produz.',
                                                    'Gera otimização dos processos e oportunidades de escala'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'Os consumidores podem vir de diferentes segmentos de mercado.',
                                                    'Focar em bases já existentes que se beneficiam desse modelo.'
                                                ],
                                    how:        [
                                                    'Os fabricantes arcam com os custos do produto.'
                                                ],
                                    cost:       [
                                                    'Venda dos produtos a marcas diferentes. Isso é bastante comum na indústria alimentícia ao passo que as matérias-primas são produzidas para diversos consumidores.'
                                                ],
                                    logo1Img:   'EvoluttoLogo',
                                    logo1Desc:  'Plataforma White Label para empresas de consultoria oferecerem seus serviços.',
                                    logo2Img:   'FoxconnLogo',
                                    logo2Desc:  'O grupo tecnológico Foxconn é o principal fornecedor de design, desenvolvimento, manufatura, montagem e serviços de pós-venda para líderes globais de computadores, comunicação e entretenimento. A Empresa não é apenas a maior, com unidades de negócio em toda a Europa, Américas e Ásia, mas também a multinacional que cresce mais rápido em prestação de serviços no mundo, incluindo CEM, EMS, ODM e CMMS.',
                                    logo3Img:   'ReservaInkLogo',
                                    logo3Desc:  'Empresa que oferece toda a estrutura de produção e logística para outras marcas no setor de moda.'
                                },
                                {
                                    name:       'Layer Player',
                                    desc:       'Concentra-se em uma ou apenas algumas atividades dentro de uma cadeia de valor.',
                                    what:       [
                                                    'A empresa que aplica o padrão Layer Player geralmente concentra-se em uma ou apenas algumas atividades dentro de uma cadeia de valor.',
                                                    'Esse tipo de empresa atende alguns segmentos de mercado de vários setores.',
                                                    'A empresa se especializa em uma etapa da cadeia de valor.',
                                                    'A empresa pode crescer posteriormente para outras áreas.'
                                                ],
						            examples:   [
                                                    'Clinica de Saúde , Clinica de Fisioterapia, Clinica de Nutrição, Clinica Odontológica , Designer Gráfico, Empresa de Criação de Website, Marcenaria, Produção de Games, Representação Comercial'
                                                ],
                                    for:        [
                                                    'Os clientes se beneficiam de um know-how específico oferecido.'
                                                ],
                                    how:        [
                                                    'Maximiza-se o potencial de eficiência e know-how, possibilitando influenciar e desenvolver padrões como benefício próprio.',
                                                    'Opera-se em um ambiente particularmente competitivo. A especialização pode ser boa para permitir delimitar solidamente um foco essencial de expertise e estimular e desenvolver seus pontos fortes.'
                                                ],
                                    cost:       [
                                                    'Venda dos produtos.',
                                                    'Taxas de contratação.',
                                                    'Produção.',
                                                    'Manutenção.'
                                                ],
                                    logo1Img:   'PaybackLogo',
                                    logo1Desc:  'Indicador do tempo de retorno de um investimento e um método de tomada de decisões que considera o tempo para obtenção dos valores e o montante que deverá ser retirado dos caixas.',
                                    logo2Img:   'MozillaLogo',
                                    logo2Desc:  'Navegador livre e multiplataforma desenvolvido pela Mozilla Foundation com ajuda de centenas de colaboradores.',
                                    logo3Img:   'CeweColorLogo',
                                    logo3Desc:  'Se especializou em imprimir fotos e designs dos seus consumidores em vários formatos e propostas diferentes.'
                                },
                                {
                                    name:       'Licenciamento',
                                    desc:       'Foca na comercialização dos direitos que podem ser vendidos para mais de um interessado.',
                                    what:       [   
                                                    'O Licenciamento serve como um meio de diversificar as receitas e os riscos da empresa.',
                                                    'O modelo de negócio Licenciamento lida com a criação de propriedade intelectual (PI) que é licenciada para terceiros.',
                                                    'O foco é a questão da comercialização dos direitos em vez de realizar e capitalizar sobre a PI.',
                                                    'Uma vantagem importante do Licenciamento é que os direitos geralmente podem ser vendidos para mais de um interessado.'
                                                ],
						            examples:   [
                                                    'Empresa de Criação de Website, Empresa de Webdesign, Loja de Moda Intima , Produção de Games, Revenda de Semijóias, Sex Shop'
                                                ],
                                    for:        [
                                                    'Propriedade Intelectual.',
                                                    'Empresas de conhecimento e tecnologia.',
                                                    'Escritórios especializados em marcas e patentes.'
                                                ],
                                    how:        [
                                                    'Comercializar a propriedade intelectual de marcas, produtos, tecnologias e serviços.',
                                                    'Esse padrão é mais bem aplicado em contextos que usam intensamente conhecimento e tecnologia.',
                                                    'O Licenciamento apresenta uma opção interessante para monetizar seus produtos e tecnologias que não constituam a essência de seu negócio.'
                                                ],
                                    cost:       [
                                                    'Percentuais sobre Royalties.',
                                                    'Taxa das vendas.',
                                                    'Manutenção da plataforma.',
                                                    'Registros de patentes e adjacentes.'
                                                ],
                                    logo1Img:   'BayerLogo',
                                    logo1Desc:  'Empresa química e farmacêutica alemã.',
                                    logo2Img:   'BoschLogo',
                                    logo2Desc:  'Líder mundial no fornecimento de tecnologia e serviços.',
                                    logo3Img:   'HarleyDavidsonLogo',
                                    logo3Desc:  'Tem um processo rigoroso de licenciamento onde apenas produtos que são compatíveis com seus objetivos e prósitos são aceitos.'
                                },
                                {
                                    name:       'Franquia',
                                    desc:       'Concentra-se em vender o direito de uso do modelo de negócio para franqueados.',
                                    what:       [
                                                    'Poder usar o modelo de negócio e a marca da matriz.',
                                                    'Franqueados são os responsáveis por toda a gestão.',
                                                    'Empresa que busca se expandir sem ter muitos custos atrelados.'
                                                ],
						            examples:   [
                                                    'Academia de Ginastica , Agência de Viagens e Turismo, Agência de Marketing Digital , Agência de Publicidade, Bar, Casa de Bolos e Tortas , Casa de Festas Infantis, Centro de Estética , Clinica de Saúde , Clinica de Fisioterapia, Clinica de Nutrição, Confeiteira (personalização de bolos e doces), Clinica Odontológica , Creche, Crossfit, Decoração de Ambientes , Decoração de Eventos, Empresa de Organização de Eventos , Empresa de Serviço de Depilação, Escritório de Contabilidade, Esmalteria, Hamburgueria, Lanchonete , Loja de Animais - Pet Shop, Loja de Moda Intima , Marcenaria, MEI Assessor de Redes Sociais, MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Editor de Vídeo, MEI Manicure, MEI Maquiador(a), Padaria, Pastelaria, Personal Trainer, Pilates, Pizzaria, Pousada, Produção de Games, Pub, Restaurante, Revenda de Semijóias, Serviço de Jardinagem, Serviço de Pequenas Obras para Construção Civil, Serviços de Massagem, Sex Shop'
                                                ],
                                    for:        [
                                                    'Este modelo visa investidores individuais e privados a diversificar seus negócios.',
                                                    'Empresas que já estejam consolidadas no mercado.'
                                                ],
                                    how:        [
                                                    'Neste modelo de negócio, a matriz é responsável pelos produtos, fornecedores, marca, arquitetura, treinamentos das equipes das franquias, entre outros',
                                                    'O franqueado é responsável pelo local, aluguel, custos e por manter a qualidade eo padrão do produto ou serviço.'
                                                ],
                                    cost:       [
                                                    'O modelo gera receitas diversas, como de royalties, taxas de franquia, taxas de venda e do fornecimento de matéria prima.',
                                                    'Os custos principais são: treinamentos e marketing.'
                                                ],
                                    logo1Img:   'McDonaldsLogo',
                                    logo1Desc:  'O Mcdonald\'s tem diversas franquias pelo mundo e mantém uma grande padronização',
                                    logo2Img:   'SubwayLogo',
                                    logo2Desc:  'Subway é uma franquia de sanduíches presente no Brasil e em outros países.',
                                    logo3Img:   'MaryKayLogo',
                                    logo3Desc:  'Mary Kay é uma franquia de Skincare, Maquiagens e Perfumes.'
                                },
                                {
                                    name:       'Alugue ao invés de comprar',
                                    desc:       'Não precisar propor os custos de aquisição iniciais de uma compra direta, permitindo que obtenham produtos que do contrário não conseguiriam comprar.',
                                    what:       [
                                                    'A locação evita amarrar o capital por longos períodos de tempo, deixando os clientes com mais margem de liquidez financeira.'
                                                ],
						            examples:   [
                                                    'Casa de Festas Infantis, Decoração de Ambientes , Decoração de Eventos, Empresa de Criação de Website, Empresa de Organização de Eventos , Empresa de Webdesign, Representação Comercial, Serviço de Jardinagem, Serviço de Pequenas Obras para Construção Civil'
                                                ],
                                    for:        [
                                                    'Locação, aluguel, bens de capital intensivo, expertise no negócios, expertise na operação, serviços adicionais, montagem.'
                                                ],
                                    how:        [
                                                    'Esse padrão é amplamente aplicável.',
                                                    'Se a empresa oferece produtos ou serviços a um preço fixo, também pode pensar em alugá-los. Na verdade, se optar por fazer isso, será capaz de entrar no jogo de uma tendência cada vez mais comum.',
                                                    'Essa tendência, que começou na indústria de bens de consumo, já é relevante na indústria automotiva e em breve também afetará outros setores.'
                                                ],
                                    cost:       [
                                                    'Receita longo prazo.',
                                                    'Pague por uso.',
                                                    'Taxa básica.',
                                                    'Preço fixo.'
                                                ],
                                    logo1Img:   'GELogo',
                                    logo1Desc:  'A GE aluga alguns de seus produtos para clientes., dando flexibilidade e vantagens em relação ao custo.',
                                    logo2Img:   'Car2GoLogo',
                                    logo2Desc:  'Os usuários do Car2Go podem usar qualquer um dos carros da empresa por uma taxa por minuto após o registro no serviço.',
                                    logo3Img:   'DropboxLogo',
                                    logo3Desc:  'O espaço de hospedagem de arquivos do Dropbox não é de propriedade do cliente, mas ele pode utilizá-lo para armazenar seus arquivos online. Isso elimina a necessidade de um disco rígido físico.'
                                },
                                {
                                    name:       'Luxo Supremo',
                                    desc:       'Focaliza clientes na faixa financeira mais alta.',
                                    what:       [
                                                    'Empresas que operam nesse padrão podem distinguir seus produtos ou serviços fornecendo privilégios e serviços da mais alta qualidade, compatíveis com o poder de compra do mercado de alto padrão que elas visam: singularidade e autorrealização.',
                                                    'O foco está na marca e no emprego de representantes de vendas competentes para promover produtos e serviços memoráveis para os clientes.'
                                                ],
						            examples:   [
                                                    'Corretor (a) de Imóveis , Decoração de Ambientes , Personal Stylist, Pousada, Restaurante'
                                                ],
                                    for:        [
                                                    'Classe alta.'
                                                ],
                                    how:        [
                                                    'Privilégios e serviços da mais alta qualidade, singularidade e autorrealização.'
                                                ],
                                    cost:       [
                                                    'Produtos e serviços de alto valor.',
                                                    'A empresa é capaz de operar de maneira lucrativa com uma base de clientes relativamente pequena, uma vez que suas taxas são muito altas.'
                                                ],
                                    logo1Img:   'AmericanAirlinesLogo',
                                    logo1Desc:  'Viajantes de primeira classe e hóspedes com o mais alto status de fidelidade desfrutam de tratamento e comodidades exclusivas, como transporte de limusine para o aeroporto, lounges exclusivos e refeições de luxo.',
                                    logo2Img:   'PorscheLogo',
                                    logo2Desc:  'Os produtos da Porsche são direcionados a um número muito limitado de clientes com poder de compra superior. Assim, ao posicionar o produto como tal, a Porsche pode gerar margens acima da média.',
                                    logo3Img:   'LufthansaLogo',
                                    logo3Desc:  'A Lufthansa tem uma oferta e serviço especiais para clientes do segmento de luxo. Viajantes de primeira classe e hóspedes com o mais alto status de fidelidade desfrutam de tratamento e comodidades exclusivas, como transporte de limusine para o aeroporto, lounges exclusivos e refeições de luxo.'
                                },
                                {
                                    name:       'Preço único',
                                    desc:       'Concentra-se em comprar um serviço ou produto por valor fixo e usar o quanto quiser.',
                                    what:       [
                                                    'O cliente observa vantagem por uma consumação ilimitada com controle sob seus recursos financeiros.',
                                                    'A empresa deve fornecer o quanto o consumidor quiser pelo valor pago. '
                                                ],
						            examples:   [
                                                    'Academia de Ginastica 	Agência de Viagens e Turismo	Agência de Marketing Digital 	Assistência Técnica em Informática 	Agência de Publicidade	Bar	Barbearia	Brechó	Casa de Bolos e Tortas 	Call-Center 	Casa de Festas Infantis	Centro de Estética 	Clinica de Saúde 	Clinica de Fisioterapia	Clinica de Nutrição	Confeiteira (personalização de bolos e doces)	Clinica Odontológica 	Corretor (a) de Imóveis 	Creche	Crossfit	Decoração de Ambientes 	Decoração de Eventos	Designer Gráfico	Empresa de Criação de Website	Empresa de Organização de Eventos 	Empresa de Serviço de Depilação	Empresa de Webdesign	Escritório de Contabilidade	Esmalteria	Hamburgueria	Lanchonete 	Loja de Animais - Pet Shop	Loja de Moda Intima 	Marcenaria	MEI Assessor de Redes Sociais	MEI Cabeleireiro	MEI Costureiro	MEI Designer de Sobrancelha	MEI Editor de Vídeo	MEI Manicure	MEI Maquiador(a)	Padaria	Papelaria	Pastelaria	Personal Stylist	Personal Trainer	Pilates	Pizzaria	Pousada	Produção de Games	Pub	Representação Comercial	Restaurante	Revenda de Semijóias	Serviço de Jardinagem	Serviço de Pequenas Obras para Construção Civil'
                                                ],
                                    for:        [
                                                    'Consumidores que desejam pagar único valor para utilizar o quanto quiserem de um serviço ou um produto.'
                                                ],
                                    how:        [
                                                    'Oferecer variedade, em estabelecimentos de comida, é importante lembrar que o desejo alimentar de cada pessoa diminui a cada porção que ela come, então dificimente terá um consumo crescente.',
                                                    'Deixe o cliente saciado e busque manter uma relação cordial para manter a fidelidade. '
                                                ],
                                    cost:       [
                                                    'Existem diversas maneiras de monetização a depender da formulação do preço.'
                                                ],
                                    logo1Img:   'SteamLogo',
                                    logo1Desc:  'Plataforma que comercializa jogos digitais que podem ser comprados por valores únicos e utilizados à vontade.',
                                    logo2Img:   'BuffetLogo',
                                    logo2Desc:  'Nos buffets o cliente pode comer a vontade, normalmente não atinge a cota máxima.',
                                    logo3Img:   'BetoCarreroWorldLogo',
                                    logo3Desc:  'Parque temático em que o cliente paga um valor único pela entrada e tem acesso ilimitado a maior parte dos serviços oferecidos no parque.'
                                },
                                {
                                    name:       'Contratação baseada em performance',
                                    desc:       'O pagamento depende dos resultados.',
                                    what:       [
                                                    'Os serviços são medidos em função do que é produzido.',
                                                    'Os clientes podem controlar mais facilmente seus custos.',
                                                    'A intensidade do uso do produto é irrelevante para a determinação de preços.'
                                                ],
						            examples:   [
                                                    'Consultoria, Mentoria, Agência de Marketing Digital , Assistência Técnica em Informática , Agência de Publicidade, Centro de Estética , Clinica de Saúde , Clinica de Fisioterapia, Clinica de Nutrição, Clinica Odontológica , Corretor (a) de Imóveis , Designer Gráfico, Empresa de Criação de Website, Empresa de Serviço de Depilação, Empresa de Webdesign, Escritório de Contabilidade, Esmalteria, MEI Assessor de Redes Sociais, MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Editor de Vídeo, MEI Manicure, MEI Maquiador(a), Produção de Games, Representação Comercial, Serviço de Jardinagem, Serviço de Pequenas Obras para Construção Civil, Serviços de Massagem'
                                                ],
                                    for:        [
                                                    'Consumidores com necessidades muito específicas e de estrutura contratual transparente.'
                                                ],
                                    how:        [
                                                    'Ao prestar serviços ou oferecer produtos, a empresa reduz ou aumenta os respectivos preços baseando-se na performance dos processos ou dos resultados. Além disso, essas variações de performance podem tanto impactar um coletivo de produtos ou serviços bem como os afetar individualmente.'
                                                ],
                                    cost:       [
                                                    'O cálculo do preço de um produto considerando os serviços que ele gera em vez de seu valor nominal.',
                                                    'Esses serviços são medidos precisamente, em função do que é produzido e pelos quais os clientes pagam para a empresa uma quantia específica.'
                                                ],
                                    logo1Img:   'GELogo',
                                    logo1Desc:  'A GE introduziu um contrato de serviço de manutenção e monitoramento baseado em condições. Isso permite que a GE mude de manutenção baseada em calendário e eventos para monitoramento e manutenção baseados em condições.',
                                    logo2Img:   'BasfLogo',
                                    logo2Desc:  'Para modelos “custo por unidade”. O custo da pintura dos veículos é calculado por módulo pintado, em vez de pela quantidade de tinta utilizada. A BASF assumiu parte da responsabilidade de dar acabamento aos automóveis prestando suporte aos clientes in situ (no local) e ajudando-os a melhorar sua eficiência. Essa eficiência foi repassada aos consumidores de forma coletiva reduzindo os preços dos módulos.',
                                    logo3Img:   'PPPLogo',
                                    logo3Desc:  'Parcerias público-privadas são muitas vezes fundamentadas em contratos baseados em performance, ou seja, o contratado ganhará mais ou menos de acordo com sua capacidade de gerar resultados.'
                                },
                                {
                                    name:       'Faça Mais do Mesmo',
                                    desc:       'Concentra-se em oferecer o know-how como um serviço para terceiros.',
                                    what:       [
                                                    'Know-how oferecido como serviço para terceiros.',
                                                    'Conhecimento único e difícil de obter.',
                                                    'Conhecimento aplicável em outras indústrias e setores.'
                                                ],
						            examples:   [
                                                    'Bar, Casa de Bolos e Tortas , Call-Center , Centro de Estética , Clinica de Saúde , Clinica de Fisioterapia, Clinica de Nutrição, Confeiteira (personalização de bolos e doces), Clinica Odontológica , Empresa de Criação de Website, Empresa de Serviço de Depilação, Esmalteria, Hamburgueria, Lanchonete , MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Manicure, MEI Maquiador(a), Padaria, Pastelaria, Pizzaria, Pub, Representação Comercial, Restaurante, Serviços de Massagem'
                                                ],
                                    for:        [
                                                    'Experts enxergam potencial no know-how da empresa.',
                                                    'Conhecimento pode ser aplicado em outras áreas.'
                                                ],
                                    how:        [
                                                    'As expertises da equipe podem ser monetizadas.',
                                                    'Pode melhorar os processos internos e revitalizar o core business.',
                                                    'Empresa vista como inovadora.'
                                                ],
                                    cost:       [
                                                    'Custo reduzidos por usar conhecimento já existente na empresa.',
                                                    'Nova fonte de receita para complementar o core business.'
                                                ],
                                    logo1Img:   'BasfLogo',
                                    logo1Desc:  'A BASF é uma empresa alemã que fornece produtos químicos, plásticos e outros materiais sintéticos para uso industrial.',
                                    logo2Img:   'AmzWebServicesLogo',
                                    logo2Desc:  'Clientes de mais de 190 países usam o Amazon Web Services para computação em nuvem e serviços relacionados.',
                                    logo3Img:   'FoxconnLogo',
                                    logo3Desc:  'Produz peças para empresas de terceiros.'
                                },
                                {
                                    name:       'Virtualização',
                                    desc:       'A imititação de um processo tradicionalmente físico para o digital.',
                                    what:       [
                                                    'Oferece uma experiência virtual de algo que costumava ser físico com a possibilidade de interação em qualquer lugar e por qualquer dispositivo.'
                                                ],
						            examples:   [
                                                    'Escritório de Contabilidade, Loja de Moda Intima , Produção de Games, Revenda de Semijóias, Sex Shop'
                                                ],
                                    for:        [
                                                    'Permite que as pessoas possam interagir com processos de qualquer lugar e com uma comunicação focada na agilidade.'
                                                ],
                                    how:        [
                                                    'Criação de um ambiente virtual em que os processos físicos podem acontecer virtualmente.'
                                                ],
                                    cost:       [
                                                    'Diversos modelos podem ser incorporados como, por exemplo, assinatura, venda direta ou taxa de uso.'
                                                ],
                                    logo1Img:   'CeweColorLogo',
                                    logo1Desc:  'Os produtos individualizados da Cewe não têm a marca Cewe, permitindo que as empresas criem produtos de marca individual, por exemplo, para fins publicitários.',
                                    logo2Img:   'WikipediaLogo',
                                    logo2Desc:  'Transformação da ideia de enciclopédia em um modelo digital.',
                                    logo3Img:   'BradescoLogo',
                                    logo3Desc:  'Um dos diversos bancos que substituiram os modelos de dados em papéis pelos modelos digitais de informação.'
                                },
                                {
                                    name:       'Inovação Reversa',
                                    desc:       'Produtos simples e baratos.',
                                    what:       [
                                                    'Produtos adaptados para atender os mercados emergentes, por vezes oferecidos sobre a premissa: baratos e simples.'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'Mercados emergentes de classes com menor poder econômico.'
                                                ],
                                    how:        [
                                                    'Produção e adaptação de produtos.'
                                                ],
                                    cost:       [
                                                    'Venda de produtos ou serviços.'
                                                ],
                                    logo1Img:   'PeGLogo',
                                    logo1Desc:  'Desenvolvimento de fraldas de baixo custo para o mercado brasileiro em 2010.',
                                    logo2Img:   'UnileverLogo',
                                    logo2Desc:  'O produto Knorr Stock Pot é um exemplo que foi lançado na china em 2007.',
                                    logo3Img:   'RenaultLogo',
                                    logo3Desc:  'Fez modelos de veículos para mercados com classes de menor poder aquisitivo.'
                                },
                                {
                                    name:       'Crowdsourcing',
                                    desc:       'Concentra-se em terceirizar tarefas para atores externos e revertê-las em benefício para empresa.',
                                    what:       [
                                                    'Utiliza plataformas na internet que conectam a demanda por serviços a uma grande fonte de fornecedores.',
                                                    'É um processo colaborativo no qual pessoas agregam seus conhecimentos em torno da solução de problemas.',
                                                    'A empresa recebe serviços e soluções com baixo custo.'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'Empresas que buscam a inovação e a fidelização do seu público.',
                                                    'Muitas empresas usam essas estratégias de trabalho colaborativo para divulgar seus lançamentos a um grupo de clientes, buscando opiniões e as aderindo ao projeto.'
                                                ],
                                    how:        [
                                                    'Utilizado normalmente na concepção de novos produtos.',
                                                    'Utilize uma plataforma de crowdsourcing e anuncie o serviço de que precisa.',
                                                    'Se preferir, você pode escolher um seleto grupo de clientes fiéis à marca para participar do processo.'
                                                ],
                                    cost:       [
                                                    'Para a empresa, o custo será baixo e essencialmente centrado na manutenção da plataforma.',
                                                    'A receita é gerada a partir do percentual de repasse do valor do serviço.'
                                                ],
                                    logo1Img:   'RufflesLogo',
                                    logo1Desc:  'A Ruffles lançou em 2012 uma campanha em que novos sabores eram escolhidos e criados pelo público para serem comercializados no Brasil.',
                                    logo2Img:   'HarleyDavidsonLogo',
                                    logo2Desc:  'Em 2011, a Harley Davidson lançou uma nova campanha que estimulava a criação de um comercial de forma coletiva. Os clientes escolheriam as especificações e cores para o modelo Harley Davidson 120.',
                                    logo3Img:   'GELogo',
                                    logo3Desc:  'A GE criou uma plataforma de crowdsourcing na qual publica problemas explícitos que estavam procurando resolver, como na área de ciência de dados. A plataforma permite que equipes independentes enviem soluções e também colaborem e trabalhem em conjunto com as partes interessadas internas ou externas no desenvolvimento de novas soluções.'
                                },
                                {
                                    name:       'Orquestrador',
                                    desc:       'Concentram-se em suas principais competências.',
                                    what:       [
                                                    'Terceirização das atividades que não são suas principais competências.',
                                                    'Modelo de negócio utilizado em indústrias que buscam focar nas atividades nas quais se destacam e, por isso, terceirizam as demais.',
                                                    'O Orquestrador será aquele que administrará as atividades próprias e as dos terceiros.'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'Quaisquer setores capazes de gerenciar um ecossistema empresarial.'
                                                ],
                                    how:        [
                                                    'Concentra todos os seus esforços nas atividades principais e terceiriza as restantes, reduzindo custos e aumentandando a flexibilidade.',
                                                    'Uma grande quantiadde de tempo é gasta na combinação e na execução de atividades individuais que agregam valor.'
                                                ],
                                    cost:       [
                                                    'É possível reduzir os custos totais a partir da terceirização de determinadas atividades.'
                                                ],
                                    logo1Img:   'AirtelLogo',
                                    logo1Desc:  'Esta empresa deu ênfase em suas competências essenciais de marketing vendas e finanças, terceirizando outras partes como, por exemplo, o suporte de TI.',
                                    logo2Img:   'UberLogo',
                                    logo2Desc:  'Realiza a conexão entre motoristas e passageiros sem empregar qualquer motorista.',
                                    logo3Img:   'PeGLogo',
                                    logo3Desc:  'Ecossistema de inovação externa que podem atingir parâmetros globais.'
                                },
                                {
                                    name:       'Sem Luxo',
                                    desc:       'Concentra-se em preços baixos: "menos é mais".',
                                    what:       [
                                                    'O valor está no preço baixo.',
                                                    'Redução de custos e preço baixo podem fazer parte da proposta de valor.'
                                                ],
						            examples:   [
                                                    'Academia de Ginastica , Call-Center , Loja de Animais - Pet Shop, Loja de Moda Intima , Personal Trainer, Pilates, Pousada, Revenda de Semijóias, Serviço de Jardinagem, Serviço de Pequenas Obras para Construção Civil, Sex Shop'
                                                ],
                                    for:        [
                                                    'O foco é atingir as massas.'
                                                ],
                                    how:        [
                                                    'O sucesso depende dos custos baixos.',
                                                    'Economias de escala e produção padronizada.',
                                                    'Otimização da distribuição.'
                                                ],
                                    cost:       [
                                                    'Rentável quando atinge as massas.'
                                                ],
                                    logo1Img:   'ibisBudgetLogo',
                                    logo1Desc:  'Oferecem serviços de hotelaria de qualidade a preços baixos.',
                                    logo2Img:   'McDonaldsLogo',
                                    logo2Desc:  'Oferece lanches na versão fast-food, com baixo custo agregado.',
                                    logo3Img:   'SheinLogo',
                                    logo3Desc:  'Empresa de moda que oferece peças a preços baixos.'
                                },
                                {
                                    name:       'Supermercado',
                                    desc:       'A empresa vende uma grande variedade de produtos em um mesmo local.',
                                    what:       [
                                                    'A ampla variedade disponível de produtos atende aos desejos da maioria dos clientes e gera demanda considerável.',
                                                    'Os preços geralmente são mantidos baixos para atrair clientes.',
                                                    'Supermercados são populares, porque os clientes normalmente gostam de comprar tudo o que precisam no mesmo lugar.'
                                                ],
						            examples:   [
                                                    'Bar, Centro de Estética , Empresa de Serviço de Depilação, Esmalteria, Hamburgueria, Lanchonete , Loja de Moda Intima , MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Manicure, MEI Maquiador(a), Padaria, Pastelaria, Pizzaria, Pub, Restaurante, Revenda de Semijóias, Serviços de Massagem, Sex Shop'
                                                ],
                                    for:        [
                                                    'Clientes querem tudo de um lugar só.'
                                                ],
                                    how:        [
                                                    'Esse padrão pode ser aplicado a todas as situações em que economias de escala e escopo entram em jogo.',
                                                    'O conceito Supermercado envolve oferecer uma ampla variedade de produtos, tornando-o oposto a negócios que focam apenas em produtos especiais.'
                                                ],
                                    cost:       [
                                                    'Devido a variedade e grande quantidade de produtos, os custos são reduzidos permitindo a competição por preços.',
                                                    'Economia de escala e de escopo.'
                                                ],
                                    logo1Img:   'BestBuyLogo',
                                    logo1Desc:  'A Best Buy oferece uma ampla variedade de produtos eletrônicos, incluindo produtos para vários requisitos de preço e qualidade. Isso permite que eles segmentem clientes com poder de compra variável e forneçam comparações de preços e qualidades de produtos.',
                                    logo2Img:   'HeMLogo',
                                    logo2Desc:  'A H&M não só vende roupa como também se expandiu nas áreas de acessórios e calçado. Sua oferta de roupas vai do formal ao lazer. Assim, a H&M tem uma oferta holística, permitindo que os clientes comprem roupas inteiras em uma loja.',
                                    logo3Img:   'IkeaLogo',
                                    logo3Desc:  'Com base na "vida nórdica", a IKea oferece tudo relacionado à casa e à vida, bem como mantimentos. Isso inclui móveis para interior e exterior, acessórios e alimentos.'
                                },
                                {
                                    name:       'Engenharia Reversa',
                                    desc:       'Aprendendo com os erros e as experiências dos pioneiros, imitações têm o potencial de serem tão boas quanto o original.',
                                    what:       [
                                                    'O objetivo principal não é “tirar vantagem” do pioneirismo dos outros, mas otimizar os produtos existentes.',
                                                    'O modelo de negócio engenharia reversa, uma tecnologia existente ou um produto do concorrente é analisado e as informações obtidas são utilizadas para desenvolver um produto semelhante ou compatível.',
                                                    'Como isso exige pouco investimento em pesquisa e desenvolvimento, os produtos podem ser oferecidos a um preço mais baixo do que seus equivalentes de mercado.'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'Produtos e serviços, otimizar existentes, automóveis, papelaria.',
                                                    'A engenharia reversa não se limita a produtos ou serviços: por exemplo, também pode ser aplicada aos modelos de negócio como um todo, quando as cadeias de valor dos concorrentes são analisadas e seus princípios são reproduzidos.'
                                                ],
                                    how:        [
                                                    'Empresas dos setores: farmacêuticos, automotivos e de softwares fazem uso frequente desse padrão.',
                                                    'A atratividade e os inúmeros benefícios da engenharia reversa são muitas vezes intuitivos.',
                                                    'Alguns deles incluem cortar custos e reduzir o tempo necessário para atividades de P&D, adquirir conhecimento e know-how para produtos que já foram testados no mercado e recriar produtos cujos fabricantes ou a documentação não existem mais.'
                                                ],
                                    cost:       [
                                                    'Os produtos nichados não podem ter custos significativamente maiores do que os de massa. Margens menores, mas grande volume de vendas de produtos individuais.'
                                                ],
                                    logo1Img:   'BrillianceLogo',
                                    logo1Desc:  'Marca de carros chinesa com semelhanças quanto aos carros fabricados pela BMW, sendo capaz de vender carros semelhantes a um custo substancialmente menor.',
                                    logo2Img:   'BayerLogo',
                                    logo2Desc:  'A engenharia reversa é normalmente usada para decodificar os parâmetros genéricos de formulação de um produto inovador.',
                                    logo3Img:   'DennerLogo',
                                    logo3Desc:  'Denner fez engenharia reversa das pastilhas Nespresso da Nestlé para vender pastilhas de café próprias compatíveis com máquinas de café Nespresso a um preço mais baixo.'
                                },
                                {
                                    name:       'Loja na Loja',
                                    desc:       'Refere-se à prática de varejistas ou prestadores de serviços que estabelecem uma loja independente no espaço de varejo de outra empresa.',
                                    what:       [
                                                    'A empresa integrada geralmente tem a liberdade de escolher sua própria variedade de produtos e projetar o espaço de vendas de acordo com especificações próprias, assim, não é necessário nenhum sacrifício para promover sua marca.',
                                                    'Esses arranjos podem produzir sinergias valiosas que resultam em uma situação mutuamente vantajosa.'
                                                ],
						            examples:   [
                                                    'Centro de Estética , Empresa de Serviço de Depilação, Esmalteria, Loja de Moda Intima , MEI Cabeleireiro, MEI Costureiro, MEI Designer de Sobrancelha, MEI Manicure, MEI Maquiador(a), Revenda de Semijóias, Serviços de Massagem, Sex Shop'
                                                ],
                                    for:        [
                                                    'A instituição hospedeira se beneficia dos clientes atraídos para os produtos ou serviços da outra empresa e do aluguel recebido; já a hóspede se beneficia do posicionamento de sua marca em uma área comercial ativa ou ambiente de trabalho, e de recursos mais baratos como espaço ou força de trabalho.'
                                                ],
                                    how:        [
                                                    'Esse tipo de modelo oferece uma simbiose empresarial, que beneficia ambos os negócios.',
                                                    'Oferece uma ampla variedade produtos e serviços no mesmo local.',
                                                    'Pode haver centralização dos meios de pagamento.'
                                                ],
                                    cost:       [
                                                    'Os custos são contratos que podem variar desde aluguéis até conceitos de franquias.'
                                                ],
                                    logo1Img:   'TchiboLogo',
                                    logo1Desc:  'Muitas das lojas da Tchibo são, na verdade, lojas menores dentro de outras lojas, como supermercados ou varejistas. Nesses relacionamentos Shop-in-Shop, ambas as partes se beneficiam de receitas adicionais ou potenciais de economia de custos.',
                                    logo2Img:   'LevissLogo',
                                    logo2Desc:  'Marca de roupas presente em shoppings.',
                                    logo3Img:   'DeutschePostLogo',
                                    logo3Desc:  'Marca alemã que inseriu papelarias em supermercados para aproveitar o espaço já existente.'
                                },
                                {
                                    name:       'Marca Ingrediente',
                                    desc:       'Neste modelo, certos produtos ou serviços só podem ser adquiridos junto de outro devido à compatibilidade.',
                                    what:       [
                                                    'Uma marca atribui um produto que só pode ser comprado como um ingrediente fixo de outro produto, isto é, o produto ingrediente não pode ser comprado individualmente.',
                                                    'A Marca Ingrediente resulta em um cenário mutuamente vantajoso em que os atributos positivos do produto ingrediente são transferidos para o produto final e aumentam sua conveniência aos olhos dos consumidores.'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'É mais vantajoso para mercados nichados em que os clientes se beneficiam de produtos ingredientes, que são incorporáveis ao produto essencial.'
                                                ],
                                    how:        [
                                                    'O produto ingrediente é anunciado como uma característica notável do produto final.',
                                                    'O cliente vê “uma marca dentro de uma marca” ao visualizar o produto final.',
                                                    'A empresa que fornece esse tipo de produto ingrediente melhora o perfil da marca e atrai clientes finais.',
                                                    'A percepção criada em relação à marca pelo padrão Marca Ingrediente reduz a possibilidade de substituir produtos por outros e dá à empresa mais poder de barganha nos acordos com o fabricante do produto final.'
                                                ],
                                    cost:       [
                                                    'Porcentagem obtida da venda dos produtos que possuem a marca ingrediente.',
                                                    'Produção.'
                                                ],
                                    logo1Img:   'goCaseLogo',
                                    logo1Desc:  'Empresa especializada em fabricar capinhas de celular, que requerem os modelos específicos de smartphones.',
                                    logo2Img:   'PlayStationLogo',
                                    logo2Desc:  'Esta empresa fabrica os consoles nos quais os jogos funcionam, mas não é a companhia que desenvolve os jogos digitais propriamente.',
                                    logo3Img:   'IntelLogo',
                                    logo3Desc:  'É uma marca que fabrica processadores compatíveis com diversos aparelhos eletrônicos.'
                                },
                                {
                                    name:       'Aikido',
                                    desc:       'Concentra-se em se diferenciar de outras marcas a partir da novidade.',
                                    what:       [
                                                    'Evita confronto direto com concorrentes.',
                                                    'Cria novas necessidades de consumo.',
                                                    'Se diferem de outras empresas do seu setor.'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'Adequado a clientes que buscam personalidade e originalidade em um produto.',
                                                    'Se difere no modo de relacionamento com o cliente, trazendo um atendimento personalizado.',
                                                    'Visa um nicho específico e produtos específicos.'
                                                ],
                                    how:        [
                                                    'Utilizar diferentes tendências do consumo e inovar.',
                                                    'Prezar por pautas atuais como sustentabilidade.',
                                                    'Ir além do que a concorrência está acostumada a fazer.'
                                                ],
                                    cost:       [
                                                    'O custo para o cliente se torna mais alto devido ao valor agregado ao produto ou serviço.',
                                                    'Custos mais elevados, para acrescentar uma maior qualidade ao produto final.'
                                                ],
                                    logo1Img:   'CirqueDuSoleilLogo',
                                    logo1Desc:  'Diferencia-se pelo uso de outros recursos para gerar entretenimento. O estilo único do Cirque du Soleil o faz economizar em custos e, ao mesmo tempo, lidar com públicos novos e completamente diferentes, incluindo adultos e clientes corporativos.',
                                    logo2Img:   'SmartFitLogo',
                                    logo2Desc:  'Busca a diferenciação oferecendo academias equipadas com instrumentos de qualidade e tecnologia, além de cobrar um valor mais baixo que as concorrentes.',
                                    logo3Img:   'TheBodyShopLogo',
                                    logo3Desc:  'Uma das principais diferenças que caracterizam a The Body Shop é a ausência de grandes campanhas publicitárias. além disso, a marca acredita na venda de embalagens ecológicas reutilizáveis e busca inserir também ingredientes naturais em seus produtos, tendo senso de sustentabilidade e sendo contra testes em animais.'
                                },
                                {
                                    name:       'Objeto Self-Service',
                                    desc:       'Objeto capaz de automatizar o consumo e estocagem de produtos.',
                                    what:       [
                                                    'Oferece uma fusão de consumíveis e serviços. Consumidores podem focar nas suas atividades mais importantes sem ter que se preocupar com tarefas que consomem mais tempo, pois agora estarão sendo automatizadas.'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'Consumidores que utilizam produtos que devem ser reestocados constantemente.'
                                                ],
                                    how:        [
                                                    'Serviço compatível com infraestruturas existentes e fáceis de implementar.'
                                                ],
                                    cost:       [
                                                    'Receita recorrente.'
                                                ],
                                    logo1Img:   'MieleLogo',
                                    logo1Desc:  'Máquina de lavar que encomenda detergentes automaticamente quando está acabando.',
                                    logo2Img:   'FelfelLogo',
                                    logo2Desc:  'Automatiza ordens de produtos e pagamentos por meio de um refrigerador com comidas saudáveis.',
                                    logo3Img:   'WurthLogo',
                                    logo3Desc:  'Com uma câmera instalada em caixas de suprimentos, é possível automatizar encomendas de acordo com o estoque que está ligado a um sistema ERP.'
                                },
                                {
                                    name:       'Objeto como Ponto de Vendas',
                                    desc:       'O ponto de vendas vai até o consumidor.',
                                    what:       [
                                                    'Gera facilidade para o consumidor cria valor através da tecnologia oferecida.'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'Público que confia na tecnologia para facilitar o processo de compra.'
                                                ],
                                    how:        [
                                                    'Desenvolvimento de tecnologias que sejam fáceis de se utilizar análise de dados do consumidor para constante melhoria.'
                                                ],
                                    cost:       [
                                                    'A receita é proveniente da venda do objeto e dos recursos que são oferecidos por ele.'
                                                ],
                                    logo1Img:   'GoogleLogo',
                                    logo1Desc:  'O Google Glasses permite que os usuários comprem o que veem através dos óculos.',
                                    logo2Img:   'BirdLogo',
                                    logo2Desc:  'Scooter elétricas em cada esquina que podem ser utilizadas através de um aplicativo que coleta dados sobre distância percorrida e cobra com base nos dados.',
                                    logo3Img:   'AmzLogo',
                                    logo3Desc:  'Alexa é a assistente virtual da Amazon capaz de responder perguntas e executar ações como: colocar produtos em carrinho de vendas, ligar ou desligar luzes, abrir portas, tocar músicas etc.'
                                },
                                {
                                    name:       'Prosumer',
                                    desc:       'As empresas permitem que os consumidores sejam produtores também.',
                                    what:       [
                                                    'Cria uma comunidade na qual os consumidores produzem para a própria plataforma e podem consumir conteúdo de outros.'
                                                ],
						            examples:   [
                                                    'Empresa de Criação de Webdesign, Mentoria'
                                                ],
                                    for:        [
                                                    'Consumidores e emrpesas que geram conteúdos dentro de plataformas.'
                                                ],
                                    how:        [
                                                    'Poucos custos de produção manutenção de plataforma parcerias com outras empresas para monetização.'
                                                ],
                                    cost:       [
                                                    'Através da integração de outros modelos como taxa de uso, anúncios, venda de informações.'
                                                ],
                                    logo1Img:   'EasySmartGridLogo',
                                    logo1Desc:  'Tecnologias transformam energia em baterias virtuais.',
                                    logo2Img:   'YouTubeLogo',
                                    logo2Desc:  'Os usuarios geram o conteudo para plataforma.',
                                    logo3Img:   'InstagramLogo',
                                    logo3Desc:  'Facilita a criação e gerenciamnto de networks de blockchain.'
                                },
                                {
                                    name:       'Leilão',
                                    desc:       'Concentra-se em fazer vendas conforme os valores oferecidos pelos clientes.',
                                    what:       [
                                                    'Se baseia em preços participativos, ou seja, o preço de um produto não é determinado pelo vendedor e sim pelos compradores que influenciam no valor final dos bens ou serviços.',
                                                    'Para se ter um preço inicial, um potencial comprador oferecendo certa quantia com base em sua disposição de pagar.',
                                                    'Quando o leilão termina, o cliente que fez o lance mais alto tem o compromisso de adquirir o produto ou serviço.'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'Clientes que querem pagar apenas até um determinado valor em um produto.',
                                                    'Quem busca produtos raros ou limitados.'
                                                ],
                                    how:        [
                                                    'A empresa pode oferecer seus próprios produtos ou criar um mercado para vendedores e compradores variados tipos de produto ou direcionado para um nicho de itens.',
                                                    'Podem ser utilizadas plataformas de corretagem privadas e leilões ao vivo ou casas de leilão.'
                                                ],
                                    cost:       [
                                                    'A empresa realiza a venda pelo preço mais alto aceitável e oferecido pelo cliente.',
                                                    'O cliente se beneficia por poder escolher o valor que deseja pagar a um produto.'
                                                ],
                                    logo1Img:   'EbayLogo',
                                    logo1Desc:  'O eBay foi o pioneiro em relação a leilão online. Os vendedores poderem listar seus produtos para uma oferta inicial muito baixa, chamou a atenção de potenciais compradores.',
                                    logo2Img:   'MyHammerLogo',
                                    logo2Desc:  'A MyHammer utiliza o sistema de leilão reverso, no qual a empresa oferece lances para seu produto. Os clientes da MyHammer indicam o tipo de serviço de que necessitam: desde pequenos consertos até projetos inteiros de construção.',
                                    logo3Img:   'LufthansaLogo',
                                    logo3Desc:  'A Lufthansa oferece troca de classes em seus aviões. Para ter mais clientes em uma classe mais alta e monetizar em sua classe executiva, a empresa permite que os clientes obteham um upgrade alguns dias antes da partida do voo através do pagamento de certa quantia.'
                                },
                                {
                                    name:       'Sensor como um Serviço',
                                    desc:       'O uso de sensores permite serviços adicionais a uma oferta física.',
                                    what:       [
                                                    'Sensores que podem funcionar como novos e independentes serviços.',
                                                    'A proposta de valor deste modelo está na coleta de dados dos sensores com a finalidade de gerar informações úteis para o cliente.'
                                                ],
						            examples:   [
                                                ],
                                    for:        [
                                                    'Setores industriais com necessidades específicas serviços ou produtos com necessidade de compras recorrentes serviços que necessitam de de análide de dados constantes e em tempo real.'
                                                ],
                                    how:        [
                                                    'Análise de dados geração de informações.'
                                                ],
                                    cost:       [
                                                    'Venda das informações geradas pela análise de dados dos sensores.'
                                                ],
                                    logo1Img:   'StreetLineLogo',
                                    logo1Desc:  'A empresa instala sensores que mostram vagas de estacionamento livres, podendo vender a informação tanto para o consumidor final quanto para empresas que precisam da informação.',
                                    logo2Img:   'PeGLogo',
                                    logo2Desc:  'Sua cadeia de suprimentos é munida de sensores que rastreaim todas as possibilidades de otimização de seus suprimentos.',
                                    logo3Img:   'GoogleLogo',
                                    logo3Desc:  'Um termostato que usa sensores e inputs do celular para criar uma rotina de acordo com as necessidades do usuário.'
                                }
                            ];