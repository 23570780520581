import React from 'react'
import styles from './CheckClient.module.sass'
import axios from 'axios'
import UserStore from '../../../store/userStore'
import { useState } from 'react'
import { StringMask } from '../../library/StringMasks'
import SalesHistory from './salesHistory/SalesHistory'

const CheckClient = ({close, setIsLoadingScreen, selectedClient: sClient, editSale, setSelectedSale}) => {

    console.log(sClient);   
    const [client, setClient] = useState({name: sClient.clientName, 
                    address: sClient.address, 
                    businessPosition: sClient.businessPosition, 
                    email: sClient.email, 
                    identity: sClient.identity, 
                    CEP: sClient.CEP, 
                    phone01: sClient.phone01, 
                    phone02: sClient.phone02, 
                    origem: sClient.origem, uf: '', localidade: '',
                    classification: sClient.classification, clientId: sClient.id, pName: sClient.pName})


                    const getAddress = async(cep) => {
                        await axios.get(`http://viacep.com.br/ws/${cep.replace('-', '')}/json/`)
                        
                        
                        
                        .then(resp=>{setClient({...client, localidade: resp.data.localidade, uf: resp.data.uf})}).catch(err=>console.log(err))



                    }

                    const checkClient = async() =>{
                        setIsLoadingScreen(true)
                        getAddress(client.CEP) 
                        await axios.put('https://qwih4aw3zpr2bruxoyyqmykfo40ymveh.lambda-url.us-east-2.on.aws/', {client: client})
                        .then(resp=>{setIsLoadingScreen(false); close()}).catch(err=>{setIsLoadingScreen(false); console.log(err)})


                    }
                    const formatCEP = (cep) => {

                        
                        var formattedCEP = cep.replace(/\D/g, '');
                        formattedCEP = formattedCEP.replace(/(\d{5})(\d{3})/, "$1-$2");

                        console.log(cep); console.log(formattedCEP)
                        return formattedCEP;
                    }
  return (
    <div className={styles.checkClient}>
        <div className={styles.content}>
            <div className={styles.topInfo}>
                <h2>{sClient.clientName}</h2>
                <span onClick={close}>x</span>   
            </div>    
            <div className={styles.middleInfo}>
                <div className={styles.leftContent}>
                    <label>Nome</label><input type="" value={client.name}  />
                    <label>Endereço</label><input type="" value={client.address}  />
                    <label>Cargo</label><input type="" value={client.businessPosition}  />
                    <label>Email</label><input type="" value={client.email}   />
                </div>







    
                <div className={styles.rightContent}>
                    <label>CNPJ / CPF</label><input type="" value={client.identity} maxLength={18} />
                    <label>CEP</label><input type="" value={client.CEP} maxLength={9 } />
                    <div><label>Telefone<input type="" value={client.phone01} maxLength={15} /></label>
                    <label>Celular<input type="" value={client.phone02} maxLength={15}/></label></div>
                    <div><div className={styles.selects}><label>Origem</label><input type="" value={client.origem}  /></div><div className={styles.selects}>
                    <label>Produto</label><input type="" value={client.pName}  /></div></div>


                </div>
            </div>   
            
            <div className={styles.bottomInfo}><SalesHistory clientId={client.clientId}
clientName={client.clientName}
close={close}
setIsLoadingScreen={setIsLoadingScreen}
setSelectedSale={setSelectedSale}
editSale={editSale}/></div>

        </div>
    </div>










  )
}

export default CheckClient  


