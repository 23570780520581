import React, { useState } from 'react'
import styles from './RankingJourneyCard.module.css'
import { ReadMoreContentJourneyCard } from '../readMoreContentJourneyCard/ReadMoreContentJourneyCard'
const RankingJourneyCard = ({title, valuePhrase01, valuePhrase02, valuePhrase03}) => {

  const [moreContent, setMoreContent] = useState(false)
  const [moreContentMessage, setMoreContentMessage] = useState('')
  const [moreContentTitle, setMoreContentTitle] = useState('')
  
  const setScreen = (message, moreContentTitle) =>{

    setMoreContentMessage(message)
    setMoreContent(true)
    setMoreContentTitle(moreContentTitle)
  }




  const contentLength = 80
  const ListElement = ({position, valuePhrase}) =>{
    return(  
    
    <div className={styles.elementInRanking} style={{marginRight: '30px'}}>
                <div className={styles.positionInRank}><p>{position}</p></div>
                <div><p>{valuePhrase && valuePhrase.substring(0, contentLength)} {valuePhrase && valuePhrase.length>contentLength ? <span onClick={()=>setScreen(valuePhrase, title)} style={{cursor: 'pointer', fontWeight: 'bold', fontSize: '12px'}}>Leia Mais</span> : ''}</p></div>           
    </div>    
    
      
)       
}



  return (
    <div className={styles.rankingJourneyCard}>

      {moreContent && <ReadMoreContentJourneyCard title={moreContentTitle} message={moreContentMessage} onClick={()=>setMoreContent(false)}/>}
        <h4>{title}</h4>

        <div className={styles.listRanking}>
         <ListElement position="1" valuePhrase={valuePhrase01}/>
         <ListElement position="2" valuePhrase={valuePhrase02}/>         
         <ListElement position="3" valuePhrase={valuePhrase03}/>     


        
        
          
          
          </div>
    
    </div>
  )
}

export default RankingJourneyCard