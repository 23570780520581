import React from 'react'
import styles from './MaterialCard.module.css'
import { MaterialsPDFBg } from '../../../imgsLoader/LoadBgImgs'
import UserStore from '../../../../store/userStore'
const MaterialCard = ({type, title, material}) => {
    const token = UserStore.getToken()
    const company = UserStore.getUserId()
    const materialss =  'AAAA'
    const downloadFile = () =>{
    const material = window.location
    console.log(material)


    }
  return (
    <div className={styles.materialCard}>    
        <div className={styles.materialType}>{type ? type : 'PDF'}</div>
        <div className={styles.topContent}><img src={MaterialsPDFBg} alt=""/></div>
        <div className={styles.bottomContent}>
            
            

        <div className={styles.bottomTitle}><h2>{title}</h2></div>
        
        {


        //<a href={material && `https://uei5rvjj53q35d7d5fmeq2i7ga0kmobx.lambda-url.us-east-2.on.aws/?book=${material}&token=${token}&company=${company}`} target ='_blank' style={ { textDecoration:'none' } }>
        <a href={ `https://uei5rvjj53q35d7d5fmeq2i7ga0kmobx.lambda-url.us-east-2.on.aws/?book=${material}&token=` + token + '&company=' + company } target ='_blank' style={ { textDecoration:'none' } }>
        
        <div className={styles.download}><button onClick={downloadFile}>Download</button>
        
        
        
        </div>
        </a>
        }
        
                </div>    
    </div>
    
    
    

  )
}

export default MaterialCard