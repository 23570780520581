import axios from "axios";
import AmountJourneyCard from "../journeyCard/amountJourneyCard/AmountJourneyCard";
import SimpleJourneyCard from "../journeyCard/simpleJourneyCard/SimpleJourneyCard";
import TagsJourneyCard from "../journeyCard/tagsJourneyCard/TagsJourneyCard";
import TagsWithPercentJourneyCard from "../journeyCard/tagsWithPercentJourneyCard/TagsWithPercentJourneyCard";
import styles from './CardsContainer.module.css';
import UserStore from "../../store/userStore";
import { useState } from "react";
import { useEffect } from "react";
import { ReadMoreContentJourneyCard } from "../journeyCard/readMoreContentJourneyCard/ReadMoreContentJourneyCard";
export const CardsContainer = () =>{

    const [forWho, setForWho] = useState([])
    const [marketRange, setMarketRange] = useState('')
    const [moreContent, setMoreContent] = useState(false)
    const [moreContentMessage, setMoreContentMessage] = useState('')

    const defineMoreContentScreen = (content, title) =>{

        setMoreContentMessage(content)
        setMoreContent(true)
    


    }

    useEffect(()=>{
    axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/', {params:{ company:UserStore.getUserId() ,token: UserStore.getToken() }})
        .then((resp)=>{

            if (resp.data) {console.log(resp.data)
            setForWho(resp.data.ForWhom)
                switch (resp.data.ForWhom.jfwMarketRange) {
                    case 1:
                        setMarketRange('Internacional')
                    break;
                    case 2:
                        setMarketRange('Nacional')
                    break;
                    case 3:
                        setMarketRange('Regional')
                    break;
                    case 4:
                        setMarketRange('Municipal')
                    break;
                                                      
                    default:
                        break;
                }

            }


        })



    }, [])

    
    return(
        <div className={styles.cardsContainer}>

            <div>
            <div><p className={styles.cardsTitle}>Problema | Necessidade </p></div>

            <div>
                <SimpleJourneyCard defineMoreContentScreen={defineMoreContentScreen} largeWidth={true} title="Sobre:" content={forWho.jfwProblemDesc || <a href='forwhom/questions'>Preencha aqui!</a>} />
            
            </div>
            <div style={{marginTop: '12px'}}><p className={styles.cardsTitle}>Mercado</p></div>

            <div> 
                <SimpleJourneyCard defineMoreContentScreen={defineMoreContentScreen} largeWidth={true} title="Sobre:" content={forWho.jfwMyMarket || <a href='market/questions'>Preencha aqui!</a>}/>
            
            </div>

            <div className={styles.cards}>
                <div>
                    <TagsJourneyCard title="Abrangência:" tags={marketRange || <a href='market/questions'>Preencha aqui!</a>}/>
                    <AmountJourneyCard title="Tamanho do Mercado" value={forWho.jfwMarketSize || <a href='market/questions'>Preencha aqui!</a>}/>
                    <AmountJourneyCard title="Potencial de Crescimento" value={forWho.jfwMarketPotencialGrow || <a href='market/questions'>Preencha aqui!</a>} complement="a.a."/>
                </div>
                <div>
                    <TagsWithPercentJourneyCard title="Percentual do mercado a ser atingido" tags="Ano 1, Ano 2, Ano 3" yearValue01={forWho.jfwMarketGrow1} yearValue02={forWho.jfwMarketGrow2} yearValue03={forWho.jfwMarketGrow3}/> 
                </div>
            
            
            </div>

</div>





{



    //2
}


         <div className={styles.middleContainer}>

         {moreContent && <ReadMoreContentJourneyCard message={moreContentMessage}/>}
            <div><p className={styles.cardsTitle}>Público-Alvo </p></div>
            <div className={styles.middleInsideContainer}>
                <div>



                    <SimpleJourneyCard title="Características Demográficas" content={forWho.jfwConsumerProfile || <a href='public/questions'>Preencha aqui!</a>} />
                    <SimpleJourneyCard title="Características Geográficas" content={forWho.jfwConsumerPreference || <a href='public/questions'>Preencha aqui!</a>}/>
      
                
                
                </div>
    
                
                <div>

                    <SimpleJourneyCard cardHeight="220" title="Características Psicológicas" content={forWho.jfwInfluencyFactor || <a href='public/questions'>Preencha aqui!</a>}/>
                    <SimpleJourneyCard cardHeight="220" title="Características Comportamentais" content={forWho.jfwAquisitionPeriod || <a href='public/questions'>Preencha aqui!</a>}/>                                
                
                </div>
     


            </div>

</div>



{
    //3



}       

 

       <div>
        <div><p className={styles.cardsTitle}>Concorrentes </p></div>

            <div className={styles.rightCards}>
                <SimpleJourneyCard cardHeight="160" title="Concorrentes Diretos:" content={forWho.jfwCompetitorsList || <a href='competitors/questions'>Preencha aqui!</a>}/>
                <SimpleJourneyCard cardHeight="160" title="Diferencial:" content={forWho.jfwCompetitorsComparation || <a href='competitors/questions'>Preencha aqui!</a>}/>
            
            </div>

            <div className={styles.rightCards}> 
                <SimpleJourneyCard cardHeight="160" title="Aprendizado:" content={forWho.jfwCompetitorsKnowledge || <a href='competitors/questions'>Preencha aqui!</a>}/>
                <SimpleJourneyCard cardHeight="160" title="Falhas:" content={forWho.jfwCompetitorsWeakness || <a href='competitors/questions'>Preencha aqui!</a>}/>
            
            </div>

            <div>
                <div className={styles.rightCards}>
                    <SimpleJourneyCard cardHeight="160" title="Tecnologias Necessárias:" content={forWho.jfwCompetitorsTecNeed || <a href='competitors/questions'>Preencha aqui!</a>}/>
                </div>
            
            
            </div>


        </div>




        </div>
    );

}