import React from 'react'
import styles from './Modatta.module.sass'
import logo from '../../assets/new_logo.png'
import ModattaContent from './modattaContent/ModattaContent'
const Modatta = ({modattaSearch}) => {

  const splittedSearch = modattaSearch.split('=')
  const modid = splittedSearch[1]
  
  return (
    <div className={styles.modatta}>
      
      {modid && 
      
      <div className={styles.modattaContent}>
          <div className={styles.header}><div className={styles.headerImg}><img src={logo} alt=""/></div><h1>MEconecte</h1></div>
          <ModattaContent modid={modid}/>
      </div>
      }




      {!modid && <div>Código indisponivel</div>}

    </div>
  )
}

export default Modatta