import React from "react";
import { CProgress, CProgressBar } from '@coreui/react'
import "../journey/JourneyKnowingYourModel.css";
import menuJourney from "../../assets/icons/iconMenuJourney.png";
import JourneysideMenu from "./JourneysideMenu";
import styles from "./JourneyAll.module.css";
import UserStore from "../../store/userStore";
import axios from "axios";
import { useEffect } from "react";
import MobileKYMStructure from "./mobileJourney/mobileKYMStructure/MobileKYMStructure";
import { botconversaWebhook } from "../../functions/botconversaWebhook";

const JourneyKYMResources = () => {

    const screenWidth = window.screen.width
    
    
    const pageLocale = 'Jornada'
    useEffect(()=>{

        botconversaWebhook({locale: pageLocale, action: 'Abriu Recursos'})
        const postStep = async(step) =>{
            await axios.put('https://iz5smq7lxhsxjtskiegdcibiru0vwhjh.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), token: UserStore.getToken(), journeyStep: step})
        }
        postStep(45) 
    }, [])    
    const type= 'Organizando o negócio'
    const title = 'Recursos'
    const content = `                    Cada MODELO DE NEGÓCIO requer RECURSOS. Eles permitem que uma empresa 
                    crie e ofereça sua PROPOSTA DE VALOR, alcance mercados, mantenha
                    RELACIONAMENTOS com os SEGMENTO/CLIENTE e obtenha receita. 
                    Diferentes RECURSOS são necessários dependendo do MODELO DE NEGÓCIO. 
                    Um fabricante de microchip requer fábricas de capital intensivo, 
                    enquanto um projetista de microchip se concentra mais nos recursos humanos.
                    Os RECURSOS representam os mecanismos pelos quais as ATIVIDADE serão financiadas 
                    e geram os valores dos CUSTOS e despesas, possuem relação direta com o QUANTO 
                    que será a  ultima etapa da nossa jornada.`
    const prevVideoPath = '/journey/partners/questions'
    const nextVideoPath = '/journey/resources/video'
    const stepPercent = 94

    const OpenSideDiv = ()=>{
        let sideDiv = document.getElementById('journey1SideDiv');
        sideDiv.style.display='block';
    }


    return(

        <>        {screenWidth>780 ?  
        <div className="journeyKYMBackground" style={{overflow:'hidden'}}>
            <img src={menuJourney} alt-='' className="journeyKYMIconMenu" onClick={OpenSideDiv}>
            </img>
            <div style={{position:'relative',
                        top: '1.7rem',
                        left: '6.8rem',
                        width:'6rem'}}>
                <text className="journeyKYMHeader">
                    {type}</text>
            </div>
            <div className="journeyKYMTextBackground" style={{position:'relative', left:'6.5rem', top:'11rem'}}>
                <text className="journeyKYMTitleBlurDiv">{title}</text>
                <div style={{marginTop:'1rem',
                            width:'33rem',
                            }}>
                    <text className="journeyKYMText" >
                        {content}
                    </text>
                </div>
            </div>  
            <div className="journeyKYMVideoDiv" style={{top:'10rem'}}>
                <a href={nextVideoPath} className="journeyKYMlink" style={{textDecoration:'none'}}>
                    <div style={{display:'flex',
                                flexDirection:'column',
                                marginLeft:'1.5rem',
                                marginTop:'0.8rem',
                                marginBottom:'0.8rem'}}>
                        <text className="journeyKYMVideoUpperTxt">Próximo conteúdo</text>
                        <text className="journeyKYMVideoLowerTxt">Vídeo</text>
                    </div>
                        <div className="journeyKYMArrowDiv">
                            <text className="journeyKYMArrow">&#8250;</text>
                        </div>
                </a>
            </div>    
            <text className="journeyKYMprogressText" style={{position: 'absolute', top:'62px', right: '188px'}}>
                Seu progresso
            </text>      
            <div style={{position:'absolute',
                        top:'65px',
                        right:'88px',
                        paddingLeft:'0.4rem',
                        paddingRight:'0.4rem',
                        borderRadius:'0.3rem',
                        backgroundColor:'#FFFFFF',
                        width:'fit-content'}}>
                <text className="journeyKYMprogressBarText">
                    {stepPercent}% completo
                </text>
            </div>
            <div style={{position:'absolute',
                        top:'93px',
                        right:'10px',
                        width:'20rem'}}>
                <CProgress height={ 6 } >
                    <CProgressBar value={ stepPercent } />
                </CProgress>
            </div>
            <a href={prevVideoPath} className={styles.backArrow} style={{textDecoration:'none'}}>
                <div className="backButton1" style={{top:'6.1rem'}}>
                    <text className="textBackButton1">&#8249;</text>
                </div>
            </a>
            <JourneysideMenu></JourneysideMenu>
        </div>
        
        
        
        : 
        <MobileKYMStructure height="860px" stepPercent={stepPercent} prevVideoPath={prevVideoPath} nextVideoPath={nextVideoPath} content={content} type={type} title={title} OpenSideDiv={OpenSideDiv}/>
        }</>
    )
}

export default JourneyKYMResources;