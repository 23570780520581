import React, { useEffect } from 'react'
import styles from './Community.module.sass'
import axios from 'axios'
import { useState } from 'react'
import SearchGroups from './searchGroups/SearchGroups'
import CreateGroups from './createGroups/CreateGroups'
import UserStore from '../../store/userStore'
import LoadingScreen from '../loadingScreen/LoadingScreen'
import e from 'cors'
import { botconversaWebhook } from '../../functions/botconversaWebhook'
import MobileSearchGroups from './mobileSearchGroups/MobileSearchGroups'
import MobileAddComment from './mobileAddComment/MobileAddComment'
import { RemoveElement } from '../removeElement/RemoveElement'



const Community = () => {

    const [isAdmin, setIsAdmin] = useState(false )
    const [mobileSearchGroups, setMobileSearchGroups] = useState(false)
    const [mobileAddComment, setMobileAddComment] = useState(false)



    const [isGroupSearch, setIsGroupSearch] = useState(false)
    const [isAdministrator, setIsAdministrator] = useState(false)
    const [isCreateGroup, setIsCreateGroup] = useState(false)
    const [reloadUserGroups, setReloadUserGroups] = useState(false)
    const [userGroups, setUserGroups] = useState()

    const [selectedGroup, setSelectedGroup] = useState()

    const [newComment, setNewComment] = useState('')
    const [selectedGroupComments, setSelectedGroupComments] = useState()
    const [selectedGroupAnswers, setSelectedGroupAnswers] = useState()
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1
    const currentDay = currentDate.getDate()


    const [isLoading, setIsLoading] = useState(false)

    const [likedComments, setLikedComments] = useState()



























    const [selectedCommentId, setSelectedCommentId] = useState(0)
    const [isAnswer, setIsAnswer] = useState()
    const [newAnswer, setNewAnswer] = useState('')
    const [groupLikesAmount, setGroupLikesAmount] = useState(null)      
    const [confirmRemoveComment, setConfirmRemoveComment] = useState(false)










    


    const enterOnDefaultGroup = async() =>{
    
    /**/
            setIsLoading(true)
            const companyId = UserStore.getUserId()
            await axios.post('https://qubjznwr6bebq4lhexer7lgrpm0lkhkw.lambda-url.us-east-2.on.aws/', {
                companyId: companyId, 
                groupId: 1
    
            }).then(resp=>{getUserGroups(); setIsLoading(false);}).catch(err=>setIsLoading(false))
    



    }
    const checkAdmin = async() =>{
        await axios.get('https://pzwrv2g43mbqvyckgxuazix3k40bumco.lambda-url.us-east-2.on.aws/', {params: {adminId: UserStore.getUserId()}})
        
        .then(resp=>{if(!!resp.data.products){setIsAdmin(true)}}).catch(err=>{console.log(err)})



    }

    useEffect(()=>{
        checkAdmin()



    }, [])

    
    useEffect(()=>{

        const screenWidth = window.screen.width
        console.log(screenWidth)
        if (screenWidth>980) {
            const boardRight = document.querySelector('.BoardRight')
            boardRight.style.padding = '15px 15px 15px 0px'            
        } 


    }, [])

    const getGroupLikesAmount = async({groupId}) =>{

        
        console.log('amount')
        console.log(selectedGroup)
        await axios.get('https://3rgjq726hcdp3crbfh7nfxsiue0arfgt.lambda-url.us-east-2.on.aws/', {params: {groupId: groupId}}).then(resp=>{
                console.log(resp)
                if(resp.data.likes){
                   setGroupLikesAmount(resp.data.likes )



                }


        })



    }

   /* const botconversaWebhook = async() =>{

        const phone = UserStore.getUserPhone()
        const formattedPhone = phone && phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '').replace(' ', '')
        const url = 'https://backend.botconversa.com.br/api/v1/webhooks-automation/catch/64231/OnraahUpYENu/'
        await axios.post(url, {name: UserStore.getUserName(), email: UserStore.getUserEmail(), description: 'test', phone: formattedPhone}, {
            headers: {
              'Content-Type': 'application/json'
            }
        }).then(resp=>console.log(resp))  
    }
*/
    const openGroupsSearch = async() =>{

        botconversaWebhook({action: 'Abriu pesquisa de grupos'})
        setIsGroupSearch(true)



    } 

    const createGroup = () =>{
        setIsCreateGroup(true)

    }

    const getUserGroups = async() =>{
        const screenWidth = window.screen.width

        const device = screenWidth>800 ? 'webCoverUrl' : 'mobileCoverUrl'
        console.log( userGroups)
        await axios.get('https://wsltxu3a5fhrqzedne33uyh6s40maomn.lambda-url.us-east-2.on.aws/', {params: {companyId: UserStore.getUserId(), device: device}})
        .then(resp=>{

        
        if (resp.data.communityGroups[0]) {
            console.log(resp.data.communityGroups); 
            setUserGroups(resp.data.communityGroups)
             
            setSelectedGroup(resp.data.communityGroups[0])
            getGroupLikesAmount({groupId: resp.data.communityGroups[0].groupId})



            getComments(resp.data.communityGroups[0])
            getAnswers(resp.data.communityGroups[0])

        }else{enterOnDefaultGroup()}

    
    })

    }
    

    const postCommentOnGroup = async() =>{
        //botconversaWebhook({action: 'Comentou na Comunidade'})       
        console.log(selectedGroup)
        console.log(newComment)
        if (newComment) {
            setIsLoading(true)



            await axios.post('https://yvb3bsqeg2wxjavvjisi7ccdga0nwuhb.lambda-url.us-east-2.on.aws/', 
            {   groupId: selectedGroup.groupId, 
                companyId: UserStore.getUserId(),
                content: newComment
        
            }
            ).then(resp=>{setNewComment(''); getComments(selectedGroup); setIsLoading(false)}).catch(err=>{console.log(err); setIsLoading(false)})
            setIsLoading(false)
        }

        else{alert('Preencha o comentário')}



    }

        

    const postAnswerOnGroup = async() =>{
        botconversaWebhook({action: 'Respondeu comentário'})      


        if (newAnswer) {
            setIsLoading(true)

            await axios.post('https://sa3izwngzaprx3jmsiheszz4ui0wssye.lambda-url.us-east-2.on.aws/', 
            {   groupId: selectedGroup.groupId, 
                companyId: UserStore.getUserId(),
                content: newAnswer,
                commentId: selectedCommentId
            }
            ).then(resp=>{setIsAnswer(false); setNewAnswer(''); getAnswers(selectedGroup); setIsLoading(false)}).catch(err=>{console.log(err); setIsLoading(false)})

        }

        else{alert('Preencha o comentário')}



    }


    const getAnswers = async(group)=>{
        await axios.get('https://dd5uvdbhe2lngbl2tgwznnlrki0wiohl.lambda-url.us-east-2.on.aws/', {params: {
            groupId: group.groupId   
        }}).then(resp=>setSelectedGroupAnswers(resp.data.answers))



    }
    const getComments = async(group)=>{
        await axios.get('https://6nrlcm25h4cqa7kjh2yuybbj2y0dgqzu.lambda-url.us-east-2.on.aws/', {params: {
            groupId: group.groupId   
        }}).then(resp=>{console.log(resp.data.comments);setSelectedGroupComments(resp.data.comments); getGroupLikes(group)})



    }

    const transformObjInArray = (obj) =>{

        let newArray = []
        obj.forEach(item=>{
              return(
                  newArray.push(item.commentId)



              )

        })

    
        return newArray


    }

    const getGroupLikes = async(group) =>{

        //setLikedComments(null)
        await axios.get('https://tcaowxrfd4dsii7qvo3pgi3txa0gyddl.lambda-url.us-east-2.on.aws/', {params: { groupId: group.groupId, companyId: UserStore.getUserId()}})
        .then(resp=>{
            console.log('aaaaaaaaaaaaaaaa'); 

            const likesArray = transformObjInArray(resp.data.likes)  
            console.log(Object.values(likesArray)); 
            setLikedComments(likesArray)
            })



    }

    const likeComment = async(commentId) =>{ 
        botconversaWebhook({action: 'Curtiu Comentário'})
        const groupId = selectedGroup ? selectedGroup.groupId : userGroups ? userGroups[0].groupId : null
        console.log('ssss');console.log(groupId)

        await axios.post('https://5ez2ejmi7k3hrahlgjzz2hadbq0euouk.lambda-url.us-east-2.on.aws/', 
        {   groupId: groupId, 
            companyId: UserStore.getUserId(),
            commentId: commentId
    
        }
        ).then(resp=>{/**/setNewComment(''); getGroupLikes(selectedGroup); getComments(selectedGroup);  setIsLoading(false)}).catch(err=>{console.log(err); setIsLoading(false)})



    }










    
    const unlikeComment = async(commentId) =>{
        botconversaWebhook({action: 'Descurtiu Comentário'})       

        const groupId = selectedGroup ? selectedGroup.groupId : userGroups ? userGroups[0].groupId : null
        console.log('ssss');console.log(commentId)
        
        await axios.delete('https://lzyv7wtm5slawtwttp25zj3m6y0plrvy.lambda-url.us-east-2.on.aws/', 
        { data: {   groupId: groupId, 
            companyId: UserStore.getUserId(),
            commentId: commentId
    
        } }
        ).then(resp=>{getGroupLikes(selectedGroup);setNewComment('');  getComments(selectedGroup); /**/ setIsLoading(false)}).catch(err=>{console.log(err); setIsLoading(false)})



    }









    const convertDate = (date) =>{
        const splittedDate = date.split('-')
        const thisYear = splittedDate[0]
        const thisMonth = splittedDate[1]        
        const thisDay = splittedDate[2].substring(0, 2)        
        let thisConvertedDate = 'Ocorreu algum erro, tente mais tarde'

        console.log(thisDay)

        if (currentYear>thisYear) {
            const distance = Number(currentYear) - Number(thisYear)
            thisConvertedDate = `${distance} ${distance===1 ? 'ano' : 'anos'} atrás`
        } else if(currentMonth>thisMonth){
            const distance = Number(currentMonth) - Number(thisMonth)
            thisConvertedDate = ` ${distance} ${distance===1 ? 'mês' : 'meses'} atrás`
        } else if(currentDay>thisDay){
            const distance = Number(currentDay) - Number(thisDay)
            thisConvertedDate = `${distance} ${distance===1 ? 'dia' : 'dias'} atrás`
        } else if(Number(currentDay)===Number(thisDay)){
            thisConvertedDate = `Hoje`



        }
        return thisConvertedDate
    }


    const openAnswerComment = (commentId) =>{
        setSelectedCommentId(commentId)
        setIsAnswer(true)


    }

    const answerComment = async() =>{
        await axios.post('https://sa3izwngzaprx3jmsiheszz4ui0wssye.lambda-url.us-east-2.on.aws/', {commentId: selectedCommentId})



    }
    






    const findCommentLikes = ({comment, like}) =>{

        console.log('EEEE'); console.log(groupLikesAmount)
        const data = groupLikesAmount.find(commentData=>commentData.commentId === comment.id)
        if (data) {
            return  data.amount
        }else if(!!like){
            
            return 1
        
        }else{return 0}


    }

    const removeBoardContentBorder = () =>{
        if(window.screen.width<=900){
            const board = document.querySelector('.BoardContent')
            board.style.padding = '0px 4px'
            board.style.height = '90vh'

        }



    }

    const openMobileGroups = () =>{
        setMobileSearchGroups(true)   
    }

    const changeSelectedGroup = (group) =>{
        setSelectedGroup(group); 
        getComments(group); 
        getAnswers(group); 
        getGroupLikes(group); 
        getGroupLikesAmount({groupId: group.groupId})



    }
    const deleteComment = async(/*{commentId}*/) =>{
        
        
        
        const commentId = selectedCommentId
        console.log(commentId); 
        
        console.log(selectedGroup.groupId)
        setIsLoading(true)
        await axios.delete('https://iajhxthi7mpmpofo3qj6kfx7vm0geaub.lambda-url.us-east-2.on.aws/', {params: {commentId: commentId, groupId: selectedGroup.groupId}})
        
        .then(resp=>{console.log(resp); getComments(selectedGroup); setIsLoading(false); setConfirmRemoveComment(false)}).catch(err=>{console.log(err); setIsLoading(false)})

    }




    const changePinComment = async({commentId, pinState}) =>{

        console.log(commentId);console.log(selectedGroup.groupId);
        const pinValue = pinState==='UNPIN' ? 1 : 0
        setIsLoading(true)
        await axios.post('https://gejlrycgky7khkseyts4yw4kyu0nqujn.lambda-url.us-east-2.on.aws/', {commentId: commentId, groupId: selectedGroup.groupId, pinValue: pinValue})
        .then(resp=>{console.log(resp); getComments(selectedGroup); setIsLoading(false)})
        .catch(err=>{setIsLoading(false)})

    }
  useEffect(()=>{

    removeBoardContentBorder()
    getUserGroups()
    


  }, [])

  return (
    <div className={styles.community}>

        {confirmRemoveComment && <RemoveElement message="Deseja remover o comentário?" moreButtons={true} onClick={()=>deleteComment()} closeWarn={()=>setConfirmRemoveComment(false)}/>}
        {mobileAddComment && <MobileAddComment closeFn={()=>{setMobileAddComment(false)}} newComment={newComment} setNewComment={setNewComment} postCommentOnGroup={postCommentOnGroup}/>}
        {mobileSearchGroups && <MobileSearchGroups closeFn={()=>setMobileSearchGroups(false)} selectedGroup={selectedGroup} userGroups={userGroups} changeSelectedGroup={changeSelectedGroup} openGroupsSearch={()=>setIsGroupSearch(true)}/>}

        {isLoading && <LoadingScreen/>}

        {isAdministrator && isCreateGroup && <CreateGroups closeFn={()=>setIsCreateGroup(false)}/>}
        {isGroupSearch && <SearchGroups reloadUserGroups={getUserGroups} closeFn={()=>setIsGroupSearch(false)} userGroups={userGroups}/>}
        <div className={styles.groupsList}>
            <h2 onClick={botconversaWebhook}>Grupos</h2>

            {isAdministrator && <button onClick={createGroup}>Criar Grupo</button>}
            <button onClick={openGroupsSearch}>Procurar Grupo</button>
            <div className={styles.userGroups}>

                {!userGroups && <p>Você ainda não participa de nenhum grupo</p>}
               {userGroups && userGroups.map(group=>(
                   <div className={styles.userGroup} id={Number(selectedGroup ? selectedGroup.groupId : userGroups[0].groupId)===Number(group.groupId) && /**/styles.isSelected} onClick={()=>{setSelectedGroup(group); getComments(group); getAnswers(group); getGroupLikes(group); getGroupLikesAmount({groupId: group.groupId})}}>
                        <span>{String.fromCodePoint(parseInt (group.groupEmoji, 10))} </span>

                        <h2>{group.groupTitle}</h2>
                    </div>



               ))}
            </div>
        </div>



        <div className={styles.groupComments}>
            <div className={styles.top} >
                <img src={selectedGroup && selectedGroup.groupCover} alt='' className={styles.groupCover}/>


                <div className={styles.mobileOpenGroups} onClick={openMobileGroups}><i className="fa fa-bars"/></div>
                <h2 className={styles.groupTitle}>{console.log(userGroups)}{

                    selectedGroup ? 
                    selectedGroup.groupTitle : 
                    userGroups ? 
                    /**/ userGroups[0].groupTitle /*'SS'*/ : 
                    /*`Participe da comunidade, selecione seu primeiro grupo ao lado ${String.fromCodePoint(0x1F604)}`*/ `Carregando...`}
                    </h2>
            </div>
            <div className={styles.bottom}>

                {userGroups &&<> 
                <div className={styles.createComment} onClick={()=>{if(window.screen.width<=900){setMobileAddComment(true)} }} >
                    <textarea type="text" value={newComment} onChange={e=>{window.screen.width>=900 && setNewComment(e.target.value)}}placeholder="Compartilhe algo..."/>
                    <button onClick={()=>{if(window.screen.width>=900){postCommentOnGroup()} else{setMobileAddComment(true)}}}>Comentar &gt;</button>
                
                </div>
                <div className={styles.allComments}>
                    {selectedGroupComments && selectedGroupComments.map(comment=>(
                    <div className={styles.userComment}>
                        <div className={styles.userInfoTop}>








                        <div className={styles.userPicture}><i className="fa fa-user"/></div>

                        <div className={styles.commentDataInfo}>
                                <h2 className={styles.commentUser}>
                                    {comment.companyName}
                                </h2>
                            <p className={styles.commentContent}>

                                {comment.content} 

                            </p>
                        </div>
                        {!!comment.isPinned && <span style={{cursor: isAdmin &&'pointer'}} onClick={()=>{isAdmin && changePinComment({commentId: comment.id, pinState: 'PIN'})}}>Fixado<i className="fa fa-toggle-on"/></span>}
                        {isAdmin && !comment.isPinned && <span style={{cursor: 'pointer'}} onClick={()=>{changePinComment({commentId: comment.id, pinState: 'UNPIN'})}}>Fixar<i className="fa fa-toggle-off"/></span>}


                        {isAdmin && <i className="fa fa-trash" style={{cursor: 'pointer'}} onClick={()=>{setSelectedCommentId(comment.id); setConfirmRemoveComment(true)}/*deleteComment({commentId: comment.id})*/}/>}
                        </div>


                        <div className={styles.commentInfo}>

                          <div className={styles.commentActions}>
                            <div className={styles.answer} onClick={()=>openAnswerComment(comment.id)}>
                                <i className="fa fa-comment"/>
                                <p>Responder</p>

                            </div>
                            {likedComments ? likedComments.find(id => id===comment.id) ?
                            
                                <div className={styles.like} onClick={()=>unlikeComment(comment.id)}>   
                                    <i className="fa fa-heart" style={{color: '#FF0000'}}/>  
                                    <p style={{color: '#FF0000'}}>{/*Add Count*/groupLikesAmount ? findCommentLikes({comment: comment, like: true}) : 1}    </p>   
                                </div>
                                
                                :
                                <div className={styles.like} onClick={()=>likeComment(comment.id)}>
                                    <i className="fa fa-heart" />  
                                    <p>{/*Add Count*/groupLikesAmount ? findCommentLikes({comment: comment}) : 0}</p>       
                                </div>

                                :                                 
                                <div className={styles.like} onClick={()=>likeComment(comment.id)}>
                                    <i className="fa fa-heart" />  
                                    <p>{/*Add Count*/groupLikesAmount ? findCommentLikes({comment: comment}) : 0}</p>       
                                </div>
                            }
                          </div>

                          <div className={styles.createdAt}>

                                <p> {convertDate(comment.createdAt)}</p>
                          </div>
                        </div>


                    {isAnswer && comment.id===selectedCommentId && <div className={styles.typeAnswer}>
                        <h2>Resposta</h2>
                        <div>
                            <textarea type="text" value={newAnswer} onChange={e=>setNewAnswer(e.target.value)} placeholder="Resposta..."/>
                            <button onClick={postAnswerOnGroup}>Responder &gt;</button>
                

                        </div>
                        
                        </div>}

                        {selectedGroupAnswers && selectedGroupAnswers[0] && <div className={styles.commentAnswers}>{selectedGroupAnswers.map(answer=>{if(answer.commentId === comment.id){return(
                        <div className={styles.commentAnswer}>



                        <div className={styles.userPicture}><i className="fa fa-user"/></div>




                            
                            <div className={styles.answerInfo}>


                            <p>{answer.companyName}</p>
                            <p>{answer.content}</p>

                            </div>

          
                        </div>
                        )}})
                        }</div>
                        
                        
                        
                        }
                    </div>
                    ))}
                </div>
                 </>}



            </div>

        </div>


    </div>
  )
}

export default Community