import React from 'react'
import styles from './ModattaInput.module.sass'

const ModattaInput = ({title, placeholder, value, onChange, type, maxLength}) => {
  return (
    <div className={styles.input}>
        <label>{title}</label>
        <input type={type || 'text'} maxLength={maxLength} placeholder={placeholder} value={value} onChange={(e)=>onChange(e.target.value)}/>
    </div>
  )
}

export default ModattaInput