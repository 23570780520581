import React from 'react'
import styles from './ProductCard.module.sass'
import { StringMask } from '../../../library/StringMasks'
const ProductCard = ({productName, targetValue, doneValue, convertToCurrency}) => {

  const result = doneValue - targetValue
  return (
    <div className={styles.productCard}>
        <div className={styles.itemName}><p>{productName}</p></div>
        <div className={styles.values}>
            <div><p>Previsto</p><span>R$ {StringMask( targetValue.toFixed( 2 ), { type:'2DigitFraction' }, ( targetValue < 0 ) ? '-' : '' )}</span></div>
            <div><p>Realizado</p><span style={{color: result>=0 ? '#19a60a' : doneValue===0 ? '#d3d3d3' : undefined }}>R$ {StringMask( doneValue.toFixed( 2 ), { type:'2DigitFraction' }, ( doneValue < 0 ) ? '-' : '' )}</span></div> 
        </div>
    </div>
  )
}

export default ProductCard