import React, { useState } from 'react'
import styles from './TextWithTagsCard.module.css'
import { ReadMoreContentJourneyCard } from '../readMoreContentJourneyCard/ReadMoreContentJourneyCard';
const TextWithTagsCard = ({title, tags, content, isSmall}) => {
    const tagsSplit = tags.split(', ');



    const [moreContent, setMoreContent] = useState(false)
    const [moreContentMessage, setMoreContentMessage] = useState('')
    const [moreContentTitle, setMoreContentTitle] = useState('')
    const contentLength = 380
    const localContent = content.toString()





    const setScreen = (message, moreContentTitle) =>{

      setMoreContentMessage(message)
      setMoreContent(true)
      setMoreContentTitle(moreContentTitle)
    }

    





  return (
    <div className={!isSmall ? styles.textWithTagsCard : styles.textWithTagsCardSmall} >

        {moreContent && <ReadMoreContentJourneyCard title={moreContentTitle} message={moreContentMessage} onClick={()=>setMoreContent(false)}/>}


        <h4>{title}</h4>

        <div className={styles.tagsCard}>
        {tags && tagsSplit.map(tag=>(
        <div><p>{tag}</p></div>
       


        ))}
        </div>{!!localContent && console.log(content.toString().length)}
          {typeof content !=='string' && content}

        {typeof content==='string' && !isSmall && content && <p>{localContent.substring(0, contentLength)} {(localContent.length>contentLength) ? <span onClick={()=>setScreen(content, title)} style={{cursor: 'pointer', fontWeight: 'bold', fontSize: '14px'}}>Leia Mais</span> : ''}</p>}
        {typeof content==='string' && isSmall && content && (
          
          (content.length)<45 ? 
        <p>{content}</p> 
        : 
        <>
            <p>{typeof content==='string' && content.substring(0, 45)}...{typeof content==='string' ?  <span onClick={()=>{setScreen(content, 'Problema')}}>Ler Mais</span> : content}</p>
        
        </>


        )
        }


    
    </div>
  )
}

export default TextWithTagsCard