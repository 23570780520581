import styled from 'styled-components';
import background from './../assets/new_background.png';
import '@fontsource/montserrat';

export const colors = {
    primary: "#251E53",
    secundary: "#F57C20",
    white: "#FFFFFF",
    light1: "#F3F4F6",
    light2: "#E5E4EB",
    light3: "#ECECEC",
    dark1: "#1F2937",
    dark2: "#4B5563",
    dark3: "#B4B4B4",
    error: "#DC2626",    
    lightbg: "#ECECEC",
    lightblue: "#231CFE",
    gold: "#F5D45F",
    silver: "#A7A7A7",
    cooper: "#FDCABA",
    lightPurple: "#571D94",
    purple: "#2C0E4A"
}

export const AppArea = styled.div`
    text-align: center;
`;

export const MicroInfoIcon = styled.div`
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 5px;
`;

export const AppContainer = styled.div `
    margin: 0;    
    height: 100vh;
    min-height: 960px;
    width: 100vw;
    min-width: 1920px;
    justify-content: left;
    align-items: center;
    background: ${(props) => (props.bg ? props.bg : colors.white)};
    background-size: cover;
    background-attachment: fixed;
`;

export const AppContent = styled.div`
    min-height: 100px;
    width: 100%;
    text-align: left;
    margin: 0;
    justify-content: center;
    align-items: center;
    float: left;
    padding: 20px;
`;

export const AppSideBarIcon = styled.div`
    width: 80px;
    height: 67px;
    border-radius: 0px;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    margin: 30px auto 30px auto;

    &:hover {
        background-image: url(${props => props.hoverImage});
    }
`;

export const AppWidget = styled.div`
    background-color: ${(props) => (props.bg ? props.bg : colors.white)};
    height: ${(props) => (props.height ? props.height : '28vh')};
    width: ${(props) => (props.width ? props.width : '22vw')};
    border-radius: 5px;
    text-align: left;
    margin: 5px;
    padding: 20px;    
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
`;

export const AppWidgetTitle = styled.p`
    font-size: ${(props) => props.size ? props.size : '15'}px;
    font-family: Montserrat;
    color: ${(props) => props.color ? props.color : '#000000'};
    font-weight: bold; 
`;

export const AppWidgetResultTitle = styled.p`
    font-size: ${(props) => props.size ? props.size : '20'}px;
    font-family: Montserrat;
    text-align: center;
    color: ${(props) => props.color ? props.color : '#000000'};
    font-weight: bold; 
`;

export const AppWidgetValue = styled.p`
    font-size: ${(props) => props.size ? props.size : '37'}px;
    font-family: Montserrat;
    text-align: left;
    color: ${(props) => props.color ? props.color : '#000000'};
    font-weight: bold; 
    margin-top: 12px;
    margin-bottom: 12px;
`;

export const AppWidgetProgressBar = styled.div`    
    margin: 12px;
`;

export const AppWidgetDetails = styled.p`
    font-size: ${(props) => props.size ? props.size : '12'}px;
    font-family: Montserrat;
    text-align: left;
    color: ${(props) => props.color ? props.color : '#000000'};  
    font-weight: ${(props) => props.fontWeight ? props.fontWeight : 'normal'};
`;

export const AppDashboardGrid = styled.table`
    cellPadding: 0;
    cellSpacing: 0;
`;

export const QuizButtonPrevious = styled.button`
    background-color: ${colors.white};
    border: 1px solid ${colors.lightblue};
    border-radius: 8px;    
    color: ${colors.lightblue};
    padding: 15px 32px;
    text-align: center;
    font-weight: bold;   
    font-family: Montserrat;
`;

export const QuizButtonNext = styled.button`
    background-color: #FFFFFF;
    border: 1px solid #251f53;    
    border-radius: 3px;    
    color: #251f53;
    padding: 15px 32px;
    text-align: center;

    &:hover {
        font-weight: bold;   
        background-color: #251f53;
        color: #FFFFFF;
        font-weight:bold;    
    }
`;