import React, { useState } from 'react'
import styles from './QuestionsInformation.module.css'

const QuestionsInformation = ({information, breakLine, haveTopics, topicsInsideTopics}) => {

  const [infoArray, setInfoArray] = useState([])
    
 // if (breakLine) {
  //  setInfoArray(information.split)
 // }
  return (
    <div style={{display: 'flex', width: 'auto'}}>
    <div className={styles.info}>

      <div style={{marginRight: '10px'}}>
        <i className={styles.infoIcon}></i>
      </div>

      {!breakLine &&
      <span className={styles.extraInfo}>
        {information}
      </span>      
      }
      {breakLine &&
        <div style={{display: 'flex', flexDirection: 'column'}}>

          {
        information.split('<br/>').map(info=>(
        <span className={styles.extraInfo}>{haveTopics ? info.split('->').map((topicInfo, index)=>{
            let font = '';
            let isTopic = false;
            if (index!==0) {
              font = '100';
            }

            if (topicsInsideTopics===true && index!==0) {
              isTopic = true;
            }
            return(<>
            {!isTopic && 
            <span style={{fontWeight: font}}>
              {topicInfo}
              </span>              
            }
            
            
            
            {isTopic && <span style={{fontWeight: font}}>
              <ul style={{marginLeft: '40px'}}>
                {topicInfo.split('<topic>').map(topic=>(<li>{topic}</li>))}



              </ul>
              
              
              </span>}
            
            
            </>            
          






              )

        }
) 
        : 
        info
        }</span>
        )
        )}
        </div>  
        
        }

    </div>
    </div>
  )
}

export default QuestionsInformation