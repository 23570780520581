import React, { useState } from 'react'
import styles from './ModattaRegister.module.sass'
import ModattaInput from '../modattaInput/ModattaInput'
import axios from 'axios'
import UserStore from '../../../store/userStore'
import { StringMask } from '../../../components/library/StringMasks'
import md5 from 'md5'
import LoadingScreen from '../../../components/loadingScreen/LoadingScreen'
const ModattaRegister = ({setHaveCreatedAccount, setUserId}) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')   
    const [password, setPassword] = useState('')  
    const [phone, setPhone] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const getUserData =async(email, password) =>{
        const userCredentials = await axios.get
        (
            'https://ff7dnqc4cqykjhtn3xkehgou7m0pmmjp.lambda-url.us-east-2.on.aws/',
            {
                params:
                {
                    email: email,
                    password: password
                }
            }
        );
       // setIsLoading(false)
        return userCredentials.data;

    }






    const defineCredentials = async(userEmail, userPassword) => {
        const user = await getUserData(userEmail, userPassword)
        setUserId( user.userId );
        console.log('Cadastro ID'); console.log(user.userId)
        setIsLoading(false)
        setHaveCreatedAccount(true)
        //setIsAccountCreated(true)
    }



    const createAccount = async() =>{

        setIsLoading(true)
        const resp = await axios.post( 'https://n575iabljnkgqszqxnumanq2wi0zrpvs.lambda-url.us-east-2.on.aws/', 
        {eMail: email ,password: md5(password), companyName: '', responsibleName: name, cpfCnpj: '', phone: phone}
         )
                   

                    if( resp.data.hasOwnProperty('result') )
                    {
                        switch( resp.data.result)
                        {
                            case 0:  
                                defineCredentials(email, md5(password))  
                                break;
                            case 2:
                                setIsLoading(false)
                                alert('E-mail não disponível.')
            
                                break;
                            default:
                                setIsLoading(false)
                                alert('Erro na criação da conta.')
            
                                break;    
                        }
                    }
                    else
                    {   
                        setIsLoading(false)
                        alert('Erro na criação da conta.')
                    }






    }

    const changePhone = (inputPhone) =>{
        setPhone(StringMask(inputPhone, { type:'Phone' } ,''))




    }

  return (
    <div className={styles.register}>
        {isLoading && <LoadingScreen/>}
        <div>
            <ModattaInput title="Nome" placeholder="Insira seu Nome" onChange={setName} value={name}/>
            <ModattaInput title="Email" type="email" placeholder="Insira seu Email" onChange={setEmail} value={email}/>        
            <ModattaInput title="Senha" type="password" placeholder="Insira sua Senha" onChange={setPassword} value={password}/>        
            <ModattaInput title="Telefone" maxLength={15} placeholder="Insira seu Telefone" onChange={changePhone} value={phone}/>   
        </div>
        <div className={styles.button} onClick={createAccount}>Continuar</div>
    </div>
  )
}

export default ModattaRegister