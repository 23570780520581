import React, { useEffect, useState } from 'react'
import BooleanJourneyCard from '../journeyCard/booleanJourneyCard/BooleandJourneyCard'
import styles from './JourneyForHowModel.module.css';
import TagsJourneyCard from '../journeyCard/tagsJourneyCard/TagsJourneyCard';
import SocialMediaJourneyCard from '../journeyCard/socialMediaJourneyCard/SocialMediaJourneyCard';
import TitleTagJourneyCard from '../journeyCard/titleTagJourneyCard/TitleTagJourneyCard';
import axios from 'axios';
import UserStore from '../../store/userStore';
import SimpleJourneyCard from '../journeyCard/simpleJourneyCard/SimpleJourneyCard';
const JourneyForHowModel = () => {
  const changeBackgroundColor = () =>{

    const screenWidth = window.screen.width
    const boardContent = document.querySelector('.BoardContent')
        
    if (screenWidth>800) {
        boardContent.style.backgroundColor = '#270D43'   
        boardContent.style.padding = '30px'
        boardContent.style.borderRadius = '22px'        
    }else{boardContent.style.backgroundColor = '#ffffff'
}
  
}
useEffect(()=>{   
    changeBackgroundColor()
}, [])
    const [forHow, setForHow] = useState([])
    const [forResources, setForResources] = useState([])
  useEffect(()=>{



    if (window.screen.width<780) {


        const boardContent = document.querySelector('.BoardContent')      
        boardContent.style.overflowX = 'hidden'  
    }




    axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/', {params:{ company:UserStore.getUserId() ,token: UserStore.getToken() }})
        .then((resp)=>{console.log('resppp');console.log(resp)

            if (resp.data) {
                setForHow(resp.data.How)
                setForResources(resp.data.Resources)
        
            }


        })
        
}, [])



  return (
    <div className={styles.journeyForHowModel}>
      <div className={styles.leftContainer}>

        <p>Atividades</p>



        <div className={styles.container}>
          <div className={styles.leftCards} style={{marginBottom: '0px'}}>

            <div><p>O que é necessário para desenvolver seu negócio?</p></div>
            <div className={styles.insideContainer}>
              <div className={styles.leftInsideContainer}>
                <TitleTagJourneyCard tag="Atividades de Produto/Serviços" content={forHow.jhActivitiesProduct || <a href='activities/questions'>Preencha aqui!</a>}/>
                <TitleTagJourneyCard tag="Atividades de Marketing" content={forHow.jhActivitiesMarketing || <a href='activities/questions'>Preencha aqui!</a>}/>
              </div>
              <div className={styles.leftInsideContainer}>
              <TitleTagJourneyCard tag="Atividades de Venda" content={forHow.jhActivitiesSell || <a href='activities/questions'>Preencha aqui!</a>}/>
                <TitleTagJourneyCard tag="Atividades da Empresa" content={forHow.jhActivitiesEnterprise || <a href='activities/questions'>Preencha aqui!</a>}/>



              </div>

            </div>


          </div>
          <div className={styles.rightCards}>            <p>Parceiros</p>
            <TitleTagJourneyCard tag="Parceiros Operacionais" content={forHow.jhOperationalPartners || <a href='partners/questions'>Preencha aqui!</a>} secondTitle="Quem são seus parceiros operacionais e no que contribuem para o negócio?"/>
            <TitleTagJourneyCard tag="Marketing e Vendas" content={forHow.jhMarketingPartners || <a href='partners/questions'>Preencha aqui!</a>} secondTitle="Quem são seus parceiros de Marketing e Vendas e no que eles contribuem para seu negócio?" />



         </div>
        </div>
      </div>



      {<div className={styles.resourcesContainer}>

        <div><p style={{fontWeight: 'bold'}}>Recursos</p></div>



        <div className={styles.resourcesCards}>
            <SimpleJourneyCard title="Intelectuais" content={forResources.jrIntelectual || <a href='resources/questions'>Preencha aqui!</a>}/>
            <SimpleJourneyCard title="Físicos" content={forResources.jrPhysical || <a href='resources/questions'>Preencha aqui!</a>}/>
            <SimpleJourneyCard title="Humanos" content={forResources.jrHuman || <a href='resources/questions'>Preencha aqui!</a>}/>
            <SimpleJourneyCard title="Tecnologicos" content={forResources.jrWaysPayment || <a href='resources/questions'>Preencha aqui!</a>}/>
        </div>
      </div>}
    </div>
  )
}

export default JourneyForHowModel