import React from 'react'
import styles from './DefaultCard.module.sass'
import { StringMask } from '../../../library/StringMasks'
const DefaultCard = ({title, color, value, isResult}) => {console.log(value )
  return (
    <div className={styles.defaultCard}>
        <p>{title}</p>
        <p>{isResult && value<0 && '-'}{value && StringMask( value.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' ) }</p>


    </div>
  )


}

export default DefaultCard