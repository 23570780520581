import React, { useState } from 'react'   
import styles from './SingleVideoContainer.module.css'

import { useEffect, useRef } from 'react'
import axios from 'axios'
import SingleVideoCard from '../singleVideoCard/SingleVideoCard'

import { useMostRecentVideos } from '../../hooks/useMostRecentVideos'
import { convertToSemanticTimeFormat } from '../../../../functions/convertToSemanticTime'



const SingleVideoContainer = ({title, defaultPicture}) => {
  const { data: videos, isLoadingScreen } = useMostRecentVideos()




  const changePosition = useRef(null)
 

  const limitSize = 4
  const haveMoreCards = (videos.length>limitSize) ? true : false
  const [haveLeftArrow, setHaveLeftArrow] = useState(false)
  const [haveRightArrow, setHaveRightArrow] = useState(true)   

  const [rightPosition, setRightPosition] = useState('55px')


  const moveSlide = (leftOrRight) => {


    if (leftOrRight==='left') {


      setHaveRightArrow(true)
      changePosition.current.scrollLeft -= changePosition.current.offsetWidth
    
      if ((changePosition.current.scrollLeft - changePosition.current.offsetWidth)<= 0  ) {

        setRightPosition('55px')        
        setHaveLeftArrow(false)
      }    
    } 
    
    
    else {
      setHaveLeftArrow(true)

      setRightPosition('15px')
      changePosition.current.scrollLeft += changePosition.current.offsetWidth - 60
      if (changePosition.current.scrollLeft=== 0  ) {

      }
            console.log(changePosition.current.clientWidth)    
    }


  }

  return (
    <div className={styles.singleContainer}>     



    {!isLoadingScreen && <>      
      {haveMoreCards && haveLeftArrow &&<div className={styles.leftArrow} onClick={()=>moveSlide('left')}>&lt;</div>}           
      {haveMoreCards && haveRightArrow && <div className={styles.arrow} onClick={()=>moveSlide('right')} style={{right: rightPosition}}>&gt;</div>}
        <div><p className={styles.title}>{title}</p></div>
        <div className={styles.singleVideoContainer} ref={changePosition}>{videos && videos.map(video=>(
        <SingleVideoCard 
         videoUrl={video.videoUrl}
         title={video.title}
         desc={video.videoDescription} 
         backgroundPicture={video.pictureUrl ? video.pictureUrl : defaultPicture} 
         time={convertToSemanticTimeFormat(video.duration) } 
         watchedNum={video.watchedNum}
         trailCode={video.trailCode}
         trailPath={video.trailPath}
         videoPosition={video.position}

         />
         ))}</div>
</>}
    
    </div>
  )
}

export default SingleVideoContainer