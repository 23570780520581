import React from 'react'
import styles from './Clients.module.sass'
import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import LoadingBar from './loadingBar/LoadingBar'
import RegisterClient from './registerClient/RegisterClient'
import UserStore from '../../store/userStore'
import ClientCard from './clientCard/ClientCard'
import LoadingScreen from '../loadingScreen/LoadingScreen'
import EditClient from './editClient/EditClient'
import Select from 'react-select'
import FiltersCard from './filtersCard/FiltersCard'
import CheckClient from './checkClient/CheckClient'
import EditSale from '../actionPlan/cards/editSale/EditSale'
import { FailAddElement } from '../failAddElement/FailAddElement'
const Clients = () => {

    const [clientsList, setClientsList] = useState({})
    const [isLoading, setIsLoading] = useState(false)         
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoadingScreen, setIsLoadingScreen] = useState(false)
    const [addClient, setAddClient] = useState(false)
    const [editClient, setEditClient] = useState(false)        
    const [selectedClient, setSelectedClient] = useState(null)
    const [filteredClientsList, setFilteredClientsList] = useState({})
        

    const [checkClient, setCheckClient] = useState(false)
    
    const [fromDateFilter, setFromDateFilter] = useState( '')
    const [toDateFilter, setToDateFilter] = useState( '')
    const [isEditSale, setIsEditSale] = useState(false)
    const [selectedSale, setSelectedSale] = useState(null)

    const activeFilters = [0, 0, 0, 0, 0]   
    const origemOptions = [ { value: 'Todos', label: 'Todas' },
        { value: 'Indicação', label: 'Indicação' },
        { value: 'Internet', label: 'Internet' }, { value: 'E-mail', label: 'E-mail' }
      ]
       const [productsOption, setproductsOption] = useState([{ value: 'Todos', label: 'Todos' } 



      ]    )










      const statusOptions = [ { value: 'Todos', label: 'Todos' },
        { value: 1, label: 'Ativo' },
        { value: 0, label: 'Inativo' }
      ]; const [origemFilter, setOrigemFilter] = useState(origemOptions[0]) ;   const [productFilter, setproductFilter] = useState(statusOptions[0])
    const [statusFilter, setStatusFilter] = useState(statusOptions[0])
    const [isFilterOpen, setIsFilterOpen] = useState(false)   

    const getProducts = async() =>{
        setIsLoading(true)
        await axios.get('https://a4u74g7lciboye4ndvbgtukn3i0vanec.lambda-url.us-east-2.on.aws/', {params: {companyId: UserStore.getUserId()}})
        .then(resp=> {console.log(resp)
            const options = resp.data.products; options.unshift({ value: 'Todos', label: 'Todos' });

            console.log(options)
            setproductsOption([...options])
           })
        .catch(err=>setIsLoading(false))
    }
    const getClients = async() =>{
        setIsLoading(true)
        await axios.get('https://xlememk42zeoxyx2ybqjfqdwxy0wilvy.lambda-url.us-east-2.on.aws/', {params: {companyId: UserStore.getUserId()}})
        .then(resp=> {
            
            
            let newArr = [] 
            resp.data.clients.forEach(client => {
                const el = newArr.find(clientLocal => clientLocal.id === client.id)
                if (el) {
                    if (client.num_rows > el.num_rows) {
                        Object.assign(el, client);
                    }
                } else {
                    newArr.push(client)
                }
            })

            console.log(newArr)
            setClientsList(newArr); setFilteredClientsList(newArr); setIsLoading(false)})
        .catch(err=>setIsLoading(false))
    }
    useEffect(()=>{

        getProducts()
        getClients()   
    }, [])

    useEffect(() => {
        console.log('Novo valor de productsOption:', productsOption);
    }, [productsOption]);

    const formatGroupLabel = ({data}) =>{
        return (    <div>
            <span>{data.label}</span>
          </div>) 



    }


    const convertDate = (datetime)=> {
 
        const date = new Date(datetime);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
    
        
        day = (day < 10) ? '0' + day : day;
        month = (month < 10) ? '0' + month : month;
        return `${day}/${month}/${year}`
    }





    const filterClients = ({option, filter}) =>{

            activeFilters[filter] = 1
            console.log(option)
            let localFilter = ''
            console.log(clientsList); console.log(new Date(clientsList[0].createdAt)); console.log(new Date(`${option}T12:00:00`));
            localFilter = clientsList
            switch (filter) {
                case 0:
                        const fromDate = new Date(option!=='' ? `${option}T00:00:00` : `2023-01-01`)                
                    if (toDateFilter==='') { 

                        const toDate = new Date()
                        setFromDateFilter(fromDate)
                        localFilter = localFilter.filter(item => {
                            const currentDate = new Date(item.createdAt);
                            return currentDate >= fromDate && currentDate <= toDate;
                          });
                        if (origemFilter !== ''  &&  origemFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.origem === option.value);};
                        if (productFilter.value !== ''  &&  productFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.sProductId=== productFilter.value);};
                        if (statusFilter.value !== ''  &&  statusFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.clientStatus === statusFilter.value);}

                    } else {
                        const toDate = toDateFilter
                        
                        setFromDateFilter(fromDate)
                        localFilter = localFilter.filter(item => {
                            const currentDate = new Date(item.createdAt);
                            return currentDate >= fromDate && currentDate <= toDate;
                          });
                        if (origemFilter !== ''  &&  origemFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.origem === option.value);};
                        if (productFilter.value !== ''  &&  productFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.sProductId=== productFilter.value);};
                        if (statusFilter.value !== ''  &&  statusFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.clientStatus === statusFilter.value);}

                    }
                    break;
                case 1:







                        const toDate = new Date(option!=='' ? `${option}T23:59:00` : new Date()); console.log(toDate)
                    setToDateFilter(option)
                    if (fromDateFilter==='') {
                        const fromDate = new Date('2023-01-01')

                        setToDateFilter(toDate)
                        localFilter = localFilter.filter(item => {
                            const currentDate = new Date(item.createdAt);
                            return currentDate >= fromDate && currentDate <= toDate;
                          });
                        if (origemFilter !== ''  &&  origemFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.origem === option.value);};
                        if (productFilter.value !== ''  &&  productFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.sProductId=== productFilter.value);};
                        if (statusFilter.value !== ''  &&  statusFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.clientStatus === statusFilter.value);}
                    } else {
                        
                        const fromDate = fromDateFilter
    




                        setToDateFilter(toDate)
                        localFilter = localFilter.filter(item => {
                            const currentDate = new Date(item.createdAt);
                            return currentDate >= fromDate && currentDate <= toDate;
                          });
                        if (origemFilter !== ''  &&  origemFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.origem === option.value);};
                        if (productFilter.value !== ''  &&  productFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.sProductId=== productFilter.value);};
                        if (statusFilter.value !== ''  &&  statusFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.clientStatus === statusFilter.value);}

                    }
                    break;
                case 2:
                    setOrigemFilter(option)
                        /**/localFilter = clientsList;
                        if (option.value !== ''  &&  option.value!=='Todos') {localFilter = localFilter.filter(client => client.origem === option.value);};
                        if (productFilter.value !== ''  &&  productFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.sProductId=== productFilter.value);};
                        if (statusFilter.value !== ''  &&  statusFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.clientStatus === statusFilter.value);}

                    break;
                case 3:
                    setproductFilter(option)  
                        /**/localFilter = clientsList;
                        if (origemFilter.value !== ''  &&  origemFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.origem === origemFilter.value);}
                        if (option.value !== ''  &&  option.value!=='Todos') {localFilter = localFilter.filter(client => client.sProductId=== option.value);}
                        if (statusFilter.value !== ''  &&  statusFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.clientStatus === statusFilter.value);}
                    break;



                





                    case 4:
                        setStatusFilter(option);  
                        /**/localFilter = clientsList;
                        if (origemFilter.value !== ''  &&  origemFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.origem === origemFilter.value);}
                        if (productFilter.value !== ''  &&  productFilter.value!=='Todos') {localFilter = localFilter.filter(client => client.sProductId=== productFilter.value);}
                        if (option.value !== ''  &&  option.value!=='Todos') {localFilter = localFilter.filter(client => client.clientStatus === option.value);}
                        break;           
                default:
                    break;




            }

            setFilteredClientsList(localFilter)
            console.log(localFilter)




    }





    const deleteClient = async({clientId})=>{

        setIsLoadingScreen(true)
        await axios.delete('https://eugbqufm77zrftifcd47x2ueau0pblca.lambda-url.us-east-2.on.aws/', {data: {clientId:clientId}})
        .then(resp=>{
            
            
            setIsLoadingScreen(false)
            getClients()})
        .catch(err=>{
            setIsLoadingScreen(false)
            
            
            console.log(err)})

    }
    

  return (
    <div className={styles.clients} style={{padding: window.screen.width<800 && '0px'}}>   
        {isLoadingScreen && <LoadingScreen/>}
        {addClient && <RegisterClient setIsLoadingScreen={setIsLoadingScreen} setErrorMessage={setErrorMessage}close={()=>{getClients(); setAddClient(false)}}/>}
        {editClient && <EditClient selectedClient={selectedClient} setIsLoadingScreen={setIsLoadingScreen} close={()=>{getClients(); setEditClient(false)}}/>}   
        {checkClient && <CheckClient setSelectedSale={setSelectedSale }   editSale={()=>setIsEditSale(true)} selectedClient={selectedClient} setIsLoadingScreen={setIsLoadingScreen} close={()=>{ setCheckClient(false); getClients();}}/>}   
        {isEditSale && <EditSale selectedSale={selectedSale} closeFn={()=>{setIsEditSale(false); setSelectedSale(null)}} reloadInfo={()=>{} }/>}
        {errorMessage && <FailAddElement message={errorMessage} onClick={()=>setErrorMessage('')}/>}
        {isFilterOpen && productsOption.length>1 && <FiltersCard filterClients={filterClients} clientsList={clientsList} statusFilter={statusFilter} productFilter={productFilter} origemFilter={origemFilter} setFilteredClientsList={setFilteredClientsList} setStatusFilter={setStatusFilter} setFromDateFilter={setFromDateFilter} setToDateFilter={setToDateFilter} setOrigemFilter={setOrigemFilter} setproductFilter={setproductFilter} origemOptions={origemOptions} productsOption={productsOption} statusOptions={statusOptions} closeFilter={()=>setIsFilterOpen(false)}/>}
        <div className={styles.content}>   
            <h2>Clientes</h2>
            <p>Listamos aqui todos os seus clientes e informações que você pode filtrar. Clique no "+" para adicioná-los ao seu sistema de registro.</p>
            <div className={styles.clientsButtons}>
                
                {window.screen.width> 700 && <>
                    <label>Data<input type="date" onChange={e=>filterClients({option: e.target.value, filter: 0})}/></label>
                    <label>Data II<input type="date" onChange={e=>filterClients({option: e.target.value, filter: 1})}/></label>
                    <label>Origem<Select defaultValue={origemFilter} onChange={e=>filterClients({option:e, filter: 2})} options={origemOptions && origemOptions}   /></label>
                   {productsOption.length>1 && <label>Produto<Select defaultValue={productFilter} onChange={e=>filterClients({option:e, filter: 3})} options={productsOption && productsOption}   /></label>
                   }  <label>Status<Select defaultValue={statusFilter} onChange={e=>filterClients({option:e, filter: 4})} options={statusOptions && statusOptions}   /></label>
                
                
                </>}                
                
                <div className={styles.buttonItem}><div className={styles.button} onClick={()=>setAddClient(true)}>Adicionar +</div></div>
                
                
                
                {window.screen.width< 700 &&<div className={styles.button} onClick={()=>setIsFilterOpen(true)}>Filtros</div> }

                
                
                </div>








































            {window.screen.width>800 ? 
            <table>   
                <tr>
                    <th>Nome</th>
                    <th>CNPJ/CPF</th>
                    <th>Telefone</th>
                    <th>Origem</th>
                    <th>Status</th>
                    <th>Data de cadastro</th>                    <th>Conferir Dados</th>                    
                    <th>Editar</th>
                    <th></th>   
                </tr> 

                {isLoading &&                 <tr>
                    <td><LoadingBar/></td>
                    <td><LoadingBar/></td>                      
                    <td><LoadingBar/></td>                      
                    <td><LoadingBar/></td>                    
                    <td><LoadingBar/></td>
                    <td><LoadingBar/></td>                    <td><LoadingBar/></td>                    
                    <td><LoadingBar/></td>
                </tr> }
                {!isLoading && filteredClientsList.length>0 && filteredClientsList.map(client=>(
                    <tr><td>{client.clientName}</td>
                    <td>{client.identity}</td>
                    <td>{client.phone}</td>
                    <td>{client.origem}</td>
                    <td>{client.clientStatus===1 ? 'ATIVO' : 'INATIVO'}</td>
                    <td>{convertDate(client.createdAt)}</td>                    
                    <td><i className="fa fa-eye " onClick={()=>{setSelectedClient(client  ); setCheckClient(true)}}/></td>
                    <td className={styles.icons}><i className="fa fa-pen " onClick={()=>{setSelectedClient(client  ); setEditClient(true)}}/> <i className="fa fa-trash " onClick={()=>deleteClient({clientId: client.id})}/></td>
                    <td></td>   
                </tr>



                ))
                     }





            </table>  : <div style={{overflowY: 'auto'}}>{filteredClientsList.length>0 && filteredClientsList.map(client=><ClientCard client={client} editClient={()=>{setSelectedClient(client  ); setEditClient(true) }} removeClient={()=>deleteClient({clientId: client.id})} changeClientStatus={``}/>)}</div> }
        </div>
    </div>
  )
}

export default Clients