import React from "react";
import "../questions/questions.css";
import PencilQuestions from "../../assets/icons/pencilQuestions.png";
import CheckQuestions from "../../assets/icons/checkDarkBlue.png";
import UserQuestionsJourney from "../../assets/icons/userQuestionsJourney.png";
import { StringMask } from "../library/StringMasks";
import UserStore from "../../store/userStore";
import { useState, useEffect } from "react";
import axios from 'axios';
import QuestionsInformation from "./questionsInformation/QuestionsInformation";
import { SuccessAddElement } from "../successAddElement/SuccessAddElement";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import { ConfirmChangePage } from "../confirmChangePage/ConfirmChangePage";
import { FailAddElement } from "../failAddElement/FailAddElement";


const ResourcesQuestions = (props)=>{

    const [ journeyData, setJourneyData] = useState( null );
    const [janValue, setJan] = useState (0)
    const [febValue, setFeb] = useState (0)
    const [marValue, setMar] = useState (0)
    const [aprValue, setApr] = useState (0)
    const [mayValue, setMay] = useState (0)
    const [junValue, setJun] = useState (0)
    const [julValue, setJul] = useState (0)
    const [augValue, setAug] = useState (0)
    const [sepValue, setSep] = useState (0)
    const [octValue, setOct] = useState (0)
    const [novValue, setNov] = useState (0)
    const [decValue, setDec] = useState (0)
    const [ updateAwnserState, setUpdateAwnserState ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ haveError, setHaveError ] = useState(false)

    const screenWidth = window.screen.width
    const LoadJourneyAnswers = async() =>{
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        let resp = await axios.get('https://6vvwjjze2wgxkduabdoyrfink40wwgak.lambda-url.us-east-2.on.aws/',{ params:{ company:userId, token:token } } );
        var test = 0

        if(resp.data.Resources )
        {
            test = resp.data.Resources.jrJanRevenue
            
            
            setJourneyData(resp.data.Resources);
            setJan(StringMask( resp.data.Resources.jrJanRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setFeb(StringMask( resp.data.Resources.jrFebRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setMar(StringMask( resp.data.Resources.jrMarRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setApr(StringMask( resp.data.Resources.jrAprRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setMay(StringMask( resp.data.Resources.jrMayRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setJun(StringMask( resp.data.Resources.jrJunRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setJul(StringMask( resp.data.Resources.jrJulRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setAug(StringMask( resp.data.Resources.jrAugRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setSep(StringMask( resp.data.Resources.jrSepRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setOct(StringMask( resp.data.Resources.jrOctRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setNov(StringMask( resp.data.Resources.jrNovRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
            setDec(StringMask( resp.data.Resources.jrDecRevenue.toFixed(2), { type:'2DigitFraction' }, '' ))
        }
    }

    const saveAnswers = async()=>{

        setLoading(true)
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'saveBtn' ).innerHTML = "Salvando";
        let newJourneyData = 
        {
            jrIntelectual: journeyData.jrIntelectual, 
            jrPhysical: journeyData.jrPhysical,
            jrRevenues: journeyData.jrRevenues, 
            jrHuman: journeyData.jrHuman,
            jrWaysPayment: journeyData.jrWaysPayment,/*
            jrMonths: journeyData.jrMonths,
            jrJanRevenue: StringMask(janValue, { type:'float' }, '' ),
            jrFebRevenue: StringMask(febValue, { type:'float' }, '' ),
            jrMarRevenue: StringMask(marValue, { type:'float' }, '' ),
            jrAprRevenue: StringMask(aprValue, { type:'float' }, '' ),
            jrMayRevenue: StringMask(mayValue, { type:'float' }, '' ),
            jrJunRevenue: StringMask(junValue, { type:'float' }, '' ),
            jrJulRevenue: StringMask(julValue, { type:'float' }, '' ),
            jrAugRevenue: StringMask(augValue, { type:'float' }, '' ),
            jrSepRevenue: StringMask(sepValue, { type:'float' }, '' ),
            jrOctRevenue: StringMask(octValue, { type:'float' }, '' ),
            jrNovRevenue: StringMask(novValue, { type:'float' }, '' ),
            jrDecRevenue: StringMask(decValue, { type:'float' }, '' ),*/
        };

        await axios.put('https://bjjv5n7mi4v6epb3zjhvtenrae0vwnmc.lambda-url.us-east-2.on.aws/',{ company:userId, token:token, tag:'Resources', journeyData:newJourneyData } )
        .then(()=>{
            setLoading(false)
            setUpdateAwnserState(true)
        })
        .catch(err=>{
            setLoading(false)
            setHaveError(true)
        })
    


        document.getElementById( 'saveBtn' ).innerHTML = "Salvar";
    }



    const changetext =(e,source,date)=>{

        switch (source){

            case 'text1':{
                if(journeyData.jrIntelectual !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jrIntelectual : e.target.value})         
            }
            break;
            case 'text2':{
                if(journeyData.jrPhysical !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jrPhysical : e.target.value})
            }
            break;
            case 'text3':{
                if(journeyData.jrRevenues !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}  
                setJourneyData({...journeyData, jrRevenues : e.target.value})         
            }
            break;
            case 'text4':{
                if(journeyData.jrHuman !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jrHuman : e.target.value})
            }
            break;
            case 'text5':{
                if(journeyData.jrWaysPayment !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jrWaysPayment : e.target.value})         
            }
            break;
            case 'input1':{
                if(journeyData.jrMonths !== e.target.value && props.checkChanges !== "-1"){props.checkChanges(true)}
                setJourneyData({...journeyData, jrMonths : e.target.value})         
            }
            break;
            case 'moneyInput':{
                switch(date){
                    case 'jan':{



                        if(props.checkChanges!=="-1" && janValue !== StringMask( e.target.value, { type:'2DigitFraction' }, '' )){props.checkChanges(true)}
                        // setJourneyData({...journeyData, jrJanRevenue : StringMask( e.target.value, { type:'2DigitFraction' }, '' )})
                        setJan(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'feb':{
                        setFeb(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'mar':{
                        setMar(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'apr':{
                        setApr(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'may':{
                        setMay(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'jun':{
                        setJun(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'jul':{
                        setJul(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'aug':{
                        setAug(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'sep':{
                        setSep(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'oct':{
                        setOct(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'nov':{
                        setNov(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;
                    case 'dec':{
                        setDec(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))
                    }
                    break;               
                }
            }
            break;
            default:
        }
        
    }

    const checkText =(text)=>{
        let textInput = document.getElementById(text)
        textInput.placeholder='Digite sua resposta';
        if(textInput.value != ''){
            textInput.disabled=true;
            textInput.style.border = 'none';   
        }
    }

    const editText = (text) =>{
        let textInput = document.getElementById(text)
        if(textInput.value != ''){
            textInput.disabled = false;   
            textInput.style.border = '1px solid #251F53'
        }
    }

    var currencyMasky = (value) =>{

        if(value!== undefined ){
            value = value.replace('.', '').replace(',', '').replace(/\D/g, '');
            const options = { minimumFractionDigits: 2 }
            const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )
        return('R$'+ ' ' + result);
        }
        else{
            value = '0.00';
            value = value.replace('.', '').replace(',', '').replace(/\D/g, '');
            const options = { minimumFractionDigits: 2 }
            const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )
        return('R$'+ ' ' + result);
        }
        
    }

    const test =() =>{
        var test = StringMask(journeyData.jrJanRevenue, { type:'float' }, '' )
    }

    const saveAnswersAndRedirect = async() =>{

        props.disableConfirmScreen()
        await saveAnswers()
        


    }

    useEffect( () => { LoadJourneyAnswers()}, [] );

    return(
        <div className="journeyFWQQuestionsLayout1">  
        {haveError && <FailAddElement onClick={()=>setHaveError(false)} message="Ocorreu um erro, por favor tente mais tarde"/>}  
        {loading && <LoadingScreen/>}
        {updateAwnserState && <SuccessAddElement message="Suas respostas foram salvas com sucesso" onClick={()=>{setUpdateAwnserState(false); props.redirect()}}/>}
        {props.activeConfirmScreen && <ConfirmChangePage message='Deseja salvar as alterações nas respostas?' moreButtons={true} onClick={saveAnswersAndRedirect} closeWarn={props.redirect}/>}
            
               <div className="journeyQuestionsSaveBtn"  id='saveBtn' onClick={saveAnswers}>
                    Salvar
                </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText" style={{marginBottom:'1rem'}}>Recursos:</text>
                        <text className="journeyFWQQuestionText">Intelectuais:
                            <QuestionsInformation haveTopics={true} topicsInsideTopics={true} breakLine={true} information="São recursos baseados no conhecimento, expertise e propriedade intelectual da organização.
                            
                            
                            
                            <br/>Aqui estão alguns exemplos de recursos intelectuais:->

                                Propriedade intelectual<topic>
                                Conhecimento técnico<topic>
                                Processos e metodologias<topic>
                                Bancos de dados e informações<topic>
                                Relacionamentos e parcerias<topic>
                                Reputação da marca<topic>
                                Cultura organizacional<topic>
                                Conteúdo e propriedade digital
                                "/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea1' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jrIntelectual : '---'} onChange={(e) => changetext(e,'text1')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea1')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea1')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Físicos:
                            <QuestionsInformation haveTopics={true} breakLine={true} information="Os recursos físicos são os ativos tangíveis de um modelo de negócio, ou seja, aqueles que podem ser tocados, vistos ou quantificados. 
                            
                            
                            
                            <br/>Eles são essenciais para a operação da empresa e podem incluir:

                            <br/>Instalações físicas:-> São os edifícios, escritórios, fábricas, armazéns, lojas físicas, entre outros espaços físicos necessários para as atividades da empresa.
                            <br/>Equipamentos e maquinários:-> São os equipamentos, máquinas, ferramentas e tecnologias utilizadas para a produção, prestação de serviços ou operação do negócio.
                            <br/>Inventário:-> Refere-se aos estoques de produtos físicos que a empresa mantém para venda ou utilização em suas operações.
                            <br/>Veículos:-> Inclui veículos comerciais, frotas de transporte ou qualquer meio de transporte utilizado nas atividades da empresa.
                            <br/>Ativos fixos:-> São os ativos de longo prazo, como imóveis, terrenos, maquinários pesados, que são utilizados para fins de produção ou operação da empresa.
                            <br/>Ferramentas e suprimentos:-> São as ferramentas, equipamentos auxiliares e suprimentos necessários para realizar tarefas específicas ou operações diárias."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea2' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jrPhysical : '---'} onChange={(e) => changetext(e,'text2')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea2')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea2')}></img>
                        </div>
                    </div>
                    {/*<div className="journeyFWQQuestionsLayout2">
                        <text className="journeyFWQQuestionText">Financeiros:</text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img> 
                            <textarea id='journeyFWQtextArea3' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jrRevenues : '---'} onChange={(e) => changetext(e,'text3')} 
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea3')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea3')}></img>
                        </div>
                    </div>
                    */}<div className="journeyFWQQuestionsLayout2" >
                        <text className="journeyFWQQuestionText">Humanos:
                            <QuestionsInformation haveTopics={true} topicsInsideTopics={true} breakLine={true} information="Os recursos humanos são uma categoria importante de recursos em um modelo de negócio. Eles se referem às pessoas que fazem parte da organização e contribuem para as atividades e o funcionamento da empresa. 
                            
                            
                            
                            <br/>Alguns exemplos de recursos humanos incluem:->

                                Funcionários<topic>   
                                Gerentes e Líderes<topic>
                                Especialistas e consultores<topic>
                                Equipes de suporte<topic>
                                Parceiros e colaboradores
                                "/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea4' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jrHuman : '---'} onChange={(e) => changetext(e,'text4')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea4')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea4')}></img>
                        </div>
                    </div>
                    <div className="journeyFWQQuestionsLayout2" >
                        <text className="journeyFWQQuestionText">Tecnológicos:
                            <QuestionsInformation haveTopics={true} breakLine={true} information="Os recursos tecnológicos são uma categoria de recursos em um modelo de negócio que se refere às ferramentas, sistemas e tecnologias utilizadas para facilitar e aprimorar as operações da empresa. 
                            
                            
                            
                            <br/>Alguns exemplos de recursos tecnológicos incluem:

                                <br/>Hardware:-> Dispositivos físicos, como computadores e impressoras.
                                <br/>Software:-> Programas de computador e aplicativos.
                                <br/>Redes e infraestrutura de TI:-> Componentes para conexão e comunicação.
                                <br/>Sistemas de gerenciamento:-> Software para gestão de áreas específicas.
                                <br/>Ferramentas de análise de dados:-> Softwares para coleta e interpretação de dados.
                                <br/>Infraestrutura de nuvem:-> Armazenamento e processamento remoto de dados.
                                <br/>Tecnologias emergentes:-> Inovações como IA, IoT e realidade virtual."/>
                        </text>
                        <div className="journeyFWQQuestionsLayout3">
                            <img src={UserQuestionsJourney} alt='' className="journeyFWQProfile"></img>
                            <textarea id='journeyFWQtextArea5' className="journeyFWQTextArea" placeholder="Digite sua resposta." value ={(journeyData !== null) ? journeyData.jrWaysPayment : '---'} onChange={(e) => changetext(e,'text5')}
                            onFocus={(e) => (e.target.placeholder = "")} onBlur={(e) => (e.target.placeholder = "Digite sua resposta.")}></textarea>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', gap:'0.7rem'}}>
                            <img src={CheckQuestions} alt='' className='journeyFWQCheck' onClick={()=>checkText('journeyFWQtextArea5')}></img>
                            <img src={PencilQuestions} alt='' className='journeyFWQPencil' onClick={()=>editText('journeyFWQtextArea5')}></img>
                        </div>
                    </div>
                    {/*<div style={{display:'flex', flexDirection:'column',marginTop:'1rem', marginBottom:'2.0rem'}}>
                          
                    <div>   
                        <text className="journeyFWQQuestionText" style={{marginRight: '12px'}}>Quantos meses o seu negócio possui?

                        </text>
                        <select className="journeyFWQQuestionText journeyFWQMoneyInput" style={{marginLeft:'0rem',width:'5rem'}} value = {(journeyData !== null) ? journeyData.jrMonths : '1'} onChange={(e) => changetext(e,'input1')}>
                            <option className="journeyFWQQuestionText">1</option>
                            <option className="journeyFWQQuestionText">2</option>
                            <option className="journeyFWQQuestionText">3</option>
                            <option className="journeyFWQQuestionText">4</option>
                            <option className="journeyFWQQuestionText">5</option>
                            <option className="journeyFWQQuestionText">6</option>
                            <option className="journeyFWQQuestionText">7</option>
                            <option className="journeyFWQQuestionText">8</option>
                            <option className="journeyFWQQuestionText">9</option>
                            <option className="journeyFWQQuestionText">10</option>
                            <option className="journeyFWQQuestionText">11</option>
                            <option className="journeyFWQQuestionText">12</option>
                            <option className="journeyFWQQuestionText">12+</option>
                        </select>
                    </div>
                            <QuestionsInformation information="Caso seu negócio tenha acabado de começar, coloque 1. "/>                    
                    </div>
                    <div style={{display:'flex', flexDirection:'column',marginBottom:'2rem'}}>
                        <text className="journeyFWQQuestionText">Qual foi a receita do seu negócio nos últimos 12 meses?
                            <QuestionsInformation information="Caso não saiba os números exatos, coloque aproximadamente. Caso seu negócio ainda não tenha faturado, coloque 0."/>
                        </text>
                        <div style={{display:'flex', flexDirection: screenWidth<780 &&'column', marginTop:'1rem',marginLeft:'2rem',gap: screenWidth>780 ? '3rem' : '2px'}}>
                            <div style={{display:'flex', flexDirection:'column',width:'15.5rem',gap:'0.2rem'}}>
                                <div style={{display:'flex', flexDirection: 'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Janeiro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ?  janValue : '---'}   onChange={(e)=>changetext(e,'moneyInput','jan')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Fevereiro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? febValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','feb')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Março:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? marValue : '---'}  onChange={(e)=>changetext(e,'moneyInput','mar')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Abril:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? aprValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','apr')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Maio:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? mayValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','may')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Junho:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? junValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','jun')}></input>
                                </div>
                            </div>
                            <div style={{display:'flex', flexDirection:'column',width:'16.2rem',gap:'0.2rem'}}>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Julho:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? julValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','jul')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Agosto:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? augValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','aug')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Setembro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? sepValue : '---'}  onChange={(e)=>changetext(e,'moneyInput','sep')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Outubro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? octValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','oct')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Novembro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? novValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','nov')}></input>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                                    <text className="journeyFWQQuestionText">Dezembro:</text>
                                    <input className='journeyFWQMoneyInput' placeholder="R$ 0,00" value={ (journeyData !== null ) ? decValue  : '---'}  onChange={(e)=>changetext(e,'moneyInput','dec')}></input>
                                </div>
                            </div>
                        </div>
                    </div>*/}








                </div>
    )
}

export default ResourcesQuestions;