import React from 'react'
import styles from './SearchGroups.module.sass'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import { IconCompetitorsImg } from '../../imgsLoader/LoadIconImgs'
import UserStore from '../../../store/userStore'
import LoadingScreen from '../../loadingScreen/LoadingScreen'
import { botconversaWebhook } from '../../../functions/botconversaWebhook'
const SearchGroups = ({closeFn, reloadUserGroups, userGroups}) => {
    console.log(userGroups)
    const emoji = "1F923"

    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const currentDay = new Date().getDate()

    const [communityGroups, setCommunityGroups] = useState([])
    const [isLoading, setIsLoading] = useState(false)










    const convertDate = (date) =>{
        const splittedDate = date.split('-')
        const thisYear = splittedDate[0]
        const thisMonth = splittedDate[1]        
        const thisDay = splittedDate[2].substring(0, 2)        
        let thisConvertedDate = 'Ocorreu algum erro, tente mais tarde'

        console.log(thisDay)

        if (currentYear>thisYear) {
            const distance = Number(currentYear) - Number(thisYear)
            thisConvertedDate = `${distance} ${distance===1 ? 'ano' : 'anos'} atrás`
        } else if(currentMonth>thisMonth){
            const distance = Number(currentMonth) - Number(thisMonth)
            thisConvertedDate = ` ${distance} ${distance===1 ? 'mês' : 'meses'} atrás`
        } else if(currentDay>thisDay){
            const distance = Number(currentDay) - Number(thisDay)
            thisConvertedDate = `Novo Grupo`
        } else if(Number(currentDay)===Number(thisDay)){
            thisConvertedDate = `Novo Grupo`



        }
        return thisConvertedDate
    }









    useEffect(()=>{
    
        const getGroups = async() =>{

            setIsLoading(true)
            await axios.get('https://vcez7yunqfycmjyykyvmbian2q0atzuk.lambda-url.us-east-2.on.aws/')
            .then(resp=>{setCommunityGroups(resp.data.communityData); setIsLoading(false);})
            .catch(err=>{console.log(err); setIsLoading(false)})

        }

        getGroups()

    }, [])



    const enterGroup = async({ groupId, groupTitle, groupEmoji }) =>{
        botconversaWebhook({action: `Entrou no grupo ${groupTitle}`})
        setIsLoading(true)
        const companyId = UserStore.getUserId()
        await axios.post('https://qubjznwr6bebq4lhexer7lgrpm0lkhkw.lambda-url.us-east-2.on.aws/', {
            companyId: companyId, 
            groupId: groupId

        }).then(resp=>{reloadUserGroups(); setIsLoading(false); closeFn()}).catch(err=>setIsLoading(false))

    }


    const exitGroup = async({ groupId, groupTitle, groupEmoji }) =>{
        //botconversaWebhook({action: `Entrou no grupo ${groupTitle}`})
        setIsLoading(true)
        const companyId = UserStore.getUserId()
        await axios.delete('https://ekxatajuldmbitq574myd3ffsq0lywia.lambda-url.us-east-2.on.aws/', {data: {
            companyId: companyId, 
            groupId: groupId

        }}).then(resp=>{reloadUserGroups(); setIsLoading(false); closeFn()}).catch(err=>setIsLoading(false))

    }



  return (
    <div className={styles.searchGroups}> 
        {isLoading && <LoadingScreen/>}

        <div className={styles.content}>
            <div className={styles.top}>
               <h2>Grupos</h2>
               <span onClick={closeFn}>x</span>
            </div>
            <div className={styles.bottom}>

                <div className={styles.bottomContent}>


                {communityGroups && communityGroups.map(group=>(
                    <div className={styles.groupCard}>



                        {userGroups.find(userGroup=>userGroup.groupId===group.id) ? 
                        <button style={{backgroundColor: '#ff1515'}} onClick={()=>{
                            exitGroup({
                                groupId: group.id, 
                                groupTitle: group.title, 
                                groupEmoji: group.emoji 
                                })

                                }}>Sair</button> 
                                
                                
                                
                                :  
                                
                                
                                
                                <button onClick={()=>{
                                    enterGroup({
                                        groupId: group.id, 
                                        groupTitle: group.title, 
                                        groupEmoji: group.emoji 
                                        })
        
                                        }}>Participar</button> }











                        <div className={styles.title}>
                            <span style={{fontSize: '18px'}}>{String.fromCodePoint(parseInt (group.emoji, 10))}</span> 
                            <p style={{width: '165px'}}>{group.title}</p>
                        </div>    

                        <div className={styles.row}>
                            <img src={IconCompetitorsImg} alt=''/>
                            <p>{group.participantsAmount ? (group.participantsAmount===1 ? `${group.participantsAmount} Participante` : `${group.participantsAmount} Participantes`) : `0 Participantes` }</p>

                                                                   
                        </div>   

                        <div className={styles.row}>
                            {convertDate(group.createdAt)=== 'Novo Grupo' && <><div className={styles.info}>
                                <span>i</span>
                            </div>
                            <p>Novo Grupo</p></>} 
                        </div>     

  

                    </div>
                ))}

                {isLoading && <p>Carregando...</p>}
            </div>

                </div>


        </div>



    </div>
  )
}

export default SearchGroups