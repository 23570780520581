import React, { useState } from 'react'
import styles from './EditExpense.module.sass'
import { StringMask } from '../../../library/StringMasks'
import axios from 'axios'
import UserStore from '../../../../store/userStore'
const EditExpense = ({expense, closeEdit, fillCFList}) => {
    const userId = UserStore.getUserId()
    const token = UserStore.getToken()
    const [isLoading, setIsLoading] = useState(false)


    const saveEdit = async() =>{
       setIsLoading(true)
        await axios.put
        (
            'https://j6q3fg67zatdipf2wksmrskqqq0cqcfo.lambda-url.us-east-2.on.aws/',
            {
                company: userId,
                token: token,
                cfe:expense.id,
                month: ( expense.month + 1 ),
                done: parseFloat( StringMask( doneValue, { type:'float' }, '' ) )
            }
        );
        
        fillCFList()
        closeEdit()     
    }
    const [doneValue, setDoneValue] = useState(`${expense.doneValue.toFixed(2)}`)
  return (
    <div className={styles.editExpense}>
        <div className={styles.content}>
            <h2>Editando despesa: {expense.name}</h2>
            <div><p>Valor Previsto</p>
                 <span>R${StringMask( expense.targetValue.toFixed( 2 ), { type:'2DigitFraction' }, ( expense.targetValue < 0 ) ? '-' : '' )}</span>
            </div>
            <div><p>Valor Realizado</p>   
                 <input value={StringMask( doneValue, { type:'2DigitFraction' }, '' )} onChange={(e)=>setDoneValue(StringMask(e.target.value, { type:'2DigitFraction' }, '' ))}/>
            </div>
            <div className={styles.buttons}>
                {isLoading && <div className={styles.loadButton}>Carregando...</div>}
                {!isLoading && <>       
                    <div className={styles.closeButton} onClick={closeEdit}>Fechar</div> 
                    <div className={styles.confirmButton} onClick={saveEdit}>Salvar</div>
                </>}
            </div>

            
        </div>   
    </div>
  )
}

export default EditExpense