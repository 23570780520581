import React, { useState } from 'react'
import styles from './AnnotationsVideoOption.module.css'
import axios from 'axios'
import UserStore from '../../../../store/userStore'



const AnnotationsVideoOption = ({isLoading, newAnnotation, putNewAnnotation, changeNewAnnotationValue, annotationsArray, videoCode}) => {



  const [annotationContent, setAnnotationContent] = useState('')

  //const putNewAnnotation = async(annotation) =>{
  //  console.log(annotation)
    
   // const url = 'https://4embu7tlphqa65yz5rva2mkrcm0hpzqy.lambda-url.us-east-2.on.aws/'
  //  await axios.put(url, {tag: 1, annotation: annotation, company: UserStore.getUserId(), code: videoCode}).then(()=>setAnnotationContent(''))

  //}

  const convertVideoTime = (time) =>{
  

    const minutesFloat = Math.floor(time/60)
    const secondsFloat = Math.floor(time%60)
    
    const minutes = (minutesFloat<10) ? `0${minutesFloat}` : `${minutesFloat}`
    const seconds = (secondsFloat<10) ? `0${secondsFloat}` : `${secondsFloat}`


     const convertedTime = minutes + ':' + seconds
    return convertedTime
  }

  const defineAnnotationFormat = (annotation) => {
    return annotation.charAt(0).toUpperCase() + annotation.substring(1)



  }


  return (
    <div className={styles.annotationsVideoOption}>
      <div className={styles.topInformations}>
          <p>Anotações</p> <div><p>Anotações Recentes</p> <p>Anotações Antigas</p></div>

      </div>
      <div className={styles.newAnnotation}>

        {isLoading && <textarea placeholder='Insira suas anotações aqui' value={newAnnotation} disabled></textarea>}
        {!isLoading && <textarea placeholder='Insira suas anotações aqui' value={newAnnotation} onChange={(e)=>{changeNewAnnotationValue(e.target.value)}}></textarea>}
        
        
        {isLoading && <div className={styles.saveBtnLoading}>Salvando...</div>}
        {!isLoading && <div className={styles.saveBtn} onClick={()=>putNewAnnotation(newAnnotation)}>Salvar</div>}



      </div>



      {annotationsArray && annotationsArray.map((annotation, index)=>(
      
      <div key={index} className={styles.annotation}>
        
        <p>
        
          {defineAnnotationFormat(annotation.annotation)}

        </p>
        <span>&#x2022; Nota em {convertVideoTime(annotation.videoTime)}</span>    
      </div>))}
    </div>
  )
}

export default AnnotationsVideoOption