import React from 'react'
import styles from './Welcome.module.sass'
import Background from "./../../assets/new_background.png";
import UserStore from '../../store/userStore';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react'


const Welcome = () => {
    const isNewUser = UserStore.getWelcomeState()
    const userName = UserStore.getUserName()
    //UserStore.setWelcomeState('true')

    const tagManagerArgs = {
      gtmId: 'GTM-T6GXBPB' 

    }
    useEffect(() => {
      TagManager.initialize(tagManagerArgs);
    }, []);

    console.log(userName)
  return (
    <div className={styles.welcome}>

      {isNewUser==='true' &&<>
        <img src={Background} alt='' className={styles.background}/>

        <div className={styles.textDiv}>
            <h2 className={styles.welcomeTitle}>
                <span id={styles.welcomeSpan} className={styles.welcomeText}>Olá, muito obrigado  </span> 






                <span id={styles.welcomeSpan} className={styles.icon}>  :&#41;</span>
            
            </h2>
                <span id={styles.welcomeSpan} className={styles.introduceText}>Está pronto para transformar o seu negócio?  </span> 
        <div className={styles.confirmButton} onClick={()=>{UserStore.setWelcomeState('false'); window.location.replace('/')/**/}}>
            Continuar
        </div>

        </div>


</>
 }
    </div>
  )
}

export default Welcome