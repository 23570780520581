import React from 'react';
import { useState, useEffect } from 'react';
import '@coreui/coreui/dist/css/coreui.min.css'
import { TrashIconImg, EditIconImg, AddIconImg } from '../imgsLoader/LoadIconImgs';
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid';
import UserStore from '../../store/userStore';
import axios from 'axios';
import { StringMask } from '../library/StringMasks';
import LoadingScreen from '../loadingScreen/LoadingScreen';
import "./Cleancache.css?v=0.1.8";
import RevenuesMobile from './revenuesMobile/RevenuesMobile';
import { botconversaWebhook } from '../../functions/botconversaWebhook';

const revenueImportance = 
[
    { value: 0, label: 'Muito Alta', class: 'ModelRevenuesVeryHigh' },
    { value: 1, label: 'Alta', class: 'ModelRevenuesHigh' },
    { value: 2, label: 'Normal', class: 'ModelRevenuesNormal' },
    { value: 3, label: 'Baixa', class: 'ModelRevenuesLow' }
];

//TODO: Add cancel new item button

let pItems = [];
let products = [];

const Revenues = () =>
{



    const pageLocale = 'Gestão Financeira'
    useEffect(()=>{
    
        botconversaWebhook({locale: pageLocale, action: 'Abriu Receitas'})
    })    
    const [ tableProducts, setTableProducts ] = useState( [] );
    const [ loadingProductList, setLoadingProductList ] = useState( true );
    const [ screenDebounceTime, setScreenDebounceTime ] = useState( true );
    const [ productsList, setProductsList ] = useState([])
    const [ loadingScreen, setLoadingScreen ] = useState(false);
    const [ isAddNewProduct, setIsAddNewProduct ] = useState(false)
    const [ cardReload, setCardReload ] = useState(false)
    const screenWidth = window.screen.width
    const emptyTableProductsWarn = 
        <tr>
            <td colSpan={ 5 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
                Você não possui produtos cadastrados.<br/>
                Clique em '+' no canto superior direito para adicionar um novo produto.
            </td>
        </tr>;
    
    const loadingTableProductsWarn = 
        <tr>
            <td colSpan={ 5 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
                Por favor aguarde, carregando lista de produtos.
            </td>
        </tr>;

    const fillProductsList = async() =>
    {

        pItems = []

        products = []
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();

        axios.get( 'https://bmiejsiuhnjv5ipb5sjnbanjr40vxxxu.lambda-url.us-east-2.on.aws/', { params: { company: userId, token: token } } ).then( ( resp ) =>
        {
            if( resp.data.products )
            {

                setProductsList(resp.data.products)
                for( let index = 0; index < resp.data.products.length; index++ )
                {
                    let product = resp.data.products[ index ].product;
                    let name = resp.data.products[ index ].name;
                    let value = StringMask( resp.data.products[ index ].value.toFixed( 2 ), { type:'2DigitFraction' }, '' )
                    let importance = resp.data.products[ index ].importance;

                    const newLine = 
                        <tr id={ 'p_' + product } key={ product }>
                            <td>{ name }</td>
                            <td>{ value }</td>
                            <td><div className={ revenueImportance[ importance ].class }>{ revenueImportance[ importance ].label }</div></td>
                            <td><div className='userInputsIconGreen' onClick={ () => editProduct( product ) }><img src={ EditIconImg } alt='...'/></div></td>
                            <td><div className='userInputsIconRed' onClick={ () => removeProduct( product ) }><img src={ TrashIconImg } alt='...'/></div></td>
                        </tr>;

                    pItems.push( { added: true, productId: product, name: name, value: value, importance: importance } );
                    products.push( newLine );
                }
            }

            setTableProducts( products);

            console.log('table');console.log(resp.data.products)
            setLoadingProductList( false );
        } );
    }

    const handleSelectChange = ( productId, event ) => 
    {
        let pIndex = pItems.findIndex( ( product ) => { return product.productId === productId } );
        pItems[ pIndex ].importance = ( pIndex !== -1 ) ? event.value : pItems[ pIndex ].importance;
    }

    const handleNameChange = ( productId, event ) => 
    {
        let pIndex = pItems.findIndex( ( product ) => { return product.productId === productId } );
        pItems[ pIndex ].name = ( pIndex !== -1 ) ? event.target.value : pItems[ pIndex ].name;
    }

    const handleValueChange = ( productId, event ) => 
    {
        let pIndex = pItems.findIndex( ( product ) => { return product.productId === productId } );
        pItems[ pIndex ].value = ( pIndex !== -1 ) ? event.target.value : pItems[ pIndex ].value;
        pItems[ pIndex ].value = StringMask( pItems[ pIndex ].value, { type:'2DigitFraction' }, '' );
        event.target.value = pItems[ pIndex ].value;
    }

    const newProductOnTable = () =>
    {

            if (screenWidth>780) {
            var productId = uuidv4();

            const newLine = 
                <tr id={ 'p_' + productId } key={ productId }>
                    <td><input className='userInputsText' type='text' name='pName' onChange={ ( event ) => handleNameChange( productId, event ) }/></td>
                    <td><input className='userInputsText' type='text' name='pValue' onChange={ ( event ) => handleValueChange( productId, event ) } defaultValue={ '0,00' }/></td>
                    <td><Select className='userInputsSelector' menuPlacement='top' onChange={ ( event ) => handleSelectChange( productId, event ) } options={ revenueImportance } defaultValue= { revenueImportance[ 3 ] } styles={{control: (baseStyles, state) => ({...baseStyles,borderColor:'#000000','&:hover': { borderColor: '#000000'},boxShadow: 'none'})}}/></td>
                    <td><div className='userInputsIconGreen' onClick={ () => createProduct( productId ) }><img src={ AddIconImg } alt='...'/></div></td>
                    <td><div className='userInputsIconRed' onClick={ () => cancelNewProduct( productId ) }>X</div></td>
                </tr>;

            pItems.push( { added: false, productId: productId, name: '', value: '0,00', importance: revenueImportance[ 3 ].value } );
            products.push( newLine );
            setTableProducts( [ ...products ] );            
        } else {
            setIsAddNewProduct(true)
        }

    };

    const cancelNewProduct = ( productId ) =>
    {
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {
            pItems.splice( pIndex, 1 );
            products.splice( pIndex, 1 );
            setTableProducts( [ ...products ] );
        }
    }

    const createProduct = async( productId ) => 
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {
            if( pItems[ pIndex ].name === "" )
            {
                alert( "Por favor, insira um nome para o novo produto." );
            }
            else if( pItems[ pIndex ].value === "" )
            {
                alert( "Por favor, insira um valor para o novo produto." );
            }
            else
            {

                setLoadingScreen(true)
                let impIndex = revenueImportance.findIndex( ( imp ) => { return imp.value === pItems[ pIndex ].importance } );
                



                impIndex = ( impIndex === -1 ) ? 0 : impIndex;
                const resp = await axios.post( 'https://6femnjigs57o2ddvvoxa43nwu40mnfcj.lambda-url.us-east-2.on.aws/', { company: userId, token: token, name: pItems[ pIndex ].name, value: StringMask( pItems[ pIndex ].value, { type:'float' }, '' ), importance: pItems[ pIndex ].importance } ).catch(error=>setLoadingScreen(false));

                if( resp.data.product !== -1 )
                {

                    
                        botconversaWebhook({locale: pageLocale, action: 'Criou Produto'})
                    
                    setLoadingScreen(false);


                    const productResp = resp.data.product;
                    const newLine = 
                        <tr id={ 'p_' + productResp } key={ productResp }>
                            <td>{ pItems[ pIndex ].name }</td>
                            <td>{ StringMask( pItems[ pIndex ].value, { type:'2DigitFraction' }, '' ) }</td>
                            <td><div className={ revenueImportance[ impIndex ].class }>{ revenueImportance[ impIndex ].label }</div></td>
                            <td><div className='userInputsIconGreen' onClick={ () => editProduct( productResp ) }><img src={ EditIconImg } alt='...'/></div></td>
                            <td><div className='userInputsIconRed' onClick={ () => removeProduct( productResp ) }><img src={ TrashIconImg } alt='...'/></div></td>
                        </tr>;

                    products[ pIndex ] = newLine;
                    setTableProducts( [ ...products ] );

                }

                setLoadingScreen(false)
            }
        }
    };

    const cancelEdit = ( productId ) =>
    {
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {
            let impIndex = revenueImportance.findIndex( ( imp ) => { return imp.value === pItems[ pIndex ].importance } );
            impIndex = ( impIndex === -1 ) ? 0 : impIndex;

            const newLine = 
            <tr id={ 'p_' + productId } key={ productId }>
                <td>{ pItems[ pIndex ].name }</td>
                <td>{ pItems[ pIndex ].value }</td>
                <td><div className={ revenueImportance[ impIndex ].class }>{ revenueImportance[ impIndex ].label }</div></td>
                <td><div className='userInputsIconGreen' onClick={ () => editProduct( productId ) }><img src={ EditIconImg } alt='...'/></div></td>
                <td><div className='userInputsIconRed' onClick={ () => removeProduct( productId ) }><img src={ TrashIconImg } alt='...'/></div></td>
            </tr>;

            products[ pIndex ] = newLine;
        }

        setTableProducts( [ ...products ] );
    }

    const editProduct = ( productId ) =>
    {
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {
            let impIndex = revenueImportance.findIndex( ( imp ) => { return imp.value === pItems[ pIndex ].importance } );
            impIndex = ( impIndex === -1 ) ? 0 : impIndex;

            const newLine = 
                <tr id={ 'p_' + productId } key={ productId }>
                    <td><input className='userInputsText' type='text' name='pName' onChange={ ( event ) => handleNameChange( productId, event ) } defaultValue={ pItems[ pIndex ].name }/></td>
                    <td><input className='userInputsText' type='text' name='pValue' onChange={ ( event ) => handleValueChange( productId, event ) } defaultValue={ pItems[ pIndex ].value }/></td>
                    <td><Select className='userInputsSelector' menuPlacement='top' onChange={ ( event ) => handleSelectChange( productId, event ) } options={ revenueImportance } defaultValue= { revenueImportance[ impIndex ] } styles={{control: (baseStyles, state) => ({...baseStyles,borderColor:'#000000','&:hover': { borderColor: '#000000'},boxShadow: 'none'})}}/></td>
                    <td><div className='userInputsIconGreen' onClick={ () => confirmEditProduct( productId ) }><img src={ AddIconImg } alt='...'/></div></td>
                    <td><div className='userInputsIconRed' onClick={ () => cancelEdit( productId ) }>X</div></td>
                </tr>;

            products[ pIndex ] = newLine;
        }

        setTableProducts( [ ...products ] );
    };

    const confirmEditProduct = async( productId ) =>
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {
            if( pItems[ pIndex ].name === "" )
            {
                alert( "O nome do produto não pode ser vazio." );
            }
            else if( pItems[ pIndex ].value === "" )
            {
                alert( "O valor do produto não pode ser vazio." );
            }
            else
            {
                let impIndex = revenueImportance.findIndex( ( imp ) => { return imp.value === pItems[ pIndex ].importance } );
                impIndex = ( impIndex === -1 ) ? 0 : impIndex;
                console.log();console.log()
                setLoadingScreen(true)
                const resp = await axios.put( 'https://3axebc7hd5jch3aq5allprdqw40inbvq.lambda-url.us-east-2.on.aws/', { product: productId, company: userId, token: token, name: pItems[ pIndex ].name, value: StringMask( pItems[ pIndex ].value, { type:'float' }, '' ), importance: pItems[ pIndex ].importance } ).catch(error => setLoadingScreen(false));

                if( resp.data.product !== -1 )
                {

                        botconversaWebhook({locale: pageLocale, action: 'Editou Produto'})
                            
                    setLoadingScreen(false)
                    const newLine = 
                        <tr id={ 'p_' + productId } key={ productId }>
                            <td>{ pItems[ pIndex ].name }</td>
                            <td>{ pItems[ pIndex ].value }</td>
                            <td><div className={ revenueImportance[ impIndex ].class }>{ revenueImportance[ impIndex ].label }</div></td>
                            <td><div className='userInputsIconGreen' onClick={ () => editProduct( productId ) }><img src={ EditIconImg } alt='...'/></div></td>
                            <td><div className='userInputsIconRed' onClick={ () => removeProduct( productId ) }><img src={ TrashIconImg } alt='...'/></div></td>
                        </tr>;

                    products[ pIndex ] = newLine;
                    setTableProducts( [ ...products ] );
                }

                setLoadingScreen(false)
            }   
        }
    }

    const removeProduct = async( productId ) =>
    {
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();
        let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

        if( pIndex !== -1 )
        {

            setLoadingScreen(true)
            const resp = await axios.delete( 'https://lxxi2dd7zrnboqd7yhvisvjtgq0rdqfy.lambda-url.us-east-2.on.aws/', { data: { product: productId, company: userId, token: token } } ).catch(async(error)=>{setLoadingScreen(false); window.location.reload()});
            let pIndex = products.findIndex( ( props ) => { return props.key === productId.toString() } );

            if( resp.data.product !== -1 )
            {

                    botconversaWebhook({locale: pageLocale, action: 'Deletou Produto'})
                products = []
                setCardReload(prev=>!prev)
                setLoadingScreen(false)
                products.splice( pIndex, 1 );
                pItems.splice( pIndex, 1 );

                console.log(tableProducts);console.log(products);
                //setTableProducts( [products ] );
            }

            setLoadingScreen(false)
        }
    };

    useEffect( () => { fillProductsList(); setTimeout( () => { setScreenDebounceTime( false ); }, 1000 ); }, [cardReload] );




    const testeAPI = async() =>{

        const action = ''
        const formattedPhone = UserStore.getUserPhone().replace('(', '').replace(')', '').replace('-', '').replace(' ', '').replace(' ', '')
        const url = 'https://backend.botconversa.com.br/api/v1/webhooks-automation/catch/64231/OnraahUpYENu/'
        await axios.post(url, {name: UserStore.getUserName(), email: UserStore.getUserEmail(), description: action, phone: formattedPhone}, {
            headers: {
              'Content-Type': 'application/json'
            }
        }).then(resp=>console.log(resp))  
    }

    return(
        <div className='FullBoardContent'>



            {loadingScreen && <LoadingScreen/>}
            <div style={{minHeight:'100%'}}>
                <div className="FullBoardContentTitle" onClick={testeAPI}>
                    Produtos e receitas
                    <div className='TitleNewItemBtn' style={{float:'right'}} onClick={ newProductOnTable }>+</div>
                </div>
                <div id="NumberOfOptionsId" className="FullBoardContentSubTitle">
                    Aqui você consegue controlar todos os produtos da sua empresa. É possível inserir o nome do produto, preço <br/>
                    e a importância da venda, que será considerada para cálculo da quantidade necessário de meta de vendas no plano de ação.
                </div>
            {screenWidth>780 && <div className='HorizontalLineSeparator'/>}
            <div style={{height:'100%'}}>

                {screenWidth>780 &&
                  <table id='productTable' className="ModelRevenuesTable">
                    <thead>
                        <tr>
                            <th>Produto</th>
                            <th>Preço (R$)</th>
                            <th>Importância de venda</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { ( loadingProductList || screenDebounceTime ) ? loadingTableProductsWarn : ( tableProducts.length > 0 ) ? tableProducts : emptyTableProductsWarn }
                    </tbody>
                  </table>
                }
                
                {screenWidth<780 && 
                    <RevenuesMobile 
                        isAddNewProduct={isAddNewProduct} 
                        products={productsList} 
                        confirmEditProduct={confirmEditProduct} 
                        removeProduct={removeProduct} 
                        setLoadingScreen={setLoadingScreen} 
                        
                        setCardReload={setCardReload}
                        setIsAddNewProduct={setIsAddNewProduct}
                        />
                }

            </div>



            </div>

        </div>
    );
}

export default Revenues;