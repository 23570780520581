import React from 'react'
import styles from './EditSale.module.sass'
import { StringMask } from '../../../library/StringMasks'
import { useState } from 'react'
import axios from 'axios'
import UserStore from '../../../../store/userStore'
const EditSale = ({closeFn, selectedSale, reloadInfo}) => {
    console.log(selectedSale)
    const [newValue, setNewValue] = useState(`${StringMask(selectedSale.value.toFixed(2), { type:'2DigitFraction' }, '' )}`)

    const [newObservations, setNewObservations] = useState(selectedSale.notes)   
    const [newQuantity, setNewQuantity] = useState(selectedSale.quantity)   
    const [newInstallments, setNewInstallments] = useState(selectedSale.installments) 
    const [isLoading, setIsLoading] = useState(false)
    const [isValueOpen, setIsValueOpen] = useState(false) 
    const [isQuantityOpen, setIsQuantityOpen] = useState(false) 
    
    const [isObsOpen, setIsObsOpen] = useState(false)
    const [isInstallmentsOpen, setIsInstallmentsOpen] = useState(false)




    const editSale = async() =>{ 
        setIsLoading(true)
        console.log(UserStore.getUserId())
        console.log(selectedSale.sale)
        console.log(newValue)
        console.log(newObservations)
        console.log(selectedSale )



        await axios.put('https://mqkiohlwi4wtkvutoomqjoid4m0cuzzl.lambda-url.us-east-2.on.aws/',  {
            company: UserStore.getUserId(), sId: selectedSale.sale, sSellPrice: newValue.replace('.', '').replace(',', '.'), sQuantity: newQuantity, sInstallments: newInstallments, sNotes: newObservations, sDate: selectedSale.date
        } ).then(resp=>{
            console.log(resp)
            reloadInfo()
            closeFn() 

        })


        setIsLoading(false)

    }
  return (
    <div className={styles.editSale}>{selectedSale &&
    
    <div className={styles.content}>
        <div className={styles.info}>



        
        <div className={styles.infoData}>
            Venda de: {selectedSale.date}  
        </div>    




        
                 
        <div className={styles.infoData}>
            Com valor: {StringMask( selectedSale.curPrice.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' )}  
        </div>    
        {true && <div className={styles.infoData}>
            <p>Efetuado por: {StringMask( selectedSale.value.toFixed( 2 ), { type:'2DigitFraction' }, 'R$ ' )}</p>  {isValueOpen ? <i className="fa fa-times" onClick={()=>setIsValueOpen(false)}/> : <i onClick={()=>setIsValueOpen(true)} className="fa fa-pen"/> }
        </div>     }
        {selectedSale.recurrenceMonths===1 && selectedSale.installments && <>
            <div className={styles.infoData}>
                Quantidade: {selectedSale.quantity} 
            {isQuantityOpen ? <i className="fa fa-times" onClick={()=>setIsQuantityOpen(false)}/> : <i onClick={()=>setIsQuantityOpen(true)} className="fa fa-pen"/>}
            </div> {isQuantityOpen && <div>Nova Quantidade:   
                <input disabled={isLoading} value={newQuantity} onChange={e=>setNewQuantity(e.target.value)}/>    
        </div> } </>} 




        {isValueOpen && <div>Novo Valor Efetuado:   
                <input disabled={isLoading} value={StringMask( newValue, { type:'2DigitFraction' }, '' )} onChange={e=>setNewValue(StringMask(e.target.value, { type:'2DigitFraction' }, '' ))}/>    
        </div> }
        
        {selectedSale.recurrenceMonths===1 && selectedSale.installments && <>
            <div className={styles.infoData}>
                Numero de Parcelas: {selectedSale.installments}x
            {isInstallmentsOpen ? <i className="fa fa-times" onClick={()=>setIsInstallmentsOpen(false)}/> : <i onClick={()=>setIsInstallmentsOpen(true)} className="fa fa-pen"/>}
            </div> {isInstallmentsOpen && <div>Novo Parcelamento Efetuado:   
                <input disabled={isLoading} value={newInstallments} onChange={e=>setNewInstallments(e.target.value)}/>    
        </div> } </>} 


        <div className={styles.infoData}>
            <p>Com a observação: {selectedSale.notes}</p>  {isObsOpen ? <i className="fa fa-times" onClick={()=>setIsObsOpen(false)}/> : <i onClick={()=>setIsObsOpen(true)} className="fa fa-pen"/> }
        </div>    





        {isObsOpen && <div>Nova Observação:   
                <input disabled={isLoading} value={newObservations} onChange={e=>setNewObservations(e.target.value)}/>    
        </div> }
        </div>
        {!isLoading && <>
        
        <div className={styles.button} onClick={editSale   }>
            Confirmar  
        </div>        
        
        
        <div className={styles.closeButton} onClick={closeFn}>
            Fechar  
        </div>    

        
        
        
        </>}

        {isLoading && <div className={styles.button}>
            Carregando...
        </div>}
        
    </div>
     }
    
    
    </div>
  )
}

export default EditSale