import React from 'react'
import styles from './EditRevenuePopUp.module.sass'
import { useState } from 'react'
import { StringMask } from '../../../library/StringMasks'
import UserStore from '../../../../store/userStore'
import axios from 'axios'
const EditRevenuePopUp = ({product, closeFn, setLoadingScreen, setCardReload, newProduct}) => {
    const [id, setId] = useState(product.product)
    const [productName, setProductName] = useState(product.name)
    const [price, setPrice] = useState(!!product.price ? (StringMask( product.value, { type:'2DigitFraction' }, '' )) : 0)
    const [importance, setImportance] = useState(product.importance)
    const userId = UserStore.getUserId()
    const token = UserStore.getToken()
    console.log(product.importance)
    


    const importanceOptions = [
        {id: 0, bgColor: '#E42A2A', color: '#ffffff', title: 'Muito Alta'}, 
        {id: 1, bgColor: '#F47B20', color: '#ffffff', title: 'Alta'}, 
        {id: 2, bgColor: '#DFB51C', color: '#ffffff', title: 'Normal'}, 
        {id: 3, bgColor: '#08BF46', color: '#ffffff', title: 'Baixa'}
    ]
    const [selectedImportance, setSelectedImportance] = useState(importanceOptions.find(option=>product.importance===option.id))

    const confirmEditProduct = async() =>{
        setLoadingScreen(true)
        if (!newProduct) {
            console.log(selectedImportance)
            await axios.put( 'https://3axebc7hd5jch3aq5allprdqw40inbvq.lambda-url.us-east-2.on.aws/', { product: id.toString(), company: userId, token: token, name: productName, value: StringMask( price, { type:'float' }, '' ), importance: selectedImportance.id } )
            .then(resp=>{closeFn(); setLoadingScreen(false); setCardReload(prev => !prev)})
            .catch(error => setLoadingScreen(false));
                    
        } else {
            await axios.post( 'https://6femnjigs57o2ddvvoxa43nwu40mnfcj.lambda-url.us-east-2.on.aws/', { company: userId, token: token, name: productName, value: StringMask( price, { type:'float' }, '' ), importance: selectedImportance.id } )
            .then(resp=>{closeFn(); setLoadingScreen(false); setCardReload(prev => !prev)})
            .catch(error=>setLoadingScreen(false));

        }



        
    }

  return (
    <div className={styles.editRevenuePopUp}>
        <div className={styles.content}>
            <div className={styles.top}>
                <p>Editar Produto</p>
                <span onClick={closeFn}>x</span>
            </div>

            <div className={styles.middle}>
                <div className={styles.input}> 
                    <p>Produto</p>
                    <input type="text" value={productName} onChange={(e)=>setProductName(e.target.value)}/>
                </div>
                <div className={styles.input}> 
                    <p>Valor</p>
                    <input type="text" value={price} onChange={(e)=>setPrice(StringMask( e.target.value, { type:'2DigitFraction' }, '' ))}/>
                </div>
                <div className={styles.input}> 
                    <p>Importância</p>
                    <select onChange={e=>setSelectedImportance(importanceOptions.find(option=>Number(option.id)===Number(e.target.value)))}>
                        {importanceOptions && importanceOptions.map(option=><option value={option.id} selected={product.importance===option.id && 'selected'}>{option.title}</option>)}



                    </select>
                </div>    




                <div className={styles.bottom}>
                    <span className={styles.left} onClick={confirmEditProduct}>Confirmar</span>
                    <span className={styles.right} onClick={closeFn}>Fechar</span>
                </div>            
            </div>

        </div>



    </div>
  )
}

export default EditRevenuePopUp