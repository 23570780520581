import React from 'react'
import styles from './TutorialPopUp.module.sass'

const TutorialPopUp = ({text, buttonText, onClick}) => {
  return (
    <div className={styles.tutorialPopUp}>

        <div className={styles.background}></div>
        <p>{text}</p>
        <button onClick={onClick}>{buttonText}</button>
    </div>        
  )
}

export default TutorialPopUp