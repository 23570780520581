/* eslint-disable no-lone-blocks */
/* eslint-disable default-case */
import React, {useState} from "react";
import "../journey/JourneyKnowingYourModel.css";
import { IconExpensesImg, IconRevenuesImg, IconCommunicationImg, IconDeliveriesImg, IconRelashionshipImg, IconActivitiesImg, IconProductsImg, IconBeginImg } from '../imgsLoader/LoadIconImgs';
import { IconPartnersImg, IconCompetitorsImg, IconMarketImg, IconNeedsImg, IconPainImg, IconProblemImg, IconTargetImg, IconValueImg } from '../imgsLoader/LoadIconImgs';
import PainQuestions from "../questions/painQuestions";
import MarketQuestions from "../questions/marketQuestions.js"
import ActivitiesQuestions from "../questions/activitiesQuestions";
import CommunicationQuestions from "../questions/communicationsChannelsQuestions.js"
import CompetitorsQuestions from "../questions/competitorsQuestions";
import DeliveryQuestions from "../questions/deliveryChannelsQuestions.js"
import PartnersQuestions from "../questions/partnersQuestions";
import ProductCharacteristicsQuestions from "../questions/productCharacteristicsQuestions.js"
import RelationshipQuestions from "../questions/relationshipChannelQuestions";
import ResourcesQuestions from "../questions/resourcesQuestions.js"
import SalesQuestions from "../questions/salesChannelQuestions";
import PublicQuestions from "../questions/targetPublic.js"
import ValuePropositionQuestions from "../questions/valuePropositionQuestions.js"


const JourneyMyAnswers =() =>{

    const [popUp, setPopUp] = useState('');  

    const closePopUp =()=>{
        
            setPopUp(<div></div>)
        
    }

    const showQuestions =(question)=>{
        switch(question){
            case 'pain':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <PainQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'market':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <MarketQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'public':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <PublicQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'competitors':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <CompetitorsQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'activities':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <ActivitiesQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'communication':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <CommunicationQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'product':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <ProductCharacteristicsQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'relationship':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <RelationshipQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'resources':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <ResourcesQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'sales':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <SalesQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'delivery':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <DeliveryQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'value':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <ValuePropositionQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
            case 'partners':{
                setPopUp(
                    <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                        <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF'}} onClick={(event) => event.stopPropagation()}>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'1rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                            <PartnersQuestions checkChanges="-1" activeConfirmScreen="" redirect="" disableConfirmScreen=""/>
                        </div>
                    </div>
                )
                
            }
            break;
        }
    }

    return(

            <div style={{width: '100%'}}>
                <div style={{display:'flex', flexDirection:'column', gap:'2rem', marginTop:'1rem', alignItems: 'center'}}>
                    <div className="JourneyMyAnswersCards" style={{display:'flex', gap:'2rem' ,justifyContent:'center'}}>
                        <div style={{display:'flex', flexDirection:'column', backgroundColor:'#FFFFFF', borderRadius:'0.8rem',width:'19rem', height:'23rem'}}>
                            <div style={{display:'flex',backgroundColor:'#251F53',height:'5rem',borderRadius:'0.8rem 0.8rem 0rem 0rem', alignItems:'center', justifyContent:'center'}}>
                                <text className="journeyAnswersTitle" style={{}}>Para quem</text>
                            </div>
                            <div style={{display:'flex', flexDirection:'column', gap:'1.2rem', marginTop:'1rem', marginLeft:'1.5rem',padding:'1rem 0.5rem 1rem 0.5rem'}}>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconPainImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('pain')}>Dor, Problema e Necessidade</text>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconMarketImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('market')}>Mercado</text>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconTargetImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('public')}>Público alvo</text>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconCompetitorsImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('competitors')}>Concorrência</text>
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', backgroundColor:'#FFFFFF', borderRadius:'0.8rem',width:'19rem', height:'23rem'}}>
                            <div style={{display:'flex',backgroundColor:'#251F53',height:'5rem',borderRadius:'0.8rem 0.8rem 0rem 0rem', alignItems:'center', justifyContent:'center'}}>
                                <text className="journeyAnswersTitle" style={{}}>O que</text>
                            </div>
                            <div style={{display:'flex', flexDirection:'column', gap:'1.2rem', marginTop:'1rem', marginLeft:'1.5rem',padding:'1rem 0.5rem 1rem 0.5rem'}}>
                            <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconProductsImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('product')}>Produtos ou Serviços</text>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconValueImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('value')}>Proposta de Valor</text>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconCommunicationImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('communication')}>Canais de comunicação</text>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconExpensesImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('sales')}>Canais de Venda</text>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconDeliveriesImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('delivery')}>Canais de Entrega</text>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconRelashionshipImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('relationship')}>Relacionamento</text>
                                </div>
                            </div>
                        </div>    
                        <div style={{display:'flex', flexDirection:'column', backgroundColor:'#FFFFFF', borderRadius:'0.8rem',width:'19rem', height:'23rem'}}>
                            <div style={{display:'flex',backgroundColor:'#251F53',height:'5rem',borderRadius:'0.8rem 0.8rem 0rem 0rem', alignItems:'center', justifyContent:'center'}}>
                                <text className="journeyAnswersTitle" style={{}}>Como</text>
                            </div>
                            <div style={{display:'flex', flexDirection:'column', gap:'1.2rem', marginTop:'1rem', marginLeft:'1.5rem',padding:'1rem 0.5rem 1rem 0.5rem'}}>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconActivitiesImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('activities')}>Atividades</text>
                                </div>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconPartnersImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('partners')}>Parceiros</text>
                                </div>
                            </div>
                        </div> 
                        <div style={{display:'flex', flexDirection:'column', backgroundColor:'#FFFFFF', borderRadius:'0.8rem',width:'19rem', height:'23rem'}}>
                            <div style={{display:'flex',backgroundColor:'#251F53',height:'5rem',borderRadius:'0.8rem 0.8rem 0rem 0rem', alignItems:'center', justifyContent:'center'}}>
                                <text className="journeyAnswersTitle" style={{}}>Recursos</text>
                            </div>
                            <div style={{display:'flex', flexDirection:'column', gap:'1.2rem', marginTop:'1rem', marginLeft:'1.5rem',padding:'1rem 0.5rem 1rem 0.5rem'}}>
                                <div style={{display:'flex', flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                                    <img src={IconExpensesImg} alt='' style={{width:'1.2rem',height:'1.2rem'}}/>
                                    <text className='journeyIntroductionLinksText' style={{fontSize:'1.2rem', cursor:'pointer'}} onClick={()=>showQuestions('resources')}>Recursos</text>
                                </div>
                            </div>
                        </div>                       
                    </div>
                </div>
                {
                    popUp
                }
            </div>            
        
    )
}

export default JourneyMyAnswers;
