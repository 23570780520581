import React from 'react'
import styles from './SupportIcon.module.sass'

const SupportIcon = () => {

  const redirectToSupport = () =>{
    window.open('https://wa.me/5551981519574?text=Estou+precisando+de+suporte+na+MEconecte') 
  }


  return (
    <div className={styles.support} onClick={redirectToSupport}>
      <i className="fa fa-headset"/> 
    </div>
  )
}

export default SupportIcon