import React from 'react'
import styles from './FirstAccess.module.sass'

import Background from '../../assets/new_background.png'
import TutorialPopUp from './tutorialPopUp/TutorialPopUp'
import axios from 'axios'
import UserStore from '../../store/userStore'

const FirstAccess = ({watchLaterFunction, initTutorialFunction}) => {



    const watchLater = () =>{

        watchLaterFunction()

    }
    const watched = () =>{
        initTutorialFunction()
        
    }




  return (
    <div className={styles.firstAccess}>
      <img src={Background} alt="background"/>
      <div className={styles.lightPointTop}></div>
      <div className={styles.lightPointBottom}></div>
      <button className={styles.backOption} onClick={watchLater}>Voltar</button>


      <div className={styles.content}>
        <div className={styles.introductionMessage}>  
          <p>Bem-vindo,</p>
          <span>{UserStore.getUserName()}</span>
        </div>    
        <TutorialPopUp text={`Descubra a plataforma revolucionária da MEconecte e dê vida às suas ideias empreendedoras.`} buttonText={`Começar`} onClick={watched}/>
      </div>

    </div>
  )
}

export default FirstAccess