import React from 'react';
import Select from 'react-select'
import '@coreui/coreui/dist/css/coreui.min.css'
import { useState, useEffect } from 'react';
import UserStore from '../../store/userStore';
import axios from 'axios';

const monthNames = [ 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro' ];
let selectedSemester = 1;
let selectedYear = 0;
let growth = [];
let changedValues = [];

const Growth = () =>
{
    const [ tableGrowth, setTableGrowth ] = useState( [] );
    const [ loadingGrowthList, setLoadingGrowthList ] = useState( true );
    const [ years, setYears ] = useState( [ { label: 'Carregando', value: -1 } ] );
    const [ markedSelectYear, setMarkedSelectYear ] = useState( { label: 'Carregando', value: -1 } );
    const [ screenDebounceTime, setScreenDebounceTime ] = useState( true );

    const emptyYearGrowthWarn = 
        <tr>
            <td colSpan={ 9 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
                Você não possui nenhum produto cadastrado nesse ano.<br/>
                Por favor, verifique se os produtos aparecem em anos que eles já haviam sido cadastrados.
            </td>
        </tr>;

    const emptyPoductsGrowthWarn = 
        <tr>
            <td colSpan={ 9 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
                Você não possui produtos cadastrados.<br/>
                Por favor, prossiga para a aba 'Receitas' acima e adicione os produtos do seu negócio.
            </td>
        </tr>;

    const loadingTableGrowthWarn = 
        <tr>
            <td colSpan={ 9 } style={ { textAlign: 'center', fontSize: '15px', fontWeight: 'normal' } }>
                Por favor aguarde, carregando lista de produtos.
            </td>
        </tr>;

    const handleSelectChange = ( event ) => 
    {
        if( event.value !== -1 )
        {
            selectedYear = event.value;
            setMarkedSelectYear( event );
            buildTableContent();
        }
    }

    const fillGrowthList = async() =>
    {
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        let availableYears = [];

        axios.get( 'https://nwzumzd5qiuqwhsoxgkttxf7oa0koaom.lambda-url.us-east-2.on.aws/', { params: { company: userId, token: token } } ).then( ( resp ) =>
        {
            if( resp.data.products )
            {
                for( let index = 0; index < resp.data.products.length; index++ )
                {
                    let productGrowth = resp.data.products[ index ];
                    let growthOnMonth = [];

                    growthOnMonth.push( productGrowth.jan );
                    growthOnMonth.push( productGrowth.feb );
                    growthOnMonth.push( productGrowth.mar );
                    growthOnMonth.push( productGrowth.apr );
                    growthOnMonth.push( productGrowth.may );
                    growthOnMonth.push( productGrowth.jun );
                    growthOnMonth.push( productGrowth.jul );
                    growthOnMonth.push( productGrowth.aug );
                    growthOnMonth.push( productGrowth.sep );
                    growthOnMonth.push( productGrowth.oct );
                    growthOnMonth.push( productGrowth.nov );
                    growthOnMonth.push( productGrowth.dez );

                    let productToAdd = { product: productGrowth.product, name:productGrowth.name, month: growthOnMonth };
                    let yearIndex = growth.findIndex( ( product ) => { return product.year === productGrowth.year } );
                    
                    if( yearIndex === -1 )
                    {
                        availableYears.push( { label: productGrowth.year.toString(), value: productGrowth.year } );
                        growth.push( { year: productGrowth.year, products: [ productToAdd ] } );
                    }
                    else
                    {
                        growth[ yearIndex ].products.push( productToAdd );
                    }
                }
            }

            if( availableYears.length > 0 )
            {
                setYears( availableYears );
                setMarkedSelectYear( availableYears[ 0 ] );
                selectedYear = availableYears[ 0 ].value;
            }
            else
            {
                setYears( [ { label: '---', value: -1 } ] );
                setMarkedSelectYear( { label: '---', value: -1 } );
            }

            buildTableContent();
            setLoadingGrowthList( false );
        } );
    }

    const checkUpdateError = ( currentValue ) =>
    {
        if( currentValue !== 0 )
        {
            let yearIndex = growth.findIndex( ( product ) => { return product.year === selectedYear } );
            
            if( yearIndex !== -1 )
            {
                let productIndex =  growth[ yearIndex ].products.findIndex( ( product ) => { return product.product === currentValue } );

                if( productIndex !== -1 )
                {
                    alert( 'Erro ao editar produto ' + growth[ yearIndex ].products[ productIndex ].name + ', por favor tente novamente mais tarde.' );
                }
            }
        }
    }

    const confirmEdition = async() =>
    {
        let userId = UserStore.getUserId();
        let token = UserStore.getToken();

        if( changedValues.length > 0 )
        {
            const resp = await axios.put( 'https://6kihtz4letylt23oowrkh55wwi0ydtnz.lambda-url.us-east-2.on.aws/', { params: { company: userId, token: token, toUpdate: changedValues } } );

            if( resp.data.length > 0 )
            {
                resp.data.forEach( ( currentValue ) => checkUpdateError( currentValue ) );
                changedValues = [];
                buildTableContent();
            }
            else
            {
                alert( 'Erro no servidor. Tente novamente mais tarde.' )
            }
        }
    }

    const modifyGrowth = ( product, month, direction ) =>
    {
        const yearIndex = growth.findIndex( ( yearData ) => { return yearData.year === selectedYear; } );
        
        if( yearIndex !== -1 )
        {
            const productIndex = growth[ yearIndex ].products.findIndex( ( productData ) => { return productData.product === product; } );

            if( productIndex !== -1 )
            {
                const changedValueIndex = changedValues.findIndex( ( changed ) => { return ( changed.product === product ) && ( changed.year === selectedYear ) } );

                growth[ yearIndex ].products[ productIndex ].month[ month ] += direction * 5;
                buildTableContent();

                if( changedValueIndex !== -1 )
                {
                    changedValues[ changedValueIndex ].month[ month ] = growth[ yearIndex ].products[ productIndex ].month[ month ];
                }
                else
                {
                    let auxMonthArray = [ null, null, null, null, null, null, null, null, null, null, null, null ];

                    auxMonthArray[ month ] = growth[ yearIndex ].products[ productIndex ].month[ month ];
                    changedValues.push( { product: product, year: selectedYear, month: auxMonthArray } );
                }
            }
        }
    }

    const buildTableContent = () =>
    {
        const ModelGrowthTableHeader = document.getElementsByName( "ModelGrowthTable" )[ 0 ].childNodes[ 0 ].childNodes[ 0 ];
        const selectedYearGrowth = growth.find( ( yearData ) => { return yearData.year === selectedYear; } );
        const monthOffset = ( selectedSemester === 2 ) ? 6 : 0;
        let   tableContent = [];
        
        ModelGrowthTableHeader.childNodes[ 2 ].childNodes[ 0 ].textContent = monthNames[ monthOffset + 0 ];
        ModelGrowthTableHeader.childNodes[ 3 ].childNodes[ 0 ].textContent = monthNames[ monthOffset + 1 ];
        ModelGrowthTableHeader.childNodes[ 4 ].childNodes[ 0 ].textContent = monthNames[ monthOffset + 2 ];
        ModelGrowthTableHeader.childNodes[ 5 ].childNodes[ 0 ].textContent = monthNames[ monthOffset + 3 ];
        ModelGrowthTableHeader.childNodes[ 6 ].childNodes[ 0 ].textContent = monthNames[ monthOffset + 4 ];
        ModelGrowthTableHeader.childNodes[ 7 ].childNodes[ 0 ].textContent = monthNames[ monthOffset + 5 ];

        if( selectedYearGrowth !== undefined )
        {
            for( let iProduct = 0; iProduct < selectedYearGrowth.products.length; iProduct++ )
            {
                let productsList = [];

                for( let iMonth = 0; iMonth < 6; iMonth++ )
                {
                    const product = selectedYearGrowth.products[ iProduct ].product;

                    productsList.push(
                        <td key={ product + '' + iMonth }>
                            <div className='ModelGrowthPercValuesBox'>
                                <div className='ModelGrowthPercValuesBoxLeft' >-</div>
                                <div className='ModelGrowthPercValuesBoxValue'>{ selectedYearGrowth.products[ iProduct ].month[ monthOffset + iMonth ] }%</div>
                                <div className='ModelGrowthPercValuesBoxRight' >+</div>
                            </div>
                        </td>
                    );
                }

                tableContent.push(
                    <tr key={ selectedYearGrowth.products[ iProduct ].product }>
                        <td colSpan={ 2 }>{ selectedYearGrowth.products[ iProduct ].name }</td>
                        { productsList }
                        <td></td>
                    </tr>
                );
            }
        }

        setTableGrowth( tableContent );
    }

    const changeSemester = () =>
    {
        selectedSemester = ( selectedSemester % 2 ) + 1;
        buildTableContent();
    }

    useEffect( () => { buildTableContent(); fillGrowthList(); setTimeout( () => { setScreenDebounceTime( false ); }, 1000 ); }, [] );

    return(
        <div className='FullBoardContent'>
            {/* <div className='BoardTitleBtnsInline'><div className="BoardBtnOk" onClick={ confirmEdition }>Confirmar valores ajustados</div></div> */}
            <div style={ { float: 'right', height: '30px', marginTop: '6px', marginRight: '4px' } }>
                <div className='BoardHeaderSelectorTxt'>Apresentando o ano de:</div>
                <Select className='BoardHeaderSelector' options={ years } value={ markedSelectYear } onChange={ ( event ) => handleSelectChange( event ) } />
            </div>
            <div className="FullBoardContentTitle">Crescimento</div>
            <div id="NumberOfOptionsId" className="FullBoardContentSubTitle">
                Aqui é possível configurar as metas de crescimento dos seus produtos mês a mês.<br/>
                Isso irá impactar na quantidade de produtos a serem vendidos no seu plano de ação.<br/>
                Utilize "+"/"-" para alterar o crescimento desejado do mês, e "ajustar" para alterar o semestre.<br/>
                *É recomendado que o crescimento não exceda 5% ao mês.<br/>
                *Funcionalidade a ser liberada
            </div>
            <div className='HorizontalLineSeparator'/>
            <div style={ { height:'100%' } }>
                <table name='ModelGrowthTable' className="ModelGrowthTable">
                    <thead>
                        <tr>
                            <th>Produto</th>
                            <th><div className='SimpleBtn' onClick={ changeSemester }>{ '<' }</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='ModelGrowthTableHeader'>...</div></th>
                            <th><div className='SimpleBtn' onClick={ changeSemester }>{ '>' }</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        { ( screenDebounceTime || loadingGrowthList ) ? loadingTableGrowthWarn : ( tableGrowth.length !== 0 ) ? tableGrowth : ( growth.length !== 0 ) ? emptyYearGrowthWarn : emptyPoductsGrowthWarn }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Growth;