import React from 'react';
import UserSideBar from '../components/mainTemplate/UserSideBar';
import ModelRec from '../components/modelRecomendation/ModelRec';
import UserBoardHeader from '../components/mainTemplate/UserBoardHeader';
import Model from '../components/modelRecomendation/Model'
import Dashboard from '../components/dashboard/Dashboard'
import CashFlow from '../components/cashflow/CashFlow';
import Revenues from '../components/cashflow/Revenues';
import Expenses from '../components/cashflow/Expenses';
import Taxes from '../components/cashflow/Taxes';
import Growth from '../components/cashflow/Growth';
import Traction from '../components/cashflow/Traction';
import ActionPlan from '../components/actionPlan/ActionPlan';
import JourneyForWhomQuestions from '../components/journey/JourneyForWhomQuestions';
import JourneyPublicVideo from '../components/journey/JourneyPublicVideo';
import JourneyPublicQuestions from '../components/journey/JourneyPublicQuestions';
import JourneyPublicFurtherMaterials from '../components/journey/JourneyPublicFurtherMaterials';
import JourneyKYMWhat from '../components/journey/JourneyKYMWhat';
import JourneyWhatVideo from '../components/journey/JourneyWhatVideo';
import JourneyValuePropositionVideo from '../components/journey/JourneyValuePropositionVideo';
import JourneyValuePropositionQuestions from '../components/journey/JourneyValuePropositionQuestions';
import JourneyValuePropositionFurtherKnowledge from '../components/journey/JourneyValuePropositionFurtherKnowledge';
import JourneyProductAndServiceVideo from '../components/journey/JourneyProductAndServiceVideo';
import JourneyProductAndServiceQuestions from '../components/journey/JourneyProductAndServiceQuestions';
import JourneyKYMHow from '../components/journey/JourneyKYMHow';
import JourneyHowVideo from '../components/journey/JourneyHowVideo';
import JourneyActivitiesVideo from '../components/journey/JourneyActivitiesVideo';
import JourneyKYMResources from '../components/journey/JourneyKYMResources';
import JourneyResourcesVideo from '../components/journey/JourneyResourcesVideo';
import JourneyResourcesQuestions from '../components/journey/JourneyResourcesQuestions';
import JourneyResourcesFurtherMaterials from '../components/journey/JourneyResourcesFurtherMaterials';
import JourneyIntroduction from '../components/journey/JourneyIntroduction';
import JourneyIntroductionVideo from '../components/journey/JourneyIntroductionVideo';
import JourneyIntroductionOurJourneyVideo from '../components/journey/JourneyIntroductionOurJourneyVideo';
import JourneyIntroductionFurtherMaterials from '../components/journey/JourneyIntroductionFurtherMaterials';
import JourneyKYMForWhom from '../components/journey/JourneyKYMForWhom';
import JourneyForWhomVideoo from '../components/journey/JourneyForWhomVideo';
import JourneyMarketVideo from '../components/journey/JourneyMarketVideo';
import JourneyForWhomFurtherMaterials from '../components/journey/JourneyForWhomFurtherMaterials';
import JourneyMarketQuestions from '../components/journey/JourneyMarketQuestions';
import JourneyMarketFurtherMaterials from '../components/journey/JourneyMarketFurtherMaterials';
import JourneyCompetitorsVideo from '../components/journey/JourneyCompetitorsVideo';
import JourneyCompetitorsQuestions from '../components/journey/JourneyCompetitorsQuestions';
import JourneyCompetitorsFurtherMaterials from '../components/journey/JourneyCompetitorsFurtherMaterials';
import JourneyDistributionChannelVideo from '../components/journey/JourneyDistributionChannelVideo';
import JourneyCommunicationChannelVideo from '../components/journey/JourneyCommunicationChannelVideo';
import JourneyCommunicationChannelQuestions from '../components/journey/JourneyCommunicationChannelQuestions';
import JourneyCommunicationChannelFurtherMaterials from '../components/journey/JourneyCommunicationChannelFurtherMaterials';
import JourneySalesChannelVideo from '../components/journey/JourneySalesChannelsVideo';
import JourneySalesChannelsQuestions from '../components/journey/JourneySalesChannelsQuestions';
import JourneyDeliveryChannelVideo from '../components/journey/JourneyDeliveryChannelVideo';
import JourneyDeliveryChannelQuestions from '../components/journey/JourneyDeliveryChannelQuestions';
import JourneyRelationshipChannelVideo from '../components/journey/JourneyRelationshipChannelVideo';
import JourneyRelationshipChannelsQuestions from '../components/journey/JourneyRelationshipChannelQuestions';
import JourneyRelationshipChannelFurtherMaterials from '../components/journey/JourneyRelationshipChannelFurtherMaterials';
import JourneyActivitiesQuestions from '../components/journey/JourneyActivitiesQuestions';
import JourneyActivitesFurtherMaterials from '../components/journey/JourneyActivitesFurtherMaterials';
import JourneyPartnersVideo from '../components/journey/JourneyPartnersVideo';
import JourneyPartnersQuestions from '../components/journey/JourneyPartnersQuestions';
import JourneyPartnersFurtherMaterials from '../components/journey/JourneyPartnersFurtherMaterials';
import JourneyForWhomIntroductionVideo from '../components/journey/JourneyForWhomIntroductionVideo';
import MyAccount from '../components/settings/MyAccount';
import MyPlanAccount from '../components/settings/MyPlanAccount';
//import JourneyNewIntroduction from '../components/journey/JourneyIntroductionNew';



import JourneyNewIntroduction from '../components/newJourney/journeyNewIntroduction/JourneyNewIntroduction';
import JourneyMyAnswers from '../components/journey/JourneyMyAnswers';
import FamiliarCashFlow from '../components/cashflow/FamiliarCashFlow';
import JourneyForWhoModel from '../components/journey/JourneyForWhoModel';
import JourneyForWhatModel from '../components/journey/JourneyForWhatModel';
import JourneyForChannelModel from '../components/journey/JourneyForChannelModel';
import JourneyForHowModel from '../components/journey/JourneyForHowModel';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import UserStore from '../store/userStore';
import IntroductionVideo from '../components/introductionVideo/IntroductionVideo';
import "./RegisterPage.css?v=0.2.8";
import GetPremiumPlan from '../components/getPremiumPlan/GetPremiumPlan';
import VideosSection from '../components/videosSection/VideosSection';
import VideoTrailContainer from '../components/videosSection/videoCards/videoTrailContainer/VideoTrailContainer';
import TrailSection from '../components/trailSection/TrailSection';
import FirstAccess from '../components/firstAccess/FirstAccess';
import DefaultSandwichMenu from '../components/mainTemplate/defaultSandwichMenu/DefaultSandwichMenu';
import { ActionPlanIconImg, CashFlowIconImg, DashboardIconImg, IconCommunity, IconVideo, JourneyIconImg, ModelIconImg, SettingsIconImg } from '../components/imgsLoader/LoadIconImgs';
import VideoTrailSandwichMenu from '../components/mainTemplate/videoTrailSandwichMenu/VideoTrailSandwichMenu';
import Community from '../components/community/Community';
import PlataformGuide from '../components/plataformGuide/PlataformGuide';
import SelectModel from '../components/selectModel/SelectModel';
import styles from '../components/mainTemplate/defaultSandwichMenu/DefaultSandwichMenu.module.sass'
    
import NewJourney from '../components/newJourney/NewJourney';
import NewModelRecomendation from '../components/newModelRecomendation/NewModelRecomendation';
import Clients from '../components/clients/Clients';
  
import { Route, RouterProvider, createBrowserRouter } from 'react-router-dom'
import Cashflow from '../components/cashflow/CashFlow';
import Monthcashflow from '../components/cashflow/monthcashflow/Monthcashflow';
import GetPlan from './getPlan/GetPlan';

const UserBoard = ( props ) => {


    const [isSandwichMenuClosed, setIsSandwichMenuClosed] = useState(true)
    const changeSandwichMenuState = (close) =>{

            setIsSandwichMenuClosed(prevState=>!prevState)  


    }

    
    const [appearPlanBanner, setAppearPlanBanner] = useState(false)
    const [planDays, setPlanDays] = useState(31)
    const upgradePlanLink = 'https://sun.eduzz.com/1745888'
  useEffect( ()=>{ 
    const getExpireDate = async() =>{

        await axios.get('https://iuigcj7jka7bblez3rm557uevi0zdgvl.lambda-url.us-east-2.on.aws/', {params:{ company: UserStore.getUserId(), token: UserStore.getToken()}})
        .then(resp=>{
            if (resp.data) {console.log(resp);console.log(resp);
                const localPlanDays = resp.data.products[0].restOfPlanDays
                if (Number(UserStore.getUserPlanDays())!==localPlanDays || localPlanDays<=0) {
                                setPlanDays(localPlanDays)  

                                UserStore.setUserPlanDays(localPlanDays)
                                setAppearPlanBanner(true)
                }



            }
            }
        )  
    }
    getExpireDate()

}, [])
    const MobileUpgradePlanBanner = () =>
    {
        return(
            <div className={styles.mobilePlanData}>
                <div className={styles.mobilePlanDataContent}>
                    {(planDays<=1 && planDays>0) ? <p>Seu Plano expira em {planDays} dia</p> : (planDays>0)? <p>Seu plano expira em {planDays} dias</p> : <p>Seu plano expirou</p>}
                    <span onClick={()=>{setAppearPlanBanner(false)}}>X</span>



                </div>
                <div className={styles.mobilePlanDataContent}> 
                    <a target="blank" href={upgradePlanLink} className={styles.mobilePlanDataContentButton}>Fazer Upgrade</a>
                </div>
            </div>   
        )     
    }    
    const closeSandwichMenu = () =>{
        setIsSandwichMenuClosed(true)



    }

    //TODO: Pagina inicial por padrão
    //TODO: Criar enums para paginas
    const [access, setAccess] = useState(UserStore.getUserFirstAccess());
    const [hideIntro, setHideIntro] = useState(UserStore.getHideIntro());





    useEffect(()=>{
        const getAccess = async() =>{

        
            await axios.get('https://cy7oh4mxbivpv7mggnhyj5g4zi0xlduw.lambda-url.us-east-2.on.aws/', {params:{ company: UserStore.getUserId(), token: UserStore.getToken()}})
            .then(resp=>{

                console.log(resp.data.products[0]);
                setAccess(/**/resp.data.products[0].haveWatchedIntro)  
                console.log('test');console.log(hideIntro)
                if(Number(hideIntro)===0){

                    console.log('test')
                    setHideIntro(/**/resp.data.products[0].hideIntro)  
                }   
                UserStore.setUserFirstAccess(resp.data.products[0].haveWatchedIntro)
                console.log(resp.data.products[0].haveWatchedIntro)
                console.log('AAAA')
                console.log(resp.data.products)
                const userName = resp.data.products[0].name
                const name = userName.charAt(0).toUpperCase() + userName.substring(1)

                !resp.data.products[0].haveWatchedIntro && UserStore.setUserName(name)
                }
            )

        }
        getAccess()
    }, [])




    let userContent = <Model/>;
    let selectedSideMenu = '';
const discoverSelectedMenu = () => {
    console.log(window.location.pathname);
    switch (window.location.pathname) {
        case '':
        case '/newmodelrec':
        case '/modelrec':
            UserStore.setSelectedMenuStore('modelrec');
            return 'modelrec';
        case '/dashboard':
            UserStore.setSelectedMenuStore('dashboard');
            return 'dashboard';
        case '/familiarcashflow':
        case '/cashflow':
        case '/revenues':
        case '/expenses':
        case '/taxes':
        case '/growth':
        case '/traction':
        case '/monthcashflow':
            UserStore.setSelectedMenuStore('cashflow');
            return window.location.pathname.substring(1); // remove leading '/'
        case '/actionplan':
        case '/clients':
            UserStore.setSelectedMenuStore('actionplan');
            return window.location.pathname.substring(1); // remove leading '/'
        case '/journey':
        case '/journey/forwho':
        case '/journey/forwhat':
        case '/journey/forchannel':
        case '/journey/forhow':
        case '/journey/myanswer':
        case '/newjourney':
        case '/getpremium':
            UserStore.setSelectedMenuStore('journey');
            return window.location.pathname.substring(1); // remove leading '/'
        case '/videoSection':
        case '/videoTrail':
        case '/videoTrail/introduction':
        case '/videoTrail/businessTips':
            UserStore.setSelectedMenuStore('videoSection');
            return window.location.pathname.substring(1); // remove leading '/'
        case '/community':
            UserStore.setSelectedMenuStore('community');
            return window.location.pathname.substring(1); // remove leading '/'
        case 'settings':
            // What should happen in case of 'settings'?
            break;
        default:
            //TODO: Pagina inicial por padrão
            UserStore.setSelectedMenuStore('cashflow');
            return 'monthcashflow';
    }
};
const discoverSelectedPage = () => {
    console.log(window.location.pathname);
    switch (window.location.pathname) {
        case '':
        case '/newmodelrec':
        case '/modelrec':
            return 'modelrec';
        case '/dashboard':
            return 'dashboard';
        case '/familiarcashflow':
        case '/cashflow':
        case '/revenues':
        case '/expenses':
        case '/taxes':
        case '/growth':
        case '/traction':
        case '/monthcashflow':
            return window.location.pathname.substring(1); // remove leading '/'
        case '/actionplan':
        case '/clients':
            return window.location.pathname.substring(1); // remove leading '/'
        case '/journey':
        case '/journey/forwho':
        case '/journey/forwhat':
        case '/journey/forchannel':
        case '/journey/forhow':
        case '/journey/myanswer':
        case '/newjourney':
        case '/getpremium':
            return window.location.pathname.substring(1); // remove leading '/'
        case '/videoSection':
        case '/videoTrail':
        case '/videoTrail/introduction':
        case '/videoTrail/businessTips':
            return window.location.pathname.substring(1); // remove leading '/'
        case '/community':
            return window.location.pathname.substring(1); // remove leading '/'
        case 'settings':
            // What should happen in case of 'settings'?
            break;
        default:
            //TODO: Pagina inicial por padrão
            return 'monthcashflow';
    }
};
    const [newSelectedSideMenu, setNewSelectedSideMenu] = useState(discoverSelectedMenu())

    const [newSelectedPage, setNewSelectedPage] = useState(discoverSelectedPage())
    const firstAccess = () =>{
         return <ModelRec/>
         //return((access===1) ? <ModelRec/> : (access===0)? <FirstAccess/> : <></>)


    }






























































    /*const router = createBrowserRouter([ 
        {
            path: '/',
            element: firstAccess()
        },   
        {
            path: '/newmodelrec',
            element: <NewModelRecomendation/>  
        },         
        {
            path: '/modelrec',
            element: <ModelRec/>  
        },        
        {
            path: '/dashboard',
            element: <Dashboard/>  
        },        
        {
            path: '/familiarcashflow',
            element: <FamiliarCashFlow/>  
        },        
        {
            path: '/cashflow',
            element: <Dashboard/>  
        },        
        {
            path: '/revenues',
            element: <Dashboard/>  
        },        
        {
            path: '/expenses',
            element: <Dashboard/>  
        },        
        {
            path: '/taxes',
            element: <Dashboard/>  
        },        
        {
            path: '/growth',
            element: <Dashboard/>  
        },        
        {
            path: '/traction',
            element: <Dashboard/>  
        },
        {
            path: '/actionplan',
            element: <Dashboard/>  
        },
        
        {
            path: '/journey',
            element: <Dashboard/>  
        },
        
        {
            path: '/newjourney',
            element: <Dashboard/>  
        },
        
        {
            path: '/modelsettings',
            element: <Dashboard/>  
        },
        
        {
            path: '/dashboard',
            element: <Dashboard/>
        },
        {
            path: '/dashboard',
            element: <Dashboard/>
        },




        {
            path: '/journey/myanswer',
            element: <JourneyMyAnswers/>,
            selectedSideMenu: 'journey'
        },
        {
            path: '/videoSection',
            element: <VideosSection
                isSandwichMenuClosed={isSandwichMenuClosed}
                changeSandwichMenuState={() => { changeSandwichMenuState() }}
                closeSandwichMenu={() => closeSandwichMenu()}
            />,
            selectedSideMenu: 'videoSection'
        },
        {
            path: '/videoTrail',
            element: <VideoTrailContainer/>,
            selectedSideMenu: 'videoSection'
        },
        {
            path: '/videoTrail/introduction',
            element: <TrailSection
                isSandwichMenuClosed={isSandwichMenuClosed}
                changeSandwichMenuState={() => { changeSandwichMenuState() }}
            />,
            selectedSideMenu: 'videoSection'
        },
        {
            path: '/videoTrail/businessTips',
            element: <TrailSection
                isSandwichMenuClosed={isSandwichMenuClosed}
                changeSandwichMenuState={() => { changeSandwichMenuState() }}
            />,
            selectedSideMenu: 'videoSection'
        },
        {
            path: '/community',
            element: <Community/>,
            selectedSideMenu: 'community'
        },
        {
            path: '/getpremium',
            element: <GetPremiumPlan/>,
            selectedSideMenu: 'journey'
        },
        {
            path: '/clients',
            element: <Clients/>,
            selectedSideMenu: 'modelrec'
        }
                
    
    ])*/

    const discoverPage = () =>{

        console.log(window.location.pathname)
    switch (window.location.pathname) {

      case '': 
        UserStore.setSelectedMenuStore('modelrec');
        UserStore.setSelectedPageStore('modelrec');
        break;
      case '/newmodelrec':
        UserStore.setSelectedMenuStore('modelrec');
        UserStore.setSelectedPageStore('modelrec');
        break;
      case '/modelrec':
        UserStore.setSelectedMenuStore('modelrec');
        UserStore.setSelectedPageStore('modelrec');
        break;
      case '/dashboard':
        UserStore.setSelectedMenuStore('dashboard');
        UserStore.setSelectedPageStore('dashboard');
        break;
      case '/familiarcashflow':
        UserStore.setSelectedMenuStore('cashflow');
        UserStore.setSelectedPageStore('familiarcashflow');
        break;
      case '/cashflow':
        UserStore.setSelectedMenuStore('cashflow');
        UserStore.setSelectedPageStore('cashFlow');
        break;
      case '/revenues':
        UserStore.setSelectedMenuStore('cashflow');
        UserStore.setSelectedPageStore('revenues');
        break;
      case '/expenses':
        UserStore.setSelectedMenuStore('cashflow');
        UserStore.setSelectedPageStore('expenses');
        break;
      case '/taxes':
        UserStore.setSelectedMenuStore('cashflow');
        UserStore.setSelectedPageStore('taxes');
        break;
      case '/growth':
        UserStore.setSelectedMenuStore('cashflow');
        UserStore.setSelectedPageStore('growth');
        break;
      case '/traction':
        UserStore.setSelectedMenuStore('cashflow');
        UserStore.setSelectedPageStore('traction');
        break;
      case '/actionplan':
        UserStore.setSelectedMenuStore('actionplan');
        UserStore.setSelectedPageStore('actionplan');
        break;
      case '/journey':
        UserStore.setSelectedMenuStore('journey');
        UserStore.setSelectedPageStore('journey');
        break;
      case '/journey/forwho':
        UserStore.setSelectedMenuStore('journey');
        UserStore.setSelectedPageStore('journey/forwho' );
        break;
      case '/journey/forwhat':
        UserStore.setSelectedMenuStore('journey');
        UserStore.setSelectedPageStore('journey/forwhat');
        break;
      case '/journey/forchannel':
        UserStore.setSelectedMenuStore('journey');
        UserStore.setSelectedPageStore('journey/forchannel');
        break;
      case '/journey/forhow':
        UserStore.setSelectedMenuStore('journey');
        UserStore.setSelectedPageStore('journey/forhow');
        break;
      case '/newjourney':
        UserStore.setSelectedMenuStore('journey');
        UserStore.setSelectedPageStore('newjourney');
        break;
      case '/modelsettings':
        UserStore.setSelectedMenuStore('modelsettings');
        UserStore.setSelectedPageStore('modelsettings');
        break;
      case '/journey/myanswer':
        UserStore.setSelectedMenuStore('journey');
        UserStore.setSelectedPageStore('journey/myanswer');
        break;
      case '/videoSection':
        UserStore.setSelectedMenuStore('videoSection');
        UserStore.setSelectedPageStore('videoSection');
        break;
      case '/videoTrail':
        UserStore.setSelectedMenuStore('videoSection');
        UserStore.setSelectedPageStore('TrailContainer' );
        break;
      case '/videoTrail/introduction':
        UserStore.setSelectedMenuStore('videoSection');
        UserStore.setSelectedPageStore('videoSection' );
        break;
      case '/videoTrail/businessTips':
        UserStore.setSelectedMenuStore('videoSection');
        UserStore.setSelectedPageStore('videoSection' );
        break;
      case '/community':
        UserStore.setSelectedMenuStore('community');
        UserStore.setSelectedPageStore('community' );
        break;
      case '/getpremium':
        UserStore.setSelectedMenuStore('journey');
        UserStore.setSelectedPageStore('getpremium' );
        break;
      case '/clients':
        UserStore.setSelectedMenuStore('actionplan');
        UserStore.setSelectedPageStore('clients' );
        break;
      case '/monthcashflow':
        UserStore.setSelectedMenuStore('cashflow');
        UserStore.setSelectedPageStore('monthcashflow' );
        break;

    

      case 'settings':

      break;
      default:
        //TODO: Pagina inicial por padrão
    
        UserStore.setSelectedMenuStore('cashflow');
        UserStore.setSelectedPageStore('monthcashflow' );

        //UserStore.setSelectedMenuStore('modelrec');
        //UserStore.setSelectedPageStore(<Model/>);
        break;
    }

    }
    useEffect(()=>{




        
        if (UserStore.getSelectedMenuStore()===null || UserStore.getSelectedPageStore()===null) {
            //alert('')
            discoverPage()

        } else {
            
        }



    }, [])
    if( props )
    {
        switch( props.page )
        {
            case '/':
                userContent = firstAccess()
                selectedSideMenu = 'modelrec'
                break;
            case '/newmodelrec':
                userContent = <NewModelRecomendation/>
                selectedSideMenu='modelrec'
                break;

            case '/modelrec':
                userContent = <ModelRec/>;
                selectedSideMenu = 'modelrec';
                break;
            case '/dashboard':
                userContent = <Dashboard/>;
                selectedSideMenu = 'dashboard';
                break;
            case '/familiarcashflow':
                userContent = <FamiliarCashFlow/>;   
                selectedSideMenu = 'cashflow';
                break; 
            case '/cashflow':
                userContent = <CashFlow/>;
                selectedSideMenu = 'cashflow';
                break;
            case '/revenues':
                userContent = <Revenues/>;
                selectedSideMenu = 'cashflow';
                break;
            case '/expenses':
                userContent = <Expenses/>;
                selectedSideMenu = 'cashflow';
                break;
            case '/taxes':
                userContent = <Taxes/>;
                selectedSideMenu = 'cashflow';
                break;
            case '/growth':
                userContent = <Growth/>;
                selectedSideMenu = 'cashflow';
                break;
            case '/traction':
                userContent = <Traction/>;
                selectedSideMenu = 'cashflow';
                break;
            case '/actionplan':
                userContent = <ActionPlan/>;
                selectedSideMenu = 'actionplan';
                break;
            case '/journey':
                userContent = <JourneyNewIntroduction/>
                selectedSideMenu = 'journey';
                break
            case '/journey/forwho':
                userContent = <JourneyForWhoModel/>
                selectedSideMenu= 'journey';
                break;   
            case '/journey/forwhat':
                userContent = <JourneyForWhatModel/>
                selectedSideMenu='journey'
                break;
            case '/journey/forchannel':
                userContent = <JourneyForChannelModel/>
                selectedSideMenu='journey'
                break;
            case '/journey/forhow':
                userContent = <JourneyForHowModel/>
                selectedSideMenu='journey'
                break;
            case '/journey/introduction':
                userContent = <JourneyIntroduction/>
                selectedSideMenu = 'journey';
                break
            case '/journey/introduction/video':
                userContent = <JourneyIntroductionVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/introduction/ourjouneyvideo':
                userContent = <JourneyIntroductionOurJourneyVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/introduction/furthermaterials':
                userContent = <JourneyIntroductionFurtherMaterials/>
                selectedSideMenu = 'journey';
                break 
            case '/journey/forwhom':
                userContent = <JourneyKYMForWhom/>
                selectedSideMenu = 'journey';
                break
            case '/journey/forwhom/introduction':
                userContent = <JourneyForWhomIntroductionVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/forwhom/video':
                userContent = <JourneyForWhomVideoo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/forwhom/furthermaterials':
                userContent = <JourneyForWhomFurtherMaterials/>
                selectedSideMenu = 'journey';
                break
            case '/journey/forwhom/questions':
                userContent = <JourneyForWhomQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/market/video':
                userContent = <JourneyMarketVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/market/furthermaterials':
                userContent = <JourneyMarketFurtherMaterials/>
                selectedSideMenu = 'journey';
                break
            case '/journey/market/questions':
                userContent = <JourneyMarketQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/public/video':
                userContent = <JourneyPublicVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/public/furthermaterials':
                userContent = <JourneyPublicFurtherMaterials/>
                selectedSideMenu = 'journey';
                break
            case '/journey/public/questions':
                userContent = <JourneyPublicQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/competitors/video':
                userContent = <JourneyCompetitorsVideo/>
                selectedSideMenu = 'journey';
                break    
            case '/journey/competitors/furthermaterials':
                userContent = <JourneyCompetitorsFurtherMaterials/>
                selectedSideMenu = 'journey';
                break
            case '/journey/competitors/questions':
                userContent = <JourneyCompetitorsQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/what':
                userContent = <JourneyKYMWhat/>
                selectedSideMenu = 'journey';
                break
            case '/journey/what/video':
                userContent = <JourneyWhatVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/productandservices/video':
                userContent = <JourneyProductAndServiceVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/productandservices/questions':
                userContent = <JourneyProductAndServiceQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/valueproposition/video':
                userContent = <JourneyValuePropositionVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/valueproposition/furthermaterials':
                userContent = <JourneyValuePropositionFurtherKnowledge/>
                selectedSideMenu = 'journey';
                break
            case '/journey/valueproposition/questions':
                userContent = <JourneyValuePropositionQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/distributionchannel/video':
                userContent = <JourneyDistributionChannelVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/communicationchannel/video':
                userContent = <JourneyCommunicationChannelVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/communicationchannel/furthermaterials':
                userContent = <JourneyCommunicationChannelFurtherMaterials/>
                selectedSideMenu = 'journey';
                break    
            case '/journey/communicationchannel/questions':
                userContent = <JourneyCommunicationChannelQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/saleschannel/video':
                userContent = <JourneySalesChannelVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/saleschannel/questions':
                userContent = <JourneySalesChannelsQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/deliverychannel/video':
                userContent = <JourneyDeliveryChannelVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/deliverychannel/questions':
                userContent = <JourneyDeliveryChannelQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/relationshipchannel/video':
                userContent = <JourneyRelationshipChannelVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/relationshipchannel/furthermaterials':
                userContent = <JourneyRelationshipChannelFurtherMaterials/>
                selectedSideMenu = 'journey';
                break
            case '/journey/relationshipchannel/questions':
                userContent = <JourneyRelationshipChannelsQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/how':
                userContent = <JourneyKYMHow/>
                selectedSideMenu = 'journey';
                break
            case '/journey/how/video':
                userContent = <JourneyHowVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/activities/video':
                userContent = <JourneyActivitiesVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/activities/furthermaterials':
                userContent = <JourneyActivitesFurtherMaterials/>
                selectedSideMenu = 'journey';
                break    
            case '/journey/activities/questions':
                userContent = <JourneyActivitiesQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/partners/video':
                userContent = <JourneyPartnersVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/partners/furthermaterials':
                userContent = <JourneyPartnersFurtherMaterials/>
                selectedSideMenu = 'journey';
                break    
            case '/journey/partners/questions':
                userContent = <JourneyPartnersQuestions/>
                selectedSideMenu = 'journey';
                break
            case '/journey/resources':
                userContent = <JourneyKYMResources/>
                selectedSideMenu = 'journey';
                break
            case '/journey/resources/video':
                userContent = <JourneyResourcesVideo/>
                selectedSideMenu = 'journey';
                break
            case '/journey/resources/furthermaterials':
                userContent = <JourneyResourcesFurtherMaterials/>
                selectedSideMenu = 'journey';
                break    
            case '/journey/resources/questions':
                userContent = <JourneyResourcesQuestions/>
                selectedSideMenu = 'journey';
                break

            case '/newjourney':
                userContent = <NewJourney/>
                selectedSideMenu='journey'

                break
            case '/modelsettings':
                userContent = <MyAccount/>;
                selectedSideMenu = 'modelsettings';
                break;
            /*case '/modelsettings/plan':
                userContent = <MyPlanAccount/>;
                selectedSideMenu = 'modelsettings';
                break;*/
            case '/journey/myanswer':
                userContent = <JourneyMyAnswers/>;
                selectedSideMenu = 'journey';
                break;

            case '/videoSection':
                userContent = <VideosSection isSandwichMenuClosed={isSandwichMenuClosed} changeSandwichMenuState={()=>{changeSandwichMenuState()}} closeSandwichMenu={()=>closeSandwichMenu()}/>
                selectedSideMenu = 'videoSection';
                break;
            case '/videoTrail':
                userContent = <VideoTrailContainer/>
                selectedSideMenu = 'videoSection';
                break;
            case '/videoTrail/introduction':
                userContent = <TrailSection isSandwichMenuClosed={isSandwichMenuClosed} changeSandwichMenuState={()=>{changeSandwichMenuState()}}/>
                selectedSideMenu = 'videoSection';
            break;
            case '/videoTrail/businessTips':
                userContent = <TrailSection  isSandwichMenuClosed={isSandwichMenuClosed} changeSandwichMenuState={()=>{changeSandwichMenuState()}}/>
                selectedSideMenu = 'videoSection';
            break;

            case '/community':
                userContent = <Community/>;
                selectedSideMenu = 'community';
            break;
            case '/getpremium':
                userContent = <GetPremiumPlan/>
                selectedSideMenu='journey';
                break;   
                case '/clients':
                    userContent = <Clients/>
                    selectedSideMenu='modelrec'
                    break;            



                case '/monthcashflow':
                    userContent = <Monthcashflow/>
                    selectedSideMenu='cashflow'
                    break;            
                                        
            default:
                //TODO: Pagina inicial por padrão
                userContent = <Model/>;
        }
    }
    else
    {
        //TODO: Redirect
    }
    const [isMenuExpanded, setIsMenuExpanded] = useState(false)
    const [isSelectModelOpen, setIsSelectModelOpen] = useState(false)






    const openSelectModel = () =>{
        setIsSelectModelOpen(true)



    }



    useEffect(()=>{
        const redefineContent = async() =>{

            
            const root = document.querySelector('#root')
            const profileIcon = document.querySelector('.BoardHeaderBtn')

            const sideBar = document.querySelector('.BoardWrapper')
            const mainContent = document.querySelector('.BoardRight')
            const mainContentHeader = document.querySelector('.BoardRightHeader')
            const mainContentLine = document.querySelector('.HorizontalLineSeparator')        
            const body = document.querySelector('body')
            const board = document.querySelector('.BoardRight')
            board.style.backgroundColor = ''   
            board.style.width =  ''
            body.style.overflowY = ''
            const boardContent = document.querySelector('.BoardContent')
            boardContent.style.height = ''   
            boardContent.style.padding = '' 
            boardContent.style.backgroundColor = ''
            sideBar.style.backgroundColor = ''    
            profileIcon.style.backgroundColor = ''   
            profileIcon.parentNode.style.marginBottom = ''
           // profileIcon.style.transform = 'translateY(-30%)'
            profileIcon.parentNode.style.marginRight = ''
    
    
    
            root.style.backgroundColor = ''  
            mainContent.style.padding = ''   
            mainContentHeader.style.backgroundColor = ''   
            mainContentHeader.style.height = ''
            mainContentHeader.style.padding = '' 
            mainContentHeader.style.width = ''  
    
            mainContentLine.style.margin = '' 
            mainContent.style.backgroundColor = '' 
            mainContentLine.style.backgroundColor = '' 
            mainContent.style.width = ''  

            const intervalId = () =>{ return new Promise(resolve=>setTimeout(resolve, 1000)) }

            await intervalId()

            const lateralMenu = document.querySelector('.sideBar')  
            lateralMenu.style.backgroundColor = ''

        }



        const redefineJourneyContent = async () =>{
            
                        
            const root = document.querySelector('#root')
            const profileIcon = document.querySelector('.BoardHeaderBtn')

            const sideBar = document.querySelector('.BoardWrapper')
            const mainContent = document.querySelector('.BoardRight')
            const mainContentHeader = document.querySelector('.BoardRightHeader')
            const mainContentLine = document.querySelector('.HorizontalLineSeparator')        
            const body = document.querySelector('body')
            const board = document.querySelector('.BoardRight')
            board.style.backgroundColor = ''   
            board.style.width =  ''
            body.style.overflowY = ''
            const boardContent = document.querySelector('.BoardContent')
            //boardContent.style.height = ''   
            //boardContent.style.padding = '' 
            //boardContent.style.backgroundColor = ''
            sideBar.style.backgroundColor = ''    
            profileIcon.style.backgroundColor = ''   
            profileIcon.parentNode.style.marginBottom = ''
           // profileIcon.style.transform = 'translateY(-30%)'
            profileIcon.parentNode.style.marginRight = ''
    
    
    
            root.style.backgroundColor = ''  
            mainContent.style.padding = ''   
            mainContentHeader.style.backgroundColor = ''   
            mainContentHeader.style.height = ''
            mainContentHeader.style.padding = '' 
            mainContentHeader.style.width = ''  
    
            mainContentLine.style.margin = '' 
            mainContent.style.backgroundColor = '' 
            mainContentLine.style.backgroundColor = '' 
            mainContent.style.width = ''  

            const intervalId = () =>{ return new Promise(resolve=>setTimeout(resolve, 1000)) }

            await intervalId()

            const lateralMenu = document.querySelector('.sideBar')  
            lateralMenu.style.backgroundColor = ''



        }
        console.log(newSelectedPage)
        if (!newSelectedPage  || newSelectedPage==='newjourney' || newSelectedPage==='videoTrail/introduction'   ) {
            
        } else if(newSelectedPage==='journey/forwho'   || newSelectedPage==='journey/forwhat' ||  newSelectedPage==='journey/forhow'  ||  newSelectedPage==='journey/forchannel'){redefineJourneyContent()} else {
            redefineContent()            
        }




    }, [newSelectedPage])









    const sideBarOptions = [          
        {id: 5, route: 'modelrec', title: 'Diagnóstico' /*'Quiz'*/, subMenus: [
            {id: 'MODEL', route: 'modelrec', title: 'Diagnóstico' /*'Quiz'*/}

          ], icon: ModelIconImg },   
        {id: 0, route: 'journey', title: 'Modelo de Negócios'/*'Jornada'*/, subMenus: [
            {id: 'JOURNEY', route: 'journey', title: 'Modelo de Negócios'/*'Jornada'*/},
            {id: 'MYANSWER', route: 'journey/myanswer', title: 'Minhas Respostas'},
            {id: 'FORWHO', route: 'journey/forwho', title: 'Para quem?'},
            {id: 'WHAT', route: 'journey/forwhat', title: 'O que?'},
            {id: 'CHANNELS', route: 'journey/forchannel', title: 'Canais'},
            {id: 'HOW', route: 'journey/forhow', title: 'Como'} 
        ], icon: JourneyIconImg },

        {id: 1, route: 'actionplan', title: 'Gestão de Vendas'/*'Plano de Ação'*/, subMenus: [
            {id: 'ACTIONPLAN', route: 'actionplan', title: 'Gestão de Vendas'/*'Plano de Ação'*/},

            {id: 'ACTIONPLAN', route: 'clients', title: 'Clientes'}
        ], icon: ActionPlanIconImg },

        {id: 2, route: 'cashflow', title: 'Gestão Financeira' /*'Fluxo de Caixa'*/, subMenus: [
            {id: 'CASHFLOW', route: 'cashflow', title: 'Gestão Financeira' /*'Fluxo de Caixa'*/},
            {id: 'MONTHCASHFLOW', route: 'monthcashflow', title: 'Fluxo de Caixa Mensal'}, 
            {id: 'REVENUES', route: 'revenues', title: 'Receitas'},
            {id: 'EXPENSES', route: 'expenses', title: 'Custos e Despesas'},
            {id: 'TAXES', route: 'taxes', title: 'Tributos'}
        ], icon: CashFlowIconImg },

        {id: 3, route: 'dashboard', title: 'Painel de Indicadores' /*'Dashboard'*/, subMenus: [
            {id: 'DASH', route: 'dashboard', title: 'Painel de Indicadores' /*'Dashboard'*/}
        ], icon: DashboardIconImg },   

        {id: 4, route: 'videoSection', title: 'MEconecte Academy', subMenus: [
            {id: 'VIDEOS', route: 'videoSection', title: 'MEconecte Academy'}

        ], icon: IconVideo },


        {id: 6, route: 'community', title: 'Comunidade', subMenus: [
            {id: 'COMMUNITY', route: 'community', title: 'Comunidade'}

          ], icon: IconCommunity },

                   
        ]
    
    const completeSideBarOptions = [
          ...sideBarOptions,
     
          {id: 7, route: 'modelsettings', title: 'Configurações', subMenus: [
            {id: 'SETTINGS', route: 'modelsettings', title: 'Configurações'}
        ], icon: SettingsIconImg }



    ]




    const urlPath = window.location.pathname.split('/')



    const watchLaterFunction = () =>{setAccess(1)}
    
    //const haveWatchedIntro = async() =>{
       // setAccess(1) 
       // await axios.put('https://o3lghqveqwzv5rxsldv2ivrukm0qilrb.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), haveWatchedIntro: 1})
       // .then(resp=>window.location.replace('/videoTrail/introduction?=0/1'))
       // .catch(err=>console.log(err))
    

    const initTutorial = () =>{

        //setAccess(1)
        window.location.replace('/selectmodel')


    }
    const haveWatchedIntro = async() =>{
        //setAccess(1) 
        await axios.put('https://o3lghqveqwzv5rxsldv2ivrukm0qilrb.lambda-url.us-east-2.on.aws/', {company: UserStore.getUserId(), haveWatchedIntro: 1})
        .then(resp=>{window.location.replace(/*'/videoTrail/introduction?=0/1'*/'/selectmodel')})
        .catch(err=>console.log(err)) 
    }
    return(
        <div className='BoardWrapper' style={{width: isMenuExpanded && '210px'}}>

{planDays<=15 && appearPlanBanner && window.screen.width<900 && <MobileUpgradePlanBanner/>}

            
            {access===1 && !hideIntro && <SelectModel/>}
            {isSelectModelOpen && <SelectModel/>}
            {!isSelectModelOpen && <PlataformGuide openSelectModel={openSelectModel}/>}
            {access===0 && <FirstAccess watchLaterFunction={watchLaterFunction} initTutorialFunction={initTutorial}/>}
            <div className='Board'>


                <div className='BoardLeft' style={{zIndex: access===0 && '-1'}}>
                    <UserSideBar selectedSideMenu={ newSelectedSideMenu } setNewSelectedSideMenu={ setNewSelectedSideMenu } setNewSelectedPage={ setNewSelectedPage } isFirstAccessScreen={access} sideBarOptions={sideBarOptions} isMenuExpanded={isMenuExpanded} setIsMenuExpanded={setIsMenuExpanded}/>
                </div>
                <div className='BoardRight' style={{/*position: isMenuExpanded &&*//* 'absolute', left: isMenuExpanded && '80px'*/}}>
                    <DefaultSandwichMenu setNewSelectedPage={setNewSelectedPage} setNewSelectedSideMenu={setNewSelectedSideMenu} selectedSideMenu={ selectedSideMenu } sideBarOptions={completeSideBarOptions}/> {/*Enters when screen width < 1100px*/}                   
                    
                    {urlPath[1]==='videoTrail' && <VideoTrailSandwichMenu isSandwichMenuClosed={isSandwichMenuClosed} changeSandwichMenuState={()=>changeSandwichMenuState()}/>}
                    <UserBoardHeader page={ `/${newSelectedPage}` }  setNewSelectedSideMenu={setNewSelectedSideMenu} setNewSelectedPage={setNewSelectedPage}/>
                    <div className='BoardContent'>
                        { /*userContent*/ }                     
                        
                        
                        
                        <Route path="/modelrec">
                            <ModelRec />
                        </Route>
                        <Route exact path="/journey">
                            <JourneyNewIntroduction />
                        </Route>            
                        <Route path="/journey/myanswer">
                            <JourneyMyAnswers />
                        </Route>             
                        <Route path="/actionplan">
                            <ActionPlan />
                        </Route>                        
                        <Route path="/cashflow">
                            <Cashflow />
                        </Route>              
                        <Route path="/monthcashflow">
                            <Monthcashflow />
                        </Route>                                
                        <Route path="/dashboard">
                            <Dashboard />
                        </Route>
                        <Route exact path="/videoSection">
                               <VideosSection isSandwichMenuClosed={isSandwichMenuClosed} changeSandwichMenuState={()=>{changeSandwichMenuState()}} closeSandwichMenu={()=>closeSandwichMenu()}/> 
                        </Route>                        
                        <Route path="/community">
                            <Community />
                        </Route>                        
                        <Route path="/modelsettings">
                            <MyAccount />
                        </Route>                     




                        <Route path="/newmodelrec">
                            <NewModelRecomendation/>
                        </Route>                               
                        <Route path="/revenues">
                            <Revenues/>
                        </Route>                               
                        <Route path="/expenses">
                            <Expenses/>
                        </Route>                               
                        <Route path="/taxes">
                            <Taxes />
                        </Route>                               
                        <Route path="/journey/forwho">
                            <JourneyForWhoModel/>
                        </Route>                               
                        <Route path="/journey/forwhat">
                            <JourneyForWhatModel/>
                        </Route>                               
                        <Route path="/journey/forchannel">
                            <JourneyForChannelModel/>
                        </Route>                               
                        <Route path="/journey/forhow">
                            <JourneyForHowModel/>
                        </Route>                               
                        <Route path="/newjourney">
                            <NewJourney/>
                        </Route>                               
                        <Route path="/getpremium">
                            <GetPremiumPlan/>
                        </Route>                               
                        <Route path="/clients">
                            <Clients/>
                        </Route>                               
                                             
                        
                                                
                        
                        
                        <Route exact path="/videoTrail">
                            <VideoTrailContainer />
                        </Route>                               
                        <Route exact path="/getPlan">
                            <GetPlan />
                        </Route>                                 
                        
                        
                        <Route path="/videoTrail/introduction">
                            <TrailSection  isSandwichMenuClosed={isSandwichMenuClosed} changeSandwichMenuState={()=>{changeSandwichMenuState()}}/>
                        </Route>                               
                        
                        
                        
                        <Route path="/videoTrail/businessTips">
                            <TrailSection  isSandwichMenuClosed={isSandwichMenuClosed} changeSandwichMenuState={()=>{changeSandwichMenuState()}}/>
                        </Route>                               

                        <Route path="/videoTrail/cibele">
                            <TrailSection  isSandwichMenuClosed={isSandwichMenuClosed} changeSandwichMenuState={()=>{changeSandwichMenuState()}}/>
                        </Route>                               
                       

                        <Route path="/videoTrail/claudio">
                            <TrailSection  isSandwichMenuClosed={isSandwichMenuClosed} changeSandwichMenuState={()=>{changeSandwichMenuState()}}/>
                        </Route>                                                      


                    </div>
                </div>
            </div>
        </div>
    );
}; 

export default UserBoard;