import React, { useEffect } from 'react'
import styles from './FullStep.module.sass'
import StepOption from '../videoStepsContainer/stepOption/StepOption'
import axios from 'axios'
import UserStore from '../../../../store/userStore'

const FullStep = ({reloadSteps, allSteps, selectStep, currentGroup, subGroups   , setIsFullStepOpen}) => {
  console.log(currentGroup)
  console.log(allSteps)
  
  const newSelectStep = ({group, step}) =>{

    selectStep({group, step})
    setIsFullStepOpen(false)

  }
  return (
    <div className={styles.videoStepContainer}>
        <div  className={styles.closePopUp} onClick={()=>setIsFullStepOpen(false)}>Fechar</div >
 {allSteps && Object.keys(allSteps).map(group=><StepOption isWeb={true}  subGroups={subGroups} reloadSteps={reloadSteps} group={group} isOpen={currentGroup===allSteps[group][0].sGroup } selectStep={newSelectStep} groupStepList={allSteps[group]} groupName={group}/>)}
    
    </div>
  )
}

export default FullStep