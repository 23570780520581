import React, { useState } from 'react'
import styles from './GetPlan.module.sass'
import { StringMask } from '../../components/library/StringMasks'
const GetPlan = () => {



    const [userName, setUserName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isFirstStep , setIsFirstStep ] = useState(true )



    const [cardNumber, setCardNumber] = useState('')
    const validateIsFirstStep = () =>{
    if (userName && phone && email && password ) {
        setIsFirstStep(false)
    }




    }
  return (
    <div className={styles.getPlan}>   
    <div className={styles.logo}>
        
        </div>
        
        <div className={styles.title}>
        
        
        </div>











































        {isFirstStep &&
        <div className={styles.content}>
            <p>Insira seus dados</p>
             <label>Nome Completo: <input type="text" placeholder="Lucas Silva" value={userName} onChange={e=>{setUserName(e.target.value)}}/></label>
             <label>Telefone: <input type="text" placeholder="32 9 9889-1010" value={phone} maxLength={15} onChange={e=>{setPhone(StringMask(e.target.value, { type:'Phone' } ,'')   )}}/></label>
             <label>Email: <input type="email" placeholder="exemplo@mail" value={email} onChange={e=>{setEmail(e.target.value)}}/></label>
             <label>Senha: <input type="password" placeholder="****" value={password} onChange={e=>{setPassword(e.target.value)}}/></label>
            {userName && phone && email && password && <button className={styles.continueButton} onClick={()=>{console.log(!!userName); validateIsFirstStep( )}}>Continuar</button>
            }{(!userName || !phone || !email || !password) && <button className={styles.continueButton} style={{backgroundColor: '#bbbbbb'}}>Continuar</button>}
            
        </div>  }       
        







        {!isFirstStep && !!userName && phone && email && password && <div className={styles.cardContent}>
            <p>Insira os dados do cartão</p>
             <label>Nome Completo: <input type="text" placeholder="Lucas Silva"/></label>
             <label>Número: <input type="text" placeholder="5522 2222 2222 2222" value={cardNumber} onChange={e=>       setCardNumber(e.target.value.replace( /\D/g,'' )
        .replace( /(\d{4})(\d)/,'$1 $2' )
        .replace( /(\d{4})(\d)/,'$1 $2' )
        .replace( /(\d{4})(\d)/,'$1 $2' )
        .replace( /(\d{4})/,'$1' ))} maxLength={20}/></label>
             <label>Validade: <input type="text" placeholder="02/55"/></label>
             <label>Código de Segurança: <input type="text" placeholder="111" maxLength={3}/></label>
            <button className={styles.continueButton}>Pagar </button>
            

        </div>  }            
        </div>
  )
}

export default GetPlan  