import React from 'react'
import styles from './VideoTrailSandwichMenu.module.sass'
import { useState } from 'react'
import Options from '../options/Options'


const VideoTrailSandwichMenu = ({isSandwichMenuClosed, changeSandwichMenuState}) => {   
  const [isFirstAppear, setIsFirstAppear] = useState(true)

  const changeMenuStateLayout = () =>{
    changeSandwichMenuState()
    setIsFirstAppear(false)
}

const redirectToVideoSection = () =>{

    window.location.replace('/videoSection')


}

  return (
    <div className={styles.videoTrailSandwichMenu}>
        <div onClick={redirectToVideoSection} className={styles.backOption}><p> &lt; Voltar</p></div>


        <div onClick={changeMenuStateLayout}><i className={!isSandwichMenuClosed ? "fa fa-times" : "fa fa-bars"}/></div>

    </div>
  )
}

export default VideoTrailSandwichMenu