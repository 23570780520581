import React from 'react'
import styles from './MobileAddComment.module.sass'
const MobileAddComment = ({closeFn, setNewComment, newComment, postCommentOnGroup}) => {
  return (
    <div className={styles.mobileAddComment}>
        <span className={styles.close} onClick={closeFn}>x</span>
        <h2 className={styles.title}>Adicionar Comentário</h2>
        <textarea type="text" autoFocus value={newComment} onChange={e=>setNewComment(e.target.value)} placeholder="Escreva aqui seu comentário..."/>
        <button className={styles.addComment} onClick={()=>{postCommentOnGroup(); closeFn()}}>Publicar</button>
    </div>
  )
}

export default MobileAddComment