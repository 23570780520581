import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useState } from 'react';
import ModelRecForm from './modelRecForm/ModelRecForm';
import ModelRecResult from './modelRecResult/ModelRecResult';
import Model from './model/Model';
import UserStore from '../../store/userStore';
import styles from './NewModelRecomendation.module.sass'

const NewModelRecomendation = () => {

    const initialSelectedOptions =  [ 
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ] 
    ];

    const [ QuizStep, setQuizStep ] = useState( { step: 0, modelId: -1 } );
    const [ MyBusinessModel, setMyBusinessModel ] = useState( { modelName: '', selectedOptions: initialSelectedOptions } );
    const [ RecModelsByQuiz, setRecModelsByQuiz ] = useState( { First: { modelId: 0, modelName: '', modelFit: 0 }, Second: { modelId: 0, modelName: '', modelFit: 0 }, Third: { modelId: 0, modelName: '', modelFit: 0 } } );
    
    if( ( QuizStep.step === 0 ) && ( QuizStep.modelId === -1 ) && ( UserStore.getMyModel() ) )
    {
        setQuizStep( { step: 2, modelId: -1 } );
    }


















    return (
        <div className={styles.newModelRecomendation}> 
            {/*QuizStep===1*/ false && <ModelRecResult setQuizStep={setQuizStep} RecModelsByQuiz={RecModelsByQuiz} MyBusinessModel={MyBusinessModel}/>}
            {QuizStep===2 && <Model QuizStep={QuizStep} setQuizStep={setQuizStep}/>}
            {/*QuizStep!==1 && QuizStep!==2*/ true && <ModelRecForm setQuizStep={setQuizStep} MyBusinessModel={MyBusinessModel} setRecModelsByQuiz={setRecModelsByQuiz} setMyBusinessModel={setMyBusinessModel}/>}
        </div>   
    )
}

export default NewModelRecomendation;