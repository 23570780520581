import React from 'react'   
import styles from './SingleVideoCard.module.css'
import redirectTo from '../../functions/redirectTo'
import UserStore from '../../../../store/userStore'


const SingleVideoCard = ({videoPosition, trailPath, trailCode, title, desc, backgroundPicture, time, watchedNum}) => {



  const redirectToVideos = () =>{

    UserStore.setSelectedPageStore('videoTrail/introduction')
    redirectTo(trailPath, trailCode, videoPosition)   

  }
  





  return (
    <div onClick={redirectToVideos} className={styles.singleVideoCard}>{/*Display flex flex direction*/}
        {backgroundPicture &&<div style={{width: '317px'}}><img src={backgroundPicture} alt=""/></div>}
        
        <div className={styles.bottomContent}>

          <div className={styles.bottomTopContent}>
            <p style={{marginTop: '12px', textAlign: 'center', fontWeight: '700', fontSize: '16px'}}>{title}</p>
            <p style={{marginTop: '4px', padding: '2px 19px', textAlign: 'left', fontWeight: '500', fontSize: '12px'}}>{desc.substring(0, 96)}</p>
          </div>
            <div className={styles.videoInformations}>


              <p><i style={{marginRight: '4px'}} className="fa fa-clock"></i>{time || '00:00:00'}</p>


              {/*<p><i style={{marginRight: '4px'}} className="fa fa-user"></i>{watchedNum} pessoas</p>*/}
              
      
            {/*Titulo, Numero de videos, tempo de aulas, curtir*/}</div>
        </div>

    
    </div>
  )
}

export default SingleVideoCard