import React from 'react';
import '@coreui/coreui/dist/css/coreui.min.css'
import { EditIconImg } from '../imgsLoader/LoadIconImgs';
import { useState, useEffect } from 'react';
import Select from 'react-select'
import UserStore from '../../store/userStore';
import axios from 'axios';
import { StringMask } from '../library/StringMasks';
import { v4 as uuidv4 } from 'uuid';
import { calculateAliquote } from '../library/cashFlowLib'
import { composeInitialProps } from 'react-i18next';
import LoadingScreen from '../loadingScreen/LoadingScreen';
import styles from './CashFlow.module.sass'
import { RemoveElement } from '../removeElement/RemoveElement';
import { botconversaWebhook } from '../../functions/botconversaWebhook';
import MobileCashFlow from './mobileCashFlow/MobileCashFlow';
//TODO: Ver tamanho dos textos dentro das caixas

const monthNames = [ 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro' ];
const currentYear = new Date().getFullYear()

let currentMonth = new Date().getMonth() + 1;
let currentSemester = currentMonth <= 6 ? 1 : 2
let selectedSemester = currentSemester;
let cfpList = [];
let salesList = new Array( 12 ).fill( 0 );
let cfeList = [];
let aliquotTable = 0;
let rbt12 = 0;
let monthTaxes = Array( 12 ).fill( 0 );
let cfpItems = [ { year: currentYear, products: [] } ];
let cfeItems = [ { year: currentYear, expenses: [] } ];








const Cashflow = () =>
{
    const pageLocale = 'Gestão Financeira'
    useEffect(()=>{
    
        botconversaWebhook({locale: pageLocale, action: 'Abriu Fluxo de Caixa'})
    })    
    const [isLoading, setIsLoading] = useState(false)
    const [selectedYear, setSelectedYear] = useState(currentYear)
    const [ tableCFP, setTableCFP ] = useState( [] );
    const [ tableCFE, setTableCFE ] = useState( [] );
    const [ effectiveExpensPopUp, setPopUp ] = useState('');
    const [ expensesSelection, setExpensesSelection ] = useState( [] );

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();


    const selecStyles =
    {   
        valueContainer:( provided, state ) => ( { ...provided, padding:'0px 5px' } ),
        dropdownIndicator:( provided, state ) => ( { ...provided, padding:'4px' } ),
        control:( styles ) => ( { ...styles, minHeight:'10px', 
                                borderColor:'gray',
                                width:'21rem', 
                                height:'2.5rem',
                                borderRadius:'5px',
                                padding:'5px',
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '1.2rem',
                                lineHeight: '1.3rem',
                                color: '#251F53' } )
    };

    const initTable = () =>
    {
            
        
        
        
        cfpList = []

        cfeList = []
        for( let index = 0; index < 5; index++ )
        {

            //console.log('lista');//console.log(cfpList)

            cfpList.push
            (
                <tr id={ 'p_' + index } key={ 'p_' + index }>
                    <td><div>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                </tr>
            );

            cfeList.push
            (
                <tr id={ 'e_' + index } key={ 'e_' + index }>
                    <td><div>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                    <td><div className='ModelCashflowLabelLeft'>-----</div><div className='ModelCashflowLabelRight'>-----</div></td>
                </tr>
            );
        }

        setTableCFP( [ ...cfpList ] );
        setTableCFE( [ ...cfeList ] );

    
    }

    const fillCFList = async(year) =>
    {

        //console.log('List');//console.log(year)
        var userId = UserStore.getUserId();
        var token = UserStore.getToken();
        setIsLoading(true)

        cfpItems[ 0 ].year = year
        cfpItems[ 0 ].products = []
        cfeItems[ 0 ].expenses = []
        await axios.get( 'https://4iirgtz6rqhn2itpfzey4zd4au0iyfli.lambda-url.us-east-2.on.aws/', { params: { year: year, company: userId, token: token } } ).then( ( cfResp ) =>
        {//console.log('RESP');//console.log(cfResp)
            if( cfResp.data.pTarget )
            {//console.log('CFR'); //console.log(cfResp.data)
                for( let index = 0; index < cfResp.data.pTarget.length; index++ )
                {
                    let curProduct = cfResp.data.pTarget[ index ];
                    let product = curProduct.product;
                    let name = curProduct.name;
                    let target = [ curProduct.jan, curProduct.feb, curProduct.mar, curProduct.apr, curProduct.may, curProduct.jun, curProduct.jul, curProduct.aug, curProduct.sep, curProduct.oct, curProduct.nov, curProduct.dece ];
                    let done = Array( 12 ).fill( 0 );

                    cfpItems[ 0 ].products.push( { product:product, name:name, done:done, target:target } );
                }

                //console.log('cfp after axios');//console.log(cfpItems)
            }

            if( cfResp.data.pDone )
            {





                for( let index = 0; index < cfResp.data.pDone.length; index++ )
                {


                    const pIndex = cfpItems[ 0 ].products.findIndex( ( element ) => { return element.product == cfResp.data.pDone[ index ].product } );
                            //console.log('INSTALL'); //console.log(cfResp.data)



                    if(cfResp.data.pDone[index].saleYear === year || cfResp.data.pDone[index].month + cfResp.data.pDone[index].recurrenceMonths -1 > 12 || cfResp.data.pDone[index].month + cfResp.data.pDone[index].installments - 1 > 12){
                  
                            
                            

                        

                    if( pIndex !== -1  )
                    {


                        /*for( let monthLoop = 0; monthLoop < cfResp.data.pDone[ index ].recurrenceMonths; monthLoop++ ) // 0 5
                        {

                            if ( false && changeStep - monthLoop - 1 >=0) { // 2 - 0 - 1 = 1    1 - 0 - 1 = 0  
                                
                            
                                salesList[ cfResp.data.pDone[ index ].month + monthLoop - 1 ] += cfResp.data.pDone[ index ].done;
                            }
                            else{
                                salesList[ cfResp.data.pDone[ index ].month + monthLoop - 1 ] += cfResp.data.pDone[ index ].done; 
                            }


                        }*/

                        if( cfResp.data.pDone[ index ].recurrenceMonths > 1 )
                        {
                                     //console.log('VALID')
                                    //console.log(cfResp.data)

                            if(cfResp.data.pDone[index].saleYear === year){
                                for( let monthLoop = 0; monthLoop < cfResp.data.pDone[ index ].recurrenceMonths; monthLoop++ )
                                {          
                                    cfpItems[ 0 ].products[ pIndex ].done[ cfResp.data.pDone[ index ].month + monthLoop - 1 ] += cfResp.data.pDone[ index ].done; 
                                }



                            }else{

                                
                                const changeStep = 12 - cfResp.data.pDone[index].month + 1 //  12 - 12 + 1 = 1
                                const changeMonths = cfResp.data.pDone[index].recurrenceMonths - changeStep // 5 - 2 = 3 -->> 1 2 3
                                //console.log(changeStep);//console.log(changeMonths)


                                for( let monthLoop = 0; monthLoop < changeMonths; monthLoop++ )
                                {          
                                    cfpItems[ 0 ].products[ pIndex ].done[ monthLoop ] += cfResp.data.pDone[ index ].done; 
                                }
                            }

                        }
                        else
                        {
                            const paymentFraction = cfResp.data.pDone[ index ].done / Math.max( cfResp.data.pDone[ index ].installments, 1 );





                            
                            if(cfResp.data.pDone[index].saleYear === year){                           
                            for( let monthInstallment = 0; monthInstallment < Math.max( cfResp.data.pDone[ index ].installments, 1 ); monthInstallment++ )
                            {
                                cfpItems[ 0 ].products[ pIndex ].done[ cfResp.data.pDone[ index ].month + monthInstallment - 1 ] += paymentFraction;
                            } }

                            
                            else{          
                                
                                

                                const changeStep = 12 - cfResp.data.pDone[index].month + 1 //  12 - 12 + 1 = 1
                                const changeMonths = cfResp.data.pDone[index].installments - changeStep // 5 - 2 = 3 -->> 1 2 3
                                //console.log(changeStep);//console.log(changeMonths)
                                for( let monthInstallment = 0; monthInstallment < changeMonths; monthInstallment++ )
                                {




                                    cfpItems[ 0 ].products[ pIndex ].done[ monthInstallment ] += paymentFraction;
                                } }
    
                            
                                        


                        }
                    }
                    }



                }
            }

            if( cfResp.data.expenses )
            {//console.log('cfResp.data');//console.log(cfResp.data)


                for( let index = 0; index < cfResp.data.expenses.length; index++ )
                {
                    //TODO change expense var name
                    //TODO change values after the current month
                    let curProduct = cfResp.data.expenses[ index ];
                    let cfe = curProduct.cfe;
                    let name = curProduct.name;
                    let done = [ curProduct.jan, curProduct.feb, curProduct.mar, curProduct.apr, curProduct.may, curProduct.jun, curProduct.jul, curProduct.aug, curProduct.sep, curProduct.oct, curProduct.nov, curProduct.dece ];
                    let target = [ curProduct.jant, curProduct.febt, curProduct.mart, curProduct.aprt, curProduct.mayt, curProduct.junt, curProduct.jult, curProduct.augt, curProduct.sept, curProduct.octt, curProduct.novt, curProduct.dect ];
                    cfeItems[ 0 ].expenses.push( { cfe: cfe, name: name, done: done, target: target } );
                }
            }

            if( cfResp.data.aliquot )
            {
                aliquotTable = cfResp.data.aliquot;
            }

            if( cfResp.data.taxes )
            {   
                monthTaxes = cfResp.data.taxes;
            }


        } ).catch(err=>{setIsLoading(false); monthTaxes = new Array(12).fill(0); aliquotTable = 0;});
            drawTable();       
        setIsLoading(false)
    }

    const changeSemester = () =>
    {
        selectedSemester = ( selectedSemester % 2 ) + 1;
        drawTable();
    }

    const drawTable = () =>
    {
        const MonthTableHeader = document.getElementsByName( "ModelCashflowTable" )[ 0 ].childNodes[ 0 ].childNodes[ 0 ];
        const RevenuesTableHeader = document.getElementsByName( "ModelCashflowTable" )[ 0 ].childNodes[ 1 ].childNodes[ 1 ];
        const TaxesTableHeader = document.getElementsByName( "ModelCashflowTable2" )[ 0 ].childNodes[ 0 ].childNodes[ 1 ];
        const Accummulated = document.getElementsByName( "ModelCashflowTable3" )[ 0 ].childNodes[ 0 ].childNodes[ 2 ];
        const ExpensesTableHeader = document.getElementsByName( "ModelCashflowTable3" )[ 0 ].childNodes[ 0 ].childNodes[ 0 ];
        const ResultTableHeader = document.getElementsByName( "ModelCashflowTable3" )[ 0 ].childNodes[ 0 ].lastChild;
        const monthOffset = ( selectedSemester === 2 ) ? 6 : 0;
        let monthRevenues = Array( 12 ).fill( 0 );
        
        let monthExpenses = Array( 12 ).fill( 0 );
        let monthResult = Array( 12 ).fill( 0 );
        let monthAccumulated = Array( 12 ).fill( 0 );
        let monthResultWithAccumulated = Array( 12 ).fill( 0 );
        MonthTableHeader.childNodes[ 1 ].childNodes[ 1 ].textContent = monthNames[ monthOffset + 0 ];
        MonthTableHeader.childNodes[ 2 ].textContent = monthNames[ monthOffset + 1 ];
        MonthTableHeader.childNodes[ 3 ].textContent = monthNames[ monthOffset + 2 ];
        MonthTableHeader.childNodes[ 4 ].textContent = monthNames[ monthOffset + 3 ];
        MonthTableHeader.childNodes[ 5 ].textContent = monthNames[ monthOffset + 4 ];
        MonthTableHeader.childNodes[ 6 ].childNodes[ 1 ].textContent = monthNames[ monthOffset + 5 ];

        setExpensesSelection( [] );

        for( let index = 0; ( index < cfpItems[ 0 ].products.length ); index++ )
        {
            let cfp = cfpItems[ 0 ].products[ index ];

            for( let monthIndex = 0; monthIndex < 12; monthIndex++ )
            {
                monthRevenues[ monthIndex ] += cfp.done[ monthIndex ];
            }
        }

        for( let index = 0; ( index < cfeItems[ 0 ].expenses.length ); index++ )
        {
            let cfe = cfeItems[ 0 ].expenses[ index ];

            for( let monthIndex = 0; monthIndex < 12; monthIndex++ )
            {
                monthExpenses[ monthIndex ] += cfe.done[ monthIndex ];
            }
        }

        for( let index = 0; ( index < cfpItems[ 0 ].products.length ); index++ )
        {
            let cfp = cfpItems[ 0 ].products[ index ];
            let td = [];

            for( let monthIndex = 0; monthIndex < 6; monthIndex++ )
            {
                td.push
                (
                    <td key={ 'cfp_' + uuidv4() }>
                        <div className='ModelCashflowLabelLeft'>{ StringMask( cfp.target[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' ) }</div>
                        <div className='ModelCashflowLabelRight'>{ StringMask( cfp.done[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' ) }</div>
                    </td>
                );
            }

            cfpList[ index ] =
                <tr id={ 'p_' + cfp.cfp } key={ 'p_' + index }>
                    <td><div>{ cfp.name }</div></td>
                    { td }
                </tr>;
        }

        for( let index = 0; ( index < cfeItems[ 0 ].expenses.length ); index++ )
        {
            let cfe = cfeItems[ 0 ].expenses[ index ];
            let td = [];

            
            for( let monthIndex = 0; monthIndex < 6; monthIndex++ )
            {
                td.push
                (
                    <td key={ 'cfe_' + uuidv4() } >
                        <div className='ModelCashflowLabelLeft'>{ StringMask( cfe.target[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' ) }</div>
                        <div className='ModelCashflowLabelRight'>{(cfeItems[ 0 ].expenses[ index ].done[ monthOffset + monthIndex ] !== undefined) ? StringMask( cfe.done[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' ) : StringMask(  0 , { type:'2DigitFraction' }, '' ) }</div>
                    </td>
                );
            } 
            
            cfeList[ index ] =
                <tr id={ 'e_' + cfe.cfe } key={ 'e_' + index }>
                    <td><div>{ cfe.name }</div></td>
                    { td }
                </tr>;

            setExpensesSelection(expensesSelection =>[ ...expensesSelection, { label:cfeItems[ 0 ].expenses[ index ].name, value: index } ] );
        }

        for( let monthIndex = 0; monthIndex < 12; monthIndex++ )
        {
            //TODO: Check rbt12
            //TODO: Port logic to back end and also add current month as limit
            monthAccumulated[ monthIndex ] = ( monthIndex > 0  &&  monthIndex <=  currentMonth && selectedYear<=currentYear) ? monthResultWithAccumulated[ monthIndex - 1 ] : 0;
            monthResult[ monthIndex ] = monthRevenues[ monthIndex ] - monthExpenses[ monthIndex ] - monthTaxes[ monthIndex ] //+ monthAccumulated[ monthIndex ];

            monthResultWithAccumulated[monthIndex] = monthResult[monthIndex] + monthAccumulated[monthIndex]
        }

        for( let monthIndex = 0; monthIndex < 6; monthIndex++ )
        {
            //TODO: quando começa a zerar os tributos do cara
            //TODO: Ajustar rbt para todos os meses
            //TODO: Resultado negativo de dezembro para janeiro e de junho para julho
            RevenuesTableHeader.childNodes[ monthIndex + 1 ].textContent = StringMask( monthRevenues[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' );
            TaxesTableHeader.childNodes[ monthIndex + 1 ].textContent = StringMask( (monthTaxes[ monthOffset + monthIndex ]).toFixed( 2 ), { type:'2DigitFraction' }, '' );
            ExpensesTableHeader.childNodes[ monthIndex + 1 ].textContent = StringMask( monthExpenses[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, '' );
            ResultTableHeader.childNodes[ monthIndex + 1 ].textContent = StringMask( monthResult[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, ( monthResult[ monthOffset + monthIndex ] < 0 ) ? '-' : '' );
            Accummulated.childNodes[ monthIndex + 1 ].textContent = StringMask( monthAccumulated[ monthOffset + monthIndex ].toFixed( 2 ), { type:'2DigitFraction' }, ( monthAccumulated[ monthOffset + monthIndex ] < 0 ) ? '-' : '' );
        }

        setTableCFP( [ ...cfpList ] );
        setTableCFE( [ ...cfeList ] );
    }

    const checkSelectedValue= async( index ) =>
    {
        const selectorExp = document.getElementsByName('expensesSelector');
        var inputCurrencyCost = document.getElementById('currencyInput')
        var itemNumber = selectorExp[0].value;
        cfeItems[ 0 ].expenses[itemNumber].done[index] =parseFloat(StringMask( inputCurrencyCost.value, { type:'float' }, '' ));

        let userId = UserStore.getUserId();
        let token = UserStore.getToken();
        document.getElementById( 'confirmBtn' ).innerHTML = "Salvando";

        console.log(cfeItems[0].expenses)

        console.log(index)
        await axios.put
        (
            'https://j6q3fg67zatdipf2wksmrskqqq0cqcfo.lambda-url.us-east-2.on.aws/',
            {
                company: userId,
                token: token,
                cfe:cfeItems[ 0 ].expenses[ itemNumber ].cfe,
                month: ( index + 1 ),
                done: parseFloat( StringMask( inputCurrencyCost.value, { type:'float' }, '' ) )
            }
        );
        document.getElementById( 'confirmBtn' ).innerHTML = "Salvo";
        drawTable();
        closePopUp();
    }

    const inputMaskHandle = () =>
    {
        var inputCurrencyCost = document.getElementById('currencyInput')
        inputCurrencyCost.value = StringMask( inputCurrencyCost.value, { type:'2DigitFraction' }, '' );
    }

    const editEfetiveCost = ( index ) =>
    {
        setPopUp(
                <div style={{position:'fixed',display:'flex', backgroundColor:'#000000b9', width:'100%', height:'100%', top:'0', left:'0'}} onClick={closePopUp}>
                    <div className="journeyFWQQuestionsDiv" style={{backgroundColor:'#FFFFFF',width:'40rem',height:'20rem',paddingLeft:'1.5rem'}} onClick={(event) => event.stopPropagation()}>
                        <text className="CashFlowBtnClosePopUp" style={{marginLeft:'auto',marginRight:'-4rem', marginTop:'-2rem', marginBottom:'0.5rem', fontSize:'1.5rem', cursor:'pointer'}} onClick={closePopUp}>X</text>
                        <div style={{display:'flex', flexDirection:'row', width:'20rem',margin:'0rem 0rem 1rem 2rem'}}>
                            <text className="journeyFWQQuestionText" style={{fontSize:'1.4rem'}}>Mês: {monthNames[index]}</text>
                            <text className="journeyFWQQuestionText" style={{marginLeft:'auto',fontSize:'1.4rem'}}>Ano: {selectedYear}</text>
                        </div>
                        <div style={{height:'0.1rem', backgroundColor:'#4a3bac',width:'105%',margin:'0rem 0rem 1rem 2rem' }}></div>
                        <div style={{display:'flex', flexDirection:'row', width:'20rem',margin:'0rem 0rem 1rem 2rem' ,gap:'2rem'}}>
                            <div style={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>
                                <text className="journeyFWQQuestionText" >Custo/Despesa</text>
                                <Select name='expensesSelector' className='actionPlanPopupSelector' styles={ selecStyles }  options={ expensesSelection } defaultValue={ expensesSelection.label } placeholder='' />
                            </div>
                                <div style={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>
                                        <text className="journeyFWQQuestionText" >Preço</text>
                                        <input id='currencyInput' className="CashFlowInputPopUp" onChange={inputMaskHandle}></input>
                                    </div>
                                <div>
                            </div>
                        </div>
                        <div id='confirmBtn' className='CashFlowConfirmbtn' style={{margin:'0rem 0rem 1rem 2rem'}} onClick={()=>checkSelectedValue(index)}>
                            Confirmar Custo Efetivo
                        </div>
                    </div>
                </div>
        )
    }

    const closePopUp = () =>
    {
        setPopUp(<div></div>)
    }
    useEffect( () => { initTable(); fillCFList(selectedYear); }, [selectedYear] );
    

    const screenWidth = window.screen.width
    const [showSmallDeviceWarn, setSmallDeviceWarn] = useState(screenWidth<780)
    const closeSmallDeviceWarn = () =>{setSmallDeviceWarn(false)}



    return(
        <div>{window.screen.width>800 ? 
<>

            {showSmallDeviceWarn && <RemoveElement message="O Fluxo de Caixa está disponível na versão Desktop" onClick={closeSmallDeviceWarn} closeWarn={closeSmallDeviceWarn}/>}
            {isLoading && <LoadingScreen/>}
            <table name='ModelCashflowTable' className='ModelCashflowTable'>
                <thead>
                    <tr>   
                        <th><span className={styles.buttons} onClick={()=>{setSelectedYear(prev=>prev-1)}}>-</span> {selectedYear} <span className={styles.buttons} onClick={()=>{setSelectedYear(prev=>prev + 1)}}>+</span></th>
                        <th>
                            <div className='CashFlowbtnMonthLeft' onClick={ changeSemester }>
                                { '<' }
                            </div>
                            <div style={{textAlign:'center', paddingTop:'10px'}}>
                                Janeiro
                            </div>
                        </th>
                        <th>Fevereiro</th>
                        <th>Março</th>
                        <th>Abril</th>
                        <th>Maio</th>
                        <th>
                            <div className='CashFlowbtnMonthRight' style={ { float:'right' } } onClick={ changeSemester }>
                                { '>' }
                            </div>
                            <div style={ { textAlign:'center', paddingTop:'10px' } }>
                                Junho
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr></tr>
                    <tr>
                        <td>Receita Total (R$)</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                    </tr>
                    <tr></tr>
                    <tr>
                        <td>
                            <div className='CashFlowBtnBoxValue'>Produtos (R$)</div>
                        </td>
                        <td>Previsto / Realizado</td>
                        <td>Previsto / Realizado</td>
                        <td>Previsto / Realizado</td>
                        <td>Previsto / Realizado</td>
                        <td>Previsto / Realizado</td>
                        <td>Previsto / Realizado</td>
                    </tr>
                    { tableCFP }

                </tbody>
            </table>
            <table name='ModelCashflowTable2' className='ModelCashflowTable2'>
                <tbody>
                    <tr></tr>
                    <tr>
                        <td>Tributos (R$)</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        </tr>
                    <tr></tr>
                    <tr>
                        <td>
                            <div className='CashFlowBtnBoxValue'>Custo/Despesa (R$)</div>
                        </td>
                        <td>Previsto<img id={( selectedSemester === 2 ) ? 6 : 0 + 0} className='userInputsIconGreenSmall' src={ EditIconImg } onClick={() => editEfetiveCost((( selectedSemester === 2 ) ? 6 : 0) + 0)} alt='...'/>Efetivo</td>
                        <td>Previsto<img id={( selectedSemester === 2 ) ? 6 : 0 + 1} className='userInputsIconGreenSmall' src={ EditIconImg } onClick={() => editEfetiveCost((( selectedSemester === 2 ) ? 6 : 0) + 1)} alt='...'/>Efetivo</td>
                        <td>Previsto<img id={( selectedSemester === 2 ) ? 6 : 0 + 2} className='userInputsIconGreenSmall' src={ EditIconImg } onClick={() => editEfetiveCost((( selectedSemester === 2 ) ? 6 : 0) + 2)} alt='...'/>Efetivo</td>
                        <td>Previsto<img id={( selectedSemester === 2 ) ? 6 : 0 + 3} className='userInputsIconGreenSmall' src={ EditIconImg } onClick={() => editEfetiveCost((( selectedSemester === 2 ) ? 6 : 0) + 3)} alt='...'/>Efetivo</td>
                        <td>Previsto<img id={( selectedSemester === 2 ) ? 6 : 0 + 4} className='userInputsIconGreenSmall' src={ EditIconImg } onClick={() => editEfetiveCost((( selectedSemester === 2 ) ? 6 : 0) + 4)} alt='...'/>Efetivo</td>
                        <td>Previsto<img id={( selectedSemester === 2 ) ? 6 : 0 + 5} className='userInputsIconGreenSmall' src={ EditIconImg } onClick={() => editEfetiveCost((( selectedSemester === 2 ) ? 6 : 0) + 5)} alt='...'/>Efetivo</td>
                    </tr>
                    { tableCFE }
                    <tr></tr>
                </tbody>
            </table>
            <table name='ModelCashflowTable3' className='ModelCashflowTable3'>
                <tbody>
                    <tr>
                        <td>Total de Custo (R$)</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                    </tr>
                    <tr/>
                    <tr>
                        <td>Saldo acumulado (R$)</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                    </tr>
                    <tr/>
                    <tr>
                        <td>Resultado (R$)</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                        <td>-----</td>
                    </tr>
                </tbody>
            </table>
            { effectiveExpensPopUp }
</>
 :  <MobileCashFlow/> }


        </div>
    );
}

export default Cashflow;